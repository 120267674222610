import React from "react";
import { Icon, IconButton } from "rsuite";
import { components } from "../../../../../types/openapi";
import { I18nContext } from "../../../../../provider/I18nProvider";
import MwTag from "../../../../../components/MwTag";
import Label from "../../../../../icons/Label";
import "./index.scss";

interface ILabelButtonProps {
  label: components["schemas"]["Label"] | null;
}

const LabelButton = ({ label }: ILabelButtonProps) => {
  const { t } = React.useContext(I18nContext);
  return label ? (
    <MwTag size="lg" color="green" rounded iconComponent={Label}>
      {label.name}
    </MwTag>
  ) : (
    <IconButton
      className="label-selector-button"
      icon={<Icon icon="star" componentClass={Label} />}
      appearance="ghost"
      color="blue"
    >
      {t("view_LabelAutomatorModal_labelIdTitle")}
    </IconButton>
  );
};
export default LabelButton;
