import React from "react";
import { Badge } from "rsuite";
import "./index.scss";

interface INumberBadgeProps {
  children?: React.ReactChild;
  number?: number;
  className?: string;
  title?: string;
}

const NumberBadge = ({
  children,
  number,
  className,
  title,
}: INumberBadgeProps) => {
  return (
    <Badge
      className={`number-badge${className ? ` ${className}` : ""}`}
      content={number || false}
      title={title}
    >
      {children}
    </Badge>
  );
};
export default NumberBadge;
