import React, { SVGProps } from "react";

const WidgetTypeAuthor = (
  props: SVGProps<SVGSVGElement> & { color1?: string; color2?: string }
) => {
  const { color1 = "#96afaf", color2 = "#2d50b4", ...svgProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={40.272}
      viewBox="0 0 36.131 40.272"
      {...svgProps}
    >
      <g transform="translate(-1840.713 -912.324)">
        <path
          d="M1843.124 947.429a1.385 1.385 0 01-1.338-1.737l5.81-22.05a1.385 1.385 0 01.975-.984l11.14-3.019a1.385 1.385 0 011.341.357l8.121 8.121a1.385 1.385 0 01.357 1.341l-3.019 11.141a1.385 1.385 0 01-.984.975l-22.05 5.809a1.38 1.38 0 01-.353.046zm6.944-22.309l-5 18.982 18.982-5 2.6-9.594-6.987-6.987z"
          fill={color1}
        />
        <path
          d="M1843.124 947.429a1.385 1.385 0 01-.978-2.363l10.5-10.5a1.384 1.384 0 011.957 1.957l-10.5 10.5a1.378 1.378 0 01-.979.406z"
          fill={color1}
        />
        <path
          d="M1856.436 938.01a5.277 5.277 0 113.731-1.544 5.262 5.262 0 01-3.731 1.544zm0-7.786a2.51 2.51 0 101.775 4.285 2.51 2.51 0 00-1.775-4.285z"
          fill={color1}
        />
        <path
          d="M1870.007 932.291a1.381 1.381 0 01-.979-.405l-11.745-11.745a1.383 1.383 0 010-1.957l5.453-5.454a1.383 1.383 0 011.957 0l11.745 11.746a1.383 1.383 0 010 1.957l-5.453 5.453a1.379 1.379 0 01-.978.405zm-9.788-13.129l9.788 9.788 3.5-3.5-9.788-9.788z"
          fill={color1}
        />
        <path
          fill={color2}
          d="M1870.347 952.6H1842.1a1.384 1.384 0 110-2.768h28.25a1.384 1.384 0 010 2.768z"
        />
      </g>
    </svg>
  );
};

export default WidgetTypeAuthor;
