import React from "react";
import LoginForm from "./LoginForm";
import "./index.scss";
import { APP_PATH } from "../../../inc/constants";
import { Link, useHistory } from "react-router-dom";
import { Loader } from "rsuite";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import { parseLocationSearch } from "../../../inc/data";

const { REACT_APP_BUILD_ID = "unknown" } = process.env;
const release = REACT_APP_BUILD_ID.replace(/\D/g, "");

const LoginView = () => {
  const { auth } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const history = useHistory();

  React.useEffect(() => {
    if (!auth?.bearer) {
      return;
    }
    if (release) {
      window.document.title = `media·web 5.7 - Build ${release}`;
    }
    const search = parseLocationSearch<{ path: string }>(
      history.location.search
    );
    history.push(search.path || APP_PATH);
  }, [auth, history]);

  return auth ? (
    <Loader />
  ) : (
    <>
      <LoginForm />
      <Link
        to={"/login/lostPassword"}
        style={{
          display: "block",
          marginTop: 20,
          fontSize: 14,
          color: "#96afaf",
        }}
      >
        {t("forgotPassword")}
      </Link>
    </>
  );
};

export default LoginView;
