import React, { Dispatch, SetStateAction } from "react";
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  FlexboxGrid,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Icon,
  IconButton,
  Loader,
} from "rsuite";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { components } from "../../../../../types/openapi";
import { TSchemaFormErrors } from "../../../../../components/SchemaFormBody";
import DraggableMediaItem from "./DraggableMediaItem";
import axios, { AxiosError } from "axios";
import { ApiDataContext } from "../../../../../provider/ApiDataProvider";
import { IEditorialRubricMap } from "../index";
import { IHashMap } from "../../../../../inc/data";
import { I18nContext } from "../../../../../provider/I18nProvider";
import useEditorialRubrics from "../../../../../hooks/useEditorialRubrics";
import useEditorialRubricMediaItems from "../../../../../hooks/useEditorialRubricMediaItems";
import { APP_PATH } from "../../../../../inc/constants";
import { IMaartenError } from "../../../../../types";

interface IRubricsTabProps {
  errors: TSchemaFormErrors<components["schemas"]["EditorialIssue"]>;
  editorialMediaItems: IHashMap<
    components["schemas"]["EditorialMediaItem"] | null
  >;
  mediaItems: IHashMap<components["schemas"]["MediaItem"] | null>;
  setEditorialMediaItems: Dispatch<
    SetStateAction<IHashMap<components["schemas"]["EditorialMediaItem"] | null>>
  >;
  editorialEditorialRubrics:
    | components["schemas"]["EditorialRubric"][]
    | undefined;
  editorialIssue: components["schemas"]["EditorialIssue"];
  editorialRubricMaps: IEditorialRubricMap[] | undefined;
  setEditorialRubricMaps: Dispatch<
    SetStateAction<IEditorialRubricMap[] | undefined>
  >;
  onChange: (editorialIssue: components["schemas"]["EditorialIssue"]) => void;
  goToPreviewTab: (
    editorialIssue: components["schemas"]["EditorialIssue"],
  ) => void;
  hasEditorialRubricMediaItems: boolean;
  container: Element;
}

const RubricsTab = ({
  errors,
  editorialMediaItems,
  setEditorialMediaItems,
  editorialEditorialRubrics,
  editorialIssue,
  editorialRubricMaps,
  mediaItems,
  setEditorialRubricMaps,
  onChange,
  goToPreviewTab,
  hasEditorialRubricMediaItems,
  container,
}: IRubricsTabProps) => {
  const { t } = React.useContext(I18nContext);
  const { setEditorialRubrics } = React.useContext(ApiDataContext);
  const editorialRubrics = useEditorialRubrics();
  const editorialRubricMediaItems = useEditorialRubricMediaItems();

  const addMediaItem = React.useCallback(
    (editorialRubricId: string, mediaItemId: string) => {
      setEditorialRubricMaps((editorialRubricMaps) => {
        if (!editorialRubricMaps) {
          return [];
        }
        const editorialRubricMap = editorialRubricMaps.find(
          (editorialRubricMap) =>
            editorialRubricMap.editorialRubricId === editorialRubricId,
        );
        if (!editorialRubricMap) {
          return [
            ...editorialRubricMaps,
            {
              editorialRubricId,
              mediaItemIds: [mediaItemId],
            },
          ];
        }
        return editorialRubricMaps.map((editorialRubricMap) =>
          editorialRubricMap.editorialRubricId === editorialRubricId
            ? {
                ...editorialRubricMap,
                mediaItemIds: [...editorialRubricMap.mediaItemIds, mediaItemId],
              }
            : editorialRubricMap,
        );
      });
    },
    [setEditorialRubricMaps],
  );

  const removeMediaItem = React.useCallback(
    (editorialRubricId: string, mediaItemId: string) => {
      setEditorialRubricMaps((editorialRubricMaps) =>
        (editorialRubricMaps || []).map((editorialRubricMap) =>
          editorialRubricMap.editorialRubricId === editorialRubricId
            ? {
                ...editorialRubricMap,
                mediaItemIds: editorialRubricMap.mediaItemIds.filter(
                  (existingMediaItemId) => existingMediaItemId !== mediaItemId,
                ),
              }
            : editorialRubricMap,
        ),
      );
    },
    [setEditorialRubricMaps],
  );

  const moveMediaItem = React.useCallback(
    (
      sourceEditorialRubricId: string,
      sourceMediaItemIndex: number,
      destinationEditorialRubricId: string,
      destinationMediaItemIndex: number,
    ) => {
      if (!editorialRubricMaps) {
        return;
      }
      const sourceEditorialRubricMapsIndex = editorialRubricMaps.findIndex(
        (editorialRubricMap) =>
          editorialRubricMap.editorialRubricId === sourceEditorialRubricId,
      );
      let destinationEditorialRubricMapsIndex = editorialRubricMaps.findIndex(
        (editorialRubricMap) =>
          editorialRubricMap.editorialRubricId === destinationEditorialRubricId,
      );
      const sourceMediaItemIds =
        editorialRubricMaps[sourceEditorialRubricMapsIndex].mediaItemIds;
      const destinationMediaItemIds =
        editorialRubricMaps[destinationEditorialRubricMapsIndex].mediaItemIds;
      const [removed] = sourceMediaItemIds.splice(sourceMediaItemIndex, 1);
      destinationMediaItemIds.splice(destinationMediaItemIndex, 0, removed);
      setEditorialRubricMaps((editorialRubricMaps) => {
        const newEditorialRubricMaps = [...(editorialRubricMaps || [])];
        if (destinationEditorialRubricMapsIndex === -1) {
          destinationEditorialRubricMapsIndex = newEditorialRubricMaps.push({
            editorialRubricId: destinationEditorialRubricId,
            mediaItemIds: [],
          });
        }
        return newEditorialRubricMaps.map((editorialRubricMap, index) => {
          let mediaItemIds: string[];
          switch (index) {
            case sourceEditorialRubricMapsIndex:
              mediaItemIds = sourceMediaItemIds;
              break;

            case destinationEditorialRubricMapsIndex:
              mediaItemIds = destinationMediaItemIds;
              break;

            default:
              return editorialRubricMap;
          }
          return {
            ...editorialRubricMap,
            mediaItemIds,
          };
        });
      });
    },
    [editorialRubricMaps, setEditorialRubricMaps],
  );

  const moveEditorialRubric = React.useCallback(
    (editorialRubricId: string, direction: "up" | "down") => {
      if (!editorialRubrics) {
        return;
      }
      const editorialRubric = editorialRubrics[editorialRubricId];
      if (!editorialRubric) {
        return;
      }

      setEditorialRubricMaps((oldEditorialRubricMaps) => {
        if (!oldEditorialRubricMaps) {
          return oldEditorialRubricMaps;
        }
        const oldIndex = oldEditorialRubricMaps.findIndex(
          (editorialRubricMap) =>
            editorialRubricMap.editorialRubricId === editorialRubricId,
        );
        const newIndex = oldIndex + (direction === "up" ? -1 : 1);
        const newEditorialRubricMaps = [...oldEditorialRubricMaps];
        const editorialRubricMap = newEditorialRubricMaps[oldIndex];
        newEditorialRubricMaps.splice(oldIndex, 1);
        newEditorialRubricMaps.splice(newIndex, 0, editorialRubricMap);
        return newEditorialRubricMaps;
      });

      const newPosition = editorialRubric.order - (direction === "up" ? 1 : -1);

      // EditorialRubric that belongs to the same editorial
      // AND should be moved to the position of this editorialRubric
      const neighbourEditorialRubric = Object.values(editorialRubrics).find(
        (currentEditorialRubric) =>
          currentEditorialRubric.editorialId === editorialRubric.editorialId &&
          currentEditorialRubric.order === newPosition,
      ) as components["schemas"]["EditorialRubric"];
      const editorialRubricsToUpdate: components["schemas"]["EditorialRubric"][] =
        [];
      if (!!neighbourEditorialRubric) {
        neighbourEditorialRubric.order = editorialRubric.order;
        editorialRubricsToUpdate.push(neighbourEditorialRubric);
      }

      editorialRubric.order = newPosition;
      editorialRubricsToUpdate.push(editorialRubric);
      editorialRubricsToUpdate.forEach((currentEditorialRubric) => {
        axios
          .put<components["schemas"]["EditorialRubric"]>(
            `/editorialRubric/crud/${currentEditorialRubric.editorialRubricId}`,
            currentEditorialRubric,
          )
          .then((res) => {
            setEditorialRubrics((currentEditorialRubrics) => ({
              ...currentEditorialRubrics,
              [res.data.editorialRubricId as string]: res.data,
            }));
          });
      });
    },
    [editorialRubrics, setEditorialRubricMaps, setEditorialRubrics],
  );

  const onMediaItemDragEnd = React.useCallback(
    (result: {
      source: { droppableId: string; index: number };
      destination: { droppableId: string; index: number };
    }) => {
      const { source, destination } = result;
      moveMediaItem(
        source.droppableId,
        source.index,
        destination.droppableId,
        destination.index,
      );
    },
    [moveMediaItem],
  );

  const rubricDroppables = React.useMemo(() => {
    if (
      !editorialRubricMaps ||
      !editorialRubrics ||
      !editorialEditorialRubrics ||
      !editorialRubricMediaItems
    ) {
      return <Loader />;
    }

    const activeEditorialRubricMaps = editorialRubricMaps.filter(
      ({ mediaItemIds }) => mediaItemIds.length,
    );
    return activeEditorialRubricMaps.map(
      ({ editorialRubricId, mediaItemIds }, index) => (
        <Droppable droppableId={`${editorialRubricId}`} key={editorialRubricId}>
          {(provided, snapshot) => (
            <li
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`editorial-issue-create-modal__rubric${
                snapshot.isDraggingOver
                  ? " editorial-issue-create-modal__rubric--dragging-over"
                  : ""
              }`}
            >
              <h4 className="editorial-issue-create-modal__rubric__title">
                {editorialRubrics[editorialRubricId].name}
                {activeEditorialRubricMaps.length > 1 ? (
                  <span className="editorial-issue-create-modal__rubric__title__move-buttons">
                    {index > 0 ? (
                      <IconButton
                        className="move-button move-button--up"
                        icon={<Icon icon="arrow-up2" />}
                        size="sm"
                        appearance="ghost"
                        color="blue"
                        onClick={() =>
                          moveEditorialRubric(editorialRubricId, "up")
                        }
                      />
                    ) : null}
                    {index + 1 < activeEditorialRubricMaps.length ? (
                      <IconButton
                        className="move-button move-button--down"
                        icon={<Icon icon="arrow-down2" />}
                        size="sm"
                        appearance="ghost"
                        color="blue"
                        onClick={() =>
                          moveEditorialRubric(editorialRubricId, "down")
                        }
                      />
                    ) : null}
                  </span>
                ) : null}
              </h4>
              <ul className="list-unstyled editorial-issue-create-modal__rubric__media-items">
                {mediaItemIds.map((mediaItemId, mediaItemIndex) => {
                  const editorialMediaItem = editorialMediaItems[mediaItemId];
                  const mediaItem = mediaItems[mediaItemId];
                  const draggableId = `${editorialRubricId}-${mediaItemId}`;
                  const isInEditorialRubricIds = editorialRubricMaps
                    .filter(
                      (rubric) => rubric.mediaItemIds.indexOf(mediaItemId) >= 0,
                    )
                    .map(
                      (editorialRubricMap) =>
                        editorialRubricMap.editorialRubricId,
                    );

                  return editorialMediaItem ? (
                    <DraggableMediaItem
                      key={draggableId}
                      draggableId={draggableId}
                      editorialMediaItem={editorialMediaItem}
                      addMediaItem={addMediaItem}
                      removeMediaItem={removeMediaItem}
                      editorialEditorialRubrics={editorialEditorialRubrics}
                      mediaItem={mediaItem}
                      isInEditorialRubricIds={isInEditorialRubricIds}
                      mediaItemIndex={mediaItemIndex}
                      updateEditorialMediaItem={(
                        editorialMediaItem: components["schemas"]["EditorialMediaItem"],
                      ) => {
                        setEditorialMediaItems((editorialMediaItems) => ({
                          ...editorialMediaItems,
                          [mediaItemId]: editorialMediaItem,
                        }));
                        axios
                          .put<components["schemas"]["EditorialMediaItem"]>(
                            `/editorialMediaItem/crud/${editorialMediaItem.mediaItemId}`,
                            editorialMediaItem,
                          )
                          .catch((err: AxiosError<IMaartenError>) => {
                            Alert.error(
                              t(
                                err.response?.data.error ||
                                  "Wijziging kon niet worden opgeslagen",
                              ),
                            );
                          });
                      }}
                      container={container}
                    />
                  ) : (
                    <Loader key={draggableId} />
                  );
                })}
                {provided.placeholder}
              </ul>
            </li>
          )}
        </Droppable>
      ),
    );
  }, [
    addMediaItem,
    container,
    editorialEditorialRubrics,
    editorialMediaItems,
    editorialRubricMaps,
    editorialRubricMediaItems,
    editorialRubrics,
    mediaItems,
    moveEditorialRubric,
    removeMediaItem,
    setEditorialMediaItems,
    t,
  ]);

  return (
    <>
      <Form onSubmit={() => goToPreviewTab(editorialIssue)}>
        <FormGroup>
          <ControlLabel className="label">{t("senderName")}</ControlLabel>
          <FormControl
            className="input input-gray mw-100"
            onChange={(newSenderName) =>
              onChange({
                ...editorialIssue,
                senderName: newSenderName,
              })
            }
            size="sm"
            value={editorialIssue.senderName}
          />
          {errors && errors["senderName"] ? (
            <HelpBlock style={{ color: "red" }}>
              {t(errors["senderName"])}
            </HelpBlock>
          ) : null}
        </FormGroup>
        <FormGroup>
          <ControlLabel className="label">{t("subject")} *</ControlLabel>
          <FormControl
            className="input input-gray mw-100"
            onChange={(newSubject) =>
              onChange({
                ...editorialIssue,
                subject: newSubject,
              })
            }
            size="sm"
            value={editorialIssue.subject}
          />
          {errors && errors["subject"] ? (
            <HelpBlock style={{ color: "red" }}>
              {t(errors["subject"])}
            </HelpBlock>
          ) : null}
        </FormGroup>
        <FormGroup>
          <ControlLabel className="label">{t("intro")} *</ControlLabel>
          <FormControl
            className="input input-gray mw-100"
            onChange={(newIntro) =>
              onChange({ ...editorialIssue, intro: newIntro })
            }
            rows={5}
            componentClass="textarea"
            size="sm"
            value={editorialIssue.intro}
          />
          {errors && errors["intro"] ? (
            <HelpBlock style={{ color: "red" }}>{t(errors["intro"])}</HelpBlock>
          ) : null}
        </FormGroup>
      </Form>
      <hr />

      {hasEditorialRubricMediaItems ? (
        <ul className="editorial-issue-create-modal__rubrics list-unstyled">
          <DragDropContext onDragEnd={onMediaItemDragEnd as any}>
            {rubricDroppables}
          </DragDropContext>
        </ul>
      ) : (
        <FlexboxGrid
          justify="center"
          className="layout__app-layout__dashboard-template-dialog__image-container"
        >
          <FlexboxGrid.Item colspan={24}>
            <h3 className="my-3 mb-0 text-center d-flex flex-row justify-content-center">
              <span>{t("createModal_noArticles_selected")}</span>
            </h3>
            <p
              style={{
                fontSize: 18,
                lineHeight: 1.33,
                textAlign: "center",
              }}
            >
              {t("editorialIssueNoMediaitems")}
            </p>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={24}>
            <img
              alt={t("createModal_noArticles_selected")}
              src="/img/art/design/design.png"
              srcSet="/img/art/design/design@2x.png 2x, /img/art/design/design@3x.png 3x"
              className="mx-auto my-5 d-block"
              style={{ maxWidth: "80%" }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            className="dashboard-view__content__button-wrapper text-center"
            componentClass={Col}
            colspan={24}
          >
            <Button
              href={`${APP_PATH}/myMedia`}
              appearance="primary"
              className="mt-5"
            >
              {t("addItemsManually")}
            </Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      )}
    </>
  );
};
export default RubricsTab;
