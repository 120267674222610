import React from "react";
import { Loader, Panel } from "rsuite";
import Chart from "react-apexcharts";
import { capitalize, round, sum, upperFirst } from "lodash";
import { ApexOptions } from "apexcharts";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { I18nContext } from "../../../provider/I18nProvider";
import { CHART_COLORS, MEDIA_TYPE_COLORS } from "../../../inc/constants";
import { compact, currency, formatInt } from "../../../inc/numbers";
import { useBenchmarkAnalysisCache } from "../inc/useBenchmarkAnalysisCache";
import { EValueType, IBenchmarkWidgetProps } from "../index";
import PopoverForm from "../../benchmarkWidgets/inc/PopoverForm";
import { EChartType } from "../../../inc/enums";
import {
  calculateChartMaxTick,
  calculateChartTickAmount,
  formatValue,
  getDonutLegendConfig,
} from "../../../inc/widgets/chart";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";
import "./index.scss";

interface IMediaTypeCountMap {
  [source: string]: number[];
}

export interface IMediaTypeBenchmarkWidgetSettings {
  chartType?: EChartType.donut | EChartType.bar | EChartType.column;
  valueType: EValueType;
  notes?: string;
}

const MediaTypeBenchmarkWidget = ({
  benchmark,
  colWidth,
  height,
  onDelete,
  onNotesChange,
  onSettingsChange,
  settings,
  uid,
  width,
}: IBenchmarkWidgetProps<IMediaTypeBenchmarkWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);

  const { matchTexts } = benchmark;
  const defaultSettings = React.useMemo(
    () => ({
      chartType: EChartType.column,
      valueType: EValueType.count,
    }),
    [],
  );
  const { chartType, valueType, notes } = { ...defaultSettings, ...settings };

  const benchmarkAnalysis = useBenchmarkAnalysisCache(
    `/benchmark/analyse/${valueType || "count"}/mediatype/none/publicationDate`,
    benchmark,
  );

  const mediaTypeCountMap = React.useMemo<IMediaTypeCountMap>(() => {
    if (!benchmarkAnalysis) {
      return {};
    }
    return benchmarkAnalysis.items.reduce<IMediaTypeCountMap>((prev, item) => {
      benchmarkAnalysis.valueGroup.forEach((mediaType, mediaTypeIndex) => {
        if (!prev[mediaType]) {
          prev[mediaType] = new Array(item.values.length).fill(0);
        }
        prev[mediaType][mediaTypeIndex] = item.values[mediaTypeIndex];
      });
      return prev;
    }, {});
  }, [benchmarkAnalysis]);

  const mediaTypeNames = React.useMemo(
    () => Object.keys(mediaTypeCountMap),
    [mediaTypeCountMap],
  );

  React.useEffect(() => {
    // Detect invalid configuration and patch it
    if (
      settings &&
      onSettingsChange &&
      matchTexts.length > 1 &&
      chartType === EChartType.donut
    ) {
      onSettingsChange(uid, {
        ...settings,
        chartType: EChartType.bar,
      });
    }
  }, [chartType, matchTexts.length, onSettingsChange, settings, uid]);

  const chartWidth = Math.floor(0.96 * width);
  const chartHeight = height - 105;

  const chartOptions = React.useMemo<ApexOptions>(() => {
    const result: ApexOptions = {
      colors:
        chartType === EChartType.donut
          ? mediaTypeNames.map(
              (mediaTypeName) => MEDIA_TYPE_COLORS[mediaTypeName as "print"],
            )
          : CHART_COLORS,
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: { enabled: false },
      },
      plotOptions: {
        bar: {
          horizontal: chartType === EChartType.bar,
          columnWidth: "55%",
          // @ts-ignore
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: chartType !== EChartType.donut,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        custom: ({ dataPointIndex, series, seriesIndex }) =>
          dataPointIndex !== null
            ? `<div class="arrow_box arrow_box--left"><span>${formatInt(
                series[seriesIndex][dataPointIndex],
              )}</span></div>`
            : "",
      },
      xaxis: {
        categories: mediaTypeNames.map((mediaType) => capitalize(mediaType)),
      },
      yaxis: {
        labels: {
          formatter: (val: number) =>
            valueType && ["ave", "summedAve"].indexOf(valueType) >= 0
              ? currency(val, 0)
              : formatInt(val),
          style: { cssClass: "apexcharts-label--yaxis" },
          offsetX: -10,
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
        min: 0,
        tickAmount: 6,
      },
    };

    switch (chartType) {
      case EChartType.donut:
        if (valueType) {
          const labels = mediaTypeNames.map(upperFirst);
          result.labels = labels;
          result.legend = getDonutLegendConfig({
            chartHeight: height - 58,
            chartWidth: Math.floor(0.96 * width),
            valueType,
          });
          result.tooltip = {
            custom: (options) => {
              const { series, seriesIndex } = options;
              const total = series.reduce(
                (carry: number, value: number) => carry + value,
              );
              return `<div class="arrow_box arrow_box--bottom"><span>${
                labels[seriesIndex]
              }: ${formatValue(valueType, series[seriesIndex])} (${round(
                (series[seriesIndex] / total) * 100,
                1,
              )}%)</span></div>`;
            },
          };
        }
        break;

      // @ts-ignore
      case EChartType.bar:
        const maxXValues = benchmarkAnalysis
          ? benchmarkAnalysis.valueGroup.map((yAxisValue, key) =>
              benchmarkAnalysis.items.reduce(
                (prev, item) => prev + item.values[key],
                0,
              ),
            )
          : [];
        const maxValue = Math.max(...maxXValues);
        const maxTick = calculateChartMaxTick(maxValue);
        result.xaxis = {
          ...result.xaxis,
          min: 0,
          max: maxTick,
          tickAmount: calculateChartTickAmount(maxTick, chartWidth),
          labels: {
            ...result.xaxis?.labels,
            formatter(value: string): string {
              const parsedValue = parseInt(value, 10);
              if (!isFinite(parsedValue)) {
                return value;
              }
              return colWidth === 1
                ? compact(parsedValue, 1)
                : formatValue(valueType, parsedValue);
            },
          },
        };

      // @falls through
      case EChartType.column:
        result.tooltip = {
          custom: ({ dataPointIndex, series, seriesIndex }) => {
            const value = series[seriesIndex][dataPointIndex];
            return `<div class="arrow_box arrow_box--left"><span>${formatValue(
              valueType,
              value,
            )}${
              series.length === 1
                ? ` (${round((value / sum(series[0])) * 100, 1)}%)`
                : ""
            }</span></div>`;
          },
        };
    }

    return result;
  }, [
    benchmarkAnalysis,
    chartType,
    chartWidth,
    colWidth,
    height,
    mediaTypeNames,
    valueType,
    width,
  ]);

  const series = React.useMemo(() => {
    if (chartType === EChartType.donut) {
      return benchmarkAnalysis
        ? benchmarkAnalysis.valueGroup.map(
            (_mediaType, mediaTypeIndex) =>
              benchmarkAnalysis?.items.reduce(
                (prev, item) => prev + item.values[mediaTypeIndex],
                0,
              ),
          )
        : null;
    }
    return matchTexts.map((matchText) => {
      const item = benchmarkAnalysis?.items.find(
        (item) => item.benchmarkName === matchText.name,
      );
      return {
        name: matchText.name,
        data: item?.values || [],
      };
    });
  }, [matchTexts, benchmarkAnalysis, chartType]);

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        notes={notes}
        onNotesChange={onNotesChange}
        allowImageDownload
        onDelete={onDelete}
        popoverForm={
          onSettingsChange ? (
            <PopoverForm
              onSettingsChange={onSettingsChange}
              settings={
                {
                  ...defaultSettings,
                  ...settings,
                } as IMediaTypeBenchmarkWidgetSettings
              }
              chartTypes={{
                donut: matchTexts.length === 1,
                bar: true,
                column: true,
              }}
              showRemainingOption={false}
              widgetUid={uid}
            />
          ) : undefined
        }
        subtitle={`${t(`valueType_${valueType}`)}`}
        title={`${t("MediaType")}`}
      />
    ),
    [
      defaultSettings,
      matchTexts.length,
      notes,
      onDelete,
      onNotesChange,
      onSettingsChange,
      settings,
      t,
      uid,
      valueType,
    ],
  );

  return (
    <Panel
      bordered
      header={header}
      className={`views__benchmark-view__widgets__media-type-widget`}
    >
      {series ? (
        !series.length ||
        // @ts-ignore
        (series[0] && series[0].data && !series[0].data.length) ? (
          <InsufficientDataBody />
        ) : (
          <div
            className="chart"
            style={{
              width: chartWidth,
              height: chartHeight,
              overflow: "hidden",
            }}
          >
            <Chart
              key={`${chartType}_${valueType}`}
              type={
                (chartType === EChartType.column ? "bar" : chartType) as any
              }
              series={series}
              options={chartOptions}
              height={chartHeight}
              width={chartWidth}
            />
          </div>
        )
      ) : (
        <div style={{ width }}>
          <Loader backdrop />
        </div>
      )}
    </Panel>
  );
};

export default MediaTypeBenchmarkWidget;
