import React, { SVGProps } from "react";

const PasswordHide = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Wachtwoord verbergen"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18.548}
    viewBox="0 0 24 18.548"
    {...props}
  >
    <g id="prefix__Group_1827" data-name="Group 1827">
      <path
        id="prefix__Path_2195"
        data-name="Path 2195"
        fill="#96afaf"
        d="M1583.858 862.334a.829.829 0 01-.46-.139 23.222 23.222 0 01-5.681-5.577.834.834 0 010-.943c.215-.313 5.324-7.658 11.854-7.658a10.7 10.7 0 015.317 1.552.835.835 0 11-.83 1.449 9.06 9.06 0 00-4.486-1.332c-4.771 0-8.925 4.9-10.128 6.461a21.664 21.664 0 004.876 4.656.835.835 0 01-.461 1.531z"
        transform="translate(-1577.571 -846.873)"
      />
      <path
        id="prefix__Path_2196"
        data-name="Path 2196"
        fill="#96afaf"
        d="M1596.046 867.18a9 9 0 01-1.465-.122.834.834 0 11.272-1.646 7.421 7.421 0 001.193.1c4.771 0 8.926-4.9 10.128-6.461a23.427 23.427 0 00-2.016-2.291.834.834 0 111.178-1.182 23.3 23.3 0 012.565 3 .835.835 0 010 .943c-.216.314-5.325 7.659-11.855 7.659z"
        transform="translate(-1584.046 -849.777)"
      />
      <path
        id="prefix__Path_2197"
        data-name="Path 2197"
        fill="#96afaf"
        d="M1584.121 864.668a1.206 1.206 0 01-.861-2.05l15.826-16.136a1.206 1.206 0 111.722 1.689l-15.826 16.136a1.2 1.2 0 01-.861.361z"
        transform="translate(-1579.692 -846.119)"
      />
      <path
        id="prefix__Path_2198"
        data-name="Path 2198"
        fill="#96afaf"
        d="M1593.068 862.4l5.269-5.373a3.936 3.936 0 10-5.269 5.373z"
        transform="translate(-1582.879 -849.633)"
      />
    </g>
  </svg>
);

export default PasswordHide;
