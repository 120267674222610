import React, { RefObject } from "react";
import { ApiDataContext } from "../../../../../provider/ApiDataProvider";
import {
  Divider,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Popover,
  TagGroup,
} from "rsuite";
import MagnifyingGlass from "../../../../../icons/MagnifyingGlass";
import LabelIcon from "../../../../../icons/Label";
import { containsWordThatStartWith } from "../../../../../inc/query";
import { I18nContext } from "../../../../../provider/I18nProvider";
import useLabels from "../../../../../hooks/useLabels";
import MwTag from "../../../../../components/MwTag";
import LabelFilledIcon from "../../../../../icons/LabelFilled";
import LabelTag from "../../../../../components/LabelTag";
import MwWhisper from "../../../../../components/MwWhisper";

interface ILabelCellProps {
  value: string[];
  onChange?: (value: string[]) => void;
}

const LabelCell = ({ value, onChange }: ILabelCellProps) => {
  const { t } = React.useContext(I18nContext);
  const { updateLabel } = React.useContext(ApiDataContext);
  const labels = useLabels();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const results = React.useMemo(
    () =>
      containsWordThatStartWith(
        Object.values(labels || {}),
        "name",
        searchQuery
      ),
    [labels, searchQuery]
  );

  const triggerRef: RefObject<any> = React.useRef();

  const renderedResults = React.useMemo(() => {
    if (!results.length) {
      return searchQuery.trim().length ? (
        <MwTag
          size="lg"
          iconComponent={LabelIcon}
          color="cyan"
          rounded
          onClick={
            onChange
              ? () => {
                  updateLabel({
                    name: searchQuery.trim(),
                  }).then((label) => {
                    if (label.labelId) {
                      onChange([...value, label.labelId]);
                    }
                  });
                }
              : undefined
          }
        >
          {t("createOption", searchQuery)}
        </MwTag>
      ) : null;
    }
    return (
      <>
        {results.map((result) => (
          <LabelTag
            key={result.labelId}
            size="lg"
            color={value.includes(result.labelId as string) ? "green" : "cyan"}
            rounded
            onClick={
              onChange
                ? () => {
                    const id = result.labelId as string;
                    onChange(
                      value.includes(id)
                        ? value.filter((x) => x !== id)
                        : [...value, id]
                    );
                  }
                : undefined
            }
            label={result}
          />
        ))}
      </>
    );
  }, [onChange, results, searchQuery, t, updateLabel, value]);

  const icon = React.useMemo(
    () => (
      <Icon
        className="w-100"
        icon="star"
        componentClass={value.length ? LabelFilledIcon : LabelIcon}
      />
    ),
    [value.length]
  );

  return onChange ? (
    <MwWhisper
      placement={"auto"}
      triggerRef={triggerRef}
      trigger="click"
      speaker={
        <Popover className={`popover-without-arrow label-popover`}>
          <InputGroup className="label-popover__search-input-group" inside>
            <InputGroup.Addon>
              <Icon icon="star" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              className="label-popover__search-input"
              size="sm"
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
            />
          </InputGroup>
          <Divider />
          <TagGroup className="label-popover__results">
            {renderedResults}
          </TagGroup>
        </Popover>
      }
    >
      <IconButton
        appearance="subtle"
        className="rs-btn-subtle--in-table"
        size="xs"
        icon={icon}
      />
    </MwWhisper>
  ) : (
    icon
  );
};
export default LabelCell;
