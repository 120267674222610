import React, { SVGProps } from "react";

function Prullie(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Prullie_icon"
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="currentColor"
      {...props}
    >
      <defs>
        <clipPath id="prefix__clip-path">
          <path
            id="prefix__Rectangle_1447"
            d="M0 0h12.02v13.138H0z"
            className="prefix__cls-1"
          />
        </clipPath>
      </defs>
      <g id="prefix__Group_1153" clipPath="url(#prefix__clip-path)">
        <path
          id="prefix__Path_1568"
          d="M11.715 18.042H4.492A1.133 1.133 0 013.36 16.91V8.391a.522.522 0 111.043 0v8.519a.088.088 0 00.089.09h7.223a.089.089 0 00.089-.089v-8.52a.522.522 0 111.043 0v8.519a1.133 1.133 0 01-1.132 1.132"
          className="prefix__cls-1"
          transform="translate(-2.094 -4.903)"
        />
        <path
          id="prefix__Path_1569"
          d="M13.813 2.006a.521.521 0 01-.522-.522v-.352a.089.089 0 00-.089-.089h-2.668a.09.09 0 00-.089.089v.352a.521.521 0 11-1.043 0v-.352A1.133 1.133 0 0110.534 0H13.2a1.133 1.133 0 011.132 1.132v.352a.521.521 0 01-.522.522"
          className="prefix__cls-1"
          transform="translate(-5.859)"
        />
        <path
          id="prefix__Path_1570"
          d="M11.5 7.6H.522a.522.522 0 110-1.043H11.5a.522.522 0 010 1.043"
          className="prefix__cls-1"
          transform="translate(0 -4.085)"
        />
        <path
          id="prefix__Path_1571"
          d="M15.084 18.814a.521.521 0 01-.522-.522V12.9a.522.522 0 011.043 0v5.391a.521.521 0 01-.522.522"
          className="prefix__cls-1"
          transform="translate(-9.074 -7.714)"
        />
        <path
          id="prefix__Path_1572"
          d="M9.81 18.814a.521.521 0 01-.522-.522V12.9a.522.522 0 011.043 0v5.391a.521.521 0 01-.522.522"
          className="prefix__cls-1"
          transform="translate(-5.788 -7.714)"
        />
        <path
          id="prefix__Path_1573"
          d="M20.359 18.814a.521.521 0 01-.522-.522V12.9a.522.522 0 011.043 0v5.391a.521.521 0 01-.522.522"
          className="prefix__cls-1"
          transform="translate(-12.361 -7.714)"
        />
      </g>
    </svg>
  );
}

export default Prullie;
