import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormGroup,
  Modal,
  Popover,
} from "rsuite";
import axios, { AxiosError } from "axios";
import LabelIdsPicker from "../../../../components/form/LabelIdsPicker";
import React from "react";
import { findDOMNode } from "react-dom";
import { I18nContext } from "../../../../provider/I18nProvider";
import { components } from "../../../../types/openapi";
import MwWhisper from "../../../../components/MwWhisper";
import { AuthContext } from "../../../../provider/AuthProvider";
import { IMaartenError } from "../../../../types";

interface IBuyPopoverProps {
  searchEngineResultIds: string[];
}

const BuyPopover = ({ searchEngineResultIds }: IBuyPopoverProps) => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const popoverRef = React.useRef();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [isFinished, setIsFinished] = React.useState(false);
  const [mediaItemPurchases, setMediaItemPurchases] =
    React.useState<components["schemas"]["MediaItemPurchase"][]>();

  React.useEffect(() => {
    if (!popoverOpen) {
      return;
    }
    const handleClickOutsideModal = (event: any) => {
      const popoverDom = findDOMNode(popoverRef.current);
      if (
        !popoverDom ||
        popoverDom.contains(event.target) ||
        document.querySelectorAll(".rs-picker-menu").length
      ) {
        return;
      }
      setPopoverOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [popoverOpen]);
  const [buyLabelIds, setBuyLabelIds] = React.useState([]);

  const handleClose = React.useCallback(() => {
    setMediaItemPurchases(undefined);
  }, []);

  const purchasedArticleUrls = React.useMemo(
    () =>
      mediaItemPurchases
        ? mediaItemPurchases
            .map((mediaItemPurchase) => mediaItemPurchase.url)
            .filter((url) => !!url)
        : [],
    [mediaItemPurchases],
  );

  if (!searchEngineResultIds.length) {
    return null;
  }

  return (
    <>
      <MwWhisper
        trigger="none"
        open={popoverOpen}
        speaker={
          <Popover
            title={
              searchEngineResultIds.length > 1
                ? t("buyArticles", searchEngineResultIds.length)
                : t("buyArticle")
            }
            ref={popoverRef}
          >
            <Form
              style={{ width: 400 }}
              className="p-2"
              onSubmit={() => {
                setPopoverOpen(false);
                setIsFinished(true);
                if (!auth) {
                  return;
                }
                axios
                  .request<components["schemas"]["MediaItemPurchase"][]>({
                    method: "post",
                    url: "/mediaItemPurchase/createFromSearchEngineResults",
                    params: {
                      searchEngineResultIds: searchEngineResultIds.join(","),
                      labelIds: buyLabelIds.join(","),
                    },
                  })
                  .then((res) => {
                    setMediaItemPurchases(res.data);
                  })
                  .catch((err: AxiosError<IMaartenError>) => {
                    Alert.error(
                      t(err.response?.data.error || "genericErrorMessage"),
                    );
                  });
              }}
            >
              <p>
                <strong>{t("achtung")}</strong>&nbsp;
                {t(
                  searchEngineResultIds.length === 1
                    ? "addSearchResultSingle"
                    : "addSearchResultMultiple",
                )}
              </p>
              <hr />
              <FormGroup>
                <ControlLabel>{t("optionalAddLabels")}</ControlLabel>
                <LabelIdsPicker
                  onChange={setBuyLabelIds as any}
                  value={buyLabelIds}
                />
              </FormGroup>
              <Button
                className="mt-3"
                appearance="primary"
                type="submit"
                title={t("addArticleTitle")}
              >
                {t("addArticle")}
              </Button>
            </Form>
          </Popover>
        }
      >
        <Button
          appearance="ghost"
          onClick={() => setPopoverOpen((popoverOpen) => !popoverOpen)}
          disabled={isFinished}
        >
          {isFinished ? t("addedArticle") : t("buy")}
        </Button>
      </MwWhisper>
      {auth && purchasedArticleUrls.length ? (
        <Modal onHide={handleClose} show={true}>
          <Modal.Header>
            <Modal.Title>{t("articlesAreBeingProcessedTitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              minHeight: "30vh",
              maxHeight: undefined,
              paddingTop: 10,
              alignItems: "initial",
            }}
          >
            <img
              alt={t("articlesAreBeingProcessedTitle")}
              src="/img/art/kauwgomballen/kauwgomballen.png"
              style={{ maxWidth: "100%" }}
            />
            <p className="my-3">{t("articlesAreBeingProcessedBody")}</p>
            {mediaItemPurchases ? (
              <ul>
                {purchasedArticleUrls.map((url, key) => (
                  <li key={url}>
                    <a
                      href={`${url}?bearer=${auth.bearer}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("article")} {key + 1}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default BuyPopover;
