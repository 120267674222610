import { Alert, Button, Loader } from "rsuite";
import React from "react";
import { uniq } from "lodash";
import { AxiosError } from "axios";

import { I18nContext } from "../../../../provider/I18nProvider";
import axios from "./../../../../inc/axios";
import { format, subYears } from "date-fns";
import { components } from "../../../../types/openapi";
import { ApiDataContext } from "../../../../provider/ApiDataProvider";
import { DATE_FORMAT_REVERSE } from "../../../../inc/date";
import { currency } from "../../../../inc/numbers";
import { IMaartenError } from "../../../../types";

interface ILogPanelProps {
  mediaItem: components["schemas"]["MediaItem"];
}

const LogPanel = ({ mediaItem }: ILogPanelProps) => {
  const { t } = React.useContext(I18nContext);
  const { labels } = React.useContext(ApiDataContext);

  const [logs, setLogs] = React.useState<
    components["schemas"]["Log"][] | null
  >();

  function renderLogMessage(log: components["schemas"]["Log"]): string {
    let oldObject: components["schemas"]["MediaItem"];
    let newObject: components["schemas"]["MediaItem"];

    const { userName, superUserName } = log;
    const actor = superUserName ? superUserName : userName;
    try {
      oldObject = log.oldJson ? JSON.parse(log.oldJson) : null;
      newObject = log.newJson ? JSON.parse(log.newJson) : null;
    } catch (e) {
      return "";
    }
    if ((!oldObject && !newObject) || log.oldJson === log.newJson) {
      return `${actor} heeft dit item geopend`;
    }

    if (!oldObject) {
      return `${actor} heeft dit item gemaakt`;
    }

    if (!newObject) {
      return `${actor} heeft dit item verwijderd`;
    }

    const changedProps = (
      uniq([
        ...Object.keys(oldObject),
        ...Object.keys(newObject),
      ]) as (keyof components["schemas"]["MediaItem"])[]
    ).filter(
      (propKey) =>
        // https://www.notion.so/c700a5b224564b5395ec6c30144d735a?v=886ff64714724bb2a742a4953b2fdf44
        // Als gebruiker wil ik in de log bij een artikel kunnen zien wie, wanneer de PR waarde of het sentiment...
        propKey !== "imageUrl" &&
        JSON.stringify(oldObject[propKey]) !==
          JSON.stringify(newObject[propKey]),
    );

    switch (changedProps.length) {
      case 0:
        return `${actor} heeft dit item geopend`;

      case 1:
        switch (changedProps[0]) {
          case "labelIds":
            const addedLabelIds =
              newObject.labelIds?.filter(
                (labelId: string) =>
                  (oldObject.labelIds || []).indexOf(labelId) === -1,
              ) || [];
            const removedLabelIds =
              oldObject.labelIds?.filter(
                (labelId: string) =>
                  (newObject.labelIds || []).indexOf(labelId) === -1,
              ) || [];
            const messages = [];
            if (labels && addedLabelIds.length) {
              messages.push(
                `het label ${addedLabelIds
                  .map((labelId: string) => {
                    const label = labels[labelId];
                    return label ? `"${label.name}"` : `"${labelId}"`;
                  })
                  .join(" en ")} toegevoegd`,
              );
            }
            if (labels && removedLabelIds.length) {
              messages.push(
                `het label ${removedLabelIds
                  .map((labelId: string) => {
                    const label = labels[labelId];
                    return label ? `"${label.name}"` : `"${labelId}"`;
                  })
                  .join(" en ")} verwijderd`,
              );
            }
            return messages.length
              ? `${actor} heeft ${messages.join(" en ")}.`
              : "";

          case "customerSentiment":
            return newObject[changedProps[0]]
              ? `${actor} heeft Sentiment gewijzigd naar "${t(
                  `sentiment${newObject[changedProps[0]]}`,
                )}"`
              : `${actor} heeft Sentiment teruggezet naar "${t(
                  `sentiment${mediaItem.sentiment}`,
                )}"`;

          case "customerPrValue":
            return newObject[changedProps[0]] === undefined
              ? `${actor} heeft PR-waarde teruggezet naar ${
                  mediaItem.prValue === undefined
                    ? "onbekend"
                    : currency(mediaItem.prValue)
                }`
              : `${actor} heeft PR-waarde gewijzigd naar ${currency(
                  newObject["customerPrValue"] || 0,
                )}`;
        }
        const newValue = newObject[changedProps[0]];
        return `${actor} wijzigde "${t(changedProps[0])}" naar ${
          typeof newValue === "string"
            ? `"${t(newValue)}"`
            : t(String(newValue))
        }`;

      default:
        return `${actor} wijzigde ${changedProps
          .map((prop) => `"${t(prop)}"`)
          .join(" en ")}.`;
    }
  }

  return (
    <div>
      <Button
        className="mt-1"
        block
        disabled={logs === null}
        onClick={() => {
          if (logs) {
            setLogs(undefined);
            return;
          }
          setLogs(null);
          axios
            .request<components["schemas"]["Log"][]>({
              method: "get",
              url: `/log/crud/objectTypeMediaItem/${mediaItem.mediaItemId}`,
              params: {
                startDate: format(subYears(new Date(), 1), DATE_FORMAT_REVERSE),
              },
            })
            .then((res) => {
              setLogs(res.data);
            })
            .catch((err: AxiosError<IMaartenError>) => {
              setLogs(undefined);
              Alert.error(t(err.response?.data.error || "genericErrorMessage"));
            });
        }}
      >
        {t(
          logs
            ? "components_MediaItem_hideLog"
            : "components_MediaItem_showLog",
        )}
      </Button>
      {logs === null ? (
        <div style={{ minHeight: 200, position: "relative" }}>
          <Loader center size="lg" />
        </div>
      ) : null}
      {logs ? (
        <div className="meta-lists">
          {logs.length
            ? logs.map((log) => {
                const { logDate } = log;
                const formattedDate = format(new Date(logDate), "dd-MM HH:mm");
                return (
                  <div className="log-item" key={logDate}>
                    {formattedDate} {renderLogMessage(log)}
                  </div>
                );
              })
            : t("components_MediaItem_log_noResults")}
        </div>
      ) : null}
    </div>
  );
};

export default LogPanel;
