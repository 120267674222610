import { Icon, Input, InputGroup, Popover } from "rsuite";
import CopyToClipboard from "react-copy-to-clipboard";
import React from "react";
import MwWhisper from "../MwWhisper";

interface ICopyToClipboardPopoverProps {
  value: string;
  children: React.ReactNode;
}

const CopyToClipboardPopover = ({
  value,
  children,
}: ICopyToClipboardPopoverProps) => (
  <MwWhisper
    preventOverflow
    trigger="active"
    speaker={
      <Popover>
        <InputGroup inside size="sm">
          <Input value={value} style={{ width: 300 }} />
          <CopyToClipboard text={value}>
            <InputGroup.Button style={{ height: 26 }}>
              <Icon icon="copy" />
            </InputGroup.Button>
          </CopyToClipboard>
        </InputGroup>
      </Popover>
    }
    placement="bottomEnd"
  >
    {children}
  </MwWhisper>
);
export default CopyToClipboardPopover;
