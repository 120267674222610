import React, { SVGProps } from "react";

const DashboardAnalysis = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={16.017}
      viewBox="0 0 25 16.017"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-1{fill:#fff}"}</style>
      </defs>
      <g id="prefix__Group_1563" transform="translate(-1174.439 -50.99)">
        <path
          id="prefix__Path_1847"
          d="M1202.187 60.227a.593.593 0 01-.594-.593 3.517 3.517 0 013.513-3.514.593.593 0 110 1.187 2.33 2.33 0 00-2.326 2.327.593.593 0 01-.593.593z"
          className="prefix__cls-1"
          transform="translate(-15.507 -2.93)"
        />
        <path
          id="prefix__Path_1848"
          d="M1197.158 73.825h-20.438a2.283 2.283 0 01-2.281-2.281v-6.335a2.283 2.283 0 012.281-2.281.593.593 0 110 1.187 1.1 1.1 0 00-1.094 1.094v6.336a1.1 1.1 0 001.094 1.093h20.438a1.1 1.1 0 001.094-1.093v-6.336a1.1 1.1 0 00-1.094-1.094.593.593 0 110-1.187 2.283 2.283 0 012.281 2.281v6.336a2.283 2.283 0 01-2.281 2.28z"
          className="prefix__cls-1"
          transform="translate(0 -6.818)"
        />
        <path
          id="prefix__Path_1849"
          d="M1201.688 61.969a5.489 5.489 0 115.489-5.489 5.5 5.5 0 01-5.489 5.489zm0-9.791a4.3 4.3 0 104.3 4.3 4.307 4.307 0 00-4.3-4.3z"
          className="prefix__cls-1"
          transform="translate(-12.427 -.001)"
        />
        <path
          id="prefix__Path_1850"
          d="M1221.155 76.946a.592.592 0 01-.42-.174l-1.6-1.6a.594.594 0 01.839-.839l1.6 1.6a.593.593 0 01-.42 1.013z"
          className="prefix__cls-1"
          transform="translate(-25.423 -13.229)"
        />
        <path
          id="prefix__Path_1851"
          d="M1218.747 55.195a.593.593 0 01-.421-1.011l2.995-3.018a.594.594 0 01.843.837l-2.995 3.017a.593.593 0 01-.422.175z"
          className="prefix__cls-1"
          transform="translate(-24.965)"
        />
        <path
          id="prefix__Path_1852"
          d="M1190.131 73.051a.594.594 0 01-.418-.172L1185.6 68.8l-1.706 1.961a.594.594 0 11-.9-.779l2.122-2.44a.593.593 0 01.426-.2.578.578 0 01.44.172l4.138 4.111 1.676-1.689a.594.594 0 01.843.836l-2.094 2.11a.524.524 0 01-.414.169z"
          className="prefix__cls-1"
          transform="translate(-4.807 -9.334)"
        />
      </g>
    </svg>
  );
};

export default DashboardAnalysis;
