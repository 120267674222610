import React, { RefObject } from "react";
import { Dropdown, Nav, Popover } from "rsuite";
import { I18nContext } from "../../../../../provider/I18nProvider";
import { BootstrapSize } from "../../../../../inc/constants";
import { LayoutContext } from "../../../../../provider/LayoutProvider";
import MwWhisper from "../../../../../components/MwWhisper";
import { elementHasClassName } from "../../../../../inc/dom";

import "./index.scss";

interface IMoreOptionsPopoverProps {
  items: React.ReactElement[];
}

const MoreOptionsPopover = (props: IMoreOptionsPopoverProps) => {
  const { items } = props;
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = React.useContext(I18nContext);
  const triggerRef: RefObject<any> = React.useRef();

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    const handleClickOutsideModal = (event: any) => {
      // detect a click outside any (nested-)popover up.
      let doClose = true;
      let parentNode = event.target.parentNode;
      while (parentNode && doClose) {
        doClose = !elementHasClassName(parentNode, "rs-popover-content");
        parentNode = parentNode.parentNode;
      }
      if (doClose) {
        setTimeout(() => {
          setIsOpen(false);
        }, 100);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideModal);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [isOpen]);
  const isSmall = items.length > 10;

  return (
    <MwWhisper
      placement={windowOuterWidth < BootstrapSize.MD ? "rightStart" : "bottom"}
      triggerRef={triggerRef}
      trigger="none"
      open={isOpen}
      speaker={
        <Popover className="bulk-actions-bar-more-options__popover">
          <Dropdown.Menu>{items}</Dropdown.Menu>
        </Popover>
      }
    >
      <Nav.Item
        onSelect={() => setIsOpen((isOpen) => !isOpen)}
        className={`bulk-actions-bar-more-options__nav-item icon-nav-item icon-nav-item--chevron rs-picker-placement-bottom${
          isSmall ? " bulk-actions-bar-more-options__nav-item--small" : ""
        }`}
      >
        <span className="bulk-actions-bar-more-options__label">
          {t("moreOptions")}
        </span>
        <span className="rs-picker-toggle-caret" />
      </Nav.Item>
    </MwWhisper>
  );
};
export default MoreOptionsPopover;
