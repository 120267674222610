import {
  TLocale,
  TLocaleCalendar,
  TLocaleData,
  TLocaleRsuiteData,
} from "./index";

const db: TLocaleData = {
  "Aan een woord niet genoeg":
    "Aan één woord niet genoeg? De hulptool helpt je bij het samenstellen van een complexe zoekopdracht. Combineer woorden of sluit woorden uit in je zoekopdracht.",
  "Aanpassen widgets": "Aanpassen widgets",
  "Actieve filterbalk": "Actieve filterbalk",
  "Bad Request": "Kon gegevens niet ophalen. Probeer het later nog eens",
  "Bijvoorbeeld het type of sentiment":
    "Om in te zoomen op specifieke aspecten binnen je gemonitorde berichten. Bijvoorbeeld op het mediumtype of sentiment.",
  "Check de resultaten": "Check de resultaten",
  "Dan kan je de statistieken van die edities hier terugvinden":
    "Dan kan je de statistieken van die edities hier terugvinden.",
  "Dan kun je de naam hier aanpassen":
    "Dan kun je de naam hier aanpassen, zodat jouw dashboard makkelijk terug te vinden is.",
  "Dashboard already exists": "Er bestaat al een dashboard met deze naam.",
  "Dashboards beheren": "Dashboards beheren",
  "Dat kan hier met de zoekopdracht die jij zelf samenstelt":
    "Dat kan hier met de zoekopdracht die jij zelf samenstelt. Hier leggen we uit hoe je dat doet.",
  "Dat kun je hier terugvinden":
    "Dat kun je hier terugvinden. Daarnaast kan je hier abonnees toevoegen. Dat kan op twee manieren. Voeg in een keer een lijst toe of voeg incidentieel een abonnee toe.",
  "De actieve punten in de filter verschijnen in deze balk":
    "De actieve punten in de filter verschijnen in deze balk.",
  "De perfecte zoekopdracht samengesteld":
    "De perfecte zoekopdracht samengesteld? Sla hem dan op zodat je op een later moment opnieuw kan gebruiken.",
  "De widgets zijn volledig zelf in te delen":
    "De widgets zijn volledig zelf in te delen. Je kunt de grootte en de volgorde aanpassen en widgets toevoegen of verwijderen.",
  "Dit is de nieuwsbriefmodule": "Dit is de nieuwsbriefmodule",
  "Editorial already exists":
    "Er bestaat al een nieuwsbrief met deze naam. Pas de naam van deze nieuwsbrief aan.",
  "Heb je een editie verstuurd": "Heb je een editie verstuurd?",
  "Hier kun je opgeslagen dashboards terugvinden of verwijderen":
    "Hier kun je opgeslagen dashboards terugvinden of verwijderen en eventueel nieuwe toevoegen.",
  "Hier kun je zelf nieuwsbrieven aanmaken":
    "Hier kun je zelf nieuwsbrieven aanmaken om je stakeholders te informeren",
  "Hier staan je widgets": "Hier staan je widgets",
  "Hier vind je een nieuwsbrief die al is ingesteld":
    "Hier vind je een nieuwsbrief die al is ingesteld",
  "Hier voeg je widgets toe": "Hier voeg je widgets toe",
  "In de widgets wordt je data getoond":
    "In de widgets wordt je data getoond. Widgets reageren op je filter en andersom. Dit is de berichtenwidget maar er zijn er nog veel meer.",
  "Is je dashboard naar wens": "Is je dashboard naar wens?",
  "Klik hier om widgets toe te voegen":
    "Klik hier om widgets toe te voegen. Je hebt keuze uit diverse soorten zoals de berichtenwidget, highlights of labels.",
  "LabelAutomator already exists": "Er bestaat al een automator voor dit label",
  "Meer informatie over nieuwsbrieven": "Meer informatie over nieuwsbrieven?",
  "Met de filter kun je de resultaten aanpassen":
    "Met de filter kun je de resultaten aanpassen",
  "Neem contact op met je accountmanager of kijk op onze supportpagina":
    "Neem contact op met je accountmanager of kijk op onze <a href='https://support.mediaweb.eu/' target='_blank'>supportpagina</a>.",
  "Network Error":
    "Er ging iets mis bij het verwerken van jouw aanvraag. Probeer het later nog eens of neem contact met ons op.",
  "No Filter": "Geen filter",
  "No mediaitems found": "Geen mediaitems gevonden voor in de PDF",
  "Request failed with status code 404": "Dit kon niet worden gevonden.",
  "Request failed with status code 405": "De aanvraag is niet volledig.",
  "Request failed with status code 429":
    "Er loopt reeds een andere PDF-taak. Probeer het later nog eens.",
  "Schaf de artikelen aan die je nodig hebt":
    'Schaf de artikelen aan die je nodig hebt. Deze vind je in je dashboards terug onder de zoekterm: "Vanuit zoekmachine". Je kan eventueel ook meteen een label aan de aangeschafte artikelen hangen.',
  "Sla je zoekopdracht op": "Sla je zoekopdracht op",
  "Stel je zoekopdracht samen": "Stel je zoekopdracht samen",
  "Vanuit hier kun je gemakkelijk en snel een editie versturen":
    "Vanuit hier kun je gemakkelijk en snel een editie versturen.",
  "Via deze knop kun je de widget aanpassen of verwijderen":
    "Via deze knop kun je de widget aanpassen of verwijderen.",
  "Voer een zoekterm in en specificeer eventueel op mediumtype":
    "Voer een zoekterm in en specificeer eventueel op mediumtype, bron en/of periode.",
  "Wil je naar een ander account":
    "Wil je naar een ander account? Dan kan je hier wisselen. Ieder account heeft z'n eigen dashboards.",
  "Wil je zien wie je nieuwsbrieven ontvangt":
    "Wil je zien wie je nieuwsbrieven ontvangt?",
  "Wissel van account": "Wissel van account",
  "Zelf zoeken in onze database": "Zelf zoeken in onze database?",
  "editorial-issue-create-modal__disabled-title":
    "Er zijn geen abonnees voor deze nieuwsbrief. Voeg eerst abonnees toe",
  "email already used by another subscriber":
    "Er bestaat al een abonnee met dit e-mailadres.",
  "invalid email format": "Dit is geen geldig e-mailadres.",
  "name already exists": "Deze naam bestaat al",
  search: "Zoeken",
  "must NOT be shorter than 1 characters": "Dit veld dient niet leeg te zijn.",
  "must NOT be shorter than 2 characters":
    "Dit veld dient ten minste 2 karakters te bevatten.",
  "must NOT be shorter than 5 characters":
    "Dit veld dient ten minste 5 karakters te bevatten.",
  "must NOT have fewer than 1 items":
    "Er dient ten minste 1 item te zijn gekozen.",
  "should have filter": "Dient een filter te hebben.",
  'must match format "email"': "Dit dient een (geldig) e-mailadres te zijn.",
  'must match format "password"':
    "Een wachtwoord dient uit ten minste 8 karakters te bestaan, waaronder een kleine letter, een hoofdletter, een cijfer en een leesteken.",
  'must match format "uri"':
    "Een valide URL begint met http:// of https:// gevolgd door een domeinnaam.",
  'must match pattern "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"':
    "Een wachtwoord dient uit ten minste 8 karakters te bestaan, waaronder een kleine letter, een hoofdletter, een cijfer en een leesteken.",
  'must match pattern "^(\\*|(([1-5]?[0-9])|(([1-5]?[0-9])-([1-5]?[0-9])))(?:,(([1-5]?[0-9])|(([1-5]?[0-9])-([1-5]?[0-9]))))*)\\s(\\*|((1?[0-9]|2[0-3])|((1?[0-9]|2[0-3])-(1?[0-9]|2[0-3])))(?:,((1?[0-9]|2[0-3])|((1?[0-9]|2[0-3])-(1?[0-9]|2[0-3]))))*)\\s(\\*|(([1-9]|[1-2][0-9]|3[0-1])|(([1-9]|[1-2][0-9]|3[0-1])-([1-9]|[1-2][0-9]|3[0-1])))(?:,(([1-9]|[1-2][0-9]|3[0-1])|(([1-9]|[1-2][0-9]|3[0-1])-([1-9]|[1-2][0-9]|3[0-1]))))*)\\s(\\*|(([1-9]|1[0-2]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([1-9]|1[0-2])-([1-9]|1[0-2]))|((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))))(?:,(([1-9]|1[0-2]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([1-9]|1[0-2])-([1-9]|1[0-2]))|((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))))*)\\s(\\*|(([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)|((([0-6])-([0-6]))|((SUN|MON|TUE|WED|THU|FRI|SAT)-(SUN|MON|TUE|WED|THU|FRI|SAT))))(?:,(([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)|((([0-6])-([0-6]))|((SUN|MON|TUE|WED|THU|FRI|SAT)-(SUN|MON|TUE|WED|THU|FRI|SAT)))))*)(\\s([1-2][0-9][0-9][0-9])(-([1-2][0-9][0-9][0-9]))?)?$"':
    "Moet een geldig herhalingspatroon zijn.",
  'must match pattern "^https?://.*facebook|flickr|foursquare|instagram|linkedin|pinterest|snapchat|tumblr|twitter|vimeo|whatsapp|youtube"':
    "Dit veld zou een geldige URL naar een social media kanaal moeten bevatten",
  Ave: "AVE",
  Count: "Aantal",
  Email: "E-mail",
  Hulptool: "Hulptool",
  LabelAlreadyInFilter: "Label is al actief in filter.",
  Login: "Inloggen",
  MediaType: "Mediumtype",
  PrValue: "PR Waarde",
  Reach: "Bereik",
  Sort: "Sorteren...",
  Submit: "Versturen",
  TEMPLATE_TYPE_ANALYSIS: "Analyse",
  TEMPLATE_TYPE_EMPTY: "Leeg",
  TEMPLATE_TYPE_MONITORING: "Monitoring",
  TEMPLATE_TYPE_READING: "Lezen",
  WIDGET_TYPE_AUTHOR: "Auteurs",
  WIDGET_TYPE_AUTHOR_DESCRIPTION:
    "Bekijk de populairste of best presterende auteurs voor jouw zoekopdracht.",
  WIDGET_TYPE_CATEGORY: "Categorieën",
  WIDGET_TYPE_CATEGORY_DESCRIPTION:
    "Bekijk de populairste of best presterende categorieën voor jouw zoekopdracht.",
  WIDGET_TYPE_HIGHLIGHT: "Highlights",
  WIDGET_TYPE_HIGHLIGHT_DESCRIPTION:
    "Aantal berichten, bereik, AVE en sentiment in één oogopslag.",
  WIDGET_TYPE_LABEL: "Labels",
  WIDGET_TYPE_LABEL_DESCRIPTION:
    "Bekijk de populairste of best presterende labels voor jouw zoekopdracht.",
  WIDGET_TYPE_MEDIAITEM: "Berichten",
  WIDGET_TYPE_MEDIAITEM_DESCRIPTION:
    "Lees en beheer berichten in een helder overzicht.",
  WIDGET_TYPE_MEDIA_TYPE: "Mediumtype",
  WIDGET_TYPE_MEDIA_TYPE_DESCRIPTION:
    "Bekijk resultaten in een bepaalde tijdsperiode.",
  WIDGET_TYPE_SEARCH_TOPIC: "Zoekopdrachten",
  WIDGET_TYPE_SEARCH_TOPIC_DESCRIPTION:
    "Bekijk de populairste of best presterende zoekopdrachten.",
  WIDGET_TYPE_SOURCE: "Bronnen",
  WIDGET_TYPE_SOURCE_DESCRIPTION:
    "Bekijk de populairste of best presterende bronnen voor jouw zoekopdracht.",
  WIDGET_TYPE_TREND: "Trend",
  WIDGET_TYPE_TREND_DESCRIPTION:
    "Bekijk resultaten in een bepaalde tijdsperiode.",
  accountManager: "Accountmanager",
  achtung: "Let op!",
  active: "Actief",
  activeSourcesView_help:
    "Staat een bron er niet tussen die je graag zou willen monitoren? Of heb jij een suggestie voor onze bereikcijfers?",
  activeSourcesView_letUsKnow: "Laat ons het weten via de chat!",
  activeSourcesView_startDescription:
    "Zoek in onze actuele bronnenlijst, om te checken of we de bron die jij wilt monitoren ook door ons gemonitord wordt.",
  add: "Toevoegen",
  addAction: "Voeg toe",
  addAllSubscribersToApp: "Alle abonnees toevoegen",
  addAllSubscribersToEmail: "Alle abonnees toevoegen",
  memberOfEmail: "E-mail nieuwsbrief",
  memberOfApp: "App gebruiker",
  addArticle: "Artikel toevoegen",
  addArticleTitle:
    "Alle artikelen die je vanuit de zoekmachine toevoegt zijn terug te vinden onder de zoekopdracht 'Vanuit zoekmachine'.",
  addEditorialRubric: "Voeg rubriek toe",
  addEmail: "E-mailadres toevoegen",
  addItemsManually: "Handmatig toevoegen",
  addLabels: "Labels toevoegen",
  addSearchResultMultiple:
    "Je staat op het punt om meerdere artikelen toe te voegen aan je media-overzicht. Dit kan van invloed zijn op je abonnementskosten.",
  addSearchResultSingle:
    "Je staat op het punt om één artikel toe te voegen aan je media-overzicht. Dit kan van invloed zijn op je abonnementskosten.",
  addSubscribers: "Voeg abonnees toe",
  addSubscribersConfirmation:
    "Hiermee bevestig ik dat bovenstaande personen toestemming hebben gegeven om abonnee te worden van deze nieuwsbrief.",
  addToEditorial: "Zet in nieuwsbrief",
  added: "Toegevoegd",
  addedToEditorial: "Verzonden in nieuwsbrief",
  addedToEditorialRubric: "Geselecteerd voor rubriek",
  addedToEditorialRubricX: "Geselecteerd voor rubriek ({0})",
  advancedMatchConfig_allOfThese: "Al deze termen:",
  advancedMatchConfig_noneOfThese: "Geen van deze termen:",
  advancedMatchConfig_oneOfThese: "Eén van deze termen:",
  advancedMatchConfig_searchArticlesWith: "Zoek berichten met:",
  alerts: "Notificaties",
  alertsView_startDescription:
    "Stel een persoonlijke notificatie in om per e-mail op de hoogte gebracht te worden wanneer er nieuwe artikelen in je overzicht zijn verschenen",
  alertsView_startTitle: "Nog geen notificaties ingesteld",
  all: "Alles",
  allItems: "Alle items",
  allowDuplicateMediaItemsAcrossSubjects: "Herhaling media-items toestaan",
  allowDuplicateMediaItemsAcrossSubjects_description:
    "Hiermee kan een item bij meerdere rubrieken in de nieuwsbrief voor komen.",
  allowDuplicateMediaItemsAcrossSubjects_help:
    "Wanneer een media-item aan meerdere rubrieken toebehoort, wordt deze standaard aan de eerste rubriek toegekend. \nIndien je het media-item in meerdere rubrieken wil laten terugkomen, zet dan dit vinkje aan.",
  apiDataProvider_fetchingFailed_category:
    "Het ophalen van de categorieën is niet gelukt",
  apiDataProvider_fetchingFailed_group:
    "Het ophalen van de groepen is niet gelukt",
  apiDataProvider_fetchingFailed_invoice:
    "Het ophalen van de facturen is niet gelukt",
  apiDataProvider_fetchingFailed_label:
    "Het ophalen van de labels is niet gelukt",
  apiDataProvider_fetchingFailed_labelAutomator:
    "Het ophalen van de label automators is niet gelukt",
  apiDataProvider_fetchingFailed_newContentNotification:
    "Het ophalen van de nieuw content notificaties is niet gelukt",
  apiDataProvider_fetchingFailed_newsAlert:
    "Het ophalen van de instant notificaties is niet gelukt",
  apiDataProvider_fetchingFailed_rssExport:
    "Het ophalen van de rss export-items is niet gelukt",
  apiDataProvider_fetchingFailed_searchEngineQuery:
    "Het ophalen van de zoekmachine-zoekopdrachten is niet gelukt",
  apiDataProvider_fetchingFailed_searchTopic:
    "Het ophalen van de zoekopdrachten is niet gelukt",
  apiDataProvider_fetchingFailed_subscriber:
    "Het ophalen van de subscribers is niet gelukt",
  apiDataProvider_fetchingFailed_benchmark:
    "Het ophalen van de analyses is niet gelukt",
  apiDataProvider_firstDashboardWillBeCreated:
    "Uw eerste dashboard wordt automatisch aangemaakt...",
  apply: "Pas toe",
  applyEditorials: "Ken nieuwsbrieven toe",
  applyEditorialsPlaceholder: "Selecteer nieuwsbrieven",
  approved: "Toegewezen",
  areYouSure: "Weet je het zeker?",
  areYouSureDeleteUser:
    "Weet je het zeker? Hiermee wordt het account van {0} opgeheven. Dit kan niet meer worden teruggedraaid.",
  areYouSureUnsavedChanges:
    "Weet je zeker dat je door wil gaan zonder op te slaan?",
  areYourSureDeleteEditorialEditorialRubricMediaItems:
    "Wanneer je de selectie verwijdert, zullen alle artikelen voor je aankomende editie worden verwijderd en kun je starten met het maken van een nieuwe selectie. Weet je zeker dat je de selectie wil verwijderen?",
  author: "Auteur",
  authorAsyncPopoverInitialLabel: "Zoek een auteur...",
  authors: "Auteurs",
  authorsX: "Auteurs ({0})",
  automaticEditorialHasRubricsWithoutFilters:
    "Er zijn rubrieken die geen filter bevatten. Dit betekent dat er uit die rubrieken niet automatisch artikelen worden klaargezet voor je nieuwsbrief. Weet je zeker dat je verder wil?",
  automaticEditorialRubricWithoutFilter_help:
    "Let op! Je hebt geen filter ingesteld voor deze rubriek. Dit betekent dat er niet automatisch berichten worden klaargezet voor deze rubriek.",
  automaticSearchEngineQueryManager:
    "Optie zoekresultaten automatisch toevoegen (agent)",
  automatically: "Automatisch",
  automaticallyAddResults: "Resultaten automatisch toevoegen",
  automaticallySendEditorial: "Automatisch versturen",
  ave: "AVE",
  aveAsc: "AVE oplopend",
  aveDesc: "AVE aflopend",
  aveMeaning: "Advertising Value Equivalency",
  backOfficer: "Backofficer",
  backgroundTask_error: "Fout bij achtergrondtaak",
  backgroundTask_finished: "Achtergrondtaak afgerond",
  backgroundTask_running: "Achtergrondtaak actief",
  backgroundTask_waiting: "Bezig met starten...",
  buy: "Toevoegen",
  cancel: "Annuleer",
  categories: "Categorieën",
  category: "Categorie",
  categoryAddedToFilter: "Categorie {0} is toegevoegd",
  chooseTemplate: "Template kiezen",
  circulation: "Oplage",
  clickCount: "Aantal keer geopend",
  close: "Sluiten",
  closeAdvancedSearchEngineMatch: "Hulptool sluiten",
  closeAndDontShowAgain: "Sluiten en niet meer tonen",
  col_area: "Verspreidingsgebied",
  col_author: "Auteur",
  col_ave: "AVE",
  col_category: "Categorie",
  col_circulation: "Oplage",
  col_insertDate: "Datum toegevoegd",
  col_isFavourite: "Favoriet",
  col_isInEditorial: "In nieuwsbrief",
  col_isRead: "Gelezen",
  col_labels: "Labels",
  col_mediaType: "Mediumtype",
  col_page: "Pagina",
  col_prValue: "PR-waarde",
  col_publicationDate: "Publicatiedatum",
  col_publicationIssue: "Uitgave",
  col_reach: "Bereik",
  col_searchTopics: "Zoekopdrachten",
  col_sentiment: "Sentiment",
  col_source: "Bron",
  col_title: "Titel",
  col_url: "Link",
  col_wordCount: "Aantal woorden",
  columns: "Kolommen",
  combine: "Combineren",
  components_MediaItem_LinkedArticlesAve: "AVE",
  components_MediaItem_LinkedArticlesCirculation: "Oplage",
  components_MediaItem_LinkedArticlesDate: "Datum",
  components_MediaItem_LinkedArticlesFrequency: "Frequentie",
  components_MediaItem_LinkedArticlesName: "Naam",
  components_MediaItem_LinkedArticlesPage: "Pagina",
  components_MediaItem_LinkedArticlesRegion: "Regio",
  components_MediaItem_doubleArticle: "doorplaatsing",
  components_MediaItem_doubleArticles: "doorplaatsingen",
  components_MediaItem_hide: "Verberg",
  components_MediaItem_hideLog: "Verberg log van dit item",
  components_MediaItem_issueName: "Editie",
  components_MediaItem_log_noResults:
    "Er is momenteel nog geen log van dit item.",
  components_MediaItem_show: "Toon",
  components_MediaItem_showLog: "Bekijk log van dit item",
  components_MediaItem_source: "Publicatie",
  copied: "Gekopieerd",
  copyLink: "Kopieer link",
  count: "Aantal",
  cover: "Cover",
  createAlert: "Nieuwe notificatie instellen",
  createAndSend: "Opmaken & verzenden",
  createDashboard: "Maak dashboard",
  createDashboardTitle: "Dashboard aanmaken",
  createFirstEditorial: "Stel je eerste nieuwsbrief in",
  createModal_noArticles_selected:
    "Er zijn nog geen artikelen geselecteerd voor deze editie",
  createOption: 'Label toevoegen: "{0}"',
  createPassword: "Wachtwoord aanmaken",
  createPasswordBody: "Maak éénmalig een wachtwoord aan om in te loggen",
  createSubscribers: "Maak abonnees aan",
  createdAt: "Gemaakt op",
  creditApproved: "Toegewezen",
  creditRejected: "Afgewezen",
  creditRequestDeleteTypeCreditRequest:
    "Selectie voldoet NIET aan de zoekvraag (verplicht reden opgeven)",
  creditRequestDeleteTypeDeleteRequest:
    "Selectie voldoet WEL aan de zoekvraag (alleen verwijderen)",
  creditRequestDeleteTypeIsRequired: "Kies een reden",
  creditRequestMessageIsRequired:
    "Het is belangrijk dat wij weten waarom de selectie niet voldoet aan de zoekopdracht. Dit om het in de toekomst te voorkomen.",
  cron: "Herhaling",
  cronEditorInputLabel_days: "Dag(en)",
  cronEditorInputLabel_frequency: "Frequentie van versturen",
  cronEditorInputLabel_hours: "Vanaf",
  cron_label_friday: "Vr",
  cron_label_monday: "Ma",
  cron_label_saturday: "Za",
  cron_label_sunday: "Zo",
  cron_label_thursday: "Do",
  cron_label_tuesday: "Di",
  cron_label_wednesday: "Wo",
  currentPassword: "Oud wachtwoord",
  currentlyInDevelopment:
    "Momenteel in ontwikkeling. Binnenkort te gebruiken als filter",
  customer: "Klant",
  customerLinkIds: "Klanten",
  customerName: "Klantnaam",
  customerNo: "Klantnummer",
  customerPrValue: "PR-waarde",
  customerSentiment: "Sentiment",
  customers: "Klanten",
  daily: "Dagelijks",
  dashboard: "Dashboard",
  dashboardAccessTokenExpiredBody:
    "Er is zojuist een e-mail verstuurt naar {0} met een bijgewerkte link. Gebruik deze link om toegang te krijgen tot je dashboard",
  dashboardAccessTokenExpiredTitle: "De link is verlopen",
  dashboardAccessTokenUnknownBody: "Neem contact op met je beheerder.",
  dashboardAccessTokenUnknownTitle: "Geen toegang.",
  dashboardCanBeSavedWhenDirty:
    "Een dashboard kan worden opgeslagen na een wijziging hiervan",
  dashboardShareRssDescription: "Exporteer als RSS-feed.",
  dashboardShareRssTitle: "RSS",
  dashboardTemplateDialog_chooseATemplate: "Kies een template in dit menu",
  dashboardTemplateDialog_previewAndChoose:
    "Preview en kies hiernaast een template",
  dashboardTemplateDialog_templateDescription:
    "Dashboards zijn altijd nog aan te passen.\nJe kunt widgets toevoegen, verwijderen of anders indelen.",
  dashboardTemplateDialog_templateType_analyse_description:
    "Deze template is geschikt voor het analyseren van je data. Met grafiekwidgets en direct inzichtelijke cijfers heb je inzicht over al je media-items.",
  dashboardTemplateDialog_templateType_empty_description:
    "Start met een leeg dashboard. Met enkele muisklikken kun je het dashboard naar eigen wens indelen.",
  dashboardTemplateDialog_templateType_monitoring_description:
    "Deze template heeft alle widgets die je nodig hebt voor een complete monitoring.",
  dashboardTemplateDialog_templateType_read_description:
    "Een template geschikt om zoveel mogelijk media-items in één oogopslag te kunnen zien.",
  dashboardTemplateDialog_withThisButtonYouCanCreateADashboard:
    "Via deze knop kunt u dit sjabloon gebruiken om een nieuw dashboard aan te maken.",
  dashboardTemplatesButtonConfirm:
    "Je staat op het punt je dashboard aan te passen naar een ander template. Hiermee gaan je huidige instellingen van je widgets verloren. Weet je zeker dat je verder wil gaan?",
  dashboardView_addWidget: "Voeg widget toe",
  dashboardView_emptyDashboard: "Dit dashboard is leeg",
  dashboardView_emptyDashboardAlt: "Dit dashboard heeft geen widgets",
  dashboardView_emptyDashboardDescription:
    "Voeg widgets toe om je data te tonen.",
  dashboards: "Dashboards",
  date: "Datum",
  defaultIntroText: "Standaard introductietekst",
  defaultSenderName: "Naam afzender",
  defaultSubject: "Standaard onderwerp",
  delete: "Verwijderen",
  deleteConfirmation: "Weet je zeker dat je dit wilt verwijderen?",
  deleteEditorial: "Instellingen + edities verwijderen",
  deleteEditorialEditorialRubricMediaItems: "Verwijder selectie",
  deleteRequestStatus: "Creditatie",
  deleteRubric: "Rubriek verwijderen",
  deleteSuccessful: "Het verwijderen is gelukt.",
  saveSuccessful: "Het opslaan is gelukt.",
  deleteUser: "Gebruiker verwijderen",
  deleted: "Alleen verwijderd",
  deletedBy: "Verwijderd door",
  description: "Omschrijving",
  displayName: "Naam",
  distributionProvinces: "Provincies",
  doYouWantToRecalculatePrValue: "Wilt u ook de PR-waarde herberekenen?",
  doubleArticles: "Doorplaatsingen",
  download: "Download",
  downloadAsExcel: "Download als Excel",
  downloadAsPdf: "Download als PDF",
  downloadNow: "Nu downloaden",
  drenthe: "Drenthe",
  edit: "Bewerk",
  editAlert: "Notificatie bewerken",
  editEditorialConfig: "Nieuwsbrief sjabloon bewerken",
  editFilter: "Bewerk filter",
  editingFilter: "Filter bewerken",
  editorial: "Nieuwsbrief",
  editorialConfigView__bodyFont: "Body font",
  editorialConfigView__editorialIssueTitle: "Nieuwsbrief-editie titel",
  editorialConfigView__editorialIssueTitleHelper:
    "Deze klantnaam komt boven de nieuwsbrief te staan.",
  editorialConfigView__facebook: "Facebook URL",
  editorialConfigView__footer: "Regel footer",
  editorialConfigView__footerBackground: "Footer achtergrondkleur",
  editorialConfigView__footerColor: "Footer tekst kleur",
  editorialConfigView__footerColumn: "Footer kolom tekst",
  editorialConfigView__footerColumnHeading: "Footer kolom Header",
  editorialConfigView__footerColumnLinkLabel: "Footer kolom link tekst",
  editorialConfigView__footerColumnLinkUrl: "Footer kolom link URL",
  editorialConfigView__footerLink: "Footer link",
  editorialConfigView__footerLinkColor: "Footer links kleur",
  editorialConfigView__footerLinkUnderline: "Footer links onderstrepen",
  editorialConfigView__header__body: "Body",
  editorialConfigView__header__common: "Algemeen",
  editorialConfigView__header__footer: "Footer",
  editorialConfigView__header__headers: "Headers",
  editorialConfigView__header__show_per_article: "Toon per bericht",
  editorialConfigView__header__socials: "Socials",
  editorialConfigView__heading1: "Headers Kleur Rubrieken",
  editorialConfigView__heading2: "Headers Kleur Artikelen",
  editorialConfigView__heading3: "Footer Kleur Kop",
  editorialConfigView__headingContent: "Content",
  editorialConfigView__headingFont: "Headers Font",
  editorialConfigView__heroImageUrl: "Hero afbeelding",
  editorialConfigView__link: "Link kleur",
  editorialConfigView__linkUnderline: "Links onderstrepen",
  editorialConfigView__menuBackground: "Menu achtergrondkleur",
  editorialConfigView__menuColor: "Menu tekst kleur",
  editorialConfigView__metadata: "Metadata kleur",
  editorialConfigView__showAve: "Toon AVE",
  editorialConfigView__showCirculation: "Toon oplage",
  editorialConfigView__showReach: "Toon bereik",
  editorialConfigView__showSingleRubricName:
    "Headerrubriek tonen indien 1 rubriek aanwezig in nieuwsbrief?",
  editorialConfigView__socialMedia1: "Social Media 1",
  editorialConfigView__socialMedia2: "Social Media 2",
  editorialConfigView__socialMedia3: "Social Media 3",
  editorialConfigView__socialMedia4: "Social Media 4",
  editorialConfigView__socialMedia5: "Social Media 5",
  editorialConfigView__socialMedia6: "Social Media 6",
  editorialConfigView__socialMedia7: "Social Media 7",
  editorialConfigView__socialMedia8: "Social Media 8",
  editorialConfigView__templateType: "Sjabloon type",
  editorialConfigView__textColor: "Tekst kleur",
  editorialConfigView__twitter: "Twitter URL",
  editorialConfigView__youTube: "YouTube URL",
  editorialDeleteConfirmation:
    "Weet je zeker dat je deze nieuwsbrief wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.",
  editorialIssue: "Nieuwsbrief-editie",
  editorialIssueNoEditions: "Nog geen edities",
  editorialIssueNoMediaitems:
    "Wacht totdat je filters artikelen inladen of voeg handmatig artikelen toe.",
  editorialIssuePreview: "Nieuwsbrief-editie voorbeeld",
  editorialIssueResend: "Nieuwsbrief-editie nasturen",
  editorialIssueResetMessagesAfterDelete:
    "Berichten uit deze editie opnieuw klaarzetten voor volgende nieuwsbrief",
  editorialIssueSchedule: "Nieuwsbrief-editie plannen",
  editorialIssueStats: "Nieuwsbrief-editie statistieken",
  editorialIssueSuccessfullyResend: "Nieuwsbrief-editie succesvol nagestuurd.",
  editorialIssueTest: "Nieuwsbrief-editie testen",
  editorialIssueTestDescription:
    "Je kunt een test naar jezelf versturen op een door jou te bepalen e-mailadres.",
  editorialRubric: "Rubriek",
  editorialRubricFilter_help:
    "Wil je automatisch media-items klaarzetten voor deze rubriek? Stel dan een filter in. Wil je handmatig artikelen toekennen voor deze rubriek? Dan hoef je geen filter in te stellen.",
  editorialRubricNameIsAlreadyUsed:
    "Deze naam wordt al gebruikt binnen deze nieuwsbrief. Kies een andere naam.",
  editorialRubricNamePlaceholder: "Voer rubrieksnaam in",
  editorialRubrics: "Rubrieken",
  editorialRubricsHelp:
    "Rubrieken worden gevuld met artikelen die vanaf nu nieuw binnenkomen. Mochten er artikelen nodig zijn voor de nieuwsbrief die al in media·web staan, dan kunnen deze handmatig vanuit de dashboards worden toegevoegd.",
  editorialTitle: "Nieuwsbrief titel",
  editorialTour_1_1:
    "Je staat op het punt een nieuwsbrief in te stellen. Om je op weg te helpen, leggen we graag in een aantal stappen uit hoe je dit kan doen.",
  editorialTour_1_2:
    "Aan het daadwerkelijk versturen van een nieuwsbrief zijn kosten verbonden. Mocht je hier niet van op de hoogte zijn, neem dan contact op met je accountmanager.",
  editorialTour_2_1: "Overzicht met de juiste titel",
  editorialTour_2_2:
    "De opgegeven titel is alleen te zien door degene die de nieuwsbrieven maakt.",
  editorialTour_3_1: "Bepaal afzender",
  editorialTour_3_2:
    "Geef een naam op vanuit wie je de nieuwsbrief stuurt (bijv. Afdeling communicatie, de naam van de organisatie).",
  editorialTour_4_1: "Herkenbaarheid door consistentie",
  editorialTour_4_2:
    "Zorg voor herkenbaarheid in de mailbox door een gestandaardiseerd onderwerp. Per editie kan deze nog aangepast worden (door bijv. een editienummer toe te voegen aan je onderwerp).",
  editorialTour_5_1: "Bepaal je structuur",
  editorialTour_5_2:
    "Cluster je berichten voor je nieuwsbrief op onderwerp, mediumtype of sentiment. Vul je rubrieken automatisch aan de hand van een filter, of stel geen filter in en doe de selectie handmatig.",
  editorialTour_6_1: "Kies je abonnees",
  editorialTour_6_2:
    'Kies uit je bestaande abonnees, of voeg nieuwe toe. Indien je een lijst aan nieuwe abonnees wil toevoegen, ga dan naar "Abonnees" in je navigatie.',
  editorialTour_7_1: "Automatisch je nieuwsbrieven versturen?",
  editorialTour_7_2:
    "Dat kan! Het systeem verstuurt dan automatisch de berichten die nieuw zijn toegevoegd sinds de laatst verzonden editie. Hiervoor moet je wel een filter hebben ingesteld bij de rubrieken.",
  editorialTour_8_1: "Nieuwsbrief in stijl van jouw organisatie?",
  editorialTour_8_2:
    "Neem dan contact op met je accountmanager om de mogelijkheden te bespreken",
  editorials: "Nieuwsbrieven",
  email: "E-mail",
  emailAddress: "E-mailadres",
  emailSent: "De e-mail is verzonden.",
  excludeFromResults: "Weglaten uit resultaten",
  export: "Export",
  exportAction: "Exporteer",
  false: "Nee",
  favorite: "Favoriet",
  fetchPreview: "Voorbeeld ophalen",
  filter: "Filter",
  filterDateChanged: "Datum in filter is aangepast.",
  filterDeleteConfirmation: "Weet je zeker dat je dit filter wilt verwijderen?",
  filterEditConfirmation: "Weet je zeker dat je dit filter wilt bewerken?",
  filterHasBeenUpdated: "Filter is aangepast.",
  filterPdfJob: "Verzamel PDF",
  filterQuery: "Zoekopdracht",
  filterXlsxJob: "Excel export",
  filterXlsxJobLimited: "Export export (beperkt)",
  filter_podcast: "Podcast",
  filter_print: "Print",
  filter_radio: "Radio",
  filter_rss: "RSS",
  filter_social: "Social",
  filter_tv: "TV",
  filter_web: "Web",
  flevoland: "Flevoland",
  forgotOldPassword: "Oud wachtwoord vergeten?",
  forgotPassword: "Wachtwoord vergeten?",
  formSaved: "Formulier opgeslagen",
  fragment: "Fragment",
  friesland: "Friesland",
  gelderland: "Gelderland",
  generateExcelExport: "Excel export opstellen",
  generateXls: "Excel bestand opstellen",
  genericErrorMessage: "Er ging iets mis, probeer het later nog eens",
  genericErrorMessage_short: "Er ging iets mis",
  goToSend: "Naar verzenden",
  groningen: "Groningen",
  group: "Groep",
  groupId: "Groep",
  groupIds: "Groepen",
  groupName: "Groepsnaam",
  groups: "Groepen",
  headline: "Titel",
  helpOptions: "Hulp opties",
  hideImages: "Verberg afbeeldingen",
  highlightWidget_ave: "AVE",
  highlightWidget_aveDescription:
    "Vergeleken met de periode {0} t/m {1}. AVE: {2}",
  highlightWidget_pr: "PR-waarde",
  highlightWidget_prDescription:
    "Vergeleken met de periode {0} t/m {1}. PR-waarde: {2}",
  highlightWidget_messages: "Berichten",
  highlightWidget_messagesDescription:
    "Vergeleken met de periode {0} t/m {1}. Berichten: {2}",
  highlightWidget_reach: "Bereik",
  highlightWidget_reachDescription:
    "Vergeleken met de periode {0} t/m {1}. Bereik: {2}",
  highlightWidget_sentiment: "Sentiment",
  highlightWidget_sentimentDescription: "{0} positief, {1} negatief ({2}%)",
  highlightWidget_sentimentDescriptionWithDates:
    "Vergeleken met de periode {0} t/m {1}: {2} positief, {3} negatief ({4}%)",
  impersonate: "Switch",
  importCsv: "Importeer csv",
  inactiveCustomerTooltip: "Deze klant is niet actief.",
  inactiveSearchTopics_hide: "Verberg inactieve zoekopdrachten",
  inactiveSearchTopics_show: "Toon inactieve zoekopdrachten",
  inholland: "Inholland",
  insertDate: "Datum toegevoegd",
  insertDateAsc: "Oudste toegevoegd eerst",
  insertDateDesc: "Nieuwste toegevoegd eerst",
  insertDateExplanation:
    "Moment waarop artikel in je media-overzicht verschenen is.",
  instant: "Instant",
  instantDescription:
    "Bij een instant notificatie krijg je direct een e-mail voor ieder nieuw bericht wat binnenkomt. Dit is bijvoorbeeld handig indien er sprake is van een crisis en je direct op de hoogte moet worden gesteld van nieuwe berichtgeving.",
  insufficientDataForInterval:
    "Onvoldoende data voor interval. Pas datumfilter of de interval van de widget aan.",
  insufficientDataForWidget:
    "Binnen filter geen data beschikbaar voor deze widget",
  interval_auto: "Auto",
  interval_day: "Dag",
  interval_month: "Maand",
  interval_quarter: "Kwartaal",
  interval_week: "Week",
  interval_year: "Jaar",
  intro: "Introductietekst",
  introduction: "Introductie",
  invalidData: "De gegevens zijn onjuist! Controleer jouw gegevens.",
  invalidEmailAddresses: "Ongeldige e-mailadressen",
  invalidToken: "Deze link is verlopen :(",
  invalidTokenDescription:
    "De link is inmiddels verlopen. Je hebt een nieuwe link nodig. Klik hieronder om een nieuwe resetlink aan te vragen.",
  invoice: "Facturatie",
  invoiceDate: "Datum",
  invoiceNo: "Factuurnummer",
  isAutomatic: "Resultaten automatisch toevoegen",
  isFavourite: "Favoriet",
  isOwned: "Aangekocht",
  isOwned_full: "Is aangekocht",
  isRead: "Gelezen",
  label: "Label",
  labelAddedToFilter: "Label {0} is toegevoegd.",
  labelAutomator: "Label automator",
  labelAutomatorDeleteMessage:
    "Weet je het zeker?\nJe staat op het punt om je label automator te verwijderen. Dit stopt de automatische toewijzing van dit label. Dit label blijft bestaan, en zichtbaar bij de media-items waar dit label al is toegekend",
  labelCanNotBeDeleted:
    "Het label kon niet worden verwijderd: het label is nog gekoppeld aan een artikel.",
  labelId: "Label",
  labelIds: "Labels",
  labelToAssign: "Label dat wordt toegepast",
  labels: "Labels",
  language_EN: "EN",
  language_NL: "NL",
  lastIndexDate: "Laatst opgehaald op",
  lastMonth: "Afgelopen 30 dagen",
  lastQuarter: "Afgelopen 90 dagen",
  lastWeek: "Afgelopen 7 dagen",
  layout: "Opmaak",
  limburg: "Limburg",
  loading: "Bezig met laden...",
  log: "Log",
  loggedInAs: "Ingelogd als:",
  loginFailed: "Inloggen mislukt",
  loginForm_title: "Login media·web",
  loginForm_usernameAndPasswordCombinationInvalid:
    "De combinatie gebruikersnaam / wachtwoord is niet bij ons bekend. Ben je je wachtwoord vergeten? Klik op de link hieronder.",
  logoFileId: "Logo",
  lostPasswordBody:
    "Geen probleem! Het overkomt de beste. Vul hieronder je e-mailadres in om een resetlink te ontvangen.",
  lostPasswordTitle: "Wachtwoord vergeten",
  makeUp: "Opmaken",
  manager: "Beheerder",
  manualAdd: "Vanuit zoekmachine",
  matchTextPlaceholder: "Voer zoekopdracht in",
  mediaItemAddLabelIds: "Koppel label(s) aan berichten",
  mediaItemDeleteExplanationPlaceholder:
    "Omschrijf waarom de artikelen niet voldoen",
  mediaItemDeleteHint:
    "Bericht verwijderen uit overzicht;\nBericht verwijderen en melden als niet correct.",
  mediaItemDeleteJob: "Berichten verwijderen",
  mediaItemDeleteMessage:
    "Je staat op het punt één bericht te verwijderen. Dit kan niet ongedaan worden gemaakt.",
  mediaItemPurchaseOffer:
    "Je hebt dit artikel nog niet aangeschaft. Indien je dit artikel wil inzien kan je dit artikel aanschaffen.\n\nWilt u dit bericht aanschaffen?",
  mediaItemSetFavourite: "Markeer berichten als favoriet",
  mediaItemSetRead: "Markeer berichten als gelezen",
  mediaItemSetSentimentAndPrValue:
    "Sentiment en PR-waarde van berichten aanpassen",
  mediaItemSetSentimentOnly: "Sentiment berichten aanpassen",
  mediaItemSetUnfavourite: "Markeer berichten als niet favoriet",
  mediaItemSetUnread: "Markeer berichten als ongelezen",
  mediaItemWidget_title: "{0} Berichten",
  mediaItemsDeleteMessage:
    "Je staat op het punt {0} berichten te verwijderen. Dit kan niet ongedaan worden gemaakt.",
  mediaType: "Type",
  mediaTypeAddedToFilter: "Mediumtype {0} is toegevoegd.",
  mediaType_podcast: "Podcast",
  mediaType_print: "Print",
  mediaType_radio: "Radio",
  mediaType_rss: "RSS",
  mediaType_social: "Social",
  mediaType_tv: "TV",
  mediaType_web: "Web",
  memberOf: "Lid van",
  monthly: "Maandelijks",
  moreOptions: "Meer opties",
  myMedia: "Mijn media",
  myMediaTemplatesButtonConfirm:
    'Je staat op het punt je "Mijn media" aan te passen naar een ander template. Hiermee gaan je huidige instellingen van je widgets verloren. Weet je zeker dat je verder wil gaan?',
  myMediaTourBody11:
    "Via de reddingsboei rechtsbeneden kun je een chat starten, de rondleiding opnieuw openen of de <a href='https://support.mediaweb.eu/' target='_blank'>supportpagina</a> bezoeken. Succes!",
  myMediaTourBody1:
    "Jouw persoonlijke landingspagina binnen media·web die je naar eigen wens kan indelen aan de hand van widgets.",
  myMediaTourBody8:
    'Voer een naam in en sla je indeling + filter op als dashboard. De indeling van "Mijn media" zal automatisch opgeslagen worden.',
  myMediaTourHeading11: "Heb je verder nog vragen?",
  myMediaTourHeading1: 'Welkom in "Mijn media"',
  myMediaTourHeading8: "Opslaan als dashboard",
  name: "Naam",
  namelessDashboard: "Dashboard",
  namelessEditorial: "Naamloze nieuwsbrief",
  namelessBenchmark: "Naamloze analyse",
  namelessSearchEngineQuery: "Naamloze zoekmachine opdracht",
  navigationTourNotAvailable: "Rondleiding op deze pagina niet beschikbaar.",
  navigation_account: "Account",
  navigation_admin: "Beheer",
  navigation_admin_content: "Content exports",
  navigation_admin_customer: "Weergave",
  navigation_admin_deletedMediaItems: "Verwijderde items",
  navigation_admin_invoices: "Facturen",
  navigation_admin_labels: "Labels",
  navigation_admin_mediaItemPurchases: "Toegevoegde items",
  navigation_admin_rss: "Rss",
  navigation_admin_searchTopics: "Zoekopdrachten",
  navigation_admin_source: "Bronnen",
  navigation_admin_users: "Gebruikers",
  navigation_alerts: "Notificaties",
  navigation_customers: "Beheer klanten",
  navigation_dashboards: "Dashboards",
  navigation_downloadTranslations: "Download vertalingen",
  navigation_editorials: "Nieuwsbrief",
  navigation_editorials_issues: "Verzonden edities",
  navigation_editorials_overview: "Nieuwsbrieven",
  navigation_editorials_subscribers: "Abonnees",
  navigation_groups: "Beheer groepen",
  navigation_help: "Help",
  navigation_logout: "Uitloggen",
  navigation_search: "Zoekmachine",
  navigation_analysis: "Analyse",
  navigation_tour: "Rondleiding",
  navigation_users: "Beheer gebruikers",
  negative: "Negatief",
  neutral: "Neutraal",
  newAlert: "Nieuwe notificatie",
  newAlertWithThisFilterAlreadyExists:
    "Er bestaat al een notificatie met deze instellingen!",
  newContentExport: "Nieuwe content export",
  newContentNotification: "Periodieke notificatie",
  newDashboard: "Nieuw dashboard",
  newEditorial: "Nieuwe nieuwsbrief",
  newFilter: "Nieuw filter",
  newGroup: "Nieuwe groep",
  newLabelAutomator: "Nieuwe label automator",
  newPassword: "Nieuw wachtwoord",
  newPasswordRepeat: "Nieuw wachtwoord herhalen",
  newPasswordRepeatIsntEqualToNewPassword:
    "Dient hetzelfde te zijn als het nieuwe wachtwoord",
  newPasswordShouldBeNew:
    "Je kan niet hetzelfde wachtwoord kiezen als je huidige wachtwoord. Voer een nieuw wachtwoord in.",
  newRssExport: "Nieuwe RSS export",
  newSubscriber: "Nieuwe abonnee",
  newfirst: "Publicatiedatum aflopend",
  newsAlert: "Instant notificatie",
  newsAlertDescriptionWithDates:
    "Je krijgt per nieuw verschenen bericht direct een mail van {0} tot {1}.",
  newsAlertOptionFilterDescription:
    "Het instellen van een filter is niet verplicht. Wanneer geen filter is ingesteld, zullen alle berichten die nieuw verschenen zijn in media·web getoond worden.",
  newsAlertPeriodError:
    "Stel een begin - en einddatum in met een maximale periode van 3 maanden.",
  newsAlertWithDates: "Direct van {0} tot {1}",
  nextEdition: "Volgende editie: {0}",
  noActiveEditorials: "Je hebt nog geen nieuwsbrieven ingesteld.",
  noDoubles: "excl. doorplaatsingen",
  noEditorialsFound: "Je hebt nog geen nieuwsbrieven.",
  noEditorialsFound_description:
    "Een nieuwsbrief is nodig om nieuwsbrieven te versturen. Maak er hieronder één aan.",
  noLabelAutomatorsFound: "Geen label automators gevonden.",
  noLabelsFound: "Geen labels gevonden.",
  noLimit: "Geen beperking",
  noResultsText: "Geen resultaten gevonden.",
  none: "Geen",
  noordBrabant: "Noord-Brabant",
  noordHolland: "Noord-Holland",
  notifications: "Notificaties",
  notificationsFor: "Notificaties ingesteld voor",
  null: "niet bekend",
  oldfirst: "Publicatiedatum oplopend",
  oneMomentPlease: "Een ogenblik geduld...",
  open: "Open",
  openAsPage: "Open als pagina",
  openManual: "Open handleiding",
  openedPercentage: "Geopend: {0}%",
  optionalAddLabels: "Optioneel: labels toevoegen",
  overijssel: "Overijssel",
  page: "Volledige pagina",
  password: "Wachtwoord",
  passwordExpiredDescription:
    "In verband met de aanscherping van de veiligheidseisen, vragen we je een nieuw wachtwoord aan te maken.",
  passwordExpiredTitle: "Wachtwoord verlopen",
  pdf: "PDF",
  pending: "Wordt verwerkt",
  period: "Periode",
  periodic: "Periodiek",
  periodicDescription:
    "Stel zelf in hoe vaak per dag, per week of per maand je op de hoogte wil worden gesteld van de nieuwe berichten die voor jou zijn verschenen.",
  plusEditorial: "+ Nieuwsbrief",
  plusLabel: "+ Label",
  positive: "Positief",
  poweredByLexisnexis: "Aangeleverd door LexisNexis.",
  prValue: "PR-waarde",
  prValueAsc: "PR-waarde oplopend",
  prValueDesc: "PR-waarde aflopend",
  prValueHasBeenSaved: "PR-waarde is opgeslagen",
  preview: "Voorbeeld",
  price: "Bedrag",
  profileUpdateSuccessfull:
    "Account instellingen zijn opgeslagen met laatste wijzigingen",
  provideASearchQueryFirst: "Geef eerst een zoekopdracht!",
  publicationDate: "Publicatiedatum",
  publicationDateAsc: "Publicatiedatum oplopend",
  publicationDateDesc: "Publicatiedatum aflopend",
  publicationDateExplanation: "Datum van publicatie",
  publicationName: "Publicatie",
  publicationalphabetical: "Publicatie A-Z",
  purchaseDatetime: "Aankoopdatum",
  purchaseStatus: "Aankoopstatus",
  reach: "Bereik",
  reachAsc: "Bereik oplopend",
  reachDesc: "Bereik aflopend",
  read: "Gelezen",
  readyDatetime: "Aankoop gereed op",
  reason: "Reden",
  regular: "Normaal",
  rejectReason: "Reden afwijzing",
  rejected: "Afgewezen",
  relevance: "Relevantie",
  remainingAuthors: "Overige auteurs",
  remainingCategories: "Overige categorieën",
  remainingLabels: "Overige labels",
  remainingSearchTopics: "Overige zoekopdrachten",
  remainingSources: "Overige bronnen",
  remove: "Verwijderen",
  removeLabels: "Labels verwijderen",
  resend: "Nasturen",
  resetAgain: "Opnieuw resetten",
  resetOriginal: "Origineel herstellen",
  resetPassword: "Wachtwoord instellen",
  restricted: "Beperkt",
  role: "Rol",
  roles: "Rollen",
  rss: "RSS",
  save: "Opslaan",
  saveAs: "Opslaan als...",
  saveAsDashboard: "Opslaan als dashboard",
  saveDashboardAs: "Dashboard opslaan als...",
  saveSearchEngineQueryAs: "Zoekopdracht opslaan als...",
  saving: "Aan het opslaan...",
  scheduleEditorialIssue: "Nieuwsbrief-editie inplannen",
  scheduleSending: "Verzending inplannen",
  scheduledFor: "Ingepland voor: {0}",
  screenshot: "Screenshot",
  searchAuthor: '"{0}" als auteur zoeken',
  searchByCustomer: "Zoekopdracht vanuit zoekmachine",
  searchByMig: "Zoekopdracht via Media Info Groep",
  searchEngineQueryCanBeSavedWhenDirty:
    "Een zoekopdracht kan worden opgeslagen nadat deze is uitgevoerd.",
  searchEngineView_startDescription:
    "Voer een zoekopdracht in zodat we de juiste artikelen uit onze database kunnen halen.",
  searchInText: '"{0}" in tekst zoeken',
  searchLabel: "Zoek label...",
  searchQueries: "Zoekopdrachten",
  searchQuery: "Zoekopdracht",
  searchSource: '"{0}" als bron zoeken',
  searchTopic: "Zoekopdracht",
  searchTopicAddedToFilter: "Zoekopdracht {0} is toegevoegd",
  searchTopicIds: "Zoekopdrachten",
  searchTopicXlsxJob: "Exporteer zoekopdrachten",
  searchTopics: "Zoekopdrachten",
  searchTopicsX: "Zoekopdrachten ({0})",
  searchType: "Type",
  searchWithinResults: "Zoeken binnen resultaten",
  selectAll: "Alles selecteren",
  selectAllResults: "Alle resultaten toevoegen ({0})",
  selectWithWord: 'Alles met woord toevoegen "{0}" ({1})',
  send: "Verzenden",
  sendDirect: "Direct verzenden",
  sendEditorialIssue: "Nieuwsbrief-editie versturen",
  sendEditorialIssueTest: "Nieuwsbrief-editie test versturen",
  sendScheduled: "Gepland versturen",
  sendTestMail: "Verzend testmail",
  senderName: "Afzender",
  sentOn: "Verstuurd: {0}",
  sentiment0: "Niet bepaald",
  sentiment1: "Negatief",
  sentiment2: "Neutraal",
  sentiment3: "Positief",
  sentiment: "Sentiment",
  sentimentUndefined: "Geen sentiment",
  sentimentundefined: "Onbekend",
  setFilter: "Stel filter in",
  setNewPassword: "Stel nieuw wachtwoord in",
  setValue: "Stel in",
  settingFilter: "Filter instellen",
  share: "Delen",
  showAveAs: "Toon AVE als",
  showAveAs_cumulative: "Doorplaatsingen als cumulatief",
  showAveAs_linkedArticles: "Doorplaatsingen per regel",
  showAveAs_original: "Doorplaatsingen niet meegenomen",
  showPdfArea: "Toon oppervlakte in PDF",
  showPdfAve: "Toon AVE in PDF",
  showPdfCirculation: "Toon oplage in PDF",
  showPdfCumulativeAve: "Toon AVE (cumulatief) in PDF",
  showPdfCumulativeCirculation: "Toon oplage (cumulatief) in PDF",
  showPdfCumulativeReach: "Toon bereik (cumulatief) in PDF",
  showPdfFrequency: "Toon frequentie  in PDF",
  showPdfPageNumber: "Toon paginanummer in PDF",
  showPdfPublication: "Toon publicatie in PDF",
  showPdfPublicationDate: "Toon publicatiedatum in PDF",
  showPdfReach: "Toon bereik in PDF",
  showPdfRegion: "Toon regio in PDF",
  showRemaining: "Toon overige",
  showValueTypeInTitleCheckboxTitle:
    "Kan alleen uitgezet worden wanneer een grafiek wordt getoond",
  source: "Bron",
  sourceAddedToFilter: "Bron {0} is toegevoegd",
  sourceAsc: "Bron (A-Z)",
  sourceAsyncPopoverInitialLabel: "Zoek een bron...",
  sourceDesc: "Bron (Z-A)",
  sources: "Bronnen",
  sourcesX: "Bronnen ({0})",
  splitType_author: "Auteur",
  splitType_category: "Categorie",
  splitType_labels: "Label",
  splitType_mediatype: "Mediumtype",
  splitType_none: "Niet uitsplitsen",
  splitType_searchtopic: "Zoekopdracht",
  splitType_sentiment: "Sentiment",
  splitType_source: "Bron",
  startWithATemplate: "Start met een template",
  startWithAnEmptyDashboard: "Start met een leeg dashboard",
  stats: "Statistieken",
  status: "Status",
  subject: "Onderwerp",
  subscribed: "Geabonneerd",
  subscriber: "Abonnee",
  subscriberDisplayNameIsAlreadyUsed:
    "Er bestaat al een abonnee met deze naam.",
  subscriberEmailAddressIsAlreadyUsed:
    "Er bestaat al een abonnee met dit e-mailadres.",
  subscriberEmailAddresses: "Voer één of meerdere e-mailadressen in:",
  subscriberEmailAddressesHint:
    '(*Onderscheidingstekens die zijn toegestaan: spatie, enter, "," of ";")',
  subscribers: "Abonnees",
  success: "Succes",
  summedAve: "AVE",
  summedPrValue: "PR-waarde",
  summedReach: "Bereik",
  superuser: "Superuser",
  templates: "Templates",
  testMailHasBeenSend: "Nieuwsbrief-editie test is verzonden!",
  text: "Tekst",
  thisDay: "Vandaag",
  thisMediaItemIsUsedInMultipleRubrics:
    "Dit artikel staat in meerdere rubrieken.",
  thisTaskIsReady: "Deze taak is gereed",
  title: "Titel",
  titlealphabetical: "Titel A-Z",
  toApply: "Toepassen",
  today: "Vandaag",
  topOwn: "Eigen",
  topXResults: "Top {0}",
  true: "Ja",
  tryAgain: "Probeer opnieuw",
  type: "Type",
  unfavorite: "Niet favoriet",
  unread: "Ongelezen",
  unsaveDashboardChanges:
    "Wil je doorgaan zonder de wijzigingen van je dashboard op te slaan?",
  unsavedChangedAreYouSure:
    "Het formulier heeft niet-opgeslagen wijzigingen. Weet je zeker dat je de pagina wilt verlaten?",
  unsubscribe: "Uitschrijven",
  unsubscribe_description:
    "Gebruik de onderstaande knop om je uit te schrijven van de nieuwsbrief. Indien je van meerdere nieuwsbrieven lid bent, dien je jezelf hier apart voor af te melden",
  unsubscribe_error: "Mislukt",
  unsubscribe_inProgress: "...Bezig met uitschrijven...",
  unsubscribe_success: "Afmelden gelukt",
  unsubscribed: "Uitgeschreven",
  url: "URL",
  useAdvancedSearchEngineMatch: "Hulptool gebruiken",
  usedIn: "Gebruikt in",
  userForm__showInactiveAccounts: "Toon inactieve accounts",
  userForm_accessToAccounts: "Toegang tot accounts:",
  userForm_chooseAGroup: "Kies een groep",
  userForm_noRightToSettingsMessage:
    "Indien je dit wilt aanpassen, neem contact op met de beheerder van je account",
  userForm_personal: "Persoonlijk",
  userForm_requestFirstPassword:
    "De gebruiker ontvangt na het opslaan een e-mail met instructies voor het instellen van een wachtwoord",
  userForm_requestNewPassword: "Vraag nieuwe aan",
  userForm_rights: "Rechten",
  userId: "ID",
  userName: "E-mailadres",
  userReportXlsxJob: "Exporteer rapportage",
  users: "Gebruikers",
  utrecht: "Utrecht",
  valueType_count: "Aantal",
  valueType_summedAve: "AVE",
  valueType_summedPrValue: "PR-waarde",
  valueType_summedReach: "Bereik",
  view_LabelAutomatorModal_labelIdTitle: "Kies een label",
  view_LabelAutomatorModal_refineLabelAutomator: "Labelautomator verfijnen",
  view_LabelAutomatorModal_searchTopicIdsTitle: "Zoek binnen zoekopdrachten",
  view_LabelAutomatorModal_selectALabel: "Je moet een label selecteren",
  waitForApproval: "In behandeling",
  web: "Website",
  website: "Website",
  weekly: "Wekelijks",
  welcomeModal_message:
    "Is dit je eerste keer in media·web? We nemen je graag mee in de belangrijkste onderdelen van de portal. Als je de rondleiding op een later moment wilt starten, kun je deze aanroepen via de reddingsboei rechtsonder in je scherm. Succes!",
  welcomeModal_startTour: "Doe rondleiding",
  welcomeModal_title: "Welkom in media·web",
  widgetDrawer_amountOfActiveWidgetsDescription:
    "Aantal keer dat deze widget in dit dashboard wordt getoond",
  widgetSettings_display: "Weergave:",
  widgetSettings_filter: "Filter:",
  widgetSettings_interval: "Interval:",
  widgetSettings_limitedResults: "Beperk resultaten:",
  widgetSettings_splitType: "Uitsplitsen op:",
  widgetSettings_valueType: "Toon data:",
  widgets: "Widgets",
  wordCount: "Aantal woorden",
  xMessages: "{0} Berichten",
  xSelectedItem: "{0} item",
  xSelectedItems: "{0} items",
  xSubscriber: "{0} Abonnee",
  xSubscribers: "{0} Abonnees",
  xlsColumnHelp:
    "Selecteer en/of sorteer de kolommen die je in de Excel-export wenst te zien. Jouw voorkeuren slaan we op voor een volgende keer.",
  yearToDate: "Jaar tot op heden",
  youHaveBeenUnsubscribed: "U bent uitgeschreven",
  zeeland: "Zeeland",
  zuidHolland: "Zuid-Holland",
  activeFilters: "Actieve filters",
  restoreOriginalFilter: "Origineel filter terugzetten",
  narrowcastingTitle: "Narrowcasting-modus",
  narrowcastingBody:
    "Narrowcasting is een weergave waarmee je live data op een scherm kan tonen. In deze modus worden alle menu’s, knoppen en filterbalken verborgen. Door op ESC te drukken verlaat je deze modus.",
  stop: "Stoppen",
  start: "Starten",
  managerUser: "Beheerder",
  regularUser: "Reguliere gebruiker",
  dashboardOnlyUser: "Gedeeld-dashboard gebruiker",
  shareBenchmarkTitle: "Analyse delen",
  shareDashboardTitle: "Dashboard delen",
  shareBenchmarkBody:
    "Iedereen met de link kan de analyse met de huidige instellingen bekijken. Men kan deze niet bewerken.",
  shareDashboardBody:
    "Iedereen met de link kan het dashboard met de huidige instellingen bekijken. Men kan deze niet bewerken.",
  notFoundViewTitle: "Er ging iets fout :(",
  noAccessViewTitle: "Geen toegang",
  noAccessViewBody: "Neem contact op met je beheerder",
  "Subscriber,Self unsubscribed":
    "Deze ontvanger heeft zichzelf afgemeld. Opnieuw aanmelden door derde persoon is niet mogelijk.",
  benchmarkAccessLinkSent:
    "Er is een e-mail verzonden met een link naar deze analyse.",
  dashboardAccessLinkSent:
    "Er is een e-mail verzonden met een link naar dit dashboard.",
  benchmarkSharedWith: "Analyse gedeeld met",
  dashboardSharedWith: "Dashboard gedeeld met",
  benchmarkNotSharedYet: "Nog geen gebruikers toegevoegd",
  dashboardNotSharedYet: "Nog geen gebruikers toegevoegd",
  xDeleteMediaItemsCanNotBeDisplayed:
    "Er zijn {0} artikelen uit media·web verwijderd. De informatie hiervan kan niet meer worden getoond.",
  compareTo: "Vergelijk met",
  previousYear: "Gelijke periode, vorig jaar",
  previousPeriod: "Vorige periode",
  customPeriod: "Afwijkende periode",
  comparedWith: "Vergeleken met",
  comparedWith_lastWeek: "Vergeleken met vorige week",
  comparedWith_lastMonth: "Vergeleken met vorige 30 dagen",
  comparedWith_lastQuarter: "Vergeleken met vorige 90 dagen",
  comparedWith_thisDay: "Vergeleken met gisteren",
  comparedWith_yearToDate: "Vergeleken met vorig jaar",
  highlightWidget_compareMessages: "Berichten in vergeleken periode",
  showTrend: "Toon trendpercentages",
  showHighlightWidgetItemType_MESSAGES: "Toon berichten",
  showHighlightWidgetItemType_REACH: "Toon bereik",
  showHighlightWidgetItemType_AVE: "Toon AVE",
  showHighlightWidgetItemType_PR: "Toon PR-waarde",
  showHighlightWidgetItemType_SENTIMENT: "Toon sentiment",
  visibleHighlightWidgetItemTypes: "Zichtbare items",
  analysis: "Analyse",
  benchmarkCanBeSavedWhenDirty:
    "Een analyse kan worden opgeslagen na een wijziging hiervan",
  benchmarkView_startTitle: "Start een analyse",
  benchmarkView_startBody: "Voer een eerste zoekterm in om te beginnen",
  "should be string": "Dit veld mag niet leeg zijn",
  pressReleaseName: "Naam persbericht",
  customerId: "Klant",
  prDashboardUrl: "PR-Dashboard URL",
  pressReleaseUrl: "Press release URL",
  'must match format "url"': "zou een valide URL moeten bevatten",
  "benchmarkView__no-access-title": "Deze module is niet geactiveerd",
  "benchmarkView__no-access-body":
    '<p>Via deze module heb je de mogelijkheid om data buiten eigen zoekopdrachten te analyseren en benchmarken.</p><p><strong>Meer weten?</strong> Kijk dan op <a href="https://support.mediaweb.eu/category/analyse" target="_blank">https://support.mediaweb.eu/category/analyse</a> of neem contact op met je accountmanager.</p>',
  benchmarkView_disclaimer:
    '<p>Formuleer je zoekopdracht op <a href="https://support.mediaweb.eu/article/hoe-stel-ik-een-complexe-zoekopdracht-samen-in-de-zoekmachine" target="_blank">de juiste wijze</a> om zo gericht mogelijk te zoeken. Voor meer informatie lees onze <a href="https://support.mediaweb.eu/category/analyse" target="_blank">supportpagina</a>.</p>',
  addWidgets: "Voeg widgets toe",
  benchmarkView_noWidgetsWarning:
    "Resultaten zijn opgehaald. Voeg een widget toe om de resultaten te bekijken",
  "Benchmark already exists": "De gekozen naam is al in gebruik",
  dashboardName: "Naam van het dashboard",
  message: "Bericht",
  messageNotFoundTitle: "Bericht niet gevonden",
  messageNotFoundBody:
    "Mogelijk is het verwijderd of zijn de toegangsrechten aangepast.",
  articlesAreBeingProcessedTitle: "Artikelen worden verwerkt",
  articlesAreBeingProcessedBody:
    "Het toevoegen van de artikelen aan je dashboards kan even duren. Een voorbeeld van je aangeschafte artikelen tref je hier:",
  article: "Artikel",
  buyArticle: "Artikel aankopen",
  buyArticles: "{0} artikelen aankopen",
  addedArticle: "Artikel toegevoegd",
  app: "App",
  allSubscribersAreMailSubscribers: "Iedereen aanmelden voor nieuwsbrief",
  allSubscribersAreAppSubscribers: "Iedereen aanmelden voor gebruik in app",
  useApp: "App",
  useMail: "E-mail",
  appEnabled: "Gebruik App",
  matchText: "Zoekopdracht",
  benchmarkResultBenchmarkWidget_title: "{0} Berichten",
  updateSearch: "Zoekopdracht bijwerken",
  notes: "Notities",
  appConfig: "App configuratie",
  logoUrlLight: "Logo (lichte modus)",
  logoUrlDark: "Logo (donkere modus)",
  "must NOT have fewer than 1 characters": "Dit veld mag niet leeg zijn",
  sourceName: "Bron",
  benchmarkNames: "Zoekopdracht(en)",
  widgetSettings_bechmarkSplitType: "Filter op:",
  bechmarkSplitType_none: "Geen",
  bechmarkSplitType_web: "Mediatype Web",
  bechmarkSplitType_print: "Mediatype Print",
  benchmarkName: "Naam Zoekopdracht",
  benchmarkInvalid: "Analyse ongeldig",
  bechmarkSplitType_category: "Categorie",
  bechmarkSplitType_author: "Auteur",
  mediaItemSortType_aveAsc: "AVE oplopend",
  mediaItemSortType_aveDesc: "AVE aflopend",
  mediaItemSortType_insertDateAsc: "Oudste toegevoegd eerst",
  mediaItemSortType_insertDateDesc: "Nieuwste toegevoegd eerst",
  mediaItemSortType_prValueAsc: "PR-waarde oplopend",
  mediaItemSortType_prValueDesc: "PR-waarde aflopend",
  mediaItemSortType_publicationDateAsc: "Publicatiedatum oplopend",
  mediaItemSortType_publicationDateDesc: "Publicatiedatum aflopend",
  mediaItemSortType_reachAsc: "Bereik oplopend",
  mediaItemSortType_reachDesc: "Bereik aflopend",
  mediaItemSortType_sourceAsc: "Bron (A-Z)",
  mediaItemSortType_sourceDesc: "Bron (Z-A)",
  preparing: "Bezig met voorbereiden...",
  loadingResults: "Bezig met ophalen van {0} resultaten",
  showInactiveSubscribers: "Toon inactieve abonnees",
  atNotAllowed: "Het karakter '@' is niet toegestaan",
  moreAboutThisData: "Meer over deze data",
};

const Calendar: TLocaleCalendar = {
  formattedDayPattern: "MMM DD, YYYY",
  formattedMonthPattern: "MMM, YYYY",
  friday: "Vr",
  hours: "Uren",
  minutes: "Minuten",
  monday: "Ma",
  ok: "OK",
  saturday: "Za",
  seconds: "Secondes",
  sunday: "Zo",
  thursday: "Do",
  today: "Vandaag",
  tuesday: "Di",
  wednesday: "Wo",
  yesterday: "Gisteren",
};

const rsuiteLocale: TLocaleRsuiteData = {
  Pagination: {
    first: "Eerste",
    last: "Laatste",
    more: "Meer",
    next: "Volgende",
    prev: "Vorige",
  },
  Table: {
    emptyMessage: "Geen gegevens gevonden",
    loading: "Bezig met laden...",
  },
  TablePagination: {
    lengthMenuInfo: "{0} / pagina",
    totalInfo: "Totaal: {0}",
  },
  Calendar,
  DatePicker: Calendar,
  DateRangePicker: { ...Calendar, last7Days: "Afgelopen 7 dagen" },
  Picker: {
    checkAll: "Alle",
    noResultsText: "Geen resultaten gevonden",
    placeholder: "Selecteer",
    searchPlaceholder: "Zoek",
  },
  InputPicker: {
    createOption: 'Label toevoegen: "{0}"',
    newItem: "Nieuw item",
  },
  Uploader: {
    complete: "Gereed",
    emptyFile: "Leeg bestand",
    error: "Fout",
    inited: "Initieel",
    progress: "Bezig met uploaden",
    upload: "Upload",
  },
};

export const locale: TLocale = {
  db,
  rsuite: rsuiteLocale,
};
