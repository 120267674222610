import { components } from "../types/openapi";
import { Icon } from "rsuite";
import React from "react";
import MediaTypeWeb from "./MediaTypeWeb";
import MediaTypePrint from "./MediaTypePrint";
import MediaTypeRadio from "./MediaTypeRadio";
import MediaTypeTv from "./MediaTypeTv";
import MediaTypePodcast from "./MediaTypePodcast";
import MediaTypeRss from "./MediaTypeRss";

export function getSearchEngineResultIcon(
  searchEngineResult: components["schemas"]["SearchEngineResult"],
) {
  const iconStyle = { color: "#96afaf" };
  switch (searchEngineResult.archiveType) {
    case "Print":
      return (
        <Icon
          style={iconStyle}
          icon="newspaper-o"
          componentClass={MediaTypePrint}
        />
      );

    case "Web":
    default:
      return (
        <Icon style={iconStyle} icon="globe" componentClass={MediaTypeWeb} />
      );
  }
}

export function getMediaItemIcon(
  mediaItem: components["schemas"]["MediaItem"],
) {
  return getMediaItemIconFromTypeAndSource(
    mediaItem.mediaType,
    mediaItem.source,
  );
}

export function getBenchmarkResultIcon(
  benchmarkResult: components["schemas"]["BenchmarkResult"],
) {
  return getMediaItemIconFromTypeAndSource(
    benchmarkResult.mediaType as "print",
    benchmarkResult.sourceName as string,
  );
}

export function getDeleteRequestIcon(
  deleteRequest: components["schemas"]["DeleteRequest"],
) {
  if (!deleteRequest.mediaType || !deleteRequest.source) {
    return null;
  }
  return getMediaItemIconFromTypeAndSource(
    deleteRequest.mediaType,
    deleteRequest.source,
  );
}

function getMediaItemIconFromTypeAndSource(
  mediaType: components["schemas"]["MediaType"],
  source: string,
) {
  const iconStyle = { color: "#96afaf" };

  switch (mediaType) {
    case "print":
      return (
        <Icon
          style={iconStyle}
          icon="newspaper-o"
          componentClass={MediaTypePrint}
        />
      );

    case "rss":
      return (
        <Icon style={iconStyle} icon="rss" componentClass={MediaTypeRss} />
      );

    case "radio":
      return (
        <Icon
          style={iconStyle}
          icon="microphone"
          componentClass={MediaTypeRadio}
        />
      );

    case "podcast":
      return (
        <Icon
          style={iconStyle}
          icon="microphone"
          componentClass={MediaTypePodcast}
        />
      );

    case "tv":
      return <Icon style={iconStyle} icon="tv" componentClass={MediaTypeTv} />;

    case "social":
      const lcSource = source.toLowerCase();

      switch (lcSource) {
        case "facebook":
          return <Icon style={iconStyle} icon="facebook" />;

        case "youtube":
          return <Icon style={iconStyle} icon="youtube" />;

        case "instagram":
          return <Icon style={iconStyle} icon="instagram" />;

        case "twitter":
          return <Icon style={iconStyle} icon="twitter" />;

        case "pinterest":
          return <Icon style={iconStyle} icon="pinterest" />;

        case "tiktok":
          return (
            <img
              src="/img/social/icons/tiktok.png"
              alt="TikTok"
              style={{ ...iconStyle, width: 14, height: 14 }}
            />
          );

        case "x":
          return (
            <img
              src="/img/social/icons/x.png"
              alt="X"
              style={{ width: 14, height: 14 }}
            />
          );
      }
      return null;

    case "web":
    default:
      return (
        <Icon style={iconStyle} icon="globe" componentClass={MediaTypeWeb} />
      );
  }
}
