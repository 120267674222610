import React from "react";
import ItemBody from "./ItemBody";
import {
  MEDIA_ITEM_GRID_ITEM_WIDTH_LG,
  MEDIA_ITEM_GRID_ITEM_WIDTH_MD,
} from "../inc/constants";
import { components } from "../../../../../types/openapi";
import { AuthContext } from "../../../../../provider/AuthProvider";
import {
  DATE_FORMAT,
  localeFormat,
  parseDateWithOptionalTime,
} from "../../../../../inc/date";
import { getBenchmarkResultIcon } from "../../../../../icons";
import Slider from "../../../../Slider";

import "./index.scss";

interface IBenchmarkResultGridItemProps {
  colspan: number;
  disabled?: boolean;
  display: "compact" | "block";
  benchmarkResult: components["schemas"]["BenchmarkResult"];
  onClick?: (benchmarkResult: components["schemas"]["BenchmarkResult"]) => void;
  width: number;
}

const BenchmarkResultGridItem = (props: IBenchmarkResultGridItemProps) => {
  const { disabled, display, benchmarkResult, onClick, width } = props;
  const { imageUrl, publicationDate, sourceName, title } = benchmarkResult;
  const { auth } = React.useContext(AuthContext);

  const onBodyClick = React.useCallback(() => {
    if (onClick) {
      onClick(benchmarkResult);
    }
  }, [benchmarkResult, onClick]);

  const finalUrl = React.useMemo((): string => {
    if (imageUrl) {
      return imageUrl;
    }
    return "data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
  }, [imageUrl]);

  let size: "xs" | "md" | "lg" = "xs";
  if (width >= MEDIA_ITEM_GRID_ITEM_WIDTH_MD) {
    size = "md";
  }
  if (width >= MEDIA_ITEM_GRID_ITEM_WIDTH_LG) {
    size = "lg";
  }

  if (!auth) {
    return null;
  }

  return (
    <div
      className={`benchmark-result-grid-item benchmark-result-grid-item--${display} benchmark-result-grid-item--${size}${
        disabled ? " pt-3" : ""
      }`}
    >
      <div style={{ display: "flex" }} onClick={onBodyClick}>
        <div className="benchmark-result-grid-item__image">
          <img
            src={finalUrl}
            alt={title}
            style={{ maxWidth: "100%", marginBottom: 10 }}
          />
        </div>
        <div className="benchmark-result-grid-item__content">
          <div className="benchmark-result-grid-item__meta-data benchmark-result-grid-item__meta-data--top">
            <div>
              <span className="ps-0">
                {localeFormat(
                  parseDateWithOptionalTime(publicationDate),
                  DATE_FORMAT,
                )}
              </span>
            </div>
            <div className="benchmark-result-grid-item__meta-data__publicationName">
              {getBenchmarkResultIcon(benchmarkResult)}
              <span>{sourceName}</span>
            </div>
          </div>
          {size !== "xs" ? (
            <ItemBody benchmarkResult={benchmarkResult} />
          ) : null}
        </div>
      </div>
      {size === "xs" ? <ItemBody benchmarkResult={benchmarkResult} /> : null}
      <div className="benchmark-result-grid-item__benchmark-names mt-2">
        <Slider>
          {benchmarkResult.benchmarkNames.map((benchmarkName) => {
            return (
              <div
                key={benchmarkName}
                className="benchmark-result-benchmark-widget__benchmark-name"
                title={benchmarkName}
              >
                {benchmarkName}
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default BenchmarkResultGridItem;
