import React from "react";
import { Alert, Button, Checkbox, Form, Loader, Modal } from "rsuite";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import { components } from "../../../types/openapi";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../../inc/ajv";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import axios from "../../../inc/axios";
import { isEqual } from "lodash";
import { I18nContext } from "../../../provider/I18nProvider";
import useEditorials from "../../../hooks/useEditorials";
import useEditorialSubscribers from "../../../hooks/useEditorialSubscribers";
import useSubscribers from "../../../hooks/useSubscribers";
import { IHashMap } from "../../../inc/data";
import { AuthContext } from "../../../provider/AuthProvider";
import useCustomers from "../../../hooks/useCustomers";
import { AxiosError } from "axios";
import { IMaartenError } from "../../../types";

interface ISubscriberModalProps {
  onClose: () => void;
  show: boolean;
  subscriberId?: string;
}

const subscriberSchema = openapi.components.schemas
  .Subscriber as oas30.SchemaObject;
const validate = ajv.compile(subscriberSchema);

const { properties } = subscriberSchema;
if (!properties) {
  throw new Error("Incomplete subscriber schema");
}

const SubscriberModal = ({
  show,
  onClose,
  subscriberId,
}: ISubscriberModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { setSubscribers, setEditorialSubscribers } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const editorialsMap = useEditorials();
  const editorialSubscriberMap = useEditorialSubscribers();
  const subscribers = useSubscribers();
  const customers = useCustomers();

  const [subscriber, setSubscriber] =
    React.useState<components["schemas"]["Subscriber"]>();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [subscriberEditorialSubscribers, setSubscriberEditorialSubscribers] =
    React.useState<components["schemas"]["EditorialSubscriber"][]>();
  const [errors, setErrors] = React.useState<
    TSchemaFormErrors<components["schemas"]["Subscriber"]>
  >({});

  const currentCustomer = React.useMemo(() => {
    return customers && auth?.jwt.currentCustomerLinkId
      ? customers[auth.jwt.currentCustomerLinkId]
      : undefined;
  }, [auth?.jwt.currentCustomerLinkId, customers]);

  React.useEffect(() => {
    if (!subscribers) {
      return;
    }

    if (
      subscriber &&
      ((subscriber.subscriberId === undefined && subscriberId === "NEW") ||
        subscriber.subscriberId === subscriberId)
    ) {
      return;
    }

    let newSubscriber: components["schemas"]["Subscriber"] | undefined =
      subscribers && subscriberId ? subscribers[subscriberId] : undefined;
    if (!newSubscriber) {
      newSubscriber = {
        ...getEmptyObject(subscriberSchema),
      } as components["schemas"]["Subscriber"];
    }

    if (!newSubscriber) {
      throw new Error("Could not construct subscriber for form");
    }
    setSubscriber(JSON.parse(JSON.stringify(newSubscriber)));
  }, [editorialSubscriberMap, subscriber, subscriberId, subscribers]);

  const isSubscriberEmailAlreadyUsed = React.useCallback(
    (newSubscriber: components["schemas"]["Subscriber"]) => {
      return subscribers
        ? !!Object.values(subscribers)
            .map((subscriber) => subscriber.email)
            .find((subscriberEmail) => newSubscriber.email === subscriberEmail)
        : false;
    },
    [subscribers],
  );

  const isSubscriberDisplayNameAlreadyUsed = React.useCallback(
    (newSubscriber: components["schemas"]["Subscriber"]) => {
      return subscribers
        ? !!Object.values(subscribers)
            .map((subscriber) => subscriber.displayName)
            .find(
              (subscriberDisplayName) =>
                newSubscriber.displayName === subscriberDisplayName,
            )
        : false;
    },
    [subscribers],
  );

  const oldSubscriberEditorialSubscribers = React.useMemo<
    components["schemas"]["EditorialSubscriber"][] | null
  >(() => {
    if (!editorialsMap || !editorialSubscriberMap) {
      return null;
    }
    const editorialSubscriberList = Object.values(editorialSubscriberMap);
    return Object.values(editorialsMap).map((editorial) => {
      const editorialSubscriber = editorialSubscriberList.find(
        (editorialSubscriber) =>
          editorialSubscriber.subscriberId === subscriberId &&
          editorialSubscriber.editorialId === editorial.editorialId,
      );
      return (
        editorialSubscriber || {
          editorialId: editorial.editorialId!,
          subscriberId: subscriberId!,
          useApp: false,
          useMail: false,
        }
      );
    });
  }, [editorialSubscriberMap, editorialsMap, subscriberId]);

  const formIsDirty = React.useMemo(() => {
    const originalSubscriber =
      subscribers && subscriberId ? subscribers[subscriberId] : null;
    return (
      !isEqual(originalSubscriber, subscriber) ||
      !isEqual(
        oldSubscriberEditorialSubscribers,
        subscriberEditorialSubscribers,
      )
    );
  }, [
    oldSubscriberEditorialSubscribers,
    subscriber,
    subscriberEditorialSubscribers,
    subscriberId,
    subscribers,
  ]);

  React.useEffect(() => {
    setSubscriberEditorialSubscribers(oldSubscriberEditorialSubscribers || []);
  }, [oldSubscriberEditorialSubscribers]);

  const onSubmit = React.useCallback(async () => {
    if (
      isSaving ||
      !subscriberEditorialSubscribers ||
      !oldSubscriberEditorialSubscribers ||
      !subscriber
    ) {
      return;
    }

    // @todo remove me after fixing https://gitlab.com/mediainfogroep/mediaweb/mediaweb5-app/-/issues/19
    if (subscriber.bounced === null) {
      delete subscriber.bounced;
    }
    setIsSaving(true);
    const isValid = validate(subscriber);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(subscriber, validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      setIsSaving(false);
      return;
    }

    if (!subscriber) {
      setIsSaving(false);
      return;
    }
    const isNew = !(subscriber && subscriber.subscriberId);
    if (isNew && isSubscriberDisplayNameAlreadyUsed(subscriber)) {
      setErrors({ displayName: "subscriberDisplayNameIsAlreadyUsed" });
      setTimeout(focusError, 200);
      setIsSaving(false);
      return;
    }
    if (isNew && isSubscriberEmailAlreadyUsed(subscriber)) {
      setErrors({ email: "subscriberEmailAddressIsAlreadyUsed" });
      setTimeout(focusError, 200);
      setIsSaving(false);
      return;
    }
    setErrors({});
    const res = await axios.request<components["schemas"]["Subscriber"]>({
      method: subscriber && isNew ? "post" : "put",
      url: `/subscriber/crud${
        subscriber && subscriber.subscriberId
          ? `/${subscriber.subscriberId}`
          : ""
      }`,
      data: subscriber,
    });
    const newSubscriber = res.data as components["schemas"]["Subscriber"];
    setSubscribers({
      ...subscribers,
      [newSubscriber.subscriberId as string]: newSubscriber,
    });
    const newEditorialSubscriberMap: IHashMap<
      components["schemas"]["EditorialSubscriber"]
    > = { ...editorialSubscriberMap };
    try {
      await Promise.all(
        subscriberEditorialSubscribers.map(
          async (newEditorialSubscriber, index) => {
            const oldEditorialSubscriber =
              oldSubscriberEditorialSubscribers[index];
            if (
              JSON.stringify(newEditorialSubscriber) ===
              JSON.stringify(oldEditorialSubscriber)
            ) {
              return;
            }
            const res = await axios.request<
              components["schemas"]["EditorialSubscriber"]
            >({
              method: newEditorialSubscriber.editorialSubscriberId
                ? "put"
                : "post",
              url: `/editorialSubscriber/crud${
                newEditorialSubscriber.editorialSubscriberId
                  ? `/${newEditorialSubscriber.editorialSubscriberId}`
                  : ""
              }`,
              data: {
                ...newEditorialSubscriber,
                subscriber: newSubscriber.subscriberId,
              },
            });
            newEditorialSubscriberMap[
              res.data.editorialSubscriberId as string
            ] = res.data;
          },
        ),
      );
    } catch (err) {
      Alert.error(
        t(
          (err as AxiosError<IMaartenError>).response?.data.error ||
            (err as AxiosError<components["schemas"]["ValidationError"]>)
              .response?.data.message ||
            (err as Error).message,
        ),
      );
    }

    setEditorialSubscribers(newEditorialSubscriberMap);
    setSubscriber(undefined);
    setIsSaving(false);
    onClose();
  }, [
    isSaving,
    subscriberEditorialSubscribers,
    oldSubscriberEditorialSubscribers,
    subscriber,
    isSubscriberDisplayNameAlreadyUsed,
    isSubscriberEmailAlreadyUsed,
    setSubscribers,
    subscribers,
    editorialSubscriberMap,
    setEditorialSubscribers,
    onClose,
    t,
  ]);

  const onMailChange = React.useCallback(
    (editorialId: string, useMail: boolean) => {
      setSubscriberEditorialSubscribers((editorialSubscribers) =>
        editorialSubscribers
          ? editorialSubscribers.map((subscriberEditorialSubscriber) =>
              subscriberEditorialSubscriber.editorialId === editorialId
                ? {
                    ...subscriberEditorialSubscriber,
                    useMail,
                  }
                : subscriberEditorialSubscriber,
            )
          : editorialSubscribers,
      );
    },
    [],
  );

  const onAppChange = React.useCallback(
    (editorialId: string, useApp: boolean) => {
      setSubscriberEditorialSubscribers((editorialSubscribers) =>
        editorialSubscribers
          ? editorialSubscribers.map((subscriberEditorialSubscriber) =>
              subscriberEditorialSubscriber.editorialId === editorialId
                ? {
                    ...subscriberEditorialSubscriber,
                    useApp,
                  }
                : subscriberEditorialSubscriber,
            )
          : editorialSubscribers,
      );
    },
    [],
  );

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      {subscriberEditorialSubscribers && editorialsMap ? (
        <Form onSubmit={onSubmit}>
          <Modal.Header>
            <Modal.Title>
              {subscriber ? subscriber.displayName : t("loading")}
            </Modal.Title>
            <div className="d-flex">
              <Button
                type="submit"
                appearance="primary"
                disabled={!formIsDirty || isSaving}
              >
                {t("save")}
              </Button>
            </div>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: undefined }}>
            <div className="row w-100">
              <div className="col-md-6">
                <h4>{t("subscriber")}</h4>
                {subscriber ? (
                  <SchemaFormBody<components["schemas"]["Subscriber"]>
                    schema={{
                      ...subscriberSchema,
                      properties,
                    }}
                    config={{
                      bounced: {
                        hidden: true,
                      },
                      subscriberId: {
                        hidden: true,
                      },
                    }}
                    onChange={setSubscriber}
                    value={subscriber}
                    errors={errors}
                    inputClassName="input-gray"
                  />
                ) : (
                  <Loader center size="lg" />
                )}
              </div>
              <div className="col-md-3">
                <h4>{t("editorials")}</h4>
                {subscriberEditorialSubscribers.map(
                  (editorialSubscriber, index) => (
                    <Checkbox
                      key={index}
                      value={editorialSubscriber.editorialId}
                      checked={editorialSubscriber.useMail}
                      onChange={onMailChange}
                    >
                      {editorialsMap[editorialSubscriber.editorialId].name}
                    </Checkbox>
                  ),
                )}
              </div>
              {currentCustomer?.appEnabled ? (
                <div className="col-md-3">
                  <h4>{t("app")}</h4>

                  {subscriberEditorialSubscribers.map(
                    (editorialSubscriber, index) => (
                      <Checkbox
                        key={index}
                        value={editorialSubscriber.editorialId}
                        checked={editorialSubscriber.useApp}
                        onChange={onAppChange}
                      >
                        {editorialsMap[editorialSubscriber.editorialId].name}
                      </Checkbox>
                    ),
                  )}
                </div>
              ) : null}
            </div>
            {isSaving ? (
              <Loader backdrop content={t("saving")} vertical />
            ) : null}
          </Modal.Body>
        </Form>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};
export default SubscriberModal;
