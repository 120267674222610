import React, { Dispatch, Ref, SetStateAction } from "react";
import axios from "axios";
import { components } from "../../../../types/openapi";
import SearchEngineResultItem from "../SearchEngineResultItem";
import { IHashMap } from "../../../../inc/data";

interface IInViewSearchEngineResultItemProps {
  colspan: number;
  getSelected: (
    searchEngineResult: components["schemas"]["SearchEngineResult"]
  ) => boolean;
  hideButton: boolean;
  inView: boolean;
  isInholland: boolean;
  isSelectable: boolean;
  onItemClick: (
    searchEngineResult: components["schemas"]["SearchEngineResult"]
  ) => void;
  innerRef: Ref<HTMLDivElement>;
  searchEngineResult: components["schemas"]["SearchEngineResult"];
  searchEngineResultDetailsMap: IHashMap<
    components["schemas"]["SearchEngineResultDetails"] | null
  >;
  searchEngineResultId: string;
  searchEngineResultWidth: number;
  setSearchEngineResultDetailsMap: Dispatch<
    SetStateAction<{
      [searchEngineResultId: string]:
        | components["schemas"]["SearchEngineResultDetails"]
        | null;
    }>
  >;
  settings: {
    display: "block" | "table";
  };
  setSelected: (
    searchEngineResult: components["schemas"]["SearchEngineResult"],
    isSelected: boolean
  ) => void;
}

const InViewSearchEngineResultItem = ({
  colspan,
  getSelected,
  hideButton,
  inView,
  isInholland,
  isSelectable,
  onItemClick,
  innerRef,
  searchEngineResult,
  searchEngineResultDetailsMap,
  searchEngineResultId,
  searchEngineResultWidth,
  setSearchEngineResultDetailsMap,
  setSelected,
  settings,
}: IInViewSearchEngineResultItemProps) => {
  const isSelected = inView && getSelected(searchEngineResult);
  React.useEffect(() => {
    if (
      inView &&
      searchEngineResultDetailsMap[searchEngineResultId] === undefined
    ) {
      setSearchEngineResultDetailsMap((searchEngineResultDetailsMap) => ({
        ...searchEngineResultDetailsMap,
        [searchEngineResultId]: null,
      }));
      axios
        .get<components["schemas"]["SearchEngineResultDetails"]>(
          `/searchEngineResult/details/${searchEngineResultId}`
        )
        .then((result:any) => {
          setSearchEngineResultDetailsMap((searchEngineResultDetailsMap) => ({
            ...searchEngineResultDetailsMap,
            [searchEngineResultId]: result.data,
          }));
        });
    }
  }, [
    inView,
    searchEngineResultDetailsMap,
    searchEngineResultId,
    setSearchEngineResultDetailsMap,
  ]);
  return (
    <SearchEngineResultItem
      innerRef={innerRef}
      colspan={colspan}
      display={(settings.display || "block") as "block"}
      isSelected={isSelected}
      searchEngineResult={searchEngineResult}
      searchEngineResultDetails={
        searchEngineResultDetailsMap[
          searchEngineResult.searchEngineResultId as string
        ]
      }
      onClick={onItemClick}
      onSetSelected={setSelected}
      width={searchEngineResultWidth}
      hideCheckbox={!isSelectable || isInholland}
      hideButton={hideButton}
      hideSearchTopics={isInholland}
    />
  );
};

export default InViewSearchEngineResultItem;
