import React from "react";
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  Icon,
  IconButton,
  Tooltip,
} from "rsuite";
import Plus from "../../../icons/Plus";
import Minus from "../../../icons/Minus";
import "./index.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { components } from "../../../types/openapi";
import { TSchemaFormErrors } from "../../../components/SchemaFormBody";
import FilterEditButton from "../../../components/form/FilterEditButton";
import Drag from "../../../icons/Drag";
import WarningBadge from "../../../components/WarningBadge";
import { I18nContext } from "../../../provider/I18nProvider";
import MwWhisper from "../../../components/MwWhisper";

interface IEditorialRubricsProps {
  editorialRubrics: components["schemas"]["EditorialRubric"][];
  onChange: (
    newEditorialRubrics: components["schemas"]["EditorialRubric"][],
  ) => void;
  errors: TSchemaFormErrors;
  showEditorialRubricsWithoutFilters: boolean;
}

const EditorialRubrics = (props: IEditorialRubricsProps) => {
  const {
    editorialRubrics,
    errors,
    onChange,
    showEditorialRubricsWithoutFilters,
  } = props;
  const { t } = React.useContext(I18nContext);

  const getReorderedRubrics = React.useCallback(
    (startIndex: number, endIndex: number) => {
      const newRubrics = [...editorialRubrics];
      const [removed] = newRubrics.splice(startIndex, 1);
      newRubrics.splice(endIndex, 0, removed);
      return newRubrics;
    },
    [editorialRubrics],
  );

  const onDragEnd = React.useCallback(
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      // Reorder (by index)
      let newEditorialRubrics = getReorderedRubrics(
        result.source.index,
        result.destination.index,
      );
      // Update
      newEditorialRubrics.forEach(
        (newEditorialRubric, index) => (newEditorialRubric.order = index),
      );
      onChange([...newEditorialRubrics]);
    },
    [onChange, getReorderedRubrics],
  );

  const updateRubricFilter = React.useCallback(
    (
      rubricIndex: number,
      newFilter: components["schemas"]["Filter"] | undefined,
    ) => {
      const newEditorialRubrics = [...editorialRubrics];
      newEditorialRubrics[rubricIndex].filter = newFilter;
      onChange(newEditorialRubrics);
    },
    [editorialRubrics, onChange],
  );

  return (
    <div className="editorial-rubrics">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`editorial-rubrics__container${
                snapshot.isDraggingOver
                  ? " editorial-rubrics__container--dragging-over"
                  : ""
              }`}
            >
              {editorialRubrics.map((editorialRubric, index) => (
                <Draggable
                  key={index}
                  draggableId={`${editorialRubric.order}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={provided.draggableProps.style}
                      className={`editorial-rubrics__item${
                        snapshot.isDragging
                          ? " editorial-rubrics__item--dragging"
                          : ""
                      }`}
                    >
                      <FormGroup
                        key={index}
                        className="editorial-rubrics__item__filter"
                      >
                        <ControlLabel className="label">
                          {t("editorialRubric")} {index + 1}
                        </ControlLabel>
                        <div className="row align-items-center position-relative">
                          <div
                            className="editorial-rubrics__item__drag-handle"
                            {...provided.dragHandleProps}
                          >
                            <Drag />
                          </div>
                          <div className="col-md">
                            <FormControl
                              className="input input-gray mw-100"
                              onChange={(newName: string) => {
                                const newEditorialRubrics = [
                                  ...editorialRubrics,
                                ];
                                newEditorialRubrics[index].name = newName;
                                onChange(newEditorialRubrics);
                              }}
                              size="sm"
                              value={editorialRubric.name}
                              placeholder={t("editorialRubricNamePlaceholder")}
                            />
                          </div>
                          <div className="col-md-auto" style={{ zIndex: 1 }}>
                            <FilterEditButton
                              value={editorialRubric.filter}
                              onChange={(newFilter) =>
                                updateRubricFilter(index, newFilter)
                              }
                              onDelete={() =>
                                updateRubricFilter(index, undefined)
                              }
                              tooltip={t("editorialRubricFilter_help")}
                            />
                            {showEditorialRubricsWithoutFilters &&
                            editorialRubric.filter === undefined ? (
                              <MwWhisper
                                trigger="hover"
                                speaker={
                                  <Tooltip>
                                    {t(
                                      "automaticEditorialRubricWithoutFilter_help",
                                    )}
                                  </Tooltip>
                                }
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-.625rem",
                                    right: "-.125rem",
                                  }}
                                >
                                  <WarningBadge />
                                </div>
                              </MwWhisper>
                            ) : null}
                          </div>
                          <div>
                            <div className="editorial-rubrics__item__delete-button">
                              <MwWhisper
                                placement="bottom"
                                trigger="hover"
                                speaker={<Tooltip>{t("deleteRubric")}</Tooltip>}
                              >
                                <IconButton
                                  appearance="subtle"
                                  className="ms-1"
                                  onClick={() => {
                                    onChange(
                                      editorialRubrics
                                        .filter(
                                          (_rubric, rubricIndex) =>
                                            rubricIndex !== index,
                                        )
                                        .map((rubric, rubricIndex) => ({
                                          ...rubric,
                                          order: rubricIndex,
                                        })),
                                    );
                                  }}
                                  icon={
                                    <Icon icon="minus" componentClass={Minus} />
                                  }
                                />
                              </MwWhisper>
                            </div>
                          </div>
                        </div>
                        {
                          // @ts-ignore
                          errors && errors[`editorialRubrics_${index}`] ? (
                            <HelpBlock style={{ color: "red" }}>
                              {
                                // @ts-ignore
                                t(errors[`editorialRubrics_${index}`])
                              }
                            </HelpBlock>
                          ) : null
                        }
                      </FormGroup>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <IconButton
        icon={<Icon icon="plus" componentClass={Plus} />}
        appearance="subtle"
        onClick={() => {
          onChange([
            ...editorialRubrics,
            {
              name: "",
              order: editorialRubrics.length,
            } as components["schemas"]["EditorialRubric"],
          ]);
        }}
      >
        {t("addEditorialRubric")}
      </IconButton>
    </div>
  );
};
export default EditorialRubrics;
