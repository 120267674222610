import React from "react";
import { APP_PATH, BootstrapSize } from "../../inc/constants";
import "react-grid-layout/css/styles.css";
import "./index.scss";
import {
  ENarrowCastingMode,
  LayoutContext,
} from "../../provider/LayoutProvider";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Dropdown,
  Icon,
  IconButton,
  Loader,
} from "rsuite";
import {
  EDashboardWidgetType,
  IDashboardHashData,
  newDashboardWidget,
} from "../../components/dashboardWidgets";
import { getEmptyObject } from "../../inc/schema";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { TDashboard } from "../../types";
import {
  Prompt,
  Redirect,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router-dom";
import { components } from "../../types/openapi";
import SaveAsModal from "./SaveAsModal";
import axios from "../../inc/axios";
import _, { isEqual } from "lodash";
import ExistingDashboardView from "./ExistingDashboardView";
import ActionButton from "../../components/ActionButton";
import { cleanDashboard, cleanFilter } from "../../inc/data";
import DashboardTour from "./DashboardTour";
import { I18nContext } from "../../provider/I18nProvider";
import DashboardTemplateDialog, {
  ETemplateType,
  getTemplateDefinitions,
} from "../../layout/AppLayout/DashboardTemplateDialog";
import useDashboards from "../../hooks/useDashboards";
import { useDashboardHashData } from "./inc/hooks";
import { AuthContext } from "../../provider/AuthProvider";
import Templates from "../../icons/Templates";
import ShareButton from "./ShareButton";
import WidgetDrawer from "./WidgetDrawer";
import useMes from "../../hooks/useMes";
import useQueryParameters from "../../hooks/useQueryParameters";
import { getComparePeriodDateRange, IPeriods } from "../../inc/date";
import PrDashboard from "../../icons/PrDashboard";

const filterSchema = openapi.components.schemas.Filter as oas30.SchemaObject;
const periodSchema = openapi.components.schemas.Period as oas30.SchemaObject;

const DashboardView = (
  props: RouteComponentProps<{}, {}, { dashboard?: TDashboard }>,
) => {
  const { location } = props;
  const {
    contentWidth,
    narrowCastingMode,
    startNarrowCasting,
    setSelectedMediaItemIds,
    windowOuterWidth,
  } = React.useContext(LayoutContext);
  const isNarrowCasting =
    narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF;
  const { t } = React.useContext(I18nContext);
  const { updateDashboard, updateUser } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const dashboards = useDashboards();
  const users = useMes();

  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = React.useState(false);

  const currentUser = React.useMemo(() => {
    if (!users || !auth || !auth.jwt.userId) {
      return undefined;
    }
    return users.find(
      (user) =>
        user.userId === auth.jwt.userId &&
        user.groupId === auth.jwt.currentGroupId,
    );
  }, [auth, users]);

  const [filter, setFilter] = React.useState<components["schemas"]["Filter"]>();

  // STATE
  const [filterCacheRequest, setFilterCacheRequest] =
    React.useState<components["schemas"]["FilterCacheRequest"]>();
  const [filterCacheResponse, setFilterCacheResponse] = React.useState<
    components["schemas"]["FilterCacheResponse"] | null
  >();
  const [compareFilterCacheResponse, setCompareFilterCacheResponse] =
    React.useState<components["schemas"]["FilterCacheResponse"] | null>();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [saveAsDashboard, setSaveAsDashboard] = React.useState<TDashboard>();
  const [dashboard, setDashboard] = React.useState<TDashboard>();
  const [titleInputError, setTitleInputError] = React.useState<string>();

  // HOOKS
  const toggleSaveAsDialog = React.useCallback(() => {
    if (!dashboard || !filterCacheRequest) {
      return;
    }
    setSaveAsDashboard((currentSaveAsDashboard) =>
      currentSaveAsDashboard
        ? undefined
        : {
            ...dashboard,
            dashboardId: undefined,
            filter: cleanFilter(filterCacheRequest.filter),
          },
    );
  }, [dashboard, filterCacheRequest]);

  const { dashboardId = "new" } = useParams<{ dashboardId: string }>();
  const history = useHistory();
  const locationHashData = useDashboardHashData();
  const queryParameters = useQueryParameters<{ customerLinkId: string }>();

  const templateDefinitions = React.useMemo(
    () => getTemplateDefinitions(t),
    [t],
  );
  const myMediaDashboard = React.useMemo(
    () =>
      currentUser?.userId
        ? {
            ...templateDefinitions[ETemplateType.TEMPLATE_TYPE_MONITORING]
              .dashboard,
            name: "",
            config:
              currentUser?.settings?.myMediaDashboardConfig ||
              templateDefinitions[ETemplateType.TEMPLATE_TYPE_MONITORING]
                .dashboard.config,
          }
        : undefined,
    [
      currentUser?.userId,
      currentUser?.settings?.myMediaDashboardConfig,
      templateDefinitions,
    ],
  );

  const originalDashboard: TDashboard | undefined = React.useMemo(() => {
    if (dashboardId === "new") {
      return myMediaDashboard;
    }

    const originalDashboard =
      dashboards && dashboard?.dashboardId
        ? dashboards[dashboard.dashboardId]
        : undefined;
    return originalDashboard
      ? JSON.parse(
          JSON.stringify({
            ...originalDashboard,
            filter: cleanFilter(originalDashboard.filter),
          }),
        )
      : undefined;
  }, [dashboard?.dashboardId, dashboardId, dashboards, myMediaDashboard]);

  const titleInputRef = React.useRef<HTMLInputElement>(null);

  const addWidget = React.useCallback(
    (widgetType: EDashboardWidgetType) => {
      if (!dashboard) {
        return;
      }
      const widget = newDashboardWidget(widgetType);
      setDashboard({
        ...dashboard,
        config: {
          ...dashboard.config,
          widgets: [
            ...(dashboard.config.widgets || []).map((currentWidget) => {
              // Move the existing widgets down
              currentWidget.layouts.lg.y += widget.layouts.lg.h;
              currentWidget.layouts.xs.y += widget.layouts.xs.h;
              return currentWidget;
            }),
            widget,
          ],
        },
      });
      setIsDrawerOpen(false);
    },
    [dashboard],
  );

  const isfilterCacheRequestFilterDirty = React.useMemo(() => {
    if (!filterCacheRequest || !filter) {
      return false;
    }
    return !_.isEqual(filterCacheRequest.filter || {}, filter || {});
  }, [filter, filterCacheRequest]);

  const isDashboardDirty = React.useMemo<boolean>(() => {
    if (!dashboard || !filterCacheRequest) {
      return false;
    }
    const filter =
      filterCacheRequest.filter && Object.keys(filterCacheRequest.filter).length
        ? filterCacheRequest.filter
        : undefined;

    const o1 = cleanDashboard({
      ...dashboard,
      filter,
    });
    const o2 = cleanDashboard({ ...originalDashboard } as TDashboard);

    // UNCOMMENT TO SHOW DIFF FOR DASHBOARDS
    // if (o1 && o2) {
    //   for (const prop of uniq([...Object.keys(o1), ...Object.keys(o2)])) {
    //     // @ts-ignore
    //     if (JSON.stringify(o2[prop]) !== JSON.stringify(o1[prop])) {
    //       // @ts-ignore
    //       console.log("Diff", prop, o1[prop], o2[prop]);
    //     }
    //   }
    // }

    return !isEqual(o1, o2);
  }, [dashboard, originalDashboard, filterCacheRequest]);

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (!dashboard || !filterCacheRequest || !isDashboardDirty) {
        return;
      }
      if (!dashboard.name) {
        const node = titleInputRef.current;
        if (node) {
          node.focus();
        }
        setTitleInputError("must NOT be shorter than 1 characters");
        return;
      }
      setTitleInputError(undefined);
      try {
        const updatedDashboard = isDashboardDirty
          ? await updateDashboard({
              ...dashboard,
              filter: cleanFilter(filterCacheRequest.filter),
            })
          : dashboard;
        setDashboard(updatedDashboard);
        if (updatedDashboard.dashboardId !== dashboard.dashboardId) {
          return history.push(
            `${APP_PATH}/dashboards/${updatedDashboard.dashboardId}`,
          );
        }
      } catch (err: any) {
        if (err.response?.data.error === "Title,Empty") {
          setTitleInputError("must NOT be shorter than 1 characters");
          return;
        }
        Alert.error(t(err.response?.data.error || "genericErrorMessage"));
      }
    },
    [
      dashboard,
      isDashboardDirty,
      filterCacheRequest,
      updateDashboard,
      history,
      t,
    ],
  );

  const onPeriodsChange = React.useCallback(
    ({ comparePeriod, period }: IPeriods) => {
      history.push(
        `${history.location.pathname}#${encodeURIComponent(
          JSON.stringify({
            filterPeriod: {
              comparePeriod,
              filter,
              period,
            },
          } as IDashboardHashData),
        )}`,
      );
    },
    [filter, history],
  );

  const onDashboardChange = React.useCallback(
    (dashboard: TDashboard) => {
      setDashboard(dashboard);
      if (
        currentUser &&
        !dashboard.dashboardId &&
        JSON.stringify(dashboard?.config) !==
          JSON.stringify(currentUser.settings?.myMediaDashboardConfig)
      ) {
        updateUser({
          ...currentUser,
          settings: {
            ...currentUser.settings,
            myMediaDashboardConfig: dashboard?.config,
          },
        }).catch((err) => {
          console.log(err);
        });
      }
    },
    [currentUser, updateUser],
  );

  const onFilterReset = React.useCallback(() => {
    if (filterCacheRequest?.filter) {
      setFilter(filterCacheRequest.filter);
      return;
    }
    setFilter(dashboard?.filter);
  }, [dashboard?.filter, filterCacheRequest?.filter]);

  const titleInputOnKeyDown = React.useCallback(
    (e: any) => {
      if (
        e.key !== "Escape" ||
        !dashboard ||
        !dashboards ||
        !dashboards[dashboardId]
      ) {
        return;
      }
      setDashboard({
        ...dashboard,
        name: dashboards[dashboardId].name,
      });
      const { current } = titleInputRef;
      if (current) {
        current.blur();
      }
    },
    [dashboards, dashboard, dashboardId],
  );

  const onFilterChange = React.useCallback(
    (newFilter?: components["schemas"]["Filter"]) =>
      setFilter(cleanFilter(newFilter) || {}),
    [],
  );

  const isDashboardOnly = auth
    ? auth.jwt.userRoles.indexOf("dashboardOnly") >= 0
    : false;
  const actionsMenu = React.useMemo(
    () => (
      <ButtonToolbar className="me-lg-4">
        {dashboard?.prDashboardUrl ? (
          <IconButton
            className="dashboard-view__pr-button"
            appearance="subtle"
            icon={<Icon icon="star" componentClass={PrDashboard} />}
            size="md"
            onClick={() => {
              window.open(dashboard?.prDashboardUrl);
            }}
          />
        ) : null}
        <ShareButton dashboardId={dashboardId} />
        {isDashboardOnly ? null : (
          <IconButton
            className="border-0 btn--big-icon d-none d-sm-inline-block me-3 me-md-0"
            icon={<Icon icon="question" componentClass={Templates} />}
            placement="right"
            size="xs"
            appearance="ghost"
            color="blue"
            onClick={() => {
              setIsTemplateDialogOpen(true);
            }}
          >
            {windowOuterWidth >= BootstrapSize.MD
              ? t("templates").toLowerCase()
              : null}
          </IconButton>
        )}
        {isDashboardOnly ? null : (
          <ActionButton
            className="dashboard-action-button--add-widget"
            onClick={() => setIsDrawerOpen(true)}
            title={windowOuterWidth >= BootstrapSize.MD ? "widget" : undefined}
          />
        )}
      </ButtonToolbar>
    ),
    [
      dashboard?.prDashboardUrl,
      dashboardId,
      isDashboardOnly,
      t,
      windowOuterWidth,
    ],
  );

  const saveButton = React.useMemo(
    () => (
      <ButtonGroup
        size="xs"
        className="dashboard-view__topbar__save-dashboard-buttons d-none d-sm-flex"
      >
        <Button
          className="dashboard-view__topbar__save-dashboard-buttons__button"
          disabled={!!dashboard?.dashboardId && !isDashboardDirty}
          appearance={
            !!dashboard?.dashboardId && !isDashboardDirty ? "ghost" : "primary"
          }
          type="submit"
          title={
            isDashboardDirty ? undefined : t("dashboardCanBeSavedWhenDirty")
          }
        >
          {t(!!dashboard?.dashboardId ? "save" : "saveAsDashboard")}
        </Button>
        {dashboard?.dashboardId ? (
          <Dropdown
            placement="bottomEnd"
            disabled={!isDashboardDirty}
            renderTitle={() => (
              <IconButton
                className="dashboard-view__topbar__save-dashboard-buttons__menu"
                icon={<Icon icon="angle-down" />}
                disabled={!isDashboardDirty}
                appearance={isDashboardDirty ? "primary" : "ghost"}
              />
            )}
          >
            <Dropdown.Item onSelect={onSubmit}>{t("save")}</Dropdown.Item>
            <Dropdown.Item
              onSelect={toggleSaveAsDialog}
              disabled={!!dashboard.prDashboardUrl}
            >
              {t("saveAs")}
            </Dropdown.Item>
          </Dropdown>
        ) : null}
      </ButtonGroup>
    ),
    [
      dashboard?.dashboardId,
      dashboard?.prDashboardUrl,
      isDashboardDirty,
      onSubmit,
      t,
      toggleSaveAsDialog,
    ],
  );

  React.useEffect(() => {
    let updateFilterCacheRequest = false;
    if (!dashboard || !locationHashData || !locationHashData.filterPeriod) {
      return;
    }

    const dashboardUpdates: Partial<components["schemas"]["Dashboard"]> = {};

    if (
      !filterCacheRequest ||
      JSON.stringify(locationHashData.filterPeriod.filter) !==
        JSON.stringify(filterCacheRequest.filter)
    ) {
      setFilter(cleanFilter(locationHashData.filterPeriod.filter) || {});
      updateFilterCacheRequest = true;
    }

    if (
      !filterCacheRequest ||
      JSON.stringify(locationHashData.filterPeriod.period) !==
        JSON.stringify(filterCacheRequest.period)
    ) {
      dashboardUpdates.period = locationHashData.filterPeriod.period;
      updateFilterCacheRequest = true;
    }

    if (
      JSON.stringify(locationHashData.filterPeriod.comparePeriod) !==
      JSON.stringify(dashboard.comparePeriod)
    ) {
      dashboardUpdates.comparePeriod =
        locationHashData.filterPeriod.comparePeriod;
    }

    if (updateFilterCacheRequest) {
      setFilterCacheRequest(locationHashData.filterPeriod);
    }
    if (Object.keys(dashboardUpdates).length) {
      setDashboard({
        ...dashboard,
        ...dashboardUpdates,
      });
    }
  }, [dashboard, filter, filterCacheRequest, locationHashData]);

  React.useEffect(() => {
    setSelectedMediaItemIds([]);
    setSaveAsDashboard(undefined);
    setTitleInputError(undefined);
    const node = titleInputRef.current;
    if (node) {
      node.focus();
    }
  }, [setSelectedMediaItemIds]);

  React.useEffect(() => {
    setSelectedMediaItemIds([]);
  }, [filterCacheResponse, setSelectedMediaItemIds]);

  React.useEffect(() => {
    if (!dashboards || !myMediaDashboard) {
      return;
    }
    const newDashboard =
      dashboardId === "new" ? myMediaDashboard : dashboards[dashboardId];
    const newFilter =
      newDashboard?.filter ||
      getEmptyObject<components["schemas"]["Filter"]>(filterSchema);
    const newFilterCacheRequest = {
      filter: newFilter,
      period:
        newDashboard?.period ||
        getEmptyObject<components["schemas"]["Period"]>(periodSchema),
    };
    if (
      newDashboard &&
      // Load first dashboard?
      (!dashboard ||
        // Load a new, switched dashboard?
        newDashboard.dashboardId !== dashboard.dashboardId ||
        // Restore a dashboard after going back from a filter
        (!locationHashData &&
          JSON.stringify(newFilterCacheRequest) !==
            JSON.stringify(filterCacheRequest)))
    ) {
      setDashboard(JSON.parse(JSON.stringify(newDashboard)));
      if (!locationHashData?.filterPeriod) {
        setFilter(newFilter);
        setFilterCacheRequest(newFilterCacheRequest);
      }
    }
  }, [
    dashboard,
    dashboardId,
    dashboards,
    filter,
    history,
    setSelectedMediaItemIds,
    locationHashData,
    filterCacheRequest,
    myMediaDashboard,
  ]);

  React.useEffect(() => {
    if (!filterCacheRequest) {
      setFilterCacheResponse(undefined);
      return;
    }
    setFilterCacheResponse(null);
    axios
      .post<components["schemas"]["FilterCacheResponse"]>(
        "/filter/cache",
        filterCacheRequest,
      )
      .then((res) => {
        setFilterCacheResponse(res.data);
      });
  }, [filterCacheRequest]);

  const period = React.useMemo(
    () => filterCacheRequest?.period || dashboard?.period,
    [dashboard?.period, filterCacheRequest?.period],
  );

  const { comparePeriod } = dashboard || {};

  React.useEffect(() => {
    const compareDateRange =
      filterCacheRequest && period && comparePeriod
        ? getComparePeriodDateRange(comparePeriod, period)
        : [];
    if (!compareDateRange.length || !comparePeriod) {
      setCompareFilterCacheResponse(undefined);
      return;
    }
    setCompareFilterCacheResponse(null);
    axios
      .post<components["schemas"]["FilterCacheResponse"]>("/filter/cache", {
        ...filterCacheRequest,
        period: {
          dateType: comparePeriod.dateType,
          periodType: "custom",
          startDate: compareDateRange[0],
          endDate: compareDateRange[1],
        },
        comparePeriod: undefined,
      })
      .then((res) => {
        // console.log(res.data);
        setCompareFilterCacheResponse(res.data);
      });
  }, [filterCacheRequest, comparePeriod, period]);

  React.useEffect(() => {
    if (location?.state?.dashboard) {
      setDashboard(location.state.dashboard);
    }
  }, [location]);

  React.useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === "F11") {
        e.preventDefault();
        startNarrowCasting();
      }
    };
    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
  }, [startNarrowCasting]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isNarrowCasting || !filterCacheResponse) {
        return;
      }
      // set a "new" filterCacheRequest to refresh the results
      axios
        .request({
          method: "post",
          url: "/filter/cache",
          params: {
            filterCacheToken: filterCacheResponse.token,
          },
          data: {
            ...filterCacheRequest,
            comparePeriod: undefined,
          },
        })
        .then((res) => {
          setFilterCacheResponse(res.data);
        });
      // Background mediaweb jobs run every 6 minutes
    }, 360_000);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterCacheRequest, filterCacheResponse, history, isNarrowCasting]);

  const customerRole = auth?.jwt.customerRole || "";
  if (["regular", "restricted"].indexOf(customerRole) === -1) {
    return <Redirect to={`${APP_PATH}`} />;
  }

  // widget view layout breaks when rendering without width...
  // https://www.notion.so/c700a5b224564b5395ec6c30144d735a?v=886ff64714724bb2a742a4953b2fdf44&p=7fd7063c00fe4197a361242fbeb7a40e
  if (!dashboards || !windowOuterWidth || !auth || !period) {
    return <Loader />;
  }

  if (isDashboardOnly && dashboards && dashboardId === "new") {
    const dashboardIds = Object.keys(dashboards);
    return (
      <Redirect
        to={`${APP_PATH}/${
          dashboardIds.length ? `dashboards/${dashboardIds[0]}` : "noAccess"
        }`}
      />
    );
  }

  if (!dashboard) {
    // wait for it...
    if (
      dashboardId === "new" ||
      dashboards[dashboardId] ||
      // wait for customer switch...
      auth.jwt.currentCustomerLinkId !== queryParameters.customerLinkId
    ) {
      return <Loader />;
    }

    return <Redirect to={`${APP_PATH}/myMedia`} />;
  }

  return (
    <div id={dashboardId} key={dashboardId} className="h-100">
      <Prompt
        when={isDashboardDirty}
        message={(location) => {
          let newLocationHashData: IDashboardHashData | undefined;
          try {
            newLocationHashData = JSON.parse(
              decodeURIComponent(location.hash.substr(1)),
            );
          } catch (e) {
            // console.log(e);
          }

          return (
            history.location.pathname === location.pathname ||
            // allow to navigate away to copied dashboard
            (newLocationHashData && newLocationHashData.isCopy) ||
            !dashboards ||
            // allow to navigate away from deleted dashboard
            (!dashboards[dashboardId] ? true : t("unsaveDashboardChanges"))
          );
        }}
      />
      <ExistingDashboardView
        actionsMenu={actionsMenu}
        compareFilterCacheResponse={compareFilterCacheResponse}
        comparePeriod={comparePeriod}
        dashboard={dashboard}
        dashboardWidth={contentWidth}
        filter={filter}
        filterCacheResponse={filterCacheResponse}
        isDirty={isfilterCacheRequestFilterDirty}
        onDashboardChange={isDashboardOnly ? undefined : onDashboardChange}
        onDrawerToggle={setIsDrawerOpen}
        onFilterChange={isDashboardOnly ? undefined : onFilterChange}
        onFilterReset={onFilterReset}
        onPeriodsChange={isDashboardOnly ? undefined : onPeriodsChange}
        onSubmit={onSubmit}
        period={period}
        saveButton={saveButton}
        setFilterCacheResponse={setFilterCacheResponse}
        titleInputError={titleInputError}
        titleInputOnKeyDown={titleInputOnKeyDown}
        titleInputRef={titleInputRef}
      />
      <WidgetDrawer
        dashboard={dashboard}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        addWidget={addWidget}
      />
      <SaveAsModal
        close={toggleSaveAsDialog}
        dashboard={saveAsDashboard}
        setDashboard={setSaveAsDashboard}
      />
      <DashboardTour />
      {isTemplateDialogOpen ? (
        <DashboardTemplateDialog
          show={isTemplateDialogOpen}
          setDashboardConfig={(config) => {
            setIsTemplateDialogOpen(false);
            if (
              !config ||
              !window.confirm(
                t(
                  dashboardId === "new"
                    ? "myMediaTemplatesButtonConfirm"
                    : "dashboardTemplatesButtonConfirm",
                ),
              )
            ) {
              return;
            }
            setDashboard(
              (dashboard) =>
                ({
                  ...dashboard,
                  config,
                }) as TDashboard,
            );
          }}
        />
      ) : null}
    </div>
  );
};

export default DashboardView;
