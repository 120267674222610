import React from "react";
import { Button, Divider, IconButton, Modal, Panel } from "rsuite";
import "./index.scss";
import { components } from "../../types/openapi";
import PeriodsControl from "./PeriodsControl";
import MoreOptionsPopover from "./MoreOptionsPopover";
import ToggleButton from "./ToggleButton";
import TagsBar from "./TagsBar";
import AdvancedSearch from "./AdvancedSearch";
import { useHistory } from "react-router-dom";
import { WhisperInstance } from "rsuite/lib/Whisper";
import { I18nContext } from "../../provider/I18nProvider";
import MediaTypeControl from "./MediaTypeControl";
import SearchTopicControl from "./SearchTopicControl";
import SentimentControl from "./SentimentControl";
import UnreadControl from "./UnreadControl";
import LabelControl from "./LabelControl";
import { getEmptyObject } from "../../inc/schema";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { BootstrapSize } from "../../inc/constants";
import { LayoutContext } from "../../provider/LayoutProvider";
import History from "../../icons/History";
import { IDashboardHashData } from "../dashboardWidgets";
import { IPeriods } from "../../inc/date";

interface IFilterBarProps {
  comparePeriod?: components["schemas"]["Period"];
  isDirty?: boolean;
  isLoading?: boolean;
  onChange?: (filter: components["schemas"]["Filter"]) => void;
  onPeriodsChange?: (periods: IPeriods) => void;
  filter?: components["schemas"]["Filter"];
  period?: components["schemas"]["Period"];
  resetFilter?: () => void;
  collapsibleTagsBar?: boolean;
  width: number;
}

const componentMap = {
  searchTopic: {
    Control: SearchTopicControl,
    barBreakpoint: 550,
  },
  mediaType: {
    Control: MediaTypeControl,
    barBreakpoint: 690,
  },
  sentiment: {
    Control: SentimentControl,
    barBreakpoint: 786,
  },
  unread: {
    Control: UnreadControl,
    barBreakpoint: 920,
  },
  label: {
    Control: LabelControl,
    barBreakpoint: 1100,
  },
};

const emptyFilter = getEmptyObject<components["schemas"]["Filter"]>(
  openapi.components.schemas.Filter as oas30.SchemaObject,
);
const emptyPeriod = getEmptyObject<components["schemas"]["Period"]>(
  openapi.components.schemas.Period as oas30.SchemaObject,
);

const FilterBar = ({
  comparePeriod,
  isDirty,
  isLoading,
  filter = emptyFilter,
  onChange,
  onPeriodsChange,
  period,
  resetFilter,
  collapsibleTagsBar,
  width,
}: IFilterBarProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const history = useHistory();

  const [isTagsPanelExpanded, setIsTagsPanelExpanded] = React.useState(true);
  const searchTopicWhisperRef = React.useRef<WhisperInstance>(null);
  const authorsWhisperRef = React.useRef<WhisperInstance>(null);
  const categoryWhisperRef = React.useRef<WhisperInstance>(null);
  const distributionProvincesWhisperRef = React.useRef<WhisperInstance>(null);
  const editorialRubricsWhisperRef = React.useRef<WhisperInstance>(null);
  const sourcesWhisperRef = React.useRef<WhisperInstance>(null);
  const amountOfFilterRules = React.useMemo(() => {
    let result = 0;
    if (!filter) {
      return result;
    }
    const {
      searchTopicIds = [],
      mediaTypes = [],
      sentiments = [],
      isReadMediaItem,
      excludeLabelIds = [],
      includeLabelIds = [],
      categoryIds = [],
      sourceIds = [],
      authorIds = [],
      isFavouriteMediaItem,
      text,
    } = filter;
    result +=
      searchTopicIds.length +
      mediaTypes.length +
      sentiments.length +
      excludeLabelIds.length +
      includeLabelIds.length +
      categoryIds.length +
      sourceIds.length +
      authorIds.length;
    if (isReadMediaItem) {
      result += 1;
    }
    if (isFavouriteMediaItem) {
      result += 1;
    }
    if (text) {
      result += 1;
    }
    return result;
  }, [filter]);

  const expandAndChange = React.useCallback(
    (newFilter: components["schemas"]["Filter"]) => {
      setIsTagsPanelExpanded(true);
      if (onChange) {
        onChange(newFilter);
      }
    },
    [onChange],
  );

  React.useEffect(() => {
    if (!isDirty) {
      return;
    }
    const handleClickOutsideModal = (event: any) => {
      const filterBarContainer = document.querySelector(
        ".filter-bar-container",
      );
      // If you click outside the filter-bar-container
      // and there aren't any open rs menus
      if (
        resetFilter &&
        filterBarContainer &&
        !filterBarContainer.contains(event.target) &&
        !document.querySelectorAll(".rs-popover,.rs-picker-menu").length
      ) {
        resetFilter();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideModal);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [isDirty, resetFilter]);

  const startFilter = React.useCallback(() => {
    if (!period) {
      return;
    }
    const hashData: IDashboardHashData = {
      filterPeriod: {
        filter,
        period,
        comparePeriod,
      },
    };
    history.push(
      `${history.location.pathname}#${encodeURIComponent(
        JSON.stringify(hashData),
      )}`,
    );
  }, [comparePeriod, filter, history, period]);

  const [barControls, menuControls] = React.useMemo(() => {
    const barControls: React.ReactElement[] = [];
    const menuControls: React.ReactElement[] = [];
    Object.keys(componentMap).forEach((controlKey) => {
      const { Control, barBreakpoint } =
        componentMap[controlKey as "searchTopic"];
      const controlProps = {
        key: controlKey,
        disabled: !onChange,
        filter,
        expandAndChange,
        searchTopicWhisperRef,
      };
      if (width < barBreakpoint) {
        menuControls.push(<Control controlType="menuItem" {...controlProps} />);
        menuControls.push(
          <Divider style={{ margin: 0 }} key={`${controlKey}Divider`} />,
        );
        return;
      }
      barControls.push(<Control controlType="button" {...controlProps} />);
      barControls.push(<Divider vertical key={`${controlKey}Divider`} />);
    });
    return [barControls, menuControls];
  }, [expandAndChange, filter, onChange, width]);

  const periods = React.useMemo(
    () => ({ comparePeriod, period: period || emptyPeriod }),
    [comparePeriod, period],
  );

  return (
    <div
      className={`mt-3 mt-md-4 filter-bar-container${
        isDirty ? " filter-bar-container--dirty" : ""
      }${
        isTagsPanelExpanded
          ? " filter-bar-container--expanded"
          : " filter-bar-container--collapsed"
      }`}
    >
      <Modal
        show={isDirty}
        onHide={resetFilter}
        dialogClassName="d-none"
        backdrop="static"
      />
      {isDirty ? (
        <div className="filter-bar__filter-button">
          <Button
            appearance="primary"
            size="lg"
            className="border border-light"
            onClick={startFilter}
            disabled={!isDirty}
            loading={isLoading}
          >
            {t("toApply")}
          </Button>
        </div>
      ) : null}
      <Panel className="filter-bar" shaded>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            flexDirection: barControls.length ? "row" : "row-reverse",
          }}
        >
          {onChange ? (
            <>
              {barControls}
              <MoreOptionsPopover
                placement={
                  barControls.length
                    ? windowOuterWidth < BootstrapSize.MD
                      ? "rightStart"
                      : "bottom"
                    : "leftStart"
                }
                authorsWhisperRef={authorsWhisperRef}
                categoryWhisperRef={categoryWhisperRef}
                distributionProvincesWhisperRef={
                  distributionProvincesWhisperRef
                }
                isDirty={isDirty}
                filter={filter}
                items={menuControls}
                onChange={expandAndChange}
                showButtonText={width > componentMap.searchTopic.barBreakpoint}
                sourcesWhisperRef={sourcesWhisperRef}
                editorialRubricsWhisperRef={editorialRubricsWhisperRef}
              />

              <div className="col" style={{ position: "unset" }}>
                <AdvancedSearch
                  width={width}
                  disabled={!onChange}
                  filter={filter}
                  onChange={expandAndChange}
                  startFilter={startFilter}
                />
              </div>
            </>
          ) : (
            <div className="col">
              <div className="d-inline-block pt-1 font-weight-bold">
                {t("activeFilters")}:
              </div>
              {history.location.hash ? (
                <IconButton
                  size="lg"
                  className="ms-2"
                  appearance="ghost"
                  title={t("restoreOriginalFilter")}
                  onClick={() => {
                    history.push(history.location.pathname);
                  }}
                  icon={<History />}
                  style={{ border: 0 }}
                ></IconButton>
              ) : null}
            </div>
          )}
          {onPeriodsChange ? (
            <div className="col-auto">
              <PeriodsControl
                periods={periods}
                onPeriodsChange={onPeriodsChange}
              />
            </div>
          ) : null}
        </div>
      </Panel>
      <TagsBar
        authorsWhisperRef={authorsWhisperRef}
        categoryWhisperRef={categoryWhisperRef}
        distributionProvincesWhisperRef={distributionProvincesWhisperRef}
        editorialRubricsWhisperRef={editorialRubricsWhisperRef}
        searchTopicWhisperRef={searchTopicWhisperRef}
        sourcesWhisperRef={sourcesWhisperRef}
        isExpanded={isTagsPanelExpanded}
        filter={filter}
        onChange={onChange}
        collapsible={
          collapsibleTagsBar !== undefined ? collapsibleTagsBar : true
        }
      />
      <div className="views-dashboard-view__filter-bar__toolbar">
        {collapsibleTagsBar !== false ? (
          <ToggleButton
            amountOfActiveFilters={amountOfFilterRules}
            isTagsPanelExpanded={isTagsPanelExpanded}
            setIsTagsPanelExpanded={setIsTagsPanelExpanded}
          />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(FilterBar);
