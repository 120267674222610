import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useLabelAutomators = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { labelAutomators, setLabelAutomators } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      labelAutomators !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    setLabelAutomators(null);
    axios
      .get<components["schemas"]["LabelAutomator"][]>("/labelAutomator/crud")
      .then((res) => {
        setLabelAutomators(
          res.data.reduce<IHashMap<components["schemas"]["LabelAutomator"]>>(
            (prev, labelAutomator) => {
              prev[labelAutomator.labelAutomatorId as string] = labelAutomator;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_labelAutomator"));
      });
  }, [
    auth?.bearer,
    auth?.jwt.userRoles,
    setLabelAutomators,
    labelAutomators,
    t,
  ]);

  return labelAutomators;
});

export default useLabelAutomators;
