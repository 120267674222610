import React, { ReactNode } from "react";
import { FlexboxGrid, Col } from "rsuite";
import LanguageToggle from "../../components/LanguageToggle";

import "./index.scss";

interface IPrDashboardLayoutProps {
  children: ReactNode;
  img?: string;
  img2x?: string;
  img3x?: string;
}

const PrDashboardLayout = (props: IPrDashboardLayoutProps) => {
  return (
    <>
      <FlexboxGrid
        className="prDashboardLayout"
        align="middle"
        justify="center"
      >
        <FlexboxGrid.Item
          componentClass={Col}
          colspan={22}
          className="text-end"
        >
          <LanguageToggle />
        </FlexboxGrid.Item>
        {/*<FlexboxGrid.Item componentClass={Col} colspan={8} xsHidden smHidden>*/}
        {/*  <img*/}
        {/*    className="prDashboardLayout__image"*/}
        {/*    alt="media·web"*/}
        {/*    srcSet={`${props.img2x || "/img/art/key/key@2x.png"} 2x, ${*/}
        {/*      props.img3x || "/img/art/key/key@3x.png"*/}
        {/*    } 3x`}*/}
        {/*  />*/}
        {/*</FlexboxGrid.Item>*/}
        <FlexboxGrid.Item
          componentClass={Col}
          xs={24}
          sm={18}
          md={8}
          className="layout__share-dashboard-layout__content p-3"
        >
          <h2 className="my-5 d-flex align-items-center">
            <span className="me-4 mt-2">Koppel</span>
            <img
              src="/img/prDashboard.png"
              alt="PR-Dashboard"
              className="ms-3"
            />
          </h2>
          {props.children}
        </FlexboxGrid.Item>
        <div className="w-100 align-self-baseline text-center mt-5">
          <img src="/img/migLogo.svg" alt="media·web" />
        </div>
      </FlexboxGrid>
    </>
  );
};

export default PrDashboardLayout;
