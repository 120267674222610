import * as React from "react";
import { SVGProps } from "react";

function SwitchEditorialRubric(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.07}
      height={13.909}
      viewBox="0 0 16.07 13.909"
      fill="currentColor"
      {...props}
    >
      <path
        id="prefix__Path_1883"
        data-name="Path 1883"
        d="M1438.057 1114.051h-9.59a.69.69 0 010-1.38h9.59a.69.69 0 010 1.38z"
        transform="translate(-1422.676 -1111.635)"
      />
      <path
        id="prefix__Path_1884"
        data-name="Path 1884"
        d="M1438.057 1124.543h-9.59a.69.69 0 010-1.379h9.59a.69.69 0 010 1.379z"
        transform="translate(-1422.676 -1116.899)"
      />
      <path
        id="prefix__Path_1885"
        data-name="Path 1885"
        d="M1438.057 1135.036h-9.59a.69.69 0 010-1.379h9.59a.69.69 0 010 1.379z"
        transform="translate(-1422.676 -1122.162)"
      />
      <circle
        id="prefix__Ellipse_327"
        data-name="Ellipse 327"
        cx={1.725}
        cy={1.725}
        r={1.725}
      />
      <circle
        id="prefix__Ellipse_328"
        data-name="Ellipse 328"
        cx={1.725}
        cy={1.725}
        r={1.725}
        transform="translate(0 5.229)"
      />
      <circle
        id="prefix__Ellipse_329"
        data-name="Ellipse 329"
        cx={1.725}
        cy={1.725}
        r={1.725}
        transform="translate(0 10.458)"
      />
    </svg>
  );
}

export default SwitchEditorialRubric;
