import React, { SVGProps } from "react";

const MediaTypePodcast = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="prefix__Podcast"
      xmlns="http://www.w3.org/2000/svg"
      width={8.271}
      height={14.075}
      viewBox="0 0 8.271 14.075"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Group_1632" data-name="Group 1632">
        <g
          id="prefix__Group_1631"
          data-name="Group 1631"
          transform="translate(.409 2.335)"
        >
          <path
            id="prefix__Path_1956"
            data-name="Path 1956"
            d="M1719.895 1167.223h-2.267a.522.522 0 010-1.044h2.267a.522.522 0 010 1.044z"
            transform="translate(-1717.106 -1161.432)"
          />
          <path
            id="prefix__Path_1957"
            data-name="Path 1957"
            d="M1719.895 1161.161h-2.267a.522.522 0 010-1.044h2.267a.522.522 0 010 1.044z"
            transform="translate(-1717.106 -1157.745)"
          />
          <path
            id="prefix__Path_1958"
            data-name="Path 1958"
            d="M1719.895 1155.1h-2.267a.522.522 0 010-1.043h2.267a.522.522 0 010 1.043z"
            transform="translate(-1717.106 -1154.061)"
          />
        </g>
        <g id="prefix__Group_284" data-name="Group 284">
          <path
            id="prefix__Path_1959"
            data-name="Path 1959"
            d="M1722 1159.154h-3.612a2.247 2.247 0 01-2.33-2.149v-6.755a2.247 2.247 0 012.33-2.149H1722a2.247 2.247 0 012.33 2.149v6.755a2.247 2.247 0 01-2.33 2.149zm-3.612-10.009a1.207 1.207 0 00-1.286 1.1V1157a1.207 1.207 0 001.286 1.1H1722a1.207 1.207 0 001.286-1.1v-6.755a1.207 1.207 0 00-1.286-1.1z"
            transform="translate(-1716.061 -1148.101)"
          />
        </g>
      </g>
      <path
        id="prefix__Path_1960"
        data-name="Path 1960"
        d="M1725.808 1178.468a.522.522 0 01-.522-.522v-2.546a.522.522 0 011.044 0v2.541a.522.522 0 01-.522.527z"
        transform="translate(-1721.674 -1164.392)"
      />
      <path
        id="prefix__Path_1961"
        data-name="Path 1961"
        d="M1725 1182.414h-2.928a.522.522 0 110-1.044H1725a.522.522 0 110 1.044z"
        transform="translate(-1719.398 -1168.338)"
      />
    </svg>
  );
};

export default MediaTypePodcast;
