import React from "react";
import Chart from "react-apexcharts";
import {
  ESplitType,
  EValueType,
  IDashboardHashData,
  IDashboardWidgetProps,
} from "../index";
import { Loader, Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { ApexOptions } from "apexcharts";
import {
  getDefaultOptions,
  LINE_CHART_SPACING,
} from "../../../inc/widgets/chart";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInMonths,
  differenceInQuarters,
  differenceInWeeks,
  differenceInYears,
  isBefore,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subSeconds,
} from "date-fns";
import {
  DATE_FORMAT_REVERSE,
  daysInRange,
  differenceInDays,
  getComparedToDate,
  getPeriodDateRange,
  localeFormat,
} from "../../../inc/date";
import { LayoutContext } from "../../../provider/LayoutProvider";
import {
  BootstrapSize,
  MEDIA_TYPE_COLORS,
  REMAINING_AUTHOR_NAME,
} from "../../../inc/constants";
import { I18nContext } from "../../../provider/I18nProvider";
import { useHistory, useLocation } from "react-router-dom";
import { oas30 } from "openapi3-ts";
import { ESentiment, sentimentOptions } from "../../../inc/sentimentOptions";
import { IHashMap } from "../../../inc/data";
import PopoverForm from "../inc/PopoverForm";
import { EChartType, EIntervalType } from "../../../inc/enums";
import { debounce, flatten, sum } from "lodash";
import { getSortedAnalysisItemKeys } from "../inc/default";
import { getEmptyObject } from "../../../inc/schema";
import openapi from "../../../openapi.json";
import CustomLegend from "../inc/CustomLegend";
import "./index.scss";
import { formatInt } from "../../../inc/numbers";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";

interface IAnalysisItem {
  date: string;
  values: number[];
}

export interface ITrendWidgetSettings {
  chartType?: EChartType.curved | EChartType.line | EChartType.column;
  interval?: EIntervalType;
  valueType?: EValueType;
  splitType?: ESplitType;
  limit: number;
  customValueGroups?: string[];
}

const TrendWidget = ({
  comparePeriod,
  compareFilterCacheResponse,
  filter,
  filterCacheResponse,
  height,
  onDelete,
  onFilterChange,
  onSettingsChange,
  onSettingsToggle,
  period,
  settings,
  uid,
  width,
}: IDashboardWidgetProps<ITrendWidgetSettings>) => {
  const history = useHistory();
  const location = useLocation();
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const [analysis, setAnalysis] = React.useState<
    components["schemas"]["Analysis"] | null
  >();
  const [compareAnalysis, setCompareAnalysis] = React.useState<
    components["schemas"]["Analysis"] | null
  >();
  const customTooltipRef = React.useRef<HTMLDivElement>(null);
  const widgetPeriod = React.useMemo<components["schemas"]["Period"]>(
    () =>
      period || {
        dateType: "insertDate",
        periodType: "lastMonth",
      },
    [period],
  );
  const {
    chartType = EChartType.curved,
    customValueGroups = [],
    interval = EIntervalType.auto,
    limit = 5,
    splitType = ESplitType.none,
    valueType = EValueType.count,
  } = settings || {};

  const { dateType } = widgetPeriod;
  const sortedAnalysisItemKeys = React.useMemo(
    () => (analysis ? getSortedAnalysisItemKeys(analysis, splitType) : []),
    [analysis, splitType],
  );
  const hasCustomLegend =
    [ESplitType.none, ESplitType.mediatype, ESplitType.sentiment].indexOf(
      splitType,
    ) === -1;

  const filterPeriodDayCount = React.useMemo(() => {
    switch (widgetPeriod.periodType) {
      case "thisDay":
        return 1;

      case "lastWeek":
        return 7;

      case "lastMonth":
        return 31;

      case "lastQuarter":
        return 90;

      case "yearToDate":
        return differenceInDays(new Date(), startOfYear(new Date()));

      case "custom":
        return widgetPeriod?.endDate && widgetPeriod?.startDate
          ? differenceInDays(
              new Date(widgetPeriod.endDate),
              new Date(widgetPeriod.startDate),
            )
          : 365 * 8;

      default:
      case "all":
        return 365 * 8; // 8 years
    }
  }, [widgetPeriod.endDate, widgetPeriod.periodType, widgetPeriod.startDate]);

  const finalInterval = React.useMemo(() => {
    if (interval !== EIntervalType.auto) {
      return interval;
    }
    let period: "day" | "week" | "month" = "day";
    if (filterPeriodDayCount >= 35) {
      period = "week";
    }
    if (filterPeriodDayCount >= 150) {
      period = "month";
    }
    return period;
  }, [filterPeriodDayCount, interval]);

  const xAxisDates = React.useMemo(() => {
    let startAndEndDates = getPeriodDateRange(widgetPeriod);

    // e.g. period "all"
    if (!startAndEndDates.length) {
      const timestamps = analysis?.items.map((item) =>
        Number(new Date(item.date)),
      );
      if (!timestamps?.length) {
        return [];
      }
      startAndEndDates = [
        new Date(Math.min(...timestamps)),
        new Date(Math.max(...timestamps)),
      ];
    }

    switch (finalInterval) {
      case "week":
        startAndEndDates[0] = startOfWeek(startAndEndDates[0]);
        break;

      case "month":
        startAndEndDates[0] = startOfMonth(startAndEndDates[0]);
        break;

      case "quarter":
        startAndEndDates[0] = startOfQuarter(startAndEndDates[0]);
        break;

      case "year":
        startAndEndDates[0] = startOfYear(startAndEndDates[0]);
        break;
    }

    const [startDate, endDate] = startAndEndDates;
    return daysInRange(startDate, endDate, finalInterval);
  }, [widgetPeriod, finalInterval, analysis?.items]);

  const per = splitType === ESplitType.none ? "mediaType" : splitType;
  React.useEffect(() => {
    setAnalysis(null);
    if (filterCacheResponse?.token) {
      axios
        .get<components["schemas"]["Analysis"]>(
          `/analyse/cache/${filterCacheResponse?.token}/${valueType}/${per}/${finalInterval}/${dateType}`,
        )
        .then((res) => setAnalysis(res.data));
      return;
    }
  }, [dateType, per, valueType, finalInterval, filterCacheResponse?.token]);

  React.useEffect(() => {
    if (!compareFilterCacheResponse?.token) {
      setCompareAnalysis(undefined);
      return;
    }
    setCompareAnalysis(null);
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${compareFilterCacheResponse?.token}/${valueType}/${per}/${finalInterval}/${dateType}`,
      )
      .then((res) => setCompareAnalysis(res.data));
  }, [
    compareFilterCacheResponse?.token,
    dateType,
    finalInterval,
    per,
    valueType,
  ]);

  const getDataPointEndDate = React.useCallback(
    (startDate: Date) => {
      switch (finalInterval) {
        case "day":
          return subSeconds(addDays(startDate, 1), 1);

        case "week":
          return subSeconds(startOfWeek(addWeeks(startDate, 1)), 1);

        case "month":
          return subSeconds(startOfMonth(addMonths(startDate, 1)), 1);

        case "quarter":
          return subSeconds(startOfQuarter(addQuarters(startDate, 1)), 1);

        case "year":
          return subSeconds(startOfYear(addYears(startDate, 1)), 1);
      }
      return startDate;
    },
    [finalInterval],
  );

  const series = React.useMemo(() => {
    if (
      !analysis ||
      filterCacheResponse === null ||
      compareFilterCacheResponse === null
    ) {
      return null;
    }
    const { items, valueGroup } = analysis;
    if (!items || !items.length || !valueGroup) {
      return [];
    }

    const itemMap = items.reduce<IHashMap<IAnalysisItem>>((prev, item) => {
      prev[item.date] = item as IAnalysisItem;
      return prev;
    }, {});

    const reversedXAxisDats = [...xAxisDates].reverse();
    const compareItemMap =
      comparePeriod && period
        ? compareAnalysis?.items.reduce<IHashMap<IAnalysisItem>>(
            (prev, item) => {
              const comparedToDate = getComparedToDate(
                new Date(item.date),
                comparePeriod,
                period,
              );
              const comparedToXAxisDate =
                reversedXAxisDats.find((date) =>
                  isBefore(date, comparedToDate),
                ) || xAxisDates[0];
              prev[localeFormat(comparedToXAxisDate, DATE_FORMAT_REVERSE)] =
                item as IAnalysisItem;
              return prev;
            },
            {},
          )
        : undefined;

    if (splitType === ESplitType.none) {
      let result = [
        {
          name: t("highlightWidget_messages"),
          data: xAxisDates.map((date) => {
            const item = itemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
            return {
              x: date,
              y: item?.values ? sum(item.values) : 0,
            };
          }),
        },
      ];
      if (compareItemMap) {
        result.push({
          name: t("highlightWidget_compareMessages"),
          data: xAxisDates.map((date) => {
            const item =
              compareItemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
            return {
              x: date,
              y: item?.values ? sum(item.values) : 0,
            };
          }),
        });
      }

      return result;
    }

    // custom valueGroups!
    if (limit === 0) {
      return customValueGroups.map((customValueGroup) => {
        const key = valueGroup.findIndex((x) => x === customValueGroup);
        return {
          name: customValueGroup,
          data: xAxisDates.map((date) => {
            const item = itemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
            return {
              x: date,
              y: key >= 0 && item?.values ? item.values[key] : 0,
            };
          }),
        };
      });
    }

    return sortedAnalysisItemKeys
      .filter((key) => analysis.valueGroup[key] !== REMAINING_AUTHOR_NAME)
      .map((key) => ({
        name:
          splitType === ESplitType.sentiment
            ? t(`sentiment${valueGroup[key]}`)
            : valueGroup[key],
        data: xAxisDates.map((date) => {
          const item = itemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
          return {
            x: date,
            y: item?.values ? item.values[key] : 0,
          };
        }),
      }))
      .slice(0, limit)
      .filter((serie) => !!serie.data.find(({ y }) => y > 0));
  }, [
    analysis,
    compareAnalysis?.items,
    compareFilterCacheResponse,
    comparePeriod,
    customValueGroups,
    filterCacheResponse,
    limit,
    period,
    sortedAnalysisItemKeys,
    splitType,
    t,
    xAxisDates,
  ]);

  // make space for bottom legend if needed
  const chartHeight =
    height -
    (splitType === ESplitType.none ? 58 : 80) -
    (hasCustomLegend || comparePeriod ? 16 : 0);
  const chartWidth = Math.floor(0.96 * width) - LINE_CHART_SPACING;

  const tickAmount = React.useMemo(() => {
    const maxTicks = Math.floor(
      chartWidth / (finalInterval === EIntervalType.week ? 120 : 100),
    );

    if (!xAxisDates.length) {
      return maxTicks;
    }

    const start = xAxisDates[0];
    const end = xAxisDates[xAxisDates.length - 1];
    let diffMethod: (
      dateLeft: number | Date,
      dateRight: number | Date,
    ) => number;
    let addMethod: (date: Date, number: number) => Date;
    switch (finalInterval) {
      case EIntervalType.year:
        diffMethod = differenceInYears;
        addMethod = addYears;
        break;

      case EIntervalType.quarter:
        diffMethod = differenceInQuarters;
        addMethod = addQuarters;
        break;

      case EIntervalType.month:
        diffMethod = differenceInMonths;
        addMethod = addMonths;
        break;

      case EIntervalType.week:
        diffMethod = differenceInWeeks;
        addMethod = addWeeks;
        break;

      default:
      case EIntervalType.day:
        diffMethod = differenceInDays;
        addMethod = addDays;
        break;
    }

    let difference = diffMethod(end, start);
    if (addMethod(start, 1).toISOString() !== end.toISOString()) {
      difference += 1;
    }
    return Math.min(difference, maxTicks);
  }, [chartWidth, finalInterval, xAxisDates]);

  const onMarkerClick = React.useCallback(
    (_event: any, _chartContext: any, config: any) => {
      const { seriesIndex, dataPointIndex } = config;
      if (
        (splitType === ESplitType.none && seriesIndex !== 0) ||
        !analysis ||
        !series ||
        // while this is not used, we still want to check this for "disabled" state, e.g. readOnly dashboards
        !onFilterChange
      ) {
        return;
      }
      const newFilter: components["schemas"]["Filter"] = filter
        ? { ...filter }
        : getEmptyObject<components["schemas"]["Filter"]>(
            openapi.components.schemas.Filter as oas30.SchemaObject,
          );
      switch (splitType) {
        case ESplitType.sentiment:
          newFilter.sentiments = [
            parseInt(
              analysis.valueGroup[sortedAnalysisItemKeys[seriesIndex]],
              10,
            ) as ESentiment,
          ];
          break;

        case ESplitType.mediatype:
          newFilter.mediaTypes = [
            series[seriesIndex].name.toLowerCase() as "print",
          ];
          break;
      }
      history.push(
        `${location.pathname}#${encodeURIComponent(
          JSON.stringify({
            filterPeriod: {
              filter: newFilter,
              period: {
                ...widgetPeriod,
                periodType: "custom",
                startDate:
                  series[seriesIndex].data[dataPointIndex].x.toISOString(),
                endDate: getDataPointEndDate(
                  series[seriesIndex].data[dataPointIndex].x,
                ).toISOString(),
              },
            },
          } as IDashboardHashData),
        )}`,
      );
    },
    [
      splitType,
      filter,
      analysis,
      onFilterChange,
      history,
      location.pathname,
      widgetPeriod,
      series,
      getDataPointEndDate,
      sortedAnalysisItemKeys,
    ],
  );

  const onMouseMove = React.useMemo(
    () =>
      debounce((_e: unknown, t: any) => {
        if (!customTooltipRef.current) {
          return;
        }
        const tooltip = t.el.querySelector(".apexcharts-tooltip");
        if (!tooltip) {
          return;
        }
        const bottomTooltip = t.el.querySelector(".apexcharts-xaxistooltip");
        const isVisible = tooltip.className.endsWith("apexcharts-active");
        if (!isVisible || !bottomTooltip) {
          customTooltipRef.current.style.display = "none";
          return;
        }
        customTooltipRef.current.style.display = "block";
        customTooltipRef.current.style.left = `${
          14 +
          parseInt(bottomTooltip.style.left, 10) +
          bottomTooltip.offsetWidth / 2 -
          tooltip.offsetWidth / 2
        }px`;
        customTooltipRef.current.innerText = tooltip.innerText;
      }, 100),
    [],
  );

  const isMultiYear = xAxisDates.length
    ? xAxisDates[0].getFullYear() !==
      xAxisDates[xAxisDates.length - 1].getFullYear()
    : false;

  const chartOptions = React.useMemo(() => {
    if (!series) {
      return null;
    }
    const isStacked = [EChartType.bar, EChartType.column].includes(chartType);
    const options: ApexOptions = {
      ...getDefaultOptions(
        chartType === EChartType.column ? EChartType.column : EChartType.line,
        valueType,
        splitType,
        chartWidth,
        chartHeight,
        Math.max(
          ...(isStacked && series.length
            ? series[0].data.map((_xy, dataIndex) =>
                sum(series.map((serie) => serie.data[dataIndex].y)),
              )
            : flatten(
                series.map((serie) => serie.data.map((point) => point.y)),
              )),
        ),
        false,
        [],
      ),
    };

    let colors = options.colors;
    switch (splitType) {
      case ESplitType.mediatype:
        colors = series.map(
          (serie) =>
            MEDIA_TYPE_COLORS[serie.name.toLowerCase() as "print"] || "#ccc",
        );
        break;

      case ESplitType.sentiment:
        colors = analysis
          ? sortedAnalysisItemKeys.map((key) => {
              const sentiment =
                sentimentOptions[
                  parseInt(analysis.valueGroup[key], 10) as ESentiment
                ];
              return sentiment?.color || "#ccc";
            })
          : [];
    }

    return {
      ...options,
      chart:
        windowOuterWidth > BootstrapSize.LG
          ? {
              ...options.chart,
              events: {
                markerClick: onMarkerClick,
                mouseMove: onMouseMove,
              },
            }
          : options.chart,
      tooltip: {
        ...options.tooltip,
        custom: (options) => {
          const { seriesIndex, dataPointIndex, w, series } = options;
          const seriesNames = w.config.series.map(
            (serie: { name?: string }) => serie.name || "",
          );
          return `<div class="arrow_box arrow_box--${
            chartType === EChartType.column ? "left" : "bottom"
          }"><span>${seriesNames[seriesIndex]}: ${formatInt(
            series[seriesIndex][dataPointIndex],
          )}</span></div>`;
        },
        x: {
          ...options.tooltip?.x,
          formatter: (_val: number, opts: any) => {
            const serie =
              series[opts.seriesIndex] || opts.series[opts.seriesIndex];
            const date = serie.data[opts.dataPointIndex].x;
            switch (finalInterval) {
              case EIntervalType.week:
                return `${localeFormat(date, "ww")}: ${localeFormat(
                  date,
                  "dd MMM",
                )} - ${localeFormat(getDataPointEndDate(date), "dd MMM")}`;

              case EIntervalType.month:
                return localeFormat(date, "MMMM yyyy");

              case EIntervalType.quarter:
                return `Q${localeFormat(date, "Q yyyy")}`;

              case EIntervalType.year:
                return localeFormat(date, "yyyy");

              default:
                return localeFormat(date, "dd MMM");
            }
          },
        },
      },
      xaxis: {
        ...options.xaxis,
        // type: xaxisType,
        labels: {
          ...options.xaxis?.labels,
          formatter: (value?: string) => {
            if (!value) {
              return "";
            }
            const date = new Date(value);
            switch (finalInterval) {
              case EIntervalType.week:
                return localeFormat(date, isMultiYear ? "ww yyyy" : "ww");

              case EIntervalType.month:
                return localeFormat(
                  date,
                  // doesn't work with single quote
                  isMultiYear ? 'MMM  "yy' : "MMMM",
                ).replace('"', "'");

              case EIntervalType.quarter:
                return `Q${localeFormat(date, isMultiYear ? "Q yyyy" : "Q")}`;

              case EIntervalType.year:
                return localeFormat(date, "yyyy");

              default:
                return localeFormat(date, "dd MMM");
            }
          },
        },
        tickAmount:
          options.xaxis?.tickAmount && tickAmount
            ? Math.min(tickAmount, options.xaxis?.tickAmount as number)
            : tickAmount,
        tickPlacement: "on",
      },
      yaxis: {
        ...options.yaxis,
        title: "",
      },
      stroke: {
        curve: chartType === EChartType.curved ? "smooth" : "straight",
      } as ApexStroke,
      colors,
      markers: {
        ...options.markers,
        strokeColors: colors,
      },
      legend: {
        ...options.legend,
        show: !hasCustomLegend,
      },
    } as ApexOptions;
  }, [
    analysis,
    chartHeight,
    chartType,
    chartWidth,
    finalInterval,
    getDataPointEndDate,
    hasCustomLegend,
    isMultiYear,
    onMarkerClick,
    onMouseMove,
    series,
    sortedAnalysisItemKeys,
    splitType,
    tickAmount,
    valueType,
    windowOuterWidth,
  ]);

  const onCustomLegendChange = React.useCallback(
    (customValueGroups: any) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        limit: 0,
        customValueGroups,
      });
    },
    [onSettingsChange, settings, uid],
  );

  const panelBody = React.useMemo(() => {
    if (!analysis || !series || !chartOptions) {
      return <Loader backdrop />;
    }

    let noDataFound =
      (!series.length || series[0].data.length < 2) &&
      !series.find((serie) => !!serie.data[0].y);

    return (
      <div
        key={chartType}
        className="chart"
        style={{
          width: chartWidth,
          height: chartHeight,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {noDataFound ? (
          <InsufficientDataBody
            description={
              interval === EIntervalType.auto
                ? undefined
                : t("insufficientDataForInterval")
            }
          />
        ) : (
          <Chart
            options={chartOptions}
            series={series}
            type={chartType === EChartType.column ? "bar" : "line"}
            height={chartHeight}
            width={chartWidth}
          />
        )}
        <div
          className="arrow_box arrow_box--bottom trend-widget__custom-tooltip"
          ref={customTooltipRef}
        />
        {hasCustomLegend ? (
          <CustomLegend
            colors={chartOptions.colors as string[]}
            options={analysis.valueGroup}
            value={series.map((serie) => serie.name)}
            onChange={onCustomLegendChange}
          />
        ) : null}
      </div>
    );
  }, [
    analysis,
    chartHeight,
    chartOptions,
    chartType,
    chartWidth,
    hasCustomLegend,
    interval,
    onCustomLegendChange,
    series,
    t,
  ]);

  const popoverForm = React.useMemo(
    () =>
      onSettingsChange && settings ? (
        <PopoverForm<ITrendWidgetSettings>
          onSettingsChange={onSettingsChange}
          settings={settings}
          showIntervalOption
          chartTypes={[EChartType.curved, EChartType.line, EChartType.column]}
          showRemainingOption={false}
          splitTypes={Object.keys(ESplitType) as ESplitType[]}
          topResultOptions={
            [
              ESplitType.source,
              ESplitType.labels,
              ESplitType.category,
              ESplitType.searchtopic,
              ESplitType.author,
            ].includes(splitType)
              ? [3, 5, 0]
              : undefined
          }
          widgetUid={uid}
        />
      ) : undefined,
    [onSettingsChange, settings, splitType, uid],
  );

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        allowImageDownload={!!onSettingsChange}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={popoverForm}
        subtitle={`${t(`valueType_${valueType}`)}${
          splitType !== ESplitType.none
            ? ` > ${t(`splitType_${splitType}`)}`
            : ""
        }`}
        title={`Trend`}
      />
    ),
    [
      onDelete,
      onSettingsChange,
      onSettingsToggle,
      popoverForm,
      splitType,
      t,
      valueType,
    ],
  );

  return (
    <Panel
      bordered={true}
      header={header}
      className={`views__dashboard-view__widgets__trend-widget views__dashboard-view__widgets__trend-widget--${chartType}`}
    >
      {panelBody}
    </Panel>
  );
};

export default React.memo(TrendWidget) as typeof TrendWidget;
