import React, { SVGProps } from "react";

const LabelFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13.974}
    height={13.975}
    viewBox="0 0 13.974 13.975"
    fill="currentColor"
    {...props}
  >
    <path d="M13.924 1.319a1.288 1.288 0 00-1.269-1.27L7.516 0h-.021a2.182 2.182 0 00-1.534.635L.37 6.222a1.284 1.284 0 000 1.813l5.564 5.564a1.282 1.282 0 001.813 0l5.587-5.587a2.182 2.182 0 00.635-1.554zM11.65 4.254a1.365 1.365 0 110-1.93 1.361 1.361 0 01-.001 1.931z" />
  </svg>
);
export default LabelFilled;
