import Lightbox from "../Lightbox";
import React from "react";
import { createPortal } from "react-dom";
import { LayoutContext } from "../../provider/LayoutProvider";
import "./index.scss";

interface IAnchoredLightboxedItemProps {
  anchorPoint: { x: number; y: number };
  anchorPosition?: "bottomLeft" | "bottomRight" | "topLeft" | "topRight";
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
}

const modalRoot = document.getElementById("modal-root");

const AnchoredLightboxedItem = ({
  anchorPoint,
  anchorPosition,
  children,
  className,
  onClose,
}: IAnchoredLightboxedItemProps) => {
  const { windowInnerHeight, windowOuterWidth } =
    React.useContext(LayoutContext);
  const [menuEl, setMenuEl] = React.useState<HTMLDivElement>();
  const rect = React.useMemo(() => {
    return menuEl?.getBoundingClientRect();
  }, [menuEl]);
  const ref = React.useCallback((el: HTMLDivElement) => {
    if (el) {
      setMenuEl(el);
    }
  }, []);

  const doClose = React.useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }
      onClose();
    },
    [onClose]
  );

  const style = React.useMemo(() => {
    const result: React.CSSProperties = {
      overflow: "auto",
      position: "absolute",
    };

    let finalAnchorPosition = anchorPosition;
    if (!finalAnchorPosition) {
      finalAnchorPosition = "topLeft";
      if (anchorPoint.y > windowInnerHeight / 2) {
        finalAnchorPosition =
          anchorPoint.x > windowOuterWidth / 2 ? "bottomRight" : "bottomLeft";
      }
    }

    switch (finalAnchorPosition) {
      case "bottomLeft":
        result.left = anchorPoint?.x || 0;
        result.top = rect ? (anchorPoint?.y || 0) - rect.height : 0;
        break;

      case "topLeft":
        result.left = anchorPoint?.x || 0;
        result.top = anchorPoint?.y || 0;
        break;

      case "bottomRight":
        result.left = rect ? (anchorPoint?.x || 0) - rect.width : 0;
        result.top = rect ? (anchorPoint?.y || 0) - rect.height : 0;
        break;

      case "topRight":
      default:
        result.left = rect ? (anchorPoint?.x || 0) - rect.width : 0;
        result.top = anchorPoint?.y;
        break;
    }
    result.maxHeight = windowInnerHeight - result.top - 10;
    return result;
  }, [
    anchorPoint.x,
    anchorPoint.y,
    anchorPosition,
    rect,
    windowInnerHeight,
    windowOuterWidth,
  ]);

  return modalRoot
    ? createPortal(
        <Lightbox onClose={doClose} transparent className={className}>
          <div className="anchored-lightboxed-item" ref={ref} style={style}>
            {children}
          </div>
        </Lightbox>,
        modalRoot
      )
    : null;
};

export default React.memo(
  AnchoredLightboxedItem
) as typeof AnchoredLightboxedItem;
