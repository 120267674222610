import React from "react";
import "./index.scss";
import Help from "../../../icons/Help";
import { Icon } from "rsuite";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { I18nContext } from "../../../provider/I18nProvider";
import Support from "../../../icons/Support";
import Tour from "../../../icons/Tour";
import ReactGA from "react-ga";

const HelpBar = () => {
  const { isHelpBarOpen, setIsHelpBarOpen, startTour } =
    React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);

  React.useEffect(() => {
    const Tawk_API = (window as any).Tawk_API;
    if (!Tawk_API || !Tawk_API.showWidget || !Tawk_API.hideWidget) {
      return;
    }
    isHelpBarOpen ? Tawk_API.showWidget() : Tawk_API.hideWidget();
    if (isHelpBarOpen) {
      ReactGA.event({
        category: "Reddingsboei",
        action: `Reddingsboei geopend`,
      });
    }
  }, [isHelpBarOpen]);

  return (
    <div
      className={`app-layout__help-bar d-none d-md-flex ${
        isHelpBarOpen ? " app-layout__help-bar--is-open" : ""
      }`}
    >
      <Help
        onClick={() => {
          setIsHelpBarOpen((open) => !open);
        }}
        style={{
          marginTop: 2,
        }}
        title={t("helpOptions")}
      />
      <Support
        className="help-bar__support-icon"
        title={t("openManual")}
        onClick={() => {
          window.open("https://support.mediaweb.eu/");
        }}
      />
      <Icon
        icon="bus"
        componentClass={Tour}
        title={t(startTour ? "navigation_tour" : "navigationTourNotAvailable")}
        disabled={!startTour}
        onClick={startTour}
        className={`help-bar__tour-icon${
          !startTour ? " help-bar__tour-icon--disabled" : ""
        }`}
      />
    </div>
  );
};

export default HelpBar;
