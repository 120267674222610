import React, { SVGProps } from "react";

const Tour = (
  props: SVGProps<SVGSVGElement> & { disabled: boolean; title: string }
) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25.972}
      height={37.537}
      viewBox="0 0 25.972 37.537"
      fill="currentColor"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      {...props}
    >
      <title>{props.title}</title>
      <path
        d="M1663.088 1555.809a.813.813 0 01-.813-.813v-2.6a.813.813 0 111.625 0v2.6a.812.812 0 01-.812.813z"
        transform="translate(-1651.303 -1531.407)"
      />
      <path
        d="M1663.088 1568.944a.812.812 0 01-.813-.812v-2.241a.813.813 0 111.625 0v2.241a.811.811 0 01-.812.812z"
        transform="translate(-1651.303 -1531.407)"
      />
      <path
        d="M1672.275 1550.486h-16.159a.818.818 0 01-.584-.246l-4-4.125a.815.815 0 010-1.135l4-4.095a.817.817 0 01.582-.244h16.159a.812.812 0 01.813.812v8.221a.811.811 0 01-.811.812zm-15.815-1.625h15v-6.595h-15l-3.208 3.285z"
        transform="translate(-1651.303 -1531.407)"
      />
      <path
        d="M1672.461 1564.029H1656.3a.812.812 0 01-.813-.812V1555a.812.812 0 01.813-.812h16.158a.812.812 0 01.584.246l4 4.125a.812.812 0 010 1.134l-4 4.1a.815.815 0 01-.581.236zm-15.346-1.625h15l3.208-3.285-3.209-3.31h-15z"
        transform="translate(-1651.303 -1531.407)"
      />
      {isHovered && !props.disabled ? (
        <path
          d="M1663.088 1486.759a.721.721 0 00-.721.721v2.885a.721.721 0 101.442 0v-2.885a.721.721 0 00-.721-.721zm-7.432 3.3a.721.721 0 00-.507 1.228l2.04 2.039a.717.717 0 101.014-1.014l-2.04-2.039a.712.712 0 00-.507-.214zm14.864 0a.716.716 0 00-.507.214l-2.039 2.039a.717.717 0 001.014 1.014l2.039-2.039a.721.721 0 00-.507-1.228z"
          transform="translate(-1651.303 -1486.759)"
        />
      ) : null}
    </svg>
  );
};

export default Tour;
