import React, { SVGProps } from "react";

const WidgetTypeSource = (
  props: SVGProps<SVGSVGElement> & { color1?: string; color2?: string }
) => {
  const { color1 = "#e68c91", color2 = "#96afaf", ...svgProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 45.672 48.327"
      {...svgProps}
    >
      <g transform="translate(-1577.11 -618.265)">
        <path
          d="M1597.128 640.434a6.893 6.893 0 116.893-6.893 6.9 6.9 0 01-6.893 6.893zm0-10.424a3.531 3.531 0 103.531 3.531 3.536 3.536 0 00-3.531-3.531z"
          transform="translate(2.817 1.799)"
          fill={color1}
        />
        <path
          d="M1596.207 662.023a1.68 1.68 0 01-1.681-1.681V641.23a1.681 1.681 0 113.362 0v19.112a1.681 1.681 0 01-1.681 1.681z"
          transform="translate(3.739 4.569)"
          fill={color1}
        />
        <path
          d="M1603.751 645.084a1.681 1.681 0 01-1.189-2.869 11.277 11.277 0 000-15.95 1.681 1.681 0 012.377-2.377 14.637 14.637 0 010 20.7 1.675 1.675 0 01-1.188.496z"
          transform="translate(5.358 1.101)"
          fill={color2}
        />
        <path
          d="M1589.334 645.084a1.677 1.677 0 01-1.189-.492 14.641 14.641 0 010-20.7 1.681 1.681 0 012.377 2.377 11.28 11.28 0 000 15.95 1.681 1.681 0 01-1.188 2.869z"
          transform="translate(1.448 1.101)"
          fill={color2}
        />
        <path
          d="M1585.168 652.418a1.678 1.678 0 01-1.189-.492 23.454 23.454 0 010-33.168 1.681 1.681 0 012.377 2.377 20.093 20.093 0 000 28.414 1.68 1.68 0 01-1.188 2.869z"
          fill={color2}
        />
        <path
          d="M1608.373 652.418a1.68 1.68 0 01-1.189-2.869 20.09 20.09 0 000-28.414 1.681 1.681 0 012.377-2.377 23.451 23.451 0 010 33.168 1.675 1.675 0 01-1.188.492z"
          transform="translate(6.35)"
          fill={color2}
        />
      </g>
    </svg>
  );
};

export default WidgetTypeSource;
