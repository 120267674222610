import React from "react";
import { Loader, Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { I18nContext } from "../../../provider/I18nProvider";
import Chart from "react-apexcharts";
import { round, startCase, sum } from "lodash";
import { CHART_COLORS } from "../../../inc/constants";
import { ApexOptions } from "apexcharts";
import { currency, formatInt } from "../../../inc/numbers";
import { useBenchmarkAnalysisCache } from "../inc/useBenchmarkAnalysisCache";
import { EValueType, IBenchmarkWidgetProps } from "../index";
import { components } from "../../../types/openapi";
import { EChartType } from "../../../inc/enums";
import PopoverForm from "../inc/PopoverForm";
import { ESplitType } from "../../dashboardWidgets";
import { formatValue, getDonutLegendConfig } from "../../../inc/widgets/chart";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";
import "./index.scss";

interface ISourceCountMap {
  [source: string]: number;
}

export interface ISourceBenchmarkWidgetSettings {
  chartType?: EChartType.donut | EChartType.bar;
  matchTextIndex: number;
  notes?: string;
  valueType: EValueType;
}

const SourceBenchmarkWidget = ({
  benchmark,
  height,
  onDelete,
  onNotesChange,
  onSettingsChange,
  settings,
  uid,
  width,
}: IBenchmarkWidgetProps<ISourceBenchmarkWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);

  const defaultSettings = React.useMemo(
    () => ({
      chartType: EChartType.donut,
      matchTextIndex: 0,
      valueType: EValueType.count,
    }),
    [],
  );
  const { chartType, matchTextIndex, valueType } = {
    ...defaultSettings,
    ...settings,
  };

  const matchText = benchmark.matchTexts[matchTextIndex] as
    | components["schemas"]["BenchmarkMatchText"]
    | undefined;
  const benchmarkAnalysis = useBenchmarkAnalysisCache(
    `/benchmark/analyse/${valueType}/source/none/publicationDate`,
    benchmark,
  );
  const benchmarkAnalysisItemsIndex = React.useMemo(
    () =>
      benchmarkAnalysis
        ? Object.values(benchmarkAnalysis.items).findIndex(
            (item) => item.benchmarkName === matchText?.name,
          )
        : -1,
    [benchmarkAnalysis, matchText?.name],
  );

  const sourceCountMap = React.useMemo<ISourceCountMap>(() => {
    if (!benchmarkAnalysis || benchmarkAnalysisItemsIndex < 0) {
      return {};
    }
    return benchmarkAnalysis.valueGroup.reduce<ISourceCountMap>(
      (prev, sourceName, sourceIndex) => {
        prev[sourceName] =
          benchmarkAnalysis.items[benchmarkAnalysisItemsIndex].values[
            sourceIndex
          ];
        return prev;
      },
      {},
    );
  }, [benchmarkAnalysis, benchmarkAnalysisItemsIndex]);

  const topSourceNames = React.useMemo<string[]>(() => {
    return Object.keys(sourceCountMap)
      .sort((a, b) => sourceCountMap[b] - sourceCountMap[a])
      .slice(0, 10);
  }, [sourceCountMap]);

  const chartOptions = React.useMemo<ApexOptions>(() => {
    const result: ApexOptions = {
      colors: CHART_COLORS,
      chart: {
        fontFamily: "Gelion, sans-serif",
        toolbar: {
          show: false,
        },
        zoom: { enabled: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: chartType !== EChartType.donut,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        custom:
          chartType === EChartType.donut
            ? ({ series, seriesIndex }) => {
                const total = series.reduce(
                  (carry: number, value: number) => carry + value,
                );
                return `<div class="arrow_box arrow_box--bottom"><span>${
                  topSourceNames[seriesIndex]
                }: ${formatValue(valueType, series[seriesIndex])} (${round(
                  (series[seriesIndex] / total) * 100,
                  1,
                )}%)</span></div>`;
              }
            : ({ dataPointIndex, series, seriesIndex, splitType }) => {
                const value = series[seriesIndex][dataPointIndex];
                return `<div class="arrow_box arrow_box--left"><span>${formatValue(
                  valueType,
                  value,
                )}${
                  splitType !== ESplitType.none
                    ? ` (${round((value / sum(series[0])) * 100, 1)}%)`
                    : ""
                }</span></div>`;
              },
      },
      xaxis: {
        categories: topSourceNames.map((source) =>
          source.includes(".")
            ? source.toLowerCase()
            : startCase(source.toLowerCase()),
        ),
        labels: {
          formatter: (val: any) =>
            valueType && ["ave", "summedAve"].indexOf(valueType) >= 0
              ? currency(val, 0)
              : formatInt(val),
        },
      },
    };

    if (chartType === EChartType.donut) {
      result.labels = topSourceNames;
      result.legend = getDonutLegendConfig({
        chartHeight: height - 58,
        chartWidth: Math.floor(0.96 * width),
        valueType,
      });
    }
    return result;
  }, [chartType, height, topSourceNames, valueType, width]);

  const series = React.useMemo(() => {
    if (chartType === EChartType.donut) {
      return topSourceNames.map((sourceName) => sourceCountMap[sourceName]);
    }
    return matchText
      ? [
          {
            name: matchText.name,
            data: topSourceNames.map(
              (sourceName) => sourceCountMap[sourceName],
            ),
          },
        ]
      : [];
  }, [chartType, matchText, sourceCountMap, topSourceNames]);

  const header = React.useMemo(() => {
    return matchText ? (
      <WidgetPanelHeader
        popoverForm={
          onSettingsChange ? (
            <PopoverForm
              onSettingsChange={onSettingsChange}
              settings={
                {
                  ...defaultSettings,
                  ...settings,
                } as ISourceBenchmarkWidgetSettings
              }
              chartTypes={{
                donut: true,
                bar: true,
              }}
              widgetUid={uid}
            />
          ) : undefined
        }
        notes={settings?.notes}
        onNotesChange={onNotesChange}
        allowImageDownload
        onDelete={onDelete}
        title={`${t("sources")}`}
        subtitle={`${t(`valueType_${valueType}`)} - ${t("matchText")} ${t(
          matchText.name,
        )}`}
      />
    ) : (
      <Loader />
    );
  }, [
    defaultSettings,
    matchText,
    onDelete,
    onNotesChange,
    onSettingsChange,
    settings,
    t,
    uid,
    valueType,
  ]);

  const chartWidth = Math.floor(0.96 * width);
  const chartHeight = height - 105;

  return (
    <Panel
      bordered
      header={header}
      className={`views__benchmark-view__widgets__source-widget`}
    >
      {series ? (
        series.length ? (
          <div
            className="chart"
            style={{
              width: chartWidth,
              height: chartHeight,
              overflow: "hidden",
            }}
          >
            <Chart
              key={`${chartType}_${valueType}`}
              type={chartType as any}
              series={series}
              options={chartOptions}
              width={chartWidth}
              height={chartHeight}
            />
          </div>
        ) : (
          <InsufficientDataBody />
        )
      ) : (
        <div style={{ width }}>
          <Loader backdrop />
        </div>
      )}
    </Panel>
  );
};

export default SourceBenchmarkWidget;
