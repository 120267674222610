import React from "react";
import LabelPopoverSelect from "../../../../components/LabelPopover/Select";
import { components } from "../../../../types/openapi";
import LabelButton from "./LabelButton";

interface ILabelSelectorProps {
  editable: boolean;
  label: components["schemas"]["Label"] | null;
  onChange: (label: components["schemas"]["Label"]) => void;
}

const LabelSelector = ({ editable, label, onChange }: ILabelSelectorProps) =>
  editable ? (
    <LabelPopoverSelect value={label?.labelId} onChange={onChange}>
      <span>
        <LabelButton label={label} />
      </span>
    </LabelPopoverSelect>
  ) : (
    <LabelButton label={label} />
  );
export default LabelSelector;
