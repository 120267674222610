import React from "react";
import { ESplitType, EValueType, IDashboardWidgetProps } from "../index";
import { Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { getDefaultOptions } from "../../../inc/widgets/chart";
import { I18nContext } from "../../../provider/I18nProvider";
import useLabels from "../../../hooks/useLabels";
import PopoverForm from "../inc/PopoverForm";
import { EChartType } from "../../../inc/enums";
import {
  chartSortedSentiments,
  ESentiment,
  sentimentOptions,
} from "../../../inc/sentimentOptions";
import { lowerFirst } from "lodash";
import { MEDIA_TYPE_COLORS } from "../../../inc/constants";
import {
  getSeriesMaxValue,
  getSortedAnalysisSplitItemKeys,
  getVisibleValueGroupKeys,
  useSeries,
} from "../inc/default";

import "./index.scss";
import AnalysisSplitChartBody from "../inc/AnalysisSplitChartBody";
import CustomLegend from "../inc/CustomLegend";
import { ApexOptions } from "apexcharts";

const DEFAULT_LIMIT = 10;

export interface ILabelWidgetSettings {
  chartType?: EChartType.donut | EChartType.column;
  customValueGroups?: string[];
  limit: number;
  showRemaining: boolean;
  splitType?: ESplitType;
  valueType?: EValueType;
}

const LabelWidget = ({
  colWidth,
  filterCacheResponse,
  height,
  onDelete,
  onFilterChange,
  onSettingsChange,
  onSettingsToggle,
  settings,
  uid,
  width,
}: IDashboardWidgetProps<ILabelWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);
  const labels = useLabels();
  const [analysisSplit, setAnalysisSplit] = React.useState<
    components["schemas"]["AnalysisSplit"] | null
  >();
  const {
    chartType = EChartType.donut,
    valueType = EValueType.count,
    limit = DEFAULT_LIMIT,
    showRemaining = false,
  } = settings || {};
  let { splitType = ESplitType.none } = settings || {};
  if (chartType === EChartType.donut) {
    splitType = ESplitType.none;
  }
  const type = chartType === "column" ? "bar" : chartType;
  const hasRemaining = !!(
    limit &&
    analysisSplit &&
    showRemaining &&
    analysisSplit.items.length > limit
  );
  const token = filterCacheResponse?.token;

  React.useEffect(() => {
    setAnalysisSplit(null);
    axios
      .get<components["schemas"]["AnalysisSplit"]>(
        `/analyse/cache/${token}/${lowerFirst(
          valueType.replace("summed", "")
        )}Per${
          splitType === ESplitType.mediatype ? "Mediatype" : "Sentiment"
        }/labels`
      )
      .then((res) => setAnalysisSplit(res.data));
  }, [splitType, token, valueType]);

  const customValueGroups = React.useMemo(
    () => (limit ? undefined : settings?.customValueGroups || []),
    [limit, settings?.customValueGroups]
  );
  const sortedAnalysisSplitItemKeys = React.useMemo(() => {
    if (!analysisSplit) {
      return [];
    }
    return customValueGroups
      ? customValueGroups.map((selectedValueGroup) =>
          analysisSplit.perGroup.indexOf(selectedValueGroup)
        )
      : getSortedAnalysisSplitItemKeys(analysisSplit);
  }, [analysisSplit, customValueGroups]);

  const series = useSeries(
    analysisSplit,
    chartType,
    splitType,
    valueType,
    "",
    limit,
    hasRemaining,
    customValueGroups
  );

  const chartWidth = Math.floor(0.96 * width);
  // make space for bottom legend if needed
  const chartHeight = height - (splitType === ESplitType.none ? 58 : 80);
  const chartOptions = React.useMemo<ApexOptions>(() => {
    if (!analysisSplit) {
      return {};
    }
    const yAxisLabels = (
      customValueGroups ||
      sortedAnalysisSplitItemKeys.map((key) => analysisSplit.perGroup[key])
    ).slice(0, limit || DEFAULT_LIMIT);
    if (hasRemaining) {
      yAxisLabels.push(t("remainingLabels"));
    }
    const options = getDefaultOptions(
      chartType,
      valueType,
      splitType,
      chartWidth,
      chartHeight,
      getSeriesMaxValue(series),
      colWidth === 1,
      yAxisLabels
    );
    let colors = options.colors;
    const valueGroupKeys = getVisibleValueGroupKeys(analysisSplit, splitType);
    switch (splitType) {
      case ESplitType.sentiment:
        colors = valueGroupKeys.map(
          (key) => sentimentOptions[key as ESentiment].color || "#ccc"
        );
        break;

      case ESplitType.mediatype:
        colors = valueGroupKeys.map(
          (key) => MEDIA_TYPE_COLORS[analysisSplit.valueGroup[key] as "print"]
        );

        break;
    }
    return {
      ...options,
      colors,
      chart: {
        ...options.chart,
        events: {
          dataPointSelection: (
            event: React.MouseEvent | null,
            _chartContext: any,
            config: any
          ) => {
            // non-primary button?
            if (event?.button || !labels || !onFilterChange) {
              return;
            }
            const { dataPointIndex, seriesIndex } = config;
            const labelName =
              analysisSplit.perGroup[
                sortedAnalysisSplitItemKeys[dataPointIndex]
              ];
            const label = Object.values(labels).find(
              (label) => label.name === labelName
            );
            if (!label || !label.labelId) {
              return;
            }
            const filterUpdate: Partial<components["schemas"]["Filter"]> = {};
            if (label && label.labelId) {
              filterUpdate.includeLabelIds = [[label.labelId]];
            }
            switch (splitType) {
              case ESplitType.sentiment:
                filterUpdate.sentiments = [chartSortedSentiments[seriesIndex]];
                break;

              case ESplitType.mediatype:
                filterUpdate.mediaTypes = [
                  analysisSplit.valueGroup[seriesIndex] as "print",
                ];
            }
            if (Object.keys(filterUpdate).length) {
              onFilterChange(filterUpdate);
            }
          },
        },
      },
    };
  }, [
    analysisSplit,
    chartHeight,
    chartType,
    chartWidth,
    colWidth,
    customValueGroups,
    hasRemaining,
    labels,
    limit,
    onFilterChange,
    series,
    sortedAnalysisSplitItemKeys,
    splitType,
    t,
    valueType,
  ]);

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        allowImageDownload={!!onSettingsChange}
        title={t("Label")}
        subtitle={`${t(`valueType_${valueType}`)}${
          splitType !== ESplitType.none
            ? ` > ${t(`splitType_${splitType}`)}`
            : ""
        }`}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={
          onSettingsChange && settings ? (
            <PopoverForm<ILabelWidgetSettings>
              onSettingsChange={onSettingsChange}
              settings={settings}
              chartTypes={[EChartType.donut, EChartType.bar]}
              topResultOptions={[3, 5, 10, 0]}
              showRemainingOption
              splitTypes={[
                ESplitType.none,
                ESplitType.mediatype,
                ESplitType.sentiment,
              ]}
              widgetUid={uid}
            />
          ) : undefined
        }
      />
    ),
    [
      onDelete,
      onSettingsChange,
      onSettingsToggle,
      settings,
      splitType,
      t,
      uid,
      valueType,
    ]
  );

  const onChange = React.useCallback(
    (customValueGroups: string[]) => {
      if (!onSettingsChange || !settings) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        limit: 0,
        showRemaining: false,
        customValueGroups,
      });
    },
    [onSettingsChange, settings, uid]
  );

  const colors = chartOptions.colors || [];

  return (
    <Panel
      bordered={true}
      header={header}
      className="views__dashboard-view__widgets__label-widget"
    >
      <AnalysisSplitChartBody
        analysisSplit={analysisSplit}
        splitType={splitType}
        type={type}
        chartOptions={chartOptions}
        series={series}
        width={width}
        height={height - (customValueGroups ? 20 : 0)}
      />
      {customValueGroups && analysisSplit ? (
        <CustomLegend
          colors={
            type === "bar"
              ? analysisSplit.perGroup.map(() => colors[0])
              : colors
          }
          onChange={onChange}
          options={analysisSplit.perGroup}
          value={customValueGroups}
        />
      ) : null}
    </Panel>
  );
};

export default React.memo(LabelWidget) as typeof LabelWidget;
