import React from "react";
import { Icon, Input, InputGroup } from "rsuite";
import PasswordShow from "../../../icons/PasswordShow";
import PasswordHide from "../../../icons/PasswordHide";

interface IPasswordInputProps {
  onChange?: (
    newValue: string,
    event: React.SyntheticEvent<HTMLElement, Event>
  ) => void;
  value?: string;
}

const PasswordInput = ({ value, onChange, ...props }: IPasswordInputProps) => {
  const [plainPassword, setPlainPassword] = React.useState<boolean>(false);

  return (
    <InputGroup>
      <Input
        {...props}
        value={value}
        onChange={onChange}
        type={plainPassword ? "text" : "password"}
      />
      <InputGroup.Button onClick={() => setPlainPassword(!plainPassword)}>
        <Icon
          icon="eye"
          componentClass={plainPassword ? PasswordHide : PasswordShow}
        />
      </InputGroup.Button>
    </InputGroup>
  );
};
export default PasswordInput;
