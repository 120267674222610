import React, { Dispatch, SetStateAction } from "react";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { Alert, Button, Checkbox, Form, Modal, Popover } from "rsuite";
import SchemaTable from "../../../components/SchemaTable";
import { components } from "../../../types/openapi";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";

import { LayoutContext } from "../../../provider/LayoutProvider";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import ajv from "../../../inc/ajv";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import axios from "../../../inc/axios";
import ActionButton from "../../../components/ActionButton";
import { findDOMNode } from "react-dom";
import { I18nContext } from "../../../provider/I18nProvider";
import useSubscribers from "../../../hooks/useSubscribers";
import MwWhisper from "../../../components/MwWhisper";
import { getMemberColumnConfig } from "./MemberColumnConfig";
import { AuthContext } from "../../../provider/AuthProvider";
import useCustomers from "../../../hooks/useCustomers";

interface IEditorialSubscribersModalProps {
  show: boolean;
  editorial: components["schemas"]["Editorial"];
  editorialSubscribers: components["schemas"]["EditorialSubscriber"][];
  onClose: () => void;
  onChange: Dispatch<
    SetStateAction<components["schemas"]["EditorialSubscriber"][] | undefined>
  >;
}

const subscriberSchema = openapi.components.schemas
  .Subscriber as oas30.SchemaObject;
const validate = ajv.compile(subscriberSchema);

const { properties } = subscriberSchema;
if (!properties) {
  throw new Error("Incomplete subscriber schema");
}

const EditorialSubscribersModal = (props: IEditorialSubscribersModalProps) => {
  const { show, editorial, editorialSubscribers, onClose, onChange } = props;
  const { setSubscribers } = React.useContext(ApiDataContext);
  const { windowInnerHeight } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const subscribers = useSubscribers();
  const customers = useCustomers();

  const [newSubscriber, setNewSubscriber] = React.useState<
    components["schemas"]["Subscriber"]
  >(getEmptyObject(subscriberSchema));
  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const popoverRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (!isPopoverOpen) {
      return;
    }
    const handleClickOutsidePopover = (event: any) => {
      const popoverDom = findDOMNode(popoverRef.current);
      const popoverTrigger = document.querySelector(
        ".editorial-subscribers-modal__new-subscriber",
      );
      if (
        !popoverDom ||
        popoverDom.contains(event.target) ||
        popoverTrigger?.contains(event.target)
      ) {
        return;
      }
      setIsPopoverOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutsidePopover);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePopover);
    };
  }, [isPopoverOpen]);

  const isSubscriberEmailAlreadyUsed = React.useCallback(
    (newSubscriber: components["schemas"]["Subscriber"]) => {
      return subscribers
        ? !!Object.values(subscribers)
            .map((subscriber) => subscriber.email)
            .find((subscriberEmail) => newSubscriber.email === subscriberEmail)
        : false;
    },
    [subscribers],
  );

  const isSubscriberDisplayNameAlreadyUsed = React.useCallback(
    (newSubscriber: components["schemas"]["Subscriber"]) => {
      return subscribers
        ? !!Object.values(subscribers)
            .map((subscriber) => subscriber.displayName)
            .find(
              (subscriberDisplayName) =>
                newSubscriber.displayName === subscriberDisplayName,
            )
        : false;
    },
    [subscribers],
  );

  const currentCustomer = React.useMemo(() => {
    return customers && auth?.jwt.currentCustomerLinkId
      ? customers[auth.jwt.currentCustomerLinkId]
      : undefined;
  }, [auth?.jwt.currentCustomerLinkId, customers]);

  const onSubmit = React.useCallback(async () => {
    const isValid = validate(newSubscriber);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!newSubscriber) {
      return;
    }
    if (isSubscriberDisplayNameAlreadyUsed(newSubscriber)) {
      setErrors({ displayName: "subscriberDisplayNameIsAlreadyUsed" });
      setTimeout(focusError, 200);
      return;
    }
    if (isSubscriberEmailAlreadyUsed(newSubscriber)) {
      setErrors({ email: "subscriberEmailAddressIsAlreadyUsed" });
      setTimeout(focusError, 200);
      return;
    }
    setErrors({});
    axios
      .post<components["schemas"]["Subscriber"]>(
        "/subscriber/crud",
        newSubscriber,
      )
      .then((res) => {
        const newSubscriber = res.data as components["schemas"]["Subscriber"];
        setSubscribers({
          ...subscribers,
          [newSubscriber.subscriberId as string]: newSubscriber,
        });
        onChange([
          ...editorialSubscribers,
          {
            editorialId: editorial.editorialId!,
            subscriberId: newSubscriber.subscriberId!,
            useApp: !!currentCustomer?.appEnabled,
            useMail: true,
          },
        ]);
        setNewSubscriber(getEmptyObject(subscriberSchema));
        setIsPopoverOpen(false);
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [
    currentCustomer?.appEnabled,
    editorial.editorialId,
    editorialSubscribers,
    isSubscriberDisplayNameAlreadyUsed,
    isSubscriberEmailAlreadyUsed,
    newSubscriber,
    onChange,
    setSubscribers,
    subscribers,
    t,
  ]);

  const allSubscribersAreMailSubscribers = React.useMemo((): boolean => {
    return !editorialSubscribers.find(
      (editorialSubscriber) => !editorialSubscriber.useMail,
    );
  }, [editorialSubscribers]);

  const onAddAllMailSubscribersChange = React.useCallback(
    (_: unknown, checked: boolean) => {
      onChange((editorialSubscribers) =>
        editorialSubscribers
          ? editorialSubscribers.map((editorialSubscriber) => ({
              ...editorialSubscriber,
              useMail: checked,
            }))
          : editorialSubscribers,
      );
    },
    [onChange],
  );

  const allSubscribersAreAppSubscribers = React.useMemo((): boolean => {
    return !editorialSubscribers.find(
      (editorialSubscriber) => !editorialSubscriber.useApp,
    );
  }, [editorialSubscribers]);

  const onAllSubscribersAreAppSubscribersChange = React.useCallback(
    (_: unknown, checked: boolean) => {
      onChange((editorialSubscribers) =>
        editorialSubscribers
          ? editorialSubscribers.map((editorialSubscriber) => ({
              ...editorialSubscriber,
              useApp: checked,
            }))
          : editorialSubscribers,
      );
    },
    [onChange],
  );

  const onEditorialSubscriberChange = React.useCallback(
    (editorialSubscriber: components["schemas"]["EditorialSubscriber"]) => {
      onChange((editorialSubscribers) =>
        editorialSubscribers
          ? editorialSubscribers.map((x) =>
              x.subscriberId === editorialSubscriber.subscriberId
                ? editorialSubscriber
                : x,
            )
          : editorialSubscribers,
      );
    },
    [onChange],
  );

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      <Modal.Header>
        <Modal.Title>
          {editorial.name} {t("subscribers")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: undefined, height: "100vh" }}>
        <div className="w-100 h-100 d-flex flex-column">
          <SchemaTable<components["schemas"]["EditorialSubscriber"]>
            actionBar={
              <div className="d-flex">
                <Checkbox
                  className="mb-2"
                  checked={allSubscribersAreMailSubscribers}
                  onChange={onAddAllMailSubscribersChange}
                >
                  {t("allSubscribersAreMailSubscribers")}
                </Checkbox>
                {currentCustomer?.appEnabled ? (
                  <Checkbox
                    className="mb-2"
                    checked={allSubscribersAreAppSubscribers}
                    onChange={onAllSubscribersAreAppSubscribersChange}
                  >
                    {t("allSubscribersAreAppSubscribers")}
                  </Checkbox>
                ) : null}
              </div>
            }
            schema={
              openapi.components.schemas
                .EditorialSubscriber as oas30.SchemaObject
            }
            height={windowInnerHeight - 400}
            data={editorialSubscribers}
            columnsConfigs={[
              {
                name: "editorialId",
                hidden: true,
              },
              {
                name: "subscriberId",
                hidden: true,
              },
              getMemberColumnConfig(
                editorialSubscribers,
                "useMail",
                onEditorialSubscriberChange,
              ),
              {
                ...getMemberColumnConfig(
                  editorialSubscribers,

                  "useApp",
                  onEditorialSubscriberChange,
                ),
                hidden: !currentCustomer?.appEnabled,
              },
              {
                name: "editorialSubscriberId",
                title: "Ontvanger",
                renderData: (rowData) => {
                  if (!subscribers) {
                    return "";
                  }
                  const subscriber = subscribers[rowData.subscriberId];
                  if (!subscriber) {
                    return "⌛";
                  }
                  return `${subscribers ? subscriber.email! : ""}${
                    subscribers &&
                    subscriber.displayName &&
                    subscriber.displayName !== subscriber.email
                      ? ` / ${subscriber.displayName}`
                      : ""
                  }`;
                },
              },
            ]}
            globalSearch
            sortable
          >
            <MwWhisper
              placement="bottom"
              trigger="none"
              open={isPopoverOpen}
              speaker={
                <Popover className="popover-without-arrow" ref={popoverRef}>
                  <Form onSubmit={onSubmit}>
                    <SchemaFormBody<components["schemas"]["Subscriber"]>
                      schema={{
                        ...subscriberSchema,
                        properties,
                      }}
                      config={{
                        bounced: {
                          hidden: true,
                        },
                        subscriberId: {
                          hidden: true,
                        },
                      }}
                      onChange={setNewSubscriber}
                      value={newSubscriber}
                      errors={errors}
                      inputClassName="input-gray"
                    />
                    <Button appearance="primary" type="submit">
                      {t("save")}
                    </Button>
                  </Form>
                </Popover>
              }
            >
              <ActionButton
                className="editorial-subscribers-modal__new-subscriber"
                title={t("newSubscriber")}
                onClick={() =>
                  setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen)
                }
              />
            </MwWhisper>
          </SchemaTable>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditorialSubscribersModal;
