import { Drawer, Icon } from "rsuite";
import {
  EDashboardWidgetType,
  dashboardWidgetDefinitions,
} from "../../../components/dashboardWidgets";
import NumberBadge from "../../../components/NumberBadge";
import ActionButton from "../../../components/ActionButton";
import React, { Dispatch, SetStateAction } from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import { TDashboard } from "../../../types";

interface IDashboardWidgetDrawerProps {
  dashboard: TDashboard;
  isDrawerOpen: boolean;
  addWidget: (widgetType: EDashboardWidgetType) => void;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const WidgetDrawer = ({
  dashboard,
  isDrawerOpen,
  addWidget,
  setIsDrawerOpen,
}: IDashboardWidgetDrawerProps) => {
  const { t } = React.useContext(I18nContext);

  const closeDrawer = React.useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  return (
    <Drawer
      className="widget-menu-drawer"
      show={isDrawerOpen}
      onHide={closeDrawer}
      size="sm"
    >
      <Drawer.Header>
        <Drawer.Title className="widget-menu-drawer__title">
          {t("widgets")}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="widget-menu">
        {Object.values(EDashboardWidgetType).map((widgetType) => (
          <div
            key={widgetType}
            className="widget-menu__item"
            onClick={() => addWidget(widgetType as EDashboardWidgetType)}
          >
            <NumberBadge
              title={t("widgetDrawer_amountOfActiveWidgetsDescription")}
              className="widget-menu__item__badge"
              number={
                dashboard?.config.widgets?.filter(
                  (widget) => widget.type === widgetType
                ).length
              }
            >
              <ActionButton />
            </NumberBadge>
            <div className="widget-menu__item__content">
              <div className="row h-100 align-items-center">
                <div className="col-3 text-center">
                  <Icon
                    icon="star"
                    componentClass={
                      dashboardWidgetDefinitions[widgetType].menuIcon
                    }
                    width={50}
                    height={50}
                    style={{ color: "#96afaf" }}
                  />
                </div>
                <div className="col">
                  <h4 className="widget-menu__item__content__title">
                    {t(widgetType)}
                  </h4>
                  <p className="widget-menu__item__content__description">
                    {t(`${widgetType}_DESCRIPTION`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Drawer.Body>
    </Drawer>
  );
};

export default WidgetDrawer;
