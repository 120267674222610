import React, { JSXElementConstructor } from "react";
import { Nav } from "rsuite";
import "./index.scss";
import { TypeAttributes } from "rsuite/lib/@types/common";
import _ from "lodash";

interface IIconNavItemProps {
  hasChevron?: boolean;
  chevronPlacement?: TypeAttributes.Placement;
  className?: string;
  disabled?: boolean;
  iconComponent: JSXElementConstructor<{
    className?: string;
    style?: React.CSSProperties;
  }>;
  iconStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  title?: string;
  onClick?: () => void;
}

const IconNavItem = ({
  disabled,
  title,
  onClick,
  hasChevron,
  iconComponent,
  chevronPlacement,
  className,
  iconStyle,
  titleStyle = { marginRight: 6 },
}: IIconNavItemProps) => {
  const Icon = iconComponent;
  return (
    <Nav.Item
      onSelect={onClick}
      disabled={disabled}
      className={`icon-nav-item ${
        hasChevron
          ? `icon-nav-item--chevron rs-picker-placement-${
              chevronPlacement ? _.kebabCase(chevronPlacement) : "bottom"
            }`
          : ""
      } ${className || ""}`}
    >
      {title ? <span style={titleStyle}>{title}</span> : null}
      <Icon className="icon" style={iconStyle} />
      {hasChevron ? <span className="rs-picker-toggle-caret" /> : null}
    </Nav.Item>
  );
};

export default IconNavItem;
