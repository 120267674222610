import React from "react";
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
} from "rsuite";
import axios from "axios";
import { dataIsEmail } from "../../../../../inc/query";
import { I18nContext } from "../../../../../provider/I18nProvider";

interface IEditorialIssueResendModalProps {
  show: boolean;
  editorialIssueId: string;
  onClose: () => void;
}

const EditorialIssueResendModal = ({
  show,
  onClose,
  editorialIssueId,
}: IEditorialIssueResendModalProps) => {
  const { t } = React.useContext(I18nContext);
  const [emailAddress, setEmailAddress] = React.useState<string>("");
  const [emailAddressError, setEmailAddressError] = React.useState<string>();

  const resend = React.useCallback(() => {
    if (!dataIsEmail(emailAddress)) {
      setEmailAddressError("invalid email format");
      return;
    }
    setEmailAddressError(undefined);
    axios
      .post(`/editorialIssue/resend/${editorialIssueId}`, null, {
        params: { emailAddress },
      })
      .then(() => {
        Alert.success(t("editorialIssueSuccessfullyResend"));
        onClose();
      });
  }, [editorialIssueId, emailAddress, onClose, t]);

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      <Modal.Header>
        <Modal.Title>{t("editorialIssueResend")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: undefined }}>
        <Form className="w-100" onSubmit={resend}>
          <FormGroup className="mb-5">
            <ControlLabel className="label">{t("emailAddress")}</ControlLabel>
            <FormControl
              className="input input-gray"
              onChange={setEmailAddress}
              value={emailAddress}
              autoComplete="email"
            />
            {emailAddressError ? (
              <HelpBlock style={{ color: "red" }}>
                {t(emailAddressError)}
              </HelpBlock>
            ) : null}
          </FormGroup>

          <Button appearance="primary" type="submit" disabled={!emailAddress}>
            {t("resend")}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default EditorialIssueResendModal;
