import React from "react";
import { useParams } from "react-router-dom";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { Loader } from "rsuite";
import { APP_PATH } from "../../inc/constants";
import { useHistory } from "react-router-dom";

const SwitchCustomerView = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();
  const { setCurrentCustomer } = React.useContext(ApiDataContext);

  React.useEffect(
    () => {
      setCurrentCustomer(customerId);
      setTimeout(() => {
        history.push(APP_PATH);
      }, 1);
    },
    // eslint-disable-next-line
    []
  );

  return <Loader />;
};

export default SwitchCustomerView;
