import React from "react";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  FlexboxGrid,
  Form,
  Icon,
  Modal,
} from "rsuite";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import { oas30 } from "openapi3-ts";
import { components } from "../../../types/openapi";
import { Loader } from "rsuite";
import resolvedOpenApi, {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import "./index.scss";
import axios from "../../../inc/axios";
import ajv from "../../../inc/ajv";
import EditorialConfigView from "./EditorialConfigView";
import _ from "lodash";
import { I18nContext } from "../../../provider/I18nProvider";
import useCustomers from "../../../hooks/useCustomers";
import { AuthContext } from "../../../provider/AuthProvider";
import useUsers from "../../../hooks/useUsers";
import { elementHasClassName } from "../../../inc/dom";
import useNewContentNotificationCustomerUserLinks from "../../../hooks/useNewContentNotificationCustomerUserLinks";

interface ICustomerModalProps {
  show: boolean;
  customerLinkId?: string;
  onClose: () => void;
}

type Customer = components["schemas"]["Customer"];

const customerSchema = resolvedOpenApi.components.schemas
  .Customer as oas30.SchemaObject;
const AppConfigSchema = resolvedOpenApi.components.schemas
  .AppConfig as oas30.SchemaObject;
const EditorialConfigSchema = resolvedOpenApi.components.schemas
  .EditorialConfig as oas30.SchemaObject;

const customerValidate = ajv.compile(customerSchema);
const appConfigValidate = ajv.compile(AppConfigSchema);
const editorialConfigValidate = ajv.compile(EditorialConfigSchema);

if (!customerSchema.properties) {
  throw new Error("Incomplete customer schema");
}

const CustomerModal = ({
  show,
  customerLinkId,
  onClose,
}: ICustomerModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { setCustomers, updateUser } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const customers = useCustomers();
  const users = useUsers();
  const { newContentNotificationCustomerUserLinks, hydrate } =
    useNewContentNotificationCustomerUserLinks();

  const [customer, setCustomer] = React.useState<Customer>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const [activeView, setActiveView] = React.useState<"settings" | "template">(
    "settings",
  );
  const [groupUserSelectedMap, setGroupUserSelectedMap] = React.useState<{
    [userId: string]: boolean;
  }>({});

  const groupUsers = React.useMemo(() => {
    if (!customer?.groupId || !users) {
      return [];
    }
    // Throws error when in render-cycle
    requestAnimationFrame(() => {
      hydrate(customer.groupId);
    });
    return users
      .filter((user) => user.groupId === customer.groupId)
      .sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1,
      );
  }, [customer?.groupId, hydrate, users]);

  const originalGroupUserSelectedMap = React.useMemo(
    () =>
      groupUsers.reduce<{
        [userId: string]: boolean;
      }>((prev, user) => {
        prev[user.userId as string] = customerLinkId
          ? user.customerLinkIds.indexOf(customerLinkId) >= 0
          : false;
        return prev;
      }, {}),
    [customerLinkId, groupUsers],
  );

  React.useEffect(() => {
    setGroupUserSelectedMap(originalGroupUserSelectedMap);
  }, [originalGroupUserSelectedMap]);

  React.useEffect(() => {
    setActiveView("settings");
  }, [customerLinkId]);

  React.useEffect(() => {
    if (!customers || !auth || !customerLinkId) {
      return;
    }
    let customer: Customer | undefined = customers[customerLinkId];
    if (!customer) {
      throw new Error("Could not construct customer for form");
    }
    const newCustomer = JSON.parse(JSON.stringify(customer));
    setCustomer({
      ...newCustomer,
      editorialConfig:
        newCustomer.editorialConfig || getEmptyObject(EditorialConfigSchema),
    });
  }, [auth, customerLinkId, customers]);

  const formIsDirty = React.useMemo(() => {
    const originalCustomer =
      customers && customerLinkId ? customers[customerLinkId] : null;
    return (
      JSON.stringify(originalCustomer) !== JSON.stringify(customer) ||
      !_.isEqual(originalGroupUserSelectedMap, groupUserSelectedMap)
    );
  }, [
    customer,
    customerLinkId,
    customers,
    groupUserSelectedMap,
    originalGroupUserSelectedMap,
  ]);

  const validateCustomer = React.useCallback(() => {
    // Remove broken and/or invalid appConfig
    if (customer?.appConfig) {
      if (!customer.appConfig.logoUrlLight) {
        customer.appConfig.logoUrlLight =
          // @ts-ignore
          resolvedOpenApi.components.schemas.AppConfig.properties.logoUrlLight.default;
      }
      if (!customer.appConfig!.logoUrlDark) {
        customer.appConfig.logoUrlDark =
          // @ts-ignore
          resolvedOpenApi.components.schemas.AppConfig.properties.logoUrlDark.default;
      }
      if (!appConfigValidate(customer.appConfig)) {
        customer.appConfig = undefined;
      }
    }
    const isCustomerValid = customerValidate(customer);
    if (
      !isCustomerValid &&
      customerValidate.errors &&
      customerValidate.errors.length
    ) {
      console.log(customerValidate.errors);
      setErrors(validationErrorsToSchemaFormErrors(customerValidate.errors));
    }
    return isCustomerValid;
  }, [customer]);

  const validateEditorialConfig = React.useCallback(() => {
    const isValid = customer
      ? editorialConfigValidate(customer.editorialConfig)
      : false;
    if (
      !isValid &&
      editorialConfigValidate.errors &&
      editorialConfigValidate.errors.length
    ) {
      console.log(editorialConfigValidate.errors);
      setErrors(
        validationErrorsToSchemaFormErrors(editorialConfigValidate.errors),
      );
    }
    return isValid;
  }, [customer]);

  const onSubmit = React.useCallback(() => {
    if (!users || !customerLinkId) {
      return;
    }

    if (!validateEditorialConfig()) {
      setActiveView("template");
      setTimeout(focusError, 200);
      return;
    }

    if (!validateCustomer()) {
      setActiveView("settings");
      setTimeout(focusError, 200);
      return;
    }

    setErrors({});
    const isNew = !(customer && customer.customerLinkId);

    Object.entries(originalGroupUserSelectedMap).forEach(
      ([userId, wasSelected]) => {
        const user = groupUsers.find((user) => user.userId === userId);
        if (!user) {
          throw new Error("Can not update user: user not found");
        }
        const isSelected = groupUserSelectedMap[userId];
        let customerLinkIds: string[] | null = null;
        if (isSelected && !wasSelected) {
          customerLinkIds = [...user.customerLinkIds, customerLinkId];
        }
        if (!isSelected && wasSelected) {
          customerLinkIds = user.customerLinkIds.filter(
            (userCustomerLinkId) => userCustomerLinkId !== customerLinkId,
          );
        }
        if (customerLinkIds) {
          updateUser({
            ...user,
            customerLinkIds,
          });
        }
      },
    );

    axios
      .request<Customer>({
        url: `/customer/crud${
          customer && customer.customerLinkId
            ? `/${customer.customerLinkId}`
            : ""
        }`,
        method: isNew ? "post" : "put",
        data: {
          ...customer,
        },
      })
      .then((res) => {
        const updatedCustomer = res.data;
        if (customers) {
          setCustomers({
            ...customers,
            [updatedCustomer.customerLinkId as string]: updatedCustomer,
          });
        }
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [
    customer,
    customerLinkId,
    customers,
    groupUserSelectedMap,
    groupUsers,
    onClose,
    originalGroupUserSelectedMap,
    setCustomers,
    t,
    updateUser,
    users,
    validateCustomer,
    validateEditorialConfig,
  ]);

  const checkedUserCount = React.useMemo(
    () =>
      Object.values(groupUserSelectedMap).reduce(
        (prev, isSelected) => prev + (isSelected ? 1 : 0),
        0,
      ),
    [groupUserSelectedMap],
  );

  const onSelectAllChange = React.useCallback(() => {
    if (!customerLinkId) {
      return;
    }
    const doSelect = checkedUserCount !== groupUsers.length;
    setGroupUserSelectedMap((groupUserSelectedMap) =>
      Object.keys(groupUserSelectedMap).reduce(
        (prev, userId) => ({
          ...prev,
          [userId]: doSelect,
        }),
        {},
      ),
    );
  }, [checkedUserCount, customerLinkId, groupUsers.length]);

  const onSelectGroupUserChange = React.useCallback(
    (userId: string, checked: boolean) => {
      setGroupUserSelectedMap((groupUserSelectedMap) => ({
        ...groupUserSelectedMap,
        [userId]: checked,
      }));
    },
    [],
  );

  const config = React.useMemo(
    () => ({
      editorialConfig: {
        hidden: true,
      },
      showPdfPublication: {
        hidden: true,
      },
      showPdfPublicationDate: {
        hidden: true,
      },
      showPdfArea: {
        hidden: true,
      },
      showPdfPageNumber: {
        hidden: true,
      },
      showPdfAve: {
        hidden: true,
      },
      showPdfRegion: {
        hidden: true,
      },
      showPdfFrequency: {
        hidden: true,
      },
      showPdfCirculation: {
        hidden: true,
      },
      showPdfCumulativeCirculation: {
        hidden: true,
      },
      showPdfReach: {
        hidden: true,
      },
      showPdfCumulativeReach: {
        hidden: true,
      },
      showPdfCumulativeAve: {
        hidden: true,
      },
      appConfig: {
        hidden: !customer?.appEnabled,
      },
    }),
    [customer?.appEnabled],
  );

  const isSuperUser = auth
    ? auth.jwt.userRoles.indexOf("superuser") >= 0
    : false;

  return (
    <Modal
      show={show}
      onHide={(e: any) => {
        if (elementHasClassName(e.currentTarget, "rs-modal-header-close")) {
          return;
        }
        onClose();
      }}
      className="modal-size-auto"
    >
      <Modal.Header
        onHide={() =>
          activeView === "settings" ? onClose() : setActiveView("settings")
        }
      >
        <Modal.Title>
          {customer ? customer.customerName : t("loading")}
        </Modal.Title>
        <div style={{ display: "flex" }}>
          <Button
            onClick={onSubmit}
            appearance="primary"
            disabled={!formIsDirty}
          >
            {t("save")}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body
        className={activeView === "template" ? "modal--template" : "py-0"}
        style={{ maxHeight: undefined }}
      >
        <div className="modal__content">
          {customer ? (
            <>
              {activeView === "settings" ? (
                <Form className="modal__content__form py-0" onSubmit={onSubmit}>
                  <FlexboxGrid className="modal__content__form__rights">
                    <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
                      <div className="modal__content__form__profile">
                        <h4 className="modal__content__form__profile__title">
                          Klantprofiel
                        </h4>
                        <SchemaFormBody<Customer>
                          schema={customerSchema}
                          onChange={setCustomer}
                          value={customer}
                          errors={errors}
                          inputClassName="input-gray"
                          config={config}
                        />
                      </div>
                      {customerLinkId ? (
                        <div className="mb-5">
                          <h4>Gebruikers</h4>
                          {groupUsers.length ? (
                            <>
                              <Checkbox
                                indeterminate={
                                  checkedUserCount > 0 &&
                                  checkedUserCount < groupUsers.length
                                }
                                checked={checkedUserCount === groupUsers.length}
                                onChange={onSelectAllChange}
                              >
                                Selecteer alles
                              </Checkbox>
                              <hr />
                            </>
                          ) : (
                            <div>Geen gebruikers in deze groep gevonden</div>
                          )}
                          {groupUsers.map((user) => {
                            let hasNotifications =
                              !!newContentNotificationCustomerUserLinks?.find(
                                (newContentNotificationCustomerUserLink) =>
                                  newContentNotificationCustomerUserLink.customerLinkId ===
                                    customerLinkId &&
                                  newContentNotificationCustomerUserLink.userId ===
                                    user.userId,
                              );
                            return (
                              <Checkbox
                                key={`${user.userId}_${
                                  groupUserSelectedMap[user.userId as string]
                                }`}
                                value={user.userId}
                                checked={
                                  groupUserSelectedMap[user.userId as string]
                                }
                                onChange={onSelectGroupUserChange}
                              >
                                {isSuperUser ? (
                                  <Icon
                                    icon={
                                      hasNotifications
                                        ? "bell-o"
                                        : "bell-slash-o"
                                    }
                                    className="me-2"
                                  />
                                ) : null}
                                {user.displayName} ({user.userName})
                              </Checkbox>
                            );
                          })}
                        </div>
                      ) : null}
                      <div className="modal__content__form__profile">
                        <Button
                          appearance="primary"
                          onClick={() => setActiveView("template")}
                        >
                          {t("editEditorialConfig")}
                        </Button>
                      </div>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
                      <h4>Weergave PDF</h4>
                      <SchemaFormBody
                        schema={{
                          properties: {
                            showPdfPublication: { type: "boolean" },
                            showPdfPublicationDate: { type: "boolean" },
                            showPdfArea: { type: "boolean" },
                            showPdfPageNumber: { type: "boolean" },
                            showPdfAve: { type: "boolean" },
                            showPdfRegion: { type: "boolean" },
                            showPdfFrequency: { type: "boolean" },
                            showPdfCirculation: { type: "boolean" },
                            showPdfCumulativeCirculation: { type: "boolean" },
                            showPdfReach: { type: "boolean" },
                            showPdfCumulativeReach: { type: "boolean" },
                            showPdfCumulativeAve: { type: "boolean" },
                          },
                          required: [
                            "showPdfPublication",
                            "showPdfPublicationDate",
                            "showPdfArea",
                            "showPdfPageNumber",
                            "showPdfAve",
                            "showPdfRegion",
                            "showPdfFrequency",
                            "showPdfCirculation",
                            "showPdfCumulativeCirculation",
                            "showPdfReach",
                            "showPdfCumulativeReach",
                            "showPdfCumulativeAve",
                          ],
                          type: "object",
                        }}
                        onChange={setCustomer}
                        value={customer}
                        errors={errors}
                        inputClassName="input-gray"
                      />
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </Form>
              ) : (
                <EditorialConfigView
                  editorialConfig={customer.editorialConfig}
                  onChange={(editorialConfig) => {
                    setCustomer((customer) =>
                      customer
                        ? {
                            ...customer,
                            editorialConfig,
                          }
                        : customer,
                    );
                  }}
                  errors={errors}
                />
              )}
            </>
          ) : (
            <Loader center size="lg" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomerModal;
