import Dashboards from "../../icons/Dashboards";
import Editorial from "../../icons/Editorial";
import Search from "../../icons/Search";
import Admin from "../../icons/Admin";
import Rss from "../../icons/Rss";
import Label from "../../icons/Label";
import Group from "../../icons/Group";
import CartCheck from "../../icons/CartCheck";
import Eye from "../../icons/Eye";
import Source from "../../icons/WidgetTypeSource";
import { IconNames, IconProps } from "rsuite/lib/Icon/Icon";
import { components } from "../../types/openapi";
import { APP_PATH } from "../../inc/constants";
import Compass from "../../icons/Compass";
import Invoice from "../../icons/Invoice";
import React from "react";
import Account from "../../icons/Account";
import Alert from "../../icons/Alert";
import Logout from "../../icons/Logout";
import { intersection } from "lodash";
import { saveAs } from "file-saver";
import Prullie from "../../icons/Prullie";
import DashboardSubmenu from "./NavItems/DashboardSubmenu";
import SendEditorial from "../../icons/SendEditorial";
import { I18nContext } from "../../provider/I18nProvider";
import useEditorials from "../../hooks/useEditorials";
import MediaType from "../../icons/MediaType";
import useRssExports from "../../hooks/useRssExports";
import { AuthContext } from "../../provider/AuthProvider";
import Benchmark from "../../icons/Benchmark";

export interface INavItemProps {
  title: string;
  to: string;
  iconComponent?: React.FunctionComponent;
}

export type TNavItem = INavConfig | "-";
export interface INavConfig {
  className?: string;
  icon?: IconNames;
  iconProps?: Partial<IconProps>;
  svg?: React.FunctionComponent;
  to?: string;
  title: string;
  requiredRoles?: Array<
    components["schemas"]["CustomerRole"] | components["schemas"]["UserRole"]
  >;
  hideForRoles?: Array<
    components["schemas"]["CustomerRole"] | components["schemas"]["UserRole"]
  >;
  children?: TNavItem[];
  onClick?: () => void;
  disabled?: boolean;
  component?: React.FunctionComponent<INavItemProps>;
}

export default function useUserNavConfig(): TNavItem[] {
  const { getCsv, t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const editorials = useEditorials();
  const rssExports = useRssExports();

  const roles = React.useMemo(() => {
    if (!auth) {
      return [];
    }
    return [...auth.jwt.userRoles, auth.jwt.customerRole];
  }, [auth]);
  const navConfigs: TNavItem[] = React.useMemo(() => {
    const result: TNavItem[] = [
      {
        svg: MediaType,
        title: t("myMedia"),
        to: `${APP_PATH}/myMedia`,
        requiredRoles: ["regular", "restricted"],
        hideForRoles: ["dashboardOnly", "benchmarkOnly"],
        className: "nav-item--my-media",
      },
      {
        svg: Dashboards,
        to: `${APP_PATH}/dashboards`,
        title: t("navigation_dashboards"),
        requiredRoles: ["regular", "restricted", "dashboardOnly"],
        hideForRoles: ["benchmarkOnly"],
        component: DashboardSubmenu,
      },
      {
        svg: Editorial,
        to: `${APP_PATH}/editorials`,
        title: t("navigation_editorials"),
        requiredRoles: ["regular", "restricted"],
        hideForRoles: ["dashboardOnly", "benchmarkOnly"],
        children: [
          {
            className: "layout__app-layout__nav-items__editorials__overview",
            to: `${APP_PATH}/editorials/overview`,
            title: t("navigation_editorials_overview"),
            requiredRoles: ["regular", "restricted"],
            svg: Editorial,
          },
          {
            className: "layout__app-layout__nav-items__editorials__issues",
            to: `${APP_PATH}/editorials/issues`,
            title: t("navigation_editorials_issues"),
            requiredRoles: ["regular", "restricted"],
            disabled: !editorials || !Object.keys(editorials).length,
            svg: SendEditorial,
          },
          {
            className: "layout__app-layout__nav-items__editorials__subscribers",
            to: `${APP_PATH}/editorials/subscribers`,
            title: t("navigation_editorials_subscribers"),
            requiredRoles: ["regular", "restricted"],
            svg: Group,
          },
        ],
      },
      {
        svg: Search,
        to: `${APP_PATH}/search`,
        title: t("navigation_search"),
        requiredRoles: ["regular", "restricted"],
        hideForRoles: ["dashboardOnly", "benchmarkOnly"],
      },
      {
        svg: Benchmark,
        to: `${APP_PATH}/analysis`,
        title: t("navigation_analysis"),
        requiredRoles: ["regular", "restricted"],
        hideForRoles: ["dashboardOnly", "inholland"],
      },
      {
        svg: Admin,
        to: `${APP_PATH}/admin`,
        title: t("navigation_admin"),
        requiredRoles: ["regular", "restricted", "manager", "invoice"],
        hideForRoles: ["dashboardOnly", "benchmarkOnly"],
        children: [
          {
            svg: Compass,
            to: `${APP_PATH}/admin/searchTopics`,
            title: t("navigation_admin_searchTopics"),
            requiredRoles: ["regular", "restricted"],
          },
          {
            svg: Label,
            to: `${APP_PATH}/admin/labels`,
            title: t("navigation_admin_labels"),
            requiredRoles: ["regular", "restricted"],
          },
          {
            svg: Group,
            to: `${APP_PATH}/admin/users`,
            title: t("navigation_admin_users"),
            requiredRoles: ["manager"],
          },
          // {
          //   svg: Folder,
          //   to: `${APP_PATH}/admin/#`,
          //   title: "Bestanden",
          //   requiredRoles: ["regular", "restricted"],
          //   disabled: true,
          // },
          {
            svg: Invoice,
            to: `${APP_PATH}/admin/invoices`,
            title: t("navigation_admin_invoices"),
            requiredRoles: ["invoice"],
          },
          {
            svg: CartCheck,
            to: `${APP_PATH}/admin/media-item-purchases`,
            title: t("navigation_admin_mediaItemPurchases"),
            requiredRoles: ["regular", "restricted"],
          },
          {
            svg: Prullie,
            to: `${APP_PATH}/admin/deleted-media-items`,
            title: t("navigation_admin_deletedMediaItems"),
            requiredRoles: ["regular", "restricted"],
          },
          {
            svg: Eye,
            to: `${APP_PATH}/admin/customer`,
            title: t("navigation_admin_customer"),
            requiredRoles: ["manager"],
          },
          {
            svg: Source,
            to: `${APP_PATH}/admin/activeSources`,
            title: t("navigation_admin_source"),
          },
        ],
      },
      {
        svg: Group,
        to: `${APP_PATH}/users`,
        title: t("navigation_users"),
        requiredRoles: ["superuser"],
      },
      {
        icon: "id-mapping",
        to: `${APP_PATH}/customers`,
        title: t("navigation_customers"),
        requiredRoles: ["superuser"],
      },
      {
        icon: "people-group",
        to: `${APP_PATH}/groups`,
        title: t("navigation_groups"),
        requiredRoles: ["superuser"],
      },
      {
        icon: "globe",
        title: t("navigation_downloadTranslations"),
        requiredRoles: ["superuser"],
        onClick: () => {
          saveAs(
            new Blob([getCsv()], { type: "text/csv;charset=utf-8" }),
            "vertaling.csv",
          );
        },
      },
      "-",
      {
        svg: Alert,
        to: `${APP_PATH}/alerts`,
        title: t("navigation_alerts"),
        requiredRoles: ["regular", "restricted"],
        hideForRoles: ["superuser", "dashboardOnly", "benchmarkOnly"],
      },
      {
        svg: Account,
        to: `${APP_PATH}/account`,
        requiredRoles: ["regular", "restricted"],
        hideForRoles: ["dashboardOnly", "benchmarkOnly"],
        title: t("navigation_account"),
      },
      {
        svg: Logout,
        iconProps: {
          style: { marginLeft: 4 },
        },
        to: "/logout",
        title: t("navigation_logout"),
      },
    ];

    const adminMenuItemIndex = 5;
    // https://www.notion.so/c700a5b224564b5395ec6c30144d735a
    // Indien je geen RSS'en hebt ingesteld, ook de RSS knop niet tonen
    // Superuser ziet de knop altijd
    if (
      !!auth?.jwt.superUserId ||
      (rssExports && Object.keys(rssExports).length > 0)
    ) {
      // @ts-ignore
      result[adminMenuItemIndex].children.splice(1, 0, {
        svg: Rss,
        to: `${APP_PATH}/admin/rss`,
        title: t("navigation_admin_rss"),
        requiredRoles: ["regular", "restricted"],
      });
    }

    if (!!auth?.jwt.superUserId) {
      /// @ts-ignore
      result[adminMenuItemIndex].children.splice(1, 0, {
        svg: Rss,
        to: `${APP_PATH}/admin/contentExport`,
        title: t("navigation_admin_content"),
        requiredRoles: ["regular", "restricted"],
      });
    }

    return result;
  }, [auth?.jwt.superUserId, editorials, getCsv, rssExports, t]);

  const userNavConfigs = React.useCallback(
    (nav: TNavItem[]): TNavItem[] => {
      return nav.reduce((carry: any[], value) => {
        if (value === "-") {
          carry.push(value);
          return carry;
        }

        // Nav configs with requiredRoles that dont match the auth roles, are skipped
        if (
          value.requiredRoles &&
          !intersection(value.requiredRoles, roles).length
        ) {
          return carry;
        }

        // Nav configs with hideForRoles that match the auth roles, are skipped
        if (
          value.hideForRoles &&
          intersection(value.hideForRoles, roles).length
        ) {
          return carry;
        }

        if (value.children?.length) {
          value.children = userNavConfigs(value.children);
        }

        carry.push(value);
        return carry;
      }, []);
    },
    [roles],
  );

  return React.useMemo(
    () => userNavConfigs(navConfigs),
    [navConfigs, userNavConfigs],
  );
}
