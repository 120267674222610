import React from "react";
import axios from "../inc/axios";
import { TUser } from "../types";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { AuthContext } from "../provider/AuthProvider";

const useUsers = () => {
  const { users, setUsers } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (users !== undefined || !auth?.bearer) {
      return;
    }
    setUsers(null);

    axios.get<TUser[]>("/user/crud").then((res) => {
      // make every user "valid" in our schema
      const users = res.data.map((user) => ({
        ...user,
        settings: user.settings || {},
      }));
      setUsers(users);
    });
  }, [auth?.bearer, auth?.jwt.userRoles, setUsers, users]);

  return users;
};

export default useUsers;
