import React, { SVGProps } from "react";

const MediaTypeWeb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.416}
    height={9.417}
    viewBox="0 0 9.416 9.417"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__RSS">
      <path
        id="prefix__Path_1976"
        d="M1824.691 1208.141a.507.507 0 01-.507-.507 4.538 4.538 0 00-4.533-4.533.507.507 0 010-1.013 5.553 5.553 0 015.547 5.547.507.507 0 01-.507.506z"
        transform="translate(-1819.144 -1198.725)"
      />
      <path
        id="prefix__Path_1977"
        d="M1828.054 1202.663a.507.507 0 01-.507-.507 7.906 7.906 0 00-7.9-7.9.507.507 0 010-1.013 8.92 8.92 0 018.91 8.91.507.507 0 01-.503.51z"
        transform="translate(-1819.144 -1193.246)"
      />
      <g id="prefix__Group_1639" transform="translate(0 6.951)">
        <circle
          id="prefix__Ellipse_342"
          cx={0.726}
          cy={0.726}
          r={0.726}
          transform="translate(.507 .507)"
        />
        <path
          id="prefix__Path_1978"
          d="M1820.377 1213.984a1.233 1.233 0 111.233-1.233 1.235 1.235 0 01-1.233 1.233zm0-1.453a.22.22 0 10.22.22.219.219 0 00-.22-.22z"
          transform="translate(-1819.144 -1211.518)"
        />
      </g>
    </g>
  </svg>
);

export default MediaTypeWeb;
