import React from "react";
import { ESplitType, EValueType, IDashboardWidgetProps } from "../index";
import { Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { getDefaultOptions } from "../../../inc/widgets/chart";
import { I18nContext } from "../../../provider/I18nProvider";
import { MEDIA_TYPE_COLORS } from "../../../inc/constants";
import {
  chartSortedSentiments,
  sentimentOptions,
} from "../../../inc/sentimentOptions";
import PopoverForm from "../inc/PopoverForm";
import { EChartType } from "../../../inc/enums";
import { lowerFirst } from "lodash";
import {
  getSeriesMaxValue,
  getSortedAnalysisSplitItemKeys,
  useSeries,
} from "../inc/default";

import "./index.scss";
import AnalysisSplitChartBody from "../inc/AnalysisSplitChartBody";

export interface IMediaTypeWidgetSettings {
  chartType?: EChartType.donut | EChartType.column;
  splitType?: ESplitType.none | ESplitType.sentiment;
  valueType?: EValueType;
}

const MediaTypeWidget = ({
  colWidth,
  filterCacheResponse,
  height,
  onDelete,
  onFilterChange,
  onSettingsChange,
  onSettingsToggle,
  period,
  settings,
  uid,
  width,
}: IDashboardWidgetProps<IMediaTypeWidgetSettings>) => {
  const [analysisSplit, setAnalysisSplit] = React.useState<
    components["schemas"]["AnalysisSplit"] | null
  >();
  const { t } = React.useContext(I18nContext);
  const dateType = period?.dateType || "insertDate";
  const { chartType = EChartType.donut, valueType = EValueType.count } =
    settings || {};
  let { splitType = ESplitType.none } = settings || {};
  if (chartType === EChartType.donut) {
    splitType = ESplitType.none;
  }
  const type = chartType === EChartType.column ? EChartType.bar : chartType;

  React.useEffect(() => {
    setAnalysisSplit(null);
    if (filterCacheResponse?.token) {
      axios
        .get<components["schemas"]["AnalysisSplit"]>(
          `/analyse/cache/${filterCacheResponse.token}/${lowerFirst(
            valueType.replace("summed", ""),
          )}PerSentiment/mediaType`,
        )
        .then((res) => setAnalysisSplit(res.data));
      return;
    }
  }, [dateType, filterCacheResponse?.token, splitType, valueType]);

  const chartWidth = Math.floor(0.96 * width);
  // make space for bottom legend if needed
  const chartHeight = height - (splitType === ESplitType.none ? 58 : 80);
  const series = useSeries(
    analysisSplit,
    chartType,
    splitType,
    valueType,
    "",
    100,
    false,
  );
  const sortedAnalysisSplitItemKeys = React.useMemo(
    () => (analysisSplit ? getSortedAnalysisSplitItemKeys(analysisSplit) : []),
    [analysisSplit],
  );
  const chartOptions = React.useMemo(() => {
    if (!analysisSplit) {
      return {};
    }
    const yAxisLabels = sortedAnalysisSplitItemKeys.map(
      (key) => analysisSplit.perGroup[key],
    );
    const options = getDefaultOptions(
      chartType,
      valueType,
      splitType,
      chartWidth,
      chartHeight,
      getSeriesMaxValue(series),
      colWidth === 1,
      yAxisLabels,
    );
    const colors =
      splitType === ESplitType.sentiment
        ? chartSortedSentiments.map(
            (sentiment) => sentimentOptions[sentiment].color || "#ccc",
          )
        : sortedAnalysisSplitItemKeys.map(
            (key) =>
              MEDIA_TYPE_COLORS[
                analysisSplit.perGroup[key].toLowerCase() as "print"
              ],
          );

    const result = {
      ...options,
      colors,
      chart: {
        ...options.chart,
        events: {
          dataPointSelection: (
            event: React.MouseEvent | null,
            _chartContext: any,
            config: any,
          ) => {
            // non-primary button?
            if (event?.button) {
              return;
            }
            const { dataPointIndex, seriesIndex } = config;
            const mediaTypeName =
              analysisSplit.perGroup[
                sortedAnalysisSplitItemKeys[dataPointIndex]
              ];
            const mediaType = mediaTypeName.toLowerCase() as "print" | "web";

            if (onFilterChange) {
              const filterUpdate: Partial<components["schemas"]["Filter"]> = {};
              if (mediaTypeName) {
                filterUpdate.mediaTypes = [mediaType];
              }
              if (splitType !== ESplitType.none) {
                filterUpdate.sentiments = [chartSortedSentiments[seriesIndex]];
              }
              if (Object.keys(filterUpdate).length) {
                onFilterChange(filterUpdate);
              }
              return;
            }

            // if (onSearchEngineRequestChange && searchEngineRequest) {
            //   onSearchEngineRequestChange({
            //     ...searchEngineRequest,
            //     query: {
            //       ...searchEngineRequest.query,
            //       includeMediaPrint: mediaType === "print",
            //       includeMediaWeb: mediaType === "web",
            //     },
            //   });
            // }
          },
        },
      },
    };
    if (result.xaxis && result.plotOptions?.bar) {
      result.xaxis.categories = result.labels;
      result.plotOptions.bar.barHeight = "70%";
      result.plotOptions.bar.distributed = splitType === ESplitType.none;
      result.legend = {
        show: splitType !== ESplitType.none,
      };
    }
    return result;
  }, [
    analysisSplit,
    chartHeight,
    chartType,
    chartWidth,
    colWidth,
    onFilterChange,
    series,
    sortedAnalysisSplitItemKeys,
    splitType,
    valueType,
  ]);

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        allowImageDownload={!!onSettingsChange}
        title={t("MediaType")}
        subtitle={`${t(`valueType_${valueType}`)}${
          splitType !== ESplitType.none
            ? ` > ${t(`splitType_${splitType}`)}`
            : ""
        }`}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={
          onSettingsChange && settings ? (
            <PopoverForm<IMediaTypeWidgetSettings>
              onSettingsChange={onSettingsChange}
              settings={settings}
              chartTypes={[EChartType.donut, EChartType.bar, EChartType.column]}
              showRemainingOption={false}
              splitTypes={[ESplitType.none, ESplitType.sentiment]}
              widgetUid={uid}
            />
          ) : undefined
        }
      />
    ),
    [
      onDelete,
      onSettingsChange,
      onSettingsToggle,
      settings,
      splitType,
      t,
      uid,
      valueType,
    ],
  );

  return (
    <Panel
      bordered={true}
      header={header}
      className="views__dashboard-view__widgets__media-type-widget"
    >
      <AnalysisSplitChartBody
        analysisSplit={analysisSplit}
        splitType={splitType}
        type={type}
        chartOptions={chartOptions}
        series={series}
        width={width}
        height={height}
      />
    </Panel>
  );
};

export default MediaTypeWidget;
