import { Loader } from "rsuite";
import Chart from "react-apexcharts";
import React from "react";
import { components } from "../../../../types/openapi";
import { ESplitType } from "../../index";
import { ApexOptions } from "apexcharts";
import InsufficientDataBody from "../../../../inc/widgets/InsufficientDataBody";

interface IAnalysisSplitChartBodyProps {
  analysisSplit?: components["schemas"]["AnalysisSplit"] | null;
  chartOptions: ApexOptions;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  splitType: ESplitType;
  type?: "line" | "bar" | "pie" | "donut";
  width: number;
  height: number;
}

const AnalysisSplitChartBody = ({
  analysisSplit,
  chartOptions,
  series,
  splitType,
  type,
  width,
  height,
}: IAnalysisSplitChartBodyProps) => {
  if (!analysisSplit || !series) {
    return <Loader backdrop />;
  }

  const chartWidth = Math.floor(0.96 * width);
  // make space for bottom legend if needed
  const chartHeight = height - (splitType === ESplitType.none ? 58 : 80);

  // @ts-ignore
  if (!series.length || (series[0].data && !series[0].data.length)) {
    return <InsufficientDataBody />;
  }

  return (
    <div
      className="chart"
      style={{
        width: chartWidth,
        height: chartHeight,
        overflow: "hidden",
      }}
    >
      <Chart
        key={type}
        options={chartOptions}
        series={series}
        type={type}
        height={chartHeight}
        width={chartWidth}
      />
    </div>
  );
};

export default AnalysisSplitChartBody;
