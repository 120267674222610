import React from "react";
import { Button, Form, Loader, Modal } from "rsuite";
import { components } from "../../../../types/openapi";
import axios from "axios";
import MediaItemGrid from "../../../MediaItemGrid";
import FilterBar from "../../../FilterBar";
import { TSchemaFormErrors } from "../../../SchemaFormBody";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../../inc/schema";
import ajv from "../../../../inc/ajv";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import "./index.scss";
import { I18nContext } from "../../../../provider/I18nProvider";
import { LayoutContext } from "../../../../provider/LayoutProvider";

interface IFilterModalProps {
  allowEdit?: boolean;
  collapsibleTagsBar?: boolean;
  confirmationBeforeSubmit?: string;
  filter: components["schemas"]["Filter"] | undefined;
  onClose: (filter?: components["schemas"]["Filter"]) => void;
  onDelete?: () => void;
}

const filterSchema = openapi.components.schemas.Filter as oas30.SchemaObject;
const emptyFilter =
  getEmptyObject<components["schemas"]["Filter"]>(filterSchema);
const validate = ajv.compile(filterSchema);

const FilterModal = (props: IFilterModalProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const {
    allowEdit,
    collapsibleTagsBar,
    confirmationBeforeSubmit,
    onClose,
    onDelete,
  } = props;
  const [width, setWidth] = React.useState<number>(100);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const [filterCacheRequest, setFilterCacheRequest] = React.useState<
    components["schemas"]["FilterCacheRequest"] | undefined
  >({ filter: props.filter || emptyFilter });
  const [filterCacheResponse, setFilterCacheResponse] =
    React.useState<components["schemas"]["FilterCacheResponse"]>();
  const [filter, setFilter] = React.useState<components["schemas"]["Filter"]>(
    props.filter || emptyFilter,
  );

  const getWidth = React.useCallback(() => {
    const body = window.document
      .getElementsByClassName("test-result-modal__body")
      .item(0);
    if (body?.clientWidth) {
      setWidth(body.clientWidth);
    }
  }, []);

  React.useEffect(() => {
    if (!filterCacheRequest) {
      return;
    }
    axios
      .request<components["schemas"]["FilterCacheResponse"]>({
        method: "post",
        url: "/filter/cache",
        data: filterCacheRequest,
      })
      .then((res) => setFilterCacheResponse(res.data));
  }, [filterCacheRequest]);

  const onSubmit = React.useCallback(() => {
    if (
      !allowEdit ||
      (confirmationBeforeSubmit && !window.confirm(confirmationBeforeSubmit))
    ) {
      return;
    }
    const dirtyFilter: components["schemas"]["Filter"] = {
      ...filter,
    };
    const isValid = validate(dirtyFilter);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!dirtyFilter) {
      return;
    }
    setErrors({});
    onClose(dirtyFilter);
  }, [allowEdit, confirmationBeforeSubmit, filter, onClose]);

  const [mediaItems, setMediaItems] = React.useState<
    Array<components["schemas"]["MediaItem"] | null | undefined>
  >(new Array(filterCacheResponse?.count || 0).fill(undefined));

  React.useEffect(() => {
    setMediaItems(new Array(filterCacheResponse?.count || 0).fill(undefined));
  }, [filterCacheResponse?.count, filterCacheResponse?.token, setMediaItems]);

  const isMediaItemLoaded = React.useCallback(
    ({ index }: { index: number }) => {
      return mediaItems ? mediaItems[index] !== undefined : false;
    },
    [mediaItems],
  );

  const loadMoreMediaItems = React.useCallback(
    ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
      if (!filterCacheResponse?.token) {
        return Promise.reject(
          new Error("Cannot load mediaitems without token"),
        );
      }
      setMediaItems((mediaItems) => {
        // if (mediaItems[startIndex]) {
        //   console.log(startIndex, "already loaded!");
        // }
        return mediaItems.map((mediaItem, mediaItemKey) =>
          mediaItemKey >= startIndex && mediaItemKey <= stopIndex
            ? null
            : mediaItem,
        );
      });
      return axios
        .request<components["schemas"]["MediaItem"][]>({
          method: "post",
          url: "/filter/results",
          params: {
            filterCacheToken: filterCacheResponse.token,
            limit: stopIndex - startIndex,
            offset: startIndex,
            sort: "mediaItemSortType_insertDateAsc",
          },
        })
        .then((res) => {
          setMediaItems((mediaItems) =>
            mediaItems.map((mediaItem, mediaItemKey) =>
              mediaItemKey >= startIndex && mediaItemKey <= stopIndex
                ? res.data[mediaItemKey - startIndex]
                : mediaItem,
            ),
          );
        });
    },
    [filterCacheResponse?.token, setMediaItems],
  );

  return (
    <Modal
      show={true}
      onHide={() => onClose()}
      onClose={onClose}
      onEnter={getWidth}
      className="modal-size-auto components__form__filter-id-picker__filter-modal"
    >
      <Form>
        <Modal.Header>
          <Modal.Title>
            {t(props.filter === undefined ? "settingFilter" : "editingFilter")}
          </Modal.Title>
          <div style={{ display: "flex" }}>
            <Button
              appearance="primary"
              disabled={!allowEdit}
              onClick={onSubmit}
            >
              {t("save")}
            </Button>
            {onDelete && props.filter !== undefined ? (
              <Button
                appearance="primary"
                color="red"
                onClick={onDelete}
                className="ms-2"
              >
                {t("delete")}
              </Button>
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body
          className="test-result-modal__body modal-body-no-padding"
          style={{ maxHeight: undefined, minHeight: "25rem" }}
        >
          <div className="w-100">
            <FilterBar
              collapsibleTagsBar={
                collapsibleTagsBar !== undefined ? collapsibleTagsBar : true
              }
              filter={filter}
              onChange={
                !allowEdit
                  ? undefined
                  : (filter) => {
                      setFilter(filter);
                      setFilterCacheRequest({ filter });
                    }
              }
              width={windowOuterWidth}
            />
            {filterCacheRequest ? (
              <div className="pt-3">
                {filterCacheResponse ? (
                  <MediaItemGrid
                    filterCacheResponse={filterCacheResponse}
                    width={width}
                    height={450}
                    disabled={true}
                    isMediaItemLoaded={isMediaItemLoaded}
                    loadMoreMediaItems={loadMoreMediaItems}
                    mediaItems={mediaItems}
                  />
                ) : (
                  <div style={{ minHeight: 450 }}>
                    <Loader center size="lg" />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export default FilterModal;
