import { Dropdown, Icon, IconButton } from "rsuite";
import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import { components } from "../../../types/openapi";
import LabelIcon from "../../../icons/Label";
import LabelPopover from "../../LabelPopover/Filter";

interface ILabelControlProps {
  controlType: "menuItem" | "button";
  disabled: boolean;
  filter: components["schemas"]["Filter"];
  expandAndChange: (filter: components["schemas"]["Filter"]) => void;
}

const LabelControl = ({
  controlType,
  disabled,
  expandAndChange,
  filter,
}: ILabelControlProps) => {
  const { t } = React.useContext(I18nContext);
  return (
    <LabelPopover filter={filter} onChange={expandAndChange}>
      {controlType === "button" ? (
        <IconButton
          ripple={false}
          disabled={disabled}
          className={`filter-bar__filters-col__button`}
          size="xs"
          icon={<Icon icon="question" componentClass={LabelIcon} />}
          appearance="subtle"
        >
          {t("label")}
        </IconButton>
      ) : (
        <Dropdown.Item className="components__filter-bar__label-control">
          {t("label")}
        </Dropdown.Item>
      )}
    </LabelPopover>
  );
};

export default LabelControl;
