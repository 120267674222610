import React from "react";
import useQueryParameters from "../../../hooks/useQueryParameters";
import { AuthContext } from "../../../provider/AuthProvider";
import { Loader } from "rsuite";
import axios from "../../../inc/axios";
import { components } from "../../../types/openapi";
import { Redirect } from "react-router-dom";
import { APP_PATH } from "../../../inc/constants";
import { I18nContext } from "../../../provider/I18nProvider";
import ResetPasswordForm from "../inc/ResetPasswordForm";

const ShareDashboardView = () => {
  const { auth } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const [dashboardAccessTokenResponse, setDashboardAccessTokenResponse] =
    React.useState<components["schemas"]["DashboardAccessTokenResponse"]>();

  const { token } = useQueryParameters<{ token: string }>();

  React.useEffect(() => {
    if (!token) {
      setDashboardAccessTokenResponse({
        emailAddress: "",
        dashboardAccessTokenStatus: "tokenUnknown",
      });
      return;
    }
    axios
      .request<components["schemas"]["DashboardAccessTokenResponse"]>({
        method: "post",
        url: "/auth/dashboardRequest",
        data: {
          token,
        },
      })
      .then((res) => {
        setDashboardAccessTokenResponse(res.data);
      })
      .catch((err) => {
        console.log(err);
        setDashboardAccessTokenResponse({
          emailAddress: "",
          dashboardAccessTokenStatus: "tokenUnknown",
        });
      });
  }, [token]);

  if (!dashboardAccessTokenResponse) {
    return <Loader />;
  }

  let nextPath = `${APP_PATH}${
    dashboardAccessTokenResponse.dashboardId
      ? `/dashboards/${dashboardAccessTokenResponse.dashboardId}`
      : ``
  }`;
  if (
    auth ||
    dashboardAccessTokenResponse.dashboardAccessTokenStatus === "userExists"
  ) {
    if (dashboardAccessTokenResponse.customerLinkId && nextPath) {
      nextPath += `?customerLinkId=${dashboardAccessTokenResponse.customerLinkId}`;
    }
    return <Redirect to={nextPath} />;
  }

  switch (dashboardAccessTokenResponse.dashboardAccessTokenStatus) {
    case "tokenValid":
      return <ResetPasswordForm nextPath={nextPath} resetToken={token} />;

    case "tokenExpired":
      return (
        <>
          <h2>{t("dashboardAccessTokenExpiredTitle")}</h2>
          <p>
            {t(
              "dashboardAccessTokenExpiredBody",
              dashboardAccessTokenResponse.emailAddress
            )}
          </p>
        </>
      );

    case "tokenUnknown":
    default:
      return (
        <>
          <h2>{t("dashboardAccessTokenUnknownTitle")}</h2>
          <p>{t("dashboardAccessTokenUnknownBody")}</p>
        </>
      );
  }
};

export default ShareDashboardView;
