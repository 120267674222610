import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useEditorials = singletonHook(undefined, () => {
  const { editorials, setEditorials } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);
  const { userRoles = [] } = auth?.jwt || {};

  React.useEffect(() => {
    if (
      editorials !== undefined ||
      !auth?.bearer ||
      userRoles.indexOf("superuser") + userRoles.indexOf("dashboardOnly") >
        -2 ||
      auth?.jwt.customerRole === "inholland"
    ) {
      return;
    }
    setEditorials(null);
    axios
      .get<components["schemas"]["Editorial"][]>("/editorial/crud")
      .then((res) => {
        setEditorials(
          res.data.reduce<IHashMap<components["schemas"]["Editorial"]>>(
            (prev, editorial) => {
              prev[editorial.editorialId as string] = editorial;
              return prev;
            },
            {}
          )
        );
      });
  }, [
    auth?.bearer,
    auth?.jwt.customerRole,
    editorials,
    setEditorials,
    userRoles,
  ]);

  return editorials;
});

export default useEditorials;
