import React, { SVGProps } from "react";

const Hamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Hamburger"
    width={32}
    height={28}
    viewBox="0 0 32 28"
    fill="currentColor"
    {...props}
  >
    <rect id="prefix__Rectangle_1460" width={32} height={4} rx={1} />
    <rect
      id="prefix__Rectangle_1461"
      width={32}
      height={4}
      rx={1}
      transform="translate(0 12)"
    />
    <rect
      id="prefix__Rectangle_1462"
      width={32}
      height={4}
      rx={1}
      transform="translate(0 24)"
    />
  </svg>
);
export default Hamburger;
