import React from "react";
import { Alert, Button, Form, Loader, Modal } from "rsuite";
import { components } from "../../../types/openapi";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../../inc/ajv";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import axios from "axios";
import "./index.scss";
import { I18nContext } from "../../../provider/I18nProvider";
import useContentExports from "../../../hooks/useContentExports";
import { AuthContext } from "../../../provider/AuthProvider";

interface IContentExportModalProps {
  contentExportId?: string;
  show: boolean;
  onClose: () => void;
}

type TContentExport = components["schemas"]["ContentExport"];

const ContentExportchema = openapi.components.schemas
  .ContentExport as oas30.SchemaObject;
const validate = ajv.compile(ContentExportchema);

const { properties } = ContentExportchema;
if (!properties) {
  throw new Error("Incomplete contentExport schema");
}

const ContentExportModal = (props: IContentExportModalProps) => {
  const { contentExportId, show, onClose } = props;
  const { t } = React.useContext(I18nContext);
  const { updateContentExport, setContentExports } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const contentExports = useContentExports();
  const [contentExport, setContentExport] = React.useState<TContentExport>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors<TContentExport>>(
    {}
  );

  React.useEffect(() => {
    if (!contentExports) {
      return;
    }

    if (
      contentExport &&
      ((contentExport.contentExportId === undefined &&
        contentExportId === "NEW") ||
        contentExport.contentExportId === contentExportId)
    ) {
      return;
    }

    let newContentExport: TContentExport | undefined =
      contentExports && contentExportId
        ? contentExports[contentExportId]
        : undefined;
    if (!newContentExport) {
      newContentExport = {
        ...getEmptyObject(ContentExportchema),
      } as TContentExport;
    }

    if (!newContentExport) {
      throw new Error("Could not construct contentExport for form");
    }
    setContentExport(JSON.parse(JSON.stringify(newContentExport)));
  }, [contentExports, contentExportId, contentExport]);

  const formIsDirty = React.useMemo(() => {
    const originalContentExport =
      contentExports && contentExportId
        ? contentExports[contentExportId]
        : null;
    return (
      JSON.stringify(originalContentExport) !== JSON.stringify(contentExport)
    );
  }, [contentExports, contentExportId, contentExport]);

  const onSubmit = React.useCallback(() => {
    const isValid = validate(contentExport);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!contentExport) {
      return;
    }
    setErrors({});
    updateContentExport(contentExport)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [onClose, contentExport, t, updateContentExport]);

  const onDelete = React.useCallback(() => {
    if (contentExportId === "NEW") {
      return;
    }
    axios
      .delete(`/contentExport/crud/${contentExportId}`)
      .then(() => {
        const newContentExport: { [key: string]: TContentExport } = {
          ...contentExports,
        };
        delete newContentExport[contentExportId as string];
        setContentExports(newContentExport);
        Alert.success(t("deleteSuccessful"));
        onClose();
      })
      .catch((err) => {
        Alert.error(t(err.message || "genericErrorMessage"));
      });
  }, [onClose, contentExportId, contentExports, setContentExports, t]);

  const isSuperUser = !!auth?.jwt.superUserId;

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="modal-size-auto views__contentExport-view__contentExport-modal"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>
            {contentExport ? contentExport.name : t("loading")}
          </Modal.Title>
          <div className="d-flex">
            <Button type="submit" appearance="primary" disabled={!formIsDirty}>
              {t("save")}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: undefined }}>
          <div className="w-100">
            {contentExport ? (
              <>
                <SchemaFormBody<TContentExport>
                  schema={{
                    ...ContentExportchema,
                    properties,
                  }}
                  config={
                    {
                      title: {
                        order: 1,
                      },
                      url: {
                        order: 2,
                      },
                      labelIds: {
                        order: 3,
                      },
                      lastIndexDate: {
                        hidden: true,
                      },
                    } as any
                  }
                  onChange={(updatedContentExport) => {
                    setContentExport(updatedContentExport);
                  }}
                  value={contentExport}
                  errors={errors}
                  inputClassName="input-gray"
                />
                {!!contentExport?.contentExportId && isSuperUser ? (
                  <Button
                    style={{ marginTop: 48 }}
                    onClick={onDelete}
                    appearance="primary"
                    color="red"
                  >
                    {t("delete")}
                  </Button>
                ) : null}
              </>
            ) : (
              <Loader center size="lg" />
            )}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export default ContentExportModal;
