import React, { RefObject } from "react";
import {
  Dropdown,
  Popover,
  Whisper,
  Nav,
  Icon,
  InputGroup,
  Input,
} from "rsuite";
import { components } from "../../../types/openapi";
import "./index.scss";
import { LayoutContext } from "../../../provider/LayoutProvider";
import axios from "../../../inc/axios";
import { useHistory } from "react-router-dom";
import { APP_PATH } from "../../../inc/constants";
import { dataContainsWordThatStartWith } from "../../../inc/query";
import MagnifyingGlass from "../../../icons/MagnifyingGlass";
import CompanyAvatarSmall from "../../../icons/CompanyAvatarSmall";
import CompanyAvatar from "../../../icons/CompanyAvatar";
import { I18nContext } from "../../../provider/I18nProvider";
import useCustomers from "../../../hooks/useCustomers";
import { AuthContext } from "../../../provider/AuthProvider";
import useMes from "../../../hooks/useMes";

const CustomerMenu = () => {
  const { auth } = React.useContext(AuthContext);
  const { isSidebarOpen } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const customers = useCustomers();

  const currentCustomer =
    customers && auth?.jwt.currentCustomerLinkId
      ? customers[auth.jwt.currentCustomerLinkId]
      : undefined;
  const mes = useMes();

  const history = useHistory();
  const [searchQuery, setSearchQuery] = React.useState("");

  const myCustomers: components["schemas"]["Customer"][] = React.useMemo(() => {
    if (!auth || !customers || !mes) {
      return [];
    }

    if (!mes.length || !customers) {
      return [];
    }
    return mes
      .reduce<components["schemas"]["Customer"][]>((prev, me) => {
        me.customerLinkIds.forEach((customerLinkId) => {
          const customer = customers[customerLinkId];
          if (customer) {
            prev.push(customer);
          }
        });
        return prev;
      }, [])
      .sort((customerA, customerB) => {
        if (customerA.active === customerB.active) {
          return customerA.customerName.localeCompare(customerB.customerName);
        }
        return customerA.active ? -1 : 1;
      });
  }, [auth, customers, mes]);
  const isDisabled = myCustomers.length <= 1;

  const optionCustomers = React.useMemo(
    () =>
      myCustomers.filter(
        (customer) =>
          dataContainsWordThatStartWith(customer.customerName, searchQuery) ||
          dataContainsWordThatStartWith(`${customer.customerNo}`, searchQuery)
      ),
    [myCustomers, searchQuery]
  );

  const isDashboardOnly = auth
    ? auth.jwt.userRoles?.indexOf("dashboardOnly") >= 0
    : false;
  const renderCustomerTitle = React.useCallback(
    (customer: components["schemas"]["Customer"]) => {
      return `${customer.customerName}${
        isDashboardOnly ? "" : ` (${customer.customerNo})`
      }`;
    },
    [isDashboardOnly]
  );

  const triggerRef: RefObject<any> = React.useRef();
  const popoverContainerRef = React.useRef(null);

  if (!currentCustomer) {
    return null;
  }

  let currentCustomerIcon = (
    <Icon
      icon="avatar"
      componentClass={CompanyAvatar}
      style={{ marginLeft: isSidebarOpen ? -8 : -10 }}
    />
  );
  if (currentCustomer.logoFileId) {
    currentCustomerIcon = (
      <i className="rs-icon customer-menu__current-customer-icon">
        <img
          src={`${axios.defaults.baseURL}file/crud/${currentCustomer.logoFileId}?bearer=${auth?.bearer}`}
          className="img-fluid rounded-circle"
          alt=""
        />
      </i>
    );
  }
  if (!currentCustomer.active) {
    currentCustomerIcon = (
      <Icon
        icon="unlink"
        style={{ left: 36, top: 10 }}
        title={t("inactiveCustomerTooltip")}
      />
    );
  }

  return (
    <div className="customer-menu" ref={popoverContainerRef}>
      <Whisper
        placement={isSidebarOpen ? "top" : "rightEnd"}
        triggerRef={triggerRef}
        container={popoverContainerRef.current || undefined}
        trigger={isDisabled ? "none" : "click"}
        speaker={
          <Popover
            full
            className="popover-without-arrow customer-menu__popover"
          >
            <InputGroup inside size="sm" className="m-2 w-auto">
              <InputGroup.Addon>
                <Icon icon="search" componentClass={MagnifyingGlass} />
              </InputGroup.Addon>
              <Input
                value={searchQuery}
                onChange={setSearchQuery}
                className="customer-menu__popover__search-bar"
              />
            </InputGroup>
            {optionCustomers.length ? (
              <Dropdown.Menu
                className="customer-menu__menu"
                onSelect={(eventKey: string) => {
                  if (triggerRef.current) {
                    triggerRef.current.hide();
                  }
                  history.push(`${APP_PATH}/switchCustomer/${eventKey}`);
                }}
              >
                {optionCustomers.map((customer, key) => {
                  let icon = (
                    <Icon
                      icon="avatar"
                      componentClass={CompanyAvatarSmall}
                      style={{ height: 16, marginRight: 4 }}
                    />
                  );
                  if (customer.logoFileId) {
                    icon = (
                      <i>
                        <span className="customer-menu__icon">
                          <img
                            src={`${axios.defaults.baseURL}file/crud/${customer.logoFileId}?bearer=${auth?.bearer}`}
                            className="img-fluid rounded-circle"
                            alt=""
                          />
                        </span>
                      </i>
                    );
                  }
                  if (!customer.active) {
                    icon = (
                      <Icon
                        icon="unlink"
                        title={t("inactiveCustomerTooltip")}
                      />
                    );
                  }
                  return (
                    <Dropdown.Item
                      key={key}
                      eventKey={customer.customerLinkId}
                      icon={icon}
                      disabled={
                        customer.customerLinkId ===
                        currentCustomer.customerLinkId
                      }
                    >
                      {renderCustomerTitle(customer)}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            ) : (
              <div className="p-2">{t("noResultsText")}</div>
            )}
          </Popover>
        }
      >
        <Nav.Item
          hasTooltip={false}
          disabled={isDisabled}
          icon={currentCustomerIcon}
        >
          {renderCustomerTitle(currentCustomer)}
          {!isDisabled ? (
            <span className="customer-menu__menu__arrows">
              <Icon
                className="customer-menu__menu__arrow customer-menu__menu__arrow--top"
                icon="angle-up"
              />
              <Icon
                className="customer-menu__menu__arrow customer-menu__menu__arrow--bottom"
                icon="angle-down"
              />
            </span>
          ) : null}
        </Nav.Item>
      </Whisper>
    </div>
  );
};

export default React.memo(CustomerMenu);
