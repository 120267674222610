import React from "react";
import { Checkbox, Table } from "rsuite";
import { IRenderData } from "../index";

const { Cell } = Table;

interface ICheckboxCellProps {
  getSelected: (item: any) => boolean;
  isDisabled: (item: any) => boolean;
  onChange: (rowData: IRenderData, selected: boolean) => void;
  rowData?: any;
}

const CheckboxCell = ({
  rowData,
  getSelected,
  isDisabled,
  onChange,
  ...rest
}: ICheckboxCellProps) => (
  <Cell className="schema-table__select-cell" {...rest}>
    <Checkbox
      checked={getSelected(rowData)}
      disabled={isDisabled(rowData)}
      onChange={(_:unknown, selected) => {
        onChange(rowData, selected);
      }}
    />
  </Cell>
);

export default CheckboxCell;
