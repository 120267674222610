import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { IHashMap } from "../inc/data";
import { components } from "../types/openapi";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";
import { TBenchmark } from "../types";

export const useBenchmarks = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { benchmarks, setBenchmarks } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      benchmarks !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    const { currentCustomerLinkId, customerRole } = auth.jwt;
    const isInholland = customerRole === "inholland";

    if (currentCustomerLinkId && !isInholland && auth.jwt.canBenchmark) {
      setBenchmarks(null);
      axios
        .get<components["schemas"]["Benchmark"][]>("/benchmark/crud")
        .then((res) => {
          setBenchmarks(
            res.data.reduce<IHashMap<TBenchmark>>((prev, benchmark) => {
              prev[benchmark.benchmarkId as string] = benchmark as TBenchmark;
              if (!prev[benchmark.benchmarkId as string].config) {
                prev[benchmark.benchmarkId as string].config = {};
              }
              return prev;
            }, {}),
          );
        })
        .catch((err) => {
          console.log(err);
          Alert.error(t("apiDataProvider_fetchingFailed_benchmark"));
        });
    }
  }, [auth?.bearer, setBenchmarks, benchmarks, t, auth?.jwt]);

  return benchmarks;
});
