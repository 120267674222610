import React from "react";
import { Alert, Button, Loader, Modal } from "rsuite";
import { format, subYears } from "date-fns";
import { AxiosError } from "axios";
import { oas30 } from "openapi3-ts";

import { I18nContext } from "../../../provider/I18nProvider";
import { IMaartenError, TUser } from "../../../types";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { AuthContext } from "../../../provider/AuthProvider";
import SchemaTable from "../../../components/SchemaTable";
import openapi from "../../../openapi.json";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { DATE_FORMAT_REVERSE } from "../../../inc/date";

import "./index.scss";

const schema = openapi.components.schemas.Log as oas30.SchemaObject;

interface ILogModalProps {
  user: TUser;
  onClose: () => void;
}

const LogModal = ({ onClose, user }: ILogModalProps) => {
  const { windowInnerHeight } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const [detailedLog, setDetailedLog] =
    React.useState<components["schemas"]["Log"]>();

  const [logs, setLogs] = React.useState<
    components["schemas"]["Log"][] | null
  >();

  const doClose = React.useCallback(() => {
    if (detailedLog) {
      setDetailedLog(undefined);
      return;
    }
    onClose();
  }, [detailedLog, onClose]);

  React.useEffect(() => {
    if (logs !== undefined) {
      return;
    }
    setLogs(null);
    axios
      .request<components["schemas"]["Log"][]>({
        method: "get",
        url: `/log/crud/${user.userId}`,
        params: {
          startDate: format(subYears(new Date(), 1), DATE_FORMAT_REVERSE),
        },
      })
      .then((res) => {
        setLogs(res.data);
      })
      .catch((err: AxiosError<IMaartenError>) => {
        setLogs([]);
        Alert.error(t(err.response?.data.error || "genericErrorMessage"));
      });
  }, [logs, t, user.userId]);

  if (!auth) {
    return null;
  }

  return (
    <>
      <Modal show onHide={doClose} className="modal-size-auto">
        <Modal.Header>
          <Modal.Title>{user ? user.displayName : t("loading")}</Modal.Title>
          <div style={{ display: "flex" }}>
            <Button onClick={doClose} appearance="primary">
              {t("close")}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body
          style={
            // overrule inline styling
            { maxHeight: undefined, padding: 0 }
          }
        >
          <div className="modal__content p-3">
            {!auth || !logs ? (
              <Loader center size="lg" />
            ) : detailedLog ? (
              <div>
                <div className="sheet">
                  <h3>Oude gegevens</h3>
                  <pre>
                    {detailedLog.oldJson
                      ? `${JSON.stringify(
                          JSON.parse(detailedLog.oldJson),
                          null,
                          4,
                        )}`
                      : ""}
                  </pre>
                  <h3>Nieuwe gegevens</h3>
                  <pre>
                    {detailedLog.newJson
                      ? `${JSON.stringify(
                          JSON.parse(detailedLog.newJson),
                          null,
                          4,
                        )}`
                      : ""}
                  </pre>
                </div>
              </div>
            ) : (
              <SchemaTable<components["schemas"]["Log"]>
                data={logs}
                schema={schema}
                sortable
                globalSearch
                height={windowInnerHeight - 500}
                onRowClick={(a) => {
                  setDetailedLog(a);
                }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogModal;
