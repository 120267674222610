import React from "react";
import { InputPicker } from "rsuite";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { onSelect } from "../inc/label";
import { ItemDataType } from "rsuite/lib/@types/common";
import useLabels from "../../../hooks/useLabels";

import "./index.scss";

interface ILabelIdPickerProps {
  onChange?: (
    labelId: string | undefined,
    event: React.SyntheticEvent<HTMLElement, Event>
  ) => void;
  value?: string;
}

const LabelIdPicker = ({ onChange, value }: ILabelIdPickerProps) => {
  const { updateLabel } = React.useContext(ApiDataContext);
  const labels = useLabels();
  const [isCreatable, setIsCreatable] = React.useState<boolean>();
  const data = React.useMemo(
    () =>
      labels
        ? Object.values(labels).map((label) => ({
            value: label.labelId,
            label: label.name,
          }))
        : [],
    [labels]
  );
  return (
    <InputPicker
      data={data}
      value={value}
      onSearch={(searchKeyword: any) => {
        // Label picker is creatable if non of the options contain the searchKeyword
        setIsCreatable(
          !data.find((item) => item.label.includes(searchKeyword))
        );
      }}
      onSelect={onSelect}
      onChange={(labelId, e: any) => {
        if (!onChange) {
          return;
        }
        const newLabelName = e._newLabelName;
        if (newLabelName && data.find((item) => item.label === newLabelName)) {
          console.log("Duplicate label detected");
          return;
        }
        const labelIdPromise = newLabelName
          ? updateLabel({
              name: newLabelName,
            }).then((label) => label.labelId)
          : Promise.resolve(labelId);
        labelIdPromise.then((labelId) => {
          onChange(labelId, e);
        });
      }}
      creatable={isCreatable}
      renderMenuItem={
        ((labelName: string) => (
          <span className={`components__form__label-id-picker__menu-item`}>
            {labelName}
          </span>
        )) as any
      }
      renderValue={
        ((labelName: string, item: ItemDataType) => (
          <span className={`components__form__label-id-picker__value`}>
            {item?.label || labelName}
          </span>
        )) as any
      }
      style={{ width: 300 }}
    />
  );
};

export default LabelIdPicker;
