import React from "react";
import { components } from "../types/openapi";
import axios from "axios";
import { Notification } from "rsuite";
import JobProgressMessage from "../components/JobProgressMessage";
import { v4 } from "uuid";
import TranslatedText from "./i18n/TranslatedText";
import I18nProvider from "../provider/I18nProvider";

export const startJob = async (
  job: components["schemas"]["Job"],
  onFinish?: () => void
) => {
  const key = v4();
  const jobStatus: components["schemas"]["JobStatus"] = { jobStatus: "wait" };
  Notification.open({
    key,
    title: (
      <I18nProvider>
        <TranslatedText text={job.jobType} />
      </I18nProvider>
    ),
    description: (
      <I18nProvider>
        <JobProgressMessage
          job={
            await axios
              .request<components["schemas"]["Job"]>({
                method: "post",
                url: "/job/crud",
                data: job,
              })
              .then((res) => res.data)
              .catch((err) => {
                jobStatus.jobStatus = "error";
                jobStatus.result = err.message;
                return job;
              })
          }
          jobStatus={jobStatus}
          onClose={() => {
            try {
              Notification.close(key);
            } catch (e) {
              console.log(e);
            }
          }}
          onFinish={onFinish}
        />
      </I18nProvider>
    ),
    duration: 0,
  });
};
