import React from "react";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { Alert, Button, Form, Loader, Modal } from "rsuite";
import { components } from "../../../types/openapi";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import ajv from "../../../inc/ajv";
import axios from "axios";
import { I18nContext } from "../../../provider/I18nProvider";
import useLabels from "../../../hooks/useLabels";

interface ILabelModalProps {
  labelId?: string;
  onClose: () => void;
}

type TLabel = components["schemas"]["Label"];

const LabelSchema = openapi.components.schemas.Label as oas30.SchemaObject;
const validate = ajv.compile(LabelSchema);

const LabelModal = (props: ILabelModalProps) => {
  const { labelId, onClose } = props;
  const { t } = React.useContext(I18nContext);
  const { updateLabel, setLabels } = React.useContext(ApiDataContext);
  const labelMap = useLabels();
  const [label, setLabel] = React.useState<TLabel>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});

  React.useEffect(() => {
    if (!labelMap) {
      return;
    }

    if (
      label &&
      ((label.labelId === undefined && labelId === "NEW") ||
        label.labelId === labelId)
    ) {
      return;
    }

    let newLabel: TLabel | undefined =
      labelMap && labelId ? labelMap[labelId] : undefined;
    if (!newLabel) {
      newLabel = {
        ...getEmptyObject(LabelSchema),
      } as TLabel;
    }

    if (!newLabel) {
      throw new Error("Could not construct label automator for form");
    }
    setLabel(JSON.parse(JSON.stringify(newLabel)));
  }, [labelId, label, labelMap]);

  const formIsDirty = React.useMemo(() => {
    const originalLabel = labelMap && labelId ? labelMap[labelId] : null;
    return JSON.stringify(originalLabel) !== JSON.stringify(label);
  }, [label, labelId, labelMap]);

  const onSubmit = React.useCallback(() => {
    if (!label || !labelMap) {
      return;
    }
    const isValid = validate(label);
    let errors: TSchemaFormErrors | null = null;
    if (!isValid && validate.errors && validate.errors.length) {
      errors = validationErrorsToSchemaFormErrors(validate.errors);
    } else {
      const lcName = label.name.toLowerCase();
      const existingLabel = Object.values(labelMap).find(
        (obj) =>
          obj.labelId !== label.labelId && obj.name.toLowerCase() === lcName
      );
      if (existingLabel) {
        errors = { name: "name already exists" };
      }
    }
    if (errors) {
      setErrors(errors);
      setTimeout(focusError, 200);
      return;
    }
    updateLabel(label)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [label, labelMap, updateLabel, onClose, t]);

  const onDelete = React.useCallback(() => {
    axios
      .delete<{ success: boolean }>(`/label/crud/${labelId}`)
      .then((res) => {
        if (!res.data.success) {
          Alert.error(t("labelCanNotBeDeleted"));
          return;
        }
        const newLabelMap: { [key: string]: TLabel } = { ...labelMap };
        delete newLabelMap[labelId as string];
        setLabels(newLabelMap);
        Alert.success(t("deleteSuccessful"));
        onClose();
      })
      .catch((err) => {
        Alert.error(t(err.message || "genericErrorMessage"));
      });
  }, [labelId, labelMap, onClose, setLabels, t]);

  return (
    <Modal show onHide={onClose}>
      <Form className="w-100">
        <Modal.Header>
          <Modal.Title>{label ? label.name : t("loading")}</Modal.Title>
          <div style={{ display: "flex" }}>
            <Button
              onClick={onSubmit}
              appearance="primary"
              disabled={!formIsDirty}
              type="submit"
            >
              {t("save")}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "30vh", maxHeight: undefined }}>
          {label ? (
            <>
              <SchemaFormBody<TLabel>
                schema={LabelSchema}
                onChange={(updatedLabel) => {
                  setLabel(updatedLabel);
                }}
                value={label}
                errors={errors}
                inputClassName="input-gray"
              />
              {!!label?.labelId ? (
                <Button
                  style={{ marginTop: 48 }}
                  onClick={onDelete}
                  appearance="primary"
                  color="red"
                  type="button"
                >
                  {t("delete")}
                </Button>
              ) : null}
            </>
          ) : (
            <Loader center size="lg" />
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export default LabelModal;
