import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useNewContentNotifications = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { newContentNotifications, setNewContentNotifications } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (newContentNotifications !== undefined || !auth?.bearer) {
      return;
    }
    setNewContentNotifications(null);
    axios
      .get<components["schemas"]["NewContentNotification"][]>(
        "/newContentNotification/crud"
      )
      .then((res) => {
        setNewContentNotifications(
          res.data.reduce<
            IHashMap<components["schemas"]["NewContentNotification"]>
          >((prev, newContentNotification) => {
            prev[newContentNotification.newContentNotificationId as string] =
              newContentNotification;
            return prev;
          }, {})
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_newContentNotification"));
      });
  }, [auth?.bearer, setNewContentNotifications, newContentNotifications, t]);

  return newContentNotifications;
});

export default useNewContentNotifications;
