import React from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Icon,
  IconButton,
  SelectPicker,
} from "rsuite";
import {
  format,
  isAfter,
  startOfDay,
  subYears,
  subDays,
  isBefore,
} from "date-fns";

import { components } from "../../../types/openapi";
import DatepickerPublication from "../../../icons/DatepickerPublication";
import DatepickerInsert from "../../../icons/DatepickerInsert";
import {
  DATE_COMPACT_FORMAT,
  DATE_FORMAT,
  DATE_FORMAT_REVERSE,
  differenceInDays,
  getComparePeriodDateRange,
  getPeriodDateRange,
  IPeriods,
  localeFormat,
  periodTypeToDateRange,
} from "../../../inc/date";
import { I18nContext } from "../../../provider/I18nProvider";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { BootstrapSize } from "../../../inc/constants";
import DateRangeDatePicker, {
  DatePickerProps,
  // @ts-ignore
} from "rsuite/lib/DateRangePicker/DatePicker";
// @ts-ignore
import Toolbar from "rsuite/lib/DateRangePicker/Toolbar";
// @ts-ignore
import IntlContext from "rsuite/lib/IntlProvider/IntlContext";
import {
  getCalendarDate,
  setTimingMargin,
  // @ts-ignore
} from "rsuite/lib/DateRangePicker/utils";
import { ValueType } from "rsuite/lib/DateRangePicker";
import AnchoredLightboxedItem from "../../AnchoredLightboxedItem";

import openapi from "../../../openapi.json";
import "./index.scss";

interface IPeriodsControlProps {
  locale?: any;
  onPeriodsChange: (periods: IPeriods) => void;
  periods: IPeriods;
}

const PeriodsControl = ({ onPeriodsChange, periods }: IPeriodsControlProps) => {
  const { t } = React.useContext(I18nContext);
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { rsuiteLocale } = React.useContext(I18nContext);

  const [period, setPeriod] = React.useState<components["schemas"]["Period"]>(
    periods.period,
  );
  const [comparePeriod, setComparePeriod] = React.useState<
    components["schemas"]["Period"] | undefined
  >(periods.comparePeriod);
  const [anchorPoint, setAnchorPoint] = React.useState<
    { x: number; y: number } | undefined
  >();

  React.useEffect(() => {
    setPeriod(periods.period);
  }, [periods.period]);

  React.useEffect(() => {
    setComparePeriod(periods.comparePeriod);
  }, [periods.comparePeriod]);

  // The compare period should "follow" any changes in the regular period if applicable
  React.useEffect(() => {
    if (period.periodType === "all") {
      setComparePeriod(undefined);
      return;
    }
    if (
      comparePeriod &&
      period.periodType !== "custom" &&
      !["yearToDate", "custom"].includes(comparePeriod.periodType)
    ) {
      setComparePeriod(period);
    }
  }, [comparePeriod, period]);

  const periodCustomStartDate = React.useMemo(
    () =>
      period.periodType === "custom" && period.startDate
        ? new Date(period.startDate)
        : null,
    [period.periodType, period.startDate],
  );
  const periodCustomEndDate = React.useMemo(
    () =>
      period.periodType === "custom" && period.endDate
        ? new Date(period.endDate)
        : null,
    [period.endDate, period.periodType],
  );
  const comparePeriodCustomStartDate = React.useMemo(
    () =>
      comparePeriod?.periodType === "custom" && comparePeriod?.startDate
        ? new Date(comparePeriod.startDate)
        : null,
    [comparePeriod?.periodType, comparePeriod?.startDate],
  );
  const comparePeriodCustomEndDate = React.useMemo(
    () =>
      comparePeriod?.periodType === "custom" && comparePeriod?.endDate
        ? new Date(comparePeriod.endDate)
        : null,
    [comparePeriod?.endDate, comparePeriod?.periodType],
  );

  const datePickerValue = React.useMemo<ValueType>(() => {
    if (!period) {
      return [];
    }
    switch (period.periodType) {
      case "custom":
        return periodCustomStartDate && periodCustomEndDate
          ? [periodCustomStartDate, periodCustomEndDate]
          : [];

      default:
        return periodTypeToDateRange(period.periodType) || [];
    }
  }, [period, periodCustomEndDate, periodCustomStartDate]);

  const [calendarDate, setCalendarDate] = React.useState<ValueType>(
    getCalendarDate(datePickerValue),
  );
  const [newSelectStartDate, setNewSelectStartDate] = React.useState<Date>();
  const [hoverDate, setHoverDate] = React.useState<Date>();

  React.useEffect(() => {
    setCalendarDate(getCalendarDate(datePickerValue));
  }, [datePickerValue]);

  const icon = React.useMemo(
    () => (
      <Icon
        icon="avatar"
        componentClass={
          period?.dateType === "publicationDate"
            ? DatepickerPublication
            : DatepickerInsert
        }
      />
    ),
    [period?.dateType],
  );

  const label = React.useMemo(() => {
    if (period) {
      const dateFormat =
        windowOuterWidth > BootstrapSize.XS ? DATE_FORMAT : DATE_COMPACT_FORMAT;
      return period.periodType === "custom" &&
        periodCustomStartDate &&
        periodCustomEndDate
        ? `${localeFormat(periodCustomStartDate, dateFormat)} ~ ${localeFormat(
            periodCustomEndDate,
            dateFormat,
          )}`
        : t(period.periodType);
    }
    return t("all");
  }, [period, periodCustomEndDate, periodCustomStartDate, t, windowOuterWidth]);

  const compareLabel = React.useMemo(() => {
    if (comparePeriod) {
      const dateFormat =
        windowOuterWidth > BootstrapSize.XS ? DATE_FORMAT : DATE_COMPACT_FORMAT;
      if (comparePeriod.periodType === "custom") {
        if (!comparePeriod.startDate || !comparePeriod.endDate) {
          return t("customPeriod");
        }
        return `${t(`comparedWith`)} ${localeFormat(
          new Date(comparePeriod.startDate),
          dateFormat,
        )} ~ ${localeFormat(new Date(comparePeriod.endDate), dateFormat)}`;
      }
      return t(`comparedWith_${comparePeriod.periodType}`);
    }
    return "";
  }, [comparePeriod, t, windowOuterWidth]);

  const ranges = React.useMemo(() => {
    const today = startOfDay(new Date());
    return openapi.components.schemas.Period.properties.periodType.enum
      .filter((periodType) => periodType !== "custom")
      .map((periodType) => ({
        periodType,
        label: t(periodType),
        value: periodTypeToDateRange(
          periodType as components["schemas"]["Period"]["periodType"],
        ) || [subYears(today, 10), today],
      }));
  }, [t]);

  const onRangeClick = React.useCallback(
    (
      value: any,
      _closeOverlay: unknown,
      event: React.MouseEvent<HTMLAnchorElement> | undefined,
    ) => {
      const range = ranges.find(
        (range) => range.label === event?.currentTarget.innerText,
      );
      const periodType = range
        ? (range.periodType as components["schemas"]["Period"]["periodType"])
        : undefined;

      if (period) {
        setPeriod(
          (value[0] && !periodType) || periodType === "custom"
            ? {
                ...period,
                periodType: "custom",
                startDate: format(value[0] as Date, DATE_FORMAT_REVERSE),
                endDate: format(value[1] as Date, DATE_FORMAT_REVERSE),
              }
            : {
                ...period,
                periodType: periodType || "all",
                startDate: undefined,
                endDate: undefined,
              },
        );
      }
    },
    [ranges, period],
  );

  const onChangeCalendarDate = React.useCallback(
    (calendarIndex: number, nextPageDate: Date) => {
      const newCalendarDate: ValueType = [...calendarDate];
      newCalendarDate[calendarIndex] = nextPageDate;
      setCalendarDate(newCalendarDate);
    },
    [calendarDate],
  );

  const showOneCalendar = windowOuterWidth < BootstrapSize.SM;

  const pickerProps = React.useMemo<DatePickerProps>(
    () => ({
      isoWeek: true,
      value:
        newSelectStartDate && hoverDate
          ? [newSelectStartDate, hoverDate]
          : datePickerValue,
      format: "YYYY-MM-DD",
      calendarDate,
      hoverValue:
        newSelectStartDate && hoverDate
          ? [newSelectStartDate, hoverDate]
          : undefined,
      onMouseMove: setHoverDate,
      onChangeCalendarDate,
      onSelect: (date: Date) => {
        // event: React.SyntheticEvent<any>
        if (!setPeriod || !period) {
          return;
        }
        if (newSelectStartDate) {
          const nextValue = [newSelectStartDate, date];
          if (isAfter(nextValue[0], nextValue[1])) {
            nextValue.reverse();
          }
          nextValue[0] = setTimingMargin(nextValue[0], "left");
          nextValue[1] = setTimingMargin(nextValue[1], "right");
          setPeriod({
            ...period,
            periodType: "custom",
            startDate: format(nextValue[0], DATE_FORMAT_REVERSE),
            endDate: format(nextValue[1], DATE_FORMAT_REVERSE),
          });
          setNewSelectStartDate(undefined);
        } else {
          setNewSelectStartDate(date);
        }
      },
    }),
    [
      calendarDate,
      datePickerValue,
      hoverDate,
      newSelectStartDate,
      onChangeCalendarDate,
      setPeriod,
      period,
    ],
  );

  const onCompareToCheckboxChange = React.useCallback(
    (_: unknown, checked: boolean) => {
      if (!onPeriodsChange) {
        return;
      }
      setComparePeriod(checked ? period : undefined);
    },
    [onPeriodsChange, period],
  );

  const periodDateRange = React.useMemo(
    () => (period ? getPeriodDateRange(period) : []),
    [period],
  );
  const periodLength = React.useMemo(() => {
    return periodDateRange.length
      ? differenceInDays(
          startOfDay(periodDateRange[1]),
          startOfDay(periodDateRange[0]),
        ) + 1
      : 0;
  }, [periodDateRange]);

  const comparePeriodDateRange = React.useMemo(
    () =>
      comparePeriod ? getComparePeriodDateRange(comparePeriod, period) : [],
    [comparePeriod, period],
  );
  const comparePeriodLength = React.useMemo(
    () =>
      comparePeriodDateRange && comparePeriodDateRange.length
        ? differenceInDays(
            comparePeriodDateRange[1],
            comparePeriodDateRange[0],
          ) + 1
        : 0,
    [comparePeriodDateRange],
  );

  const comparePeriodType = React.useMemo<
    "customPeriod" | "previousPeriod" | "previousYear"
  >(() => {
    switch (comparePeriod?.periodType) {
      case "custom":
        if (
          periodDateRange.length &&
          periodLength === comparePeriodLength &&
          differenceInDays(periodDateRange[0], comparePeriodDateRange[1]) === 1
        ) {
          return "previousPeriod";
        }

        return "customPeriod";

      case "yearToDate":
        return "previousYear";

      default:
        return "previousPeriod";
    }
  }, [
    comparePeriod?.periodType,
    comparePeriodDateRange,
    comparePeriodLength,
    periodDateRange,
    periodLength,
  ]);

  const onComparePeriodTypeChange = React.useCallback(
    (newPeriodType: "customPeriod" | "previousPeriod" | "previousYear") => {
      if (!period || !comparePeriod || !onPeriodsChange) {
        return;
      }
      switch (newPeriodType) {
        case "customPeriod":
          setComparePeriod({
            ...comparePeriod,
            periodType: "custom",
          });
          break;

        case "previousPeriod":
          let { periodType } = period;
          let startDate: string | undefined = undefined;
          let endDate: string | undefined = undefined;
          if (
            ["yearToDate", "custom"].includes(period.periodType) &&
            periodDateRange.length &&
            periodLength
          ) {
            periodType = "custom";
            endDate = format(
              subDays(periodDateRange[0], 1),
              DATE_FORMAT_REVERSE,
            );
            startDate = format(
              subDays(periodDateRange[0], periodLength),
              DATE_FORMAT_REVERSE,
            );
          }

          setComparePeriod({
            dateType: period.dateType,
            periodType,
            startDate,
            endDate,
          });
          break;

        case "previousYear":
          setComparePeriod({
            dateType: period.dateType,
            periodType: "yearToDate",
          });
      }
    },
    [period, comparePeriod, onPeriodsChange, periodDateRange, periodLength],
  );

  const compareDateRange = React.useMemo(
    () =>
      comparePeriod && period
        ? getComparePeriodDateRange(comparePeriod, period)
        : [],
    [comparePeriod, period],
  );

  const onCompareStartDateChange = React.useCallback(
    (newStartDate: Date) => {
      if (!comparePeriod || !onPeriodsChange) {
        return;
      }
      setComparePeriod({
        ...comparePeriod,
        periodType: "custom",
        startDate: format(newStartDate, DATE_FORMAT_REVERSE),
      });
    },
    [comparePeriod, onPeriodsChange],
  );

  const onCompareEndDateChange = React.useCallback(
    (newEndDate: Date) => {
      if (!comparePeriod || !onPeriodsChange) {
        return;
      }
      setComparePeriod({
        ...comparePeriod,
        periodType: "custom",
        endDate: format(newEndDate, DATE_FORMAT_REVERSE),
      });
    },
    [comparePeriod, onPeriodsChange],
  );

  const submitPeriods = React.useCallback(() => {
    setAnchorPoint(undefined);
    if (!onPeriodsChange) {
      return;
    }
    onPeriodsChange({
      comparePeriod,
      period,
    });
  }, [comparePeriod, onPeriodsChange, period]);

  const onIconButtonClick = React.useCallback(() => {
    if (anchorPoint) {
      setAnchorPoint(undefined);
    }
    const button = document.querySelector(
      ".filter-bar__periods-control__button",
    );
    if (!button) {
      console.log("Could not detect anchor button");
      return;
    }
    const rect = button.getBoundingClientRect();
    setAnchorPoint({
      x: Math.round(rect.x + rect.width),
      y: Math.round(rect.y + rect.height),
    });
  }, [anchorPoint]);

  const isValid = React.useMemo(() => {
    if (
      periodCustomStartDate &&
      periodCustomEndDate &&
      isBefore(periodCustomEndDate, periodCustomStartDate)
    ) {
      return false;
    }

    if (
      comparePeriodCustomStartDate &&
      comparePeriodCustomEndDate &&
      isBefore(comparePeriodCustomEndDate, comparePeriodCustomStartDate)
    ) {
      return false;
    }

    if (
      comparePeriod &&
      comparePeriod.periodType === "custom" &&
      (!comparePeriod.startDate || !comparePeriod.endDate)
    ) {
      return false;
    }

    return period.periodType === "all" || periodDateRange.length > 0;
  }, [
    comparePeriod,
    comparePeriodCustomEndDate,
    comparePeriodCustomStartDate,
    period,
    periodCustomEndDate,
    periodCustomStartDate,
    periodDateRange,
  ]);

  const iconButtonRef = React.useRef(null);

  return (
    <IntlContext.Provider value={rsuiteLocale["DatePicker"]}>
      {anchorPoint ? (
        <AnchoredLightboxedItem
          className="rs-picker-menu placement-bottom-end rs-picker-daterange-menu filter-bar__periods-control"
          anchorPosition="topRight"
          anchorPoint={anchorPoint}
          onClose={() => {
            setAnchorPoint(undefined);
          }}
        >
          <div className="rs-picker-daterange-panel">
            <div className="rs-picker-daterange-content">
              <div className="rs-picker-daterange-header">
                {label}
                {period && setPeriod ? (
                  <SelectPicker
                    className="date-range__picker-header__date-type-picker"
                    data={openapi.components.schemas.Period.properties.dateType.enum.map(
                      (dateType) => ({
                        value: dateType,
                        label: t(dateType),
                      }),
                    )}
                    value={period.dateType}
                    onChange={(dateType) => {
                      setPeriod({
                        ...period,
                        dateType,
                      });
                    }}
                    searchable={false}
                    cleanable={false}
                    appearance="subtle"
                    size="sm"
                    style={{ width: 160 }}
                  />
                ) : null}
              </div>
              <div
                className={`rs-picker-daterange-calendar-${
                  showOneCalendar ? "single" : "group"
                }`}
              >
                <DateRangeDatePicker {...pickerProps} index={0} />
                {!showOneCalendar && (
                  <DateRangeDatePicker {...pickerProps} index={1} />
                )}
              </div>
            </div>
            <div className="d-flex mt-1">
              <div>
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: 250 }}
                  disabled={true}
                  value={periodDateRange[0]}
                />
              </div>
              <div>
                <DatePicker
                  format="DD-MM-YYYY"
                  style={{ width: 250, marginLeft: 10 }}
                  disabled={true}
                  value={periodDateRange[1]}
                />
              </div>
            </div>
            <Toolbar
              className="border-top-0 pt-1"
              ranges={ranges}
              onShortcut={onRangeClick}
              disabledShortcutButton={() => false}
              hideOkButton
            />
            <div className="d-flex mb-2">
              <div className="pe-2">
                <Checkbox
                  disabled={period?.periodType === "all"}
                  onChange={onCompareToCheckboxChange}
                  checked={!!comparePeriod}
                >
                  {t("compareTo")}
                </Checkbox>
              </div>
              <div className="flex-grow-1 pe-1">
                <SelectPicker
                  block
                  cleanable={false}
                  disabled={!comparePeriod}
                  searchable={false}
                  data={
                    comparePeriod
                      ? ["customPeriod", "previousPeriod", "previousYear"].map(
                          (value) => ({
                            value,
                            label: t(value),
                          }),
                        )
                      : []
                  }
                  onChange={onComparePeriodTypeChange}
                  value={comparePeriod ? comparePeriodType : undefined}
                />
              </div>
            </div>
            <div className="d-flex ms-0 mb-2">
              <div>
                <DatePicker
                  format="DD-MM-YYYY"
                  disabled={comparePeriodType !== "customPeriod"}
                  style={{ width: 250 }}
                  value={compareDateRange[0] || comparePeriodCustomStartDate}
                  onChangeCalendarDate={onCompareStartDateChange}
                  cleanable={false}
                  disabledDate={(date?: Date) =>
                    date
                      ? isAfter(
                          date,
                          compareDateRange[1] || comparePeriodCustomEndDate,
                        )
                      : false
                  }
                  oneTap
                  isoWeek
                />
              </div>
              <div>
                <DatePicker
                  format="DD-MM-YYYY"
                  disabled={comparePeriodType !== "customPeriod"}
                  style={{ width: 250, marginLeft: 10 }}
                  value={compareDateRange[1] || comparePeriodCustomEndDate}
                  onChangeCalendarDate={onCompareEndDateChange}
                  cleanable={false}
                  disabledDate={(date?: Date) =>
                    date
                      ? isBefore(
                          date,
                          compareDateRange[0] || comparePeriodCustomStartDate,
                        )
                      : false
                  }
                  oneTap
                  isoWeek
                />
              </div>
            </div>
            <Button
              appearance="primary"
              size="xs"
              className="d-block ms-auto"
              onClick={submitPeriods}
              disabled={!isValid}
            >
              OK
            </Button>
          </div>
        </AnchoredLightboxedItem>
      ) : null}
      <IconButton
        appearance="subtle"
        icon={icon}
        className="filter-bar__periods-control__button"
        onClick={onIconButtonClick}
        ref={iconButtonRef}
      >
        <div className="filter-bar__periods-control__labels">
          <div className="filter-bar__periods-control__label">{label}</div>
          {compareLabel ? (
            <div className="filter-bar__periods-control__compare-label">
              <Icon icon="level-up" style={{ rotate: "90deg" }} />
              <span>{compareLabel}</span>
            </div>
          ) : null}
        </div>
      </IconButton>
    </IntlContext.Provider>
  );
};
export default PeriodsControl;
