import { ControlLabel, Form, Panel } from "rsuite";
import AdvancedMatchPanel from "../../../../components/AdvancedMatchPanel";
import {
  advancedMatchesToString,
  TAdvancedMatches,
} from "../../../../inc/advancedMatch";
import React from "react";
import { components } from "../../../../types/openapi";
import { validationErrorsToSchemaFormErrors } from "../../../../inc/schema";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import SchemaFormBody, {
  ISchemaFormPropConfig,
  TSchemaFormErrors,
} from "../../../../components/SchemaFormBody";
import ajv from "../../../../inc/ajv";
import MatchTextControl from "./MatchTextControl";
import { I18nContext } from "../../../../provider/I18nProvider";

interface IBenchmarkMatchTextPanelProps {
  onChange: (value: components["schemas"]["BenchmarkMatchText"]) => void;
  value: components["schemas"]["BenchmarkMatchText"];
  onClose: (
    newBenchmarkMatchText?: components["schemas"]["BenchmarkMatchText"],
  ) => void;
}

const schema = openapi.components.schemas
  .BenchmarkMatchText as oas30.SchemaObject;
const validate = ajv.compile(schema);

const BenchmarkMatchTextPanel = ({
  onChange,
  onClose,
  value,
}: IBenchmarkMatchTextPanelProps) => {
  const { t } = React.useContext(I18nContext);

  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const [isAdvancedMatchPanelExpanded, setIsAdvancedMatchPanelExpanded] =
    React.useState(false);
  const [advancedMatches, setAdvancedMatches] =
    React.useState<TAdvancedMatches>({
      all: [],
      some: [],
      none: [],
    });

  const onToggleAdvancedMatchPanelExpandedClick = React.useCallback(() => {
    setIsAdvancedMatchPanelExpanded(
      (isAdvancedMatchPanelExpanded) => !isAdvancedMatchPanelExpanded,
    );
  }, []);

  const onSubmit = React.useCallback(() => {
    // No name? auto fill it with the matchText
    const finalValue = { ...value };
    if (!finalValue.name) {
      finalValue.name = finalValue.matchText;
    }
    validate(finalValue);
    if (validate.errors?.length) {
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      return;
    }
    setErrors({});
    onClose(finalValue);
  }, [value, onClose]);

  const config = React.useMemo<{
    [P in keyof Partial<
      components["schemas"]["BenchmarkMatchText"]
    >]: ISchemaFormPropConfig;
  }>(() => {
    return {
      name: {
        label: (
          <ControlLabel className="label" htmlFor={`name`}>
            {t("benchmarkName")}
          </ControlLabel>
        ),
        formControlProps: {
          autoFocus: true,
        },
      },
      matchText: {
        FormControl: MatchTextControl,
        formControlProps: {
          onSubmit,
          onToggleAdvancedMatchPanelExpandedClick,
        },
      },
    };
  }, [onSubmit, onToggleAdvancedMatchPanelExpandedClick, t]);

  return (
    <Panel className="benchmark-bar__panel">
      <Form onSubmit={onSubmit}>
        <SchemaFormBody<components["schemas"]["BenchmarkMatchText"]>
          errors={errors}
          schema={schema}
          value={value}
          onChange={onChange}
          config={config}
        />
      </Form>
      <AdvancedMatchPanel
        isExpanded={isAdvancedMatchPanelExpanded}
        advancedMatch={advancedMatches}
        onChange={(newAdvancedMatches) => {
          setAdvancedMatches(newAdvancedMatches);
          onChange({
            ...value,
            matchText: advancedMatchesToString(newAdvancedMatches),
          });
        }}
      />
    </Panel>
  );
};

export default React.memo(BenchmarkMatchTextPanel);
