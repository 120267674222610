import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { components } from "../types/openapi";
import { AuthContext } from "../provider/AuthProvider";

const useContentNotificationCustomerLinks = () => {
  const {
    newContentNotificationCustomerUserLinks,
    setNewContentNotificationCustomerUserLinks,
  } = React.useContext(ApiDataContext);
  const [hydratedGroupId, setHydratedGroupId] = React.useState<string>();
  const { auth } = React.useContext(AuthContext);

  const hydrate = React.useCallback(
    (groupId: string) => {
      if (!groupId || hydratedGroupId === groupId || !auth?.bearer) {
        return;
      }
      setHydratedGroupId(groupId);
      setNewContentNotificationCustomerUserLinks(null);
      axios
        .get<components["schemas"]["NewContentNotificationCustomerUserLink"][]>(
          `/newContentNotification/list/${groupId}`
        )
        .then((res) => {
          setNewContentNotificationCustomerUserLinks(res.data);
        });
    },
    [auth?.bearer, hydratedGroupId, setNewContentNotificationCustomerUserLinks]
  );

  return { newContentNotificationCustomerUserLinks, hydrate };
};

export default useContentNotificationCustomerLinks;
