import { ApexOptions } from "apexcharts";
import { ESplitType, EValueType } from "../../components/dashboardWidgets";
import { compact, currency, formatInt } from "../numbers";
import { EChartType } from "../enums";
import { round, sum } from "lodash";

import "./chart.scss";
import { EBenchmarkSplitType } from "../../components/benchmarkWidgets";

// The breakpoint that the legend (of the donut chart) moves to the right
export const CHART_MEDIUM_BREAKPOINT = 450;
export const LINE_CHART_SPACING = 10;
const MAX_YAXIS_LABEL_LENGTH = 21;

function getPlotOptions(
  chartType: EChartType,
  labels: string[] = [],
  chartHeight: number,
  chartWidth: number,
): ApexOptions["plotOptions"] {
  const backgroundBarColors = [];
  for (let i = 0; i < labels.length; i++) {
    backgroundBarColors.push("#f2f2f2");
  }

  switch (chartType) {
    case EChartType.donut:
      const plotOptions: ApexOptions["plotOptions"] = {
        pie: {
          expandOnClick: false,
          customScale: 1,
          offsetY: 0,
          donut: {
            labels: {
              show: false,
              total: {},
            },
          },
        },
      };
      if (chartWidth < CHART_MEDIUM_BREAKPOINT && chartHeight < 400) {
        plotOptions.pie = {
          ...plotOptions.pie,
          customScale: 1.2,
          offsetY: chartHeight * 0.03,
        };
      }
      return plotOptions;
    case EChartType.bar:
    case EChartType.column:
      return {
        bar: {
          borderRadius: 4,
          horizontal: chartType === EChartType.bar,
          colors: {
            backgroundBarOpacity: 0.5,
            backgroundBarColors,
            backgroundBarRadius: 4,
          },
        },
      };

    default:
      return {};
  }
}

// Tot 100, afronden op 10-tal
// Tot 1000, aronden op 100-tal
// etc.
// Max moet deelbaar zijn door 5
// https://www.notion.so/45a62881cb8b42f2aade85da20e0e41e?v=67f4d002280e49fe85e6a80583b5954a&p=afae295dea8f4563b61bdb61157b115a
export function calculateChartMaxTick(value: number) {
  let roundingNumber = getRoundingNumber(value);

  if (value / (roundingNumber * 10) <= 5) {
    roundingNumber *= 5;
  } else {
    roundingNumber *= 10;
  }
  // Return the next X number greater than greatestValue
  return Math.ceil(value / roundingNumber) * roundingNumber;
}

// 1 t/m 10 = value
// 11 t/m 50 = steps of 5
// 51 t/m 100 = steps of 10
// 101 t/m 500 = steps of 50
// 501 t/m 1000 = steps of 100
// etc.
//
// For a value of 1001 the number 3 is returned (1001 equals 3 steps of 500)
export function calculateChartTickAmount(
  value: number,
  chartWidth: number = 2000,
): number {
  if (chartWidth && chartWidth < 320) {
    return 1;
  }
  const maxTickAmount = Math.floor(chartWidth / 58);

  if (value <= 10) {
    return Math.min(value, maxTickAmount);
  }

  let roundingNumber = getRoundingNumber(value);
  const divider = roundingNumber * 10;

  // If value is a flat number starting with 1 (i.e. 100, 1000, 10000, 100000, etc.)
  if (value / divider === 1) {
    return Math.min(Math.ceil(value / roundingNumber), maxTickAmount);
  }

  // If value starts with a 5 or below, multiply by 5
  // Else, multiply by 10
  if (value / divider <= 5) {
    roundingNumber *= 5;
  } else {
    roundingNumber *= 10;
  }

  return Math.min(Math.ceil(value / roundingNumber), maxTickAmount);
}

function getRoundingNumber(value: number): number {
  // Get the amount of zero's for the rounding number
  const amountOfZeros = `${value.toFixed()}`.length - 2;
  return amountOfZeros > 0 ? Math.pow(10, amountOfZeros) : 1;
}

export const formatValue = (valueType: EValueType, val: any) => {
  if (val === undefined) {
    return "";
  }
  let labelString: string | null = null;
  switch (typeof val) {
    case "string":
      labelString = val;
      break;

    case "object":
      labelString = val.join("");
      break;
  }

  if (labelString) {
    // Automatic yaxis labels trimming results in extra empty space left of chart
    // https://www.notion.so/c700a5b224564b5395ec6c30144d735a?v=9e3ed235c0364527b4e528a081055c3f&p=1d58ce817e1d499f99404debdec78b5e
    return labelString.length > MAX_YAXIS_LABEL_LENGTH
      ? `${labelString.substr(0, MAX_YAXIS_LABEL_LENGTH)}...`
      : labelString;
  }

  return ["ave", "summedAve", "prValue", "summedPrValue"].indexOf(valueType) >=
    0
    ? currency(val as number, 0)
    : formatInt(val as number);
};

export function getDonutLegendConfig({
  chartHeight,
  chartWidth,
  valueType,
}: {
  chartHeight: number;
  chartWidth: number;
  valueType: EValueType;
}): ApexLegend {
  const formatter = (legendName: string, { seriesIndex, w }: any) => {
    const value = w.config.series[seriesIndex];
    const total = sum(w.config.series);
    return `${legendName}<span class="legend-value-description">: ${formatValue(
      valueType,
      value,
    )} (${round((value / total) * 100, 1)}%)</span>`;
  };

  if (chartWidth < CHART_MEDIUM_BREAKPOINT) {
    return {
      formatter,
      position: "bottom",
      width: undefined,
      height: chartHeight / 4,
      offsetX: 0,
    };
  }

  // Legend right of chart
  const legendWidth = Math.min(300, Math.max(chartWidth * 0.275, 80));
  return {
    formatter,
    position: "right",
    width: legendWidth,
    height: undefined,
    offsetX:
      Math.max(chartWidth - chartHeight, legendWidth) * 0.45 -
      legendWidth / 2 -
      20,
    offsetY: -20,
  };
}

export function getDefaultOptions(
  chartType: EChartType,
  valueType: EValueType,
  splitType: ESplitType | EBenchmarkSplitType,
  chartWidth: number,
  chartHeight: number,
  maxValue: number,
  compactLabels: boolean,
  labels: string[],
): ApexOptions {
  const barColumnTooltip = {
    custom: ({ dataPointIndex, series, seriesIndex, splitType }: any) => {
      const value = series[seriesIndex][dataPointIndex];
      return `<div class="arrow_box arrow_box--left"><span>${formatValue(
        valueType,
        value,
      )}${
        splitType !== ESplitType.none
          ? ` (${round((value / sum(series[0])) * 100, 1)}%)`
          : ""
      }</span></div>`;
    },
  };

  const formatter = (val?: any) => {
    return chartType === EChartType.donut
      ? `${round(val as number, 1)}%`
      : formatValue(valueType, val);
  };

  const colors = [
    "#2d50b4",
    "#3cbe8c",
    "#e68c91",
    "#646973",
    "#96afaf",
    "#bec8d2",
    "#288c8c",
    "#a0d2aa",
    "#6e64a0",
    "#e1dca5",
    "#bf946f",
  ];
  const options: ApexOptions = {
    chart: {
      stacked: [EChartType.bar, EChartType.column].includes(chartType),
      fontFamily: "Gelion, sans-serif",
      toolbar: {
        show: false,
      },
      zoom: { enabled: false },
    },
    colors,
    dataLabels: {
      enabled: chartType === EChartType.donut,
      formatter,
      dropShadow: {
        enabled: false,
      },
      style: {
        fontWeight: "normal",
      },
    },
    markers: {
      size: 1,
      strokeColors: colors,
    },
    plotOptions: getPlotOptions(chartType, labels, chartHeight, chartWidth),
    yaxis: {
      labels: {
        formatter,
        style: {
          cssClass: "apexcharts-label--yaxis",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        trim: false,
        rotate: 0,
        hideOverlappingLabels: false,
        style: {
          cssClass: "apexcharts-label--xaxis",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    states: {
      active: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: [chartType === EChartType.line ? "#f2f2f2" : "#fff", "#fff"],
      },
    },
    labels,
    legend: {
      showForSingleSeries: splitType !== ESplitType.none,
      horizontalAlign: "left",
    },
  };

  const maxTick = calculateChartMaxTick(maxValue);
  switch (chartType) {
    case EChartType.donut:
      options.tooltip = {
        custom: (options) => {
          const { series, seriesIndex } = options;
          const total = series.reduce(
            (carry: number, value: number) => carry + value,
          );
          return `<div class="arrow_box arrow_box--bottom"><span>${
            labels[seriesIndex]
          }: ${formatValue(valueType, series[seriesIndex])} (${round(
            (series[seriesIndex] / total) * 100,
            1,
          )}%)</span></div>`;
        },
      };
      break;

    case EChartType.line:
      options.chart = {
        ...options.chart,
        dropShadow: {
          enabled: true,
          top: 12,
          left: 0,
          blur: 10,
          opacity: 0.16,
        },
      };
      options.xaxis = {
        ...options.xaxis,
        crosshairs: {
          opacity: 1,
          position: "back",
          stroke: {
            color: "#2d50b4",
            width: 2,
            // dashArray: 0,
          },
          fill: {
            type: "solid",
            color: "#2d50b4",
          },
        },
      };
      options.yaxis = {
        ...options.yaxis,
        min: 0,
        max: maxTick,
        tickAmount: calculateChartTickAmount(maxTick),
        labels: {
          // @ts-ignore
          ...options.yaxis.labels,
          offsetX: -LINE_CHART_SPACING,
        },
      };
      break;

    case EChartType.column:
      options.yaxis = {
        ...options.yaxis,
        min: 0,
        max: maxTick,
        tickAmount: calculateChartTickAmount(maxTick),
      };
      options.tooltip = barColumnTooltip;
      break;

    case EChartType.bar:
      options.yaxis = {
        ...options.yaxis,
        labels: {
          ...(options.yaxis as ApexYAxis).labels,
          align: "left",
          offsetX: -10,
        },
      };
      options.xaxis = {
        ...options.xaxis,
        min: 0,
        max: maxTick,
        tickAmount: calculateChartTickAmount(maxTick, chartWidth),
        labels: {
          ...options.xaxis?.labels,
          formatter(value: string): string {
            const parsedValue = parseInt(value, 10);
            if (!isFinite(parsedValue)) {
              return value;
            }
            return compactLabels
              ? compact(parsedValue, 1)
              : formatValue(valueType, parsedValue);
          },
        },
      };
      options.tooltip = barColumnTooltip;
  }
  if (!chartWidth) {
    return options;
  }

  switch (chartType) {
    case EChartType.donut:
      options.legend = getDonutLegendConfig({
        chartHeight,
        chartWidth,
        valueType,
      });
      break;

    case EChartType.line:
    case EChartType.bar:
      if (chartHeight > CHART_MEDIUM_BREAKPOINT && labels.length) {
        const categories = labels.map((axisLabel) =>
          axisLabel.match(/.{1,20}/g),
        );
        options.xaxis = {
          ...options.xaxis,
          categories,
        };
        options.yaxis = {
          ...options.yaxis,
          // @ts-ignore
          categories,
        };
        delete options.xaxis.labels;
        // @ts-ignore
        delete options.yaxis.labels;
      }
      break;
  }
  return options;
}
