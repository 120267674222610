import * as React from "react";
import { SVGProps } from "react";

const WidgetTypeCategory = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={45.011}
      height={37.409}
      viewBox="0 0 45.011 37.409"
      {...props}
    >
      <defs>
        <style>
          {".prefix__cls-1{fill:#96afaf}.prefix__cls-2{fill:#2d50b4}"}
        </style>
      </defs>
      <g id="prefix__Group_1432" transform="translate(-1777.828 -914.462)">
        <g id="prefix__Group_1433">
          <path
            id="prefix__Path_1700"
            d="M1817.655 951.871h-34.644a3.813 3.813 0 01-3.809-3.741l-1.369-15.67c0-.04-.005-.08-.005-.12a3.812 3.812 0 013.809-3.809h37.393a3.812 3.812 0 013.809 3.809c0 .04 0 .08-.005.12l-1.37 15.67a3.813 3.813 0 01-3.809 3.741zm-37.055-19.579l1.368 15.65c0 .04.005.08.005.121a1.055 1.055 0 001.041 1.041h34.644a1.042 1.042 0 001.041-1.041c0-.041 0-.081.005-.121l1.369-15.65a1.042 1.042 0 00-1.04-.993h-37.393a1.042 1.042 0 00-1.04.993zm40.858.048z"
            className="prefix__cls-1"
          />
          <g id="prefix__Group_1431">
            <path
              id="prefix__Path_1701"
              d="M1782.361 927.717a1.387 1.387 0 01-1.248-1.981l5-10.486a1.385 1.385 0 011.249-.788h12.812a1.384 1.384 0 110 2.767h-11.939l-4.627 9.7a1.383 1.383 0 01-1.247.788z"
              className="prefix__cls-1"
            />
            <path
              id="prefix__Path_1702"
              d="M1818.306 927.717a1.383 1.383 0 01-1.25-.789l-4.627-9.7h-11.939a1.384 1.384 0 110-2.767h12.81a1.385 1.385 0 011.249.788l5 10.486a1.387 1.387 0 01-1.248 1.981z"
              className="prefix__cls-1"
            />
            <path
              id="prefix__Path_1703"
              d="M1800.177 917.229h-12.812a1.384 1.384 0 110-2.767h12.812a1.384 1.384 0 110 2.767z"
              className="prefix__cls-1"
            />
          </g>
          <path
            id="prefix__Path_1704"
            d="M1811.614 922.037h-22.561a1.383 1.383 0 110-2.767h22.561a1.383 1.383 0 110 2.767z"
            className="prefix__cls-2"
          />
          <path
            id="prefix__Path_1705"
            d="M1812.988 926.51h-25.309a1.384 1.384 0 010-2.768h25.309a1.384 1.384 0 010 2.768z"
            className="prefix__cls-2"
          />
          <path
            id="prefix__Path_1706"
            d="M1806.485 943.453h-12.3a1.383 1.383 0 01-1.38-1.276l-.344-4.427a1.383 1.383 0 011.38-1.491h12.991a1.383 1.383 0 011.38 1.491l-.344 4.427a1.383 1.383 0 01-1.383 1.276zm-11.023-2.767h9.743l.128-1.66h-10z"
            className="prefix__cls-1"
          />
        </g>
      </g>
    </svg>
  );
};

export default WidgetTypeCategory;
