import React from "react";
import { Loader } from "rsuite";
import "./index.scss";
import { I18nContext } from "../../../../provider/I18nProvider";
import { components } from "../../../../types/openapi";

const componentMap: { [key: string]: any } = {
  Title: (props: any) => <h2>{props.children}</h2>,
  Subhead: (props: any) => <h3>{props.children}</h3>,
  Paragraph: (props: any) => <p>{props.children}</p>,
  Author: (props: any) => <p className="author">{props.children}</p>,
  Cutline: (props: any) => <p className="cutline">{props.children}</p>,
};

interface ITextTabProps {
  isLexisNexisContentVisible: boolean;
  lexisNexisView?: components["schemas"]["LexisNexisView"];
  mediaItem?: components["schemas"]["MediaItem"];
  mediaItemView?: components["schemas"]["MediaItemView"];
}

const TextTab = ({
  isLexisNexisContentVisible,
  lexisNexisView,
  mediaItemView,
  mediaItem,
}: ITextTabProps) => {
  const { t } = React.useContext(I18nContext);

  const renderedTextFragments = React.useMemo(() => {
    if (!mediaItemView) {
      return null;
    }
    const headTextFragments: JSX.Element[] = [];
    const bodyTextFragments: JSX.Element[] = [];

    if (mediaItemView.textFragments) {
      mediaItemView.textFragments.forEach((textFragment, key) => {
        const TextFragmentComponent = componentMap[textFragment.format];

        const textFragmentElement: JSX.Element = (
          <TextFragmentComponent key={key}>
            {textFragment.text}
          </TextFragmentComponent>
        );
        if (bodyTextFragments.length || textFragment.format === "Paragraph") {
          bodyTextFragments.push(textFragmentElement);
          return;
        }
        headTextFragments.push(textFragmentElement);
      });
    }

    return (
      <div className="media-item__text-media-textFragments">
        <div className="components__media-item__text-tab__head">
          {headTextFragments}
        </div>
        {mediaItem?.authors ? (
          <div className="components__media-item__text-tab__author">
            {mediaItem.authors.join(", ")}
          </div>
        ) : null}
        <div className="components__media-item__text-tab__body">
          {bodyTextFragments}
        </div>
      </div>
    );
  }, [mediaItem?.authors, mediaItemView]);

  if (lexisNexisView?.text && isLexisNexisContentVisible) {
    return (
      <div className="mb-3 mb-mb-4 mb-lg-5">
        <div
          className="components__media-item__text-tab__body"
          dangerouslySetInnerHTML={{ __html: lexisNexisView.text }}
        />
      </div>
    );
  }
  return (
    <div className="mb-3 mb-mb-4 mb-lg-5">
      {!!renderedTextFragments ? (
        <>
          <div>
            <div>{renderedTextFragments}</div>
          </div>
          {mediaItem?.isLexisNexis ? (
            <a
              href="http://www.lexisnexis.com/terms/general"
              title={t("poweredByLexisnexis")}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "block", marginTop: 16 }}
            >
              <img
                className="mb-3"
                src="/poweredByLexisNexis-gray.png"
                alt={t("poweredByLexisnexis")}
                style={{ height: 26, border: 0 }}
              />
            </a>
          ) : null}
        </>
      ) : (
        <Loader key="loader" className="m-2" />
      )}
    </div>
  );
};

export default TextTab;
