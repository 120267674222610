import React, { SVGProps } from "react";

const MediaTypeTv = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="prefix__TV"
      xmlns="http://www.w3.org/2000/svg"
      width={16.658}
      height={13.63}
      viewBox="0 0 16.658 13.63"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Group_1635" data-name="Group 1635">
        <g id="prefix__Group_284" data-name="Group 284">
          <path
            id="prefix__Path_1963"
            data-name="Path 1963"
            d="M1826.665 1159.159h-10.521a3.073 3.073 0 01-3.069-3.069v-5.06a3.072 3.072 0 013.069-3.068h10.521a3.072 3.072 0 013.068 3.068v5.06a3.072 3.072 0 01-3.068 3.069zm-10.521-10.127a2 2 0 00-2 2v5.06a2 2 0 002 2h10.521a2 2 0 002-2v-5.06a2 2 0 00-2-2z"
            transform="translate(-1813.075 -1147.962)"
          />
        </g>
      </g>
      <g
        id="prefix__Group_1636"
        data-name="Group 1636"
        transform="translate(4.023 10.126)"
      >
        <path
          id="prefix__Path_1966"
          data-name="Path 1966"
          d="M1823.624 1176.664a.535.535 0 01-.107-1.06l3.342-.684v-1.22a.535.535 0 011.071 0v1.662a.535.535 0 01-.428.525l-3.769.772a.571.571 0 01-.109.005z"
          transform="translate(-1823.089 -1173.16)"
        />
        <path
          id="prefix__Path_1967"
          data-name="Path 1967"
          d="M1836.774 1176.664a.571.571 0 01-.108-.011l-3.769-.772a.535.535 0 01-.428-.525v-1.656a.535.535 0 011.071 0v1.225l3.341.684a.535.535 0 01-.106 1.06z"
          transform="translate(-1828.699 -1173.16)"
        />
      </g>
    </svg>
  );
};

export default MediaTypeTv;
