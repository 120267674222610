import React, { SVGProps } from "react";

const ChartHorizontalBars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Component_84_1"
    width={24}
    height={25}
    viewBox="0 0 24 25"
    {...props}
  >
    <defs>
      <style>{".prefix__cls-1{fill:#9baeaf}"}</style>
    </defs>
    <path
      id="prefix__Rectangle_1596"
      d="M0 0h7v16H0z"
      className="prefix__cls-1"
      transform="rotate(90 8 8)"
    />
    <path
      id="prefix__Rectangle_1598"
      d="M0 0h7v10H0z"
      className="prefix__cls-1"
      transform="rotate(90 .5 9.5)"
    />
    <path
      id="prefix__Rectangle_1600"
      d="M0 0h7v24H0z"
      className="prefix__cls-1"
      transform="rotate(90 3 21)"
    />
  </svg>
);

export default ChartHorizontalBars;
