import React from "react";
import { ELocaleOptions } from "../../inc/i18n";
import { Button, ButtonGroup } from "rsuite";
import { I18nContext } from "../../provider/I18nProvider";

const LanguageToggle = () => {
  const { activeLanguage, switchActiveLanguage, t } =
    React.useContext(I18nContext);
  const onLanguageSwitch = React.useCallback(
    (newLocaleKey: ELocaleOptions) => {
      if (
        !(newLocaleKey in ELocaleOptions) ||
        activeLanguage === newLocaleKey
      ) {
        return;
      }
      switchActiveLanguage(ELocaleOptions[newLocaleKey]);
    },
    [activeLanguage, switchActiveLanguage]
  );

  return (
    <ButtonGroup>
      {Object.keys(ELocaleOptions).map((localeKey, index) => (
        <Button
          key={index}
          active={activeLanguage === localeKey}
          color="blue"
          appearance="subtle"
          size="sm"
          onClick={() => onLanguageSwitch(localeKey as ELocaleOptions)}
        >
          {t(`language_${localeKey}`)}
        </Button>
      ))}
    </ButtonGroup>
  );
};
export default LanguageToggle;
