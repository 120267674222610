import { formatInt } from "../../../inc/numbers";
import { Progress } from "rsuite";
import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";

interface IProgressIndicatorProps {
  resultCount?: number;
  startDate: Date;
}

const SPEED_RESULTS_PER_SECOND = 1200;

const ProgressIndicator = ({
  resultCount,
  startDate,
}: IProgressIndicatorProps) => {
  const { t } = React.useContext(I18nContext);
  const [duration, setDuration] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDuration((duration) => duration + 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [startDate]);

  // 5000 per second
  const progress = resultCount
    ? Math.round(
        Math.min(
          99,
          ((duration * SPEED_RESULTS_PER_SECOND) / resultCount) * 100,
        ),
      )
    : 0;
  const status = progress === 100 ? "success" : undefined;
  const color = progress === 100 ? "#52c41a" : "#3385ff";

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <h3 className="mb-5">
        {resultCount
          ? t("loadingResults", formatInt(resultCount))
          : t("preparing")}
      </h3>
      <div style={{ width: 300, height: "100%", marginTop: 10 }}>
        <Progress.Circle
          percent={progress}
          strokeColor={color}
          status={status}
        />
      </div>
    </div>
  );
};

export default React.memo(ProgressIndicator);
