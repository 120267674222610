import React from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  ControlLabel,
  DateRangePicker,
  Form,
  FormGroup,
  HelpBlock,
  Icon,
  Loader,
  Modal,
  Tooltip,
} from "rsuite";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../../inc/ajv";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import { components } from "../../../types/openapi";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import CronEditor from "../../EditorialDetailView/CronEditor";
import axios from "../../../inc/axios";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import FilterEditButton from "../../../components/form/FilterEditButton";
import { DATE_FORMAT_REVERSE, localeFormat } from "../../../inc/date";
import { I18nContext } from "../../../provider/I18nProvider";
import useNewContentNotifications from "../../../hooks/useNewContentNotifications";
import useNewsAlerts from "../../../hooks/useNewsAlerts";
import MwWhisper from "../../../components/MwWhisper";

export type TAlertType = "instant" | "periodic";

interface IAlertModalProps {
  alertId?: string;
  alertType?: TAlertType;
  show: boolean;
  onClose: () => void;
}

const newContentNotificationSchema = openapi.components.schemas
  .NewContentNotification as oas30.SchemaObject;
const newsAlertSchema = openapi.components.schemas
  .NewsAlert as oas30.SchemaObject;
const newContentNotificationValidate = ajv.compile(
  newContentNotificationSchema,
);
const newsAlertValidate = ajv.compile(newsAlertSchema);

const { allowedMaxDays, beforeToday, combine } = DateRangePicker;

type TNewsAlert = components["schemas"]["NewsAlert"];
type TNewContentNotification = components["schemas"]["NewContentNotification"];
type TAlert = TNewsAlert | TNewContentNotification;

const AlertModal = (props: IAlertModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { setNewContentNotifications, setNewsAlerts } =
    React.useContext(ApiDataContext);
  const newContentNotifications = useNewContentNotifications();
  const newsAlerts = useNewsAlerts();
  const { alertId, show, onClose } = props;
  const [alertType, setAlertType] = React.useState<TAlertType>("periodic");
  const [alert, setAlert] = React.useState<TAlert>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors<TNewsAlert>>({});

  React.useEffect(() => {
    if (!newContentNotifications || !newsAlerts || !alertId) {
      return;
    }

    if (!!alert && props.alertType === "instant") {
      const newsAlert = alert as TNewsAlert;
      if (
        (newsAlert.newsAlertId === undefined && alertId === "NEW") ||
        newsAlert.newsAlertId === alertId
      ) {
        return;
      }
    }

    if (!!alert && props.alertType === "periodic") {
      const newContentNotification = alert as TNewContentNotification;
      // Alert is either new new OR already the current
      if (
        (newContentNotification.newContentNotificationId === undefined &&
          (alert as TNewsAlert).newsAlertId === undefined &&
          alertId === "NEW") ||
        newContentNotification.newContentNotificationId === alertId
      ) {
        return;
      }
    }

    let newAlert: TAlert | undefined;
    if (alertId === "NEW") {
      newAlert = getEmptyObject(newContentNotificationSchema);
    }

    if (!newAlert && props.alertType === "instant" && alertId in newsAlerts) {
      newAlert = newsAlerts[alertId];
    }

    if (
      !newAlert &&
      props.alertType === "periodic" &&
      alertId in newContentNotifications
    ) {
      newAlert = newContentNotifications[alertId];
    }

    setAlert(
      newAlert ? newAlert : getEmptyObject(newContentNotificationSchema),
    );
    setAlertType(props.alertType || "periodic");
  }, [alert, alertId, newContentNotifications, newsAlerts, props.alertType]);

  React.useEffect(() => {
    if (alertType === "periodic" || !alert) {
      return;
    }
    if (!("startDate" in alert)) {
      setAlert(
        (currentAlert) =>
          ({
            ...getEmptyObject(newsAlertSchema),
            ...currentAlert,
          }) as TAlert,
      );
    }
  }, [alert, alertType]);

  const onInstantAlertForm = alertType === "instant";

  const isInstantAlertValid = React.useCallback((): boolean => {
    const isValid = newsAlertValidate(alert);
    let isAlertValid = true;
    if (
      // If alert.filterId is the same as the filterId of any other alert
      // AND the alert.id isn't the same as the id of that other alert
      Object.values(newsAlerts || {}).find(
        (newsAlert) =>
          JSON.stringify(newsAlert.filter) === JSON.stringify(alert?.filter) &&
          ((alert as TNewsAlert).newsAlertId === undefined ||
            newsAlert.newsAlertId !== (alert as TNewsAlert).newsAlertId),
      )
    ) {
      Alert.error(t("newAlertWithThisFilterAlreadyExists"));
      isAlertValid = false;
    }
    if (
      !isValid &&
      newsAlertValidate.errors &&
      newsAlertValidate.errors.length
    ) {
      console.log(newsAlertValidate.errors, { alert });
      setErrors(validationErrorsToSchemaFormErrors(newsAlertValidate.errors));
      setTimeout(focusError, 200);
      isAlertValid = false;
    }
    return isAlertValid;
  }, [alert, newsAlerts, t]);

  const isPeriodicAlertValid = React.useCallback((): boolean => {
    const isValid = newContentNotificationValidate(alert);
    let isAlertValid = true;
    if (
      // If alert.filterId is the same as the filterId of any other newContentNotification
      // AND the alert.id isn't the same as the id of that other newContentNotification
      Object.values(newContentNotifications || {}).find(
        (newContentNotification) =>
          JSON.stringify(newContentNotification.filter) ===
            JSON.stringify(alert?.filter) &&
          ((alert as TNewContentNotification).newContentNotificationId ===
            undefined ||
            newContentNotification.newContentNotificationId !==
              (alert as TNewContentNotification).newContentNotificationId),
      )
    ) {
      Alert.error(t("newAlertWithThisFilterAlreadyExists"));
      isAlertValid = false;
    }
    if (
      !isValid &&
      newContentNotificationValidate.errors &&
      newContentNotificationValidate.errors.length
    ) {
      console.log(newContentNotificationValidate.errors);
      setErrors(
        validationErrorsToSchemaFormErrors(
          newContentNotificationValidate.errors,
        ),
      );
      setTimeout(focusError, 200);
      isAlertValid = false;
    }
    return isAlertValid;
  }, [alert, newContentNotifications, t]);

  const deleteNewsAlert = React.useCallback(
    (alertId: string) => {
      axios.delete(`/newsAlert/crud/${alertId}`).then(() => {
        setNewsAlerts((currentNewsAlerts) => {
          if (!currentNewsAlerts) {
            return currentNewsAlerts;
          }
          const newNewsAlerts = { ...currentNewsAlerts };
          delete newNewsAlerts[alertId];
          return newNewsAlerts;
        });
      });
    },
    [setNewsAlerts],
  );

  const deleteNewContentNotification = React.useCallback(
    (alertId: string) => {
      axios.delete(`/newContentNotification/crud/${alertId}`).then(() => {
        setNewContentNotifications((currentNewContentNotifications) => {
          if (!currentNewContentNotifications) {
            return currentNewContentNotifications;
          }
          const newNewContentNotifications = {
            ...currentNewContentNotifications,
          };
          delete newNewContentNotifications[alertId];
          return newNewContentNotifications;
        });
      });
    },
    [setNewContentNotifications],
  );

  const isNew = React.useMemo(() => {
    if (!alert) {
      return false;
    }
    return onInstantAlertForm
      ? !(alert as TNewsAlert).newsAlertId
      : !(alert as TNewContentNotification).newContentNotificationId;
  }, [alert, onInstantAlertForm]);

  const onSubmit = React.useCallback(() => {
    setErrors({});
    if (onInstantAlertForm ? !isInstantAlertValid() : !isPeriodicAlertValid()) {
      return;
    }
    // const data = onInstantAlertForm
    //   ? ({
    //     ...alert
    //       filter: alert?.filter,
    //       startDate: (alert as TNewsAlert).startDate,
    //       endDate: (alert as TNewsAlert).endDate,
    //       hideImages: (alert as TNewsAlert).hideImages,
    //     } as TNewsAlert)
    //   : ({
    //
    //       filter: alert?.filter,
    //       cron: (alert as TNewContentNotification).cron,
    //       hideImages: (alert as TNewContentNotification).hideImages,
    //     } as TNewContentNotification);
    axios
      .request({
        url: onInstantAlertForm
          ? `/newsAlert/crud${
              isNew ? "" : `/${(alert as TNewsAlert).newsAlertId}`
            }`
          : `/newContentNotification/crud${
              isNew
                ? ""
                : `/${
                    (alert as TNewContentNotification).newContentNotificationId
                  }`
            }`,
        method: isNew ? "post" : "put",
        data: alert,
      })
      .then((res) => {
        if (isNew && alertId && alertId !== "NEW") {
          // Replace the old alert with a new one (of a different type)
          if (onInstantAlertForm) {
            deleteNewContentNotification(alertId);
          } else {
            deleteNewsAlert(alertId);
          }
        }

        if (onInstantAlertForm) {
          const newAlert = res.data as TNewsAlert;
          setNewsAlerts((currentNewsAlerts) => ({
            ...currentNewsAlerts,
            [newAlert.newsAlertId as string]: newAlert,
          }));
          onClose();
          setAlert(undefined);
          return;
        }
        const newAlert = res.data as TNewContentNotification;
        setNewContentNotifications((currentNewContentNotifications) => ({
          ...currentNewContentNotifications,
          [newAlert.newContentNotificationId as string]: newAlert,
        }));
        onClose();
        setAlert(undefined);
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [
    onInstantAlertForm,
    isInstantAlertValid,
    isPeriodicAlertValid,
    isNew,
    alert,
    alertId,
    setNewContentNotifications,
    t,
    onClose,
    deleteNewContentNotification,
    deleteNewsAlert,
    setNewsAlerts,
  ]);

  const modalTitleText = React.useMemo(() => {
    if (!alert) {
      return t("loading");
    }
    const isNew =
      (alert as TNewsAlert).newsAlertId === undefined &&
      (alert as TNewContentNotification).newContentNotificationId === undefined;
    return t(isNew ? "createAlert" : "editAlert");
  }, [alert, t]);

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>{modalTitleText}</Modal.Title>
          <div style={{ display: "flex" }}>
            <Button appearance="primary" type="submit">
              {t("save")}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: undefined }}>
          {alert ? (
            <div className="w-100">
              <SchemaFormBody
                schema={newsAlertSchema}
                value={alert}
                onChange={(newAlert) => {
                  setAlert({
                    ...newAlert,
                  });
                }}
                errors={errors}
                config={{
                  filter: {
                    intro: t("newsAlertOptionFilterDescription"),
                    formControlProps: {
                      style: { maxWidth: 250 },
                      accepter: FilterEditButton,
                    },
                  },
                  hideImages: {
                    label: (
                      <ControlLabel className="label" htmlFor={`hideImages`}>
                        {t("hideImages")}
                      </ControlLabel>
                    ),
                  },
                  startDate: {
                    hidden: true,
                  },
                  endDate: {
                    hidden: true,
                  },
                }}
              />
              <ButtonGroup justified>
                {["periodic", "instant"].map((type, index) => (
                  <Button
                    key={index}
                    size="sm"
                    appearance={alertType === type ? "primary" : "subtle"}
                    onClick={() => setAlertType(type as TAlertType)}
                  >
                    {t(type)}
                    <MwWhisper
                      trigger="hover"
                      placement="bottom"
                      speaker={
                        <Tooltip>
                          {t(
                            type === "instant"
                              ? "instantDescription"
                              : "periodicDescription",
                          )}
                        </Tooltip>
                      }
                    >
                      <Icon icon="info-circle" className="ms-1 fill-color" />
                    </MwWhisper>
                  </Button>
                ))}
              </ButtonGroup>
              {onInstantAlertForm ? (
                <FormGroup>
                  <ControlLabel>{t("period")}</ControlLabel>
                  <DateRangePicker
                    preventOverflow
                    showWeekNumbers
                    disabledDate={combine(allowedMaxDays(90), beforeToday())}
                    value={
                      "startDate" in alert &&
                      "endDate" in alert &&
                      alert.startDate &&
                      alert.endDate
                        ? [new Date(alert.startDate), new Date(alert.endDate)]
                        : []
                    }
                    onChange={(value) => {
                      setAlert({
                        ...alert,
                        startDate: localeFormat(
                          value[0] as Date,
                          DATE_FORMAT_REVERSE,
                        ),
                        endDate: localeFormat(
                          value[1] as Date,
                          DATE_FORMAT_REVERSE,
                        ),
                      });
                    }}
                    cleanable={false}
                    ranges={[]}
                  />
                  {errors && (errors["startDate"] || errors["endDate"]) ? (
                    <HelpBlock style={{ color: "red" }}>
                      {t("newsAlertPeriodError")}
                    </HelpBlock>
                  ) : null}
                </FormGroup>
              ) : (
                <SchemaFormBody
                  schema={newContentNotificationSchema}
                  value={alert}
                  onChange={setAlert}
                  errors={errors}
                  config={{
                    filter: {
                      hidden: true,
                    },
                    hideImages: {
                      hidden: true,
                    },
                    cron: {
                      label: null,
                      formControlProps: {
                        accepter: CronEditor,
                      },
                    },
                  }}
                />
              )}
              {isNew || !alertId ? null : (
                <Button
                  style={{ marginTop: 48 }}
                  color="red"
                  onClick={() => {
                    if (onInstantAlertForm) {
                      deleteNewsAlert(alertId);
                    } else {
                      deleteNewContentNotification(alertId);
                    }
                    onClose();
                  }}
                >
                  {t("delete")}
                </Button>
              )}
            </div>
          ) : (
            <Loader center />
          )}
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export default AlertModal;
