import { useLocation } from "react-router-dom";
import React from "react";
import { TBenchmarkHashData } from "../../../components/benchmarkWidgets";

const initialBenchmarkHashString = window.location.hash.substr(1);
let initialBenchmarkHashData: TBenchmarkHashData | null = null;
try {
  if (initialBenchmarkHashString) {
    initialBenchmarkHashData = JSON.parse(
      decodeURIComponent(initialBenchmarkHashString)
    );
  }
} catch (e) {}

export function useBenchmarkHashData(): TBenchmarkHashData | null {
  const location = useLocation();
  const [benchmarkHashString, setBenchmarkHashString] = React.useState<string>(
    initialBenchmarkHashString
  );
  const [benchmarkHashData, setBenchmarkHashData] =
    React.useState<TBenchmarkHashData | null>(initialBenchmarkHashData);
  React.useEffect(() => {
    try {
      setBenchmarkHashData(
        benchmarkHashString
          ? JSON.parse(decodeURIComponent(benchmarkHashString))
          : null
      );
    } catch (e) {
      console.log(e);
    }
  }, [benchmarkHashString]);

  const newBenchmarkHashString = location.hash.substr(1);
  if (newBenchmarkHashString !== benchmarkHashString) {
    setBenchmarkHashString(newBenchmarkHashString);
  }
  return benchmarkHashData;
}
