import React from "react";
import { Button, Loader, Message, Modal } from "rsuite";
import { components } from "../../../../../types/openapi";
import axios from "axios";
import SchemaTable from "../../../../../components/SchemaTable";
import openapi from "../../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { APP_PATH } from "../../../../../inc/constants";
import { I18nContext } from "../../../../../provider/I18nProvider";
import { AuthContext } from "../../../../../provider/AuthProvider";

interface IEditorialIssueStatsModalProps {
  show: boolean;
  editorialIssueId: string;
  onClose: () => void;
}

const MediaItemSchema = openapi.components.schemas.MediaItem as oas30.SchemaObject;

const { properties } = MediaItemSchema;
if (!properties) {
  throw new Error("Incomplete media item schema");
}

const EditorialIssueStatsModal = ({
  show,
  onClose,
  editorialIssueId,
}: IEditorialIssueStatsModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const [editorialIssueStats, setEditorialIssueStats] = React.useState<
    components["schemas"]["EditorialIssueStats"] | null
  >();
  const [mediaItems, setMediaItems] = React.useState<
    components["schemas"]["MediaItem"][] | null
  >();

  React.useEffect(() => {
    if (!show) {
      return;
    }

    if (
      editorialIssueStats === undefined ||
      (editorialIssueStats &&
        editorialIssueStats.editorialIssueId !== editorialIssueId)
    ) {
      setMediaItems(null);
      setEditorialIssueStats(null);

      axios
        .get<components["schemas"]["EditorialIssueStats"]>(
          `/editorialIssue/stats/${editorialIssueId}`
        )
        .then((statsResponse) => {
          const stats = statsResponse.data;
          setEditorialIssueStats(stats);
          axios
            .post<components["schemas"]["FilterCacheResponse"]>(
              "/filter/cache",
              { mediaItemIds: stats.mediaItemIds }
            )
            .then((cacheResponse) => {
              axios
                .post<components["schemas"]["MediaItem"][]>(
                  "/filter/results",
                  null,
                  {
                    params: {
                      filterCacheToken: cacheResponse.data.token,
                      limit: stats.mediaItemIds.length,
                      offset: 0,
                      sort: "mediaItemSortType_insertDateAsc",
                    },
                  }
                )
                .then((resultsResponse) => {
                  setMediaItems(resultsResponse.data);
                });
            });
        });
    }
  }, [editorialIssueId, editorialIssueStats, onClose, show]);

  const getClickCount = React.useCallback(
    (mediaItemId: string) => {
      if (!editorialIssueStats) {
        return 0;
      }
      const mediaItemIndex = editorialIssueStats.mediaItemIds.findIndex(
        (statsMediaItemId) => statsMediaItemId === mediaItemId
      );
      return editorialIssueStats.clickCount[mediaItemIndex];
    },
    [editorialIssueStats]
  );

  const missingItemCount = React.useMemo<number>(() => {
    if (
      editorialIssueStats?.mediaItemIds.length === undefined ||
      mediaItems?.length === undefined
    ) {
      return 0;
    }
    return editorialIssueStats?.mediaItemIds.length - mediaItems?.length;
  }, [editorialIssueStats?.mediaItemIds.length, mediaItems?.length]);

  if (!auth) {
    return <Loader />;
  }

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      <Modal.Header>
        <Modal.Title>{t("editorialIssueStats")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: undefined }}>
        <div className="w-100">
          {missingItemCount ? (
            <Message
              className="mb-4"
              type="error"
              description={t(
                "xDeleteMediaItemsCanNotBeDisplayed",
                missingItemCount
              )}
            />
          ) : null}
          {editorialIssueStats && mediaItems ? (
            <SchemaTable
              schema={{}}
              sortable
              data={mediaItems}
              columnsConfigs={[
                { name: "title" },
                {
                  name: "clickCount" as any,
                  width: 200,
                  renderCell: (rowData) => {
                    return (
                      <span>{getClickCount(rowData.mediaItemId || "")}</span>
                    );
                  },
                  sort: (a, b, sortAsc) => {
                    const clickCountA = getClickCount(a.mediaItemId || "");
                    const clickCountB = getClickCount(b.mediaItemId || "");
                    return (clickCountA - clickCountB) * (sortAsc ? 1 : -1);
                  },
                },
                {
                  name: "action" as any,
                  title: "",
                  sortable: false,
                  flexGrow: 0,
                  renderCell: (rowData) => {
                    return (
                      <Button
                        size="sm"
                        appearance="subtle"
                        className="rs-btn-subtle--in-table"
                        href={`${APP_PATH}/mediaItem/${auth.jwt.currentCustomerLinkId}/${rowData.mediaItemId}`}
                        target="_blank"
                        block
                      >
                        {t("open")}
                      </Button>
                    );
                  },
                },
              ]}
            />
          ) : (
            <Loader center size="lg" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default EditorialIssueStatsModal;
