import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  InputGroup,
  Popover,
} from "rsuite";
import { sortSelectedOnTop } from "../../../../inc/sort";
import MagnifyingGlass from "../../../../icons/MagnifyingGlass";
import { dataContainsWordThatStartWith } from "../../../../inc/query";
import MwTag from "../../../MwTag";
import Category from "../../../../icons/Category";
import useCategories from "../../../../hooks/useCategories";
import MwWhisper from "../../../MwWhisper";

interface ICategoryPopoverProps {
  whisperRef: React.Ref<any>;
  value: string[];
  onChange?: (newValue?: string[]) => void;
}

const CategoryPopover = ({
  onChange,
  value,
  whisperRef,
}: ICategoryPopoverProps) => {
  const categories = useCategories();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [sortCounter, setSortCounter] = React.useState<number>(0);
  const sortedCategories = React.useMemo(() => {
    return sortSelectedOnTop(
      Object.values(categories || {})
        .filter((category) =>
          onChange ? true : value.indexOf(category.categoryId as string) >= 0
        )
        .sort((a, b) => {
          const nameA = a.name || "";
          const nameB = b.name || "";
          if (nameA === nameB) {
            return 0;
          }
          return nameA > nameB ? 1 : -1;
        }),
      value,
      "categoryId"
    );
    // eslint-disable-next-line
  }, [sortCounter, categories]);

  const results = React.useMemo(
    () =>
      sortedCategories.filter((category) => {
        if (searchQuery) {
          return dataContainsWordThatStartWith(
            category.name || "",
            searchQuery
          );
        }
        return true;
      }),
    [sortedCategories, searchQuery]
  );
  return (
    <MwWhisper
      placement="bottomStart"
      trigger="click"
      onClose={
        onChange
          ? () => {
              if (!value.length) {
                onChange();
              }
              setSortCounter(sortCounter + 1);
            }
          : undefined
      }
      ref={whisperRef}
      speaker={
        <Popover className="popover-without-arrow checkbox-popover">
          <InputGroup inside>
            <InputGroup.Addon>
              <Icon icon="star" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              size="sm"
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
            />
          </InputGroup>
          <CheckboxGroup value={value} onChange={onChange}>
            {results.map((category) => (
              <Checkbox
                disabled={!onChange}
                className="checkbox-max-width"
                key={category.categoryId}
                value={category.categoryId}
              >
                {category.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Popover>
      }
    >
      <MwTag
        iconComponent={Category}
        onClose={onChange ? () => onChange() : undefined}
        color="cyan"
        size="lg"
      >
        Categorie ({value.length})
      </MwTag>
    </MwWhisper>
  );
};

export default CategoryPopover;
