import React from "react";
import { Alert, Button, Icon, IconButton, Modal } from "rsuite";
import { TSchemaFormErrors } from "../../../components/SchemaFormBody";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../../types/openapi";
import { Loader } from "rsuite";
import {
  focusError,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import "./index.scss";
import axios from "../../../inc/axios";
import ajv from "../../../inc/ajv";
import UserForm from "../../../components/UserForm";
import { resetPassword } from "../../../components/UserForm/helpers";
import { I18nContext } from "../../../provider/I18nProvider";
import useUsers from "../../../hooks/useUsers";
import { AuthContext } from "../../../provider/AuthProvider";
import Prullie from "../../../icons/Prullie";
import { TUser } from "../../../types";

interface IUserModalProps {
  user: TUser;
  onClose: () => void;
}

const UserSchema = openapi.components.schemas.User as oas30.SchemaObject;
const validate = ajv.compile(UserSchema);

const UserModal = (props: IUserModalProps) => {
  const { onClose } = props;
  const { t } = React.useContext(I18nContext);
  const { setUsers, updateUser } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const users = useUsers();

  const [user, setUser] = React.useState<components["schemas"]["User"]>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});

  const stringifiedOriginalUser = React.useMemo(
    () => JSON.stringify(props.user),
    [props.user],
  );
  React.useEffect(() => {
    setUser(JSON.parse(stringifiedOriginalUser));
  }, [stringifiedOriginalUser]);
  const isDirty = React.useMemo(() => {
    return stringifiedOriginalUser !== JSON.stringify(user);
  }, [stringifiedOriginalUser, user]);

  const onDeleteClick = React.useCallback(() => {
    const { userId, groupId } = user || {};
    if (
      !userId ||
      !users ||
      !user ||
      !window.confirm(t("areYouSureDeleteUser", user.displayName))
    ) {
      return;
    }
    axios
      .delete(`/user/crud/${groupId}/${userId}`)
      .then(() => {
        setUsers((users) =>
          users
            ? users.filter(
                (user) => !(user.userId === userId && user.groupId === groupId),
              )
            : users,
        );
        Alert.success(t("deleteSuccessful"));
        onClose();
      })
      .catch((err) => {
        Alert.error(t(err.message || "genericErrorMessage"));
      });
  }, [onClose, setUsers, t, user, users]);

  const onSubmit = React.useCallback(() => {
    const isValid = validate(user);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(user, validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!user) {
      return;
    }
    setErrors({});
    const isNew = !(user && user.userId);
    updateUser(user)
      .then(() => {
        if (isNew) {
          resetPassword(user);
        }
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [onClose, t, updateUser, user]);

  return (
    <Modal
      show
      onHide={() => {
        if (!isDirty || window.confirm(t("areYouSureUnsavedChanges"))) {
          onClose();
        }
      }}
      className="modal-size-auto"
    >
      <Modal.Header>
        <Modal.Title>{user ? user.displayName : t("loading")}</Modal.Title>
        <div style={{ display: "flex" }}>
          {user && user.userId && auth && auth.jwt.userId !== user.userId ? (
            <IconButton
              icon={<Icon icon="trash" componentClass={Prullie} />}
              onClick={onDeleteClick}
              appearance="default"
              color="red"
              className="me-5"
            >
              {t("deleteUser")}
            </IconButton>
          ) : null}
          <Button onClick={onSubmit} appearance="primary" disabled={!isDirty}>
            {t("save")}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body
        style={
          // overrule inline styling
          { maxHeight: undefined }
        }
      >
        <div className="modal__content">
          {user ? (
            <UserForm errors={errors} setUser={setUser} user={user} />
          ) : (
            <Loader center size="lg" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserModal;
