import {
  Checkbox,
  CheckboxProps,
  CheckPicker,
  CheckPickerProps,
  ControlLabel,
  FormControl,
  FormControlProps,
  FormGroup,
  HelpBlock,
  SelectPicker,
  SelectPickerProps,
} from "rsuite";
import React from "react";
import resolvedOpenApi, {
  getEmptyObject,
  getReferencedSchema,
  getReferencedSchemaName,
} from "../../inc/schema";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import _ from "lodash";
import "./index.scss";
import LogoFileInput, { ILogoFileInputProps } from "../form/LogoFileInput";
import LabelIdPicker from "../form/LabelIdPicker";
import LabelIdsPicker from "../form/LabelIdsPicker";
import FilterEditButton from "../form/FilterEditButton";
import { I18nContext } from "../../provider/I18nProvider";
import PasswordInput from "../form/PasswordInput";
import { oas30 } from "openapi3-ts";
import FileInput from "../form/FileInput";

export type TSchemaFormErrors<T = {}> = {
  [P in keyof Partial<T>]: string;
};

const appConfigSchema = resolvedOpenApi.components.schemas.AppConfig;

export interface ISchemaFormPropConfig {
  FormControl?: (props: FormControlProps & { value: any }) => JSX.Element;
  header?: string;
  hidden?: boolean;
  intro?: string;
  label?: React.ReactElement | null;
  order?: number;
  formControlProps?: FormControlProps;
}

type ISchemaFormBodyProps<T> = {
  config?: {
    [P in keyof Partial<T>]: ISchemaFormPropConfig;
  };
  instancePath?: string;
  disabled?: boolean;
  errors?: TSchemaFormErrors<T>;
  schema: oas30.SchemaObject;
  value?: T;
  onChange?: (value: T) => void;
  inputClassName?: string;
  labelPrefix?: string;
};

export default function SchemaFormBody<T>(props: ISchemaFormBodyProps<T>) {
  const apiDataContext = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const {
    config,
    instancePath = "",
    disabled,
    schema,
    onChange,
    errors,
    inputClassName,
    labelPrefix,
  } = props;

  const { properties, required } = React.useMemo(() => {
    const { properties = {}, required = [] } = schema;
    return { properties, required } as {
      properties: {
        [P in keyof T]: oas30.SchemaObject | oas30.ReferenceObject;
      };
      required: (keyof T)[];
    };
  }, [schema]);
  const value: T = props.value ? props.value : getEmptyObject(schema);

  const sortedPropertyNames = React.useMemo(() => {
    return (Object.keys(properties) as (keyof T)[]).sort((a, b) => {
      const orderA = (
        config && config[a] && config[a].order ? config[a].order : 0
      ) as number;
      const orderB = (
        config && config[b] && config[b].order ? config[b].order : 0
      ) as number;
      return orderA - orderB;
    });
  }, [config, properties]);

  return sortedPropertyNames.map((propNameKey, propIndex) => {
    const propConfig = config && config[propNameKey] ? config[propNameKey] : {};
    if (propConfig.hidden) {
      return null;
    }
    let isRequired: boolean = required.indexOf(propNameKey) >= 0;
    const propSchema = properties[propNameKey] as oas30.SchemaObject;
    const propName = propNameKey as string;

    if (propSchema.readOnly) {
      return null;
    }
    const onInputChange = (propValue: any) => {
      if (!onChange) {
        return;
      }
      onChange({
        ...value,
        [propName]: propValue,
      });
    };
    let formControlProps: FormControlProps = {
      name: `${propName}`,
      id: propName,
      className: `input ${inputClassName || ""}`,
      disabled: disabled,
      onChange: onInputChange,
      value:
        (value as any)[propName] !== undefined
          ? (value as any)[propName]
          : propSchema.default,
    };
    if (
      // The first prop is the ID-prop?
      (propIndex === 0 && `${propName}`.endsWith("Id")) ||
      // A magic object? (e.g. User.settings)
      propSchema.additionalProperties
    ) {
      return null;
    }

    const label =
      propConfig.label === undefined ? (
        <ControlLabel className="label" htmlFor={`${propName}`}>
          {t((labelPrefix || "") + propName)} {!isRequired ? " *" : ""}
        </ControlLabel>
      ) : (
        propConfig.label
      );

    switch (propName) {
      case "groupId":
        let selectPickerFormControlProps: FormControlProps<SelectPickerProps> =
          {
            ...formControlProps,
            style: { width: 300 },
            accepter: SelectPicker,
            data: apiDataContext.groups
              ? Object.values(apiDataContext.groups)
              : [],
            labelKey: "groupName",
            valueKey: "groupId",
          };
        formControlProps = selectPickerFormControlProps as FormControlProps;
        break;

      case "logoUrlLight":
      case "logoUrlDark":
        formControlProps = {
          ...formControlProps,
          showPreview: true,
          showClear:
            formControlProps.value !==
            // @ts-ignore
            appConfigSchema.properties[propName].default,
          accepter: FileInput,
        } as unknown as FormControlProps;
        break;
      case "logoFileId":
        let fileComponent: FormControlProps<ILogoFileInputProps> = {
          ...formControlProps,
          accepter: LogoFileInput,
        };
        formControlProps = fileComponent as FormControlProps;
        break;

      case "filter":
        formControlProps = {
          ...formControlProps,
          accepter: FilterEditButton,
        };
        break;

      case "labelId":
        formControlProps = {
          ...formControlProps,
          accepter: LabelIdPicker,
        };
        break;

      case "labelIds":
        formControlProps = {
          ...formControlProps,
          accepter: LabelIdsPicker,
        };
        break;

      default:
        switch (propSchema.type) {
          case "object":
            return (
              <fieldset key={propName}>
                <legend>{t(propName)}</legend>
                <SchemaFormBody
                  schema={propSchema}
                  value={formControlProps.value}
                  onChange={onInputChange}
                />
              </fieldset>
            );

          case "boolean":
            let checkboxFormControlProps: FormControlProps<CheckboxProps> = {
              ...formControlProps,
              accepter: Checkbox as any,
              checked: formControlProps.value,
              className: "",
              onChange: (wasChecked, isChecked) => {
                onInputChange(isChecked);
              },
              children: label,
            };
            formControlProps = checkboxFormControlProps as FormControlProps;
            break;

          case "array":
            if (!propSchema.items) {
              throw new Error('Missing required "items"');
            }
            const itemSchema = propSchema.items as oas30.SchemaObject;
            let checkPickerFormControlProps: FormControlProps<CheckPickerProps> =
              {
                ...formControlProps,
                style: { width: 300 },
                accepter: CheckPicker,
              };
            if (itemSchema.enum) {
              checkPickerFormControlProps.data = itemSchema.enum.map(
                (value) => ({
                  label: t(value),
                  value,
                }),
              );
            } else {
              if (`${propName}`.endsWith("Ids")) {
                const referencedSchemaName =
                  getReferencedSchemaName(`${propName}`) || "";
                const referencedSchema = getReferencedSchema(
                  referencedSchemaName || "",
                );
                if (referencedSchema) {
                  checkPickerFormControlProps.data = (apiDataContext as any)[
                    `${_.lowerFirst(referencedSchemaName)}s`
                  ];
                  checkPickerFormControlProps.labelKey = `${_.lowerFirst(
                    referencedSchemaName,
                  )}Name`;
                  checkPickerFormControlProps.valueKey = `${propName}`.substr(
                    0,
                    `${propName}`.length - 1,
                  );
                }
              }
            }
            formControlProps = checkPickerFormControlProps as FormControlProps;
            break;

          case "integer":
            formControlProps.onChange = (propValue) => {
              const parsedValue = parseInt(propValue, 10);
              if (!onChange || (propValue && !isFinite(parsedValue))) {
                return;
              }
              onInputChange(propValue ? parsedValue : 0);
            };
            break;

          case "string":
            if (!formControlProps.value) {
              formControlProps.value = propSchema.enum
                ? propSchema.enum[0]
                : "";
            }
            if (propSchema.enum) {
              let selectPickerFormControlProps: FormControlProps<SelectPickerProps> =
                {
                  ...formControlProps,
                  style: { width: 300 },
                  accepter: SelectPicker,
                  data: propSchema.enum.map((value) => ({
                    value,
                    label: t(value),
                  })),
                };
              formControlProps =
                selectPickerFormControlProps as FormControlProps;
            } else {
              if (propSchema.format) {
                // allow to clear URI input if value is not required
                formControlProps.onChange = (newValue) => {
                  onInputChange(isRequired ? newValue : newValue || undefined);
                };
              }

              switch (propSchema.format as string) {
                case "hidden":
                  formControlProps.type = "hidden";
                  break;

                case "email":
                  formControlProps.type = "email";
                  break;

                case "password":
                  formControlProps.type = "password";
                  formControlProps.accepter = PasswordInput;
                  break;

                case "uri":
                  formControlProps.type = "url";
                  break;

                default:
                  formControlProps.type = "text";
              }
            }
        }
    }

    const SchemaBodyFormControl = propConfig.FormControl || FormControl;

    return (
      <FormGroup className={instancePath} key={`${propName}`}>
        {propConfig.header ? (
          <h4 className="mb-2">{propConfig.header}</h4>
        ) : null}
        {propSchema.type === "boolean" ? null : label}
        {propConfig.intro ? (
          <HelpBlock className="mb-1">{propConfig.intro}</HelpBlock>
        ) : null}
        <SchemaBodyFormControl
          {...formControlProps}
          {...propSchema["x-props"]}
          {...propConfig.formControlProps}
        />
        {propSchema.description ? (
          <HelpBlock>{propSchema.description}</HelpBlock>
        ) : null}
        {errors && errors[propNameKey] ? (
          <HelpBlock style={{ color: "red" }}>
            {t(errors[propNameKey])}
          </HelpBlock>
        ) : null}
      </FormGroup>
    );
  }) as any;
}
