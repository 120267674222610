import React from "react";
import "./index.scss";
import { Button, Icon, Input, InputGroup, Popover, Whisper } from "rsuite";
import MagnifyingGlass from "../../../icons/MagnifyingGlass";
import { components } from "../../../types/openapi";
import axios from "axios";
import AdvancedSearchEngineResults from "./Results";
import AdvancedSearchLabelResults from "./Results/Label";
import _ from "lodash";
import { containsWordThatStartWith } from "../../../inc/query";
import { WhisperInstance } from "rsuite/lib/Whisper";
import { I18nContext } from "../../../provider/I18nProvider";
import useCategories from "../../../hooks/useCategories";
import useLabels from "../../../hooks/useLabels";
import useSearchTopics from "../../../hooks/useSearchTopics";

interface IAdvancedSearchProps {
  disabled?: boolean;
  filter: components["schemas"]["Filter"];
  onChange: (filter: components["schemas"]["Filter"]) => void;
  startFilter: () => void;
  width: number;
}

const AdvancedSearch = ({
  disabled,
  filter,
  onChange,
  startFilter,
  width,
}: IAdvancedSearchProps) => {
  const { t } = React.useContext(I18nContext);
  const categories = useCategories();
  const labels = useLabels();
  const searchTopics = useSearchTopics();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [categoryResults, setCategoryResults] = React.useState<
    components["schemas"]["Category"][] | null | undefined
  >([]);
  const [sourceResults, setSourceResults] = React.useState<
    components["schemas"]["Source"][] | null | undefined
  >([]);
  const [authorResults, setAuthorResults] = React.useState<
    components["schemas"]["Author"][] | null | undefined
  >([]);
  const [labelResults, setLabelResults] = React.useState<
    components["schemas"]["Label"][] | null | undefined
  >([]);
  const [searchTopicResults, setSearchTopicResults] = React.useState<
    components["schemas"]["SearchTopic"][] | null | undefined
  >([]);
  const advancedSearchRef = React.useRef<HTMLDivElement>(null);
  const advancedSearchTriggerWhisperRef = React.useRef<WhisperInstance>(null);

  const searchCategories = React.useCallback(
    (query: string) =>
      new Promise(
        (resolve: (values: components["schemas"]["Category"][]) => void) => {
          resolve(
            categories
              ? containsWordThatStartWith(
                  Object.values(categories),
                  "name",
                  query,
                )
              : [],
          );
        },
      ),
    [categories],
  );

  const searchLabels = React.useCallback(
    (query: string) =>
      new Promise(
        (resolve: (values: components["schemas"]["Label"][]) => void) => {
          resolve(
            labels
              ? containsWordThatStartWith(Object.values(labels), "name", query)
              : [],
          );
        },
      ),
    [labels],
  );

  const searchSearchTopics = React.useCallback(
    (query: string) =>
      new Promise(
        (resolve: (values: components["schemas"]["SearchTopic"][]) => void) => {
          resolve(
            searchTopics
              ? containsWordThatStartWith(
                  Object.values(searchTopics),
                  "name",
                  query,
                )
              : [],
          );
        },
      ),
    [searchTopics],
  );

  const debouncedSourcesHydrate = React.useMemo(
    () =>
      _.debounce((searchQuery: string) => {
        setCategoryResults(null);
        setSourceResults(null);
        setAuthorResults(null);
        setLabelResults(null);
        setSearchTopicResults(null);

        Promise.all([
          searchCategories(searchQuery),
          axios.get("/source/crud", { params: { query: searchQuery } }),
          axios.post("/author/crud", { query: searchQuery }),
          searchLabels(searchQuery),
          searchSearchTopics(searchQuery),
        ]).then(
          ([
            categoryResponse,
            sourceResponse,
            authorResponse,
            labelResponse,
            searchTopicResponse,
          ]) => {
            setCategoryResults(categoryResponse);
            setSourceResults(sourceResponse.data);
            setAuthorResults(authorResponse.data);
            setLabelResults(labelResponse);
            setSearchTopicResults(searchTopicResponse);
          },
        );
      }, 1000),
    [searchCategories, searchLabels, searchSearchTopics],
  );
  React.useEffect(() => {
    if (!searchQuery || searchQuery.length < 3) {
      setCategoryResults(undefined);
      setSourceResults(undefined);
      setAuthorResults(undefined);
      setLabelResults(undefined);
      setSearchTopicResults(undefined);
      return;
    }
    debouncedSourcesHydrate(searchQuery);
  }, [debouncedSourcesHydrate, searchQuery]);

  const searchForResults = React.useCallback((query: string) => {
    if (!query || query.length < 3) {
      return;
    }
    setCategoryResults(null);
    setSourceResults(null);
    setAuthorResults(null);
    setLabelResults(null);
    setSearchTopicResults(null);
  }, []);
  const onSubmit = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (e) {
        e.preventDefault();
      }
      if (!searchQuery) {
        startFilter();
        return;
      }
      onChange({ ...filter, text: searchQuery });
      setSearchQuery("");

      if (advancedSearchTriggerWhisperRef?.current) {
        advancedSearchTriggerWhisperRef.current.close();
      }
    },
    [filter, onChange, searchQuery, startFilter],
  );

  const container = advancedSearchRef.current;

  return (
    <div ref={advancedSearchRef} className="advanced-search">
      {container ? (
        <Whisper
          ref={advancedSearchTriggerWhisperRef}
          trigger="active"
          container={container}
          placement="bottom"
          onEnter={() => setIsFocused(true)}
          onExit={() => setIsFocused(false)}
          speaker={
            <Popover
              className={`popover-without-arrow advanced-search__popover ${
                searchQuery.length < 3 ? "" : "advanced-search__popover--open"
              }`}
              style={{ maxWidth: width - 52 }}
            >
              {searchQuery.length < 3 ? null : (
                <>
                  <Button block size="xs" onClick={onSubmit}>
                    {t("searchInText", searchQuery)}
                  </Button>
                  <AdvancedSearchEngineResults
                    title={t("categories")}
                    searchQuery={searchQuery}
                    results={categoryResults}
                    onChange={(categoryIds) =>
                      onChange({
                        ...filter,
                        categoryIds,
                      })
                    }
                    activeIds={filter.categoryIds || []}
                  />
                  <AdvancedSearchEngineResults
                    title={t("sources")}
                    searchQuery={searchQuery}
                    results={sourceResults}
                    onChange={(sourceIds) =>
                      onChange({
                        ...filter,
                        sourceIds,
                      })
                    }
                    activeIds={filter.sourceIds || []}
                  />
                  <AdvancedSearchEngineResults
                    title={t("authors")}
                    searchQuery={searchQuery}
                    results={authorResults}
                    onChange={(authorIds) =>
                      onChange({
                        ...filter,
                        authorIds,
                      })
                    }
                    activeIds={filter.authorIds || []}
                  />
                  <AdvancedSearchLabelResults
                    title={t("labels")}
                    searchQuery={searchQuery}
                    results={labelResults}
                    onChange={(labelIds) => {
                      onChange({
                        ...filter,
                        includeLabelIds: labelIds,
                      });
                    }}
                    activeIds={filter.includeLabelIds || []}
                  />
                  <AdvancedSearchEngineResults
                    title={t("searchQueries")}
                    searchQuery={searchQuery}
                    results={searchTopicResults}
                    onChange={(searchTopicIds) =>
                      onChange({
                        ...filter,
                        searchTopicIds,
                      })
                    }
                    activeIds={filter.searchTopicIds || []}
                  />
                </>
              )}
            </Popover>
          }
        >
          <InputGroup
            className={`advanced-search__input ${
              isFocused ? "advanced-search__input--focus" : ""
            }`}
            size="sm"
            inside
            disabled={disabled}
          >
            <InputGroup.Addon>
              <Icon icon="question" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              placeholder={t("searchWithinResults")}
              value={searchQuery}
              onPressEnter={onSubmit}
              onChange={(value) => {
                searchForResults(value);
                setSearchQuery(value);
              }}
            />
          </InputGroup>
        </Whisper>
      ) : null}
    </div>
  );
};

export default AdvancedSearch;
