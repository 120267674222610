import React, { SVGProps } from "react";

const Editorial = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Nieuwsbrief_icon"
      width={23.398}
      height={19.624}
      viewBox="0 0 23.398 19.624"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Group_332">
        <path
          id="prefix__Path_621"
          d="M1437.889 213.025a.587.587 0 01-.362-1.051l14.007-10.911a.587.587 0 01.722.927l-14.006 10.91a.586.586 0 01-.361.125z"
          transform="translate(-1429.582 -200.398)"
        />
        <path
          id="prefix__Path_622"
          d="M1437.889 234.918a.589.589 0 01-.587-.588v-6.93a.587.587 0 111.174 0v5.41l1.408-1.547a.587.587 0 01.869.791l-2.429 2.669a.587.587 0 01-.435.195z"
          transform="translate(-1429.582 -215.294)"
        />
        <path
          id="prefix__Path_623"
          d="M1436.952 217.6a.592.592 0 01-.286-.074l-17.255-9.6a.588.588 0 01.105-1.072l22.225-7.161a.587.587 0 01.744.726l-4.969 16.762a.586.586 0 01-.563.42zm-15.788-10.044l15.434 8.587 4.445-14.992z"
          transform="translate(-1419.109 -199.664)"
        />
      </g>
    </svg>
  );
};

export default Editorial;
