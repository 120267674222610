import React, { SVGProps } from "react";

const WidgetTypeMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36.377}
    height={37.424}
    viewBox="0 0 36.377 37.424"
    {...props}
  >
    <defs>
      <style>{".svg-icons__widget-type-media{fill:#96afaf}"}</style>
    </defs>
    <g id="prefix__Mediatype_icon">
      <g id="prefix__Group_1256">
        <path
          id="prefix__Path_1074"
          d="M1662.625 231.234H1637.8a1.3 1.3 0 01-1.3-1.3v-3.2a1.3 1.3 0 012.605 0v1.9h22.22v-28.92l-3.613-3.3H1639.1v7.374a1.3 1.3 0 01-2.605 0v-8.677a1.3 1.3 0 011.3-1.3h20.415a1.3 1.3 0 01.878.34l4.41 4.025a1.3 1.3 0 01.426.963v30.793a1.3 1.3 0 01-1.299 1.302z"
          className="svg-icons__widget-type-media"
          transform="translate(-1627.55 -193.81)"
        />
        <g id="prefix__Group_284" transform="translate(0 12.96)">
          <path
            id="prefix__Path_1075"
            d="M1651.784 224.2h-19.507a5.292 5.292 0 01-5.287-5.286v-6.05a5.293 5.293 0 015.287-5.287h19.507a5.292 5.292 0 015.286 5.287v6.05a5.292 5.292 0 01-5.286 5.286zm-19.507-14.019a2.685 2.685 0 00-2.682 2.682v6.05a2.685 2.685 0 002.682 2.682h19.507a2.686 2.686 0 002.682-2.682v-6.05a2.685 2.685 0 00-2.682-2.682z"
            className="svg-icons__widget-type-media"
            transform="translate(-1626.991 -207.581)"
          />
        </g>
        <path
          id="prefix__Path_1076"
          fill="#3cbe8c"
          d="M1663.9 200.2h-4.41a1.248 1.248 0 01-1.3-1.186V195a1.189 1.189 0 01.8-1.1 1.4 1.4 0 011.42.257l4.41 4.014a1.109 1.109 0 01.282 1.294 1.31 1.31 0 01-1.202.735z"
          transform="translate(-1628.827 -193.81)"
        />
        <circle
          id="prefix__Ellipse_163"
          cx={1.365}
          cy={1.365}
          r={1.365}
          className="svg-icons__widget-type-media"
          transform="translate(3.765 19.907)"
        />
      </g>
    </g>
  </svg>
);

export default WidgetTypeMedia;
