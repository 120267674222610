import _ from "lodash";
import { TagPicker } from "rsuite";
import React from "react";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { onSelect } from "../inc/label";
import LabelTag from "../../LabelTag";
import "./index.scss";
import useLabels from "../../../hooks/useLabels";
import { ItemDataType } from "rsuite/es/@types/common";

interface ILabelIdsPickerProps {
  container?: HTMLElement | (() => HTMLElement);
  onChange?: (
    newValue: string[],
    event: React.SyntheticEvent<HTMLElement, Event>
  ) => void;
  value?: string[];
}

const LabelIdsPicker = ({
  container,
  onChange,
  value,
}: ILabelIdsPickerProps) => {
  const { updateLabel } = React.useContext(ApiDataContext);
  const labels = useLabels();
  const [isCreatable, setIsCreatable] = React.useState<boolean>();
  const tagPickerValues = React.useMemo(() => {
    if (!value?.length) {
      return [];
    }
    if (!labels) {
      return value;
    }
    return value.sort((a: string, b: string) => {
      const indexA = a in labels ? Object.keys(labels).indexOf(a) : -1;
      const indexB = b in labels ? Object.keys(labels).indexOf(b) : -1;
      return indexA - indexB;
    });
  }, [labels, value]);
  const [forceTagPickerOptionsReset, setForceTagPickerOptionsReset] =
    React.useState(0);
  const tagPickerOptions = React.useMemo(() => {
    return labels
      ? Object.values(labels)
          .sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          )
          .map((label) => ({
            value: label.labelId,
            label: label.name,
          }))
      : [];
    // eslint-disable-next-line
  }, [labels, forceTagPickerOptionsReset]);
  return labels ? (
    <div>
      <TagPicker
        className="tag-picker-without-caret"
        container={container}
        creatable={isCreatable}
        menuClassName="components__media-item__label-picker-menu"
        cleanable={false}
        value={tagPickerValues}
        data={tagPickerOptions}
        style={{ display: "block" }}
        placement={"auto"}
        onSearch={(searchKeyword) => {
          // Label picker is creatable if non of the options contain the searchKeyword
          setIsCreatable(
            !tagPickerOptions.find((tag) => tag.label.includes(searchKeyword))
          );
        }}
        onSelect={onSelect}
        onChange={(labelIds, e: any) => {
          if (!onChange) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();

          const newLabelName = e._newLabelName;
          if (
            newLabelName &&
            tagPickerOptions.find((option) => option.label === newLabelName)
          ) {
            console.log("Duplicate label detected");
            setForceTagPickerOptionsReset(forceTagPickerOptionsReset + 1);
            return;
          }
          const labelIdsPromise = newLabelName
            ? updateLabel({
                name: newLabelName,
              }).then((label) => {
                return labelIds.map((labelId: string) =>
                  labelId === newLabelName ? label.labelId : labelId
                );
              })
            : Promise.resolve(labelIds);
          labelIdsPromise.then((labelIds) => onChange(labelIds, e));
        }}
        renderValue={(_value, items: ItemDataType | ItemDataType[]) => {
          return (_.isArray(items) ? items : [items])
            .filter((item) => !!item)
            .map((item) => (
              <LabelTag
                color="green"
                rounded={true}
                size="lg"
                key={item.value}
                onClose={() => {
                  if (!onChange) {
                    return;
                  }
                  onChange(
                    (value || []).filter((id) => id !== item.value),
                    {} as any
                  );
                }}
                label={labels[item.value]}
              />
            ));
        }}
        renderMenuItem={(label, item: ItemDataType) => (
          <span className={`components__form__label-ids-picker__menu-item`}>
            {label}
          </span>
        )}
      />
    </div>
  ) : null;
};

export default LabelIdsPicker;
