import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";
import { components } from "../types/openapi";

const useBenchmarkAccess = singletonHook(undefined, () => {
  const { benchmarkAccesses, setBenchmarkAccesses } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    const { userRoles = [] } = auth?.jwt || {};
    if (
      benchmarkAccesses !== undefined ||
      !auth?.bearer ||
      userRoles.indexOf("superuser") + userRoles.indexOf("benchmarkOnly") > -2
    ) {
      return;
    }

    setBenchmarkAccesses(null);
    axios
      .get<components["schemas"]["BenchmarkAccess"][]>("/benchmarkAccess/crud")
      .then((res) => {
        setBenchmarkAccesses(
          res.data.reduce<IHashMap<components["schemas"]["BenchmarkAccess"]>>(
            (prev, benchmarkAccess) => {
              prev[benchmarkAccess.benchmarkAccessId as string] =
                benchmarkAccess;
              return prev;
            },
            {},
          ),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [benchmarkAccesses, auth?.bearer, setBenchmarkAccesses, auth?.jwt]);

  return benchmarkAccesses;
});

export default useBenchmarkAccess;
