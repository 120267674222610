import * as React from "react";
import { SVGProps } from "react";

const WidgetTypeLabel = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37.943}
      height={37.947}
      viewBox="0 0 37.943 37.947"
      {...props}
    >
      <path
        fill="#96afaf"
        d="M18.587 37.948a3.459 3.459 0 01-2.461-1.019L1.017 21.819a3.486 3.486 0 010-4.922l15.17-15.169A5.759 5.759 0 0120.408.004l13.956.132a3.5 3.5 0 013.447 3.447l.132 13.955a5.917 5.917 0 01-1.724 4.222L21.05 36.929a3.463 3.463 0 01-2.463 1.019zm-.212-3.27a.261.261 0 00.424 0l15.17-15.17a2.727 2.727 0 00.792-1.941l-.132-13.955a.3.3 0 00-.3-.3l-13.956-.133a2.855 2.855 0 00-1.943.794L3.269 19.145a.3.3 0 000 .425l15.108 15.107z"
      />
      <path
        fill="#3cbe8c"
        d="M27.861 14.482a4.4 4.4 0 113.111-1.287 4.383 4.383 0 01-3.111 1.287zm1.987-2.411zm-1.987-3.207a1.219 1.219 0 10.861.356 1.221 1.221 0 00-.861-.356z"
      />
    </svg>
  );
};

export default WidgetTypeLabel;
