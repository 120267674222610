import React, { SVGProps } from "react";

const Export = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={16}
    viewBox="0 0 12 16"
    fill="currentColor"
    {...props}
  >
    <path
      d="M1593.543 937.972h-7.455a2.166 2.166 0 01-2.206-2.119v-3.081a2.166 2.166 0 012.206-2.119.5.5 0 110 1 1.149 1.149 0 00-1.169 1.124v3.081a1.148 1.148 0 001.169 1.123h7.455a1.148 1.148 0 001.169-1.123v-3.081a1.149 1.149 0 00-1.169-1.124.5.5 0 110-1 2.166 2.166 0 012.206 2.119v3.081a2.166 2.166 0 01-2.206 2.119z"
      transform="translate(-1583.883 -922.898)"
    />
    <path
      d="M1599.4 922.989a.5.5 0 01-.5-.5v-8.212a.5.5 0 011 0v8.212a.5.5 0 01-.5.5z"
      transform="translate(-1593.258 -912.691)"
    />
    <path
      d="M1597.14 915.215a.543.543 0 01-.373-.146l-3.3-3.115-3.3 3.115a.549.549 0 01-.745 0 .477.477 0 010-.7l3.673-3.468a.549.549 0 01.746 0l3.672 3.468a.478.478 0 010 .7.542.542 0 01-.373.146z"
      transform="translate(-1587.33 -910.751)"
    />
  </svg>
);
export default Export;
