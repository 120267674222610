// Taken from https://github.com/Swizec/useDimensions/

import { useState, useCallback, useLayoutEffect, LegacyRef } from "react";

interface IDimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

interface UseDimensionsArgs {
  liveMeasure?: boolean;
}

function getDimensionObject(node: HTMLElement): IDimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: rect.x,
    left: rect.y,
    x: rect.x,
    y: rect.y,
    right: rect.right,
    bottom: rect.bottom,
  };
}

function useDimensions({ liveMeasure = true }: UseDimensionsArgs = {}): [
  LegacyRef<any>,
  IDimensionObject | null,
  HTMLElement | null,
] {
  const [dimensions, setDimensions] = useState<IDimensionObject | null>(null);
  const [node, setNode] = useState<HTMLElement | null>(null);

  const ref = useCallback((node: HTMLElement) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node)),
        );
      measure();

      if (liveMeasure) {
        window.addEventListener("resize", measure);
        window.addEventListener("scroll", measure);

        return () => {
          window.removeEventListener("resize", measure);
          window.removeEventListener("scroll", measure);
        };
      }
    }
  }, [liveMeasure, node]);

  return [ref, dimensions, node];
}

export default useDimensions;
