import React from "react";
import { Checkbox, Col, Grid, Radio, RadioGroup, Row } from "rsuite";
import IconRadioGroup from "../../../../components/IconRadioGroup";
import ChartPie from "../../../../icons/ChartPie";
import ChartHorizontalBars from "../../../../icons/ChartHorizontalBars";
import { ESplitType, EValueType } from "../../index";
import { I18nContext } from "../../../../provider/I18nProvider";
import ChartPoints from "../../../../icons/ChartPoints";
import ChartCurved from "../../../../icons/ChartCurved";
import ChartVerticalBars from "../../../../icons/ChartVerticalBars";
import { EChartType, EIntervalType } from "../../../../inc/enums";

import "./index.scss";

const svgs = {
  [EChartType.line]: ChartPoints,
  [EChartType.curved]: ChartCurved,
  [EChartType.donut]: ChartPie,
  [EChartType.bar]: ChartHorizontalBars,
  [EChartType.column]: ChartVerticalBars,
};

interface IPopoverFormProps<Settings> {
  chartTypes?: EChartType[];
  filterOptions?: string[];
  splitTypes: ESplitType[];
  onSettingsChange: (widgetUid: string, newSettings: Settings) => void;
  settings: Settings;
  showIntervalOption?: boolean;
  showRemainingOption?: boolean;
  topResultOptions?: number[];
  widgetUid: string;
}

function PopoverForm<
  Settings extends {
    chartType?: EChartType;
    filter?: string;
    interval?: EIntervalType;
    splitType?: ESplitType;
    valueType?: EValueType;
    limit?: number;
    showRemaining?: boolean;
  }
>({
  chartTypes,
  filterOptions,
  onSettingsChange,
  settings,
  showIntervalOption,
  showRemainingOption,
  splitTypes,
  topResultOptions,
  widgetUid,
}: IPopoverFormProps<Settings>) {
  const { t } = React.useContext(I18nContext);
  const {
    chartType,
    filter = "",
    interval = EIntervalType.auto,
    limit,
    showRemaining,
    splitType = ESplitType.none,
    valueType = EValueType.count,
  } = settings;

  return (
    <Grid fluid className="widgets-popover-form">
      {chartTypes ? (
        <Row>
          <Col xs={24}>
            <h5>{t("widgetSettings_display")}</h5>
            <IconRadioGroup
              inline={true}
              value={chartType || "donut"}
              onChange={(value) => {
                onSettingsChange(widgetUid, {
                  ...settings,
                  chartType: value,
                  showRemaining:
                    value === EChartType.donut ? true : settings.showRemaining,
                });
              }}
              items={chartTypes.map((chartType) => ({
                value: chartType,
                svg: svgs[chartType],
              }))}
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={12}>
          <RadioGroup
            name="valueType"
            value={valueType}
            className="mt-3"
            onChange={(value) => {
              onSettingsChange(widgetUid, {
                ...settings,
                valueType: value as EValueType,
              });
            }}
          >
            <h5 className="mt-4">{t("widgetSettings_valueType")}</h5>
            {Object.keys(EValueType).map((key) => (
              <Radio value={key} key={key}>
                {t(`valueType_${key}`)}
              </Radio>
            ))}
          </RadioGroup>
        </Col>
        <Col xs={12}>
          <RadioGroup
            disabled={chartType === EChartType.donut}
            name="splitType"
            value={splitType}
            className="mt-3"
            onChange={(value) => {
              onSettingsChange(widgetUid, {
                ...settings,
                // disable any custom by activating limit
                limit: settings.limit || 5,
                filter: "",
                splitType: value as ESplitType.none,
              });
            }}
          >
            <h5 className="mt-4">{t("widgetSettings_splitType")}</h5>
            {splitTypes.map((key) => (
              <Radio
                value={key}
                key={key}
                disabled={chartType === EChartType.donut}
              >
                {t(`splitType_${key}`)}
              </Radio>
            ))}
          </RadioGroup>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {topResultOptions ? (
            <RadioGroup
              name="limit"
              value={limit}
              className="mt-3"
              onChange={(limit: number) => {
                onSettingsChange(widgetUid, {
                  ...settings,
                  limit,
                });
              }}
            >
              <h5 className="mt-4">{t("widgetSettings_limitedResults")}</h5>
              {topResultOptions.map((value) => (
                <Radio value={value} key={value}>
                  {value ? t("topXResults", value) : t("topOwn")}
                </Radio>
              ))}
            </RadioGroup>
          ) : null}
          {showRemainingOption ? (
            <Checkbox
              name="showRemaining"
              disabled={!settings.limit}
              checked={showRemaining}
              onChange={(_:unknown, checked) =>
                onSettingsChange(widgetUid, {
                  ...settings,
                  showRemaining: checked,
                })
              }
            >
              {t("showRemaining")}
            </Checkbox>
          ) : null}
        </Col>
        {showIntervalOption ? (
          <Col xs={12}>
            <RadioGroup
              name="interval"
              value={interval}
              className="mt-3"
              onChange={(interval: number) => {
                onSettingsChange(widgetUid, {
                  ...settings,
                  interval,
                });
              }}
            >
              <h5 className="mt-4">{t("widgetSettings_interval")}</h5>
              {Object.keys(EIntervalType).map((value) => (
                <Radio value={value} key={value}>
                  {t(`interval_${value}`)}
                </Radio>
              ))}
            </RadioGroup>
          </Col>
        ) : null}
        {filterOptions ? (
          <Col xs={12}>
            <RadioGroup
              name="filter"
              value={filter}
              className="mt-3"
              onChange={(filter: number) => {
                onSettingsChange(widgetUid, {
                  ...settings,
                  filter,
                });
              }}
            >
              <h5 className="mt-4">{t("widgetSettings_filter")}</h5>
              <Radio value="">{t("No Filter")}</Radio>
              {filterOptions.map((value) => (
                <Radio value={value} key={value}>
                  {t(`filter_${value}`)}
                </Radio>
              ))}
            </RadioGroup>
          </Col>
        ) : null}
      </Row>
    </Grid>
  );
}

export default PopoverForm;
