import React from "react";
import SchemaTable from "../../components/SchemaTable";
import { components } from "../../types/openapi";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import ActionButton from "../../components/ActionButton";
import { I18nContext } from "../../provider/I18nProvider";
import { Alert, Button, Icon, Input, InputGroup } from "rsuite";
import CopyToClipboard from "../../components/CopyToClipboard";
import CopyLink from "../../icons/CopyLink";
import RssExportModal from "./RssExportModal";
import useRssExports from "../../hooks/useRssExports";
import { AuthContext } from "../../provider/AuthProvider";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../inc/date";
import axios from "../../inc/axios";
import { AxiosError } from "axios";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { IMaartenError } from "../../types";

const rssExportSchema = openapi.components.schemas
  .RssExport as oas30.SchemaObject;

const RssView = () => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { setRssExports } = React.useContext(ApiDataContext);

  const rssExports = useRssExports();
  const [selectedExportRssId, setSelectedExportRssId] =
    React.useState<string>();
  const data = React.useMemo(
    () => (rssExports ? Object.values(rssExports) : []),
    [rssExports],
  );

  const onCreateMagazineClick = React.useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      axios
        .post<components["schemas"]["RssExport"]>(
          `/rssExport/createMagazine/${e.target.dataset.rssExportId}`,
        )
        .then((res) => {
          Alert.success(t("prValueHasBeenSaved"));
          setRssExports((rssExports) => ({
            ...rssExports,
            [res.data.rssExportId as string]: res.data,
          }));
        })
        .catch((err: AxiosError<IMaartenError>) => {
          Alert.error(t(err.response?.data.error || "genericErrorMessage"));
        });
    },
    [setRssExports, t],
  );

  const isSuperUser = !!auth?.jwt.superUserId;

  return (
    <div className="rss-exports-view">
      <RssExportModal
        show={!!selectedExportRssId}
        onClose={() => setSelectedExportRssId(undefined)}
        rssExportId={selectedExportRssId}
      />
      <SchemaTable<components["schemas"]["RssExport"]>
        schema={rssExportSchema}
        sortable
        globalSearch
        data={data}
        onRowClick={(rowData) =>
          isSuperUser ? setSelectedExportRssId(rowData.rssExportId) : {}
        }
        columnsConfigs={[
          { name: "rssExportId", hidden: true },
          { name: "filter", hidden: true },
          { name: "name", flexGrow: 1 },
          {
            name: "url",
            flexGrow: 2,
            renderCell: (rowData) =>
              rowData.url ? (
                <>
                  <InputGroup
                    className="rss-exports-view__url-input-group"
                    inside
                    size="sm"
                  >
                    <Input value={rowData.url} className="border-0" />
                    <CopyToClipboard value={rowData.url}>
                      <InputGroup.Button style={{ height: 26 }}>
                        <Icon icon="copy" componentClass={CopyLink} />
                      </InputGroup.Button>
                    </CopyToClipboard>
                  </InputGroup>
                </>
              ) : null,
          },
          {
            name: "createMagazineDatetime",
            title: "Maak magazine (Superuser)",
            hidden: !isSuperUser,
            width: 280,
            renderCell: (rowData) =>
              rowData.createMagazineDatetime ? (
                <>
                  {t("createdAt")}{" "}
                  {format(
                    new Date(rowData.createMagazineDatetime),
                    DATE_TIME_FORMAT,
                  )}
                </>
              ) : (
                <Button
                  data-rss-export-id={rowData.rssExportId}
                  onClick={onCreateMagazineClick}
                >
                  Maak magazine
                </Button>
              ),
          },
        ]}
      >
        {isSuperUser ? (
          <ActionButton
            onClick={() => setSelectedExportRssId("NEW")}
            title={t("newRssExport")}
          />
        ) : null}
      </SchemaTable>
    </div>
  );
};

export default RssView;
