import React, { CSSProperties } from "react";
import "./index.scss";
import { Icon } from "rsuite";

interface ISliderProps {
  children: React.ReactElement[];
  listHeight?: number;
}

const Slider = (props: ISliderProps) => {
  // Do not use React.useRef: we need this thing reactive, see
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const [sliderEl, setSliderEl] = React.useState<HTMLUListElement>();
  // keep timeout in state: setting a new timeout scroll button visibility
  const [slideTimeout, setSlideTimeout] = React.useState<number>();
  const slide = React.useCallback(
    (slideAmount: number) => {
      setSlideTimeout(
        window.setTimeout(() => {
          if (sliderEl) {
            sliderEl.scrollLeft += slideAmount;
            slide(slideAmount);
          }
        }, 50),
      );
    },
    [sliderEl],
  );
  const slideToLeft = React.useCallback(() => {
    slide(-10);
  }, [slide]);
  const slideToRight = React.useCallback(() => {
    slide(10);
  }, [slide]);
  const stopSlide = React.useCallback(() => {
    if (slideTimeout) {
      clearTimeout(slideTimeout);
    }
  }, [slideTimeout]);
  const ref = React.useCallback(
    (node: any) => {
      if (
        node !== null &&
        (sliderEl?.scrollLeft !== node.scrollLeft ||
          sliderEl?.offsetWidth !== node.offsetWidth ||
          sliderEl?.scrollWidth !== node.scrollWidth)
      ) {
        setSliderEl(node);
      }
    },
    // eslint-disable-next-line
    [props.children.length],
  );

  const listWrapperStyle: CSSProperties = {};
  if (props.listHeight) {
    listWrapperStyle["height"] = props.listHeight;
  }

  return (
    <div className="slider">
      <button
        className={`slider__arrow slider__arrow--prev ${
          sliderEl && sliderEl.scrollLeft > 0 ? "" : "hidden"
        }`}
        onMouseDown={() => slideToLeft()}
        onMouseUp={() => stopSlide()}
        onMouseLeave={() => stopSlide()}
      >
        <Icon icon="chevron-left" />
      </button>
      <div className="slider__list-wrapper" style={listWrapperStyle}>
        <ul className="slider__list" ref={ref}>
          {props.children.map((child, index) => (
            <li className="slider__list__item" key={index}>
              {child}
            </li>
          ))}
        </ul>
      </div>
      <button
        className={`slider__arrow slider__arrow--next ${
          sliderEl &&
          sliderEl.scrollLeft + sliderEl.offsetWidth < sliderEl.scrollWidth
            ? ""
            : "hidden"
        }`}
        onMouseDown={slideToRight}
        onMouseUp={stopSlide}
        onMouseLeave={stopSlide}
      >
        <Icon icon="chevron-right" />
      </button>
    </div>
  );
};
export default Slider;
