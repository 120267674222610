import React from "react";
import { Button, Tooltip } from "rsuite";
import FilterModal from "./FilterModal";
import { components } from "../../../types/openapi";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { getEmptyObject } from "../../../inc/schema";
import { cleanFilter } from "../../../inc/data";
import { I18nContext } from "../../../provider/I18nProvider";
import MwWhisper from "../../MwWhisper";

interface IFilterEditButtonProps {
  onDelete?: () => void;
  onChange?: (
    filter: components["schemas"]["Filter"] | undefined,
    event: React.SyntheticEvent<HTMLElement, Event>,
  ) => void;
  value?: components["schemas"]["Filter"];
  style?: React.CSSProperties;
  tooltip?: string;
}

const filterSchema = openapi.components.schemas.Filter as oas30.SchemaObject;
const emptyFilter =
  getEmptyObject<components["schemas"]["Filter"]>(filterSchema);

const FilterEditButton = ({
  onDelete,
  onChange,
  value,
  style,
  tooltip,
}: IFilterEditButtonProps) => {
  const { t } = React.useContext(I18nContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const hasFilter = value !== undefined;
  return (
    <>
      <MwWhisper
        trigger={tooltip ? "hover" : "none"}
        speaker={<Tooltip>{tooltip}</Tooltip>}
      >
        <Button
          style={style}
          appearance={hasFilter ? "primary" : "ghost"}
          color={hasFilter ? "green" : "blue"}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {t(hasFilter ? "editFilter" : "setFilter")}
        </Button>
      </MwWhisper>
      {isOpen ? (
        <FilterModal
          filter={value}
          onClose={(filter) => {
            if (onChange && filter) {
              onChange(cleanFilter(filter) || emptyFilter, {} as any);
            }
            setIsOpen(false);
          }}
          onDelete={
            onDelete
              ? () => {
                  onDelete();
                  setIsOpen(false);
                }
              : undefined
          }
          collapsibleTagsBar={false}
          allowEdit
        />
      ) : null}
    </>
  );
};

export default FilterEditButton;
