import React from "react";
import { FlexboxGrid, Col } from "rsuite";
import UnsubscribeView from "./UnsubscribeView";
import { RouteComponentProps } from "react-router-dom";

const UnsubscribeLayout = (
  props: RouteComponentProps<{ editorialUnsubscribeToken: string }>,
) => {
  return (
    <FlexboxGrid className="unsubscribeView" align="middle" justify="center">
      <FlexboxGrid.Item componentClass={Col} colspan={8} xsHidden smHidden>
        <img
          className="unsubscribeView__image"
          alt="media·web"
          src="/img/art/kranteter/kranteter@2x.png"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        componentClass={Col}
        xs={24}
        sm={18}
        md={8}
        className="layout__unsubscribe-layout__content"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <UnsubscribeView
          editorialUnsubscribeToken={
            props.match.params.editorialUnsubscribeToken
          }
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default UnsubscribeLayout;
