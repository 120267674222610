import React, { SVGProps } from "react";

const DashboardRead = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={14.265}
      viewBox="0 0 25 14.265"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g
        id="prefix__Group_1560"
        opacity={0.7}
        transform="translate(-1174.439 -98.996)"
      >
        <g id="prefix__Group_1559" transform="translate(1174.439 102.364)">
          <path
            id="prefix__Path_1853"
            d="M1197.158 117.745h-20.438a2.283 2.283 0 01-2.281-2.28v-6.336a2.283 2.283 0 012.281-2.281.594.594 0 110 1.187 1.1 1.1 0 00-1.094 1.093v6.336a1.1 1.1 0 001.094 1.093h20.438a1.1 1.1 0 001.094-1.093v-6.336a1.1 1.1 0 00-1.094-1.093.594.594 0 010-1.187 2.283 2.283 0 012.281 2.281v6.336a2.283 2.283 0 01-2.281 2.28z"
            className="prefix__cls-2"
            transform="translate(-1174.439 -106.848)"
          />
        </g>
        <path
          id="prefix__Path_1854"
          d="M1198.641 100.183h-11.255a.593.593 0 110-1.187h11.255a.593.593 0 110 1.187z"
          className="prefix__cls-2"
          transform="translate(-7.055)"
        />
        <path
          id="prefix__Path_1855"
          d="M1196.777 107.412h-9.392a.594.594 0 110-1.187h9.392a.594.594 0 010 1.187z"
          className="prefix__cls-2"
          transform="translate(-7.055 -4.128)"
        />
        <path
          id="prefix__Path_1856"
          d="M1198.641 114.641h-11.255a.594.594 0 110-1.187h11.255a.594.594 0 110 1.187z"
          className="prefix__cls-2"
          transform="translate(-7.055 -8.257)"
        />
        <path
          id="prefix__Path_1857"
          d="M1194.422 121.871h-7.037a.593.593 0 110-1.187h7.037a.593.593 0 110 1.187z"
          className="prefix__cls-2"
          transform="translate(-7.055 -12.386)"
        />
      </g>
    </svg>
  );
};

export default DashboardRead;
