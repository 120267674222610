import { Tooltip } from "rsuite";
import React from "react";

interface ITrendTooltipProps {
  description: string;
}
const TrendTooltip = (props: ITrendTooltipProps) => {
  const { description, ...tooltipProps } = props;
  return (
    <Tooltip {...tooltipProps}>
      <div className="p-2">{description}</div>
    </Tooltip>
  );
};

export default TrendTooltip;
