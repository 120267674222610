export const ACTIVE_LANGUAGE_LOCAL_STORAGE_KEY = "activeLanguage";

export enum ELocaleOptions {
  NL = "NL",
  EN = "EN",
}

export type TLocaleData = {
  [key: string]: string;
};

export type TLocaleRsuiteData = {
  [key: string]: string | TLocaleData;
};

export type TLocaleCalendar = {
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  ok: string;
  today: string;
  yesterday: string;
  hours: string;
  minutes: string;
  seconds: string;
  formattedMonthPattern: string;
  formattedDayPattern: string;
};

export type TLocale = {
  db: TLocaleData;
  rsuite: TLocaleRsuiteData;
};
