import React from "react";
import { APP_PATH } from "../../../inc/constants";
import useQueryParameters from "../../../hooks/useQueryParameters";
import { AuthContext } from "../../../provider/AuthProvider";
import ResetPasswordForm from "../inc/ResetPasswordForm";

const ResetPasswordView = () => {
  const queryParameters = useQueryParameters<{ token: string }>();
  const { auth, setBearer } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (auth) {
      // http://otrs.knipsel.lan/otrs/index.pl?Action=AgentTicketZoom;TicketID=13198
      // if logged in, log out so a fresh password can be set
      setBearer(undefined);
    }
  }, [auth, setBearer]);

  return (
    <ResetPasswordForm
      nextPath={APP_PATH}
      resetToken={queryParameters?.token || ""}
    />
  );
};

export default ResetPasswordView;
