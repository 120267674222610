import BenchmarkResultGridItem from "./BenchmarkResultGridItem";
import React from "react";
import { VariableSizeGrid } from "react-window";
import { Loader } from "rsuite";
import animate from "./inc/animate";
import { range } from "lodash";
import { MEDIA_ITEM_GRID_ITEM_WIDTH_MD } from "./inc/constants";
import { areEqual } from "react-window";
import { components } from "../../../../types/openapi";
import {
  ENarrowCastingMode,
  LayoutContext,
} from "../../../../provider/LayoutProvider";
import { IBenchmarkResultBenchmarkWidgetSettings } from "../index";
import { getColspan, getSearchEngineResultWidth } from "../../../MediaItemGrid";
import { LOAD_MEDIAITEM_COUNT } from "../../../../inc/constants";

interface IBenchmarkResultGridProps {
  disabled?: boolean;
  filterCacheResponse?: components["schemas"]["FilterCacheResponse"] | null;
  getSelected?: (
    benchmarkResult: components["schemas"]["BenchmarkResult"],
  ) => boolean;
  height: number;
  isBenchmarkResultLoaded: (obj: { index: number }) => boolean;
  loadMoreBenchmarkResults: (obj: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<void>;
  benchmarkResults: Array<
    components["schemas"]["BenchmarkResult"] | null | undefined
  >;
  onBenchmarkResultClick?: (
    benchmarkResult: components["schemas"]["BenchmarkResult"],
  ) => void;
  setSelected?: (
    benchmarkResult: components["schemas"]["BenchmarkResult"],
    isSelected: boolean,
  ) => void;
  width: number;
  settings?: IBenchmarkResultBenchmarkWidgetSettings;
}

const BenchmarkResultGrid = ({
  disabled,
  filterCacheResponse,
  height,
  isBenchmarkResultLoaded,
  loadMoreBenchmarkResults,
  benchmarkResults,
  onBenchmarkResultClick,
  settings,
  width,
}: IBenchmarkResultGridProps) => {
  const { narrowCastingMode } = React.useContext(LayoutContext);
  const colspan = React.useMemo(() => getColspan(width), [width]);
  const columnWidth = React.useMemo(
    () => getSearchEngineResultWidth(width),
    [width],
  );
  const variableSizeGridRef = React.useRef<VariableSizeGrid>(null);
  const [animationScrollTop, setAnimationScrollTop] = React.useState<
    number | undefined
  >();
  const scrollTopRef = React.useRef(0);
  const prevBenchmarkResultsRef =
    React.useRef<
      Array<components["schemas"]["BenchmarkResult"] | null | undefined>
    >();

  const isNarrowCasting =
    narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF;
  const columnCount = 24 / colspan;

  React.useEffect(() => {
    const updatedIndexes = benchmarkResults
      ? [...benchmarkResults.keys()].filter((index) => {
          return (
            benchmarkResults[index] &&
            prevBenchmarkResultsRef.current &&
            benchmarkResults[index] !== prevBenchmarkResultsRef.current[index]
          );
        })
      : [];
    if (updatedIndexes.length && variableSizeGridRef.current) {
      variableSizeGridRef.current.resetAfterRowIndex(
        Math.floor(Math.min(...updatedIndexes) / columnCount),
      );
    }
    prevBenchmarkResultsRef.current = benchmarkResults;
  }, [columnCount, benchmarkResults]);

  const getRowHeight = React.useCallback(
    (rowIndex: number) => {
      const benchmarkResultsInRow = range(
        rowIndex * columnCount,
        (rowIndex + 1) * columnCount,
      )
        .map((benchmarkResultIndex) => benchmarkResults[benchmarkResultIndex])
        .filter((item) => !!item) as components["schemas"]["BenchmarkResult"][];
      const defaultHeight =
        columnWidth >= MEDIA_ITEM_GRID_ITEM_WIDTH_MD ? 190 : 290;
      const cellHeights = benchmarkResultsInRow.map((benchmarkResult) => {
        let result = defaultHeight;
        if (isNarrowCasting) {
          result -= 20;
        }
        return result;
      });
      return cellHeights.length ? Math.max(...cellHeights) : defaultHeight;
    },
    [columnCount, columnWidth, isNarrowCasting, benchmarkResults],
  );

  const rowCount = React.useMemo(
    () =>
      filterCacheResponse?.count
        ? Math.ceil(filterCacheResponse.count / columnCount)
        : 0,
    [columnCount, filterCacheResponse?.count],
  );

  React.useEffect(() => {
    const autoScrollInterval = setInterval(() => {
      if (!isNarrowCasting) {
        return;
      }

      let currentVisibleRowIndex = 0;
      let currentVisibleRowY = 0;
      let totalGridHeight = 0;
      let nextVisibleRowIndex = -1;
      let nextVisibleRowY = -1;
      for (let index = 0; index < rowCount; index++) {
        const rowHeight = getRowHeight(index);
        if (scrollTopRef.current > totalGridHeight) {
          currentVisibleRowY = totalGridHeight + rowHeight;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          currentVisibleRowIndex = index + 1;
        } else {
          if (nextVisibleRowIndex === -1) {
            if (index + 1 < rowCount) {
              nextVisibleRowY = totalGridHeight + rowHeight;
              nextVisibleRowIndex = index + 1;
            } else {
              nextVisibleRowY = 0;
              nextVisibleRowIndex = 0;
            }
          }
        }
        totalGridHeight += rowHeight;
      }
      const toValue =
        scrollTopRef.current === currentVisibleRowY
          ? nextVisibleRowY
          : currentVisibleRowY;

      // // scroll to next message
      animate({
        fromValue: scrollTopRef.current,
        toValue,
        onUpdate: (newScrollTop, callback) => {
          setAnimationScrollTop(newScrollTop);
          callback();
        },
        onComplete: () => {
          setAnimationScrollTop(undefined);
        },
      });
    }, 30_000);

    return () => {
      clearInterval(autoScrollInterval);
    };
  }, [animationScrollTop, getRowHeight, isNarrowCasting, rowCount]);

  const Cell = React.useCallback(
    ({ columnIndex, rowIndex, style }: any) => {
      const benchmarkResultIndex = rowIndex * columnCount + columnIndex;
      const benchmarkResult = benchmarkResults[benchmarkResultIndex] as
        | components["schemas"]["BenchmarkResult"]
        | undefined;
      return (
        <div
          style={{
            ...style,
          }}
        >
          {benchmarkResult ? (
            <BenchmarkResultGridItem
              key={`${columnIndex}_${rowIndex}`}
              colspan={colspan}
              disabled={disabled}
              display={(settings?.display || "block") as "block"}
              benchmarkResult={benchmarkResult}
              onClick={onBenchmarkResultClick}
              width={columnWidth}
            />
          ) : benchmarkResults[benchmarkResultIndex] === null ? (
            <Loader center size="lg" />
          ) : null}
        </div>
      );
    },
    [
      colspan,
      columnCount,
      columnWidth,
      disabled,
      benchmarkResults,
      onBenchmarkResultClick,
      settings?.display,
    ],
  );

  const onItemsRendered = React.useCallback(
    ({ overscanRowStartIndex, overscanRowStopIndex }: any) => {
      const loadMorePayload = {
        startIndex: -1,
        stopIndex: -1,
      };
      range(
        overscanRowStartIndex * columnCount,
        overscanRowStopIndex * columnCount + 1,
      ).forEach((index) => {
        if (!isBenchmarkResultLoaded({ index })) {
          if (loadMorePayload.startIndex === -1) {
            loadMorePayload.startIndex = index;
          }
          loadMorePayload.stopIndex = index;
        }
      });
      if (loadMorePayload.startIndex >= 0) {
        // load at least 12 items...
        if (
          loadMorePayload.stopIndex - loadMorePayload.startIndex <
          LOAD_MEDIAITEM_COUNT
        ) {
          loadMorePayload.stopIndex =
            loadMorePayload.startIndex + LOAD_MEDIAITEM_COUNT;
        }
        loadMoreBenchmarkResults(loadMorePayload);
      }
    },
    [columnCount, isBenchmarkResultLoaded, loadMoreBenchmarkResults],
  );

  const getColumnWidth = React.useCallback(() => columnWidth, [columnWidth]);
  return (
    <VariableSizeGrid
      ref={variableSizeGridRef}
      onItemsRendered={onItemsRendered}
      columnCount={columnCount}
      columnWidth={getColumnWidth}
      height={height}
      rowCount={Math.ceil(benchmarkResults.length / columnCount)}
      rowHeight={getRowHeight}
      width={width}
    >
      {Cell}
    </VariableSizeGrid>
  );
};

export default React.memo(BenchmarkResultGrid, areEqual);
