import React from "react";
import {
  Alert,
  Button,
  Dropdown,
  Icon,
  IconButton,
  Loader,
  Popover,
} from "rsuite";
import "./index.scss";
import Settings from "../../../icons/Settings";
import parser from "cron-parser";
import { useHistory } from "react-router-dom";
import { APP_PATH } from "../../../inc/constants";
import { components } from "../../../types/openapi";
import EditorialIssueCreateModal from "./EditorialIssueCreateModal";
import axios from "axios";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { DATE_TIME_FORMAT, localeFormat } from "../../../inc/date";
import { I18nContext } from "../../../provider/I18nProvider";
import useEditorialRubrics from "../../../hooks/useEditorialRubrics";
import useEditorials from "../../../hooks/useEditorials";
import useEditorialRubricMediaItems from "../../../hooks/useEditorialRubricMediaItems";
import MwWhisper from "../../../components/MwWhisper";

interface IEditorialItemProps {
  editorial: components["schemas"]["Editorial"];
}

const EditorialItem = (props: IEditorialItemProps) => {
  const { editorial } = props;
  const { setEditorials, setEditorialRubricMediaItems } =
    React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const editorials = useEditorials();
  const editorialRubrics = useEditorialRubrics();
  const editorialRubricMediaItems = useEditorialRubricMediaItems();

  const [showModal, setShowModal] = React.useState<boolean>(false);
  const history = useHistory();

  const onEditorialDelete = React.useCallback(() => {
    if (!window.confirm(t("editorialDeleteConfirmation"))) {
      return;
    }
    axios.delete(`/editorial/crud/${editorial.editorialId}`).then(() => {
      const newEditorials = { ...editorials };
      // @ts-ignore
      delete newEditorials[editorial.editorialId];
      setEditorials(newEditorials);
      Alert.success(t("deleteSuccessful"));
    });
  }, [editorial.editorialId, editorials, setEditorials, t]);

  const editorialEditorialRubricIds = React.useMemo(
    () =>
      editorialRubrics
        ? Object.values(editorialRubrics)
            .filter(
              (editorialRubric) =>
                editorialRubric.editorialId === editorial.editorialId
            )
            .map((editorialRubric) => editorialRubric.editorialRubricId)
        : [],
    [editorial.editorialId, editorialRubrics]
  );

  const editorialEditorialRubricMediaItems = React.useMemo(
    () =>
      editorialRubricMediaItems
        ? Object.values(editorialRubricMediaItems).filter(
            (editorialRubricMediaItem) =>
              editorialEditorialRubricIds.indexOf(
                editorialRubricMediaItem.editorialRubricId
              ) >= 0
          )
        : [],
    [editorialEditorialRubricIds, editorialRubricMediaItems]
  );

  const onDeleteEditorialEditorialRubricMediaItems = React.useCallback(() => {
    if (
      window.confirm(t("areYourSureDeleteEditorialEditorialRubricMediaItems"))
    ) {
      editorialEditorialRubricIds.forEach((editorialEditorialRubricId) => {
        axios.request({
          method: "post",
          url: `/editorialRubric/clear/${editorialEditorialRubricId}`,
        });
      });
      setEditorialRubricMediaItems((editorialRubricMediaItems) => {
        if (!editorialRubricMediaItems) {
          return editorialRubricMediaItems;
        }
        const newEditorialRubricMediaItems = {
          ...editorialRubricMediaItems,
        };
        editorialEditorialRubricMediaItems.forEach(
          (editorialEditorialRubricMediaItem) => {
            delete newEditorialRubricMediaItems[
              editorialEditorialRubricMediaItem.editorialRubricMediaItemId as string
            ];
          }
        );
        return newEditorialRubricMediaItems;
      });
    }
  }, [
    editorialEditorialRubricIds,
    editorialEditorialRubricMediaItems,
    setEditorialRubricMediaItems,
    t,
  ]);

  if (!editorialRubricMediaItems || !editorialRubrics) {
    return <Loader />;
  }

  return (
    <div className="editorial-item">
      <div className="editorial-item__top-bar">
        <h3 className="editorial-item__top-bar__title">{t("editorial")}</h3>
        <MwWhisper
          trigger="active"
          placement="bottom"
          speaker={
            <Popover
              full
              className="popover-without-arrow editorial-item__top-bar__popover"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onSelect={() =>
                    history.push(
                      `${APP_PATH}/editorials/overview/${editorial.editorialId}`
                    )
                  }
                >
                  {t("edit")}
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={onDeleteEditorialEditorialRubricMediaItems}
                  disabled={!editorialEditorialRubricMediaItems.length}
                >
                  {t("deleteEditorialEditorialRubricMediaItems")}
                </Dropdown.Item>
                <Dropdown.Item onSelect={onEditorialDelete}>
                  {t("deleteEditorial")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Popover>
          }
        >
          <IconButton
            className="editorial-item__top-bar__settings-button"
            ripple={false}
            icon={
              <Icon
                style={{ color: "#e68c91" }}
                icon="ellipsis-h"
                componentClass={Settings}
              />
            }
          />
        </MwWhisper>
      </div>
      <h4 className="editorial-item__intro">{editorial.name}</h4>
      {editorial.cron ? (
        <div>
          <Button
            color="blue"
            appearance="ghost"
            className="text-lowercase editorial-item__button"
            disabled
          >
            {t("automatically")} ({editorialEditorialRubricMediaItems.length})
          </Button>
          <small className="editorial-item__new-edition-date">
            {t(
              "nextEdition",
              localeFormat(
                parser.parseExpression(editorial.cron).next().toDate(),
                DATE_TIME_FORMAT
              )
            )}
          </small>
        </div>
      ) : (
        <div>
          {showModal ? (
            <EditorialIssueCreateModal
              onClose={() => setShowModal(false)}
              editorialId={editorial.editorialId as string}
            />
          ) : null}
          <Button
            color="blue"
            appearance="primary"
            className="text-lowercase editorial-item__button editorial-item__button--manual"
            onClick={() => setShowModal(true)}
          >
            {t("createAndSend")} ({editorialEditorialRubricMediaItems.length})
          </Button>
        </div>
      )}
    </div>
  );
};
export default EditorialItem;
