import React from "react";
import { components } from "../../types/openapi";
import LabelTag from "../LabelTag";
import { Icon, IconButton } from "rsuite";
import Prullie from "../../icons/Prullie";
import "./index.scss";

interface ILabelAutomatorTagProps {
  labelAutomator: components["schemas"]["LabelAutomator"];
  label?: components["schemas"]["Label"];
  onEditClick: (labelAutomatorId: string) => void;
  onDeleteClick: (labelAutomatorId: string) => void;
}

const LabelAutomatorTag = ({
  labelAutomator,
  label,
  onEditClick,
  onDeleteClick,
}: ILabelAutomatorTagProps) => (
  <span
    className="label-automator-tag"
    onClick={() => onEditClick(labelAutomator.labelAutomatorId as string)}
  >
    {label ? (
      <LabelTag
        className="me-3"
        rounded
        color={"green"}
        size="lg"
        label={label}
      />
    ) : null}
    <IconButton
      appearance="subtle"
      size="sm"
      icon={<Icon icon="trash" componentClass={Prullie} />}
      onClick={(e:any) => {
        e.preventDefault();
        e.stopPropagation();
        onDeleteClick(labelAutomator.labelAutomatorId as string);
      }}
    />
  </span>
);
export default LabelAutomatorTag;
