import React from "react";

import { Col, FlexboxGrid } from "rsuite";
import { APP_PATH } from "../../inc/constants";
import "./index.scss";
import { Link } from "react-router-dom";
import { I18nContext } from "../../provider/I18nProvider";

const NotFoundView = () => {
  const { t } = React.useContext(I18nContext);
  return (
    <FlexboxGrid className="notFoundView" align="middle" justify="center">
      <FlexboxGrid.Item componentClass={Col} colspan={8} xsHidden smHidden>
        <img
          className="notFoundView__image"
          alt="'Page not found'"
          src="/img/art/error/error.png"
          srcSet="/img/art/error/error@2x.png 2x, /img/art/error/error@3x.png 3x"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} xs={24} sm={18} md={8} mdPush={2}>
        <h1 className="notFoundView__title">{t("notFoundViewTitle")}</h1>
        <p>
          De pagina bestaat niet. Je kunt <Link to={APP_PATH}>hier</Link>{" "}
          teruggaan naar je dashboard
        </p>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default NotFoundView;
