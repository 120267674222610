import React, { SVGProps } from "react";

const MediaTypeWeb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14.191}
    height={14.191}
    viewBox="0 0 14.191 14.191"
    fill="currentColor"
    {...props}
  >
    <path
      data-name="Path 1949"
      d="M13.966 8.836a6.984 6.984 0 00-1.128-5.891.494.494 0 00-.069-.093 7.059 7.059 0 00-7.867-2.5.5.5 0 00-.164.06A7.1 7.1 0 000 7.096c0 .16.014.317.024.475a.494.494 0 00.018.281 7.09 7.09 0 0013.9 1.095.441.441 0 00.026-.073.122.122 0 01-.002-.038zm-.888-.612a5.723 5.723 0 01-2.174-.591 8.086 8.086 0 01.6-.846c.2-.258.341-.429.448-.554a1.142 1.142 0 00.382-.782c0-.7-.659-1.109-1.143-1.407l-.078-.048a2.147 2.147 0 011.049-.28 6.034 6.034 0 01.917 4.508zM4.325 1.676a1.219 1.219 0 00.3.875 1.253 1.253 0 00.944.348 1.569 1.569 0 01.664.138 1.553 1.553 0 01.694.715 1.93 1.93 0 01-1.04 2.1 2.053 2.053 0 01-1.325-.07 1.805 1.805 0 00-1.782.154 1.515 1.515 0 00-.471.871c-.052.211-.079.309-.186.4a1.353 1.353 0 01-1.106.089c0-.065-.01-.129-.01-.2a6.093 6.093 0 013.318-5.419zm.426 11.039a6.067 6.067 0 01.113-1.593c.062.059.127.129.18.184a1.689 1.689 0 001.4.731c.657-.09.986-.734 1.222-1.254a3.309 3.309 0 001.158 1.987c.057.04.121.07.182.1a6.05 6.05 0 01-4.253-.16zm5.667-.522a.49.49 0 00-.057 0 1.562 1.562 0 01-.964-.241 2.59 2.59 0 01-.792-1.533c-.135-.52-.274-1.057-.8-1.11-.547-.053-.791.484-1 .959-.1.227-.339.759-.492.78-.105.019-.4-.285-.534-.429-.305-.32-.685-.719-1.193-.58-.547.149-.8.786-.831 2.144a6.106 6.106 0 01-2.619-3.852 3.325 3.325 0 00.432.033 1.809 1.809 0 001.207-.393 1.583 1.583 0 00.512-.927c.051-.205.075-.283.152-.349.141-.121.384-.074.868.055a2.94 2.94 0 001.965.029 2.894 2.894 0 001.6-3.374A2.422 2.422 0 005.627 1.9a1.211 1.211 0 01-.262-.029c-.029-.032-.071-.21.035-.618a6.057 6.057 0 016.018 1.55A1.924 1.924 0 009.94 3.897c-.109.488.337.762.731 1 .154.095.606.372.66.519-.031.041-.087.106-.137.166-.115.135-.272.319-.484.6-.6.789-.873 1.15-.821 1.6.08.681.909 1.089 2.924 1.417a6.117 6.117 0 01-2.395 2.995z"
    />
  </svg>
);

export default MediaTypeWeb;
