import React from "react";
import { IDashboardWidgetProps } from "../index";
import { Checkbox, Col, Grid, Icon, Loader, Panel, Row } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import "./index.scss";
import axios from "../../../inc/axios";
import { components } from "../../../types/openapi";
import { subDays, subYears } from "date-fns";
import _, { sum } from "lodash";
import { compact, currency, formatInt } from "../../../inc/numbers";
import {
  DATE_FORMAT,
  differenceInDays,
  getComparePeriodDateRange,
  getPeriodDateRange,
  localeFormat,
  TDateRange,
} from "../../../inc/date";
import TrendTooltip from "./TrendTooltip";
import { ESentiment, sentimentOptions } from "../../../inc/sentimentOptions";
import { BootstrapSize } from "../../../inc/constants";
import { I18nContext } from "../../../provider/I18nProvider";
import MwWhisper from "../../../components/MwWhisper";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";

enum EHighlightWidgetItemType {
  "AVE" = "AVE",
  "MESSAGES" = "MESSAGES",
  "PR" = "PR",
  "REACH" = "REACH",
  "SENTIMENT" = "SENTIMENT",
}

export interface IHighlightWidgetSettings {
  itemTypes?: EHighlightWidgetItemType[];
  showTrend?: boolean;
}

interface IHighlightItem {
  label: string;
  title?: string;
  value: string | React.ReactElement;
  trend?: {
    direction: "up" | "down" | "neutral";
    summary: number;
    description: string;
  };
}

type IAnalysisItem = {
  date: string;
  values?: number[];
};

const initialItemTypes: EHighlightWidgetItemType[] = [
  EHighlightWidgetItemType.MESSAGES,
  EHighlightWidgetItemType.REACH,
  EHighlightWidgetItemType.AVE,
  EHighlightWidgetItemType.SENTIMENT,
];

const HightlightWidget = ({
  colWidth,
  compareFilterCacheResponse,
  comparePeriod,
  filterCacheResponse,
  filter,
  onDelete,
  onSettingsChange,
  onSettingsToggle,
  period,
  settings = {},
  uid,
  width,
}: IDashboardWidgetProps<IHighlightWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);

  const { showTrend = true, itemTypes = initialItemTypes } = settings;

  let variant: "square" | "wide" | "xwide" = "square";
  if (colWidth === 2 && width > BootstrapSize.SM) {
    variant = "wide";
  }
  if (colWidth === 3 && width > 935) {
    variant = "xwide";
  }

  const [countAnalysis, setCountAnalysis] =
    React.useState<components["schemas"]["Analysis"]>();
  const [aveAnalysis, setAveAnalysis] =
    React.useState<components["schemas"]["Analysis"]>();
  const [prAnalysis, setPrAnalysis] =
    React.useState<components["schemas"]["Analysis"]>();
  const [reachAnalysis, setReachAnalysis] =
    React.useState<components["schemas"]["Analysis"]>();
  const [
    widgetComparePeriodCountAnalysis,
    setWidgetComparePeriodCountAnalysis,
  ] = React.useState<components["schemas"]["Analysis"]>();
  const [widgetComparePeriodAveAnalysis, setWidgetComparePeriodAveAnalysis] =
    React.useState<components["schemas"]["Analysis"]>();
  const [widgetComparePeriodPrAnalysis, setWidgetComparePeriodPrAnalysis] =
    React.useState<components["schemas"]["Analysis"]>();
  const [
    widgetComparePeriodReachAnalysis,
    setWidgetComparePeriodReachAnalysis,
  ] = React.useState<components["schemas"]["Analysis"]>();
  const [
    previousPeriodFilterCacheResponse,
    setPreviousPeriodFilterCacheResponse,
  ] = React.useState<components["schemas"]["FilterCacheResponse"] | null>();

  const dateType = period?.dateType || "insertDate";

  const filterDates = React.useMemo<TDateRange>(
    () => (period ? getPeriodDateRange(period) : []),
    [period],
  );

  const previousPeriod: components["schemas"]["Period"] | null =
    React.useMemo(() => {
      if (!filterDates) {
        return null;
      }
      const [startDate, endDate] = filterDates;
      if (
        !endDate ||
        !startDate ||
        !period?.periodType ||
        period.periodType === "all"
      ) {
        return null;
      }

      if (period.periodType === "yearToDate") {
        return {
          ...period,
          periodType: "custom" as components["schemas"]["Period"]["periodType"],
          startDate: subYears(startDate, 1).toISOString(),
          endDate: subYears(endDate, 1).toISOString(),
        };
      }

      const numOfDays = differenceInDays(endDate, startDate);
      const previousEndDate = subDays(startDate, 1);
      return {
        ...period,
        periodType: "custom" as components["schemas"]["Period"]["periodType"],
        startDate: subDays(previousEndDate, numOfDays).toISOString(),
        endDate: previousEndDate.toISOString(),
      };
    }, [filterDates, period]);

  React.useEffect(() => {
    if (!previousPeriod || comparePeriod) {
      setPreviousPeriodFilterCacheResponse(undefined);
      return;
    }

    setPreviousPeriodFilterCacheResponse(null);
    axios
      .post<components["schemas"]["FilterCacheResponse"]>("/filter/cache", {
        filter,
        period: previousPeriod,
      })
      .then((res) => {
        setPreviousPeriodFilterCacheResponse(res.data);
      });
    /* Every search, widgets are unmounted & remounted. Only on mount, this data should be hydrated! */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparePeriod]);

  const widgetTargetPeriodDateRange = React.useMemo(
    () =>
      comparePeriod
        ? period
          ? getComparePeriodDateRange(comparePeriod, period)
          : []
        : previousPeriod
        ? getPeriodDateRange(previousPeriod)
        : [],
    [comparePeriod, period, previousPeriod],
  );

  const widgetTargetToken = React.useMemo(
    () =>
      comparePeriod
        ? compareFilterCacheResponse?.token
        : previousPeriodFilterCacheResponse?.token,
    [
      compareFilterCacheResponse?.token,
      comparePeriod,
      previousPeriodFilterCacheResponse?.token,
    ],
  );

  React.useEffect(() => {
    const token = filterCacheResponse?.token;
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${token}/count/sentiment/day/${dateType}`,
      )
      .then((res) => setCountAnalysis(res.data));
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${token}/summedAve/sentiment/day/${dateType}`,
      )
      .then((res) => setAveAnalysis(res.data));
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${token}/summedPrValue/sentiment/day/${dateType}`,
      )
      .then((res) => setPrAnalysis(res.data));
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${token}/summedReach/sentiment/day/${dateType}`,
      )
      .then((res) => setReachAnalysis(res.data));
  }, [dateType, filterCacheResponse]);

  React.useEffect(() => {
    if (!widgetTargetToken) {
      setWidgetComparePeriodCountAnalysis(undefined);
      setWidgetComparePeriodAveAnalysis(undefined);
      setWidgetComparePeriodPrAnalysis(undefined);
      setWidgetComparePeriodReachAnalysis(undefined);
      return;
    }

    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${widgetTargetToken}/count/sentiment/day/${dateType}`,
      )
      .then((res) => setWidgetComparePeriodCountAnalysis(res.data));
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${widgetTargetToken}/summedAve/sentiment/day/${dateType}`,
      )
      .then((res) => setWidgetComparePeriodAveAnalysis(res.data));
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${widgetTargetToken}/summedPrValue/sentiment/day/${dateType}`,
      )
      .then((res) => setWidgetComparePeriodPrAnalysis(res.data));
    axios
      .get<components["schemas"]["Analysis"]>(
        `/analyse/cache/${widgetTargetToken}/summedReach/sentiment/day/${dateType}`,
      )
      .then((res) => setWidgetComparePeriodReachAnalysis(res.data));
  }, [widgetTargetToken, dateType]);

  const getHighlightItem = React.useCallback(
    (
      label: string,
      descriptionTKey: string,
      currentPeriodItems: IAnalysisItem[],
      targetPeriodItems?: IAnalysisItem[],
      renderTitle?: (value: number) => string,
    ): IHighlightItem => {
      let trend;
      const currentPeriodTotal = currentPeriodItems.reduce(
        (carry, item) => carry + (item.values ? sum(item.values) : 0),
        0,
      );
      // Calculate growth based on previous period
      if (
        showTrend &&
        widgetTargetPeriodDateRange.length &&
        !!targetPeriodItems?.length
      ) {
        const widgetTargetPeriodTotal = targetPeriodItems.reduce(
          (carry, item) => carry + (item.values ? sum(item.values) : 0),
          0,
        );
        const trendSummary =
          widgetTargetPeriodTotal === 0
            ? currentPeriodTotal
            : Math.abs(
                ((currentPeriodTotal - widgetTargetPeriodTotal) /
                  widgetTargetPeriodTotal) *
                  100,
              ) || 0;
        trend = {
          direction:
            trendSummary > 0
              ? currentPeriodTotal > widgetTargetPeriodTotal
                ? "up"
                : "down"
              : "neutral",
          summary: widgetTargetPeriodTotal ? trendSummary : NaN,
          description: t(
            descriptionTKey,
            localeFormat(widgetTargetPeriodDateRange[0], DATE_FORMAT),
            localeFormat(widgetTargetPeriodDateRange[1], DATE_FORMAT),
            formatInt(widgetTargetPeriodTotal),
          ),
        };
      }
      return {
        label,
        title: renderTitle
          ? renderTitle(currentPeriodTotal)
          : `${formatInt(currentPeriodTotal)} (${t("noDoubles")})`,
        value: compact(currentPeriodTotal),
        trend,
      } as IHighlightItem;
    },
    [showTrend, t, widgetTargetPeriodDateRange],
  );

  const highlightItems = React.useMemo<IHighlightItem[]>(() => {
    const highlightItems = [];
    if (
      countAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.MESSAGES)
    ) {
      const { items = [] } = countAnalysis || {};
      const widgetTargetPeriodItems = (widgetComparePeriodCountAnalysis || {})
        .items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_messages"),
          "highlightWidget_messagesDescription",
          items,
          widgetTargetPeriodItems,
        ),
      );
    }

    if (
      reachAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.REACH)
    ) {
      const { items = [] } = reachAnalysis || {};
      const widgetTargetPeriodItems = (widgetComparePeriodReachAnalysis || {})
        .items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_reach"),
          "highlightWidget_reachDescription",
          items,
          widgetTargetPeriodItems,
        ),
      );
    }

    if (
      aveAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.AVE)
    ) {
      const { items = [] } = aveAnalysis || {};
      const widgetTargetPeriodItems = (widgetComparePeriodAveAnalysis || {})
        .items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_ave"),
          "highlightWidget_aveDescription",
          items,
          widgetTargetPeriodItems,
          (value) => `${currency(value)}  (${t("noDoubles")})`,
        ),
      );
    }

    if (
      prAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.PR)
    ) {
      const { items = [] } = prAnalysis || {};
      const widgetTargetPeriodItems = (widgetComparePeriodPrAnalysis || {})
        .items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_pr"),
          "highlightWidget_prDescription",
          items,
          widgetTargetPeriodItems,
          (value) => `${currency(value)}  (${t("noDoubles")})`,
        ),
      );
    }

    if (
      countAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.SENTIMENT)
    ) {
      let negativeSentimentArrayIndex = countAnalysis.valueGroup
        ? countAnalysis.valueGroup.indexOf(`${ESentiment.NEGATIVE}`)
        : -1;
      let positiveSentimentArrayIndex = countAnalysis.valueGroup
        ? countAnalysis.valueGroup.indexOf(`${ESentiment.POSITIVE}`)
        : -1;
      const sentimentValues = countAnalysis.items.reduce(
        (prev, { values }) =>
          values
            ? [
                prev[0] +
                  (negativeSentimentArrayIndex >= 0
                    ? values[negativeSentimentArrayIndex]
                    : 0),
                prev[1] +
                  (positiveSentimentArrayIndex >= 0
                    ? values[positiveSentimentArrayIndex]
                    : 0),
              ]
            : prev,
        [0, 0],
      );
      negativeSentimentArrayIndex =
        widgetComparePeriodCountAnalysis &&
        widgetComparePeriodCountAnalysis.valueGroup
          ? widgetComparePeriodCountAnalysis.valueGroup.indexOf(
              `${ESentiment.NEGATIVE}`,
            )
          : -1;
      positiveSentimentArrayIndex =
        widgetComparePeriodCountAnalysis &&
        widgetComparePeriodCountAnalysis.valueGroup
          ? widgetComparePeriodCountAnalysis.valueGroup.indexOf(
              `${ESentiment.POSITIVE}`,
            )
          : -1;
      const previousSentimentValues =
        widgetComparePeriodCountAnalysis?.items?.reduce(
          (prev, { values }) =>
            values
              ? [
                  prev[0] +
                    (negativeSentimentArrayIndex >= 0
                      ? values[negativeSentimentArrayIndex]
                      : 0),
                  prev[1] +
                    (positiveSentimentArrayIndex >= 0
                      ? values[positiveSentimentArrayIndex]
                      : 0),
                ]
              : prev,
          [0, 0],
        );

      // percentage positive
      const newValue =
        (sentimentValues[1] / (sentimentValues[0] + sentimentValues[1])) * 100;
      const oldValue = previousSentimentValues
        ? (previousSentimentValues[1] /
            (previousSentimentValues[0] + previousSentimentValues[1])) *
          100
        : 50;
      const diff = newValue - oldValue;
      const summary = previousSentimentValues ? diff : 0;
      const IconComponent =
        sentimentOptions[
          newValue > 50
            ? ESentiment.POSITIVE
            : // may be NaN e.g. when filtering neutral
            isNaN(newValue)
            ? ESentiment.NEUTRAL
            : ESentiment.NEGATIVE
        ].icon;

      highlightItems.push({
        label: t("highlightWidget_sentiment"),
        title: t(
          "highlightWidget_sentimentDescription",
          formatInt(sentimentValues[1]),
          formatInt(sentimentValues[0]),
          newValue,
        ),
        trend:
          showTrend &&
          widgetTargetPeriodDateRange.length &&
          previousSentimentValues?.length &&
          summary
            ? {
                direction: diff >= 0 ? "up" : "down",
                summary: Math.abs(summary),
                description: t(
                  "highlightWidget_sentimentDescriptionWithDates",
                  localeFormat(widgetTargetPeriodDateRange[0], DATE_FORMAT),
                  localeFormat(widgetTargetPeriodDateRange[1], DATE_FORMAT),
                  formatInt(previousSentimentValues[1]),
                  formatInt(previousSentimentValues[0]),
                  oldValue,
                ),
              }
            : undefined,
        value: (
          // @ts-ignore
          <IconComponent />
        ),
      } as IHighlightItem);
    }

    return highlightItems;
  }, [
    countAnalysis,
    itemTypes,
    reachAnalysis,
    aveAnalysis,
    prAnalysis,
    widgetComparePeriodCountAnalysis,
    getHighlightItem,
    t,
    widgetComparePeriodReachAnalysis,
    widgetComparePeriodAveAnalysis,
    widgetComparePeriodPrAnalysis,
    showTrend,
    widgetTargetPeriodDateRange,
  ]);

  const columns = React.useMemo<IHighlightItem[][]>(() => {
    if (!highlightItems.length) {
      return [];
    }
    switch (variant) {
      // case "small":
      //   columns = _.chunk(highlightItems, 4);
      //   break;

      case "square":
        return _.chunk(highlightItems, 2);

      default:
        return [highlightItems];
    }
  }, [highlightItems, variant]);

  const isLoading = !countAnalysis || !aveAnalysis || !reachAnalysis;

  const panelBody = React.useMemo(() => {
    if (!columns.length) {
      if (isLoading) {
        return <Loader backdrop />;
      }

      return <InsufficientDataBody />;
    }

    const valueStyle: React.CSSProperties = {};
    if (variant === "square" && width < 320) {
      valueStyle.fontSize = 50;
    }

    return (
      <div className="d-flex flex-grow-1">
        <div className="d-flex views__dashboard-view__widgets__highlight-widget__left-column flex-grow-1">
          {columns[0]
            ? columns[0].map(({ label, value, title, trend }, key) => (
                <div
                  className={`views__dashboard-view__widgets__highlight-widget__highlight${
                    key === 0 ? " border-0 ps-0 ms-0" : ""
                  }`}
                  key={label}
                >
                  <div className="views__dashboard-view__widgets__highlight-widget__highlight-title">
                    {label}
                  </div>

                  <div className="views__dashboard-view__widgets__highlight-widget__highlight-body">
                    <span
                      className="views__dashboard-view__widgets__highlight-widget__highlight-body__value"
                      title={title}
                      style={valueStyle}
                    >
                      {value}
                    </span>
                    {trend ? (
                      <MwWhisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <TrendTooltip description={trend.description} />
                        }
                      >
                        <span
                          className={`views__dashboard-view__widgets__highlight-widget__highlight-body__trend views__dashboard-view__widgets__highlight-widget__highlight-body__trend--${trend.direction}`}
                          style={{ minWidth: 60, textAlign: "center" }}
                        >
                          {trend.direction === "neutral" ? null : (
                            <Icon
                              icon={
                                trend.direction === "up"
                                  ? "sort-up"
                                  : "sort-desc"
                              }
                            />
                          )}
                          {isFinite(trend.summary)
                            ? `${trend.summary.toFixed(1)}%`
                            : trend.direction === "neutral"
                            ? "—"
                            : null}
                        </span>
                      </MwWhisper>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
        {columns[1] ? (
          <div
            className={`ms-3 ps-3 flex-grow-1 border-left d-flex flex-column views__dashboard-view__widgets__highlight-widget__right-column justify-content-${
              variant === "square" ? "between" : "center"
            }`}
          >
            {columns[1].map(({ label, value, title, trend }) => (
              <div
                className="views__dashboard-view__widgets__highlight-widget__highlight"
                key={label}
              >
                <div className="views__dashboard-view__widgets__highlight-widget__highlight-title">
                  {label}
                </div>
                <div className="views__dashboard-view__widgets__highlight-widget__highlight-body">
                  <span
                    className="views__dashboard-view__widgets__highlight-widget__highlight-body__value"
                    title={title}
                    style={valueStyle}
                  >
                    {value}
                  </span>
                </div>
                {trend ? (
                  <div>
                    <MwWhisper
                      placement="top"
                      trigger="hover"
                      speaker={<TrendTooltip description={trend.description} />}
                    >
                      <span
                        className={`align-items-start views__dashboard-view__widgets__highlight-widget__highlight-body__trend views__dashboard-view__widgets__highlight-widget__highlight-body__trend--${trend.direction}`}
                        style={{ float: "left" }}
                      >
                        {trend.direction === "neutral" ? null : (
                          <Icon
                            icon={
                              trend.direction === "up" ? "sort-up" : "sort-desc"
                            }
                          />
                        )}
                        {`${trend.summary.toFixed(1)}%`}
                      </span>
                    </MwWhisper>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }, [columns, isLoading, variant, width]);

  const onShowHighlightWidgetItemTypeChange = React.useCallback(
    (highlightWidgetItemType: EHighlightWidgetItemType, isVisible: boolean) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        itemTypes: isVisible
          ? [...itemTypes, highlightWidgetItemType]
          : itemTypes.filter((item) => item !== highlightWidgetItemType),
      });
    },
    [itemTypes, onSettingsChange, settings, uid],
  );

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        title={t("Highlights")}
        onDelete={onDelete}
        allowImageDownload={!!onSettingsChange}
        onSettingsToggle={onSettingsToggle}
        popoverForm={
          onSettingsChange && settings ? (
            <Grid fluid className="widgets-popover-form">
              <Row>
                <Col xs={24}>
                  <h3>{t("visibleHighlightWidgetItemTypes")}</h3>
                  {Object.keys(EHighlightWidgetItemType).map((itemType) => {
                    const checked = itemTypes.includes(
                      itemType as EHighlightWidgetItemType,
                    );
                    return (
                      <Checkbox
                        key={itemType}
                        value={itemType}
                        onChange={onShowHighlightWidgetItemTypeChange}
                        checked={checked}
                        disabled={
                          (checked && itemTypes.length < 2) ||
                          (!checked && itemTypes.length > 3)
                        }
                      >
                        {t(`showHighlightWidgetItemType_${itemType}`)}
                      </Checkbox>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <hr />
                  <Checkbox
                    name="showTrend"
                    checked={showTrend}
                    onChange={(_: unknown, checked) =>
                      onSettingsChange(uid, {
                        ...settings,
                        showTrend: checked,
                      })
                    }
                  >
                    {t("showTrend")}
                  </Checkbox>
                </Col>
              </Row>
            </Grid>
          ) : undefined
        }
      />
    ),
    [
      itemTypes,
      onDelete,
      onSettingsChange,
      onSettingsToggle,
      onShowHighlightWidgetItemTypeChange,
      settings,
      showTrend,
      t,
      uid,
    ],
  );

  return (
    <Panel
      bordered={true}
      header={header}
      className={`views__dashboard-view__widgets__highlight-widget views__dashboard-view__widgets__highlight-widget--${variant}`}
    >
      {panelBody}
    </Panel>
  );
};

export default HightlightWidget;
