import React from "react";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import LabelTag from "../../components/LabelTag";
import { Alert, Icon, Input, InputGroup } from "rsuite";
import LabelModal from "./LabelModal";
import "./index.scss";
import ActionButton from "../../components/ActionButton";
import LabelAutomatorModal from "./LabelAutomatorModal";
import { components } from "../../types/openapi";
import LabelAutomatorTag from "../../components/LabelAutomatorTag";
import { I18nContext } from "../../provider/I18nProvider";
import LabelAutomatorDeleteModal from "../../components/LabelAutomatorDeleteModal";
import axios, { AxiosError } from "axios";
import useLabels from "../../hooks/useLabels";
import useLabelAutomators from "../../hooks/useLabelAutomators";
import { generateAndDownload } from "../../inc/excel";
import Export from "../../icons/Export";
import { IMaartenError } from "../../types";

type TLabelAutomatorWithLabel = components["schemas"]["LabelAutomator"] & {
  label?: components["schemas"]["Label"];
};

const LabelsView = () => {
  const { t } = React.useContext(I18nContext);
  const { setLabelAutomators } = React.useContext(ApiDataContext);
  const labels = useLabels();
  const labelAutomators = useLabelAutomators();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [selectedLabel, setSelectedLabel] = React.useState<
    string | undefined
  >();
  const [selectedLabelAutomatorId, setSelectedLabelAutomatorId] =
    React.useState<string | undefined>();
  const [selectedDeleteLabelAutomatorId, setSelectedDeleteLabelAutomatorId] =
    React.useState<string | undefined>();

  const valueMatchesSearchQuery = React.useCallback(
    (value: string) => value.toLowerCase().includes(searchQuery.toLowerCase()),
    [searchQuery],
  );

  const filteredLabels = React.useMemo(
    () =>
      Object.values(labels || {})
        .filter((label) => valueMatchesSearchQuery(label.name))
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    [labels, valueMatchesSearchQuery],
  );

  const labelAutomatorsWithLabels = React.useMemo<
    TLabelAutomatorWithLabel[]
  >(() => {
    return labelAutomators
      ? Object.values(labelAutomators).map((labelAutomator) => {
          return {
            ...labelAutomator,
            label:
              labels && labelAutomator.labelId
                ? labels[labelAutomator.labelId]
                : undefined,
          };
        })
      : [];
  }, [labelAutomators, labels]);

  const filteredLabelAutomatorsWithLabels = React.useMemo<
    TLabelAutomatorWithLabel[]
  >(() => {
    if (!labelAutomatorsWithLabels.length) {
      return [];
    }

    return labelAutomatorsWithLabels
      ? labelAutomatorsWithLabels.filter((labelAutomators) => {
          if (valueMatchesSearchQuery(labelAutomators.title)) {
            return true;
          }
          if (labelAutomators.label?.name) {
            return valueMatchesSearchQuery(labelAutomators.label.name);
          }
          return false;
        })
      : [];
  }, [labelAutomatorsWithLabels, valueMatchesSearchQuery]);

  const onExportClick = React.useCallback(() => {
    if (!labels) {
      return;
    }
    generateAndDownload(
      "Labels",
      [{ header: "Label", key: "name", width: 50 }],
      Object.values(labels).map((label) => ({
        ...label,
      })),
    );
  }, [labels]);

  const ListItem = (props: { children: React.ReactNode }) => (
    <li className="labels-view__list__item">{props.children}</li>
  );

  return (
    <div className="labels-view">
      <div className="row mb-3 mb-md-4 mb-lg-5">
        <div className="col col-md-6 offset-2 offset-sm-1 offset-md-0">
          <InputGroup size="sm" inside className="schema-table__topbar__search">
            <InputGroup.Addon>
              <Icon icon="search" />
            </InputGroup.Addon>
            <Input
              placeholder={`${t("search")}...`}
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
            />
          </InputGroup>
        </div>
        <ActionButton
          icon={<Icon icon="export" componentClass={Export} />}
          onClick={onExportClick}
          title={t("exportAction")}
          className="ms-auto"
        />
      </div>
      <h3>{t("labels")}</h3>
      <ul className="labels-view__list">
        {filteredLabels.length ? (
          filteredLabels.map((label) => (
            <ListItem key={label.labelId}>
              <LabelTag
                rounded
                color="green"
                size="lg"
                onClick={() => setSelectedLabel(label.labelId)}
                label={label}
              />
            </ListItem>
          ))
        ) : (
          <ListItem>{t("noLabelsFound")}</ListItem>
        )}
        <ListItem>
          <ActionButton
            className="labels-view__add-button"
            onClick={() => setSelectedLabel("NEW")}
          />
        </ListItem>
        {selectedLabel ? (
          <LabelModal
            labelId={selectedLabel}
            onClose={() => setSelectedLabel(undefined)}
          />
        ) : null}
      </ul>
      <hr />
      <h3>{t("labelAutomator")}</h3>
      <ul className="labels-view__list">
        {filteredLabelAutomatorsWithLabels.length ? (
          filteredLabelAutomatorsWithLabels.map((labelAutomator) => (
            <ListItem key={labelAutomator.labelAutomatorId}>
              <LabelAutomatorTag
                labelAutomator={labelAutomator}
                label={labelAutomator.label}
                onEditClick={setSelectedLabelAutomatorId}
                onDeleteClick={setSelectedDeleteLabelAutomatorId}
              />
            </ListItem>
          ))
        ) : (
          <ListItem>{t("noLabelAutomatorsFound")}</ListItem>
        )}
        <LabelAutomatorModal
          show={!!selectedLabelAutomatorId}
          onClose={() => setSelectedLabelAutomatorId(undefined)}
          labelAutomatorId={selectedLabelAutomatorId}
        />
        <LabelAutomatorDeleteModal
          show={!!selectedDeleteLabelAutomatorId}
          onConfirm={() => {
            if (!selectedDeleteLabelAutomatorId) {
              return;
            }
            // fire and forget this request: it may be slow!
            axios
              .delete(`/labelAutomator/crud/${selectedDeleteLabelAutomatorId}`)
              .catch((err: AxiosError<IMaartenError>) => {
                Alert.error(
                  t(err.response?.data.error || "genericErrorMessage"),
                );
              });
            const newLabelAutomators: any = { ...labelAutomators };
            delete newLabelAutomators[selectedDeleteLabelAutomatorId];
            setLabelAutomators(newLabelAutomators);
            setSelectedDeleteLabelAutomatorId(undefined);
          }}
          onCancel={() => {
            setSelectedDeleteLabelAutomatorId(undefined);
          }}
        />
        <ListItem>
          <ActionButton
            className="labels-view__add-button"
            onClick={() => setSelectedLabelAutomatorId("NEW")}
          />
        </ListItem>
      </ul>
      <hr />
    </div>
  );
};

export default LabelsView;
