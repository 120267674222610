import React from "react";
import SchemaTable from "../../components/SchemaTable";
import { components } from "../../types/openapi";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import ActionButton from "../../components/ActionButton";
import { I18nContext } from "../../provider/I18nProvider";
import { Icon, Input, InputGroup } from "rsuite";
import CopyToClipboard from "../../components/CopyToClipboard";
import CopyLink from "../../icons/CopyLink";
import ContentExportModal from "./ContentExportModal";
import useContentExports from "../../hooks/useContentExports";
import { AuthContext } from "../../provider/AuthProvider";

const contentExportSchema = openapi.components.schemas
  .ContentExport as oas30.SchemaObject;

const ContentView = () => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const contentExports = useContentExports();
  const [selectedExportContentId, setSelectedExportContentId] =
    React.useState<string>();
  const data = React.useMemo(
    () => (contentExports ? Object.values(contentExports) : []),
    [contentExports]
  );

  const isSuperUser = !!auth?.jwt.superUserId;

  return (
    <div className="content-exports-view">
      <ContentExportModal
        show={!!selectedExportContentId}
        onClose={() => setSelectedExportContentId(undefined)}
        contentExportId={selectedExportContentId}
      />
      <SchemaTable<components["schemas"]["ContentExport"]>
        schema={contentExportSchema}
        sortable
        globalSearch
        data={data}
        onRowClick={(rowData) =>
          isSuperUser ? setSelectedExportContentId(rowData.contentExportId) : {}
        }
        columnsConfigs={[
          { name: "contentExportId", hidden: true },
          { name: "filter", hidden: true },
          { name: "name", flexGrow: 1 },
          {
            name: "url",
            flexGrow: 2,
            renderCell: (rowData) =>
              rowData.url ? (
                <>
                  <InputGroup
                    className="content-exports-view__url-input-group"
                    inside
                    size="sm"
                  >
                    <Input value={rowData.url} className="border-0" />
                    <CopyToClipboard value={rowData.url}>
                      <InputGroup.Button style={{ height: 26 }}>
                        <Icon icon="copy" componentClass={CopyLink} />
                      </InputGroup.Button>
                    </CopyToClipboard>
                  </InputGroup>
                </>
              ) : null,
          },
        ]}
      >
        {isSuperUser ? (
          <ActionButton
            onClick={() => setSelectedExportContentId("NEW")}
            title={t("newContentExport")}
          />
        ) : null}
      </SchemaTable>
    </div>
  );
};

export default ContentView;
