import React from "react";
import { Loader, Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { I18nContext } from "../../../provider/I18nProvider";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import {
  DATE_FORMAT_REVERSE,
  daysInRange,
  differenceInDays,
  getPeriodDateRange,
  localeFormat,
} from "../../../inc/date";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subSeconds,
} from "date-fns";
import { EChartType, EIntervalType } from "../../../inc/enums";
import {
  BootstrapSize,
  CHART_COLORS,
  REMAINING_AUTHOR_NAME,
} from "../../../inc/constants";
import { currency, formatInt } from "../../../inc/numbers";
import { useBenchmarkAnalysisCache } from "../inc/useBenchmarkAnalysisCache";
import {
  EBenchmarkSplitType,
  EValueType,
  IBenchmarkWidgetProps,
  TBenchmarkHashData,
} from "../";
import { IHashMap } from "../../../inc/data";
import { components } from "../../../types/openapi";
import { sum, upperFirst } from "lodash";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { useHistory } from "react-router-dom";
import PopoverForm from "../inc/PopoverForm";
import CustomLegend from "../../dashboardWidgets/inc/CustomLegend";
import { getSortedBenchmarkAnalysisItemKeys } from "../inc/default";
import { useBenchmarkHashData } from "../../../views/BenchmarkView/inc/hooks";
import resolvedOpenApi from "../../../inc/schema";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";
import "./index.scss";

interface IBenchmarkAnalysisItem {
  date: string;
  values: number[];
}

export interface ITrendBenchmarkWidgetSettings {
  chartType?: EChartType.curved | EChartType.line | EChartType.column;
  interval?: EIntervalType;
  limit: number;
  valueType?: EValueType;
  splitType?: EBenchmarkSplitType;
  customValueGroups?: string[];
  notes?: string;
}

const TrendBenchmarkWidget = ({
  benchmark,
  onDelete,
  height,
  onNotesChange,
  onSettingsChange,
  settings,
  uid,
  width,
}: IBenchmarkWidgetProps<ITrendBenchmarkWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);
  const { windowOuterWidth } = React.useContext(LayoutContext);

  const locationHashData = useBenchmarkHashData();
  const history = useHistory();

  const defaultSettings = React.useMemo(
    () => ({
      chartType: EChartType.curved,
      interval: EIntervalType.auto,
      splitType: EBenchmarkSplitType.none,
      valueType: EValueType.count,
    }),
    [],
  );
  const {
    chartType,
    customValueGroups,
    interval,
    limit,
    splitType,
    valueType,
  } = {
    ...defaultSettings,
    ...settings,
  };

  const hasCustomLegend =
    benchmark.matchTexts.length === 1 &&
    [
      EBenchmarkSplitType.none,
      EBenchmarkSplitType.web,
      EBenchmarkSplitType.print,
    ].indexOf(splitType) === -1;
  const per = hasCustomLegend ? splitType : "mediaType";

  const widgetPeriod = React.useMemo<components["schemas"]["Period"]>(
    () => ({
      dateType: "publicationDate",
      periodType: benchmark.searchEngineRequest.periodType,
      startDate: benchmark.searchEngineRequest.startDate,
      endDate: benchmark.searchEngineRequest.endDate,
    }),
    [
      benchmark.searchEngineRequest.endDate,
      benchmark.searchEngineRequest.periodType,
      benchmark.searchEngineRequest.startDate,
    ],
  );

  const filterPeriodDayCount = React.useMemo(() => {
    switch (widgetPeriod.periodType) {
      case "thisDay":
        return 1;

      case "lastWeek":
        return 7;

      case "lastMonth":
        return 31;

      case "lastQuarter":
        return 90;

      case "yearToDate":
        return differenceInDays(new Date(), startOfYear(new Date()));

      case "custom":
        return widgetPeriod?.endDate && widgetPeriod?.startDate
          ? differenceInDays(
              new Date(widgetPeriod.endDate),
              new Date(widgetPeriod.startDate),
            )
          : 365 * 8;

      default:
      case "all":
        return 365 * 8; // 8 years
    }
  }, [widgetPeriod.endDate, widgetPeriod.periodType, widgetPeriod.startDate]);

  const finalInterval = React.useMemo(() => {
    if (interval !== EIntervalType.auto) {
      return interval;
    }
    let period: "day" | "week" | "month" = "day";
    if (filterPeriodDayCount >= 35) {
      period = "week";
    }
    if (filterPeriodDayCount >= 150) {
      period = "month";
    }
    return period;
  }, [filterPeriodDayCount, interval]);

  const benchmarkAnalysis = useBenchmarkAnalysisCache(
    `/benchmark/analyse/${valueType}/${per}/${finalInterval}/publicationDate`,
    benchmark,
  );

  const { startDate, endDate } = React.useMemo<{
    startDate?: Date;
    endDate?: Date;
  }>(() => {
    const timestamps: number[] = [];
    benchmarkAnalysis?.items.forEach((item: any) => {
      timestamps.push(Number(new Date(item.date)));
    });
    if (!timestamps?.length) {
      return {};
    }
    let startDate = new Date(Math.min(...timestamps));
    let endDate = new Date(Math.max(...timestamps));
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return {};
    }

    switch (finalInterval) {
      case EIntervalType.week:
        startDate = startOfWeek(startDate);
        break;

      case EIntervalType.month:
        startDate = startOfMonth(startDate);
        break;

      case EIntervalType.quarter:
        startDate = startOfQuarter(startDate);
        break;

      case EIntervalType.year:
        startDate = startOfYear(startDate);
        break;
    }
    return { startDate, endDate };
  }, [benchmarkAnalysis?.items, finalInterval]);

  const getDataPointEndDate = React.useCallback(
    (startDate: Date) => {
      switch (finalInterval) {
        case "day":
          return subSeconds(addDays(startDate, 1), 1);

        case "week":
          return subSeconds(startOfWeek(addWeeks(startDate, 1)), 1);

        case "month":
          return subSeconds(startOfMonth(addMonths(startDate, 1)), 1);

        case "quarter":
          return subSeconds(startOfQuarter(addQuarters(startDate, 1)), 1);

        case "year":
          return subSeconds(startOfYear(addYears(startDate, 1)), 1);
      }
      return startDate;
    },
    [finalInterval],
  );

  const xAxisDates = React.useMemo(() => {
    let startAndEndDates = getPeriodDateRange(widgetPeriod);

    // e.g. period "all"
    if (!startAndEndDates.length) {
      const timestamps = benchmarkAnalysis?.items.map((item) =>
        Number(new Date(item.date)),
      );
      if (!timestamps?.length) {
        return [];
      }
      startAndEndDates = [
        new Date(Math.min(...timestamps)),
        new Date(Math.max(...timestamps)),
      ];
    }

    switch (finalInterval) {
      case "week":
        startAndEndDates[0] = startOfWeek(startAndEndDates[0]);
        break;

      case "month":
        startAndEndDates[0] = startOfMonth(startAndEndDates[0]);
        break;

      case "quarter":
        startAndEndDates[0] = startOfQuarter(startAndEndDates[0]);
        break;

      case "year":
        startAndEndDates[0] = startOfYear(startAndEndDates[0]);
        break;
    }

    const [startDate, endDate] = startAndEndDates;
    return daysInRange(startDate, endDate, finalInterval);
  }, [widgetPeriod, finalInterval, benchmarkAnalysis?.items]);

  const sortedBenchmarkAnalysisItemKeys = React.useMemo(
    () =>
      benchmarkAnalysis
        ? getSortedBenchmarkAnalysisItemKeys(benchmarkAnalysis)
        : [],
    [benchmarkAnalysis],
  );

  const series = React.useMemo(() => {
    if (!benchmarkAnalysis) {
      return null;
    }

    const { items, valueGroup } = benchmarkAnalysis;

    // custom valueGroups!
    if (hasCustomLegend) {
      const itemMap = items.reduce<IHashMap<IBenchmarkAnalysisItem>>(
        (prev, item) => {
          prev[item.date] = item as IBenchmarkAnalysisItem;
          return prev;
        },
        {},
      );

      if (!limit && customValueGroups) {
        return customValueGroups.map((customValueGroup) => {
          const key = valueGroup.findIndex((x) => x === customValueGroup);
          return {
            name: customValueGroup,
            data: xAxisDates.map((date) => {
              const item = itemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
              return {
                x: date,
                y: key >= 0 && item?.values ? item.values[key] : 0,
              };
            }),
          };
        });
      }

      return sortedBenchmarkAnalysisItemKeys
        .filter((key) => valueGroup[key] !== REMAINING_AUTHOR_NAME)
        .map((key) => ({
          name: valueGroup[key],
          data: xAxisDates.map((date) => {
            const item = itemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
            return {
              x: date,
              y: item?.values ? item.values[key] : 0,
            };
          }),
        }))
        .slice(0, limit)
        .filter((serie) => !!serie.data.find(({ y }) => y > 0));
    }

    return benchmark.matchTexts.map((matchText) => {
      const splitIndex =
        splitType === EBenchmarkSplitType.none
          ? -1
          : valueGroup.findIndex((valueGroup) => valueGroup === splitType);
      const itemMap = items.reduce<
        IHashMap<components["schemas"]["BenchmarkAnalysisItem"]>
      >((prev, item) => {
        if (item.benchmarkName === matchText.name) {
          prev[item.date] = {
            ...item,
            values: splitIndex >= 0 ? [item.values[splitIndex]] : item.values,
          };
        }
        return prev;
      }, {});
      return {
        name: matchText.name,
        data: xAxisDates.map((date) => {
          const item = itemMap[localeFormat(date, DATE_FORMAT_REVERSE)];
          if (splitType) {
          }
          return {
            x: date,
            y: item?.values ? sum(item.values) : 0,
          };
        }),
      };
    });
  }, [
    benchmark.matchTexts,
    benchmarkAnalysis,
    customValueGroups,
    hasCustomLegend,
    limit,
    sortedBenchmarkAnalysisItemKeys,
    splitType,
    xAxisDates,
  ]);

  const onMarkerClick = React.useCallback(
    (_event: any, _chartContext: any, config: any) => {
      if (!series) {
        console.log("No series?");
        return;
      }
      const { seriesIndex, dataPointIndex } = config;

      const { matchTexts, searchEngineRequest } = benchmark;

      const hashData: TBenchmarkHashData = {
        matchTexts,
        searchEngineRequest: {
          ...searchEngineRequest,
          periodType: "custom",
          startDate: format(
            series[seriesIndex].data[dataPointIndex].x,
            DATE_FORMAT_REVERSE,
          ),
          endDate: format(
            getDataPointEndDate(series[seriesIndex].data[dataPointIndex].x),
            DATE_FORMAT_REVERSE,
          ),
        },
        sort:
          locationHashData?.sort ||
          resolvedOpenApi.components.schemas.MediaItemSortType.default,
      };
      history.push(
        `${history.location.pathname}#${encodeURIComponent(
          JSON.stringify(hashData),
        )}`,
      );
    },
    [benchmark, getDataPointEndDate, history, locationHashData?.sort, series],
  );

  const chartOptions = React.useMemo<ApexOptions>(() => {
    const isMultiYear =
      startDate && endDate && startDate.getFullYear() !== endDate.getFullYear();
    return {
      chart: {
        stacked: false,
        fontFamily: "Gelion, sans-serif",
        toolbar: { show: false },
        zoom: { enabled: false },
        dropShadow: {
          enabled: true,
          top: 12,
          left: 0,
          blur: 10,
          opacity: 0.16,
        },
        events:
          windowOuterWidth > BootstrapSize.LG
            ? {
                markerClick: onMarkerClick,
                // mouseMove: onMouseMove,
              }
            : {},
      },
      colors: CHART_COLORS,
      dataLabels: {
        enabled: false,
        dropShadow: { enabled: false },
        style: { fontWeight: "normal" },
      },
      markers: {
        size: 1,
        strokeColors: CHART_COLORS,
      },
      plotOptions: {},
      yaxis: {
        labels: {
          formatter: (val: number) =>
            ["ave", "summedAve"].indexOf(valueType) >= 0
              ? currency(val, 0)
              : formatInt(val),
          style: { cssClass: "apexcharts-label--yaxis" },
          offsetX: -10,
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
        min: 0,
        tickAmount: 6,
      },
      xaxis: {
        labels: {
          trim: false,
          rotate: 0,
          hideOverlappingLabels: false,
          style: { cssClass: "apexcharts-label--xaxis" },
          formatter: (value?: string) => {
            if (!value) {
              return "";
            }
            const date = new Date(value);
            switch (finalInterval) {
              case EIntervalType.week:
                return localeFormat(date, isMultiYear ? "ww yyyy" : "ww");

              case EIntervalType.month:
                return localeFormat(
                  date,
                  // doesn't work with single quote
                  isMultiYear ? 'MMM  "yy' : "MMMM",
                ).replace('"', "'");

              // case EIntervalType.quarter:
              //   return `Q${localeFormat(date, isMultiYear ? "Q yyyy" : "Q")}`;
              //
              // case EIntervalType.year:
              //   return localeFormat(date, "yyyy");

              default:
                return localeFormat(date, "dd MMM");
            }
          },
        },
        axisBorder: { show: false },
        axisTicks: { show: false },
        crosshairs: {
          opacity: 1,
          position: "back",
          stroke: { color: "#2d50b4", width: 2 },
          fill: { type: "solid", color: "#2d50b4" },
        },
        tickAmount: 6,
        tickPlacement: "on",
      },
      states: { active: { filter: { type: "none" } } },
      stroke: {
        curve: chartType === EChartType.curved ? "smooth" : "straight",
      },
      grid: {
        yaxis: { lines: { show: false } },
        row: { colors: ["#f2f2f2", "#fff"] },
      },
      labels: [],
      legend: {
        showForSingleSeries: false,
        horizontalAlign: "left",
        show: !hasCustomLegend,
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex }) => {
          return benchmark.matchTexts[seriesIndex]
            ? `<div class="arrow_box arrow_box--bottom"><span>${
                benchmark.matchTexts[seriesIndex].name
              }: ${formatInt(series[seriesIndex][dataPointIndex])}</span></div>`
            : null;
        },
      },
    };
  }, [
    benchmark.matchTexts,
    chartType,
    endDate,
    finalInterval,
    hasCustomLegend,
    onMarkerClick,
    startDate,
    valueType,
    windowOuterWidth,
  ]);

  const popoverForm = React.useMemo(
    () =>
      onSettingsChange ? (
        <PopoverForm
          onSettingsChange={onSettingsChange}
          settings={
            {
              ...defaultSettings,
              ...settings,
            } as ITrendBenchmarkWidgetSettings
          }
          showIntervalOption
          chartTypes={{ curved: true, line: true, column: true }}
          showRemainingOption={false}
          splitTypes={
            benchmark.matchTexts.length === 1
              ? [EBenchmarkSplitType.category, EBenchmarkSplitType.author]
              : undefined
          }
          topResultOptions={
            [EBenchmarkSplitType.category, EBenchmarkSplitType.author].includes(
              splitType,
            )
              ? [3, 5, 0]
              : undefined
          }
          widgetUid={uid}
        />
      ) : undefined,
    [
      benchmark.matchTexts.length,
      defaultSettings,
      onSettingsChange,
      settings,
      splitType,
      uid,
    ],
  );

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        allowImageDownload
        notes={settings?.notes}
        onDelete={onDelete}
        onNotesChange={onNotesChange}
        popoverForm={popoverForm}
        subtitle={`${t(`valueType_${valueType}`)}${
          splitType === EBenchmarkSplitType.none
            ? ""
            : `-  (${upperFirst(splitType)})`
        }`}
        title={`${t("WIDGET_TYPE_TREND")}`}
      />
    ),
    [
      onDelete,
      onNotesChange,
      popoverForm,
      settings?.notes,
      splitType,
      t,
      valueType,
    ],
  );

  const onCustomLegendChange = React.useCallback(
    (customValueGroups: any) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        limit: 0,
        customValueGroups,
      });
    },
    [onSettingsChange, settings, uid],
  );

  const chartWidth = Math.floor(0.96 * width);
  const chartHeight = height - 105;
  const dataFound =
    series &&
    series.length &&
    series.find((serie) => !!serie.data.find((row) => row.y));

  return (
    <Panel
      bordered
      header={header}
      className={`views__benchmark-view__widgets__trend-widget views__benchmark-view__widgets__trend-widget--${chartType}`}
    >
      {series ? (
        !dataFound ? (
          <InsufficientDataBody />
        ) : (
          <div
            className="chart"
            style={{
              width: chartWidth,
              height: chartHeight,
              overflow: "hidden",
            }}
          >
            <Chart
              key={`${chartType}_${splitType}`}
              type={chartType === EChartType.column ? "bar" : "line"}
              series={series}
              options={chartOptions}
              width={chartWidth}
              height={chartHeight}
            />
            {benchmarkAnalysis && hasCustomLegend ? (
              <CustomLegend
                colors={chartOptions.colors as string[]}
                options={benchmarkAnalysis.valueGroup}
                value={series.map((serie) => serie.name)}
                onChange={onCustomLegendChange}
              />
            ) : null}
          </div>
        )
      ) : (
        <div style={{ width }}>
          <Loader backdrop />
        </div>
      )}
    </Panel>
  );
};

export default TrendBenchmarkWidget;
