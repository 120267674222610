import React, { CSSProperties } from "react";
import { TSentimentOption } from "../../../inc/sentimentOptions";
import { I18nContext } from "../../../provider/I18nProvider";

interface ISentimentButtonProps {
  active: boolean;
  onClick: (value: number) => void;
  sentimentOption: TSentimentOption;
  style?: CSSProperties | undefined;
}

const SentimentButton = (props: ISentimentButtonProps) => {
  const { t } = React.useContext(I18nContext);
  const { active, onClick, sentimentOption } = props;
  const SentimentComponent = sentimentOption.icon as any;
  const label = t(sentimentOption.label);
  return (
    <div
      onClick={() => onClick(active ? 0 : sentimentOption.value)}
      style={props.style}
    >
      <SentimentComponent
        width={35}
        height={35}
        fill={active ? sentimentOption.color : "#96afaf"}
      />
      <br />
      {label}
    </div>
  );
};
export default SentimentButton;
