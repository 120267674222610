import React, { Ref } from "react";
import { components } from "../../../../types/openapi";
import { Button, Checkbox, FlexboxGrid, Icon } from "rsuite";
import ItemBody from "./ItemBody";
import { getSearchEngineResultIcon } from "../../../../icons";
import BuyPopover from "../BuyPopover";
import { DATE_FORMAT, localeFormat } from "../../../../inc/date";
import Slider from "../../../../components/Slider";
import useSearchTopics from "../../../../hooks/useSearchTopics";
import { AuthContext } from "../../../../provider/AuthProvider";

interface ISearchEngineResultItemProps {
  colspan: number;
  display: "compact" | "block";
  hideButton: boolean;
  hideCheckbox?: boolean;
  hideSearchTopics?: boolean;
  innerRef: Ref<HTMLDivElement>;
  isSelected: boolean;
  onClick: (
    searchEngineResult: components["schemas"]["SearchEngineResult"],
  ) => void;
  onSetSelected: (
    searchEngineResult: components["schemas"]["SearchEngineResult"],
    isSelected: boolean,
  ) => void;
  searchEngineResult: components["schemas"]["SearchEngineResult"];
  searchEngineResultDetails:
    | components["schemas"]["SearchEngineResultDetails"]
    | null;
  width: number;
}

const SearchEngineResultItem = ({
  colspan,
  display,
  hideButton,
  hideCheckbox,
  hideSearchTopics,
  innerRef,
  isSelected,
  searchEngineResult,
  searchEngineResultDetails,
  onClick,
  onSetSelected,
  width,
}: ISearchEngineResultItemProps) => {
  const { auth } = React.useContext(AuthContext);
  const searchTopics = useSearchTopics();

  let size: "xs" | "md" | "lg" = "xs";
  if (width >= 400) {
    size = "md";
  }
  if (width >= 850) {
    size = "lg";
  }

  const toggleSelected = React.useCallback(
    () => onSetSelected(searchEngineResult, !isSelected),
    [isSelected, onSetSelected, searchEngineResult],
  );

  return (
    <FlexboxGrid.Item colspan={colspan}>
      <div
        ref={innerRef}
        className={`views__search-view__widgets__mediaitem-widget__mediaitem views__search-view__widgets__mediaitem-widget__mediaitem--${display} views__search-view__widgets__mediaitem-widget__mediaitem--${size}`}
      >
        {hideCheckbox ? null : (
          <div className="views__search-view__widgets__mediaitem-widget__mediaitem-action-bar">
            <div
              style={{
                flex: 1,
                height: !!searchEngineResultDetails?.mediaItemId ? 35 : "unset",
              }}
            >
              {!!searchEngineResultDetails?.mediaItemId ? null : (
                <Checkbox
                  style={{ marginTop: -1, marginLeft: -20 }}
                  checked={isSelected}
                  onChange={toggleSelected}
                />
              )}
            </div>
          </div>
        )}
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => {
            onClick(searchEngineResult);
          }}
        >
          <div className="views__search-view__widgets__mediaitem-widget__image">
            <img
              src={searchEngineResult.imageUrl}
              alt={searchEngineResult.headline}
              style={{ maxWidth: "100%", marginBottom: 10 }}
            />
          </div>
          <div className="views__search-view__widgets__mediaitem-widget__content">
            <div className="views__search-view__widgets__mediaitem-widget__meta-data views__search-view__widgets__mediaitem-widget__meta-data--top">
              <div>
                <span className={"ps-0"}>
                  {localeFormat(
                    new Date(searchEngineResult.publicationDate),
                    DATE_FORMAT,
                  )}
                </span>
              </div>
              <div className="views__search-view__widgets__mediaitem-widget__meta-data__source">
                {getSearchEngineResultIcon(searchEngineResult)}
                <span>{searchEngineResult.publicationName}</span>
              </div>
            </div>
            {size !== "xs" ? (
              <ItemBody searchEngineResult={searchEngineResult} />
            ) : null}
          </div>
        </div>
        {size === "xs" ? (
          <ItemBody searchEngineResult={searchEngineResult} />
        ) : null}
        {!hideSearchTopics && searchEngineResultDetails?.mediaItemId ? (
          <div className="mt-2">
            {searchTopics ? (
              <Slider>
                {(searchEngineResultDetails?.searchTopicIds || []).map(
                  (searchTopicId) => {
                    const searchTopic = searchTopics[searchTopicId] as
                      | components["schemas"]["SearchTopic"]
                      | undefined;
                    return (
                      <div
                        key={searchTopicId}
                        className="views__search-view__widgets__mediaitem-widget__search-topic"
                      >
                        <Icon
                          icon={searchTopic?.active ? "circle" : "circle-o"}
                          style={{ fontSize: 10 }}
                        />
                        {searchTopic ? searchTopic.name : "* ONBEKEND *"}
                      </div>
                    );
                  },
                )}
              </Slider>
            ) : null}
          </div>
        ) : hideButton ? null : (
          <div>
            {searchEngineResult.articles[0].pdfUrl && auth?.bearer ? (
              <Button
                onClick={() => {
                  window.open(
                    `${searchEngineResult.articles[0].pdfUrl}?bearer=${auth.bearer}`,
                  );
                }}
              >
                Openen
              </Button>
            ) : (
              <BuyPopover
                searchEngineResultIds={[
                  searchEngineResult.searchEngineResultId as string,
                ]}
              />
            )}
          </div>
        )}
      </div>
    </FlexboxGrid.Item>
  );
};

export default React.memo(SearchEngineResultItem);
