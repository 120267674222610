import React, { SVGProps } from "react";

const Logout = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.996 15.741"
      fill="currentColor"
      {...props}
      width={20}
      height={16}
    >
      <path
        id="prefix__Path_1979"
        data-name="Path 1979"
        d="M1596.505 1101h-4.255a2.929 2.929 0 01-2.927-2.926v-9.889a2.929 2.929 0 012.927-2.926h4.255a2.93 2.93 0 012.927 2.926.688.688 0 11-1.375 0 1.554 1.554 0 00-1.551-1.552h-4.255a1.553 1.553 0 00-1.551 1.552v9.889a1.553 1.553 0 001.551 1.551h4.255a1.553 1.553 0 001.551-1.551.688.688 0 111.375 0 2.929 2.929 0 01-2.927 2.926z"
        transform="translate(-1589.324 -1085.258)"
      />
      <path
        id="prefix__Path_1980"
        data-name="Path 1980"
        d="M1612.975 1101.657h-11.341a.688.688 0 110-1.375h11.341a.688.688 0 110 1.375z"
        transform="translate(-1595.172 -1092.817)"
      />
      <path
        id="prefix__Path_1981"
        data-name="Path 1981"
        d="M1617.85 1101.6a.687.687 0 01-.486-1.173l4.3-4.3-4.3-4.3a.688.688 0 01.972-.972l4.789 4.789a.687.687 0 010 .973l-4.789 4.789a.683.683 0 01-.486.194z"
        transform="translate(-1603.33 -1087.967)"
      />
    </svg>
  );
};

export default Logout;
