import { Dropdown, Icon, Nav } from "rsuite";
import React from "react";
import { TNavItem, INavConfig } from "../useUserNavConfig";
import { Link, useLocation } from "react-router-dom";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { BootstrapSize } from "../../../inc/constants";
import "./index.scss";

interface INavItemsProps {
  navItems: TNavItem[];
}

export const NavIcon = (navConfig: INavConfig) => {
  if (!navConfig.icon && !navConfig.svg) {
    return null;
  }
  return navConfig.icon ? (
    <Icon
      className="nav-icon"
      icon={navConfig.icon}
      style={navConfig.iconProps?.style}
    />
  ) : (
    <Icon
      {...navConfig.iconProps}
      className="nav-icon"
      icon="question"
      componentClass={navConfig.svg}
      width={25}
      height={20}
    />
  );
};

const NavItems = (props: INavItemsProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const location = useLocation();
  return (
    <div className="layout__app-layout__nav-items">
      {props.navItems.map((navItem, index) => {
        if (navItem === "-") {
          return (
            <li key="-">
              <hr style={{ margin: 20 }} />
            </li>
          );
        }
        if (navItem.component) {
          const NavItemComponent = navItem.component;
          return (
            <NavItemComponent
              key={navItem.title}
              to={`${navItem.to}`}
              title={navItem.title}
              iconComponent={navItem.svg}
            />
          );
        }
        return windowOuterWidth < BootstrapSize.MD && navItem.children ? (
          <Dropdown
            eventKey={index}
            className={`rs-nav-item nav-item ${
              navItem.to && location.pathname.startsWith(navItem.to)
                ? "rs-nav-item-active"
                : ""
            }`}
            key={navItem.to}
            title={navItem.title}
            icon={<NavIcon {...navItem} />}
            toggleClassName="rs-nav-item-content"
            style={{ zIndex: 1 }}
            disabled={navItem.disabled}
          >
            {navItem.children.map((child, key) => {
              if (child === "-") {
                return <Dropdown.Item divider key={key} />;
              }
              return (
                <Dropdown.Item
                  key={key}
                  eventKey={child.title}
                  active={
                    !!child.to && window.location.pathname.endsWith(child.to)
                  }
                  icon={
                    <NavIcon
                      {...child}
                      iconProps={{
                        ...child.iconProps,
                        color1: "#fdfdfd",
                        color2: "#fdfdfd",
                      }}
                    />
                  }
                  componentClass={child.disabled ? "a" : Link}
                  to={!child.disabled && child.to ? child.to : undefined}
                  onSelect={
                    !child.disabled && child.onClick ? child.onClick : undefined
                  }
                  disabled={child.disabled}
                >
                  <span className="nav-item__title">{child.title}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        ) : (
          <Nav.Item
            className={`nav-item${
              navItem.className ? ` ${navItem.className}` : ""
            }`}
            key={navItem.title}
            active={
              !!navItem.to && window.location.pathname.startsWith(navItem.to)
            }
            icon={
              <NavIcon
                {...navItem}
                iconProps={{
                  ...navItem.iconProps,
                  color1: "#1e384e",
                  color2: "#1e384e",
                }}
              />
            }
            componentClass={navItem.disabled ? "a" : Link}
            to={!navItem.disabled && navItem.to ? navItem.to : "#"}
            onSelect={
              !navItem.disabled && navItem.onClick ? navItem.onClick : undefined
            }
            disabled={navItem.disabled}
          >
            <span className="nav-item__title">{navItem.title}</span>
          </Nav.Item>
        );
      })}
    </div>
  );
};
export default NavItems;
