import React, { SVGProps } from "react";

const WidgetTypeTrend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44.453}
    height={25.405}
    viewBox="0 0 44.453 25.405"
    {...props}
  >
    <defs>
      <style>
        {
          ".svg-icons__widget-type-trend{fill:none;stroke-linecap:round;stroke:#9baeaf;stroke-width:2px}"
        }
      </style>
    </defs>
    <g id="prefix__Group_1424" transform="translate(-130 -687)">
      <path
        id="prefix__Path_10"
        fill="none"
        stroke="#da9093"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M172.345 698.773c-4.775-5.995-6.171-10.273-10.872-10.273-6.231.075-5.709 10.6-13.223 10.6s-7.861-9.531-16.75-9.531"
      />
      <path
        id="prefix__Path_1674"
        d="M0 0h3.693"
        className="svg-icons__widget-type-trend"
        transform="rotate(-90 421.624 289.781)"
      />
      <path
        id="prefix__Path_1675"
        d="M0 0h3.693"
        className="svg-icons__widget-type-trend"
        transform="rotate(-90 425.624 285.781)"
      />
      <path
        id="prefix__Path_1676"
        d="M0 0h3.693"
        className="svg-icons__widget-type-trend"
        transform="rotate(-90 429.624 281.781)"
      />
      <path
        id="prefix__Path_1677"
        d="M0 0h3.693"
        className="svg-icons__widget-type-trend"
        transform="rotate(-90 433.624 277.781)"
      />
      <path
        id="prefix__Path_1678"
        d="M0 0h3.693"
        className="svg-icons__widget-type-trend"
        transform="rotate(-90 437.624 273.781)"
      />
      <path
        id="prefix__Path_1679"
        d="M0 0h3.693"
        className="svg-icons__widget-type-trend"
        transform="rotate(-90 441.624 269.781)"
      />
    </g>
  </svg>
);

export default WidgetTypeTrend;
