import React from "react";
import { Button } from "rsuite";
import "./index.scss";

interface ISelectGridProps {
  values: string[];
  data: Array<
    Array<{
      label: string;
      value: string;
    } | null>
  >;
  onChange: (newValues: string[]) => void;
}

const SelectGrid = (props: ISelectGridProps) => {
  const { data, values, onChange } = props;
  return (
    <div className="select-grid">
      {data.map((dataRow, indexRow) => (
        <div key={indexRow} className="select-grid__row">
          {dataRow.map((dataCell, indexCell) => {
            if (!dataCell) {
              return (
                <div
                  key={indexCell}
                  className="rs-btn rs-btn-subtle rs-btn-sm rs-btn-block btn-disabled"
                />
              );
            }
            const isActive = values.includes(dataCell.value);
            return (
              <Button
                key={indexCell}
                appearance={isActive ? "primary" : "subtle"}
                size="sm"
                block
                onClick={() => {
                  if (values.length === 1 && values[0] === "*") {
                    return onChange([dataCell.value]);
                  }
                  return onChange(
                    isActive
                      ? values.filter((value) => value !== dataCell.value)
                      : [...values, dataCell.value],
                  );
                }}
              >
                {dataCell.label}
              </Button>
            );
          })}
        </div>
      ))}
    </div>
  );
};
export default SelectGrid;
