import React from "react";
import "./index.scss";
import { components } from "../../../../types/openapi";
import { Loader } from "rsuite";
import { AuthContext } from "../../../../provider/AuthProvider";

interface IPdfTabProps {
  isLexisNexisContentVisible: boolean;
  lexisNexisView?: components["schemas"]["LexisNexisView"];
  mediaItem?: components["schemas"]["MediaItem"];
  mediaItemView?: components["schemas"]["MediaItemView"];
}

const PdfTab = ({
  isLexisNexisContentVisible,
  lexisNexisView,
  mediaItem,
  mediaItemView,
}: IPdfTabProps) => {
  const { auth } = React.useContext(AuthContext);
  if (!mediaItemView) {
    return <Loader />;
  }
  return (
    <div className="components__media-item__pdf-tab">
      <iframe
        style={{ display: isLexisNexisContentVisible ? "none" : "block" }}
        title={mediaItem ? mediaItem.title : "Artikel"}
        src={`${mediaItemView.pdfUrl}${auth ? `?bearer=${auth.bearer}` : ""}`}
      />
      {lexisNexisView && lexisNexisView.pdfUrls.length ? (
        <iframe
          style={{ display: isLexisNexisContentVisible ? "block" : "none" }}
          title={mediaItem ? mediaItem.title : "Artikel"}
          src={lexisNexisView.pdfUrls[0]}
        />
      ) : null}
    </div>
  );
};

export default PdfTab;
