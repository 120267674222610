import openapi from "../openapi.json";
import _ from "lodash";
import { ErrorObject } from "ajv";
import { TSchemaFormErrors } from "../components/SchemaFormBody";
import { oas30 } from "openapi3-ts";

export function getEmptyObject<T>(schema: oas30.SchemaObject): T {
  const { properties = {}, required = [] } = resolve(schema);
  return Object.keys(properties).reduce((prev, propName) => {
    const prop = properties[propName] as oas30.SchemaObject;
    if (required.indexOf(propName) === -1) {
      return prev;
    }
    let propValue: any;
    switch (prop.type) {
      case "string":
        propValue = prop.default || "";
        break;

      case "array":
        propValue = prop.default || [];
        break;

      case "number":
      case "integer":
        propValue = prop.default || 0;
        break;

      case "boolean":
        propValue = prop.default || false;
        break;

      case "object":
        propValue = prop.default || getEmptyObject(prop);
        break;

      // eslint-disable-next-line no-fallthrough
      default:
        console.log(prop);
        throw new Error("Unsupported property");
    }

    // @ts-ignore
    prev[propName] = propValue;
    return prev;
  }, {}) as T;
}

function resolve(
  schema: oas30.SchemaObject | oas30.ReferenceObject,
): oas30.SchemaObject {
  const { $ref } = schema as oas30.ReferenceObject;
  if ($ref) {
    // e.g. #/components/schemas/Role
    const path = $ref.substr(2).split("/");
    let result: any = openapi;
    let pointer = path.shift();
    while (pointer) {
      result = result[pointer];
      pointer = path.shift();
    }
    return resolve(result);
  }
  const { properties } = schema as oas30.SchemaObject;

  if (properties) {
    return {
      ...schema,
      properties: Object.keys(properties).reduce<any>((prev, propName) => {
        prev[propName] = resolve(properties[propName]);
        return prev;
      }, {}),
    };
  }

  return schema as oas30.SchemaObject;
}

export function getReferencedSchemaName(propName: string): string | null {
  const endsWithIds = propName.endsWith("Ids");
  if (!endsWithIds && !propName.endsWith("Id")) {
    return null;
  }
  return propName === "customerLinkIds"
    ? "Customer"
    : _.upperFirst(propName.substr(0, propName.length - (endsWithIds ? 3 : 2)));
}

export function getReferencedSchema(
  referencedSchemaName: string,
): oas30.SchemaObject | undefined {
  if (!referencedSchemaName) {
    return undefined;
  }
  referencedSchemaName = _.upperFirst(referencedSchemaName);
  return referencedSchemaName === "CustomerLink"
    ? openapi.components.schemas.Customer
    : (openapi.components.schemas as any)[referencedSchemaName];
}

export function focusError() {
  const domEl = document.querySelector(".rs-help-block");
  if (domEl && domEl.parentElement) {
    domEl.parentElement.scrollIntoView({ behavior: "smooth" });
    const input = domEl.querySelector("input");
    if (input) {
      input.focus();
    }
  }
}

export function validationErrorsToSchemaFormErrors(
  errors: Array<ErrorObject>,
): TSchemaFormErrors {
  return errors.reduce<TSchemaFormErrors>((prev, error) => {
    // @ts-ignore
    prev[error.instancePath.substr(1)] = error.message as string;
    return prev;
  }, {});
}

const resolvedOpenApi = {
  ...openapi,
  components: {
    ...openapi.components,
    schemas: Object.entries(openapi.components.schemas).reduce<{
      [schema: string]: oas30.SchemaObject;
    }>((prev, [schemaName, schema]) => {
      prev[schemaName] = resolve(schema as oas30.SchemaObject);
      return prev;
    }, {}),
  },
};

export default resolvedOpenApi;
