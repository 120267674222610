import React, { SVGProps } from "react";

const SendEditorial = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name="Verzonden edities"
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={12.821}
      viewBox="0 0 15 12.821"
      fill="currentColor"
      {...props}
    >
      <path
        data-name="Path 2155"
        d="M8.219 12.821a.542.542 0 01-.541-.541V9.441a11.3 11.3 0 00-6.345 3.028.785.785 0 01-.843.187.773.773 0 01-.49-.719c0-6.576 5.634-7.965 7.678-8.251V.541a.541.541 0 01.911-.394l6.241 5.869a.541.541 0 010 .787l-6.241 5.871a.541.541 0 01-.37.147zm.541-11.03v2.376a.541.541 0 01-.494.539c-1.617.141-6.74 1.027-7.157 6.5A11.981 11.981 0 018.2 8.318a.533.533 0 01.4.151.54.54 0 01.166.389v2.166l4.911-4.62z"
      />
    </svg>
  );
};

export default SendEditorial;
