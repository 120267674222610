import { Dropdown, Icon, IconButton } from "rsuite";
import Compass from "../../../icons/Compass";
import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import { components } from "../../../types/openapi";
import { WhisperInstance } from "rsuite/lib/Whisper";

interface ISearchTopicControlProps {
  controlType: "menuItem" | "button";
  disabled: boolean;
  filter: components["schemas"]["Filter"];
  expandAndChange: (filter: components["schemas"]["Filter"]) => void;
  searchTopicWhisperRef: React.RefObject<WhisperInstance>;
}

const SearchTopicControl = ({
  controlType,
  disabled,
  expandAndChange,
  filter,
  searchTopicWhisperRef,
}: ISearchTopicControlProps) => {
  const { t } = React.useContext(I18nContext);

  const onClick = React.useCallback(() => {
    expandAndChange({
      ...filter,
      searchTopicIds: filter.searchTopicIds || [],
    });
    setTimeout(() => {
      if (searchTopicWhisperRef && searchTopicWhisperRef.current) {
        searchTopicWhisperRef.current.open();
      }
    }, 10);
  }, [expandAndChange, filter, searchTopicWhisperRef]);

  return controlType === "button" ? (
    <IconButton
      className={`filter-bar__filters-col__button`}
      appearance="subtle"
      size="xs"
      ripple={false}
      disabled={disabled}
      icon={<Icon icon="question" componentClass={Compass} />}
      onClick={onClick}
    >
      {t("searchQuery")}
    </IconButton>
  ) : (
    <Dropdown.Item
      onSelect={onClick}
      className="components__filter-bar__search-topic-control"
    >
      {t("searchQuery")}
    </Dropdown.Item>
  );
};

export default SearchTopicControl;
