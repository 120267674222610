import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";

interface IInsufficientDataBodyProps {
  description?: string;
}

const InsufficientDataBody = ({ description }: IInsufficientDataBodyProps) => {
  const { t } = React.useContext(I18nContext);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          alignItems: "center",
          flex: "0 0",
        }}
      >
        {description || t("insufficientDataForWidget")}
      </div>
    </div>
  );
};

export default React.memo(InsufficientDataBody);
