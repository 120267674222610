import React from "react";
import { Button, Col, FlexboxGrid, Loader } from "rsuite";
import { LayoutContext } from "../../provider/LayoutProvider";
import useDashboards from "../../hooks/useDashboards";

const DashboardsView = () => {
  const { setIsTemplateDialogOpen } = React.useContext(LayoutContext);
  const dashboards = useDashboards();

  const dashboardIds = dashboards ? Object.keys(dashboards) : undefined;
  if (!dashboardIds) {
    return <Loader />;
  }

  return (
    <div className="h-100 d-flex flex-column justify-content-center">
      <FlexboxGrid
        justify="center"
        className="dashboard-view__content__image-container"
      >
        <FlexboxGrid.Item colspan={24}>
          <h2 className="mt-5">
            {dashboardIds.length
              ? "← Kies een dashboard"
              : "Je hebt nog geen dashboards"}
          </h2>
          <img
            alt="Je hebt nog geen dashboards"
            src="/img/art/lookout/lookout.png"
            srcSet="/img/art/lookout/lookout@2x.png 2x, /img/art/lookout/lookout@3x.png 3x"
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid justify="center" className="dashboard-view__button-grid">
        <FlexboxGrid.Item
          className="dashboard-view__content__button-wrapper text-center"
          componentClass={Col}
          colspan={24}
          sm={12}
          md={10}
        >
          {dashboardIds.length ? null : (
            <Button
              className="dashboard-view__content__button-wrapper__button"
              appearance="primary"
              color="blue"
              size="xs"
              onClick={() => setIsTemplateDialogOpen(true)}
            >
              Maak een nieuw dashboard
            </Button>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
};

export default DashboardsView;
