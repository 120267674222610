import React, { RefObject } from "react";
import { components } from "../../../types/openapi";
import {
  Alert,
  Button,
  Divider,
  Icon,
  Input,
  InputGroup,
  Popover,
  Radio,
  RadioGroup,
  TagGroup,
} from "rsuite";
import MagnifyingGlass from "../../../icons/MagnifyingGlass";
import "../index.scss";
import { containsWordThatStartWith } from "../../../inc/query";
import LabelTag from "../../LabelTag";
import _ from "lodash";
import { I18nContext } from "../../../provider/I18nProvider";
import useLabels from "../../../hooks/useLabels";
import MwWhisper from "../../MwWhisper";

interface ILabelPopoverFilterProps {
  children: React.ReactNode;
  filter: components["schemas"]["Filter"];
  onChange: (filter: components["schemas"]["Filter"]) => void;
}

const LabelPopoverFilter = (props: ILabelPopoverFilterProps) => {
  const { filter, children, onChange } = props;
  const { t } = React.useContext(I18nContext);
  const labelsMap = useLabels();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [activeRadio, setActiveRadio] = React.useState<
    "combine" | "exclude" | undefined
  >();
  const [selectedLabelIds, setSelectedLabelIds] = React.useState<string[]>([]);
  const results = React.useMemo(
    () =>
      containsWordThatStartWith(
        labelsMap ? Object.values(labelsMap) : [],
        "name",
        searchQuery
      ).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    [labelsMap, searchQuery]
  );

  const triggerRef: RefObject<any> = React.useRef();
  const close = React.useCallback(() => {
    // Reset form and close popover
    setSelectedLabelIds([]);
    setActiveRadio(undefined);
    if (triggerRef.current) {
      triggerRef.current.hide();
    }
  }, [triggerRef]);

  const addLabelCombinationToFilter = React.useCallback(
    (labelIds: string[]) => {
      // Show error if this label is already in filter
      if (
        filter.includeLabelIds?.find(
          (includedLabels) =>
            includedLabels.length === labelIds.length &&
            !_.difference(includedLabels, labelIds).length
        )
      ) {
        Alert.info(t("LabelAlreadyInFilter"));
        return;
      }
      onChange({
        ...filter,
        includeLabelIds: [...(filter.includeLabelIds || []), labelIds],
      });
      close();
    },
    [close, filter, onChange, t]
  );

  const addLabelsToFilter = React.useCallback(
    (labelIds: string[]) => {
      let newIncludeLabelIds = [...(filter.includeLabelIds || [])];
      labelIds.forEach((labelId) => {
        if (
          filter.includeLabelIds?.find(
            (includedLabels) =>
              includedLabels.length === [labelId].length &&
              !_.difference(includedLabels, [labelId]).length
          )
        ) {
          Alert.info(t("LabelAlreadyInFilter"));
          return;
        }
        newIncludeLabelIds.push([labelId]);
      });

      onChange({
        ...filter,
        includeLabelIds: newIncludeLabelIds,
      });
      close();
    },
    [close, filter, onChange, t]
  );

  const addExcludeLabelToFilter = React.useCallback(() => {
    // Show error if this label is already excluded in filter
    let newExcludeLabelIds = [...(filter.excludeLabelIds || [])];
    selectedLabelIds.forEach((selectedLabelId) => {
      if (
        filter.excludeLabelIds?.find(
          (excludeLabelId) => excludeLabelId === selectedLabelId
        )
      ) {
        Alert.info(t("LabelAlreadyInFilter"));
        return;
      }
      newExcludeLabelIds.push(selectedLabelId);
    });
    onChange({
      ...filter,
      excludeLabelIds: newExcludeLabelIds,
    });
    close();
  }, [close, filter, onChange, selectedLabelIds, t]);

  const onApply = React.useCallback(() => {
    // on mobile, scroll back to starting pos
    window.scrollTo(0, 0);
    if (activeRadio === "exclude") {
      addExcludeLabelToFilter();
      return;
    }
    if (activeRadio === "combine") {
      addLabelCombinationToFilter(selectedLabelIds);
      return;
    }
    addLabelsToFilter(selectedLabelIds);
  }, [
    activeRadio,
    addExcludeLabelToFilter,
    addLabelCombinationToFilter,
    addLabelsToFilter,
    selectedLabelIds,
  ]);

  const unsetActiveRadio = React.useCallback((e: React.MouseEvent) => {
    if (!unsetActiveRadioRef.current) {
      return;
    }
    e.preventDefault();
    // @ts-ignore
    unsetActiveRadioRef.current.click();
  }, []);

  const unsetActiveRadioRef = React.useRef(null);

  return (
    <MwWhisper
      placement="bottom"
      triggerRef={triggerRef}
      trigger="click"
      speaker={
        <Popover className="popover-without-arrow label-popover">
          <InputGroup className="label-popover__search-input-group" inside>
            <InputGroup.Addon>
              <Icon icon="star" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              className="label-popover__search-input"
              size="sm"
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
              placeholder={t("searchLabel")}
            />
          </InputGroup>
          <Divider />
          <TagGroup className="label-popover__results">
            {results.length ? (
              results.map((result) => (
                <LabelTag
                  key={result.labelId}
                  size="lg"
                  color={
                    selectedLabelIds.includes(result.labelId as string)
                      ? "green"
                      : "cyan"
                  }
                  rounded
                  onClick={() => {
                    // Remove the current label
                    if (selectedLabelIds.includes(result.labelId as string)) {
                      setSelectedLabelIds(
                        selectedLabelIds.filter((x) => x !== result.labelId)
                      );
                      return;
                    }
                    // Add the current label
                    setSelectedLabelIds([
                      ...selectedLabelIds,
                      result.labelId as string,
                    ]);
                  }}
                  label={result}
                />
              ))
            ) : (
              <p>{t("noLabelsFound")}</p>
            )}
          </TagGroup>
          <Divider />
          <div>
            <RadioGroup value={activeRadio} onChange={setActiveRadio}>
              <Radio
                className="d-none"
                value={undefined}
                inputRef={unsetActiveRadioRef}
              />
              <Radio
                value="combine"
                onClick={(e: React.MouseEvent) => {
                  if (activeRadio === "combine") {
                    unsetActiveRadio(e);
                  }
                }}
              >
                {t("combine")}
              </Radio>
              <Radio
                value="exclude"
                onClick={(e: React.MouseEvent) => {
                  if (activeRadio === "exclude") {
                    unsetActiveRadio(e);
                  }
                }}
              >
                {t("excludeFromResults")}
              </Radio>
            </RadioGroup>
          </div>

          {selectedLabelIds.length ? (
            <div className="label-popover__apply">
              <Button
                className="label-popover__apply__button"
                appearance="ghost"
                color="blue"
                onClick={onApply}
              >
                {t("apply")}
              </Button>
            </div>
          ) : null}
        </Popover>
      }
    >
      {children}
    </MwWhisper>
  );
};
export default LabelPopoverFilter;
