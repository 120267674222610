import React, { SVGProps } from "react";

const Templates = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.787}
    height={20.61}
    fill="currentColor"
    {...props}
  >
    <g transform="translate(-50.444 -341.49)">
      <g transform="translate(-1380.16 -477.217)">
        <path
          d="M1438.567 854.343h-6.023a1.942 1.942 0 01-1.939-1.94v-6.023a1.941 1.941 0 011.939-1.94h6.023a1.942 1.942 0 011.94 1.94v6.02a1.942 1.942 0 01-1.94 1.943zm-6.023-8.751a.789.789 0 00-.788.788v6.02a.789.789 0 00.788.789h6.023a.789.789 0 00.788-.789v-6.02a.789.789 0 00-.788-.788z"
          transform="translate(0 -15.026)"
        />
        <path
          d="M1445.128 828.61h-6.028a1.942 1.942 0 01-1.939-1.939v-6.024a1.942 1.942 0 011.939-1.939h6.024a1.942 1.942 0 011.939 1.939v6.024a1.942 1.942 0 01-1.935 1.939zm-6.024-8.751a.788.788 0 00-.788.788v6.024a.788.788 0 00.788.788h6.024a.789.789 0 00.788-.788v-6.024a.788.788 0 00-.788-.788z"
          transform="translate(-3.831)"
        />
        <path
          d="M1464.724 854.343h-6.024a1.942 1.942 0 01-1.94-1.94v-6.023a1.942 1.942 0 011.94-1.94h6.023a1.942 1.942 0 011.94 1.94v6.02a1.942 1.942 0 01-1.939 1.943zm-6.023-8.751a.789.789 0 00-.789.788v6.02a.789.789 0 00.789.789h6.023a.789.789 0 00.788-.789v-6.02a.789.789 0 00-.788-.788z"
          transform="translate(-15.273 -15.026)"
        />
      </g>
    </g>
  </svg>
);

export default Templates;
