import React from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  InputGroup,
  Popover,
} from "rsuite";
import { sortSelectedOnTop } from "../../../../inc/sort";
import MagnifyingGlass from "../../../../icons/MagnifyingGlass";
import "./index.scss";
import { dataContainsWordThatStartWith } from "../../../../inc/query";
import Compass from "../../../../icons/Compass";
import MwTag from "../../../MwTag";
import { I18nContext } from "../../../../provider/I18nProvider";
import useSearchTopics from "../../../../hooks/useSearchTopics";
import MwWhisper from "../../../MwWhisper";

interface ISearchTopicPopoverProps {
  whisperRef?: React.Ref<any>;
  value: string[];
  onChange?: (newValue?: string[]) => void;
}

const SearchTopicPopover = ({
  onChange,
  value,
  whisperRef,
}: ISearchTopicPopoverProps) => {
  const { t } = React.useContext(I18nContext);
  const searchTopics = useSearchTopics();
  const [hasActiveFilter, setHasActiveFilter] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [sortCounter, setSortCounter] = React.useState<number>(0);
  const onToggleInactiveClick = React.useCallback(() => {
    setHasActiveFilter((showInactive) => !showInactive);
  }, []);
  const sortedSearchTopics = React.useMemo(() => {
    return sortSelectedOnTop(
      Object.values(searchTopics || {}).sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        if (nameA === nameB) {
          return 0;
        }
        return nameA > nameB ? 1 : -1;
      }),
      value,
      "searchTopicId"
    );
    // eslint-disable-next-line
  }, [sortCounter, searchTopics]);

  const results = React.useMemo(
    () =>
      sortedSearchTopics.filter((searchTopic) => {
        const isSelected =
          searchTopic.searchTopicId &&
          value.indexOf(searchTopic.searchTopicId) >= 0;
        if (!onChange && !isSelected) {
          return false;
        }
        if (searchQuery) {
          return dataContainsWordThatStartWith(searchTopic.name, searchQuery);
        }
        if (hasActiveFilter) {
          return searchTopic.active || isSelected;
        }
        return true;
      }),
    [sortedSearchTopics, value, onChange, searchQuery, hasActiveFilter]
  );

  const options = React.useMemo(
    () =>
      results.map((searchTopic) => (
        <Checkbox
          disabled={!onChange}
          className={`checkbox-max-width views__dashboard-view__filter-bar__tags-bar__search-topic-popover__item--${
            searchTopic.active ? "active" : "inactive"
          }`}
          key={searchTopic.searchTopicId}
          value={searchTopic.searchTopicId}
        >
          <Icon
            icon={searchTopic.active ? "circle" : "circle-o"}
            style={{ fontSize: 10, marginRight: 4 }}
          />
          {searchTopic.name}
        </Checkbox>
      )),
    [onChange, results]
  );

  return (
    <MwWhisper
      // make sure it fits in Filterbar in modal
      placement="bottomStart"
      trigger="click"
      onClose={
        onChange
          ? () => {
              if (!value.length) {
                onChange();
              }
              setSortCounter(sortCounter + 1);
            }
          : undefined
      }
      ref={whisperRef}
      speaker={
        <Popover className="popover-without-arrow checkbox-popover views__dashboard-view__filter-bar__tags-bar__search-topic-popover">
          <InputGroup inside>
            <InputGroup.Addon>
              <Icon icon="star" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              size="sm"
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
            />
          </InputGroup>
          <div>
            {searchQuery || !onChange ? null : (
              <Button
                block
                className="my-2 px-0"
                onClick={onToggleInactiveClick}
              >
                {hasActiveFilter
                  ? t("inactiveSearchTopics_show")
                  : t("inactiveSearchTopics_hide")}
              </Button>
            )}
            <CheckboxGroup value={value} onChange={onChange}>
              {options}
            </CheckboxGroup>
          </div>
        </Popover>
      }
    >
      <MwTag
        iconComponent={Compass}
        onClose={onChange ? () => onChange() : undefined}
        color="cyan"
        size="lg"
      >
        {t("searchTopicsX", value.length)}
      </MwTag>
    </MwWhisper>
  );
};

export default SearchTopicPopover;
