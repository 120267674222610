import React from "react";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { TSchemaFormErrors } from "../../components/SchemaFormBody";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../inc/schema";
import axios from "../../inc/axios";
import {
  Alert,
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Icon,
  Loader,
  Tooltip,
} from "rsuite";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../inc/ajv";
import { Link, Prompt, useParams } from "react-router-dom";
import "./index.scss";
import CronEditor from "./CronEditor";
import EditorialRubrics from "./EditorialRubrics";
import EditorialSubscribersModal from "./EditorialSubscribersModal";
import { useHistory } from "react-router-dom";
import { APP_PATH } from "../../inc/constants";
import { components } from "../../types/openapi";
import _, { isEqual } from "lodash";
import QuestionMark from "../../icons/QuestionMark";
import { I18nContext } from "../../provider/I18nProvider";
import EditEditorialTour from "./EditEditorialTour";
import useEditorialRubrics from "../../hooks/useEditorialRubrics";
import useEditorials from "../../hooks/useEditorials";
import useEditorialSubscribers from "../../hooks/useEditorialSubscribers";
import MwWhisper from "../../components/MwWhisper";
import { AxiosError } from "axios";
import useSubscribers from "../../hooks/useSubscribers";
import { IHashMap } from "../../inc/data";
import { IMaartenError } from "../../types";

const EditorialSchema = openapi.components.schemas
  .Editorial as oas30.SchemaObject;
const validate = ajv.compile(EditorialSchema);

const { properties } = EditorialSchema;
if (!properties) {
  throw new Error("Incomplete group schema");
}

const getActiveRubrics = (
  editorial: components["schemas"]["Editorial"],
  editorialRubrics: components["schemas"]["EditorialRubric"][],
) =>
  Object.values(editorialRubrics || {})
    .filter(
      (rubric) => editorial && rubric.editorialId === editorial.editorialId,
    )
    .map((editorialRubric) => ({ ...editorialRubric }))
    .sort((a, b) => (a.order < b.order ? -1 : 1));

const EditorialDetailView = () => {
  const { editorialId } = useParams<{ editorialId: string }>();
  const { t } = React.useContext(I18nContext);
  const { setEditorials, setEditorialRubrics, setEditorialSubscribers } =
    React.useContext(ApiDataContext);
  const editorials = useEditorials();
  const editorialRubricMap = useEditorialRubrics();
  const editorialSubscriberMap = useEditorialSubscribers();
  const subscribers = useSubscribers();

  const [newEditorial, setNewEditorial] =
    React.useState<components["schemas"]["Editorial"]>();
  const [errors, setErrors] = React.useState<
    TSchemaFormErrors<
      components["schemas"]["Editorial"] & {
        editorialRubrics: any;
      }
    >
  >({});
  const [showSubscribers, setShowSubscribers] = React.useState<boolean>(false);
  const [
    showEditorialRubricsWithoutFilters,
    setShowEditorialRubricsWithoutFilters,
  ] = React.useState<boolean>(false);
  const [newEditorialRubrics, setNewEditorialRubrics] =
    React.useState<components["schemas"]["EditorialRubric"][]>();
  const [editorialEditorialSubscribers, setEditorialEditorialSubscribers] =
    React.useState<components["schemas"]["EditorialSubscriber"][]>();
  const history = useHistory();

  const editorialRubrics = React.useMemo(
    () => (editorialRubricMap ? Object.values(editorialRubricMap) : null),
    [editorialRubricMap],
  );

  const oldEditorialEditorialSubscribers = React.useMemo<
    components["schemas"]["EditorialSubscriber"][] | null
  >(() => {
    if (!editorialId) {
      return [];
    }
    if (!subscribers || !editorialSubscriberMap) {
      return null;
    }
    const editorialSubscriberList = Object.values(editorialSubscriberMap);
    return Object.values(subscribers).map((subscriber) => {
      const editorialSubscriber = editorialSubscriberList.find(
        (editorialSubscriber) =>
          editorialSubscriber.subscriberId === subscriber.subscriberId &&
          editorialSubscriber.editorialId === editorialId,
      );
      return (
        editorialSubscriber || {
          editorialId,
          subscriberId: subscriber.subscriberId!,
          useApp: false,
          useMail: false,
        }
      );
    });
  }, [editorialId, editorialSubscriberMap, subscribers]);

  React.useEffect(() => {
    setEditorialEditorialSubscribers(oldEditorialEditorialSubscribers || []);
  }, [oldEditorialEditorialSubscribers]);

  React.useEffect(() => {
    if (!editorials || !subscribers) {
      return;
    }
    let oldEditorial: components["schemas"]["Editorial"] | null =
      editorials && editorialId ? editorials[editorialId] : null;
    if (!oldEditorial) {
      oldEditorial = getEmptyObject(
        EditorialSchema,
      ) as components["schemas"]["Editorial"];
    }
    const oldEditorialId = oldEditorial.editorialId;
    if (
      editorialSubscriberMap &&
      editorialRubrics &&
      (!newEditorial || oldEditorialId !== newEditorial.editorialId)
    ) {
      setNewEditorial(JSON.parse(JSON.stringify(oldEditorial)));
      setNewEditorialRubrics(getActiveRubrics(oldEditorial, editorialRubrics));
    }
  }, [
    newEditorial,
    editorialId,
    editorialSubscriberMap,
    editorialRubrics,
    editorials,
    subscribers,
  ]);

  const updateRubrics = React.useCallback(
    (editorial: components["schemas"]["Editorial"]) => {
      if (!editorialRubrics || !newEditorialRubrics) {
        throw new Error(
          "Cannot update rubric when rubrics are not yet hydrated",
        );
      }
      const oldRubrics = getActiveRubrics(editorial, editorialRubrics);
      // update rubric if rubricId === undefined OR if rubric is dirty
      const toBeUpdatedEditorialRubrics = newEditorialRubrics.filter(
        (newEditorialRubric) => {
          if (newEditorialRubric.editorialRubricId === undefined) {
            return true;
          }
          const oldRubric = oldRubrics.find(
            (rubric) =>
              rubric.editorialRubricId === newEditorialRubric.editorialRubricId,
          );
          return oldRubric && !isEqual(oldRubric, newEditorialRubric);
        },
      );
      const toBeRemovedEditorialRubricIds = oldRubrics
        .map((activeRubric) => activeRubric.editorialRubricId)
        .filter(
          (oldRubricId) =>
            !newEditorialRubrics.find(
              (rubric) => rubric.editorialRubricId === oldRubricId,
            ),
        ) as string[];

      toBeUpdatedEditorialRubrics.forEach((toBeUpdatedEditorialRubric) => {
        axios
          .request({
            url: `/editorialRubric/crud${
              toBeUpdatedEditorialRubric.editorialRubricId
                ? `/${toBeUpdatedEditorialRubric.editorialRubricId}`
                : ""
            }`,
            method: toBeUpdatedEditorialRubric.editorialRubricId
              ? "put"
              : "post",
            data: {
              ...toBeUpdatedEditorialRubric,
              editorialId: editorial.editorialId,
            },
          })
          .then((res) => {
            const newEditorialRubric =
              res.data as components["schemas"]["EditorialRubric"];
            setEditorialRubrics((oldEditorialRubrics) => {
              if (!oldEditorialRubrics) {
                return oldEditorialRubrics;
              }
              return {
                ...oldEditorialRubrics,
                [newEditorialRubric.editorialRubricId as string]:
                  newEditorialRubric,
              };
            });
          });
      });

      toBeRemovedEditorialRubricIds.forEach((toBeRemovedEditorialRubricId) => {
        axios
          .delete(`/editorialRubric/crud/${toBeRemovedEditorialRubricId}`)
          .then(() => {
            setEditorialRubrics((oldEditorialRubrics) => {
              const newEditorialRubrics: any = { ...oldEditorialRubrics };
              delete newEditorialRubrics[toBeRemovedEditorialRubricId];
              return newEditorialRubrics;
            });
          });
      });
    },
    [editorialRubrics, newEditorialRubrics, setEditorialRubrics],
  );

  const updateEditorialSubscribers = React.useCallback(
    (editorial: components["schemas"]["Editorial"]) => {
      if (!editorialEditorialSubscribers || !oldEditorialEditorialSubscribers) {
        throw new Error("Cannot update unhydrated editorialSubscribers");
      }
      const newEditorialSubscriberMap: IHashMap<
        components["schemas"]["EditorialSubscriber"]
      > = { ...editorialSubscriberMap };
      return Promise.all(
        editorialEditorialSubscribers.map(
          (newEditorialSubscriber, index): Promise<any> => {
            const oldEditorialSubscriber =
              oldEditorialEditorialSubscribers[index];
            if (
              JSON.stringify(newEditorialSubscriber) ===
              JSON.stringify(oldEditorialSubscriber)
            ) {
              return Promise.resolve();
            }
            return axios
              .request<components["schemas"]["EditorialSubscriber"]>({
                method: newEditorialSubscriber.editorialSubscriberId
                  ? "put"
                  : "post",
                url: `/editorialSubscriber/crud${
                  newEditorialSubscriber.editorialSubscriberId
                    ? `/${newEditorialSubscriber.editorialSubscriberId}`
                    : ""
                }`,
                data: {
                  ...newEditorialSubscriber,
                  editorialId: editorial.editorialId,
                },
              })
              .then((res) => {
                newEditorialSubscriberMap[
                  res.data.editorialSubscriberId as string
                ] = res.data;
              });
          },
        ),
      )
        .then(() => {
          setEditorialSubscribers(newEditorialSubscriberMap);
        })
        .catch((err: AxiosError<IMaartenError>) => {
          if (err.response?.data.error) {
            Alert.error(t(err.response?.data.error));
          }
        });
    },
    [
      editorialEditorialSubscribers,
      editorialSubscriberMap,
      oldEditorialEditorialSubscribers,
      setEditorialSubscribers,
      t,
    ],
  );

  const validateEditorialRubrics = React.useCallback((): TSchemaFormErrors => {
    let newErrors: any = {};
    if (!newEditorialRubrics || newEditorialRubrics.length === 0) {
      newErrors.editorialRubrics = "must NOT have fewer than 1 items";
      return newErrors;
    }
    newEditorialRubrics.forEach((newEditorialRubric, index) => {
      if (newEditorialRubric.name === "") {
        newErrors[`editorialRubrics_${index}`] =
          "must NOT be shorter than 1 characters";
        return;
      }

      // Look for another rubric with the same name
      const nameIsAlreadyUsed =
        newEditorialRubrics.find(
          (rubric, rubricIndex) =>
            index > rubricIndex && rubric.name === newEditorialRubric.name,
        ) !== undefined;
      if (nameIsAlreadyUsed) {
        newErrors[`editorialRubrics_${index}`] =
          "editorialRubricNameIsAlreadyUsed";
      }
    });
    return newErrors;
  }, [newEditorialRubrics]);

  const isFormDirty = React.useMemo(() => {
    const originalEditorial =
      editorials && editorialId ? editorials[editorialId] : null;
    return JSON.stringify(originalEditorial) !== JSON.stringify(newEditorial);
  }, [newEditorial, editorialId, editorials]);

  const isRubricsDirty = React.useMemo(() => {
    if (!newEditorial?.editorialId || !editorialRubrics) {
      return true;
    }
    return (
      JSON.stringify(getActiveRubrics(newEditorial, editorialRubrics)) !==
      JSON.stringify(newEditorialRubrics)
    );
  }, [newEditorial, editorialRubrics, newEditorialRubrics]);

  const isSubscribersDirty = React.useMemo(() => {
    if (!editorialSubscriberMap || !newEditorial?.editorialId) {
      return true;
    }
    return !_.isEqual(
      Object.values(editorialSubscriberMap).filter(
        (editorialSubscriber) =>
          editorialSubscriber.editorialId === newEditorial.editorialId,
      ),
      editorialEditorialSubscribers,
    );
  }, [
    newEditorial?.editorialId,
    editorialSubscriberMap,
    editorialEditorialSubscribers,
  ]);

  const onSubmit = React.useCallback(() => {
    if (
      (!isFormDirty && !isRubricsDirty && !isSubscribersDirty) ||
      !newEditorialRubrics
    ) {
      // Clean!
      return;
    }
    const editorialRubricsErrors = validateEditorialRubrics();
    if (Object.keys(editorialRubricsErrors).length) {
      setErrors(editorialRubricsErrors);
      setTimeout(focusError, 200);
      return;
    }
    if (
      newEditorial?.cron !== undefined &&
      newEditorialRubrics.some(
        (newEditorialRubric) => newEditorialRubric.filter === undefined,
      ) &&
      !window.confirm(t("automaticEditorialHasRubricsWithoutFilters"))
    ) {
      setShowEditorialRubricsWithoutFilters(true);
      return;
    }

    const postUpdateEditorial = (
      editorial: components["schemas"]["Editorial"],
    ) => {
      updateRubrics(editorial);
      updateEditorialSubscribers(editorial);
      history.push({
        pathname: `${APP_PATH}/editorials/overview`,
        state: { submit: true },
      });
    };

    if (!isFormDirty && !!newEditorial?.editorialId) {
      return postUpdateEditorial(newEditorial);
    }

    // Dirty form and (possible) dirty rubrics
    const isValid = validate(newEditorial);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }
    setErrors({});
    const isNew = !(newEditorial && newEditorial.editorialId);
    axios
      .request({
        url: `/editorial/crud${
          newEditorial && newEditorial.editorialId
            ? `/${newEditorial.editorialId}`
            : ""
        }`,
        method: isNew ? "post" : "put",
        data: newEditorial,
      })
      .then((res) => {
        const updatedEditorial = res.data as components["schemas"]["Editorial"];
        setEditorials({
          ...editorials,
          [updatedEditorial.editorialId as string]: updatedEditorial,
        });
        postUpdateEditorial(updatedEditorial);
      })
      .catch((err: AxiosError<IMaartenError>) => {
        Alert.error(t(err.response?.data?.error || "invalidData"));
      });
  }, [
    newEditorial,
    isSubscribersDirty,
    editorials,
    isFormDirty,
    history,
    newEditorialRubrics,
    isRubricsDirty,
    setEditorials,
    t,
    updateEditorialSubscribers,
    updateRubrics,
    validateEditorialRubrics,
  ]);

  if (!editorialEditorialSubscribers || !newEditorialRubrics) {
    return <Loader />;
  }

  return newEditorial ? (
    <>
      <Prompt
        when={isFormDirty || isRubricsDirty || isSubscribersDirty}
        message={(location) => {
          return location.state && (location.state as any).submit
            ? true
            : t("unsavedChangedAreYouSure");
        }}
      />
      <div className="row justify-content-center editorial-detail-view">
        <Form className="col-md-10 col-lg-8 col-xl-6" onSubmit={onSubmit}>
          <FormGroup>
            <ControlLabel className="label">{t("editorialTitle")}</ControlLabel>
            <FormControl
              className="input input-gray mw-100 editorial-detail-view__title-input"
              type="text"
              onChange={(newTitle) =>
                setNewEditorial({ ...newEditorial, name: newTitle })
              }
              value={newEditorial.name}
              placeholder={t("namelessEditorial")}
            />
            {errors && errors["name"] ? (
              <HelpBlock style={{ color: "red" }}>
                {t(errors["name"])}
              </HelpBlock>
            ) : null}
          </FormGroup>
          <FormGroup>
            <ControlLabel className="label">
              {t("defaultSenderName")}
            </ControlLabel>
            <FormControl
              className="input input-gray mw-100"
              type="text"
              onChange={(newSenderName) =>
                setNewEditorial({
                  ...newEditorial,
                  defaultSenderName: newSenderName,
                })
              }
              value={newEditorial.defaultSenderName}
            />
            {errors && errors["defaultSenderName"] ? (
              <HelpBlock style={{ color: "red" }}>
                {t(errors["defaultSenderName"])}
              </HelpBlock>
            ) : null}
          </FormGroup>
          <FormGroup>
            <ControlLabel className="label">{t("defaultSubject")}</ControlLabel>
            <FormControl
              className="input input-gray mw-100"
              type="text"
              onChange={(newSubject) =>
                setNewEditorial({ ...newEditorial, defaultSubject: newSubject })
              }
              value={newEditorial.defaultSubject}
            />
            {errors && errors["defaultSubject"] ? (
              <HelpBlock style={{ color: "red" }}>
                {t(errors["defaultSubject"])}
              </HelpBlock>
            ) : null}
          </FormGroup>
          <FormGroup className="mb-5">
            <ControlLabel className="label">
              {t("defaultIntroText")}
            </ControlLabel>
            <FormControl
              className="input input-gray mw-100"
              rows={3}
              name="textarea"
              componentClass="textarea"
              onChange={(newIntro) =>
                setNewEditorial({ ...newEditorial, defaultIntro: newIntro })
              }
              value={newEditorial.defaultIntro}
            />
            {errors && errors["defaultIntro"] ? (
              <HelpBlock style={{ color: "red" }}>
                {t(errors["defaultIntro"])}
              </HelpBlock>
            ) : null}
          </FormGroup>
          <hr />

          <div className="mb-5">
            <h3 className="mb-3">
              {newEditorialRubrics.length} {t("editorialRubrics")}
            </h3>
            <EditorialRubrics
              showEditorialRubricsWithoutFilters={
                showEditorialRubricsWithoutFilters &&
                newEditorial.cron !== undefined
              }
              editorialRubrics={newEditorialRubrics}
              onChange={setNewEditorialRubrics}
              errors={errors}
            />
            <HelpBlock className="mt-4">{t("editorialRubricsHelp")}</HelpBlock>
            {errors && errors["editorialRubrics"] ? (
              <HelpBlock style={{ color: "red" }}>
                {t(errors["editorialRubrics"])}
              </HelpBlock>
            ) : null}
          </div>
          <hr />

          <div className="d-flex flex-row align-items-center">
            <h3 className="flex-grow-1 mb-0">
              {
                editorialEditorialSubscribers.filter(
                  (subscriber) => subscriber.useMail || subscriber.useApp,
                ).length
              }{" "}
              {t("subscribers")}
            </h3>
            <div>
              <Button
                appearance="primary"
                color="green"
                onClick={() => setShowSubscribers(true)}
              >
                {t("edit")}
              </Button>
            </div>
            <EditorialSubscribersModal
              show={showSubscribers}
              editorial={newEditorial}
              editorialSubscribers={editorialEditorialSubscribers}
              onClose={() => setShowSubscribers(false)}
              onChange={setEditorialEditorialSubscribers}
            />
          </div>
          <hr />
          <div className="mb-4">
            <Checkbox
              checked={newEditorial.cron !== undefined}
              onChange={(_value, checked) =>
                setNewEditorial({
                  ...newEditorial,
                  cron: checked ? "" : undefined,
                })
              }
            >
              {t("automaticallySendEditorial")}
            </Checkbox>

            {newEditorial.cron !== undefined ? (
              <>
                <div>
                  <Checkbox
                    className="d-inline-block me-2 mb-3"
                    checked={newEditorial.allowDuplicateMediaItemsAcrossRubrics}
                    onChange={(_value, checked) =>
                      setNewEditorial({
                        ...newEditorial,
                        allowDuplicateMediaItemsAcrossRubrics: checked,
                      })
                    }
                    title={t(
                      "allowDuplicateMediaItemsAcrossSubjects_description",
                    )}
                  >
                    {t("allowDuplicateMediaItemsAcrossSubjects")}
                  </Checkbox>
                  <MwWhisper
                    trigger="hover"
                    speaker={
                      <Tooltip style={{ whiteSpace: "pre-wrap" }}>
                        {t("allowDuplicateMediaItemsAcrossSubjects_help")}
                      </Tooltip>
                    }
                  >
                    <Icon icon="question" componentClass={QuestionMark} />
                  </MwWhisper>
                </div>
                <CronEditor
                  value={newEditorial.cron}
                  onChange={(newCron) =>
                    setNewEditorial({ ...newEditorial, cron: newCron })
                  }
                />
                {errors && errors["cron"] ? (
                  <HelpBlock style={{ color: "red" }}>
                    {t(errors["cron"])}
                  </HelpBlock>
                ) : null}
              </>
            ) : null}
          </div>
          <Button
            appearance="primary"
            type="submit"
            disabled={!isFormDirty && !isRubricsDirty && !isSubscribersDirty}
          >
            {t("save")}
          </Button>
          <Link
            to={`${APP_PATH}/editorials/overview`}
            className="rs-btn rs-btn-subtle"
          >
            {t("cancel")}
          </Link>
        </Form>
      </div>
      <EditEditorialTour />
    </>
  ) : (
    <Loader center size="lg" />
  );
};
export default EditorialDetailView;
