import React from "react";
import "./index.scss";
import { components } from "../../../../types/openapi";
import { Loader } from "rsuite";
import { AuthContext } from "../../../../provider/AuthProvider";

interface IPageTabProps {
  mediaItem?: components["schemas"]["MediaItem"];
}

const PageTab = ({ mediaItem }: IPageTabProps) => {
  const { auth } = React.useContext(AuthContext);

  if (!mediaItem) {
    return <Loader />;
  }

  return (
    <div className="components__media-item__page-tab">
      <img
        alt={`${mediaItem.title}`}
        src={`${mediaItem.pageImageUrl}${auth ? `?bearer=${auth.bearer}` : ""}`}
      />
    </div>
  );
};

export default PageTab;
