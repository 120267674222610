import React, { SVGProps } from "react";

const Dashboards = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Dashboard_icon"
      width={24.357}
      height={15.154}
      viewBox="0 0 24.357 15.154"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Group_392">
        <path
          id="prefix__Path_744"
          d="M1437.053 72.853a5.319 5.319 0 01-.2-10.635.563.563 0 01.423.161.58.58 0 01.177.417v3.981h4.332a.578.578 0 01.578.558v.052c0 .048.006.1.006.143a5.328 5.328 0 01-5.316 5.323zm-.754-9.415a4.164 4.164 0 104.9 4.5h-4.322a.578.578 0 01-.578-.578z"
          transform="translate(-1427.778 -62.217)"
        />
        <path
          id="prefix__Path_745"
          d="M1461.705 75.882a.578.578 0 01-.578-.578v-6.86a.578.578 0 011.157 0V75.3a.579.579 0 01-.579.582z"
          transform="translate(-1444.889 -65.505)"
        />
        <path
          id="prefix__Path_746"
          d="M1468.072 72.992a.578.578 0 01-.578-.578v-8.936a.578.578 0 111.157 0v8.935a.578.578 0 01-.579.579z"
          transform="translate(-1448.596 -62.615)"
        />
        <path
          id="prefix__Path_747"
          d="M1444.406 83.692h-19.913a2.224 2.224 0 01-2.222-2.222V75.3a2.224 2.224 0 012.222-2.222.578.578 0 110 1.156 1.067 1.067 0 00-1.065 1.066v6.173a1.067 1.067 0 001.065 1.065h19.913a1.066 1.066 0 001.065-1.065V75.3a1.066 1.066 0 00-1.065-1.066.578.578 0 110-1.156 2.224 2.224 0 012.222 2.222v6.173a2.224 2.224 0 01-2.222 2.219z"
          transform="translate(-1422.271 -68.538)"
        />
      </g>
    </svg>
  );
};

export default Dashboards;
