import { useLocation } from "react-router-dom";
import React from "react";
import { IDashboardHashData } from "../../../components/dashboardWidgets";

const initialDashboardHashString = window.location.hash.substr(1);
let initialDashboardHashData: IDashboardHashData | null = null;
try {
  if (initialDashboardHashString) {
    initialDashboardHashData = JSON.parse(
      decodeURIComponent(initialDashboardHashString)
    );
  }
} catch (e) {}

export function useDashboardHashData(): IDashboardHashData | null {
  const location = useLocation();
  const [dashboardHashString, setDashboardHashString] = React.useState<string>(
    initialDashboardHashString
  );
  const [dashboardHashData, setDashboardHashData] =
    React.useState<IDashboardHashData | null>(initialDashboardHashData);
  React.useEffect(() => {
    try {
      setDashboardHashData(
        dashboardHashString
          ? JSON.parse(decodeURIComponent(dashboardHashString))
          : null
      );
    } catch (e) {
      console.log(e);
    }
  }, [dashboardHashString]);

  const newDashboardHashString = location.hash.substr(1);
  if (newDashboardHashString !== dashboardHashString) {
    setDashboardHashString(newDashboardHashString);
  }
  return dashboardHashData;
}
