import React from "react";
import { Popover } from "rsuite";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import { I18nContext } from "../../provider/I18nProvider";
import MwWhisper from "../MwWhisper";

interface IcopyToClipboardProps {
  value: string;
  delay?: number;
  children: React.ReactNode;
}

const CopyToClipboard = (props: IcopyToClipboardProps) => {
  const { t } = React.useContext(I18nContext);
  const [copied, setCopied] = React.useState<boolean>(false);
  return (
    <ReactCopyToClipboard text={props.value} onCopy={() => setCopied(true)}>
      <MwWhisper
        trigger="none"
        placement="bottom"
        speaker={<Popover>{t("copied")}</Popover>}
        open={copied}
        onOpen={() => {
          setTimeout(() => {
            setCopied(false);
          }, props?.delay || 750);
        }}
      >
        {props.children}
      </MwWhisper>
    </ReactCopyToClipboard>
  );
};
export default CopyToClipboard;
