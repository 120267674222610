import React from "react";
import { ESplitType, EValueType, IDashboardWidgetProps } from "../index";
import { Panel } from "rsuite";
import { lowerFirst } from "lodash";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { getDefaultOptions } from "../../../inc/widgets/chart";
import { I18nContext } from "../../../provider/I18nProvider";
import useCategories from "../../../hooks/useCategories";
import {
  chartSortedSentiments,
  sentimentOptions,
} from "../../../inc/sentimentOptions";
import PopoverForm from "../inc/PopoverForm";
import { EChartType } from "../../../inc/enums";
import {
  getSeriesMaxValue,
  getSortedAnalysisSplitItemKeys,
  useSeries,
} from "../inc/default";

import "./index.scss";
import AnalysisSplitChartBody from "../inc/AnalysisSplitChartBody";

export interface ICategoryWidgetSettings {
  chartType?: EChartType.donut | EChartType.column;
  splitType?: ESplitType.none | ESplitType.sentiment;
  valueType?: EValueType;
  limit: number;
  showRemaining: boolean;
}

const CategoryWidget = ({
  colWidth,
  filterCacheResponse,
  height,
  onDelete,
  onFilterChange,
  onSettingsChange,
  onSettingsToggle,
  period,
  settings,
  uid,
  width,
}: IDashboardWidgetProps<ICategoryWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);
  const categories = useCategories();
  const [analysisSplit, setAnalysisSplit] = React.useState<
    components["schemas"]["AnalysisSplit"] | null
  >();
  const {
    chartType = EChartType.donut,
    valueType = EValueType.count,
    limit = 10,
    showRemaining = false,
  } = settings || {};
  let { splitType = ESplitType.none } = settings || {};
  if (chartType === EChartType.donut) {
    splitType = ESplitType.none;
  }
  const dateType = period?.dateType || "insertDate";
  const type = chartType === "column" ? "bar" : chartType;
  const token = filterCacheResponse?.token;

  React.useEffect(() => {
    setAnalysisSplit(null);
    axios
      .get<components["schemas"]["AnalysisSplit"]>(
        `/analyse/cache/${token}/${lowerFirst(
          valueType.replace("summed", ""),
        )}PerSentiment/category`,
      )
      .then((res) => setAnalysisSplit(res.data));
  }, [token, dateType, valueType]);
  const chartWidth = Math.floor(0.96 * width);

  // make space for bottom legend if needed
  const chartHeight = height - (splitType === ESplitType.none ? 58 : 80);
  const series = useSeries(
    analysisSplit,
    chartType,
    splitType,
    valueType,
    "",
    limit,
    showRemaining,
  ) as ApexAxisChartSeries;
  const sortedAnalysisSplitItemKeys = React.useMemo(
    () =>
      analysisSplit
        ? getSortedAnalysisSplitItemKeys(analysisSplit).slice(0, limit)
        : [],
    [analysisSplit, limit],
  );

  const chartOptions = React.useMemo(() => {
    if (!analysisSplit) {
      return {};
    }
    const yAxisLabels = sortedAnalysisSplitItemKeys
      .slice(0, limit)
      .map((key) => analysisSplit.perGroup[key]);
    if (showRemaining) {
      yAxisLabels.push(t("remainingCategories"));
    }
    const options = getDefaultOptions(
      chartType,
      valueType,
      splitType,
      chartWidth,
      chartHeight,
      getSeriesMaxValue(series),
      colWidth === 1,
      yAxisLabels,
    );
    const colors =
      splitType === ESplitType.sentiment
        ? chartSortedSentiments.map(
            (sentiment) => sentimentOptions[sentiment].color || "#ccc",
          )
        : options.colors;
    return {
      ...options,
      colors,
      chart: {
        ...options.chart,
        events: {
          dataPointSelection: (
            event: React.MouseEvent | null,
            _chartContext: any,
            config: any,
          ) => {
            // non-primary button?
            if (event?.button || !categories || !onFilterChange) {
              return;
            }
            const { dataPointIndex, seriesIndex } = config;
            const categoryName =
              analysisSplit.perGroup[
                sortedAnalysisSplitItemKeys[dataPointIndex]
              ];
            if (!categoryName) {
              return;
            }
            const category = Object.values(categories).find(
              (category) => category.name === categoryName,
            );
            const filterUpdate: Partial<components["schemas"]["Filter"]> = {};
            if (category && category.categoryId) {
              filterUpdate.categoryIds = [category.categoryId];
            }
            if (splitType !== ESplitType.none) {
              filterUpdate.sentiments = [chartSortedSentiments[seriesIndex]];
            }
            if (Object.keys(filterUpdate).length) {
              onFilterChange(filterUpdate);
            }
          },
        },
      },
    };
  }, [
    analysisSplit,
    categories,
    chartHeight,
    chartType,
    chartWidth,
    colWidth,
    limit,
    onFilterChange,
    series,
    showRemaining,
    sortedAnalysisSplitItemKeys,
    splitType,
    t,
    valueType,
  ]);

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        allowImageDownload={!!onSettingsChange}
        title={t("category")}
        subtitle={`${t(`valueType_${valueType}`)}${
          splitType !== ESplitType.none
            ? ` > ${t(`splitType_${splitType}`)}`
            : ""
        }`}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={
          onSettingsChange && settings ? (
            <PopoverForm<ICategoryWidgetSettings>
              onSettingsChange={onSettingsChange}
              widgetUid={uid}
              settings={settings}
              chartTypes={[EChartType.donut, EChartType.bar]}
              topResultOptions={[3, 5, 10]}
              showRemainingOption
              splitTypes={[ESplitType.none, ESplitType.sentiment]}
            />
          ) : undefined
        }
      />
    ),
    [
      onDelete,
      onSettingsChange,
      onSettingsToggle,
      settings,
      splitType,
      t,
      uid,
      valueType,
    ],
  );

  return (
    <Panel
      bordered={true}
      header={header}
      className={`views__dashboard-view__widgets__category-widget`}
    >
      <AnalysisSplitChartBody
        analysisSplit={analysisSplit}
        splitType={splitType}
        type={type}
        chartOptions={chartOptions}
        series={series}
        width={width}
        height={height}
      />
    </Panel>
  );
};

export default CategoryWidget;
