import { Icon, IconButton, Input } from "rsuite";
import React from "react";

interface IFileInputProps {
  onChange: (newBase64?: string) => void;
  value?: string;
  showClear?: boolean;
  showPreview?: boolean;
}

const FileInput = ({
  onChange,
  showClear,
  showPreview,
  value,
}: IFileInputProps) => {
  const onFileChange = React.useCallback(
    (_path: string, e: React.SyntheticEvent<HTMLElement>) => {
      const { files } = e.target as HTMLInputElement;
      if (files && files.length > 0) {
        const file = files[0];
        new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => resolve(reader.result as string),
            false,
          );
          reader.readAsDataURL(file);
        }).then(onChange);
      }
    },
    [onChange],
  );

  return (
    <>
      {showPreview && value ? (
        <img src={value} alt="Preview" style={{ maxWidth: "100%" }} />
      ) : null}
      <Input type="file" onChange={onFileChange} accept="image/*" />
      {showClear && value ? (
        <IconButton
          size="xs"
          appearance="primary"
          color="red"
          icon={<Icon icon="trash" />}
          onClick={() => {
            onChange(undefined);
          }}
        >
          Bestand verwijderen
        </IconButton>
      ) : null}
    </>
  );
};

export default React.memo(FileInput);
