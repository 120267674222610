import React from "react";
import { components } from "../../../../../types/openapi";
import { Button, Checkbox, CheckboxGroup, Divider, Loader } from "rsuite";
import "./index.scss";
import { containsWord } from "../../../../../inc/query";
import { I18nContext } from "../../../../../provider/I18nProvider";

type TResults =
  | components["schemas"]["Category"]
  | components["schemas"]["Source"]
  | components["schemas"]["Author"]
  | components["schemas"]["SearchTopic"]
  | components["schemas"]["Label"];

interface IAdvancedSearchEngineResultsBaseProps {
  title: string;
  searchQuery: string;
  results?: TResults[] | null;
  onChange: (ids: string[]) => void;
  getResultId: (result: TResults) => string;
  allResultIds: string[];
  values: string[];
}

const AdvancedSearchEngineResultsBase = (
  props: IAdvancedSearchEngineResultsBaseProps
) => {
  const {
    title,
    searchQuery,
    results,
    onChange,
    getResultId,
    allResultIds,
    values,
  } = props;
  const { t } = React.useContext(I18nContext);
  const [resultIdsWithWord, setResultIdsWithWord] = React.useState<string[]>(
    []
  );

  React.useEffect(() => {
    const resultsContainingWord = !results
      ? []
      : containsWord(results, "name", searchQuery);
    setResultIdsWithWord(
      resultsContainingWord.map((result) => getResultId(result))
    );
  }, [getResultId, results, searchQuery]);

  const onCheckboxChange = React.useCallback(
    (newValues: string[]) => {
      onChange(newValues);
    },
    [onChange]
  );

  return !results || results.length ? (
    <>
      <Divider />
      <div className="advanced-search-results">
        <h4 className="advanced-search-results__subtitle">{title}</h4>
        <ul className="advanced-search-results__content">
          {results?.length ? (
            <>
              <Button
                className="my-1"
                size="xs"
                block
                onClick={() => onChange(allResultIds)}
              >
                {t("selectAllResults", allResultIds.length)}
              </Button>
              {resultIdsWithWord.length ? (
                <Button
                  className="my-1"
                  size="xs"
                  block
                  onClick={() => onChange(resultIdsWithWord)}
                >
                  {t("selectWithWord", searchQuery, resultIdsWithWord.length)}
                </Button>
              ) : null}
              <CheckboxGroup value={values} onChange={onCheckboxChange}>
                {results.slice(0, 20).map((result:any) => {
                  const id = getResultId(result);
                  return (
                    <Checkbox
                      className="checkbox-max-width"
                      key={id}
                      value={id}
                    >
                      {result.name}
                    </Checkbox>
                  );
                })}
              </CheckboxGroup>
            </>
          ) : (
            <div className="text-center mb-3">
              <Loader />
            </div>
          )}
        </ul>
      </div>
    </>
  ) : null;
};
export default AdvancedSearchEngineResultsBase;
