import React, { SVGProps } from "react";

const Admin = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Beheer_icon"
      width={23.666}
      height={18.024}
      viewBox="0 0 23.666 18.024"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Group_333">
        <path
          id="prefix__Path_624"
          d="M1431.711 303.062a2.619 2.619 0 112.619-2.619 2.621 2.621 0 01-2.619 2.619zm0-4.063a1.444 1.444 0 101.444 1.444 1.446 1.446 0 00-1.444-1.443z"
          transform="translate(-1425.864 -297.825)"
        />
        <path
          id="prefix__Path_625"
          d="M1456.921 318.129a2.618 2.618 0 112.619-2.618 2.621 2.621 0 01-2.619 2.618zm0-4.063a1.444 1.444 0 101.444 1.444 1.446 1.446 0 00-1.444-1.444z"
          transform="translate(-1440.376 -306.498)"
        />
        <path
          id="prefix__Path_626"
          d="M1438.266 333.2a2.619 2.619 0 112.619-2.619 2.622 2.622 0 01-2.619 2.619zm0-4.063a1.444 1.444 0 101.445 1.444 1.445 1.445 0 00-1.445-1.448z"
          transform="translate(-1429.638 -315.171)"
        />
        <path
          id="prefix__Path_627"
          d="M1457.226 303.787h-13.155a.587.587 0 010-1.175h13.155a.587.587 0 010 1.175z"
          transform="translate(-1434.149 -300.581)"
        />
        <path
          id="prefix__Path_628"
          d="M1425.3 303.787h-3.229a.587.587 0 110-1.175h3.229a.587.587 0 010 1.175z"
          transform="translate(-1421.484 -300.581)"
        />
        <path
          id="prefix__Path_629"
          d="M1471.617 318.853h-2.546a.587.587 0 110-1.174h2.546a.587.587 0 110 1.174z"
          transform="translate(-1448.54 -309.254)"
        />
        <path
          id="prefix__Path_630"
          d="M1436 318.853h-13.926a.587.587 0 110-1.174H1436a.587.587 0 010 1.174z"
          transform="translate(-1421.484 -309.254)"
        />
        <path
          id="prefix__Path_631"
          d="M1460.68 333.92h-10.609a.587.587 0 010-1.175h10.609a.587.587 0 010 1.175z"
          transform="translate(-1437.603 -317.927)"
        />
        <path
          id="prefix__Path_632"
          d="M1428.081 333.92h-6.01a.587.587 0 110-1.175h6.01a.587.587 0 110 1.175z"
          transform="translate(-1421.484 -317.927)"
        />
      </g>
    </svg>
  );
};

export default Admin;
