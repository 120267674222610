import React, { SVGProps } from "react";

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20.072}
    height={15.879}
    viewBox="0 0 20.072 15.879"
    fill="currentColor"
    {...props}
  >
    <path
      stroke="rgba(0,0,0,0)"
      strokeMiterlimit={10}
      d="M1.422 7.082h15l-4.893-4.918a.93.93 0 010-1.309.917.917 0 011.3 0l6.4 6.43a.928.928 0 010 1.307l-6.4 6.436a.917.917 0 01-1.3 0 .926.926 0 01-.273-.658.9.9 0 01.273-.648l4.861-4.893H1.422a.874.874 0 110-1.746z"
    />
  </svg>
);
export default ArrowRight;
