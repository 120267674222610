import React, { SVGProps } from "react";

const Settings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={5}
    viewBox="0 0 21 5"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Group_301" transform="rotate(90 824 -492)">
      <circle
        id="prefix__Ellipse_9"
        cx={2.5}
        cy={2.5}
        r={2.5}
        transform="translate(1316 311)"
      />
      <circle
        id="prefix__Ellipse_10"
        cx={2.5}
        cy={2.5}
        r={2.5}
        transform="translate(1316 319)"
      />
      <circle
        id="prefix__Ellipse_11"
        cx={2.5}
        cy={2.5}
        r={2.5}
        transform="translate(1316 327)"
      />
    </g>
  </svg>
);
export default Settings;
