import Slider from "../../../../components/Slider";
import MwTag from "../../../../components/MwTag";
import {
  Checkbox,
  CheckboxGroup,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Popover,
} from "rsuite";
import React from "react";
import { MinusRound } from "@rsuite/icons";
import MwWhisper from "../../../MwWhisper";
import MagnifyingGlass from "../../../../icons/MagnifyingGlass";
import { PlusCircle } from "@rsuite/icons/es/icons/legacy";

interface ICustomLegendProps {
  colors: string[];
  onChange?: (valueGroups: string[]) => void;
  options: string[];
  value: string[];
}
const CustomLegend = ({
  colors,
  onChange,
  options,
  value,
}: ICustomLegendProps) => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const onRemoveValueGroup = React.useCallback(
    (toBeRemoved: string) => {
      if (!onChange) {
        return;
      }
      onChange(value.filter((existing) => toBeRemoved !== existing));
    },
    [onChange, value],
  );

  return (
    <div className="d-flex flex-row align-items-center" style={{ height: 26 }}>
      <div style={{ width: "calc(100% - 30px)" }}>
        <Slider>
          {value.map((serie, serieKey) => (
            <MwTag
              className="custom-legend__tag"
              iconComponent={() =>
                onChange ? (
                  <MinusRound
                    color={colors[serieKey]}
                    style={{ cursor: "hand", marginLeft: -16, marginRight: 8 }}
                    onClick={() => onRemoveValueGroup(serie as string)}
                  />
                ) : (
                  <Icon
                    icon="circle"
                    style={{
                      fontSize: 10,
                      marginRight: 4,
                      marginBottom: 4,
                      color: colors[serieKey],
                    }}
                  />
                )
              }
              key={serie}
            >
              {serie}
            </MwTag>
          ))}
        </Slider>
      </div>
      {onChange ? (
        <MwWhisper
          trigger="click"
          speaker={
            <Popover className="popover-without-arrow checkbox-popover">
              <InputGroup inside>
                <InputGroup.Addon>
                  <Icon icon="star" componentClass={MagnifyingGlass} />
                </InputGroup.Addon>
                <Input
                  size="sm"
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
              </InputGroup>
              <div>
                <CheckboxGroup value={value} onChange={onChange}>
                  {options
                    .filter((option) =>
                      option.toLowerCase().includes(searchQuery.toLowerCase()),
                    )
                    .map((option) => (
                      <Checkbox
                        disabled={false}
                        className={`checkbox-max-width`}
                        key={option}
                        value={option}
                      >
                        {option}
                      </Checkbox>
                    ))}
                </CheckboxGroup>
              </div>
            </Popover>
          }
        >
          <IconButton icon={<PlusCircle />} circle className="ms-2" size="xs" />
        </MwWhisper>
      ) : null}
    </div>
  );
};

export default CustomLegend;
