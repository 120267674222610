import React from "react";
import { Input } from "rsuite";
import "./index.scss";
import ActionButton from "../../ActionButton";
import MwTag from "../../MwTag";

interface IAdvancedMatchConfigProps {
  title: string;
  items: string[];
  expanded: boolean;
  onExpandToggle: (expanded: boolean) => void;
  onChange: (items: string[]) => void;
}

const AdvancedMatchConfig = ({
  title,
  items,
  expanded,
  onExpandToggle,
  onChange,
}: IAdvancedMatchConfigProps) => {
  const [newMatch, setNewMatch] = React.useState<string>("");
  const inputRef = React.useRef<HTMLInputElement>(null);

  const addNewMatch = React.useCallback(() => {
    const toBeAddedMatch = newMatch.trim();
    if (!toBeAddedMatch) {
      return;
    }
    onChange([...items, toBeAddedMatch]);
    setNewMatch("");
  }, [items, newMatch, onChange]);

  return (
    <div className="advanced-match-config">
      <h5 className="mb-2">{title}</h5>
      <form
        onSubmit={(e:any) => {
          e.preventDefault();
          e.stopPropagation();
          addNewMatch();
        }}
        className={`advanced-match-config__actions${
          expanded ? " advanced-match-config__actions--active" : ""
        }`}
      >
        <ActionButton
          className={`advanced-match-config__toggle${
            expanded ? " advanced-match-config__toggle--active" : ""
          }`}
          onClick={() => {
            if (!expanded && inputRef.current) {
              inputRef.current.focus();
            }
            onExpandToggle(!expanded);
          }}
        />
        <Input
          value={newMatch}
          onChange={setNewMatch}
          className={`input-gray rounded-pill advanced-match-config__input${
            expanded ? " advanced-match-config__input--active" : ""
          }`}
          onBlur={addNewMatch}
          size="sm"
          inputRef={inputRef}
        />
      </form>
      <div className="advanced-match-config__items">
        {items.map((item, index) => (
          <MwTag
            key={index}
            color="cyan"
            size="lg"
            onClose={() => {
              const newItems = Array.from(items);
              newItems.splice(index, 1);
              onChange(newItems);
            }}
          >
            {item}
          </MwTag>
        ))}
      </div>
    </div>
  );
};
export default AdvancedMatchConfig;
