import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useEditorialSubscribers = () => {
  const { editorialSubscribers, setEditorialSubscribers } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (editorialSubscribers !== undefined || !auth?.bearer) {
      return;
    }
    setEditorialSubscribers(null);
    axios
      .get<components["schemas"]["EditorialSubscriber"][]>(
        "/editorialSubscriber/crud"
      )
      .then((res) => {
        setEditorialSubscribers(
          res.data.reduce<
            IHashMap<components["schemas"]["EditorialSubscriber"]>
          >((prev, editorialSubscriber) => {
            prev[editorialSubscriber.editorialSubscriberId as string] =
              editorialSubscriber;
            return prev;
          }, {})
        );
      });
  }, [auth?.bearer, setEditorialSubscribers, editorialSubscribers]);

  return editorialSubscribers;
};

export default useEditorialSubscribers;
