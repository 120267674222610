import React from "react";
import { components } from "../../../types/openapi";
import { DATE_FORMAT, localeFormat } from "../../../inc/date";
import { I18nContext } from "../../../provider/I18nProvider";

interface IDateTypeHeaderProps {
  startDate: Date;
  endDate: Date;
  periodType: components["schemas"]["Period"]["periodType"];
}

const DateTypeHeader = ({
  startDate,
  endDate,
  periodType,
}: IDateTypeHeaderProps) => {
  const { t } = React.useContext(I18nContext);
  return (
    <>
      {periodType === "custom"
        ? `${localeFormat(startDate, DATE_FORMAT)} ~ ${localeFormat(
            endDate,
            DATE_FORMAT,
          )}`
        : t(periodType)}
    </>
  );
};
export default DateTypeHeader;
