import React from "react";
import { APP_PATH } from "../../../inc/constants";
import {
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import { Alert, Button, ButtonToolbar, Form, FormGroup } from "rsuite";
import axios from "../../../inc/axios";
import SchemaFormBody from "../../../components/SchemaFormBody";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { paths } from "../../../types/openapi";
import ajv from "../../../inc/ajv";
import { Redirect } from "react-router-dom";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";

type TResetRequest =
  paths["/auth/resetPasswordRequest"]["post"]["requestBody"]["content"]["application/json"];
type TResetFormErrors = { [P in keyof TResetRequest]?: string };

const ResetPasswordRequestSchema = openapi.paths["/auth/resetPasswordRequest"]
  .post.requestBody.content["application/json"].schema as oas30.SchemaObject;
const validate = ajv.compile(ResetPasswordRequestSchema);
const LostPasswordView = () => {
  const { t } = React.useContext(I18nContext);
  const [resetPasswordRequest, setResetPasswordReques] =
    React.useState<TResetRequest>(
      getEmptyObject<TResetRequest>(ResetPasswordRequestSchema)
    );
  const [formError, setErrors] = React.useState<TResetFormErrors>({});
  const [status, setStatus] = React.useState<"idle" | "pending" | "finished">(
    "idle"
  );
  const { auth } = React.useContext(AuthContext);
  if (auth) {
    // User isn't logged in
    return <Redirect to={APP_PATH} />;
  }
  if (status === "finished") {
    return (
      <>
        <h1>Verstuurd!</h1>
        <p>
          Als je e-mailadres bij ons bekend is ontvang je binnen enkele minuten
          een mail met een reset-link.
        </p>
        <p>Klik daarop om verder te gaan.</p>
      </>
    );
  }

  return (
    <>
      <h1>{t("lostPasswordTitle")}</h1>
      <p>{t("lostPasswordBody")}</p>
      <Form
        style={{ marginTop: 40 }}
        formError={formError}
        onSubmit={() => {
          const isValid = validate(resetPasswordRequest);
          if (!isValid && validate.errors && validate.errors.length) {
            console.log(validate.errors);
            setErrors(validationErrorsToSchemaFormErrors(validate.errors));
            return;
          }
          setStatus("pending");
          setErrors({});
          axios
            .post("/auth/resetPasswordRequest", resetPasswordRequest)
            .then(() => {
              setStatus("finished");
            })
            .catch((err) => {
              setStatus("idle");
              Alert.error(t(err.message || "genericErrorMessage"));
            });
        }}
      >
        <SchemaFormBody<TResetRequest>
          schema={{
            ...ResetPasswordRequestSchema,
            properties: {
              ...ResetPasswordRequestSchema.properties,
              userName: {
                ...(ResetPasswordRequestSchema.properties as any).userName,
                "x-props": {
                  autoComplete: "userName",
                },
              },
            },
          }}
          value={resetPasswordRequest}
          onChange={setResetPasswordReques}
          disabled={status !== "idle"}
        />
        <FormGroup style={{ marginTop: 40 }}>
          <ButtonToolbar>
            <Button
              type="submit"
              appearance="primary"
              disabled={status !== "idle"}
            >
              {t("Reset")}
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </>
  );
};

export default LostPasswordView;
