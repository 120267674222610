import React from "react";
import { Button, HelpBlock, Input, Modal, Radio, RadioGroup } from "rsuite";
import "./index.scss";
import { I18nContext } from "../../provider/I18nProvider";

interface IMediaItemDeleteModalProps {
  show: boolean;
  onConfirm: (message?: string) => void;
  onCancel: () => void;
  amountOfItemsToDelete?: number;
}

const MediaItemDeleteModal = (props: IMediaItemDeleteModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { show, onCancel, onConfirm, amountOfItemsToDelete = 1 } = props;
  const [message, onMessageChange] = React.useState<string>("");
  const [deleteType, setDeleteType] = React.useState<
    "deleteRequest" | "creditRequest"
  >();
  const [formError, setFormError] = React.useState<string>();
  return (
    <Modal
      size={"md"}
      className="delete-modal"
      backdrop={true}
      show={show}
      onHide={onCancel}
    >
      <Modal.Body
        className="px-3 px-md-4 px-lg-5"
        style={{ maxHeight: undefined }}
      >
        <div className="row">
          <div className="d-none d-lg-block col-lg-6">
            <img
              className="img-fluid delete-modal__image"
              alt="media·web"
              src="/img/art/kranteter/kranteter.png"
              srcSet="/img/art/kranteter/kranteter@2x.png 2x, /img/art/kranteter/kranteter@3x.png 3x"
            />
          </div>
          <div className="col-lg-6">
            <h3>{t("areYouSure")}</h3>
            <p>
              {amountOfItemsToDelete === 1
                ? t("mediaItemDeleteMessage")
                : t("mediaItemsDeleteMessage", amountOfItemsToDelete)}
            </p>
            <RadioGroup
              name="deleteType"
              value={deleteType}
              onChange={(newDeleteType) => {
                setDeleteType(newDeleteType);
                setFormError(undefined);
              }}
            >
              <Radio value="deleteRequest">
                {t("creditRequestDeleteTypeDeleteRequest")}
              </Radio>
              <Radio value="creditRequest">
                {t("creditRequestDeleteTypeCreditRequest")}
              </Radio>
            </RadioGroup>
            {deleteType === "creditRequest" ? (
              <>
                <Input
                  componentClass="textarea"
                  rows={3}
                  className="mt-1"
                  placeholder={t("mediaItemDeleteExplanationPlaceholder")}
                  value={message}
                  onChange={onMessageChange}
                />
              </>
            ) : null}
            {formError ? (
              <HelpBlock style={{ color: "red" }}>{t(formError)}</HelpBlock>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row justify-content-center">
          <div className="col-auto">
            <Button
              onClick={() => {
                if (!deleteType) {
                  setFormError("creditRequestDeleteTypeIsRequired");
                  return;
                }
                if (deleteType === "deleteRequest") {
                  onConfirm();
                  return;
                }
                if (!message) {
                  setFormError("creditRequestMessageIsRequired");
                  return;
                }
                setFormError(undefined);
                onConfirm(message);
              }}
              appearance="primary"
              color="red"
              className="w-100"
            >
              {t("delete")}
            </Button>
            <div className="w-100" />
            <Button onClick={onCancel} appearance="subtle" className="w-100">
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default MediaItemDeleteModal;
