import React from "react";
import {
  Alert,
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Input,
  Loader,
  Message,
  Modal,
  TagPicker,
} from "rsuite";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { components } from "../../../types/openapi";
import axios, { AxiosError } from "axios";
import SchemaTable from "../../../components/SchemaTable";
import { dataIsEmail } from "../../../inc/query";
import "./index.scss";
import { I18nContext } from "../../../provider/I18nProvider";
import usePrevious from "../../../hooks/usePrevious";
import useEditorials from "../../../hooks/useEditorials";
import useSubscribers from "../../../hooks/useSubscribers";
import useCustomers from "../../../hooks/useCustomers";
import { AuthContext } from "../../../provider/AuthProvider";
import { IMaartenError } from "../../../types";
import { cloneDeep } from "lodash";

interface ISubscribersModalProps {
  onClose: () => void;
  show: boolean;
}

interface IFailedEmailAddress {
  emailAddress: string;
  message: string;
}

const SubscriberSchema = openapi.components.schemas
  .Subscriber as oas30.SchemaObject;

const { properties } = SubscriberSchema;
if (!properties) {
  throw new Error("Incomplete subscriber schema");
}

interface ISubscriber {
  displayName?: string;
  email: string;
  editorialSubscriptions: {
    [editorialId: string]: {
      useMail?: boolean;
      useApp?: boolean;
    };
  };
}

const ISubscriberSchema = {
  properties: {
    displayName: { type: "string" },
    email: { format: "email", minLength: 1, type: "string" },
    editorialIds: { type: "array", items: { type: "string" } },
  },
} as oas30.SchemaObject;

const SubscribersModal = (props: ISubscribersModalProps) => {
  const { show, onClose } = props;
  const { windowInnerHeight } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const { setEditorialSubscribers, setSubscribers } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const customers = useCustomers();
  const editorials = useEditorials();
  const subscribers = useSubscribers();

  const [subscriberEmailAddressesString, setSubscriberEmailAddressesString] =
    React.useState<string>("");
  const [newlyAddedSubscribers, setNewlyAddedSubscribers] = React.useState<
    ISubscriber[]
  >([]);
  const [failedEmailAddresses, setFailedEmailAddresses] = React.useState<
    IFailedEmailAddress[]
  >([]);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [confirmationIsChecked, setConfirmationIsChecked] =
    React.useState<boolean>(false);
  const [confirmationError, setConfirmationError] = React.useState<string>();
  const [selectedSubscriberIndexes, setSelectedSubscriberIndexes] =
    React.useState<number[]>([]);
  const [activeView, setActiveView] = React.useState<"create" | "results">(
    "create",
  );
  const [editorialSelection, setEditorialSelection] = React.useState<
    { editorialId: string; useMail?: true; useApp?: true }[]
  >([]);

  const createSubscribers = React.useCallback(() => {
    if (saving) {
      return;
    }
    setSaving(true);
    setNewlyAddedSubscribers([]);
    setFailedEmailAddresses([]);
    let createdSubscribers = false;

    // Split email addresses on newline, comma, space or semicolon
    const emailAddresses = subscriberEmailAddressesString
      .split(/\r?\n|\s|,|;/)
      .map((emailAddress) => emailAddress.trim().toLowerCase());
    emailAddresses.forEach((emailAddress, index) => {
      if (emailAddress === "") {
        return;
      }

      // Email address has invalid format
      if (!dataIsEmail(emailAddress)) {
        setFailedEmailAddresses((currentEmailAddresses) => [
          ...currentEmailAddresses,
          {
            emailAddress,
            message: 'must match format "email"',
          },
        ]);
        return;
      }

      // Email address is of existing subscriber
      // OR is duplicate in the 'emailAddresses' list (duplicate 1 is added, duplicate 2 isn't)
      // OR was already added to 'newlyAddedSubscribers'
      if (
        Object.values(subscribers || {}).find(
          (subscriber) => subscriber.email === emailAddress,
        ) ||
        emailAddresses
          .slice(0, index)
          .find(
            (previousEmailAddress) => previousEmailAddress === emailAddress,
          ) ||
        newlyAddedSubscribers.find(
          (subscriber) => subscriber.email === emailAddress,
        )
      ) {
        setFailedEmailAddresses((currentEmailAddresses) => [
          ...currentEmailAddresses,
          {
            emailAddress,
            message: "email already used by another subscriber",
          },
        ]);
        return;
      }

      createdSubscribers = true;
      setNewlyAddedSubscribers((currentSubscribers) => [
        ...currentSubscribers,
        {
          email: emailAddress,
          editorialSubscriptions: {},
        },
      ]);
    });

    if (createdSubscribers || newlyAddedSubscribers.length) {
      setActiveView("results");
    }
    setSaving(false);
  }, [
    newlyAddedSubscribers,
    saving,
    subscriberEmailAddressesString,
    subscribers,
  ]);

  const getSelected = React.useCallback(
    (_: unknown, index: number) =>
      selectedSubscriberIndexes.indexOf(index) >= 0,
    [selectedSubscriberIndexes],
  );

  const setSelected = React.useCallback(
    (_: unknown, isSelected: boolean, index: number) => {
      setSelectedSubscriberIndexes((currentSelectedSubscriberIndexes) => {
        if (isSelected) {
          return [...currentSelectedSubscriberIndexes, index];
        }
        return currentSelectedSubscriberIndexes.filter(
          (currentSelectedIndex) => currentSelectedIndex !== index,
        );
      });
    },
    [],
  );

  const getSelectAll = React.useCallback(
    () => selectedSubscriberIndexes.length === newlyAddedSubscribers.length,
    [newlyAddedSubscribers.length, selectedSubscriberIndexes.length],
  );

  const setSelectAll = React.useCallback(
    (isSelected: boolean) =>
      setSelectedSubscriberIndexes(
        isSelected
          ? Object.keys(newlyAddedSubscribers).map((index) =>
              parseInt(index, 10),
            )
          : [],
      ),
    [newlyAddedSubscribers],
  );

  // Check all newlyAddedSubscribers, if new subscribers are added
  const previousSubscribers = usePrevious(newlyAddedSubscribers);
  React.useEffect(() => {
    if (
      previousSubscribers &&
      previousSubscribers.length !== newlyAddedSubscribers.length
    ) {
      setSelectedSubscriberIndexes(
        Object.keys(newlyAddedSubscribers).map((index) => parseInt(index, 10)),
      );
    }
  }, [newlyAddedSubscribers, previousSubscribers]);

  const currentCustomer = React.useMemo(() => {
    return customers && auth?.jwt.currentCustomerLinkId
      ? customers[auth.jwt.currentCustomerLinkId]
      : undefined;
  }, [auth?.jwt.currentCustomerLinkId, customers]);

  const saveChanges = React.useCallback(async () => {
    if (!newlyAddedSubscribers || saving) {
      return;
    }
    if (!confirmationIsChecked) {
      setConfirmationError("must NOT be shorter than 1 characters");
      return;
    }
    setConfirmationError(undefined);
    setSaving(true);

    // Create subscribers
    const newSubscribers = await Promise.all(
      newlyAddedSubscribers.map(
        async (newlyAddedSubscriber) =>
          await axios
            .post<components["schemas"]["Subscriber"]>("/subscriber/crud", {
              email: newlyAddedSubscriber.email,
              displayName: newlyAddedSubscriber.displayName,
            })
            .then((res) => {
              setSubscribers((currentSubscribers) => ({
                ...currentSubscribers,
                [res.data.subscriberId as string]: res.data,
              }));
              return {
                ...res.data,
                editorialSubscriptions:
                  newlyAddedSubscriber.editorialSubscriptions,
              };
            }),
      ),
    );

    // Create subscriberEditorials
    for (const newSubscriber of newSubscribers) {
      for (const editorialId in newSubscriber.editorialSubscriptions) {
        const { useMail, useApp } =
          newSubscriber.editorialSubscriptions[editorialId];
        try {
          const res = await axios.post<
            components["schemas"]["EditorialSubscriber"]
          >("/editorialSubscriber/crud", {
            subscriberId: newSubscriber.subscriberId,
            editorialId,
            useMail,
            useApp,
          });
          setEditorialSubscribers((currentEditorialSubscribers) => ({
            ...currentEditorialSubscribers,
            [res.data.editorialSubscriberId as string]: res.data,
          }));
        } catch (err) {
          const { response } = err as AxiosError<IMaartenError>;
          if (response?.data.error) {
            Alert.error(t(response.data.error));
          }
        }
      }
    }
    setSaving(false);
    onClose();
    setNewlyAddedSubscribers([]);
    setFailedEmailAddresses([]);
    setActiveView("create");
    setSubscriberEmailAddressesString("");
  }, [
    newlyAddedSubscribers,
    saving,
    confirmationIsChecked,
    onClose,
    setSubscribers,
    setEditorialSubscribers,
    t,
  ]);

  if (!editorials) {
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={
        activeView === "results" ? () => setActiveView("create") : onClose
      }
      className="modal-size-auto subscribers-modal"
    >
      <Modal.Header>
        <Modal.Title>{t("addSubscribers")}</Modal.Title>
        <div style={{ display: "flex" }}>
          {activeView === "create" ? (
            <Button
              appearance="primary"
              type="submit"
              disabled={subscriberEmailAddressesString === "" || saving}
              onClick={createSubscribers}
            >
              {t("createSubscribers")}
            </Button>
          ) : (
            <Button
              type="submit"
              appearance="primary"
              onClick={saveChanges}
              disabled={saving}
            >
              {t("save")}
            </Button>
          )}
        </div>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: undefined }}>
        <div className="w-100">
          {failedEmailAddresses.length ? (
            <Message
              type="warning"
              title={t("invalidEmailAddresses")}
              showIcon
              closable
              className="mb-3"
              description={
                <div className="mt-2">
                  <ul className="list-unstyled">
                    {failedEmailAddresses.map((failedEmailAddress, index) => (
                      <li key={index}>
                        <b>{failedEmailAddress.emailAddress}</b>:{" "}
                        {t(failedEmailAddress.message)}
                      </li>
                    ))}
                  </ul>

                  {activeView === "create" ? null : (
                    <Button
                      size="sm"
                      appearance="subtle"
                      color="yellow"
                      onClick={() => {
                        setActiveView("create");
                        // Fill the input with failed email addresses
                        setSubscriberEmailAddressesString(
                          failedEmailAddresses
                            .map(
                              (failedEmailAddress) =>
                                failedEmailAddress.emailAddress,
                            )
                            .join("\n"),
                        );
                      }}
                    >
                      {t("tryAgain")}
                    </Button>
                  )}
                </div>
              }
            />
          ) : null}

          {activeView === "create" ? (
            <Form onSubmit={createSubscribers}>
              <FormGroup className="mb-5">
                <ControlLabel className="label">
                  {t("subscriberEmailAddresses")}
                </ControlLabel>
                <FormControl
                  className="input input-gray mw-100"
                  rows={3}
                  name="textarea"
                  componentClass="textarea"
                  onChange={(newValue) => {
                    if (!saving) {
                      setSubscriberEmailAddressesString(newValue);
                    }
                  }}
                  value={subscriberEmailAddressesString}
                />
                <HelpBlock>{t("subscriberEmailAddressesHint")}</HelpBlock>
              </FormGroup>
            </Form>
          ) : (
            <>
              <div className="row mb-4">
                <div className="col subscribers-modal__editorial-picker">
                  <ControlLabel>{t("applyEditorials")}</ControlLabel>
                  <TagPicker
                    className="d-block"
                    data={Object.values(editorials)
                      .map((editorial) => {
                        const editorialOptions: {
                          label: string;
                          value: {
                            editorialId: string;
                            useMail?: true;
                            useApp?: true;
                          };
                        }[] = [
                          {
                            label: `${editorial.name} (E-mail)`,
                            value: {
                              editorialId: editorial.editorialId!,
                              useMail: true,
                            },
                          },
                        ];

                        if (currentCustomer?.appEnabled) {
                          editorialOptions.push({
                            label: `${editorial.name} (App)`,
                            value: {
                              editorialId: editorial.editorialId!,
                              useApp: true,
                            },
                          });
                        }

                        return editorialOptions;
                      })
                      .flat()}
                    value={editorialSelection}
                    onChange={(newValue) => {
                      if (!saving) {
                        setEditorialSelection(newValue);
                      }
                    }}
                    disabled={saving}
                    placeholder={t("applyEditorialsPlaceholder")}
                    cleanable={false}
                  />
                </div>
                <div className="col-auto align-self-end">
                  <Button
                    appearance="primary"
                    disabled={saving}
                    onClick={() => {
                      setNewlyAddedSubscribers((newlyAddedSubscribers) =>
                        newlyAddedSubscribers.map(
                          (newlyAddedSubscriber, index) => {
                            if (selectedSubscriberIndexes.includes(index)) {
                              const newNewSubscriber = cloneDeep(
                                newlyAddedSubscribers[index],
                              );
                              editorialSelection.forEach((item) => {
                                if (
                                  !newNewSubscriber.editorialSubscriptions[
                                    item.editorialId
                                  ]
                                ) {
                                  newNewSubscriber.editorialSubscriptions[
                                    item.editorialId
                                  ] = {};
                                }
                                if (item.useMail) {
                                  newNewSubscriber.editorialSubscriptions[
                                    item.editorialId
                                  ].useMail = true;
                                }
                                if (item.useApp) {
                                  newNewSubscriber.editorialSubscriptions[
                                    item.editorialId
                                  ].useApp = true;
                                }
                              });
                              return newNewSubscriber;
                            }
                            return newlyAddedSubscriber;
                          },
                        ),
                      );
                    }}
                  >
                    {t("toApply")}
                  </Button>
                </div>
              </div>

              <SchemaTable<ISubscriber>
                height={windowInnerHeight - 500}
                schema={ISubscriberSchema}
                data={newlyAddedSubscribers || []}
                sortable
                getSelected={getSelected}
                setSelected={setSelected}
                getSelectAll={getSelectAll}
                setSelectAll={setSelectAll}
                columnsConfigs={[
                  {
                    name: "displayName",
                    renderCell: (rowData, index) => {
                      return (
                        <Input
                          className="input-gray"
                          style={{ marginTop: -6 }}
                          value={rowData.displayName || ""}
                          onChange={(newDisplayName) => {
                            if (saving) {
                              return;
                            }
                            setNewlyAddedSubscribers(
                              (currentNewlyAddedSubscribers) => {
                                if (!currentNewlyAddedSubscribers) {
                                  return currentNewlyAddedSubscribers;
                                }
                                const newNewlyAddedSubscribers = [
                                  ...currentNewlyAddedSubscribers,
                                ];
                                newNewlyAddedSubscribers[index].displayName =
                                  newDisplayName;
                                return newNewlyAddedSubscribers;
                              },
                            );
                          }}
                          disabled={saving}
                        />
                      );
                    },
                  },
                  { name: "email" },
                  {
                    name: "editorialIds",
                    title: "memberOf",
                    renderCell: (rowData) => {
                      const editorialNames = Object.keys(
                        rowData.editorialSubscriptions,
                      )
                        .map((editorialId) => editorials[editorialId]?.name)
                        .filter((editorialName) => !!editorialName);
                      return (
                        <span title={editorialNames.join(", \n")}>
                          {editorialNames.join(", ")}
                        </span>
                      );
                    },
                  },
                ]}
              />

              <div className="mb-3">
                <Checkbox
                  checked={confirmationIsChecked}
                  onChange={(_: unknown, checked) =>
                    setConfirmationIsChecked(checked)
                  }
                >
                  {t("addSubscribersConfirmation")}
                  {confirmationError ? (
                    <HelpBlock style={{ color: "red" }}>
                      {t(confirmationError)}
                    </HelpBlock>
                  ) : null}
                </Checkbox>
              </div>
            </>
          )}
          {saving ? <Loader backdrop content={t("saving")} vertical /> : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SubscribersModal;
