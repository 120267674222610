import AJV from "ajv";
import addFormats from "ajv-formats";
import openapi from "../openapi.json";
import { components } from "../types/openapi";

const ajv = new AJV().addFormat("password", (value: string) => {
  return !!(
    (
      value &&
      value.length > 5 && // ten minste 6 karakters
      value.match(/[a-z]/i) && // een letter
      value.match(/[0-9]/) && // een cijfer
      value.match(/[^a-z0-9]/)
    ) // een leesteken
  );
});

addFormats(ajv);
ajv.addSchema(
  Object.keys(openapi.components.schemas).map((schemaName) => ({
    $id: `#/components/schemas/${schemaName}`,
    ...openapi.components.schemas[schemaName as keyof components["schemas"]],
  })),
);

export default ajv;
