import * as React from "react";
import { SVGProps } from "react";

const ChartPoints = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={20}
    viewBox="0 0 45 20"
    {...props}
  >
    <path
      fill="none"
      stroke="#9baeaf"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M2.12 18.048L14.171 6.481l5.411 8.208L32.113 2.124"
    />
  </svg>
);

export default ChartPoints;
