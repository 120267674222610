import React from "react";
import { I18nContext } from "../../provider/I18nProvider";
import { Icon, Input, InputGroup } from "rsuite";
import MagnifyingGlass from "../../icons/MagnifyingGlass";
import "./index.scss";
import { advancedMatchStringToHTMLString } from "../../inc/advancedMatch";

interface IAdvancedMatchInputProps {
  editable: boolean;
  queryMatchText: string;
  onChange: (queryMatchText: string) => void;
  onEnterPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const AdvancedMatchInput = ({
  editable,
  queryMatchText,
  onChange,
  onEnterPress,
}: IAdvancedMatchInputProps) => {
  const { t } = React.useContext(I18nContext);
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);
  const inputRef = React.useRef(null);
  const HTMlPreview = React.useMemo(
    (): string => advancedMatchStringToHTMLString(queryMatchText),
    [queryMatchText]
  );
  return !editable ? (
    <>
      <h5 className="me-auto" style={{ whiteSpace: "nowrap" }}>
        {t("advancedMatchConfig_searchArticlesWith")}
      </h5>
      <div
        className="advanced-match-input__preview"
        dangerouslySetInnerHTML={{ __html: HTMlPreview }}
      />
    </>
  ) : (
    <InputGroup
      className="advanced-match-input__input-group"
      style={{ marginRight: "2rem" }}
      size="sm"
      inside
    >
      <InputGroup.Addon>
        <Icon icon="question" componentClass={MagnifyingGlass} />
      </InputGroup.Addon>
      <div
        className={`advanced-match-input__input-group__preview${
          isInputFocused ? " hide" : ""
        }`}
        onClick={() => {
          if (inputRef.current) {
            // @ts-ignore
            inputRef.current.focus();
          }
        }}
        dangerouslySetInnerHTML={{ __html: HTMlPreview }}
      />
      <Input
        inputRef={inputRef}
        placeholder={t("matchTextPlaceholder")}
        autoFocus={true}
        value={queryMatchText}
        onChange={onChange}
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onPressEnter={onEnterPress}
      />
    </InputGroup>
  );
};
export default AdvancedMatchInput;
