import React from "react";
import "./index.scss";
import { components } from "../../../../types/openapi";
import { AuthContext } from "../../../../provider/AuthProvider";

interface IScreenshotTabProps {
  mediaItem?: components["schemas"]["MediaItem"];
  mediaItemView?: components["schemas"]["MediaItemView"];
}

const ScreenshotTab = ({ mediaItem, mediaItemView }: IScreenshotTabProps) => {
  const { auth } = React.useContext(AuthContext);

  return (
    <div className="components__media-item__screenshot-tab">
      <img
        alt={mediaItem ? mediaItem.title : "Screenshot"}
        src={`${mediaItemView?.imageUrl}${
          auth ? `?bearer=${auth.bearer}` : ""
        }`}
      />
    </div>
  );
};

export default ScreenshotTab;
