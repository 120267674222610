import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useMediaItemPurchases = singletonHook(undefined, () => {
  const { mediaItemPurchases, setMediaItemPurchases } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (mediaItemPurchases !== undefined || !auth?.bearer) {
      return;
    }
    setMediaItemPurchases(null);

    axios
      .get<components["schemas"]["MediaItemPurchase"][]>(
        "/mediaItemPurchase/crud"
      )
      .then((res) => {
        setMediaItemPurchases(
          res.data.reduce<IHashMap<components["schemas"]["MediaItemPurchase"]>>(
            (prev, mediaItemPurchase) => {
              prev[mediaItemPurchase.mediaItemPurchaseId as string] =
                mediaItemPurchase;
              return prev;
            },
            {}
          )
        );
      });
  }, [auth?.bearer, setMediaItemPurchases, mediaItemPurchases]);

  return mediaItemPurchases;
});

export default useMediaItemPurchases;
