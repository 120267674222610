import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { TDashboard } from "../types";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useDashboards = () => {
  const { dashboards, setDashboards } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      dashboards !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }

    setDashboards(null);
    axios.get<TDashboard[]>("/dashboard/crud").then((res) => {
      setDashboards(
        res.data.reduce<IHashMap<TDashboard>>((prev, dashboard) => {
          prev[dashboard.dashboardId as string] = dashboard;
          return prev;
        }, {})
      );
    });
  }, [dashboards, auth?.bearer, auth?.jwt.userRoles, setDashboards]);

  return dashboards;
};

export default useDashboards;
