import { Modal } from "rsuite";
import MediaItem from "../../../../components/MediaItem";
import React from "react";
import { components } from "../../../../types/openapi";
import "./index.scss";
import { LayoutContext } from "../../../../provider/LayoutProvider";

interface IMediaItemModalProps {
  mediaItem?: components["schemas"]["MediaItem"];
  close: () => void;
  onMediaItemChange?: (mediaItem: components["schemas"]["MediaItem"]) => void;
  onMediaItemDelete: () => void;
}

const MediaItemModal = ({
  mediaItem,
  close,
  onMediaItemChange,
  onMediaItemDelete,
}: IMediaItemModalProps) => {
  const { windowInnerHeight } = React.useContext(LayoutContext);

  return (
    <Modal
      show={!!mediaItem}
      onHide={close}
      size="lg"
      className="views__search-view__media-item-modal"
      dialogClassName="views__search-view__media-item-modal__dialog"
    >
      <Modal.Body style={{ maxHeight: windowInnerHeight }}>
        {mediaItem ? (
          <MediaItem
            mediaItem={mediaItem}
            onChange={onMediaItemChange}
            onDelete={onMediaItemDelete}
            onClose={close}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default MediaItemModal;
