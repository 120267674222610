import React, { SVGProps } from "react";

const CompanyAvatarSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="prefix__Placeholder_klant"
    data-name="Placeholder klant"
    xmlns="http://www.w3.org/2000/svg"
    width={21.328}
    height={22.214}
    viewBox="0 0 21.328 22.214"
    {...props}
  >
    <defs>
      <style>{".prefix__cls-1{fill:#1e324e}"}</style>
    </defs>
    <g id="prefix__Group_1644" data-name="Group 1644">
      <path
        id="prefix__Path_2010"
        data-name="Path 2010"
        className="prefix__cls-1"
        d="M1776.849 459.265h5.515a.728.728 0 100-1.457h-5.515a.728.728 0 100 1.457z"
        transform="translate(-1772.336 -453.466)"
      />
      <path
        id="prefix__Path_2011"
        data-name="Path 2011"
        className="prefix__cls-1"
        d="M1795.53 472.347h-4.064a.728.728 0 100 1.457h4.064a.728.728 0 100-1.457z"
        transform="translate(-1776.975 -458.81)"
      />
      <path
        id="prefix__Path_2012"
        data-name="Path 2012"
        className="prefix__cls-1"
        d="M1795.53 478.007h-4.064a.728.728 0 100 1.457h4.064a.728.728 0 100-1.457z"
        transform="translate(-1776.975 -460.891)"
      />
      <path
        id="prefix__Path_2013"
        data-name="Path 2013"
        className="prefix__cls-1"
        d="M1795.53 466.087h-4.064a.728.728 0 100 1.457h4.064a.728.728 0 100-1.457z"
        transform="translate(-1776.975 -456.509)"
      />
      <path
        id="prefix__Path_2014"
        data-name="Path 2014"
        className="prefix__cls-1"
        d="M1788.66 457.126h-3.143v-2.01a4.178 4.178 0 00-4.174-4.173h-6.67a4.178 4.178 0 00-4.173 4.173v17.208a.834.834 0 00.834.833h19.66a.834.834 0 00.834-.833v-12.03a3.172 3.172 0 00-3.168-3.168zm-16.492-2.01a2.509 2.509 0 012.505-2.506h6.67a2.509 2.509 0 012.506 2.506v2.025c.05 0 .1-.015.148-.015a3.168 3.168 0 00-2.888 1.879h-6.257a.834.834 0 100 1.667h5.977v2.319h-5.977a.834.834 0 100 1.668h5.977v6.831h-1.241v-3.546h-3.16v3.546h-4.26zm17.993 16.374h-7.661v-11.2a1.5 1.5 0 011.5-1.5h4.663a1.5 1.5 0 011.5 1.5z"
        transform="translate(-1770.5 -450.943)"
      />
    </g>
  </svg>
);

export default CompanyAvatarSmall;
