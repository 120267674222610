import React, { SVGProps } from "react";

const Minus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <g fill="none" stroke="#1e324e" strokeWidth={1.5}>
      <circle cx={11} cy={11} r={11} stroke="none" />
      <circle cx={11} cy={11} r={10.25} />
    </g>
    <line x1={7} x2={15} y1={11.5} y2={11.5} stroke="#1e324e" />
  </svg>
);

export default Minus;
