import React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  InputGroup,
  Popover,
} from "rsuite";
import "./index.scss";
import MagnifyingGlass from "../../icons/MagnifyingGlass";
import { TypeAttributes } from "rsuite/lib/@types/common";
import { containsWordThatStartWith } from "../../inc/query";
import MwWhisper from "../MwWhisper";

type TCheckboxPopoverData = {
  value: string;
  label: string;
};

interface ICheckboxPopoverProps {
  innerRef?: React.Ref<any>;
  searchable?: boolean;
  children: React.ReactNode;
  values: string[];
  onChange?: (values?: string[]) => void;
  data: TCheckboxPopoverData[];
  placement?: TypeAttributes.Placement | TypeAttributes.Placement4;
}

const CheckboxPopover = (props: ICheckboxPopoverProps) => {
  const {
    innerRef,
    searchable,
    children,
    values,
    onChange,
    data,
    placement = "bottom",
  } = props;
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const results = React.useMemo(
    () => containsWordThatStartWith(data, "label", searchQuery),
    [data, searchQuery]
  );
  return (
    <MwWhisper
      placement={placement}
      trigger="click"
      onClose={
        onChange
          ? () => {
              if (!values.length) {
                onChange();
              }
            }
          : undefined
      }
      ref={innerRef}
      speaker={
        <Popover className="popover-without-arrow checkbox-popover">
          {searchable ? (
            <InputGroup inside>
              <InputGroup.Addon>
                <Icon icon="star" componentClass={MagnifyingGlass} />
              </InputGroup.Addon>
              <Input
                size="sm"
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
              />
            </InputGroup>
          ) : null}
          <div>
            <CheckboxGroup value={values} onChange={onChange}>
              {results.map((result) => (
                <Checkbox
                  className="checkbox-max-width"
                  key={result.value}
                  value={result.value}
                >
                  {result.label}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </div>
        </Popover>
      }
    >
      {children}
    </MwWhisper>
  );
};
export default CheckboxPopover;
