import React from "react";
import { components } from "../../../../types/openapi";
import AdvancedSearchEngineResultsBase from "./AdvancedSearchEngineResultsBase";
import _ from "lodash";

type TResults =
  | components["schemas"]["Category"]
  | components["schemas"]["Source"]
  | components["schemas"]["Author"]
  | components["schemas"]["SearchTopic"];

interface IAdvancedSearchEngineResultsProps {
  title: string;
  searchQuery: string;
  results?: TResults[] | null;
  onChange: (ids: string[]) => void;
  activeIds: string[];
}

const AdvancedSearchEngineResults = (
  props: IAdvancedSearchEngineResultsProps
) => {
  const { title, searchQuery, results, activeIds, onChange } = props;
  const getResultId = React.useCallback((result: TResults) => {
    if ("categoryId" in result) {
      return result.categoryId || "";
    } else if ("sourceId" in result) {
      return result.sourceId || "";
    } else if ("authorId" in result) {
      return result.authorId || "";
    } else if ("searchTopicId" in result) {
      return result.searchTopicId || "";
    }
    return "";
  }, []);

  const allResultIds = React.useMemo(
    () => results?.map((result) => getResultId(result)) || [],
    [getResultId, results]
  );

  return (
    <AdvancedSearchEngineResultsBase
      title={title}
      searchQuery={searchQuery}
      values={_.intersection(activeIds, allResultIds)} // Only return the activeIds that are part of the result set
      results={results}
      onChange={(ids) => {
        // Return the activeIds (that aren't part of the result set) and the new ids
        const otherIds = _.difference(activeIds, allResultIds);
        onChange([...otherIds, ...ids]);
      }}
      getResultId={getResultId}
      allResultIds={allResultIds}
    />
  );
};
export default AdvancedSearchEngineResults;
