import React from "react";
import { Icon, IconButton, IconButtonProps } from "rsuite";
import Plus from "../../icons/Plus";
import "./index.scss";

const ActionButton = (props: IconButtonProps) => {
  const {
    icon,
    onClick,
    title,
    className = "",
    placement = "right",
    ...restProps
  } = props;
  return (
    <IconButton
      className={`border-0 btn--big-icon action-button ${className}`}
      icon={icon || <Icon icon="plus" componentClass={Plus} />}
      placement={placement}
      size="xs"
      appearance="ghost"
      color="blue"
      onClick={onClick}
      circle={!title}
      type="button"
      {...restProps}
    >
      {title}
    </IconButton>
  );
};
export default ActionButton;
