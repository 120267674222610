import React, { SVGProps } from "react";
import { components } from "../../../types/openapi";
import {
  Alert,
  Button,
  Col,
  FlexboxGrid,
  Icon,
  Modal,
  Nav,
  Sidebar,
  Sidenav,
} from "rsuite";
import {
  dashboardWidgetDefinitions,
  EDashboardWidgetType,
  ESplitType,
  EValueType,
  IDashboardWidget,
} from "../../../components/dashboardWidgets";
import { v4 } from "uuid";
import ExistingDashboardView from "../../../views/DashboardView/ExistingDashboardView";
import "./index.scss";
import { IMaartenError, TDashboard } from "../../../types";
import axios from "../../../inc/axios";
import { LayoutContext } from "../../../provider/LayoutProvider";
import DashboardAnalysis from "../../../icons/DashboardAnalysis";
import DashboardMonitoring from "../../../icons/DashboardMonitoring";
import DashboardRead from "../../../icons/DashboardRead";
import DashboardEmpty from "../../../icons/DashboardEmpty";
import { useHistory } from "react-router-dom";
import { APP_PATH } from "../../../inc/constants";
import { getEmptyObject } from "../../../inc/schema";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { AxiosError } from "axios";
import Tour from "reactour";
import { I18nContext } from "../../../provider/I18nProvider";
import { nl2br } from "../../../inc/text";
import useDashboards from "../../../hooks/useDashboards";
import ReactGA from "react-ga";
import { EChartType } from "../../../inc/enums";

export enum ETemplateType {
  TEMPLATE_TYPE_ANALYSIS = "TEMPLATE_TYPE_ANALYSIS",
  TEMPLATE_TYPE_MONITORING = "TEMPLATE_TYPE_MONITORING",
  TEMPLATE_TYPE_READING = "TEMPLATE_TYPE_READING",
  TEMPLATE_TYPE_EMPTY = "TEMPLATE_TYPE_EMPTY",
}

type TGetTemplateDefinitions = (translator: (text: string) => string) => {
  [templateType: string]: {
    icon?: React.ElementType<SVGProps<SVGSVGElement>>;
    description?: string;
    name: string;
    dashboard: TDashboard;
  };
};

export const getTemplateDefinitions: TGetTemplateDefinitions = (
  translator,
) => ({
  [ETemplateType.TEMPLATE_TYPE_EMPTY]: {
    name: translator(ETemplateType.TEMPLATE_TYPE_EMPTY),
    icon: DashboardEmpty,
    description: translator(
      "dashboardTemplateDialog_templateType_empty_description",
    ),
    dashboard: {
      name: translator(ETemplateType.TEMPLATE_TYPE_EMPTY),
      config: {
        widgets: [],
      },
      period: { dateType: "insertDate", periodType: "lastMonth" },
    },
  },
  [ETemplateType.TEMPLATE_TYPE_READING]: {
    name: translator(ETemplateType.TEMPLATE_TYPE_READING),
    description: translator(
      "dashboardTemplateDialog_templateType_read_description",
    ),
    icon: DashboardRead,
    dashboard: {
      name: translator(ETemplateType.TEMPLATE_TYPE_READING),
      config: {
        widgets: [
          {
            ...dashboardWidgetDefinitions[
              EDashboardWidgetType.WIDGET_TYPE_MEDIAITEM
            ],
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIAITEM,
            uid: v4(),
            layouts: {
              xs: {
                x: 0,
                y: 0,
                w: 1,
                h: 8,
              },
              lg: {
                x: 0,
                y: 0,
                w: 3,
                h: 10,
              },
            },
            widgetConfig: {
              display: "block",
              sort: "mediaItemSortType_insertDateAsc",
              columnLayout: [],
            },
          },
        ],
      },
      period: { dateType: "insertDate", periodType: "lastMonth" },
    },
  },
  [ETemplateType.TEMPLATE_TYPE_MONITORING]: {
    name: translator(ETemplateType.TEMPLATE_TYPE_MONITORING),
    icon: DashboardMonitoring,
    description: translator(
      "dashboardTemplateDialog_templateType_monitoring_description",
    ),
    dashboard: {
      name: translator(ETemplateType.TEMPLATE_TYPE_MONITORING),
      config: {
        widgets: [
          {
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIAITEM,
            // a fixed uid, so mediaitems can always open on top of a "new" dashboard using DashboardHashData
            uid: "default_monitoring_mediaItem_widget_id",
            layouts: {
              xs: {
                w: 1,
                h: 8,
                x: 0,
                y: 4,
              },
              lg: {
                w: 3,
                h: 12,
                x: 0,
                y: 0,
              },
            },
            widgetConfig: {
              display: "block",
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_HIGHLIGHT,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 6,
                x: 0,
                y: 0,
              },
              lg: {
                w: 1,
                h: 6,
                x: 0,
                y: 12,
              },
            },
            widgetConfig: {},
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_TREND,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 12,
              },
              lg: {
                w: 1,
                h: 6,
                x: 1,
                y: 12,
              },
            },
            widgetConfig: {},
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIA_TYPE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 17,
              },
              lg: {
                w: 1,
                h: 6,
                x: 2,
                y: 12,
              },
            },
            widgetConfig: { chartType: "column" },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SOURCE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 22,
              },
              lg: {
                w: 1,
                h: 6,
                x: 0,
                y: 18,
              },
            },
            widgetConfig: {
              chartType: "bar",
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_AUTHOR,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 22,
              },
              lg: {
                w: 1,
                h: 6,
                x: 1,
                y: 18,
              },
            },
            widgetConfig: {},
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SEARCH_TOPIC,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 27,
              },
              lg: {
                w: 1,
                h: 6,
                x: 2,
                y: 18,
              },
            },
            widgetConfig: {
              chartType: "donut",
              limit: 10,
              showRemaining: true,
              showValueTypeInTitle: true,
            },
          },
        ],
      },
      period: { dateType: "insertDate", periodType: "lastMonth" },
    },
  },
  [ETemplateType.TEMPLATE_TYPE_ANALYSIS]: {
    name: translator(ETemplateType.TEMPLATE_TYPE_ANALYSIS),
    icon: DashboardAnalysis,
    description: translator(
      "dashboardTemplateDialog_templateType_analyse_description",
    ),
    dashboard: {
      name: translator(ETemplateType.TEMPLATE_TYPE_ANALYSIS),
      config: {
        widgets: [
          {
            type: EDashboardWidgetType.WIDGET_TYPE_HIGHLIGHT,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 6,
                x: 0,
                y: 0,
              },
              lg: {
                w: 3,
                h: 3,
                x: 0,
                y: 0,
              },
            },
            widgetConfig: {},
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_TREND,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 21,
              },
              lg: {
                w: 3,
                h: 5,
                x: 0,
                y: 9,
              },
            },
            widgetConfig: {},
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIA_TYPE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 55,
              },
              lg: {
                w: 1,
                h: 5,
                x: 0,
                y: 14,
              },
            },
            widgetConfig: { chartType: "column" },
          },

          {
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIA_TYPE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 31,
              },
              lg: {
                w: 1,
                h: 5,
                x: 1,
                y: 14,
              },
            },
            widgetConfig: { chartType: "column", valueType: "summedAve" },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIA_TYPE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 36,
              },
              lg: {
                w: 1,
                h: 5,
                x: 2,
                y: 14,
              },
            },
            widgetConfig: { chartType: "column", valueType: "summedReach" },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SOURCE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 41,
              },
              lg: {
                w: 1,
                h: 5,
                x: 0,
                y: 19,
              },
            },
            widgetConfig: { chartType: "donut" },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SOURCE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 46,
              },
              lg: {
                w: 1,
                h: 5,
                x: 1,
                y: 19,
              },
            },
            widgetConfig: { chartType: "donut", valueType: "summedAve" },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SOURCE,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 51,
              },
              lg: {
                w: 1,
                h: 5,
                x: 2,
                y: 19,
              },
            },
            widgetConfig: { valueType: "summedReach", chartType: "donut" },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_AUTHOR,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 52,
              },
              lg: {
                w: 1,
                h: 5,
                x: 0,
                y: 24,
              },
            },
            widgetConfig: {
              chartType: EChartType.bar,
              valueType: EValueType.count,
              splitType: ESplitType.sentiment,
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_AUTHOR,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 53,
              },
              lg: {
                w: 1,
                h: 5,
                x: 1,
                y: 24,
              },
            },
            widgetConfig: {
              chartType: EChartType.bar,
              valueType: EValueType.summedAve,
              splitType: ESplitType.sentiment,
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_AUTHOR,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 54,
              },
              lg: {
                w: 1,
                h: 5,
                x: 2,
                y: 24,
              },
            },
            widgetConfig: {
              chartType: EChartType.bar,
              valueType: "summedReach",
              splitType: ESplitType.sentiment,
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_MEDIAITEM,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 8,
                x: 0,
                y: 56,
              },
              lg: {
                w: 3,
                h: 9,
                x: 0,
                y: 29,
              },
            },
            widgetConfig: {
              display: "table",
              sort: "mediaItemSortType_publicationDateAsc",
              columnLayout: [
                {
                  propName: "publicationDate",
                  show: true,
                  width: 109,
                },
                {
                  propName: "mediaType",
                  show: true,
                  width: 56,
                },
                {
                  propName: "title",
                  show: true,
                  width: 496,
                },
                {
                  propName: "source",
                  show: true,
                  width: 224,
                },
                {
                  propName: "ave",
                  show: true,
                },
                {
                  propName: "circulation",
                  show: true,
                  width: 96,
                },
                {
                  propName: "reach",
                  show: false,
                },
                {
                  propName: "prValue",
                  show: true,
                  width: 110,
                },
                {
                  propName: "sentiment",
                  show: true,
                  width: 62,
                },
                {
                  propName: "searchTopicIds",
                  show: true,
                  width: 62,
                },
                {
                  propName: "labelIds",
                  show: true,
                  width: 61,
                },
                {
                  propName: "insertDate",
                  show: false,
                },
                {
                  propName: "isFavourite",
                  show: false,
                },
              ],
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SEARCH_TOPIC,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 6,
              },
              lg: {
                w: 1,
                h: 6,
                x: 0,
                y: 3,
              },
            },
            widgetConfig: {
              chartType: "donut",
              limit: 10,
              showRemaining: true,
              showValueTypeInTitle: true,
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SEARCH_TOPIC,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 11,
              },
              lg: {
                w: 1,
                h: 6,
                x: 1,
                y: 3,
              },
            },
            widgetConfig: {
              chartType: "donut",
              valueType: "summedAve",
              limit: 10,
              showRemaining: true,
              showValueTypeInTitle: true,
            },
          },
          {
            type: EDashboardWidgetType.WIDGET_TYPE_SEARCH_TOPIC,
            uid: v4(),
            layouts: {
              xs: {
                w: 1,
                h: 5,
                x: 0,
                y: 16,
              },
              lg: {
                w: 1,
                h: 6,
                x: 2,
                y: 3,
              },
            },
            widgetConfig: {
              chartType: "donut",
              valueType: "summedReach",
              limit: 10,
              showRemaining: true,
              showValueTypeInTitle: true,
            },
          },
        ],
      },
      period: { dateType: "insertDate", periodType: "lastMonth" },
    },
  },
});

interface IDashboardTemplateDialogProps {
  setDashboardConfig?: (config?: { widgets?: IDashboardWidget[] }) => void;
  show: boolean;
}

const emptyFilter = getEmptyObject<components["schemas"]["Filter"]>(
  openapi.components.schemas.Filter as oas30.SchemaObject,
);
const emptyPeriod = getEmptyObject<components["schemas"]["Period"]>(
  openapi.components.schemas.Period as oas30.SchemaObject,
);

const DashboardTemplateDialog = (props: IDashboardTemplateDialogProps) => {
  const { setIsTemplateDialogOpen } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const { updateDashboard } = React.useContext(ApiDataContext);
  const dashboards = useDashboards();

  const [menuTourIsOpen, setMenuTourIsOpen] = React.useState<boolean>(false);
  const [submitTourIsOpen, setSubmitTourIsOpen] =
    React.useState<boolean>(false);
  const { setDashboardConfig, show } = props;
  const [filterCacheRequest, setFilterCacheRequest] =
    React.useState<components["schemas"]["FilterCacheRequest"]>();
  const [filterCacheResponse, setFilterCacheResponse] = React.useState<
    components["schemas"]["FilterCacheResponse"] | null
  >();
  const [templatePreviewType, setTemplatePreviewType] =
    React.useState<ETemplateType>();
  const [dashboardWidth, setDashboardWidth] = React.useState<number>(0);
  const [renderAttempt, setRenderAttempt] = React.useState<number>(0);
  const dashboardWrapperRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();

  React.useEffect(() => {
    setFilterCacheRequest({
      filter: emptyFilter,
      period: { periodType: "lastMonth", dateType: "insertDate" },
    });
  }, []);

  React.useEffect(() => {
    if (!filterCacheRequest) {
      setFilterCacheResponse(undefined);
      return;
    }
    setFilterCacheResponse(null);
    axios
      .post<components["schemas"]["FilterCacheResponse"]>(
        "/filter/cache",
        filterCacheRequest,
      )
      .then((res) => {
        setFilterCacheResponse(res.data);
      });
  }, [filterCacheRequest]);

  React.useEffect(() => {
    const node = dashboardWrapperRef.current;
    if (!show || !node || !node.firstChild) {
      setTimeout(() => {
        setRenderAttempt(renderAttempt + 1);
      }, 100);
      return;
    }
    setDashboardWidth((node.firstChild as HTMLElement).clientWidth - 4);
  }, [show, renderAttempt]);

  const templateDefinitions = React.useMemo(
    () => getTemplateDefinitions(t),
    [t],
  );

  const templateDefinition = templatePreviewType
    ? templateDefinitions[templatePreviewType]
    : null;

  const createDashboard = React.useCallback(() => {
    if (!templateDefinition || !dashboards) {
      return;
    }
    setIsTemplateDialogOpen(false);
    const dashboard: TDashboard = {
      ...templateDefinition.dashboard,
      name: templateDefinition.name,
      config: {
        ...templateDefinition.dashboard.config,
        widgets: templateDefinition.dashboard.config.widgets?.map((widget) => ({
          ...dashboardWidgetDefinitions[widget.type],
          ...widget,
        })),
      },
    };
    const names = Object.values(dashboards).map((dashboard) => dashboard.name);
    let attempt = 1;
    while (names.find((name) => name === dashboard.name)) {
      dashboard.name = `${templateDefinition.name} (${++attempt})`;
    }

    updateDashboard(dashboard)
      .then((dashboard) => {
        history.push(`${APP_PATH}/dashboards/${dashboard.dashboardId}`);
      })
      .catch((err: AxiosError<IMaartenError>) => {
        Alert.error(t(err.response?.data.error || "genericErrorMessage"));
      });
  }, [
    dashboards,
    history,
    setIsTemplateDialogOpen,
    t,
    templateDefinition,
    updateDashboard,
  ]);

  const onDashboardClick = React.useCallback(() => {
    if (templateDefinition) {
      setSubmitTourIsOpen(true);
    }
  }, [templateDefinition]);

  return (
    <Modal
      show={show}
      full
      size={"lg"}
      // dialogClassName="d-none"
      backdrop
      onHide={() => {
        if (setDashboardConfig) {
          setDashboardConfig();
          return;
        }
        setTemplatePreviewType(undefined);
        setIsTemplateDialogOpen(false);
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {setDashboardConfig ? t("chooseTemplate") : t("createDashboardTitle")}
        </Modal.Title>
        <div>
          <Button
            block
            appearance="primary"
            color="green"
            className="px-3"
            disabled={!templateDefinition}
            onClick={() => {
              if (setDashboardConfig) {
                if (!templateDefinition) {
                  return;
                }
                ReactGA.event({
                  category: "Dashboards",
                  action: `Template wisselen`,
                });
                setDashboardConfig(templateDefinition.dashboard.config);
                return;
              }
              createDashboard();
            }}
          >
            {setDashboardConfig ? t("apply") : t("createDashboard")}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body
        className="modal-body-no-padding"
        style={{
          maxHeight: "calc(100vh - 140px)",
          height: "calc(100vh - 140px)",
          paddingBottom: 0,
          marginLeft: -20,
          marginRight: -20,
          marginBottom: -20,
          marginTop: 22,
          paddingRight: 0,
        }}
      >
        <div
          className={`w-100 layout__app-layout__dashboard-template-dialog${
            show ? " layout__app-layout__dashboard-template-dialog--shown" : ""
          }`}
        >
          <Sidebar
            className="layout__app-layout__dashboard-template-dialog__sidebar"
            width={250}
          >
            <Sidenav
              className="layout__app-layout__dashboard-template-dialog__sidenav"
              appearance="inverse"
              onSelect={(eventKey) => {
                if (eventKey) {
                  setMenuTourIsOpen(false);
                  setTemplatePreviewType(eventKey);
                }
              }}
              activeKey={templatePreviewType}
            >
              <Sidenav.Body className="layout__app-layout__dashboard-template-dialog__sidenav__body">
                <Nav
                  vertical
                  appearance="subtle"
                  reversed
                  className="layout__app-layout__dashboard-template-dialog__sidenav__menu-brand"
                >
                  {/*<Nav.Item componentClass={"span"} className="remove-ripple">*/}
                  {/*  {t("templates")}*/}
                  {/*</Nav.Item>*/}
                </Nav>

                <Nav
                  vertical
                  appearance="subtle"
                  reversed
                  className="layout__app-layout__dashboard-template-dialog__sidenav__menu-middle"
                >
                  {Object.entries(templateDefinitions).map(
                    ([templateType, templateDefinition]) => {
                      const TemplateIcon = templateDefinition.icon;
                      return (
                        <Nav.Item
                          key={templateType}
                          eventKey={templateType}
                          className="remove-ripple"
                          icon={
                            TemplateIcon ? (
                              <TemplateIcon
                                style={{
                                  position: "absolute",
                                  left: 32,
                                }}
                              />
                            ) : undefined
                          }
                        >
                          {templateDefinition.name}
                        </Nav.Item>
                      );
                    },
                  )}
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            {templateDefinition?.description ? (
              <div className="m-3 p-3" style={{ backgroundColor: "white" }}>
                <h4>{templateDefinition.name}</h4>
                {templateDefinition.description}
              </div>
            ) : null}
          </Sidebar>
          <div
            className="layout__app-layout__dashboard-template-dialog__preview"
            ref={dashboardWrapperRef}
            onClick={onDashboardClick}
          >
            {templateDefinition ? (
              <ExistingDashboardView
                dashboard={templateDefinition.dashboard}
                filter={emptyFilter}
                dashboardWidth={dashboardWidth}
                period={filterCacheRequest?.period || emptyPeriod}
                filterCacheResponse={filterCacheResponse}
                setFilterCacheResponse={setFilterCacheResponse}
              />
            ) : (
              <FlexboxGrid
                justify="center"
                className="layout__app-layout__dashboard-template-dialog__image-container"
                onClick={() => setMenuTourIsOpen(true)}
              >
                <FlexboxGrid.Item colspan={24}>
                  <h3 className="m-3 text-center d-flex flex-row justify-content-center">
                    <Icon icon="back-arrow" size="lg" className="me-3" />
                    <span>{t("dashboardTemplateDialog_previewAndChoose")}</span>
                  </h3>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  className="dashboard-view__content__button-wrapper"
                  componentClass={Col}
                  colspan={24}
                  sm={12}
                  md={10}
                >
                  <p
                    style={{
                      fontSize: 18,
                      lineHeight: 1.33,
                      textAlign: "center",
                      marginBottom: "3rem",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: nl2br(
                        t("dashboardTemplateDialog_templateDescription"),
                      ),
                    }}
                  />
                </FlexboxGrid.Item>
              </FlexboxGrid>
            )}
          </div>
        </div>
        <Tour
          showButtons={false}
          showCloseButton={false}
          showNavigation={false}
          showNavigationNumber={false}
          showNumber={false}
          steps={[
            {
              selector:
                ".layout__app-layout__dashboard-template-dialog__sidenav__menu-middle",
              content: t("dashboardTemplateDialog_chooseATemplate"),
            },
          ]}
          isOpen={menuTourIsOpen}
          onRequestClose={() => {
            setMenuTourIsOpen(false);
          }}
        />
        <Tour
          showButtons={false}
          showCloseButton={false}
          showNavigation={false}
          showNavigationNumber={false}
          showNumber={false}
          steps={[
            {
              selector: ".rs-modal-header .rs-btn-primary",
              content: t(
                "dashboardTemplateDialog_withThisButtonYouCanCreateADashboard",
              ),
            },
          ]}
          isOpen={submitTourIsOpen}
          onRequestClose={() => {
            setSubmitTourIsOpen(false);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
export default DashboardTemplateDialog;
