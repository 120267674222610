import { useLocation } from "react-router-dom";
import { parseLocationSearch } from "../inc/data";

const useQueryParameters = <T extends {}>() => {
  const location = useLocation();
  return (
    location.search.length > 2 ? parseLocationSearch<T>(location.search) : {}
  ) as T;
};

export default useQueryParameters;
