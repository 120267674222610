import React, { SVGProps } from "react";

const WidgetTypeMediaItem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32.429}
    height={42.476}
    viewBox="0 0 32.429 42.476"
    {...props}
  >
    <defs>
      <style>{".svg-icons__widget-type-media-item{fill:#96afaf}"}</style>
    </defs>
    <g id="prefix__Group_1420" transform="translate(-56.257 -2.515)">
      <g id="prefix__Group_699" transform="translate(56.257 2.515)">
        <path
          id="prefix__Path_1300"
          d="M1649.168 594.886a1.627 1.627 0 01-1.627-1.628v-37.1a1.627 1.627 0 113.255 0v37.1a1.628 1.628 0 01-1.628 1.628z"
          className="svg-icons__widget-type-media-item"
          transform="translate(-1620.853 -552.411)"
        />
        <path
          id="prefix__Path_1301"
          fill="#2d50b4"
          d="M1648.666 557.641a1.624 1.624 0 00-1.525-.152l-21.289 8.771a1.627 1.627 0 00-.055 2.984l21.289 9.715a1.626 1.626 0 002.3-1.479v-18.487a1.628 1.628 0 00-.72-1.352z"
          transform="translate(-1624.845 -551.912)"
        />
        <rect
          id="prefix__Rectangle_1138"
          width={8.139}
          height={3.942}
          className="svg-icons__widget-type-media-item"
          rx={0.436}
          transform="rotate(-180 16.215 1.971)"
        />
      </g>
    </g>
  </svg>
);

export default WidgetTypeMediaItem;
