import React from "react";
import { components } from "../../../../types/openapi";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import SchemaFormBody, {
  ISchemaFormPropConfig,
  TSchemaFormErrors,
} from "../../../../components/SchemaFormBody";
import { Form } from "rsuite";
import axios from "axios";
import _ from "lodash";
import "./index.scss";
import ColorPicker from "../../../../components/form/ColorPicker";
import { isEqual } from "lodash";
import { I18nContext } from "../../../../provider/I18nProvider";

const editorialConfigSchema = openapi.components.schemas
  .EditorialConfig as oas30.SchemaObject;

const { properties } = editorialConfigSchema;
if (!properties) {
  throw new Error("Incomplete editorial config schema");
}

interface IEditorialConfigViewProps {
  editorialConfig?: components["schemas"]["EditorialConfig"];
  onChange: (editorialConfig: components["schemas"]["EditorialConfig"]) => void;
  errors?: TSchemaFormErrors;
}

const EditorialConfigView = (props: IEditorialConfigViewProps) => {
  const { editorialConfig, onChange, errors } = props;
  const { t } = React.useContext(I18nContext);
  const [hydratedEditorialConfig, setHydratedEditorialConfig] =
    React.useState<components["schemas"]["EditorialConfig"]>();
  const [preview, setPreview] =
    React.useState<components["schemas"]["EditorialPreview"]>();
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const debouncedPreviewHydrate = React.useMemo(
    () =>
      _.debounce((editorialConfig) => {
        if (isEqual(hydratedEditorialConfig, editorialConfig)) {
          return;
        }

        const contentWindow = iframeRef.current?.contentWindow;
        const scrollPos = contentWindow?.scrollY;
        setHydratedEditorialConfig(editorialConfig);
        axios.post("/editorial/preview", editorialConfig).then((res) => {
          setPreview(res.data);
          setTimeout(() => {
            if (contentWindow && scrollPos) {
              contentWindow.scrollTo(0, scrollPos);
            }
          }, 100);
        });
      }, 1000),
    [hydratedEditorialConfig],
  );

  React.useEffect(() => {
    debouncedPreviewHydrate(editorialConfig);
  }, [debouncedPreviewHydrate, editorialConfig]);

  const getFontSelectorOptions = (fonts: string[]) => {
    return fonts.map((font) => {
      if (font.includes("Helvetica,Arial")) {
        return {
          label: "Arial",
          value: font,
        };
      }
      const matches = font.match(/(.*?),.*/);
      return {
        label: (matches?.length === 2 ? matches[1] : font).replace(
          /['|"](.*?)['|"]/, // Remove the wrapping ' or " characters
          "$1",
        ),
        value: font,
      };
    });
  };

  const config = React.useMemo<{
    [P in keyof Partial<
      components["schemas"]["EditorialConfig"]
    >]: ISchemaFormPropConfig;
  }>(
    () => ({
      editorialIssueTitle: {
        header: t("editorialConfigView__header__common"),
        order: 1,
      },
      heroImageUrl: {
        order: 2,
      },
      headingFont: {
        header: t("editorialConfigView__header__headers"),
        order: 3,
        formControlProps: {
          searchable: false,
          cleanable: false,
          data: getFontSelectorOptions(
            openapi.components.schemas.EditorialConfig.properties.headingFont
              .enum,
          ),
        },
      },
      heading1: {
        order: 4,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      showSingleRubricName: {
        order: 5,
      },
      heading2: {
        order: 6,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      bodyFont: {
        header: t("editorialConfigView__header__body"),
        order: 7,
        formControlProps: {
          searchable: false,
          cleanable: false,
          data: getFontSelectorOptions(
            openapi.components.schemas.EditorialConfig.properties.bodyFont.enum,
          ),
        },
      },
      textColor: {
        order: 8,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      link: {
        order: 9,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      linkUnderline: {
        order: 10,
      },
      showAve: {
        header: t("editorialConfigView__header__show_per_article"),
        order: 11,
      },
      showReach: {
        order: 12,
      },
      showCirculation: {
        order: 13,
      },
      metadata: {
        order: 130,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      footerBackground: {
        header: t("editorialConfigView__header__footer"),
        order: 140,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      footerColumnHeading: { order: 150 },
      footerColumn: { order: 160 },
      footerColumnLinkLabel: { order: 170 },
      footerColumnLinkUrl: { order: 180 },
      footerColor: {
        order: 190,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      footerLinkColor: {
        order: 200,
        formControlProps: {
          accepter: ColorPicker,
        },
      },
      footerLinkUnderline: { order: 210 },
      footer: {
        order: 220,
      },
      socialMedia1: {
        header: t("editorialConfigView__header__socials"),
        order: 230,
      },
      socialMedia2: { order: 240 },
      socialMedia3: { order: 250 },
      socialMedia4: { order: 260 },
      socialMedia5: { order: 270 },
      socialMedia6: { order: 280 },
      socialMedia7: { order: 290 },
      socialMedia8: { order: 300 },
    }),
    [t],
  );

  return (
    <div className="row editorial-config-view">
      <div className="col-lg-5 editorial-config-view__config">
        <Form>
          <SchemaFormBody<components["schemas"]["EditorialConfig"]>
            schema={editorialConfigSchema}
            onChange={onChange}
            value={editorialConfig}
            errors={errors}
            inputClassName="input-gray"
            labelPrefix="editorialConfigView__"
            config={config}
          />
        </Form>
      </div>
      <div className="col-lg-7 editorial-config-view__preview">
        {preview ? (
          <iframe
            ref={iframeRef}
            title="previewFrame"
            srcDoc={preview.html}
            style={{ width: "100%", height: "100%" }}
          />
        ) : null}
      </div>
    </div>
  );
};
export default EditorialConfigView;
