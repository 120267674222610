import React from "react";
import axios from "../../inc/axios";
import { Alert } from "rsuite";
import { components } from "../../types/openapi";
import TranslatedText from "../../inc/i18n/TranslatedText";
import I18nProvider from "../../provider/I18nProvider";

export const resetPassword = (user: components["schemas"]["User"]) => {
  if (!user?.userName) {
    return;
  }
  axios
    .post("/auth/resetPasswordRequest", {
      userName: user.userName,
    })
    .then(() => {
      Alert.success(
        <I18nProvider>
          <TranslatedText text="emailSent" />
        </I18nProvider>
      );
    })
    .catch((err) => {
      Alert.error(
        <I18nProvider>
          <TranslatedText text={err.message || "genericErrorMessage"} />
        </I18nProvider>
      );
    });
};
