import React, { SVGProps } from "react";

const WidgetTypeHighlight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={49.5}
    height={49.5}
    viewBox="0 0 49.5 49.5"
    {...props}
  >
    <defs>
      <style>
        {
          ".svg-icons__widget-type-highlight{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:3.5px;stroke:#9baeaf}"
        }
      </style>
    </defs>
    <g id="prefix__Group_1425" transform="translate(-123.25 -460.25)">
      <g id="prefix__Group_1409" transform="translate(64.94 243.101)">
        <circle
          id="prefix__Ellipse_63"
          cx={23}
          cy={23}
          r={23}
          className="svg-icons__widget-type-highlight"
          transform="translate(60.06 218.899)"
        />
        <path
          id="prefix__Path_520"
          fill="none"
          stroke="#64bb90"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3.5}
          d="M89.19 253.772a8.02 8.02 0 01-13.592-.471"
        />
        <path
          id="prefix__Path_521"
          d="M71.954 237a5.013 5.013 0 019.4-.447"
          className="svg-icons__widget-type-highlight"
          transform="translate(-1.17 -1.122)"
        />
        <path
          id="prefix__Path_522"
          d="M89.138 237a5.013 5.013 0 019.4-.447"
          className="svg-icons__widget-type-highlight"
          transform="translate(-3.322 -1.122)"
        />
      </g>
    </g>
  </svg>
);

export default WidgetTypeHighlight;
