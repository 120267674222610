import React from "react";
import Tour, { ReactourStep } from   "reactour";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { I18nContext } from "../../../provider/I18nProvider";

const EditorialTour = () => {
  const { openTourId, setOpenTourId } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const tourRef = React.useRef(null);
  const isEditorialTourOpen = openTourId === "editorial";
  React.useEffect(() => {
    if (isEditorialTourOpen && tourRef.current) {
      disableBodyScroll(tourRef.current as any);
      return;
    }
    clearAllBodyScrollLocks();
  }, [isEditorialTourOpen]);

  const steps: ReactourStep[] = React.useMemo(
    () => [
      {
        selector: ".editorials-view__list",
        content: () => (
          <>
            <h3>{t("Dit is de nieuwsbriefmodule")}</h3>
            <p>{t("Hier kun je zelf nieuwsbrieven aanmaken")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".layout__app-layout__nav-items__editorials__overview",
        content: (
          <>
            <h3>{t("Hier vind je een nieuwsbrief die al is ingesteld")}</h3>
            <p>
              {t("Vanuit hier kun je gemakkelijk en snel een editie versturen")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".layout__app-layout__nav-items__editorials__issues",
        content: () => (
          <>
            <h3>{t("Heb je een editie verstuurd")}</h3>
            <p>
              {t("Dan kan je de statistieken van die edities hier terugvinden")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".layout__app-layout__nav-items__editorials__subscribers",
        content: (
          <>
            <h3>{t("Wil je zien wie je nieuwsbrieven ontvangt")}</h3>
            <p>{t("Dat kun je hier terugvinden")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        content: (
          <>
            <h3>{t("Meer informatie over nieuwsbrieven")}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t(
                  "Neem contact op met je accountmanager of kijk op onze supportpagina"
                ),
              }}
            />
          </>
        ),
        stepInteraction: false,
      },
    ],
    [t]
  );
  return (
    <Tour
      steps={steps}
      isOpen={isEditorialTourOpen}
      onRequestClose={() => {
        setOpenTourId(undefined);
      }}
      ref={tourRef}
    />
  );
};

export default EditorialTour;
