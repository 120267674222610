import React from "react";
import { useHistory, useParams } from "react-router-dom";
import MediaItem from "../../components/MediaItem";
import axios from "../../inc/axios";
import { components } from "../../types/openapi";
import { Loader, Message } from "rsuite";
import { APP_PATH } from "../../inc/constants";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { I18nContext } from "../../provider/I18nProvider";
import { AuthContext } from "../../provider/AuthProvider";

const MediaItemView = () => {
  const history = useHistory();
  const [mediaItem, setMediaItem] =
    React.useState<components["schemas"]["MediaItem"]>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const { customerLinkId, mediaItemId } = useParams<{
    customerLinkId: string;
    mediaItemId: string;
  }>();
  const { t } = React.useContext(I18nContext);
  const { setCurrentCustomer } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    setCurrentCustomer(customerLinkId);
  }, [customerLinkId, setCurrentCustomer]);

  React.useEffect(() => {
    // is a bearer for the right customer available yet?
    if (auth?.jwt.currentCustomerLinkId === customerLinkId) {
      setErrorMessage(undefined);
      axios
        .get<components["schemas"]["MediaItem"]>(
          `/mediaItem/crud/${mediaItemId}`
        )
        .then((res) => {
          setMediaItem(res.data);
        })
        .catch((err) => {
          setErrorMessage(t(err.response?.data.error || "genericErrorMessage"));
        });
    }
  }, [mediaItemId, auth?.jwt.currentCustomerLinkId, t, customerLinkId]);

  if (errorMessage) {
    return (
      <Message
        type="error"
        title="Er gaat iets mis"
        description={<p>{errorMessage}</p>}
      />
    );
  }

  return (
    <div
      style={{
        minHeight: "100%",
        display: "flex",
      }}
    >
      {mediaItem ? (
        <MediaItem
          mediaItem={mediaItem}
          onChange={setMediaItem}
          onDelete={() => {
            history.push(APP_PATH);
          }}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default MediaItemView;
