import React, { SVGProps } from "react";

const DashboardMonitoring = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={15.555}
      viewBox="0 0 25 15.555"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g
        id="prefix__Group_1561"
        opacity={0.703}
        transform="translate(-1483.35 -67.114)"
      >
        <path
          id="prefix__Path_1858"
          d="M1498.272 78.031a5.46 5.46 0 01-.2-10.916.574.574 0 01.434.166.6.6 0 01.182.428v4.086h4.447a.594.594 0 01.594.569v.053c0 .051.006.1.006.152a5.469 5.469 0 01-5.463 5.462zm-.774-9.663a4.274 4.274 0 105.03 4.614h-4.437a.593.593 0 01-.594-.593z"
          className="prefix__cls-2"
          transform="translate(-5.402)"
        />
        <path
          id="prefix__Path_1859"
          d="M1522.8 80.992a.594.594 0 01-.594-.594v-7.041a.594.594 0 011.187 0V80.4a.594.594 0 01-.593.592z"
          className="prefix__cls-2"
          transform="translate(-22.189 -3.227)"
        />
        <path
          id="prefix__Path_1860"
          d="M1529.166 78.155a.594.594 0 01-.594-.594v-9.17a.594.594 0 011.188 0v9.171a.594.594 0 01-.594.593z"
          className="prefix__cls-2"
          transform="translate(-25.825 -.39)"
        />
        <path
          id="prefix__Path_1861"
          d="M1506.069 88.87h-20.439a2.283 2.283 0 01-2.28-2.281v-6.335a2.283 2.283 0 012.28-2.281.593.593 0 110 1.187 1.1 1.1 0 00-1.093 1.094v6.336a1.1 1.1 0 001.093 1.093h20.438a1.094 1.094 0 001.094-1.093v-6.336a1.1 1.1 0 00-1.094-1.094.593.593 0 010-1.187 2.283 2.283 0 012.281 2.281v6.336a2.283 2.283 0 01-2.28 2.28z"
          className="prefix__cls-2"
          transform="translate(0 -6.201)"
        />
      </g>
    </svg>
  );
};

export default DashboardMonitoring;
