import React, { SVGProps } from "react";

const Eye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={9.061}
    viewBox="0 0 14 9.061"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Group_676">
      <g id="prefix__Group_675">
        <path
          id="prefix__Path_1382"
          d="M7 9.06C3.2 9.06.209 4.96.084 4.79a.446.446 0 010-.52C.209 4.1 3.195 0 7 0s6.791 4.1 6.916 4.27a.446.446 0 010 .52C13.791 4.964 10.8 9.06 7 9.06M1.009 4.53C1.713 5.4 4.168 8.169 7 8.169S12.287 5.4 12.991 4.53C12.287 3.657 9.832.891 7 .891S1.713 3.657 1.009 4.53"
        />
        <path
          id="prefix__Path_1383"
          d="M19.356 9.526A2.158 2.158 0 1117.2 7.368a2.158 2.158 0 012.158 2.158"
          transform="translate(-10.198 -4.996)"
        />
      </g>
    </g>
  </svg>
);
export default Eye;
