import Editorial from "../../icons/Editorial";
import { Popover, Tooltip } from "rsuite";
import React from "react";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import "../DropdownNavItem/index.scss";
import axios from "axios";
import { components } from "../../types/openapi";
import _ from "lodash";

import "./index.scss";
import { TypeAttributes } from "rsuite/lib/@types/common";
import IconNavItem from "../IconNavItem";
import { I18nContext } from "../../provider/I18nProvider";
import useEditorialRubrics from "../../hooks/useEditorialRubrics";
import useEditorialRubricMediaItems from "../../hooks/useEditorialRubricMediaItems";
import MwWhisper from "../MwWhisper";
import EditorialFilled from "../../icons/EditorialFilled";
import EditorialRubricCheckTree from "../EditorialRubricCheckTree";

interface IAddToEditorialNavItemProps {
  hasChevron?: boolean;
  popoverClasses?: string;
  mediaItemIds: string[];
  disabled?: boolean;
  warnForIncompleteSelection?: boolean;
  hideTitle?: boolean;
  hideSelectedCount?: boolean;
  placement?: TypeAttributes.Placement;
  forBulk: boolean;
  iconComponent?: React.JSXElementConstructor<{ className?: string }>;
}

const AddToEditorialNavItem = ({
  hasChevron,
  popoverClasses,
  disabled,
  mediaItemIds,
  warnForIncompleteSelection,
  hideTitle,
  hideSelectedCount,
  placement,
  forBulk,
}: IAddToEditorialNavItemProps) => {
  const { setEditorialRubricMediaItems } = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const editorialRubrics = useEditorialRubrics();
  const editorialRubricMediaItems = useEditorialRubricMediaItems();

  const iconComponent = React.useMemo(() => {
    if (mediaItemIds.length !== 1) {
      return Editorial;
    }
    const [mediaItemId] = mediaItemIds;
    return Object.values(editorialRubricMediaItems || {}).find(
      (editorialRubricMediaItem) =>
        editorialRubricMediaItem.mediaItemId === mediaItemId,
    )
      ? EditorialFilled
      : Editorial;
  }, [editorialRubricMediaItems, mediaItemIds]);

  const selectedEditorialRubricIds = React.useMemo(
    () =>
      editorialRubricMediaItems
        ? Object.values(editorialRubricMediaItems)
            .filter(
              (editorialRubricMediaItem) =>
                mediaItemIds.indexOf(editorialRubricMediaItem.mediaItemId) >= 0,
            )
            .map(
              (editorialRubricMediaItem) =>
                editorialRubricMediaItem.editorialRubricId,
            )
        : [],
    [editorialRubricMediaItems, mediaItemIds],
  );

  const value = React.useMemo(() => {
    if (!forBulk) {
      return selectedEditorialRubricIds;
    }

    // { editorialRubricId: occurrenceCount }
    const currentEditorialRubricIds = selectedEditorialRubricIds.length
      ? selectedEditorialRubricIds.reduce(
          (carry: { [key: string]: number }, value) => {
            if (!(value in carry)) {
              carry[value] = 0;
            }
            carry[value] += 1;
            return carry;
          },
          {},
        )
      : {};

    // For the bulk display,
    // show a difference between all mediaitems are present in rubric
    // OR some mediaitems are present in rubric
    return Object.entries(currentEditorialRubricIds).map(([key, amount]) =>
      amount === mediaItemIds.length ? key : `${key}_1`,
    );
  }, [forBulk, mediaItemIds.length, selectedEditorialRubricIds]);

  const onToggle = React.useCallback(
    (toggledEditorialRubricIds: string[], checked: boolean) => {
      if (
        warnForIncompleteSelection &&
        !window.confirm(
          "Let op: de selectie is groter dan wat er nu zichtbaar is. Alleen de zichtbare items zullen worden opgenomen.\n\nWilt u doorgaan?",
        )
      ) {
        return;
      }

      mediaItemIds.forEach((mediaItemId) => {
        const existingEditorialRubric = (
          editorialRubricMediaItems
            ? Object.values(editorialRubricMediaItems)
            : []
        ).filter(
          (editorialRubricMediaItem) =>
            editorialRubricMediaItem.mediaItemId === mediaItemId,
        );
        const existingEditorialRubricIds = existingEditorialRubric.map(
          (editorialRubricMediaItem) =>
            editorialRubricMediaItem.editorialRubricId,
        );

        if (checked) {
          _.difference(
            toggledEditorialRubricIds,
            existingEditorialRubricIds,
          ).forEach((toBeAddedEditorialRubricId) => {
            axios
              .post<components["schemas"]["EditorialRubricMediaItem"]>(
                "/editorialRubricMediaItem/crud",
                {
                  mediaItemId,
                  editorialRubricId: toBeAddedEditorialRubricId,
                  order: editorialRubricMediaItems
                    ? Object.keys(editorialRubricMediaItems).length
                    : 0,
                },
              )
              .then((res) => {
                setEditorialRubricMediaItems(
                  (currentEditorialRubricMediaItems) => {
                    if (!currentEditorialRubricMediaItems) {
                      return currentEditorialRubricMediaItems;
                    }
                    return {
                      ...currentEditorialRubricMediaItems,
                      [res.data.editorialRubricMediaItemId as string]: res.data,
                    };
                  },
                );
              });
          });
        } else {
          _.intersection(
            toggledEditorialRubricIds,
            existingEditorialRubricIds,
          ).forEach((toBeRemovedEditorialRubricId) => {
            const editorialRubricMediaItemId = existingEditorialRubric.find(
              (rubric) =>
                rubric.editorialRubricId === toBeRemovedEditorialRubricId,
            )?.editorialRubricMediaItemId;
            if (!editorialRubricMediaItemId) {
              return;
            }
            axios
              .delete(
                `/editorialRubricMediaItem/crud/${editorialRubricMediaItemId}`,
              )
              .then(() => {
                setEditorialRubricMediaItems(
                  (currentEditorialRubricMediaItems) => {
                    const newEditorialRubricMediaItems: any = {
                      ...currentEditorialRubricMediaItems,
                    };
                    delete newEditorialRubricMediaItems[
                      editorialRubricMediaItemId as string
                    ];
                    return newEditorialRubricMediaItems;
                  },
                );
              });
          });
        }
      });
    },
    [
      editorialRubricMediaItems,
      mediaItemIds,
      setEditorialRubricMediaItems,
      warnForIncompleteSelection,
    ],
  );

  const hasData = editorialRubrics && Object.keys(editorialRubrics).length;

  const navItem = React.useMemo(() => {
    return (
      <IconNavItem
        chevronPlacement={placement}
        className="components__dropdown-nav-item components__add-to-editorial-nav-item"
        title={
          hideTitle
            ? undefined
            : `${t("plusEditorial")}${
                !hideSelectedCount && selectedEditorialRubricIds.length
                  ? ` (${selectedEditorialRubricIds.length})`
                  : ""
              }`
        }
        iconComponent={iconComponent || Editorial}
        hasChevron={hasChevron}
        disabled={disabled || !hasData}
      />
    );
  }, [
    disabled,
    hasChevron,
    hasData,
    hideSelectedCount,
    hideTitle,
    iconComponent,
    placement,
    selectedEditorialRubricIds.length,
    t,
  ]);

  const speaker = React.useMemo(() => {
    return hasData ? (
      <Popover
        full
        className={`popover-without-arrow popover-without-space components__add-to-editorial-nav-item__popover ${popoverClasses}`}
      >
        <EditorialRubricCheckTree
          canSelectEditorials={false}
          value={value}
          forBulk={forBulk}
          onToggle={onToggle}
        />
      </Popover>
    ) : (
      <Tooltip>{t("noActiveEditorials")}</Tooltip>
    );
  }, [forBulk, hasData, onToggle, popoverClasses, t, value]);

  return hasData ? (
    <MwWhisper
      placement={placement || "bottomStart"}
      trigger="active"
      speaker={speaker}
    >
      {navItem}
    </MwWhisper>
  ) : (
    <MwWhisper placement="bottom" trigger="hover" speaker={speaker}>
      <span className="d-inline-block" style={{ height: 26 }}>
        {navItem}
      </span>
    </MwWhisper>
  );
};

export default React.memo(AddToEditorialNavItem);
