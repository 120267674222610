import React, { SVGProps } from "react";

const Rss = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.621}
    height={11.621}
    viewBox="0 0 11.621 11.621"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__RSS_icon">
      <g id="prefix__RSS">
        <path
          id="prefix__Path_1976"
          d="M1825.989 1209.558a.625.625 0 01-.625-.625 5.6 5.6 0 00-5.594-5.594.625.625 0 110-1.25 6.853 6.853 0 016.845 6.845.626.626 0 01-.626.624z"
          transform="translate(-1819.144 -1197.937)"
        />
        <path
          id="prefix__Path_1977"
          d="M1830.139 1204.867a.626.626 0 01-.625-.625 9.756 9.756 0 00-9.745-9.745.625.625 0 010-1.25 11.008 11.008 0 0111 11 .626.626 0 01-.63.62z"
          transform="translate(-1819.144 -1193.246)"
        />
        <g id="prefix__Group_1639" transform="translate(.001 8.578)">
          <circle
            id="prefix__Ellipse_342"
            cx={0.897}
            cy={0.897}
            r={0.897}
            transform="translate(.625 .625)"
          />
          <path
            id="prefix__Path_1978"
            d="M1820.666 1214.561a1.522 1.522 0 111.522-1.521 1.524 1.524 0 01-1.522 1.521zm0-1.793a.271.271 0 10.271.271.271.271 0 00-.271-.27z"
            transform="translate(-1819.144 -1211.518)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Rss;
