import React, { SVGProps } from "react";

const CompanyAvatar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="prefix__Placeholder_klant"
    data-name="Placeholder klant"
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <defs>
      <style>{".prefix__cls-2{fill:#1e324e}"}</style>
    </defs>
    <g id="prefix__Group_1643" data-name="Group 1643">
      <g id="prefix__Group_1642" data-name="Group 1642">
        <circle
          id="prefix__Ellipse_343"
          data-name="Ellipse 343"
          cx={15}
          cy={15}
          fill="#fff"
          r={15}
        />
      </g>
    </g>
    <g
      id="prefix__Group_1644"
      data-name="Group 1644"
      transform="translate(6.045 5.078)"
    >
      <path
        id="prefix__Path_2010"
        data-name="Path 2010"
        className="prefix__cls-2"
        d="M1776.849 459.265h5.515a.728.728 0 100-1.457h-5.515a.728.728 0 100 1.457z"
        transform="translate(-1773.046 -454.053)"
      />
      <path
        id="prefix__Path_2011"
        data-name="Path 2011"
        className="prefix__cls-2"
        d="M1795.53 472.347h-4.064a.728.728 0 100 1.457h4.064a.728.728 0 100-1.457z"
        transform="translate(-1779.667 -460.638)"
      />
      <path
        id="prefix__Path_2012"
        data-name="Path 2012"
        className="prefix__cls-2"
        d="M1795.53 478.007h-4.064a.728.728 0 100 1.457h4.064a.728.728 0 100-1.457z"
        transform="translate(-1779.667 -463.202)"
      />
      <path
        id="prefix__Path_2013"
        data-name="Path 2013"
        className="prefix__cls-2"
        d="M1795.53 466.087h-4.064a.728.728 0 100 1.457h4.064a.728.728 0 100-1.457z"
        transform="translate(-1779.667 -457.803)"
      />
      <path
        id="prefix__Path_2014"
        data-name="Path 2014"
        className="prefix__cls-2"
        d="M1786.368 456.346h-2.747v-1.757a3.651 3.651 0 00-3.647-3.646h-5.828a3.651 3.651 0 00-3.646 3.646v15.036a.729.729 0 00.729.728h17.179a.728.728 0 00.729-.728v-10.511a2.771 2.771 0 00-2.769-2.768zm-14.411-1.757a2.192 2.192 0 012.189-2.19h5.828a2.192 2.192 0 012.19 2.19v1.77c.044 0 .085-.013.13-.013a2.768 2.768 0 00-2.523 1.642h-5.471a.728.728 0 100 1.457h5.222v2.026h-5.222a.729.729 0 100 1.457h5.222v5.972h-1.084v-3.1h-2.761v3.1h-3.722zm15.722 14.308h-6.7v-9.783a1.313 1.313 0 011.311-1.311h4.074a1.313 1.313 0 011.311 1.311z"
        transform="translate(-1770.5 -450.943)"
      />
    </g>
  </svg>
);

export default CompanyAvatar;
