import React, { SVGProps } from "react";

const Label = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title, ...svgProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.225}
      height={13.133}
      viewBox="0 0 13.225 13.133"
      fill="currentColor"
      {...svgProps}
    >
      {title ? <title>{title}</title> : null}
      <g id="prefix__Group_1199" transform="translate(1.113 1.066)">
        <g id="prefix__Group_313" transform="translate(-1.113 -1.066)">
          <path
            id="prefix__Path_565"
            d="M1584.52 352.65a1.118 1.118 0 01-.793-.326l-4.868-4.831a1.108 1.108 0 010-1.574l4.888-4.85a1.863 1.863 0 011.36-.551l4.5.042a1.123 1.123 0 011.111 1.1l.043 4.462a1.886 1.886 0 01-.556 1.35l-4.888 4.85a1.121 1.121 0 01-.797.328zm-.068-1.046a.084.084 0 00.136 0l4.888-4.85a.869.869 0 00.255-.621l-.043-4.462a.1.1 0 00-.1-.095l-4.5-.043a.924.924 0 00-.626.254l-4.887 4.849a.1.1 0 000 .136l4.868 4.83z"
            transform="translate(-1578.531 -340.517)"
          />
          <path
            id="prefix__Path_566"
            d="M1600.362 348.276a1.42 1.42 0 01-1-.411 1.4 1.4 0 010-1.99 1.428 1.428 0 012.006 0 1.4 1.4 0 010 1.99 1.419 1.419 0 01-1.006.411zm.64-.771zm-.64-1.025a.394.394 0 00-.278.114.388.388 0 000 .552.4.4 0 00.555 0 .387.387 0 000-.552.394.394 0 00-.277-.115z"
            transform="translate(-1591.385 -343.646)"
          />
        </g>
      </g>
    </svg>
  );
};
export default Label;
