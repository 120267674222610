import React from "react";
import { Button, Loader, Tooltip } from "rsuite";
import AlertModal, { TAlertType } from "./AlertModal";
import SchemaTable from "../../components/SchemaTable";
import { DATE_FORMAT, localeFormat } from "../../inc/date";
import ActionButton from "../../components/ActionButton";
import { AuthContext } from "../../provider/AuthProvider";
import { I18nContext } from "../../provider/I18nProvider";
import useNewContentNotifications from "../../hooks/useNewContentNotifications";
import useNewsAlerts from "../../hooks/useNewsAlerts";
import MwWhisper from "../../components/MwWhisper";
import { LayoutContext } from "../../provider/LayoutProvider";
import useMes from "../../hooks/useMes";
import { ExpressionDescriptor } from "cronstrue/dist/expressionDescriptor";

interface IRowData {
  id: string;
  cron?: string;
}

const AlertsView = () => {
  const { activeLanguage, t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { windowInnerHeight } = React.useContext(LayoutContext);
  const [cronstrue, setCronstrue] =
    React.useState<typeof ExpressionDescriptor>();

  const newContentNotifications = useNewContentNotifications();
  const newsAlerts = useNewsAlerts();
  const users = useMes();

  React.useEffect(() => {
    import("cronstrue/i18n").then((res: unknown) => {
      setCronstrue(res as typeof ExpressionDescriptor);
    });
  }, []);

  const [selectedId, setSelectedId] = React.useState<string>();
  const [selectedAlertType, setSelectedAlertType] =
    React.useState<TAlertType>();

  const tableData = React.useMemo(() => {
    if (!newContentNotifications) {
      return null;
    }
    return [
      ...Object.values(newContentNotifications).map(
        (newContentNotification) => ({
          id: newContentNotification.newContentNotificationId as string,
          cron: newContentNotification.cron,
        }),
      ),
      ...Object.values(newsAlerts || {}).map((newsAlert) => ({
        id: newsAlert.newsAlertId as string,
      })),
    ];
  }, [newContentNotifications, newsAlerts]);

  const currentUser = React.useMemo(
    () =>
      users && auth
        ? users.find(
            (user) =>
              user.userId === auth.jwt.userId &&
              user.groupId === auth.jwt.currentGroupId,
          )
        : null,
    [auth, users],
  );

  if (!tableData || !currentUser) {
    return <Loader />;
  }

  const topBar = (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <h4 className="m-0 flex-grow-1 text-left">
        {tableData.length
          ? `${t("notificationsFor")} ${currentUser.userName}`
          : null}
      </h4>
      <ActionButton
        onClick={() => {
          setSelectedId("NEW");
          setSelectedAlertType("periodic");
        }}
        title={t("newAlert")}
      />
    </div>
  );

  return (
    <>
      {selectedId ? (
        <AlertModal
          alertId={selectedId}
          alertType={selectedAlertType}
          show={true}
          onClose={() => {
            setSelectedId(undefined);
            setSelectedAlertType(undefined);
          }}
        />
      ) : null}
      {tableData.length ? (
        <SchemaTable<IRowData>
          height={windowInnerHeight - 200}
          schema={{
            properties: {
              id: { type: "string" },
              type: { type: "string" },
              cron: { type: "string" },
            },
          }}
          sortable
          globalSearch={false}
          data={tableData}
          columnsConfigs={[
            {
              name: "id",
              hidden: true,
            },
            {
              name: "type" as any,
              renderCell: (rowData) => {
                if (rowData.cron || !newsAlerts) {
                  return t("periodic");
                }
                const newsAlert = newsAlerts[rowData.id as string];
                return (
                  <MwWhisper
                    trigger="hover"
                    placement="bottom"
                    speaker={
                      <Tooltip>
                        {t(
                          "newsAlertDescriptionWithDates",
                          localeFormat(
                            new Date(newsAlert.startDate),
                            DATE_FORMAT,
                          ),
                          localeFormat(
                            new Date(newsAlert.endDate),
                            DATE_FORMAT,
                          ),
                        )}
                      </Tooltip>
                    }
                  >
                    <span>{t("instant")}</span>
                  </MwWhisper>
                );
              },
            },
            {
              name: "cron",
              renderCell: (rowData) => {
                if (rowData.cron && cronstrue) {
                  const cronDescription = cronstrue.toString(rowData.cron, {
                    use24HourTimeFormat: true,
                    locale: activeLanguage.toLowerCase(),
                  });
                  return <span title={cronDescription}>{cronDescription}</span>;
                }

                const newsAlert = (newsAlerts || {})[rowData.id as string];
                if (!newsAlert) {
                  return "";
                }
                const cronDescription = t(
                  "newsAlertWithDates",
                  localeFormat(new Date(newsAlert.startDate), DATE_FORMAT),
                  localeFormat(new Date(newsAlert.endDate), DATE_FORMAT),
                );
                return <span title={cronDescription}>{cronDescription}</span>;
              },
            },
          ]}
          onRowClick={(rowData) => {
            setSelectedId(rowData.id);
            setSelectedAlertType(rowData.cron ? "periodic" : "instant");
          }}
        >
          {topBar}
        </SchemaTable>
      ) : (
        <>
          {topBar}
          <div className="flex-grow-1 d-flex justify-content-center align-items-center pt-5 flex-column">
            <h2>{t("alertsView_startTitle")}</h2>
            <p className="mb-5">{t("alertsView_startDescription")}</p>
            <img
              className="alerts-view__start-image"
              alt="media·web notificaties"
              src={"/img/art/hond/hond@3x.png"}
              srcSet={`/img/art/hond/hond@2x.png 2x, /img/art/hond/hond@3x.png 3x`}
              style={{ width: 388, height: 260 }}
            />
            <Button
              appearance={"primary"}
              className="mt-5"
              onClick={() => {
                setSelectedId("NEW");
                setSelectedAlertType("periodic");
              }}
            >
              {t("newAlert")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};
export default AlertsView;
