import React from "react";
import { Button, Icon, IconButton, Loader } from "rsuite";
import EditorialItem from "./EditorialItem";
import Plus from "../../icons/Plus";
import "./index.scss";
import { useHistory } from "react-router-dom";
import { APP_PATH } from "../../inc/constants";
import { LayoutContext } from "../../provider/LayoutProvider";
import { I18nContext } from "../../provider/I18nProvider";
import useEditorials from "../../hooks/useEditorials";

const EditorialsView = () => {
  const history = useHistory();
  const { t } = React.useContext(I18nContext);
  const { isSidebarOpen, setOpenTourId } = React.useContext(LayoutContext);
  const editorials = useEditorials();

  const openEditorial = (editorialId: string) =>
    history.push(`${APP_PATH}/editorials/overview/${editorialId}`);
  if (!editorials) {
    return <Loader />;
  }
  const hasEditorials = !!Object.values(editorials).length;

  return (
    <div
      className={`editorials-view${
        !hasEditorials ? " editorials-view--empty" : ""
      }`}
    >
      {hasEditorials ? (
        <div className="row editorials-view__list">
          <div
            className={`editorials-view__item ${
              isSidebarOpen ? "col-xl-6 col-xxl-4" : "col-lg-6 col-xl-4"
            }`}
          >
            <div className="d-flex h-100 flex-column justify-content-center align-items-center">
              <IconButton
                className="bg-transparent mb-4"
                icon={
                  <Icon icon="plus" componentClass={Plus} className="p-0" />
                }
                circle
                size="lg"
                onClick={() => openEditorial("new")}
              />
              <h4 className="d-block font-weight-normal">
                {t("newEditorial")}
              </h4>
            </div>
          </div>
          {Object.values(editorials).map((editorial) => (
            <div
              className={`editorials-view__item ${
                isSidebarOpen ? "col-xl-6 col-xxl-4" : "col-lg-6 col-xl-4"
              }`}
              key={editorial.editorialId}
            >
              <EditorialItem editorial={editorial} />
            </div>
          ))}
        </div>
      ) : (
        <>
          <h3 className="editorials-view__title">{t("noEditorialsFound")}</h3>
          <h5 className="editorials-view__subtitle">
            {t("noEditorialsFound_description")}
          </h5>
          <img
            className="img-fluid editorials-view__image"
            alt="No mailing templates found"
            src="/img/art/blank/blank.png"
            srcSet="/img/art/blank/blank@2x.png 2x, /img/art/blank/blank@3x.png 3x"
          />
          <div>
            <Button
              appearance="primary"
              size="sm"
              onClick={() => {
                openEditorial("new");
                setOpenTourId("editEditorial");
              }}
            >
              {t("createFirstEditorial")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default EditorialsView;
