import React, { SVGProps } from "react";

const MediaType = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title, ...svgProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Mediatype_icon"
      width={15.244}
      height={15.682}
      viewBox="0 0 15.244 15.682"
      fill="currentColor"
      {...svgProps}
    >
      {title ? <title>{title}</title> : null}
      <g id="prefix__Mediatype_icon-2" transform="translate(-1626.99 -193.81)">
        <path
          id="prefix__Path_1074"
          d="M1647.445 209.493h-10.4a.546.546 0 01-.546-.546v-1.341a.546.546 0 011.092 0v.8h9.311v-12.121l-1.514-1.382h-7.8v3.09a.546.546 0 01-1.092 0v-3.636a.546.546 0 01.546-.546h8.555a.547.547 0 01.368.142l1.848 1.687a.544.544 0 01.178.4v12.9a.546.546 0 01-.546.553z"
          transform="translate(-5.756)"
        />
        <g id="prefix__Group_284" transform="translate(1626.991 199.241)">
          <path
            id="prefix__Path_1075"
            d="M1637.38 214.547h-8.174a2.218 2.218 0 01-2.215-2.215V209.8a2.218 2.218 0 012.215-2.216h8.174a2.218 2.218 0 012.215 2.216v2.535a2.217 2.217 0 01-2.215 2.212zm-8.174-5.874a1.125 1.125 0 00-1.124 1.124v2.535a1.125 1.125 0 001.124 1.124h8.174a1.125 1.125 0 001.124-1.124V209.8a1.125 1.125 0 00-1.124-1.124z"
            transform="translate(-1626.991 -207.581)"
          />
        </g>
        <path
          id="prefix__Path_1076"
          d="M1660.581 196.749h-1.847a.546.546 0 01-.545-.546v-1.847a.546.546 0 01.932-.386l1.847 1.847a.546.546 0 01-.386.932z"
          transform="translate(-18.893)"
        />
        <circle
          id="prefix__Ellipse_163"
          cx={0.572}
          cy={0.572}
          r={0.572}
          transform="translate(1628.568 202.152)"
        />
      </g>
    </svg>
  );
};
export default MediaType;
