import { components } from "../types/openapi";
import _ from "lodash";
import { Layout } from "react-grid-layout";
import { TDashboard } from "../types";
import { IDashboardWidget } from "../components/dashboardWidgets";

export interface IHashMap<T> {
  [id: string]: T;
}

export const cleanFilter = (
  filter: components["schemas"]["Filter"] | undefined
): components["schemas"]["Filter"] | undefined => {
  if (!filter) {
    return undefined;
  }
  // All filter properties that should be removed from the filter object
  // if the filter property value equals an empty array
  const removeWhenEmptyFilterProperties: (keyof components["schemas"]["Filter"])[] =
    ["mediaTypes", "sentiments", "includeLabelIds", "excludeLabelIds"];
  (Object.keys(filter) as (keyof components["schemas"]["Filter"])[]).forEach(
    (filterProperty) => {
      // If filter property is defined as undefined, delete the filter property
      if (filter[filterProperty] === undefined) {
        delete filter[filterProperty];
      }

      // If filter property is defined as an empty array, delete the filter property
      if (
        removeWhenEmptyFilterProperties.includes(filterProperty) &&
        _.isArray(filter[filterProperty]) &&
        !(filter[filterProperty] as any[]).length
      ) {
        delete filter[filterProperty];
      }
    }
  );
  if (!Object.keys(filter).length) {
    return undefined;
  }
  return filter;
};

const cleanLayout = (layout: Omit<Layout, "i">) => {
  return Object.entries(layout).reduce((carry: any, [key, value]) => {
    if (["x", "y", "w", "h"].includes(key)) {
      carry[key] = value;
    }
    return carry;
  }, {});
};

export const cleanDashboard = (
  dashboard: TDashboard | undefined
): TDashboard | undefined => {
  if (!dashboard) {
    return undefined;
  }
  return {
    ...dashboard,
    config: {
      ...dashboard.config,
      widgets: dashboard.config?.widgets?.map((widget: IDashboardWidget) => ({
        ...widget,
        layouts: {
          lg: cleanLayout(widget.layouts.lg),
          xs: cleanLayout(widget.layouts.xs),
        },
      })),
    },
    filter: cleanFilter(dashboard.filter),
  };
};

export const cleanQuery = (query: components["schemas"]["Query"]) =>
  Object.keys(query).reduce((prev, key) => {
    const val = query[key as keyof components["schemas"]["Query"]];
    if (!Array.isArray(val) || val.length) {
      // @ts-ignore
      prev[key] = val;
    }
    return prev;
  }, {}) as components["schemas"]["Query"];

export const parseLocationSearch = <T extends {}>(search: string): T => {
  if (!search.length) {
    return {} as T;
  }
  const result = {} as T;
  search
    .substring(1)
    .split("&")
    .forEach((pair) => {
      if (!pair) {
        return;
      }
      const splitPair = pair.split("=");
      if (splitPair.length !== 2) {
        return;
      }

      // @ts-ignore
      result[decodeURIComponent(splitPair[0])] = decodeURIComponent(
        splitPair[1]
      );
    });
  return result;
};

export function emptyFn() {}
