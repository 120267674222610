import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useSubscribers = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { subscribers, setSubscribers } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      subscribers !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    setSubscribers(null);

    axios
      .get<components["schemas"]["Subscriber"][]>("/subscriber/crud")
      .then((res) => {
        setSubscribers(
          res.data.reduce<IHashMap<components["schemas"]["Subscriber"]>>(
            (prev, subscriber) => {
              prev[subscriber.subscriberId as string] = subscriber;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_subscriber"));
      });
  }, [auth?.bearer, auth?.jwt.userRoles, setSubscribers, subscribers, t]);

  return subscribers;
});

export default useSubscribers;
