import React from "react";
import { sum } from "lodash";
import { EBenchmarkSplitType, EValueType } from "../index";
import { components } from "../../../types/openapi";
import { EChartType } from "../../../inc/enums";
import { I18nContext } from "../../../provider/I18nProvider";
import { REMAINING_AUTHOR_NAME } from "../../../inc/constants";

export function getSortedBenchmarkAnalysisItemKeys(
  analysis: components["schemas"]["BenchmarkAnalysis"],
) {
  const valueGroupKeys = [...analysis.valueGroup.keys()];
  return valueGroupKeys.sort((keyA, keyB) =>
    analysis.items.reduce((prev, item) => prev + item.values[keyA], 0) >
    analysis.items.reduce((prev, item) => prev + item.values[keyB], 0)
      ? -1
      : 1,
  );
}

export function getSortedAnalysisSplitItemKeys(
  analysisSplit: components["schemas"]["AnalysisSplit"],
  filter?: string,
) {
  const result = [...analysisSplit.items.keys()].sort((keyA, keyB) => {
    if (analysisSplit.perGroup[keyA] === REMAINING_AUTHOR_NAME) {
      return 1;
    }
    if (analysisSplit.perGroup[keyB] === REMAINING_AUTHOR_NAME) {
      return -1;
    }
    return sum(analysisSplit?.items[keyA]) > sum(analysisSplit?.items[keyB])
      ? -1
      : 1;
  });
  if (!filter) {
    return result;
  }
  const valueGroupIndex = analysisSplit.valueGroup.indexOf(filter);
  if (valueGroupIndex < 0) {
    return result;
  }
  return result.filter(
    (itemKey) => !!analysisSplit.items[itemKey][valueGroupIndex],
  );
}

export function getVisibleValueGroupKeys(
  analysisSplit: components["schemas"]["AnalysisSplit"],
) {
  return analysisSplit
    ? [...analysisSplit.valueGroup.keys()].filter((valueGroupKey) =>
        analysisSplit.items.find((item) => item[valueGroupKey] > 0),
      )
    : [];
}

export function getSeriesMaxValue(
  series: ApexAxisChartSeries | ApexNonAxisChartSeries,
) {
  if (!series.length) {
    return 0;
  }
  if (typeof series[0] === "number") {
    return Math.max(...(series as ApexNonAxisChartSeries));
  }
  return Math.max(
    ...(series[0].data as number[]).map((_number, dataKey) =>
      (series as ApexAxisChartSeries).reduce(
        (prev, serie) => prev + (serie.data[dataKey] as number),
        0,
      ),
    ),
  );
}

export function useSeries(
  analysisSplit: components["schemas"]["AnalysisSplit"] | null | undefined,
  chartType: EChartType,
  splitType: EBenchmarkSplitType,
  valueType: EValueType,
  filter: string,
  limit: number,
  addRemaining: boolean,
  customValueGroups?: string[],
) {
  const { t } = React.useContext(I18nContext);
  const sortedAnalysisSplitItemKeys = React.useMemo(
    () =>
      analysisSplit
        ? getSortedAnalysisSplitItemKeys(analysisSplit, filter)
        : [],
    [analysisSplit, filter],
  );

  if (!analysisSplit || !analysisSplit.valueGroup.length) {
    return [];
  }

  const visibleItems = (
    customValueGroups
      ? customValueGroups.map((selectedValueGroup) =>
          analysisSplit.perGroup.indexOf(selectedValueGroup),
        )
      : sortedAnalysisSplitItemKeys.slice(0, limit)
  )
    .map((itemKey) => analysisSplit.items[itemKey])
    .filter((result) => !!result);
  if (!customValueGroups && addRemaining) {
    visibleItems.push(
      sortedAnalysisSplitItemKeys.slice(limit).reduce(
        (prev, remainingItemKey) => {
          analysisSplit.items[remainingItemKey].forEach((value, key) => {
            prev[key] += value;
          });
          return prev;
        },
        analysisSplit.valueGroup.map(() => 0),
      ),
    );
  }
  if (chartType === EChartType.donut) {
    return visibleItems.map((item) => sum(item)) as ApexNonAxisChartSeries;
  }

  return [
    {
      name: t(`valueType_${valueType}`),
      data: visibleItems.map((item) => sum(item)),
    },
  ] as ApexAxisChartSeries;
}
