import React from "react";
import { components } from "../../types/openapi";
import { emptyFn, parseLocationSearch } from "../../inc/data";

export interface IAuth {
  bearer: string;
  issuedAt: Date;
  jwt: components["schemas"]["Jwt"];
}

export interface IAuthContext {
  auth?: IAuth | null;
  setBearer: (bearer?: string | null) => void;
}

export const AUTH_LOCAL_STORAGE_KEY = "auth";
let localStorageAuth: string | null = null;

if (/[?&]bearer=/.exec(window.location.search)) {
  try {
    const { bearer } = parseLocationSearch<{ bearer: string }>(
      window.location.search,
    );
    localStorageAuth = JSON.stringify({
      issuedAt: new Date(),
      bearer,
      jwt: parseJwt(bearer),
    });
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, localStorageAuth);
  } catch (e) {
    console.log(e);
  }
} else {
  localStorageAuth = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
}

export const AuthContext = React.createContext<IAuthContext>({
  setBearer: emptyFn,
});

export function parseJwt(token: string) {
  return JSON.parse(
    decodeURIComponent(
      atob(token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    ),
  ) as components["schemas"]["Jwt"];
}

let initialAuth: IAuth | undefined = undefined;
if (localStorageAuth) {
  const parsedLocalStorageAuth = JSON.parse(localStorageAuth) as IAuth;
  // Is the stored auth still valid?
  if (
    new Date(parsedLocalStorageAuth.jwt.exp * 1000) > new Date() &&
    parsedLocalStorageAuth?.jwt?.userRoles
  ) {
    // Reconstruct it!
    initialAuth = {
      ...parsedLocalStorageAuth,
      issuedAt: new Date(parsedLocalStorageAuth.issuedAt),
    };
  }
}

const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = React.useState<IAuth | null | undefined>(initialAuth);

  const setBearer = React.useCallback((newToken?: string | null) => {
    const newAuth = newToken
      ? {
          issuedAt: new Date(),
          bearer: newToken,
          jwt: parseJwt(newToken),
        }
      : (newToken as null | undefined);
    if (newAuth) {
      localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(newAuth));
    } else {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    }
    setAuth(newAuth);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setBearer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
