import React, { SVGProps } from "react";

const Expand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.02}
    height={11.021}
    viewBox="0 0 11.02 11.021"
    {...props}
  >
    <path
      fill="#1e324e"
      stroke="rgba(0,0,0,0)"
      strokeMiterlimit={10}
      d="M1.061 10.506a.55.55 0 01-.547-.543L.5 4.621a.539.539 0 01.924-.379.524.524 0 01.16.382l.012 4.061 6.23-6.232a.564.564 0 01.113-.087l.771-.772-4.084-.008a.551.551 0 01-.547-.546A.54.54 0 014.62.5l5.338.014a.541.541 0 01.543.542l.018 5.342a.543.543 0 01-1.086 0l-.012-4.061-6.232 6.232a.536.536 0 01-.105.083l-.773.775 4.082.009a.55.55 0 01.547.546.54.54 0 01-.541.54z"
    />
  </svg>
);
export default Expand;
