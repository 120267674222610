import React from "react";
import { Button, Message } from "rsuite";
import axios from "axios";
import { I18nContext } from "../../../provider/I18nProvider";

interface IUnsubscribeViewProps {
  editorialUnsubscribeToken: string;
}

const UnsubscribeView = ({
  editorialUnsubscribeToken,
}: IUnsubscribeViewProps) => {
  const { t } = React.useContext(I18nContext);
  const [status, setStatus] = React.useState<
    "idle" | "inProgress" | "done" | "error"
  >("idle");
  const [error, setError] = React.useState<Error>();

  switch (status) {
    case "error":
      return (
        <Message
          type="error"
          title={t("unsubscribe_error")}
          description={error?.message || t("genericErrorMessage_short")}
        />
      );

    case "done":
      return (
        <Message
          type="success"
          title={t("unsubscribe_success")}
          description={<p>{t("youHaveBeenUnsubscribed")}</p>}
        />
      );

    default:
      return (
        <div>
          <h1>{t("unsubscribe")}</h1>
          <p className="mb-5">{t("unsubscribe_description")}</p>
          <Button
            appearance={"primary"}
            disabled={status === "inProgress"}
            onClick={() => {
              setStatus("inProgress");
              axios
                .post(`/subscription/end/${editorialUnsubscribeToken}`)
                .then((res) => {
                  console.log(res.data);
                  setStatus("done");
                })
                .catch((err) => {
                  setStatus("error");
                  setError(err);
                });
            }}
          >
            {t(
              status === "inProgress" ? "unsubscribe_inProgress" : "unsubscribe"
            )}
          </Button>
        </div>
      );
  }
};

export default UnsubscribeView;
