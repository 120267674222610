import React from "react";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { TBenchmark } from "../../../types";
import { stringifyApiCacheBenchmark } from "../../../inc/benchmark";
import { LRUCache } from "lru-cache";
import { AxiosPromise } from "axios";

const cache = new LRUCache<
  string,
  | components["schemas"]["BenchmarkAnalysis"]
  | AxiosPromise<components["schemas"]["BenchmarkAnalysis"]>
>({
  max: 500,
});

export function useBenchmarkAnalysisCache(
  url: string,
  benchmark: TBenchmark,
): components["schemas"]["BenchmarkAnalysis"] | null {
  const cacheKey = `${url}_${stringifyApiCacheBenchmark(benchmark)}`;
  const cacheValue = cache.get(cacheKey);
  const cacheIsPending = cacheValue instanceof Promise;
  const [value, setValue] =
    React.useState<components["schemas"]["BenchmarkAnalysis"]>();

  React.useEffect(() => {
    if (cacheValue && !cacheIsPending && value !== cacheValue) {
      setValue(cacheValue);
    }
  }, [cacheIsPending, cacheValue, value]);

  if (!cacheValue) {
    const promise = axios.request<components["schemas"]["BenchmarkAnalysis"]>({
      method: "post",
      url,
      data: benchmark,
    });
    cache.set(cacheKey, promise);
    promise.then((res) => {
      cache.set(cacheKey, res.data);
      setValue(res.data);
    });
    return null;
  }

  if (cacheIsPending) {
    cacheValue.then((res) => {
      setValue(res.data);
    });
    return null;
  }
  return value || null;
}
