import React, { SVGProps } from "react";

const Plus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <g fill="none" stroke="currentColor" strokeWidth={1.5}>
      <circle cx={11} cy={11} r={11} stroke="none" />
      <circle cx={11} cy={11} r={10.25} />
    </g>
    <path
      fill="currentColor"
      d="M11.452 10.408V6.991h-1.061v3.417H6.989v1.061h3.4v3.418h1.063v-3.418h3.4v-1.061z"
    />
  </svg>
);

export default Plus;
