import openapi from "../../../openapi.json";
import { Dropdown, Icon, IconButton } from "rsuite";
import MediaType from "../../../icons/MediaType";
import CheckboxPopover from "../../CheckboxPopover";
import React from "react";
import { components } from "../../../types/openapi";
import { I18nContext } from "../../../provider/I18nProvider";

interface IMediaTypeControlProps {
  controlType: "menuItem" | "button";
  disabled: boolean;
  filter: components["schemas"]["Filter"];
  expandAndChange: (filter: components["schemas"]["Filter"]) => void;
}

const MediaTypeControl = ({
  controlType,
  disabled,
  filter,
  expandAndChange,
}: IMediaTypeControlProps) => {
  const { t } = React.useContext(I18nContext);

  return (
    <CheckboxPopover
      values={filter.mediaTypes || []}
      onChange={(mediaTypes) =>
        expandAndChange({
          ...filter,
          mediaTypes: (mediaTypes || []) as any,
        })
      }
      data={openapi["components"]["schemas"]["MediaType"]["enum"].map(
        (item) => ({
          value: item,
          label: t(`mediaType_${item}`),
        })
      )}
    >
      {controlType === "button" ? (
        <IconButton
          ripple={false}
          className={`filter-bar__filters-col__button`}
          size="xs"
          disabled={disabled}
          appearance="subtle"
          icon={<Icon icon="question" componentClass={MediaType} />}
        >
          {t("mediaType")}
        </IconButton>
      ) : (
        <Dropdown.Item>{t("mediaType")}</Dropdown.Item>
      )}
    </CheckboxPopover>
  );
};

export default MediaTypeControl;
