import React from "react";
import { Checkbox, Table } from "rsuite";
import { TableCellProps } from "rsuite/lib/Table/TableCell";
import "./index.scss";

const CheckCell = ({
  disabled,
  onChange,
  rowData,
  dataKey,
  ...props
}: TableCellProps) => {
  return (
    <Table.Cell
      {...props}
      className="check-cell"
      style={{ lineHeight: `${props.height - 8}px` }}
    >
      <div>
        <Checkbox
          disabled={disabled}
          value={rowData}
          inline
          onChange={onChange}
          checked={(rowData as any)[dataKey as string]}
        />
      </div>
    </Table.Cell>
  );
};

export default CheckCell;
