import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useInvoices = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { invoices, setInvoices } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (invoices !== undefined || !auth || !auth?.bearer) {
      return;
    }
    const { jwt } = auth;
    const { userRoles } = jwt;
    if (userRoles.indexOf("invoice") >= 0) {
      setInvoices(null);
      axios
        .get<components["schemas"]["Invoice"][]>("/invoice/byCustomerLinkId")
        .then((res) => {
          setInvoices(
            res.data.reduce<IHashMap<components["schemas"]["Invoice"]>>(
              (prev, invoice) => {
                prev[`${invoice.invoiceNo}`] = invoice;
                return prev;
              },
              {}
            )
          );
        })
        .catch((err) => {
          console.log(err);
          Alert.error(t("apiDataProvider_fetchingFailed_invoice"));
        });
    }
  }, [auth?.bearer, auth?.jwt.userRoles, setInvoices, invoices, t, auth]);

  return invoices;
});

export default useInvoices;
