import React, { SVGProps } from "react";

const Benchmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24.211}
    height={19.984}
    fill="currentColor"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 1865"
          style={{
            fill: "#fff",
          }}
          d="M0 0h24.211v19.984H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Group 1870"
      style={{
        clipPath: "url(#a)",
      }}
    >
      <path
        data-name="Path 2500"
        d="M5.642 11.284a5.642 5.642 0 1 1 5.642-5.642 5.648 5.648 0 0 1-5.642 5.642m0-10.064a4.422 4.422 0 1 0 4.422 4.422A4.427 4.427 0 0 0 5.642 1.22"
        style={{
          fill: "#fff",
        }}
      />
      <path
        data-name="Path 2501"
        d="M38.3 9.436a4.7 4.7 0 1 1 4.7-4.7 4.706 4.706 0 0 1-4.7 4.7m0-8.18a3.48 3.48 0 1 0 3.48 3.48 3.484 3.484 0 0 0-3.48-3.48"
        transform="translate(-18.785 -.02)"
        style={{
          fill: "#fff",
        }}
      />
      <path
        data-name="Path 2502"
        d="M26.57 34.092h-8.823a.61.61 0 0 1-.529-.916l4.412-7.641a.61.61 0 0 1 1.057 0l4.413 7.641a.61.61 0 0 1-.528.916m-7.772-1.22h6.71l-3.352-5.812z"
        transform="translate(-9.582 -14.107)"
        style={{
          fill: "#fff",
        }}
      />
      <path
        data-name="Path 2503"
        d="M7.852 26.014a.61.61 0 0 1-.083-1.214l17.546-2.442a.61.61 0 1 1 .168 1.209L7.937 26.008a.611.611 0 0 1-.085.006"
        transform="translate(-4.05 -12.498)"
        style={{
          fill: "#fff",
        }}
      />
    </g>
  </svg>
);
export default Benchmark;
