import React, { RefObject } from "react";
import _ from "lodash";
import {
  Button,
  Divider,
  Icon,
  Input,
  InputGroup,
  Nav,
  Popover,
  TagGroup,
} from "rsuite";
import MagnifyingGlass from "../../../../../icons/MagnifyingGlass";
import LabelIcon from "../../../../../icons/Label";
import { containsWordThatStartWith } from "../../../../../inc/query";
import { I18nContext } from "../../../../../provider/I18nProvider";
import useLabels from "../../../../../hooks/useLabels";
import MwTag from "../../../../../components/MwTag";
import LabelTag from "../../../../../components/LabelTag";
import { ApiDataContext } from "../../../../../provider/ApiDataProvider";
import IconNavItem from "../../../../../components/IconNavItem";
import { startJob } from "../../../../../inc/job";
import { components } from "../../../../../types/openapi";
import { TypeAttributes } from "rsuite/lib/@types/common";
import MwWhisper from "../../../../../components/MwWhisper";
import { LayoutContext } from "../../../../../provider/LayoutProvider";
import { BootstrapSize } from "../../../../../inc/constants";

interface IBulkLabelButtonProps {
  getSelectionCacheToken: () => Promise<string>;
  hasChevron: boolean;
  setFilterResults: (
    searchResults: components["schemas"]["MediaItem"][]
  ) => void;
  patchMediaItem: (
    mediaItemId: string,
    data: components["schemas"]["MediaItemPartial"]
  ) => void;
  placement?: TypeAttributes.Placement;
  popoverClasses?: string;
  selectedHydratedMediaItems: components["schemas"]["MediaItem"][];
}

const BulkLabelButton = ({
  getSelectionCacheToken,
  hasChevron,
  setFilterResults,
  patchMediaItem,
  placement,
  popoverClasses,
  selectedHydratedMediaItems,
}: IBulkLabelButtonProps) => {
  const { t } = React.useContext(I18nContext);
  const { updateLabel } = React.useContext(ApiDataContext);
  const { windowOuterWidth } = React.useContext(LayoutContext);

  const labelsMap = useLabels();

  const [activeKey, setActiveKey] = React.useState<"add" | "remove">("add");
  const [selectedToBeAddedLabelIds, setSelectedToBeAddedLabelIds] =
    React.useState<string[]>([]);
  const [selectedToBeRemovedLabelIds, setSelectedToBeRemovedLabelIds] =
    React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const results = React.useMemo(
    () =>
      containsWordThatStartWith(
        Object.values(labelsMap || {}),
        "name",
        searchQuery
      ).sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)),
    [labelsMap, searchQuery]
  );
  const existingLabels = React.useMemo(
    () =>
      labelsMap
        ? Object.keys(
            selectedHydratedMediaItems.reduce<{ [labelId: string]: true }>(
              (prev, { labelIds }) => {
                if (labelIds) {
                  labelIds.forEach((labelId) => {
                    prev[labelId] = true;
                  });
                }
                return prev;
              },
              {}
            )
          )
            .map((labelId) => labelsMap[labelId])
            .sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            )
        : [],
    [labelsMap, selectedHydratedMediaItems]
  );

  const triggerRef: RefObject<any> = React.useRef();
  const onAdd = React.useCallback(async () => {
    if (!selectedToBeAddedLabelIds || !selectedToBeAddedLabelIds.length) {
      return;
    }
    if (selectedHydratedMediaItems.length > 10) {
      await startJob(
        {
          filterCacheToken: await getSelectionCacheToken(),
          jobType: "mediaItemAddLabelIds",
          labelIds: selectedToBeAddedLabelIds,
        },
        () => {
          setFilterResults(
            selectedHydratedMediaItems.map((result:any) => {
              if (
                selectedHydratedMediaItems.find(
                  (item) => item.mediaItemId === result.mediaItemId
                )
              ) {
                result.labelIds = _.uniq([
                  ...(result.labelIds || []),
                  ...selectedToBeAddedLabelIds,
                ]);
              }
              return result;
            })
          );
          setSelectedToBeAddedLabelIds([]);
        }
      );
      if (triggerRef.current) {
        triggerRef.current.hide();
      }
      return;
    }

    selectedHydratedMediaItems.forEach(({ mediaItemId, labelIds = [] }) => {
      const newLabelIds = _.uniq([...labelIds, ...selectedToBeAddedLabelIds]);
      if (mediaItemId && labelIds.length !== newLabelIds.length) {
        patchMediaItem(mediaItemId, { labelIds: newLabelIds });
      }
    });
    if (triggerRef.current) {
      triggerRef.current.hide();
    }
  }, [
    getSelectionCacheToken,
    patchMediaItem,
    selectedHydratedMediaItems,
    selectedToBeAddedLabelIds,
    setFilterResults,
    triggerRef,
  ]);

  const onRemove = React.useCallback(async () => {
    if (!selectedToBeRemovedLabelIds || !selectedToBeRemovedLabelIds.length) {
      return;
    }
    if (selectedHydratedMediaItems.length > 10) {
      await startJob(
        {
          filterCacheToken: await getSelectionCacheToken(),
          jobType: "mediaItemRemoveLabelIds",
          labelIds: selectedToBeRemovedLabelIds,
        },
        () => {
          setFilterResults(
            selectedHydratedMediaItems.map((result:any) => {
              if (
                selectedHydratedMediaItems.find(
                  (item) => item.mediaItemId === result.mediaItemId
                )
              ) {
                result.labelIds = _.difference(
                  result.labelIds || [],
                  selectedToBeRemovedLabelIds
                );
              }
              return result;
            })
          );
        }
      );
    } else {
      selectedHydratedMediaItems.forEach(({ mediaItemId, labelIds = [] }) => {
        const newLabelIds = _.difference(labelIds, selectedToBeRemovedLabelIds);
        if (mediaItemId && labelIds.length !== newLabelIds.length) {
          patchMediaItem(mediaItemId, { labelIds: newLabelIds });
        }
      });
    }
    setSelectedToBeRemovedLabelIds([]);
    setActiveKey("add");
    if (triggerRef.current) {
      triggerRef.current.hide();
    }
  }, [
    getSelectionCacheToken,
    patchMediaItem,
    selectedHydratedMediaItems,
    selectedToBeRemovedLabelIds,
    setFilterResults,
    triggerRef,
  ]);

  const renderedToBeAddedLables = React.useMemo(() => {
    if (!results.length) {
      return searchQuery.trim().length ? (
        <MwTag
          size="lg"
          iconComponent={LabelIcon}
          color="cyan"
          rounded
          onClick={() => {
            updateLabel({
              name: searchQuery.trim(),
            }).then((label) => {
              if (label.labelId) {
                setSelectedToBeAddedLabelIds([
                  ...selectedToBeAddedLabelIds,
                  label.labelId,
                ]);
              }
            });
          }}
        >
          {t("createOption", searchQuery)}
        </MwTag>
      ) : null;
    }
    return (
      <>
        {results.map((result) => (
          <LabelTag
            key={result.labelId}
            size="lg"
            color={
              selectedToBeAddedLabelIds.includes(result.labelId as string)
                ? "green"
                : "cyan"
            }
            rounded
            onClick={() => {
              const id = result.labelId as string;
              setSelectedToBeAddedLabelIds(
                selectedToBeAddedLabelIds.includes(id)
                  ? selectedToBeAddedLabelIds.filter((x) => x !== id)
                  : [...selectedToBeAddedLabelIds, id]
              );
            }}
            label={result}
          />
        ))}
      </>
    );
  }, [results, searchQuery, selectedToBeAddedLabelIds, t, updateLabel]);

  return (
    <MwWhisper
      triggerRef={triggerRef}
      trigger="click"
      speaker={
        <Popover
          className={`popover-without-arrow label-popover ${popoverClasses}`}
        >
          {existingLabels.length ? (
            <Nav activeKey={activeKey} onSelect={setActiveKey} className="mb-3">
              {["add", "remove"].map((eventKey) => (
                <Nav.Item eventKey={eventKey} key={eventKey}>
                  {t(
                    windowOuterWidth < BootstrapSize.MD
                      ? eventKey
                      : `${eventKey}Labels`
                  )}
                </Nav.Item>
              ))}
            </Nav>
          ) : null}
          {activeKey === "add" ? (
            <>
              <InputGroup className="label-popover__search-input-group" inside>
                <InputGroup.Addon>
                  <Icon icon="star" componentClass={MagnifyingGlass} />
                </InputGroup.Addon>
                <Input
                  className="label-popover__search-input"
                  size="sm"
                  value={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
              </InputGroup>
              <Divider />
              <TagGroup className="label-popover__results">
                {renderedToBeAddedLables}
              </TagGroup>
              <div className="label-popover__apply">
                <Button
                  disabled={selectedToBeAddedLabelIds.length < 0}
                  className="label-popover__apply__button"
                  appearance="ghost"
                  color="blue"
                  onClick={onAdd}
                >
                  {t("addLabels")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <Divider />
              <TagGroup className="label-popover__results">
                {existingLabels.map((result) => (
                  <LabelTag
                    key={result.labelId}
                    size="lg"
                    color={
                      selectedToBeRemovedLabelIds.includes(
                        result.labelId as string
                      )
                        ? "green"
                        : "cyan"
                    }
                    rounded
                    onClick={() => {
                      const id = result.labelId as string;
                      setSelectedToBeRemovedLabelIds(
                        selectedToBeRemovedLabelIds.includes(id)
                          ? selectedToBeRemovedLabelIds.filter((x) => x !== id)
                          : [...selectedToBeRemovedLabelIds, id]
                      );
                    }}
                    label={result}
                  />
                ))}
              </TagGroup>
              <div className="label-popover__apply">
                <Button
                  disabled={selectedToBeRemovedLabelIds.length < 0}
                  className="label-popover__apply__button"
                  appearance="ghost"
                  color="blue"
                  onClick={onRemove}
                >
                  {t("removeLabels")}
                </Button>
              </div>
            </>
          )}
        </Popover>
      }
    >
      <IconNavItem
        hasChevron={hasChevron}
        chevronPlacement={placement}
        title={t("plusLabel")}
        iconComponent={LabelIcon}
      />
    </MwWhisper>
  );
};
export default BulkLabelButton;
