import React, { SVGProps } from "react";

const CartCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Aangekocht_icon"
    width={13.496}
    height={12.928}
    viewBox="0 0 13.496 12.928"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Group_673">
      <path
        id="prefix__Path_1378"
        d="M12.55 9.121l.936-4.668a.5.5 0 10-.988-.2l-.854 4.264h-7.6L3.022 2.554a.5.5 0 00-.5-.419H.5a.5.5 0 100 1.007h1.6l1.387 8.13a.5.5 0 00.5.419h8.072a.5.5 0 000-1.007h-7.65l-.2-1.159h7.845a.5.5 0 00.494-.4"
        transform="translate(0 -1.358)"
      />
      <path
        id="prefix__Path_1379"
        d="M12.859 30.157a.979.979 0 10.979.979.979.979 0 00-.979-.979"
        transform="translate(-7.559 -19.187)"
      />
      <path
        id="prefix__Path_1380"
        d="M26.3 30.157a.979.979 0 10.979.979.979.979 0 00-.979-.979"
        transform="translate(-16.112 -19.187)"
      />
      <path
        id="prefix__Path_1381"
        d="M16.863 5.408a.5.5 0 00.41.211h.027a.5.5 0 00.412-.251L20.367.755a.5.5 0 10-.873-.5l-2.267 3.931-1.654-2.316a.5.5 0 00-.819.586z"
        transform="translate(-9.327)"
      />
    </g>
  </svg>
);
export default CartCheck;
