import { Drawer, Icon } from "rsuite";
import {
  EBenchmarkWidgetType,
  benchmarkWidgetDefinitions,
  newBenchmarkWidget,
} from "../../../components/benchmarkWidgets";
import NumberBadge from "../../../components/NumberBadge";
import ActionButton from "../../../components/ActionButton";
import React, { Dispatch, SetStateAction } from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import { TBenchmark } from "../../../types";

import "./index.scss";

interface IBenchmarkWidgetDrawerProps {
  benchmark: TBenchmark;
  isDrawerOpen: boolean;
  setBenchmark: Dispatch<SetStateAction<TBenchmark>>;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const BenchmarkWidgetDrawer = ({
  benchmark,
  isDrawerOpen,
  setBenchmark,
  setIsDrawerOpen,
}: IBenchmarkWidgetDrawerProps) => {
  const { t } = React.useContext(I18nContext);

  const closeDrawer = React.useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const addWidget = React.useCallback(
    (widgetType: EBenchmarkWidgetType) => {
      if (!benchmark) {
        return;
      }
      const widgets = [
        EBenchmarkWidgetType.WIDGET_TYPE_AUTHOR,
        EBenchmarkWidgetType.WIDGET_TYPE_CATEGORY,
        EBenchmarkWidgetType.WIDGET_TYPE_SOURCE,
      ].includes(widgetType)
        ? benchmark.matchTexts.map((_matchText, matchTextIndex) =>
            newBenchmarkWidget(widgetType, { matchTextIndex }),
          )
        : [newBenchmarkWidget(widgetType)];
      const dLgY = widgets.reduce(
        (prev, widget) => prev + widget.layouts.lg.h,
        0,
      );
      const dXsY = widgets.reduce(
        (prev, widget) => prev + widget.layouts.xs.h,
        0,
      );
      setBenchmark({
        ...benchmark,
        config: {
          ...benchmark.config,
          widgets: [
            ...(benchmark.config?.widgets || []).map((currentWidget) => {
              // Move the existing widgets down
              currentWidget.layouts.lg.y += dLgY;
              currentWidget.layouts.xs.y += dXsY;
              return currentWidget;
            }),
            ...widgets,
          ],
        },
      });
      closeDrawer();
    },
    [benchmark, closeDrawer, setBenchmark],
  );

  return (
    <Drawer
      className="widget-menu-drawer"
      show={isDrawerOpen}
      onHide={closeDrawer}
      size="sm"
    >
      <Drawer.Header>
        <Drawer.Title className="widget-menu-drawer__title">
          {t("widgets")}
        </Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className="widget-menu">
        {Object.values(EBenchmarkWidgetType).map((widgetType) => {
          const classNames = ["widget-menu__item"];

          if (
            widgetType === EBenchmarkWidgetType.WIDGET_TYPE_HIGHLIGHT &&
            benchmark.matchTexts.length !== 1
          ) {
            classNames.push("widget-menu__item__disabled");
          }

          return (
            <div
              key={widgetType}
              className={classNames.join(" ")}
              onClick={() => addWidget(widgetType as EBenchmarkWidgetType)}
            >
              <NumberBadge
                title={t("widgetDrawer_amountOfActiveWidgetsDescription")}
                className="widget-menu__item__badge"
                number={
                  benchmark?.config?.widgets?.filter(
                    (widget) => widget.type === widgetType,
                  ).length
                }
              >
                <ActionButton />
              </NumberBadge>
              <div className="widget-menu__item__content">
                <div className="row h-100 align-items-center">
                  <div className="col-3 text-center">
                    <Icon
                      icon="star"
                      componentClass={
                        benchmarkWidgetDefinitions[widgetType].menuIcon
                      }
                      width={50}
                      height={50}
                      style={{ color: "#96afaf" }}
                    />
                  </div>
                  <div className="col">
                    <h4 className="widget-menu__item__content__title">
                      {t(widgetType)}
                    </h4>
                    <p className="widget-menu__item__content__description">
                      {t(`${widgetType}_DESCRIPTION`)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Drawer.Body>
    </Drawer>
  );
};

export default BenchmarkWidgetDrawer;
