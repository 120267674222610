import React, { SVGProps } from "react";

const SentimentNeutral = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="prefix__Sentiment_neutraal_nw"
    data-name="Sentiment neutraal nw"
    xmlns="http://www.w3.org/2000/svg"
    width={15.881}
    height={15.88}
    viewBox="0 0 15.881 15.88"
    fill="currentColor"
    {...props}
  >
    <g
      id="prefix__Sentiment_neutraal_groot"
      data-name="Sentiment neutraal groot"
    >
      <g id="prefix__Group_1386" data-name="Group 1386">
        <path
          id="prefix__Path_1649"
          data-name="Path 1649"
          d="M1693.43 422.45a7.94 7.94 0 117.94-7.94 7.949 7.949 0 01-7.94 7.94zm0-14.717a6.777 6.777 0 106.776 6.776 6.784 6.784 0 00-6.776-6.776z"
          transform="translate(-1685.49 -406.57)"
        />
        <path
          id="prefix__Path_1650"
          data-name="Path 1650"
          d="M1702.614 432.553h-5.6a.643.643 0 110-1.287h5.6a.643.643 0 010 1.287z"
          transform="translate(-1691.864 -420.9)"
        />
      </g>
    </g>
    <g
      id="prefix__Group_1729"
      data-name="Group 1729"
      transform="translate(3.859 5.368)"
    >
      <circle
        id="prefix__Ellipse_370"
        data-name="Ellipse 370"
        cx={1.141}
        cy={1.141}
        r={1.141}
      />
      <circle
        id="prefix__Ellipse_371"
        data-name="Ellipse 371"
        cx={1.141}
        cy={1.141}
        transform="translate(5.934)"
        r={1.141}
      />
    </g>
  </svg>
);
export default SentimentNeutral;
