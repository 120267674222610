import React from "react";
import { I18nContext } from "../../provider/I18nProvider";

interface ITranslatedTextProps {
  text: string;
}

const TranslatedText = (props: ITranslatedTextProps) => {
  const { t } = React.useContext(I18nContext);
  return <>{t(props.text)}</>;
};
export default TranslatedText;
