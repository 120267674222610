import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";

const useDeleteRequests = () => {
  const { deleteRequests, setDeleteRequests } =
    React.useContext(ApiDataContext);

  const refresh = React.useCallback(() => {
    setDeleteRequests(null);
    axios
      .get<components["schemas"]["DeleteRequest"][]>("/deleteRequest/crud")
      .then((res) => {
        setDeleteRequests(
          res.data.reduce<IHashMap<components["schemas"]["DeleteRequest"]>>(
            (prev, deleteRequest) => {
              prev[deleteRequest.customerMediaItemId as string] = deleteRequest;
              return prev;
            },
            {}
          )
        );
      });
  }, [setDeleteRequests]);

  return { deleteRequests, refresh };
};

export default useDeleteRequests;
