import React, { SVGProps } from "react";

const Datepicker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.229}
    height={18}
    viewBox="0 0 16.229 18"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Datepicker_icon">
      <path
        id="prefix__Path_558"
        d="M1588.9 274.676a.709.709 0 01-.709-.71V270.1a.709.709 0 011.419 0v3.869a.709.709 0 01-.71.707z"
        transform="translate(-1584.047 -269.388)"
      />
      <path
        id="prefix__Path_559"
        d="M1601.648 274.676a.71.71 0 01-.71-.71V270.1a.71.71 0 011.42 0v3.869a.709.709 0 01-.71.707z"
        transform="translate(-1590.268 -269.388)"
      />
      <rect
        id="prefix__Rectangle_846"
        width={2.064}
        height={2.064}
        rx={0.7}
        transform="translate(3.738 7.812)"
      />
      <rect
        id="prefix__Rectangle_847"
        width={2.064}
        height={2.064}
        rx={0.7}
        transform="translate(6.976 7.812)"
      />
      <rect
        id="prefix__Rectangle_848"
        width={2.064}
        height={2.064}
        rx={0.7}
        transform="translate(10.216 7.812)"
      />
      <rect
        id="prefix__Rectangle_849"
        width={2.064}
        height={2.064}
        rx={0.7}
        transform="translate(3.738 11.05)"
      />
      <rect
        id="prefix__Rectangle_850"
        width={2.064}
        height={2.064}
        rx={0.7}
        transform="translate(6.976 11.05)"
      />
      <path
        id="prefix__Path_563"
        d="M1593.314 289.074h-10.194a3.02 3.02 0 01-3.017-3.016v-10.193a3.02 3.02 0 013.017-3.016.709.709 0 110 1.417 1.6 1.6 0 00-1.6 1.6v10.192a1.6 1.6 0 001.6 1.6h10.194a1.6 1.6 0 001.6-1.6v-10.193a1.6 1.6 0 00-1.6-1.6.709.709 0 010-1.417 3.019 3.019 0 013.017 3.016v10.192a3.019 3.019 0 01-3.017 3.018z"
        transform="translate(-1580.104 -271.074)"
      />
      <path
        id="prefix__Path_564"
        d="M1595.333 274.268h-2.47a.709.709 0 110-1.419h2.47a.709.709 0 010 1.419z"
        transform="translate(-1585.985 -271.074)"
      />
    </g>
  </svg>
);
export default Datepicker;
