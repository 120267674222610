import { components } from "../../../../types/openapi";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";

import { compact, currency, formatInt } from "../../../../inc/numbers";
import {
  DATE_COMPACT_FORMAT,
  DATE_TIME_FORMAT,
  localeFormat,
  parseDateWithOptionalTime,
} from "../../../../inc/date";
import SchemaTable from "../../../../components/SchemaTable";
import React from "react";
import MediaType from "../../../../icons/MediaType";
import { elementOrParentHasClassName } from "../../../../inc/dom";
import { I18nContext } from "../../../../provider/I18nProvider";
import { InfiniteLoader } from "react-virtualized";
import {
  IBenchmarkResultBenchmarkWidgetSettings,
  TBenchmarkResultBenchmarkWidgetcolumnLayoutSettings,
} from "../index";
import { getBenchmarkResultIcon } from "../../../../icons";

interface IBenchmarkResultTableProps {
  height: number;
  isBenchmarkResultLoaded: (obj: { index: number }) => boolean;
  loadMoreBenchmarkResults: (obj: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<void>;
  benchmarkResults: Array<
    components["schemas"]["BenchmarkResult"] | null | undefined
  >;
  onItemClick?: (mediaItem: components["schemas"]["BenchmarkResult"]) => void;
  onSettingsChange?: (
    widgetUid: string,
    newSettings: IBenchmarkResultBenchmarkWidgetSettings,
  ) => void;
  settings: IBenchmarkResultBenchmarkWidgetSettings;
  widgetUid: string;
  columnLayouts: TBenchmarkResultBenchmarkWidgetcolumnLayoutSettings[];
}

const BenchmarkResultTable = ({
  height,
  isBenchmarkResultLoaded,
  loadMoreBenchmarkResults,
  benchmarkResults,
  onItemClick,
  onSettingsChange,
  settings,
  widgetUid,
  columnLayouts,
}: IBenchmarkResultTableProps) => {
  // useWhyDidYouUpdate(props);
  const { t } = React.useContext(I18nContext);
  const [tableHeight, setTableHeight] = React.useState<number>(height - 120);

  const columnConfigMap = React.useMemo(
    () => ({
      title: {
        width: 250,
        flexGrow: 1,
      },
      mediaType: {
        flexGrow: 0,
        align: "center",
        sortByValue: true,
        title: (
          <MediaType
            title={t("mediaType")}
            className="benchmark-result-grid-item__header--icon"
          />
        ),
        renderCell: (rowData: components["schemas"]["BenchmarkResult"]) => {
          return (
            <span
              title={t(`mediaType_${rowData.mediaType}`)}
              className="benchmark-result-grid-item__cell--media-type"
            >
              {getBenchmarkResultIcon(rowData)}
            </span>
          );
        },
      },
      sourceName: {
        width: 150,
      },
      ave: {
        flexGrow: 0,
        sortByValue: true,
        title: "€",
        hoverTitle: t("ave"),
        renderCell: (rowData: components["schemas"]["BenchmarkResult"]) => {
          return !rowData.ave ? (
            ""
          ) : (
            <span title={currency(rowData.ave)}>{compact(rowData.ave)}</span>
          );
        },
      },
      reach: {
        width: 100,
        sortByValue: true,
        renderCell: (rowData: components["schemas"]["BenchmarkResult"]) => {
          return !rowData.reach ? (
            ""
          ) : (
            <span title={formatInt(rowData.reach)}>
              {compact(rowData.reach)}
            </span>
          );
        },
      },
      publicationDate: {
        title: "date",
        width: 125,
        hoverTitle: "publicationDateExplanation",
        renderCell: (rowData: components["schemas"]["BenchmarkResult"]) => {
          return !rowData.publicationDate ? (
            ""
          ) : (
            <span
              title={localeFormat(
                parseDateWithOptionalTime(rowData.publicationDate),
                DATE_TIME_FORMAT,
              )}
            >
              {localeFormat(
                parseDateWithOptionalTime(rowData.publicationDate),
                DATE_COMPACT_FORMAT,
              )}
            </span>
          );
        },
      },
      benchmarkNames: {
        renderData: (rowData: components["schemas"]["BenchmarkResult"]) => {
          return rowData ? rowData['benchmarkNames'].join(', ') : ''
        },
      },
    }),
    [t],
  );

  React.useEffect(() => {
    const panelBody = document.querySelector(
      `.benchmark-result-grid-item--${widgetUid} .rs-panel-body`,
    );

    // @ts-ignore
    const observer = new ResizeObserver((entries) => {
      setTableHeight(entries[0].contentRect.height);
    });

    if (panelBody) {
      observer.observe(panelBody);
      setTableHeight(panelBody.clientHeight);

      return () => {
        observer.disconnect();
      };
    }
  }, [widgetUid, settings.display]);

  const columnConfigs = React.useMemo(
    () =>
      columnLayouts
        .filter((columnLayout) => columnLayout.show)
        .map((columnLayout) => {
          const result = {
            ...columnConfigMap[columnLayout.propName as "title"],
            name: columnLayout.propName as string,
          };
          if (columnLayout.width) {
            result.width = columnLayout.width;
          }
          return result;
        }),
    [columnLayouts, columnConfigMap],
  );

  const columnResize = React.useCallback(
    (width: number, propName: string) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(widgetUid, {
        ...settings,
        columnLayouts: columnLayouts.map((column) =>
          column.propName === propName ? { ...column, width } : column,
        ),
      });
    },
    [columnLayouts, onSettingsChange, settings, widgetUid],
  );

  const onRowClick = React.useCallback(
    (mediaItem: any, e: any) => {
      if (
        e.target &&
        onItemClick &&
        !elementOrParentHasClassName(e.target as HTMLElement, "rs-popover")
      ) {
        onItemClick(mediaItem);
      }
    },
    [onItemClick],
  );

  const rowClassName = React.useCallback(
    (rowData: any) => `${rowData.isRead ? "" : "font-weight-bold"}`,
    [],
  );

  const tableRenderer = React.useCallback(
    ({ onRowsRendered }: any) => {
      return (
        <SchemaTable<components["schemas"]["BenchmarkResult"]>
          schema={
            openapi.components.schemas.BenchmarkResult as oas30.SchemaObject
          }
          data={benchmarkResults as any}
          height={tableHeight}
          rowHeight={34}
          sortable={false}
          onResize={columnResize}
          onRowClick={onItemClick ? onRowClick : undefined}
          onRowsRendered={onRowsRendered}
          rowClassName={rowClassName}
          resizable
          columnsConfigs={columnConfigs}
          isUnconfiguredHidden
        />
      );
    },
    [
      benchmarkResults,
      columnConfigs,
      columnResize,
      onItemClick,
      onRowClick,
      rowClassName,
      tableHeight,
    ],
  );

  return (
    <InfiniteLoader
      isRowLoaded={isBenchmarkResultLoaded}
      loadMoreRows={loadMoreBenchmarkResults}
      rowCount={benchmarkResults.length}
    >
      {tableRenderer}
    </InfiniteLoader>
  );
};

export default BenchmarkResultTable;
