import React from "react";
import { Alert, Button, Form } from "rsuite";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../components/SchemaFormBody";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../types/openapi";
import {
  focusError,
  validationErrorsToSchemaFormErrors,
} from "../../inc/schema";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import axios from "../../inc/axios";
import ajv from "../../inc/ajv";
import { I18nContext } from "../../provider/I18nProvider";
import useCustomers from "../../hooks/useCustomers";
import { AuthContext } from "../../provider/AuthProvider";

type Customer = components["schemas"]["Customer"];

const CustomerSchema = openapi.components.schemas.Customer as oas30.SchemaObject;
const customerValidate = ajv.compile(CustomerSchema);

if (!CustomerSchema.properties) {
  throw new Error("Incomplete customer schema");
}

const CustomerView = () => {
  const { t } = React.useContext(I18nContext);
  const { setCustomers } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const [customer, setCustomer] = React.useState<Customer>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const customers = useCustomers();

  const customerId = auth?.jwt.currentCustomerLinkId;

  React.useEffect(() => {
    if (!customers || !auth || !customerId) {
      return;
    }
    let customer: Customer | undefined = customers[customerId];
    if (!customer) {
      throw new Error("Could not construct customer for form");
    }
    const newCustomer = JSON.parse(JSON.stringify(customer));
    setCustomer(newCustomer);
  }, [auth, customerId, customers]);

  const validateCustomer = React.useCallback(() => {
    const isValid = customerValidate(customer);
    if (!isValid && customerValidate.errors && customerValidate.errors.length) {
      console.log(customerValidate.errors);
      setErrors(validationErrorsToSchemaFormErrors(customerValidate.errors));
    }
    return isValid;
  }, [customer]);

  const onSubmit = React.useCallback(() => {
    if (!validateCustomer()) {
      setTimeout(focusError, 200);
      return;
    }
    setErrors({});
    axios
      .request<Customer>({
        url: `/customer/crud${
          customer && customer.customerLinkId
            ? `/${customer.customerLinkId}`
            : ""
        }`,
        method: "put",
        data: customer,
      })
      .then((res) => {
        const updatedCustomer = res.data;
        if (customers) {
          setCustomers({
            ...customers,
            [updatedCustomer.customerLinkId as string]: updatedCustomer,
          });
        }
        Alert.success(t("formSaved"));
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [validateCustomer, customer, customers, setCustomers, t]);

  const formIsDirty = React.useMemo(() => {
    const originalCustomer =
      customers && customerId ? customers[customerId] : null;
    return JSON.stringify(originalCustomer) !== JSON.stringify(customer);
  }, [customers, customerId, customer]);

  return (
    <div>
      <Form className="modal__content__form" onSubmit={onSubmit}>
        <div className="modal__content__form__profile">
          <h4 className="modal__content__form__profile__title">
            Weergave-instellingen
          </h4>
          <SchemaFormBody<Customer>
            schema={CustomerSchema}
            onChange={setCustomer}
            value={customer}
            errors={errors}
            inputClassName="input-gray"
            config={{
              active: {
                hidden: true,
              },
              customerName: {
                hidden: true,
              },
              customerNo: {
                hidden: true,
              },
              groupId: {
                hidden: true,
              },
              logoFileId: {
                hidden: true,
              },
              editorialConfig: {
                hidden: true,
              },
              role: {
                hidden: true,
              },
            }}
          />
        </div>
        <Button onClick={onSubmit} appearance="primary" disabled={!formIsDirty}>
          {t("save")}
        </Button>
      </Form>
    </div>
  );
};

export default CustomerView;
