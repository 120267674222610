import React, { SVGProps } from "react";

const Support = (props: SVGProps<SVGSVGElement> & { title: string }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.21"
      height="37.537"
      viewBox="0 0 22.21 37.537"
      fill="currentColor"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      {...props}
    >
      <title>{props.title}</title>
      <path
        d="M1621.206,1568.944h-7.33a2.447,2.447,0,0,1-2.445-2.446V1561a2.5,2.5,0,0,0-.905-1.931,11.106,11.106,0,1,1,14.08.086,2.543,2.543,0,0,0-.955,1.974v5.372A2.447,2.447,0,0,1,1621.206,1568.944Zm-3.575-27.89a10.159,10.159,0,0,0-1.1.06,9.472,9.472,0,0,0-4.968,16.7,4.12,4.12,0,0,1,1.491,3.181v5.5a.822.822,0,0,0,.82.821h7.33a.822.822,0,0,0,.82-.821v-5.372a4.157,4.157,0,0,1,1.556-3.236,9.474,9.474,0,0,0-5.951-16.836Z"
        transform="translate(-1606.514 -1531.407)"
      />
      <path
        d="M1622.435,1563.3h-7.687a.813.813,0,0,1,0-1.625h7.687a.813.813,0,0,1,0,1.625Z"
        transform="translate(-1606.514 -1531.407)"
      />
      <path
        d="M1622.435,1566.2h-7.687a.813.813,0,0,1,0-1.625h7.687a.813.813,0,0,1,0,1.625Z"
        transform="translate(-1606.514 -1531.407)"
      />
      {isHovered ? (
        <g transform="translate(-1606.514 -1486.759)">
          <path d="M1622.435,1518.648h-7.687a.813.813,0,0,1,0-1.625h7.687a.813.813,0,0,1,0,1.625Z" />
          <path d="M1622.435,1521.556h-7.687a.813.813,0,0,1,0-1.625h7.687a.813.813,0,0,1,0,1.625Z" />
          <path d="M1617.618,1486.759a.721.721,0,0,0-.721.721v2.885a.721.721,0,1,0,1.442,0v-2.885A.721.721,0,0,0,1617.618,1486.759Zm-7.432,3.3a.721.721,0,0,0-.507,1.228l2.04,2.039a.717.717,0,1,0,1.014-1.014l-2.039-2.039a.716.716,0,0,0-.507-.214Zm14.864,0a.716.716,0,0,0-.507.214l-2.039,2.039a.717.717,0,0,0,1.014,1.014l2.039-2.039a.721.721,0,0,0-.507-1.228Z" />
        </g>
      ) : null}
    </svg>
  );
};

export default Support;
