import {
  Alert,
  Button,
  Form,
  FormControl,
  Icon,
  IconButton,
  Loader,
  Popover,
  Whisper,
} from "rsuite";
import Plus from "../../../icons/Plus";
import Prullie from "../../../icons/Prullie";
import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import axios from "../../../inc/axios";
import { AxiosError } from "axios";
import useDashboardAccess from "../../../hooks/useDashboardAccess";
import { components } from "../../../types/openapi";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import {
  ENarrowCastingMode,
  LayoutContext,
} from "../../../provider/LayoutProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import NumberBadge from "../../../components/NumberBadge";
import Export from "../../../icons/Export";

import "./index.scss";
import { BootstrapSize } from "../../../inc/constants";
import { IMaartenError } from "../../../types";

interface IShareButtonProps {
  dashboardId: string;
}

const ShareButton = ({ dashboardId }: IShareButtonProps) => {
  const { auth } = React.useContext(AuthContext);
  const { setDashboardAccesses } = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const { narrowCastingMode, startNarrowCasting, windowOuterWidth } =
    React.useContext(LayoutContext);

  const dashboardAccesses = useDashboardAccess();
  const [shareEl, setShareEl] = React.useState<HTMLDivElement>();
  const [emailAddress, setEmailAddress] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [isInProgress, setIsInProgress] = React.useState(false);

  const whisperRef = React.useRef();

  const isNarrowCasting =
    narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF;

  const currentDashboardAccesses = React.useMemo<
    components["schemas"]["DashboardAccess"][]
  >(
    () =>
      dashboardAccesses
        ? Object.values(dashboardAccesses).filter(
            (dashboardAccess) => dashboardAccess.dashboardId === dashboardId,
          )
        : [],
    [dashboardAccesses, dashboardId],
  );

  const shareRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        setShareEl(node);
      }
    },
    // eslint-disable-next-line
    [],
  );
  const onSubmit = React.useCallback(
    (valid: boolean, e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!valid || !emailAddress) {
        setErrorMessage("must NOT be shorter than 1 characters");
        return;
      }
      if (emailAddress.indexOf("@") === -1) {
        setErrorMessage("invalid email format");
        return;
      }
      setIsInProgress(true);
      axios
        .request<components["schemas"]["DashboardAccess"]>({
          method: "post",
          url: "/dashboardAccess/crud",
          data: {
            dashboardId,
            emailAddress,
          },
        })
        .then((res) => {
          setIsInProgress(false);
          setEmailAddress("");
          Alert.info(t("dashboardAccessLinkSent"));
          setDashboardAccesses((dashboardAccesses) =>
            res.data.dashboardAccessId
              ? {
                  ...dashboardAccesses,
                  [res.data.dashboardAccessId]: res.data,
                }
              : dashboardAccesses,
          );
        })
        .catch((err: AxiosError<IMaartenError>) => {
          setIsInProgress(false);
          Alert.error(t(err.response?.data.error || "genericErrorMessage"));
        });
    },
    [dashboardId, emailAddress, setDashboardAccesses, t],
  );

  const isDashboardOnly = auth
    ? auth.jwt.userRoles.indexOf("dashboardOnly") >= 0
    : false;
  const canShare = dashboardId !== "new" && !isDashboardOnly;

  return (
    <div
      className="d-inline-block position-relative share-button"
      ref={shareRef}
    >
      <NumberBadge
        // title={t("widgetDrawer_amountOfActiveWidgetsDescription")}
        // className="widget-menu__item__badge"
        number={currentDashboardAccesses.length || undefined}
      >
        <Whisper
          trigger="click"
          ref={whisperRef}
          container={shareEl}
          placement="bottomEnd"
          speaker={
            <Popover
              className="popover-without-arrow views__dashboard-view__share-popover"
              style={{ width: 360, backgroundColor: "#fdfdfd" }}
            >
              {canShare ? (
                <>
                  <h3>{t("shareDashboardTitle")}</h3>
                  <p>{t("shareDashboardBody")}</p>
                  {isInProgress ? (
                    <div
                      style={{
                        overflow: "hidden",
                        height: 100,
                        paddingTop: 25,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Loader size="lg" />
                    </div>
                  ) : (
                    <Form className="d-flex pt-3" onSubmit={onSubmit}>
                      <FormControl
                        className="input flex-grow-1 mt-2"
                        onChange={setEmailAddress}
                        value={emailAddress}
                        errorMessage={
                          errorMessage ? t(errorMessage) : undefined
                        }
                        placeholder={t("addEmail")}
                        type="email"
                      />
                      <IconButton
                        className="action-button"
                        style={{ margin: "20px 0 20px 20px", paddingRight: 12 }}
                        size="xs"
                        type="submit"
                        icon={<Icon icon="trash" componentClass={Plus} />}
                      />
                    </Form>
                  )}
                  <hr />
                  {currentDashboardAccesses.length ? (
                    <>
                      <h3>{t("dashboardSharedWith")}:</h3>
                      {currentDashboardAccesses.map(
                        (currentDashboardAccess) => (
                          <div
                            className="share-button__dashboard-access-row"
                            key={currentDashboardAccess.dashboardAccessId}
                          >
                            <div className="flex-grow-1">
                              {currentDashboardAccess.emailAddress}
                            </div>
                            <IconButton
                              size="xs"
                              className="action-button"
                              icon={
                                <Icon icon="trash" componentClass={Prullie} />
                              }
                              onClick={() => {
                                const { dashboardAccessId } =
                                  currentDashboardAccess;
                                if (!dashboardAccessId) {
                                  return;
                                }
                                axios
                                  .request({
                                    method: "delete",
                                    url: `/dashboardAccess/crud/${dashboardAccessId}`,
                                  })
                                  .then(() => {
                                    setIsInProgress(false);
                                    setDashboardAccesses(
                                      (dashboardAccesses) => {
                                        const newDashboardAccesses = {
                                          ...dashboardAccesses,
                                        };
                                        // @ts-ignore
                                        delete newDashboardAccesses[
                                          dashboardAccessId
                                        ];
                                        return newDashboardAccesses;
                                      },
                                    );
                                  })
                                  .catch((err: AxiosError<IMaartenError>) => {
                                    setIsInProgress(false);
                                    Alert.error(
                                      t(
                                        err.response?.data.error ||
                                          "genericErrorMessage",
                                      ),
                                    );
                                  });
                              }}
                            />
                          </div>
                        ),
                      )}
                    </>
                  ) : (
                    <div style={{ color: "rgba(0,0,0,0.16)" }}>
                      {t("dashboardNotSharedYet")}
                    </div>
                  )}
                  <hr />
                </>
              ) : null}
              <h3>{t("narrowcastingTitle")}</h3>
              <p>
                {t("narrowcastingBody")}
                <Button
                  className="mt-3"
                  onClick={() => {
                    const { current } = whisperRef;
                    if (current) {
                      // @ts-ignore
                      current.close();
                    }
                    startNarrowCasting();
                  }}
                >
                  {t(isNarrowCasting ? "stop" : "start")}
                </Button>
              </p>
            </Popover>
          }
        >
          <IconButton
            className="border-0 btn--big-icon d-none d-sm-inline-block"
            style={{ zIndex: 3 }}
            ripple={false}
            placement="right"
            size="xs"
            appearance="ghost"
            color="blue"
            icon={<Icon icon="question" componentClass={Export} />}
          >
            {windowOuterWidth >= BootstrapSize.MD
              ? t("share").toLowerCase()
              : undefined}
          </IconButton>
        </Whisper>
      </NumberBadge>
    </div>
  );
};

export default ShareButton;
