import Slider from "../Slider";
import LabelTag from "../LabelTag";
import React from "react";
import { components } from "../../types/openapi";
import useLabels from "../../hooks/useLabels";

interface IMediaItemGridItemLabelsProps {
  mediaItem: components["schemas"]["MediaItem"];
  preserveSpaceForLabelSlider?: boolean;
}

const MediaItemGridItemLabels = ({
  mediaItem,
  preserveSpaceForLabelSlider,
}: IMediaItemGridItemLabelsProps) => {
  const labels = useLabels();
  const { labelIds } = mediaItem;

  if (labelIds && labelIds.length) {
    return (
      <div className="media-item-grid-item-labels mt-3">
        <Slider>
          {
            labelIds.map((labelId) => {
              const label = labels ? labels[labelId] : null;
              return label ? (
                <LabelTag rounded color={"green"} key={labelId} label={label} />
              ) : null;
            }) as any
          }
        </Slider>
      </div>
    );
  }
  return preserveSpaceForLabelSlider ? (
    <div className="mt-3" style={{ height: 22 }} />
  ) : null;
};

export default MediaItemGridItemLabels;
