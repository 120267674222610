import { Button, Form, Modal } from "rsuite";
import React, { Dispatch, SetStateAction } from "react";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import * as _ from "lodash";
import { I18nContext } from "../../../provider/I18nProvider";
import { components } from "../../../types/openapi";
import { ErrorObject, ValidationError } from "ajv";
import { validationErrorsToSchemaFormErrors } from "../../../inc/schema";

const SearchEngineQuerieschema = openapi.components.schemas
  .SearchEngineQuery as oas30.SchemaObject;

interface ISaveAsModalProps {
  close: () => void;
  searchEngineQuery: components["schemas"]["SearchEngineQuery"];
  setSearchEngineQueryName: Dispatch<SetStateAction<string>>;
  saveSearchEngineQuery: (
    searchEngineQuery: components["schemas"]["SearchEngineQuery"],
  ) => Promise<void>;
}

const SaveAsModal = ({
  close,
  saveSearchEngineQuery,
  searchEngineQuery,
  setSearchEngineQueryName,
}: ISaveAsModalProps) => {
  const { t } = React.useContext(I18nContext);
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  React.useEffect(() => {
    setTimeout(() => {
      const formInput = window.document.querySelector<HTMLInputElement>(
        ".views__searchEngineQuery-view__save-as-modal input",
      );
      if (formInput) {
        formInput.focus();
      }
    }, 1000);
  }, []);

  const onSubmit = React.useCallback(() => {
    saveSearchEngineQuery(searchEngineQuery)
      .then(close)
      .catch((validationError: ValidationError) => {
        setErrors(
          validationErrorsToSchemaFormErrors(
            validationError.errors as ErrorObject[],
          ),
        );
      });
  }, [close, saveSearchEngineQuery, searchEngineQuery]);

  return (
    <Modal
      show={!!searchEngineQuery}
      onHide={close}
      size="xs"
      dialogClassName={"views__searchEngineQuery-view__save-as-modal"}
    >
      <Modal.Header>
        <Modal.Title>{t("saveSearchEngineQueryAs")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <Form className="w-100" onSubmit={onSubmit}>
          <div>
            <SchemaFormBody<components["schemas"]["SearchEngineQuery"]>
              schema={{
                ...SearchEngineQuerieschema,
                properties: _.pick(SearchEngineQuerieschema.properties, [
                  "name",
                ]) as any,
              }}
              onChange={({ name }) => setSearchEngineQueryName(name)}
              value={searchEngineQuery}
              errors={errors}
              inputClassName="input-gray"
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit} appearance="primary">
          {t("OK")}
        </Button>
        <Button onClick={close} appearance="subtle">
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveAsModal;
