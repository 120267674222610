import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useNewsAlerts = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { newsAlerts, setNewsAlerts } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (newsAlerts !== undefined || !auth?.bearer) {
      return;
    }
    setNewsAlerts(null);
    axios
      .get<components["schemas"]["NewsAlert"][]>("/newsAlert/crud")
      .then((res) => {
        setNewsAlerts(
          res.data.reduce<IHashMap<components["schemas"]["NewsAlert"]>>(
            (prev, newsAlerts) => {
              prev[newsAlerts.newsAlertId as string] = newsAlerts;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_newsAlert"));
      });
  }, [auth?.bearer, setNewsAlerts, newsAlerts, t]);

  return newsAlerts;
});

export default useNewsAlerts;
