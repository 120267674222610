import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useGroups = singletonHook(undefined, () => {
  const { groups, setGroups } = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (groups !== undefined || !auth?.bearer) {
      return;
    }
    if (auth.jwt.userRoles.indexOf("superuser") >= 0) {
      setGroups(null);
      // eslint-disable-next-line no-debugger
      axios
        .get<components["schemas"]["Group"][]>("/group/crud")
        .then((res) => {
          setGroups(
            res.data.reduce<IHashMap<components["schemas"]["Group"]>>(
              (prev, group) => {
                prev[group.groupId as string] = group;
                return prev;
              },
              {}
            )
          );
        })
        .catch((err) => {
          console.log(err);
          Alert.error(t("apiDataProvider_fetchingFailed_group"));
        });
    }
  }, [auth?.bearer, setGroups, groups, auth?.jwt.userRoles, t]);

  return groups;
});

export default useGroups;
