import React from "react";
import { Divider, Panel } from "rsuite";
import AdvancedMatchConfig from "./AdvancedMatchConfig";
import "./index.scss";
import { I18nContext } from "../../provider/I18nProvider";
import { TAdvancedMatches } from "../../inc/advancedMatch";

interface IAdvancedMatchPanelProps {
  isExpanded: boolean;
  advancedMatch: TAdvancedMatches;
  onChange: (advancedMatch: TAdvancedMatches) => void;
  shaded?: boolean;
}

type TExpandedInputOptions = "all" | "some" | "none";

const AdvancedMatchPanel = ({
  isExpanded,
  advancedMatch,
  onChange,
  shaded,
}: IAdvancedMatchPanelProps) => {
  const { t } = React.useContext(I18nContext);
  const [expandedInput, setExpandedInput] =
    React.useState<TExpandedInputOptions>();

  React.useEffect(() => {
    if (!isExpanded) {
      setExpandedInput(undefined);
    }
  }, [isExpanded]);

  return (
    <Panel
      collapsible
      expanded={isExpanded}
      shaded={shaded}
      className="advanced-match-panel"
    >
      <div className="row flex-grow-1 py-2">
        <div className="col-12 col-md">
          <AdvancedMatchConfig
            title={t("advancedMatchConfig_allOfThese")}
            items={advancedMatch.all}
            expanded={expandedInput === "all"}
            onExpandToggle={(expand) =>
              setExpandedInput(expand ? "all" : undefined)
            }
            onChange={(newItems) => {
              onChange({
                ...advancedMatch,
                all: newItems,
              });
            }}
          />
        </div>
        <div className="d-none d-md-block col-auto">
          <Divider vertical className="h-100" />
        </div>
        <div className="col-12 col-md">
          <AdvancedMatchConfig
            title={t("advancedMatchConfig_oneOfThese")}
            items={advancedMatch.some}
            expanded={expandedInput === "some"}
            onExpandToggle={(expand) =>
              setExpandedInput(expand ? "some" : undefined)
            }
            onChange={(newItems) => {
              onChange({
                ...advancedMatch,
                some: newItems,
              });
            }}
          />
        </div>
        <div className="d-none d-md-block col-auto">
          <Divider vertical className="h-100" />
        </div>
        <div className="col-12 col-md">
          <AdvancedMatchConfig
            title={t("advancedMatchConfig_noneOfThese")}
            items={advancedMatch.none}
            expanded={expandedInput === "none"}
            onExpandToggle={(expand) =>
              setExpandedInput(expand ? "none" : undefined)
            }
            onChange={(newItems) => {
              onChange({
                ...advancedMatch,
                none: newItems,
              });
            }}
          />
        </div>
      </div>
    </Panel>
  );
};
export default AdvancedMatchPanel;
