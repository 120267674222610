import React from "react";
import SchemaTable, {
  IColumnConfig,
  IRenderData,
} from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import UserModal from "./UserModal";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { Icon, IconButton } from "rsuite";
import { TUser } from "../../types";
import ActionButton from "../../components/ActionButton";
import { I18nContext } from "../../provider/I18nProvider";
import { intersection } from "lodash";
import useUsers from "../../hooks/useUsers";
import useCustomers from "../../hooks/useCustomers";
import useGroups from "../../hooks/useGroups";
import { AuthContext } from "../../provider/AuthProvider";
import { generateAndDownload } from "../../inc/excel";
import { getEmptyObject } from "../../inc/schema";
import LogModal from "./LogModal";
import "./index.scss";
import { components } from "../../types/openapi";

const schema = openapi.components.schemas.User as oas30.SchemaObject;

const UsersView = () => {
  const { t } = React.useContext(I18nContext);
  const { impersonate } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);
  const [selectedUser, setSelectedUser] = React.useState<TUser>();
  const [logUser, setLogUser] = React.useState<TUser>();
  const groups = useGroups();
  const users = useUsers();
  const customers = useCustomers();

  const customerNumberCustomerMap = React.useMemo(() => {
    const map = new Map();
    if (customers) {
      Object.values(customers).forEach((customer) => {
        map.set(customer.customerNo, customer);
      });
    }
    return map;
  }, [customers]);

  const isSuperUser = (auth?.jwt.userRoles || []).indexOf("superuser") >= 0;
  const isManager = (auth?.jwt.userRoles || []).indexOf("manager") >= 0;

  const doImpersonate = React.useCallback(
    (rowData: TUser) => {
      const activeCustomerLinkId = customers
        ? rowData.customerLinkIds.find(
            (customerLinkId) => customers[customerLinkId].active,
          )
        : null;
      impersonate(
        rowData.userId as string,
        activeCustomerLinkId || rowData.customerLinkIds[0],
      );
    },
    [customers, impersonate],
  );

  const onRowClick = React.useCallback(
    (user: TUser | null) => {
      if (!user || !user.userId) {
        return;
      }
      if (isSuperUser) {
        if (user.customerLinkIds.length) {
          doImpersonate(user);
        }
        return;
      }
      setSelectedUser(user);
    },
    [doImpersonate, isSuperUser],
  );

  const onClose = React.useCallback(() => {
    setSelectedUser(undefined);
    setLogUser(undefined);
  }, []);
  const columnConfigs = React.useMemo<IColumnConfig<TUser>[]>(
    () => [
      { name: "userId", hidden: true },
      { name: "customerLinkIds", hidden: true },
      {
        name: "roles",
        width: 200,
        renderData: (user) => {
          const { roles = [] } = user;
          if (roles.indexOf("manager") >= 0) {
            return t("managerUser");
          }
          if (roles.indexOf("dashboardOnly") >= 0) {
            return t("dashboardOnlyUser");
          }
          return t("regularUser");
        },
      },
      { name: "settings", hidden: true },
      {
        name: "displayName",
        renderCell: (rowData: TUser) => (
          <>
            {rowData.roles && rowData.roles.indexOf("manager") >= 0 ? (
              <Icon icon="black-tie" style={{ marginRight: 8 }} />
            ) : null}
            {rowData.displayName}
          </>
        ),
      },
      { name: "groupId", hidden: !isSuperUser },
      { name: "userName" },
      {
        name: "backOfficer",
        hidden: !isSuperUser,
        sortByValue: false,
        renderData: (rowData: TUser) => {
          const group = groups ? groups[rowData.groupId] : null;
          return group?.backOfficer || "-";
        },
      },
      {
        name: "accountManager",
        hidden: !isSuperUser,
        sortByValue: false,
        renderData: (rowData: TUser) => {
          const group = groups ? groups[rowData.groupId] : null;
          return group?.accountManager || "-";
        },
      },
      {
        name: "edit",
        hidden: !isSuperUser,
        width: 80,
        sortable: false,
        renderCell: (user: TUser) => (
          <IconButton
            style={{ marginTop: -10 }}
            onClick={(e: any) => {
              e.stopPropagation();
              setSelectedUser(user);
            }}
            icon={<Icon icon="pencil" />}
          />
        ),
      },
      {
        name: "log",
        // do not show log for superuser: we need a group context!
        hidden: !isManager || !auth?.jwt.superUserId,
        width: 80,
        sortable: false,
        renderCell: (rowData: TUser) => (
          <IconButton
            style={{ marginTop: -10 }}
            onClick={(e: any) => {
              e.stopPropagation();
              setLogUser(rowData);
            }}
            icon={<Icon icon="id-mapping" />}
          />
        ),
      },
    ],
    [isSuperUser, isManager, auth?.jwt.superUserId, t, groups],
  );

  const data = React.useMemo(
    () =>
      users
        ? Object.values(users)
            .filter(
              // filter users for the currently active group
              (user) =>
                isSuperUser || user.groupId === auth?.jwt.currentGroupId,
            )
            .sort((a, b) =>
              a.displayName.toLowerCase() < b.displayName.toLowerCase()
                ? -1
                : 1,
            )
        : undefined,
    [isSuperUser, auth?.jwt.currentGroupId, users],
  );
  const dataFilter = React.useCallback(
    (renderData: IRenderData[], lcQuery: string): IRenderData[] => {
      if (!data) {
        return renderData;
      }
      const propNames = Object.keys(schema.properties as any);
      const customerLinkIds = Object.values(customers || {})
        .filter(
          (customer) =>
            `${customer.customerNo} ${customer.customerName}`
              .toLowerCase()
              .indexOf(lcQuery) >= 0,
        )
        .map((customer) => customer.customerLinkId);
      const groupIds = Object.values(groups || {})
        .filter((group) => group.groupName.toLowerCase().indexOf(lcQuery) >= 0)
        .map((group) => group.groupId);

      return renderData.filter((item) => {
        const user: TUser = data[item._index];
        if (!user) {
          return false;
        }

        if (
          intersection(customerLinkIds, user.customerLinkIds).length ||
          groupIds.indexOf(user.groupId) >= 0
        ) {
          return true;
        }
        return !!propNames.find((propName) =>
          `${user[propName as keyof TUser]}`.toLowerCase().includes(lcQuery),
        );
      });
    },
    [customers, data, groups],
  );

  const onExport = React.useCallback(
    (users: TUser[]) => {
      if (!groups) {
        return;
      }
      generateAndDownload(
        "Gebruikers",
        [
          { header: "Naam", key: "displayName", width: 40 },
          { header: "Groep", key: "groupName", width: 40 },
          { header: "E-mailadres", key: "userName", width: 30 },
          { header: "Backofficer", key: "backOfficer", width: 30 },
          {
            header: "Accountmanager",
            key: "accountManager",
            width: 40,
          },
        ],
        users.map((user) => ({
          ...user,
          ...groups[user.groupId],
        })),
      );
    },
    [groups],
  );

  const rowClassName = React.useCallback(
    (t: TUser) => {
      return isSuperUser && !t.customerLinkIds.length
        ? `users-view-row users-view-row--unswitchable`
        : "users-view-row";
    },
    [isSuperUser],
  );

  const dataSorter = React.useCallback(
    (
      a: components["schemas"]["User"],
      b: components["schemas"]["User"],
      query: string,
    ) => {
      const customerNo = parseInt(query, 10);
      if (customerNo && !isNaN(customerNo)) {
        const customer = customerNumberCustomerMap.get(customerNo);
        const customerLinkId = customer?.customerLinkId;
        if (customer && a.customerLinkIds.indexOf(customerLinkId) >= 0) {
          return -1;
        }
        if (customer && b.customerLinkIds.indexOf(customerLinkId) >= 0) {
          return 1;
        }
      }
      return 0;
    },
    [customerNumberCustomerMap],
  );

  const onAddClick = React.useCallback(() => {
    const groupId = auth?.jwt.currentGroupId as string;
    const groupCustomerLinkIds = customers
      ? Object.values(customers)
          .filter((customer) => customer.groupId === groupId)
          .map((customer) => customer.customerLinkId as string)
      : [];
    setSelectedUser({
      ...getEmptyObject(schema),
      groupId,
      customerLinkIds:
        groupCustomerLinkIds.length === 1 ? groupCustomerLinkIds : [],
      displayName: "",
      userName: "",
      roles: [],
    });
  }, [auth?.jwt.currentGroupId, customers]);

  if (!auth) {
    return null;
  }

  return (
    <>
      {selectedUser ? (
        <UserModal onClose={onClose} user={selectedUser} />
      ) : null}
      {logUser ? <LogModal onClose={onClose} user={logUser} /> : null}
      <SchemaTable<TUser>
        data={data}
        dataFilter={dataFilter}
        dataSorter={dataSorter}
        schema={schema}
        onExport={isSuperUser ? onExport : undefined}
        onRowClick={onRowClick}
        columnsConfigs={columnConfigs}
        globalSearch
        rowClassName={rowClassName}
        sortable
      >
        <ActionButton onClick={onAddClick} title={t("addAction")} />
      </SchemaTable>
    </>
  );
};

export default UsersView;
