import React, { SVGProps } from "react";

const SentimentNegative = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.885}
    height={15.884}
    viewBox="0 0 15.885 15.884"
    fill="currentColor"
    {...props}
  >
    <path
      id="prefix__Path_1643"
      d="M1640.518 422.453a7.942 7.942 0 117.942-7.942 7.951 7.951 0 01-7.942 7.942zm0-14.72a6.778 6.778 0 106.778 6.778 6.785 6.785 0 00-6.778-6.777z"
      transform="translate(-1632.576 -406.57)"
    />
    <path
      id="prefix__Path_1644"
      d="M1647.492 432.765a3.188 3.188 0 00-2.751 1.587.582.582 0 101.008.583 2.013 2.013 0 013.412-.118.582.582 0 00.964-.652 3.177 3.177 0 00-2.633-1.4z"
      transform="translate(-1639.58 -421.749)"
    />
    <path
      id="prefix__Path_1645"
      d="M1641.188 416.777a.582.582 0 00-.363.866 2.2 2.2 0 004.064-.774.582.582 0 10-1.148-.19 1.04 1.04 0 01-1.918.365.582.582 0 00-.635-.267z"
      transform="translate(-1637.308 -412.145)"
    />
    <path
      id="prefix__Path_1646"
      d="M1653.608 415.931a.573.573 0 00-.176-.011.582.582 0 00-.529.63 2.2 2.2 0 003.993 1.083.582.582 0 00-.949-.674 1.04 1.04 0 01-1.885-.51.582.582 0 00-.454-.518z"
      transform="translate(-1644.353 -411.986)"
    />
  </svg>
);
export default SentimentNegative;
