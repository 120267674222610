import { Icon, IconButton, Popover, Tooltip, Whisper } from "rsuite";
import Settings from "../../../icons/Settings";
import React from "react";
import "./index.scss";
import { saveAs } from "file-saver";
import Download from "../../../icons/Download";
import { I18nContext } from "../../../provider/I18nProvider";
import html2canvas from "html2canvas";
import svg2canvas from "./svg2canvas";
import Prullie from "../../../icons/Prullie";
import ReactGA from "react-ga";

interface IDashboardWidgetPanelHeaderProps {
  allowImageDownload?: boolean;
  children?: any;
  notes?: string;
  onDelete?: () => void;
  onNotesChange?: (newNotes: string) => void;
  onSettingsToggle?: (isOpen: boolean) => void;
  popoverForm?: React.ReactElement;
  title: string;
  subtitle?: string;
}

const WidgetPanelHeader = ({
  allowImageDownload,
  children,
  notes = "",
  onDelete,
  onNotesChange,
  onSettingsToggle,
  popoverForm,
  subtitle,
  title,
}: IDashboardWidgetPanelHeaderProps) => {
  const { t } = React.useContext(I18nContext);
  const ref = React.useRef<HTMLDivElement>(null);
  // Do not use React.useRef: we need this thing reactive, see
  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const [settingsEl, setSettingsEl] = React.useState<
    HTMLDivElement | null | undefined
  >();
  const settingsRef = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        setSettingsEl(node);
      }
    },
    // eslint-disable-next-line
    [],
  );

  const onImageDownloadClick = React.useCallback(async () => {
    ReactGA.event({
      category: "Widgets",
      action: `.png download`,
    });
    const panelBody = ref.current?.parentElement?.parentElement?.querySelector(
      ".rs-panel-body",
    ) as HTMLElement;
    const svg = panelBody.querySelector(".apexcharts-svg") as SVGElement;
    const isDonut = !!panelBody.querySelector(".apexcharts-pie");
    let legendNode: SVGForeignObjectElement;
    if (isDonut) {
      const legend = panelBody.querySelector(
        ".apexcharts-legend",
      ) as SVGElement;
      legend.style.overflow = "visible";
      // do not mangle chart style, it will break analysis images
      // const chart = panelBody.querySelector(".chart") as HTMLDivElement;
      // chart.style.height = `${chart.offsetHeight + 20}px`;
      // chart.style.width = `${chart.offsetHeight + 20}px`;

      svg
        .querySelectorAll(".apexcharts-pie .apexcharts-datalabels")
        .forEach((beautyness) => {
          (beautyness as SVGElement).style.display = "inline";
        });
    } else {
      const extraStylesheet = document.getElementById("apexChartsStyle");
      if (svg && extraStylesheet) {
        svg.appendChild(extraStylesheet.cloneNode(true));
      }
      const slider = panelBody.querySelector(".slider");
      if (slider) {
        const svgHeight = svg.scrollHeight;
        const svgWidth = svg.scrollWidth;
        const legendHeight = 80;
        legendNode = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "foreignObject",
        );
        legendNode.setAttribute("x", "0");
        legendNode.setAttribute("y", `${svgHeight}`);
        legendNode.setAttribute("width", `${svgWidth}`);
        legendNode.setAttribute("height", `${legendHeight}`);
        svg.setAttribute("height", `${svgHeight + legendHeight}`);
        svg.appendChild(legendNode);
        const newDiv = document.createElement("div");
        newDiv.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
        newDiv.innerHTML = slider.outerHTML;

        // remove minus from buttons
        newDiv
          .querySelectorAll<HTMLSpanElement>(".rs-tag-text path")
          .forEach((path) => {
            path.setAttribute(
              "d",
              "M2.343 13.657A8 8 0 1113.658 2.343 8 8 0 012.343 13.657zM4",
            );
          });
        legendNode.appendChild(newDiv);
      }
    }
    const canvas = await (svg && !isDonut
      ? svg2canvas(svg)
      : html2canvas(panelBody));
    canvas.toBlob((blob) => {
      if (legendNode) {
        legendNode.remove();
      }
      if (!blob) {
        return;
      }
      saveAs(blob, "grafiek.png");
    });
  }, []);

  const onEntering = React.useMemo(
    () => (onSettingsToggle ? () => onSettingsToggle(true) : undefined),
    [onSettingsToggle],
  );
  const onExiting = React.useMemo(
    () => (onSettingsToggle ? () => onSettingsToggle(false) : undefined),
    [onSettingsToggle],
  );

  const speaker = React.useMemo(() => {
    if (!popoverForm && !onDelete && !allowImageDownload) {
      return null;
    }

    return (
      <Popover className="popover-without-arrow views__dashboard-view__widgets__settings__popover">
        {popoverForm}
        {popoverForm ? <hr className="my-3" /> : <div className="my-4" />}
        {onNotesChange ? (
          <>
            <div className="row">
              <label>{t("notes")}:</label>
              <textarea
                name="notes"
                value={notes}
                onChange={(e) => onNotesChange(e.currentTarget.value)}
              />
            </div>
            <hr className="my-3" />
          </>
        ) : null}
        <div className="row widget-panel-header__footer">
          <div className="col-auto">
            {onDelete ? (
              <IconButton
                onClick={onDelete}
                icon={<Icon icon="trash" componentClass={Prullie} />}
                size="sm"
                appearance="link"
              >
                {t("delete")}
              </IconButton>
            ) : null}
          </div>
          {allowImageDownload ? (
            <div className="col-auto ms-auto">
              <IconButton
                size="sm"
                appearance="link"
                onClick={onImageDownloadClick}
                icon={<Icon icon="download" componentClass={Download} />}
              >
                png
              </IconButton>
            </div>
          ) : null}
        </div>
      </Popover>
    );
  }, [
    allowImageDownload,
    notes,
    onDelete,
    onImageDownloadClick,
    onNotesChange,
    popoverForm,
    t,
  ]);

  return (
    <>
      <div className="row widget-panel-header" ref={ref}>
        <span className="col widget-panel-header__title">
          {title}
          {subtitle ? (
            <div className="widget-panel-header__subtitle">{subtitle}</div>
          ) : null}
        </span>
        <div className="col-auto" style={{ display: "flex", zIndex: 10 }}>
          {notes.trim().length ? (
            <Whisper
              trigger="click"
              placement="bottomEnd"
              speaker={<Tooltip>{notes}</Tooltip>}
              style={{ paddingTop: 40 }}
            >
              <Icon icon="sticky-note" size="lg" />
            </Whisper>
          ) : null}
          {speaker ? (
            <div
              className="views__dashboard-view__widgets__settings"
              ref={settingsRef}
            >
              <Whisper
                trigger="click"
                container={settingsEl as any}
                placement="bottom"
                onEntering={onEntering}
                onExiting={onExiting}
                speaker={speaker}
              >
                <IconButton
                  className="views__dashboard-view__widgets__settings__button"
                  ripple={false}
                  icon={<Icon icon="ellipsis-h" componentClass={Settings} />}
                />
              </Whisper>
            </div>
          ) : null}
        </div>
      </div>
      {children}
    </>
  );
};

export default React.memo(WidgetPanelHeader);
