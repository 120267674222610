export function sortSelectedOnTop<T>(
  data: T[],
  values: string[],
  valueProp: keyof T
): T[] {
  return data.sort((a, b) => {
    const aSelected = values.indexOf(a[valueProp] as any) >= 0;
    const bSelected = values.indexOf(b[valueProp] as any) >= 0;
    if (aSelected !== bSelected) {
      return aSelected ? -1 : 1;
    }
    return 0;
  });
}
