import React from "react";
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  HelpBlock,
  Icon,
  IconButton,
} from "rsuite";
import axios from "../../../inc/axios";
import { AuthContext } from "../../../provider/AuthProvider";
import "./index.scss";
import FileInput from "../FileInput";

export interface ILogoFileInputProps {
  value?: string;
  onChange: (fileId?: string) => void;
}

const LogoFileInput = ({ value, onChange }: ILogoFileInputProps) => {
  const { auth } = React.useContext(AuthContext);
  const [imageSrc, setImageSrc] = React.useState<string>();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedArea, setCroppedArea] = React.useState<Area>();

  const onCropComplete = React.useCallback(
    (_: unknown, newCroppedArea: any) => {
      setCroppedArea(newCroppedArea);
    },
    [],
  );

  const logCroppedImage = React.useCallback(async () => {
    if (!imageSrc || !croppedArea) {
      console.log("Crop failed");
      return;
    }
    const image = await new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = imageSrc;
    });
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Could not get imagecontext");
    }
    canvas.width = 100;
    canvas.height = 100;
    ctx.drawImage(
      image,
      croppedArea.x,
      croppedArea.y,
      croppedArea.width,
      croppedArea.height,
      0,
      0,
      100,
      100,
    );
    // As Base64 string
    const data = canvas.toDataURL("image/png");
    // data:image/png;base64,faDAFASdf...
    const [mimeInfo, body] = data.split(",");
    const mimeData = mimeInfo.match(/image\/(.+);/);
    if (!mimeData) {
      throw new Error("Invalid file");
    }
    axios
      .post("/file/crud", {
        extension: mimeData[1],
        body,
      })
      .then((res) => {
        setImageSrc(undefined);
        onChange(res.data.fileId);
      });
  }, [imageSrc, croppedArea, onChange]);
  const minZoomLevel = 0.5;
  const maxZoomLevel = 3;

  return (
    <>
      <div style={{ maxWidth: 300 }}>
        {value ? (
          <>
            <img
              src={`${axios.defaults.baseURL}file/crud/${value}?bearer=${auth?.bearer}`}
              style={{ maxWidth: "100% " }}
              alt=""
            />
            <div className="mt-2 mb-3">
              <IconButton
                size="xs"
                appearance="primary"
                color="red"
                icon={<Icon icon="trash" />}
                onClick={() => {
                  onChange(undefined);
                }}
              >
                Logo verwijderen
              </IconButton>
            </div>
          </>
        ) : (
          <h4>Er is nog geen logo ingesteld</h4>
        )}
        <HelpBlock>Nieuw logo instellen?</HelpBlock>
        {imageSrc ? (
          <div>
            <div style={{ position: "relative", height: 200 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                minZoom={minZoomLevel}
                maxZoom={maxZoomLevel}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
              <ButtonToolbar className="mediaweb-file-input__zoom-buttons">
                <ButtonGroup>
                  <IconButton
                    size="sm"
                    icon={<Icon icon="search-plus" />}
                    onClick={() => zoom < maxZoomLevel && setZoom(zoom + 0.1)}
                  />
                  <IconButton
                    size="sm"
                    icon={<Icon icon="search-minus" />}
                    onClick={() => zoom > minZoomLevel && setZoom(zoom - 0.1)}
                  />
                </ButtonGroup>
              </ButtonToolbar>
            </div>
            <Button
              onClick={logCroppedImage}
              appearance="primary"
              style={{ marginTop: 4 }}
            >
              Dit als logo instellen
            </Button>
          </div>
        ) : (
          <FileInput onChange={setImageSrc} />
        )}
      </div>
    </>
  );
};

export default LogoFileInput;
