export enum EChartType {
  curved = "curved",
  donut = "donut",
  bar = "bar",
  column = "column",
  line = "line",
}

export enum EIntervalType {
  "auto" = "auto",
  "day" = "day",
  "week" = "week",
  "month" = "month",
  "quarter" = "quarter",
  "year" = "year",
  // "none" = "none",
}
