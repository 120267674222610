import * as React from "react";
import { SVGProps } from "react";

const ChartCurved = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44.453}
    height={16}
    viewBox="0 0 44.453 16"
    {...props}
  >
    <path
      fill="none"
      stroke="#9baeaf"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={3}
      d="M42.345 11.773C37.57 5.778 36.174 1.5 31.473 1.5c-6.231.075-5.709 10.6-13.223 10.6S10.389 2.569 1.5 2.569"
    />
  </svg>
);

export default ChartCurved;
