import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useRssExports = singletonHook(undefined, () => {
  const { rssExports, setRssExports } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);
  const { userRoles = [] } = auth?.jwt || {};

  React.useEffect(() => {
    if (
      rssExports !== undefined ||
      !auth?.bearer ||
      userRoles.indexOf("superuser") + userRoles.indexOf("dashboardOnly") > -2
    ) {
      return;
    }
    setRssExports(null);
    axios
      .get<components["schemas"]["RssExport"][]>("/rssExport/crud")
      .then((res) => {
        setRssExports(
          res.data.reduce<IHashMap<components["schemas"]["RssExport"]>>(
            (prev, rssExport) => {
              prev[rssExport.rssExportId as string] = rssExport;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        // Alert.error(t("apiDataProvider_fetchingFailed_rssExport"));
      });
  }, [auth?.bearer, rssExports, setRssExports, userRoles]);

  return rssExports;
});

export default useRssExports;
