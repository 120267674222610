import { Icon, IconButton, Nav, Sidebar, Sidenav } from "rsuite";
import ArrowLeft from "../../../icons/ArrowLeft";
import ArrowRight from "../../../icons/ArrowRight";
import MigIcon from "../../../icons/Mig";
import NavItems from "../NavItems";
import CustomerMenu from "../CustomerMenu";
import React from "react";
import { TNavItem } from "../useUserNavConfig";
import { AuthContext } from "../../../provider/AuthProvider";
import { components } from "../../../types/openapi";
import { I18nContext } from "../../../provider/I18nProvider";
import { LayoutContext } from "../../../provider/LayoutProvider";
import "./index.scss";

interface IAppSideBarProps {
  currentNavItem?: TNavItem | "-";
  isMobile: boolean;
  navItems: TNavItem[];
  currentCustomer?: components["schemas"]["Customer"];
}

const AppSideBar = ({
  currentNavItem,
  isMobile,
  navItems,
}: IAppSideBarProps) => {
  const { auth } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const { isSidebarOpen, setIsSidebarOpen } = React.useContext(LayoutContext);

  const toggleSidebar = React.useCallback(
    () => setIsSidebarOpen((isOpen) => !isOpen),
    [setIsSidebarOpen]
  );

  if (!auth) {
    return null;
  }

  return (
    <>
      <Sidebar
        className="app-layout__sidebar"
        collapsible
        width={isSidebarOpen ? 250 : isMobile ? 0 : 80}
      >
        <Sidenav
          className="app-layout__sidenav"
          appearance="inverse"
          expanded={isSidebarOpen}
        >
          <Sidenav.Header className="app-layout__sidenav__header">
            <div className="app-layout__toggle">
              <IconButton
                className="app-layout__toggle__button"
                appearance="primary"
                color="blue"
                icon={
                  <Icon
                    icon="close"
                    componentClass={
                      isMobile
                        ? undefined
                        : isSidebarOpen
                        ? ArrowLeft
                        : ArrowRight
                    }
                  />
                }
                circle
                size={isMobile ? "sm" : "xs"}
                onClick={toggleSidebar}
              />
            </div>
          </Sidenav.Header>
          <Sidenav.Body className="app-layout__sidenav__body">
            <Nav vertical className="app-layout__brand">
              <Nav.Item
                className="remove-ripple remove-hover"
                componentClass="span"
                icon={
                  <Icon
                    icon="question"
                    className="app-layout__brand__logo"
                    componentClass={MigIcon}
                  />
                }
              >
                media·web
              </Nav.Item>
            </Nav>

            <Nav
              vertical
              appearance="subtle"
              reversed
              className="app-layout__sidenav__menu-middle"
            >
              <NavItems navItems={navItems} />
            </Nav>
            {auth.jwt.superUserId && isSidebarOpen ? (
              <div
                style={{
                  color: "white",
                  paddingLeft: 20,
                  paddingBottom: 4,
                }}
              >
                {t("loggedInAs")}
                <br />
                {auth.jwt.displayName}
              </div>
            ) : null}
            <Nav vertical>
              <CustomerMenu />
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
      {currentNavItem !== "-" && currentNavItem?.children && !isMobile ? (
        <Sidebar
          className={`app-layout__sidebar app-layout__sidebar--submenu ${
            isSidebarOpen ? "open" : ""
          }`}
          collapsible
          width={isSidebarOpen ? 300 : 80}
        >
          <Sidenav
            className="app-layout__sidenav"
            appearance="subtle"
            expanded={isSidebarOpen}
          >
            <Sidenav.Body className="app-layout__sidenav__body">
              <h1 className="app-layout__brand">{currentNavItem.title}</h1>
              <Nav
                vertical
                appearance="subtle"
                reversed
                className="app-layout__sidenav__menu-middle"
              >
                <NavItems navItems={currentNavItem.children} />
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        </Sidebar>
      ) : null}
    </>
  );
};

export default React.memo(AppSideBar);
