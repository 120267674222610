import React from "react";
import { Checkbox, Col, Grid, Icon, Loader, Panel, Row } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import axios from "../../../inc/axios";
import { components } from "../../../types/openapi";
import { subDays } from "date-fns";
import _, { sum } from "lodash";
import { compact, currency, formatInt } from "../../../inc/numbers";
import {
  DATE_FORMAT,
  differenceInDays,
  getPeriodDateRange,
  localeFormat,
  periodTypeToDateRange,
} from "../../../inc/date";
import TrendTooltip from "./TrendTooltip";
import { BootstrapSize } from "../../../inc/constants";
import { I18nContext } from "../../../provider/I18nProvider";
import MwWhisper from "../../../components/MwWhisper";
import { IBenchmarkWidgetProps } from "../index";
import { TBenchmark } from "../../../types";
import { useBenchmarkAnalysisCache } from "../inc/useBenchmarkAnalysisCache";
import "./index.scss";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";

enum EHighlightWidgetItemType {
  "AVE" = "AVE",
  "MESSAGES" = "MESSAGES",
  "REACH" = "REACH",
}

export interface IHighlightWidgetSettings {
  itemTypes?: EHighlightWidgetItemType[];
  notes?: string;
  showTrend?: boolean;
}

interface IHighlightItem {
  label: string;
  title?: string;
  value: string | React.ReactElement;
  trend?: {
    direction: "up" | "down" | "neutral";
    summary: number;
    description: string;
  };
}

type IBenchmarkAnalysisItem = {
  date: string;
  values?: number[];
};

const initialItemTypes: EHighlightWidgetItemType[] = [
  EHighlightWidgetItemType.MESSAGES,
  EHighlightWidgetItemType.REACH,
  EHighlightWidgetItemType.AVE,
];

async function getBenchmarkAnalysis(url: string, data: TBenchmark) {
  return axios
    .request<components["schemas"]["BenchmarkAnalysis"]>({
      method: "post",
      url,
      data,
    })
    .then((res) => res.data);
}

const HightlightBenchmarkWidget = ({
  benchmark,
  colWidth,
  onDelete,
  onNotesChange,
  onSettingsChange,
  onSettingsToggle,
  settings = {},
  uid,
  width,
}: IBenchmarkWidgetProps<IHighlightWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);

  const { showTrend = true, itemTypes = initialItemTypes } = settings;

  let variant: "square" | "wide" | "xwide" = "square";
  if (colWidth === 2 && width > BootstrapSize.SM) {
    variant = "wide";
  }
  if (colWidth === 3 && width > 935) {
    variant = "xwide";
  }

  const [countBenchmarkAnalysis, setCountBenchmarkAnalysis] =
    React.useState<components["schemas"]["BenchmarkAnalysis"]>();
  const [aveBenchmarkAnalysis, setAveBenchmarkAnalysis] =
    React.useState<components["schemas"]["BenchmarkAnalysis"]>();
  const [reachBenchmarkAnalysis, setReachBenchmarkAnalysis] =
    React.useState<components["schemas"]["BenchmarkAnalysis"]>();
  const [
    widgetComparePeriodAveBenchmarkAnalysis,
    setWidgetComparePeriodAveBenchmarkAnalysis,
  ] = React.useState<components["schemas"]["BenchmarkAnalysis"]>();
  const [
    widgetComparePeriodReachBenchmarkAnalysis,
    setWidgetComparePeriodReachBenchmarkAnalysis,
  ] = React.useState<components["schemas"]["BenchmarkAnalysis"]>();

  const dateType = "publicationDate";
  const dateRange = React.useMemo(
    () =>
      benchmark.searchEngineRequest.periodType === "custom" &&
      benchmark.searchEngineRequest.startDate &&
      benchmark.searchEngineRequest.endDate
        ? [
            new Date(benchmark.searchEngineRequest.startDate),
            new Date(benchmark.searchEngineRequest.endDate),
          ]
        : periodTypeToDateRange(benchmark.searchEngineRequest.periodType) || [],
    [
      benchmark.searchEngineRequest.endDate,
      benchmark.searchEngineRequest.periodType,
      benchmark.searchEngineRequest.startDate,
    ],
  );
  const startDate = dateRange[0];
  const endDate = dateRange[dateRange.length - 1];

  const previousPeriod: components["schemas"]["Period"] | null =
    React.useMemo(() => {
      if (!endDate) {
        return null;
      }
      const numOfDays = differenceInDays(endDate, startDate);
      const previousEndDate = subDays(startDate, 1);
      return {
        dateType,
        periodType: "custom" as components["schemas"]["Period"]["periodType"],
        startDate: subDays(previousEndDate, numOfDays).toISOString(),
        endDate: previousEndDate.toISOString(),
      };
    }, [endDate, startDate]);

  const widgetComparePeriodCountBenchmarkAnalysis = useBenchmarkAnalysisCache(
    `/benchmark/analyse/count/mediatype/day/${dateType}`,
    {
      ...benchmark,
      searchEngineRequest: {
        ...benchmark.searchEngineRequest,
        ...previousPeriod,
      },
    } as TBenchmark,
  );

  const widgetTargetPeriodDateRange = React.useMemo(
    () => (previousPeriod ? getPeriodDateRange(previousPeriod) : []),
    [previousPeriod],
  );

  React.useEffect(() => {
    async function hydrate() {
      setCountBenchmarkAnalysis(
        await getBenchmarkAnalysis(
          `/benchmark/analyse/count/mediatype/day/${dateType}`,
          benchmark,
        ),
      );
      setAveBenchmarkAnalysis(
        await getBenchmarkAnalysis(
          `/benchmark/analyse/summedAve/mediatype/day/${dateType}`,
          benchmark,
        ),
      );
      setReachBenchmarkAnalysis(
        await getBenchmarkAnalysis(
          `/benchmark/analyse/summedReach/mediatype/day/${dateType}`,
          benchmark,
        ),
      );
    }

    // Benchmark analysis can only be fetched synchronously!
    hydrate();
  }, [benchmark, dateType]);

  React.useEffect(() => {
    if (!previousPeriod) {
      setWidgetComparePeriodAveBenchmarkAnalysis(undefined);
      setWidgetComparePeriodReachBenchmarkAnalysis(undefined);
      return;
    }

    // When cache exists for previousPeriod, other data can be requestede
    if (widgetComparePeriodCountBenchmarkAnalysis) {
      getBenchmarkAnalysis(
        `/benchmark/analyse/summedAve/mediatype/day/${dateType}`,
        {
          ...benchmark,
          searchEngineRequest: {
            ...benchmark.searchEngineRequest,
            ...previousPeriod,
          },
        } as TBenchmark,
      ).then(setWidgetComparePeriodAveBenchmarkAnalysis);

      getBenchmarkAnalysis(
        `/benchmark/analyse/summedReach/mediatype/day/${dateType}`,
        {
          ...benchmark,
          searchEngineRequest: {
            ...benchmark.searchEngineRequest,
            ...previousPeriod,
          },
        } as TBenchmark,
      ).then(setWidgetComparePeriodReachBenchmarkAnalysis);
    }
  }, [benchmark, previousPeriod, widgetComparePeriodCountBenchmarkAnalysis]);

  const getHighlightItem = React.useCallback(
    (
      label: string,
      descriptionTKey: string,
      currentPeriodItems: IBenchmarkAnalysisItem[],
      targetPeriodItems?: IBenchmarkAnalysisItem[],
      renderTitle?: (value: number) => string,
    ): IHighlightItem => {
      let trend;
      const currentPeriodTotal = currentPeriodItems.reduce(
        (carry, item) => carry + (item.values ? sum(item.values) : 0),
        0,
      );
      // Calculate growth based on previous period
      if (
        showTrend &&
        widgetTargetPeriodDateRange.length &&
        !!targetPeriodItems?.length
      ) {
        const widgetTargetPeriodTotal = targetPeriodItems.reduce(
          (carry, item) => carry + (item.values ? sum(item.values) : 0),
          0,
        );
        const trendSummary =
          widgetTargetPeriodTotal === 0
            ? currentPeriodTotal
            : Math.abs(
                ((currentPeriodTotal - widgetTargetPeriodTotal) /
                  widgetTargetPeriodTotal) *
                  100,
              ) || 0;
        trend = {
          direction:
            trendSummary > 0
              ? currentPeriodTotal > widgetTargetPeriodTotal
                ? "up"
                : "down"
              : "neutral",
          summary: widgetTargetPeriodTotal ? trendSummary : NaN,
          description: t(
            descriptionTKey,
            localeFormat(widgetTargetPeriodDateRange[0], DATE_FORMAT),
            localeFormat(widgetTargetPeriodDateRange[1], DATE_FORMAT),
            formatInt(widgetTargetPeriodTotal),
          ),
        };
      }
      return {
        label,
        title: renderTitle
          ? renderTitle(currentPeriodTotal)
          : `${formatInt(currentPeriodTotal)} (${t("noDoubles")})`,
        value: compact(currentPeriodTotal),
        trend,
      } as IHighlightItem;
    },
    [showTrend, t, widgetTargetPeriodDateRange],
  );

  const highlightItems = React.useMemo<IHighlightItem[]>(() => {
    const highlightItems = [];
    if (
      countBenchmarkAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.MESSAGES)
    ) {
      const { items = [] } = countBenchmarkAnalysis || {};
      const widgetTargetPeriodItems = (
        widgetComparePeriodCountBenchmarkAnalysis || {}
      ).items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_messages"),
          "highlightWidget_messagesDescription",
          items,
          widgetTargetPeriodItems,
        ),
      );
    }

    if (
      reachBenchmarkAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.REACH)
    ) {
      const { items = [] } = reachBenchmarkAnalysis || {};
      const widgetTargetPeriodItems = (
        widgetComparePeriodReachBenchmarkAnalysis || {}
      ).items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_reach"),
          "highlightWidget_reachDescription",
          items,
          widgetTargetPeriodItems,
        ),
      );
    }

    if (
      aveBenchmarkAnalysis?.items?.length &&
      itemTypes.includes(EHighlightWidgetItemType.AVE)
    ) {
      const { items = [] } = aveBenchmarkAnalysis || {};
      const widgetTargetPeriodItems = (
        widgetComparePeriodAveBenchmarkAnalysis || {}
      ).items;
      highlightItems.push(
        getHighlightItem(
          t("highlightWidget_ave"),
          "highlightWidget_aveDescription",
          items,
          widgetTargetPeriodItems,
          (value) => `${currency(value)}  (${t("noDoubles")})`,
        ),
      );
    }

    return highlightItems;
  }, [
    countBenchmarkAnalysis,
    itemTypes,
    reachBenchmarkAnalysis,
    aveBenchmarkAnalysis,
    widgetComparePeriodCountBenchmarkAnalysis,
    getHighlightItem,
    t,
    widgetComparePeriodReachBenchmarkAnalysis,
    widgetComparePeriodAveBenchmarkAnalysis,
  ]);

  const columns = React.useMemo<IHighlightItem[][]>(() => {
    if (!highlightItems.length) {
      return [];
    }
    switch (variant) {
      // case "small":
      //   columns = _.chunk(highlightItems, 4);
      //   break;

      case "square":
        return _.chunk(highlightItems, 2);

      default:
        return [highlightItems];
    }
  }, [highlightItems, variant]);

  const isLoading =
    !countBenchmarkAnalysis || !aveBenchmarkAnalysis || !reachBenchmarkAnalysis;

  const panelBody = React.useMemo(() => {
    if (!columns.length) {
      if (isLoading) {
        return <Loader backdrop />;
      }

      return <InsufficientDataBody />;
    }

    const valueStyle: React.CSSProperties = {};
    if (variant === "square" && width < 320) {
      valueStyle.fontSize = 50;
    }

    return (
      <div className="d-flex flex-grow-1">
        <div className="d-flex views__benchmark-view__widgets__highlight-widget__left-column flex-grow-1">
          {columns[0]
            ? columns[0].map(({ label, value, title, trend }, key) => (
                <div
                  className={`views__benchmark-view__widgets__highlight-widget__highlight${
                    key === 0 ? " border-0 ps-0 ms-0" : ""
                  }`}
                  key={label}
                >
                  <div className="views__benchmark-view__widgets__highlight-widget__highlight-title">
                    {label}
                  </div>

                  <div className="views__benchmark-view__widgets__highlight-widget__highlight-body">
                    <span
                      className="views__benchmark-view__widgets__highlight-widget__highlight-body__value"
                      title={title}
                      style={valueStyle}
                    >
                      {value}
                    </span>
                    {trend ? (
                      <MwWhisper
                        placement="top"
                        trigger="hover"
                        speaker={
                          <TrendTooltip description={trend.description} />
                        }
                      >
                        <span
                          className={`views__benchmark-view__widgets__highlight-widget__highlight-body__trend views__benchmark-view__widgets__highlight-widget__highlight-body__trend--${trend.direction}`}
                          style={{ minWidth: 60, textAlign: "center" }}
                        >
                          {trend.direction === "neutral" ? null : (
                            <Icon
                              icon={
                                trend.direction === "up"
                                  ? "sort-up"
                                  : "sort-desc"
                              }
                            />
                          )}
                          {isFinite(trend.summary)
                            ? `${trend.summary.toFixed(1)}%`
                            : trend.direction === "neutral"
                            ? "—"
                            : null}
                        </span>
                      </MwWhisper>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
        {columns[1] ? (
          <div
            className={`ms-3 ps-3 flex-grow-1 border-left d-flex flex-column views__benchmark-view__widgets__highlight-widget__right-column justify-content-${
              variant === "square" ? "between" : "center"
            }`}
          >
            {columns[1].map(({ label, value, title, trend }) => (
              <div
                className="views__benchmark-view__widgets__highlight-widget__highlight"
                key={label}
              >
                <div className="views__benchmark-view__widgets__highlight-widget__highlight-title">
                  {label}
                </div>
                <div className="views__benchmark-view__widgets__highlight-widget__highlight-body">
                  <span
                    className="views__benchmark-view__widgets__highlight-widget__highlight-body__value"
                    title={title}
                    style={valueStyle}
                  >
                    {value}
                  </span>
                </div>
                {trend ? (
                  <div>
                    <MwWhisper
                      placement="top"
                      trigger="hover"
                      speaker={<TrendTooltip description={trend.description} />}
                    >
                      <span
                        className={`align-items-start views__benchmark-view__widgets__highlight-widget__highlight-body__trend views__benchmark-view__widgets__highlight-widget__highlight-body__trend--${trend.direction}`}
                        style={{ float: "left" }}
                      >
                        {trend.direction === "neutral" ? null : (
                          <Icon
                            icon={
                              trend.direction === "up" ? "sort-up" : "sort-desc"
                            }
                          />
                        )}
                        {`${trend.summary.toFixed(1)}%`}
                      </span>
                    </MwWhisper>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }, [columns, isLoading, variant, width]);

  const onShowHighlightWidgetItemTypeChange = React.useCallback(
    (highlightWidgetItemType: EHighlightWidgetItemType, isVisible: boolean) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        itemTypes: isVisible
          ? [...itemTypes, highlightWidgetItemType]
          : itemTypes.filter((item) => item !== highlightWidgetItemType),
      });
    },
    [itemTypes, onSettingsChange, settings, uid],
  );

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        notes={settings?.notes}
        onNotesChange={onNotesChange}
        title={t("Highlights")}
        onDelete={onDelete}
        allowImageDownload={!!onSettingsChange}
        onSettingsToggle={onSettingsToggle}
        popoverForm={
          onSettingsChange ? (
            <Grid fluid className="widgets-popover-form">
              <Row>
                <Col xs={24}>
                  <h3>{t("visibleHighlightWidgetItemTypes")}</h3>
                  {Object.keys(EHighlightWidgetItemType).map((itemType) => {
                    const checked = itemTypes.includes(
                      itemType as EHighlightWidgetItemType,
                    );
                    return (
                      <Checkbox
                        key={itemType}
                        value={itemType}
                        onChange={onShowHighlightWidgetItemTypeChange}
                        checked={checked}
                        disabled={
                          (checked && itemTypes.length < 2) ||
                          (!checked && itemTypes.length > 3)
                        }
                      >
                        {t(`showHighlightWidgetItemType_${itemType}`)}
                      </Checkbox>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                  <hr />
                  <Checkbox
                    name="showTrend"
                    checked={showTrend}
                    onChange={(_: unknown, checked) =>
                      onSettingsChange(uid, {
                        ...settings,
                        showTrend: checked,
                      })
                    }
                  >
                    {t("showTrend")}
                  </Checkbox>
                </Col>
              </Row>
            </Grid>
          ) : undefined
        }
      />
    ),
    [
      itemTypes,
      onDelete,
      onNotesChange,
      onSettingsChange,
      onSettingsToggle,
      onShowHighlightWidgetItemTypeChange,
      settings,
      showTrend,
      t,
      uid,
    ],
  );

  return (
    <Panel
      bordered={true}
      header={header}
      className={`views__benchmark-view__widgets__highlight-widget views__benchmark-view__widgets__highlight-widget--${variant}`}
    >
      {panelBody}
    </Panel>
  );
};

export default HightlightBenchmarkWidget;
