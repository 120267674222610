import React from "react";
import { Alert, Checkbox, Panel, SelectPicker } from "rsuite";
import "./index.scss";
import openapi from "../../../openapi.json";
import { IDashboardWidgetProps } from "../index";
import {
  ENarrowCastingMode,
  LayoutContext,
} from "../../../provider/LayoutProvider";
import { useHistory, useLocation } from "react-router-dom";
import MediaItemDeleteModal from "../../../components/MediaItemDeleteModal";
import axios, { AxiosError } from "axios";
import { components } from "../../../types/openapi";
import _ from "lodash";
import ExcelDownloadSettingsModal from "./ExcelDownloadSettingsModal";
import { startJob } from "../../../inc/job";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import IconRadioGroup from "../../../components/IconRadioGroup";
import MediaItemModal from "./MediaItemModal";
import { formatInt } from "../../../inc/numbers";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { IDashboardHashData } from "../index";
import BulkActionsBar from "./BulkActionsBar";
import MediaItemTableDisplay from "../../../icons/MediaItemTableDisplay";
import MediaItemBlockDisplay from "../../../icons/MediaItemBlockDisplay";
import MediaItemTable from "./MediaItemTable";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Drag from "../../../icons/Drag";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import { ELocaleOptions } from "../../../inc/i18n";
import { ESentiment } from "../../../inc/sentimentOptions";
import MediaItemGrid from "../../../components/MediaItemGrid";
import { useDashboardHashData } from "../../../views/DashboardView/inc/hooks";
import { LOAD_MEDIAITEM_COUNT } from "../../../inc/constants";
import { IMaartenError } from "../../../types";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";

export type TMediaItemDashboardWidgetColumnLayoutSettings = {
  propName: string;
  width?: number;
  show?: boolean;
};

export interface IMediaItemDashboardWidgetSettings {
  display?: "compact" | "block" | "table";
  sort?: components["schemas"]["MediaItemSortType"];
  columnLayouts?: TMediaItemDashboardWidgetColumnLayoutSettings[];
}

const MediaitemWidget = ({
  width,
  height,
  filterCacheResponse,
  onDelete,
  settings,
  setFilterCacheResponse,
  onSettingsChange,
  onSettingsToggle,
  period,
  uid,
}: IDashboardWidgetProps<IMediaItemDashboardWidgetSettings>) => {
  const history = useHistory();
  const location = useLocation();
  const locationHashData = useDashboardHashData();
  const { activeLanguage, t } = React.useContext(I18nContext);
  const { setMediaItemPurchases } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);
  const { narrowCastingMode, selectedMediaItemIds, setSelectedMediaItemIds } =
    React.useContext(LayoutContext);
  const [mediaItems, setMediaItems] = React.useState<
    Array<components["schemas"]["MediaItem"] | null | undefined>
  >(new Array(filterCacheResponse?.count).fill(undefined));

  const headerRowRef = React.useRef<HTMLDivElement>(null);
  const count = filterCacheResponse?.count || 0;
  const isIncomplete = React.useMemo(
    () => mediaItems.findIndex((mediaItem) => !mediaItem) >= 0,
    [mediaItems],
  );
  const isDisplayDashboardOnly = !onSettingsChange;
  const isNarrowCasting =
    narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF;
  const sort = (settings?.sort ||
    openapi.components.schemas.MediaItemSortType
      .default) as components["schemas"]["MediaItemSortType"];

  const {
    isAllSelected,
    selectedHydratedMediaItems,
    selectedHydratedMediaItemIds,
    selectedItemCount,
  } = React.useMemo<{
    isAllSelected: boolean;
    selectedHydratedMediaItems: components["schemas"]["MediaItem"][];
    selectedHydratedMediaItemIds: string[];
    selectedItemCount: number;
  }>(() => {
    const isAllSelected = selectedMediaItemIds === true;
    const hydratedFilterResults = mediaItems.filter(
      (mediaItem) => !!mediaItem,
    ) as components["schemas"]["MediaItem"][];
    const selectedHydratedMediaItems = isAllSelected
      ? hydratedFilterResults
      : hydratedFilterResults.filter(({ mediaItemId = "" }) =>
          (selectedMediaItemIds as string[]).includes(mediaItemId),
        );
    return {
      isAllSelected,
      selectedHydratedMediaItems,
      selectedHydratedMediaItemIds: selectedHydratedMediaItems.map(
        ({ mediaItemId = "" }) => mediaItemId,
      ),
      selectedItemCount: isAllSelected
        ? count || 0
        : selectedHydratedMediaItems.length,
    };
  }, [count, mediaItems, selectedMediaItemIds]);

  const openMediaItem = React.useCallback(
    (mediaItemId: string) => {
      // DO NOT USE locationHashData from hook: this function will be uncachable!
      let newLocationHashData: IDashboardHashData | null = null;
      try {
        const hashString = decodeURIComponent(window.location.hash.substr(1));
        if (hashString) {
          newLocationHashData = JSON.parse(hashString);
        }
      } catch (e) {
        console.log(e);
      }

      if (!newLocationHashData) {
        newLocationHashData = {};
      }

      newLocationHashData.openMediaItemWidgetId = uid;
      newLocationHashData.openMediaItemId = mediaItemId;
      history.push(
        `${window.location.pathname}#${encodeURIComponent(
          JSON.stringify(newLocationHashData),
        )}`,
      );
    },
    [history, uid],
  );

  const offerMediaItemPurchase = React.useCallback(
    (mediaItemId: string) => {
      if (!window.confirm(t("mediaItemPurchaseOffer"))) {
        return;
      }
      axios
        .request<components["schemas"]["MediaItemPurchase"]>({
          url: "/mediaItemPurchase/createFromMediaItems",
          method: "post",
          params: { mediaItemIds: mediaItemId },
        })
        .then(async (res) => {
          const boughtMediaItemResponse = await axios.get<
            components["schemas"]["MediaItem"]
          >(`/mediaItem/crud/${mediaItemId}`);
          setMediaItems((mediaItems) =>
            mediaItems.map((result) =>
              result && result.mediaItemId === mediaItemId
                ? boughtMediaItemResponse.data
                : result,
            ),
          );
          setMediaItemPurchases((mediaItemPurchases) => ({
            ...mediaItemPurchases,
            [res.data.mediaItemPurchaseId as string]: res.data,
          }));
          openMediaItem(mediaItemId);
        })
        .catch((err: AxiosError<IMaartenError>) => {
          Alert.error(t(err.response?.data.error || "genericErrorMessage"));
        });
    },
    [openMediaItem, setMediaItemPurchases, t],
  );

  const onMediaItemClick = React.useCallback(
    (mediaItem: components["schemas"]["MediaItem"]): void => {
      if (isDisplayDashboardOnly) {
        return;
      }
      if (mediaItem.isOwned && mediaItem.mediaItemId) {
        openMediaItem(mediaItem.mediaItemId);
        return;
      }
      offerMediaItemPurchase(mediaItem.mediaItemId!);
    },
    [isDisplayDashboardOnly, offerMediaItemPurchase, openMediaItem],
  );

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showExcelModal, setShowExcelModal] = React.useState<boolean>(false);
  const toggleShowExcelModal = React.useCallback(() => {
    setShowExcelModal((showExcelModal) => !showExcelModal);
  }, []);

  const getSelectionCacheToken = React.useCallback(() => {
    return selectedMediaItemIds === true
      ? Promise.resolve(filterCacheResponse?.token)
      : axios
          .post("/filter/cache", {
            // we need period for the pdf summary page
            period,
            mediaItemIds: selectedMediaItemIds,
          })
          .then((response) => {
            return response.data.token;
          });
  }, [filterCacheResponse?.token, period, selectedMediaItemIds]);

  const onMediaItemChange = React.useCallback(
    (
      mediaItemId: string,
      data: components["schemas"]["MediaItemPartial"],
    ): void => {
      setMediaItems((results) =>
        results.map((result) =>
          result && result.mediaItemId === mediaItemId
            ? { ...result, ...data }
            : result,
        ),
      );
      axios
        .request({
          url: `/mediaItem/crud/${mediaItemId}`,
          method: "patch",
          data,
        })
        .then((res) => {
          setMediaItems((results) =>
            results.map((result: any) => {
              if (result && result.mediaItemId === mediaItemId) {
                result = { ...result, ...res.data };
              }
              return result;
            }),
          );
        })
        .catch((err) => {
          console.log(err);
          Alert.error(t("invalidData"));
          setMediaItems(mediaItems); // Restore original results
        });
    },
    [setMediaItems, mediaItems, t],
  );

  const onBulkFavourite = React.useCallback(
    async (isFavourite: boolean) => {
      if (selectedItemCount > 10) {
        startJob(
          {
            filterCacheToken: await getSelectionCacheToken(),
            jobType: isFavourite
              ? "mediaItemSetFavourite"
              : "mediaItemSetUnfavourite",
          },
          () => {
            setMediaItems((results) =>
              results.map((result: any) => {
                if (
                  result &&
                  selectedHydratedMediaItemIds.includes(
                    result.mediaItemId as string,
                  )
                ) {
                  result.isFavourite = isFavourite;
                }
                return result;
              }),
            );
          },
        );
        return;
      }
      selectedHydratedMediaItemIds.forEach((mediaItemId) => {
        onMediaItemChange(mediaItemId, { isFavourite });
      });
    },
    [
      getSelectionCacheToken,
      onMediaItemChange,
      selectedHydratedMediaItemIds,
      selectedItemCount,
    ],
  );

  const onBulkRead = React.useCallback(
    async (isRead: boolean) => {
      if (selectedItemCount > 10) {
        await startJob(
          {
            filterCacheToken: await getSelectionCacheToken(),
            jobType: isRead ? "mediaItemSetRead" : "mediaItemSetUnread",
          },
          () => {
            setMediaItems((results) =>
              results.map((result: any) => {
                if (
                  result &&
                  selectedHydratedMediaItemIds.includes(
                    result.mediaItemId as string,
                  )
                ) {
                  result.isRead = isRead;
                }
                return result;
              }),
            );
          },
        );
        return;
      }
      selectedHydratedMediaItemIds.forEach((mediaItemId) => {
        onMediaItemChange(mediaItemId, { isRead });
      });
    },
    [
      getSelectionCacheToken,
      onMediaItemChange,
      selectedHydratedMediaItemIds,
      selectedItemCount,
    ],
  );

  const onPdfExport = React.useCallback(async () => {
    await startJob({
      filterCacheToken: await getSelectionCacheToken(),
      jobType: "filterPdfJob",
      sort,
      locale: activeLanguage === ELocaleOptions.NL ? "nl_NL" : "en_GB",
    });
  }, [activeLanguage, getSelectionCacheToken, sort]);

  const onSortChange = React.useCallback(
    (newSort: components["schemas"]["MediaItemSortType"]) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        sort: newSort,
      });
    },
    [onSettingsChange, settings, uid],
  );
  const historyLocationMediaItemId =
    locationHashData && locationHashData.openMediaItemWidgetId === uid
      ? locationHashData.openMediaItemId
      : null;
  const currentIndex = mediaItems
    ? mediaItems.findIndex(
        (mediaItem) =>
          mediaItem && mediaItem.mediaItemId === historyLocationMediaItemId,
      )
    : -1;
  const previousItem = mediaItems ? mediaItems[currentIndex - 1] : null;
  const nextItem = mediaItems ? mediaItems[currentIndex + 1] : null;

  React.useEffect(() => {
    if (currentIndex < 0) {
      return;
    }

    // If there are not-yet-loaded mediaitems before the current selected and opened one, load them
    if (previousItem === undefined && currentIndex > 0) {
      // load data before the current item
      const stopIndex = currentIndex - 1;
      loadMoreMediaItems({
        startIndex: Math.max(stopIndex - LOAD_MEDIAITEM_COUNT, 0),
        stopIndex,
      });
    }

    // If there are not-yet-loaded mediaitems after the current selected and opened one, load them
    if (nextItem === undefined && mediaItems.length > currentIndex + 1) {
      const startIndex = currentIndex + 1;
      loadMoreMediaItems({
        startIndex,
        stopIndex: Math.min(
          startIndex + LOAD_MEDIAITEM_COUNT,
          mediaItems.length - 1,
        ),
      });
    }
  });

  const navigateToPreviousItem = React.useCallback(() => {
    if (previousItem && previousItem.isOwned && previousItem.mediaItemId) {
      openMediaItem(previousItem.mediaItemId);
    }
  }, [openMediaItem, previousItem]);
  const navigateToNextItem = React.useCallback(() => {
    if (nextItem && nextItem.isOwned && nextItem.mediaItemId) {
      openMediaItem(nextItem.mediaItemId);
    }
  }, [nextItem, openMediaItem]);

  const mediaItem = React.useMemo<
    components["schemas"]["MediaItem"] | null | undefined
  >(() => {
    return historyLocationMediaItemId
      ? mediaItems.find(
          (filterResult) =>
            filterResult &&
            filterResult.mediaItemId === historyLocationMediaItemId,
        )
      : undefined;
  }, [mediaItems, historyLocationMediaItemId]);

  React.useEffect(() => {
    if (!mediaItem) {
      return;
    }
    const keyHandler = (e: KeyboardEvent) => {
      // @ts-ignore --- allow stuff entered in e.g. PR value box or delete item request description
      if (e.target?.nodeName === "INPUT" || "TEXTAREA") {
        return;
      }
      switch (e.code) {
        case "ArrowUp":
        case "ArrowLeft":
          e.preventDefault();
          navigateToPreviousItem();
          break;

        case "ArrowDown":
        case "ArrowRight":
          e.preventDefault();
          navigateToNextItem();
          break;
      }
    };
    window.addEventListener("keydown", keyHandler);
    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, [mediaItem, navigateToNextItem, navigateToPreviousItem]);
  const closeMediaItemModal = React.useCallback(() => {
    if (!locationHashData) {
      return;
    }
    const newLocationHashData = { ...locationHashData };
    delete newLocationHashData.openMediaItemWidgetId;
    delete newLocationHashData.openMediaItemId;
    history.push(
      `${location.pathname}#${encodeURIComponent(
        JSON.stringify(newLocationHashData),
      )}`,
    );
  }, [history, location.pathname, locationHashData]);

  const onMediaItemDelete = React.useCallback(() => {
    setMediaItems((mediaItems) =>
      mediaItems.filter((filterResult) =>
        mediaItem && filterResult
          ? filterResult.mediaItemId !== mediaItem.mediaItemId
          : true,
      ),
    );
    closeMediaItemModal();
  }, [closeMediaItemModal, mediaItem]);
  const getSelected = React.useCallback(
    (mediaItem: components["schemas"]["MediaItem"]): boolean =>
      Array.isArray(selectedMediaItemIds) && mediaItem.mediaItemId
        ? selectedMediaItemIds.indexOf(mediaItem.mediaItemId) >= 0
        : true,
    [selectedMediaItemIds],
  );
  const setSelected = React.useCallback(
    (
      mediaItem: components["schemas"]["MediaItem"],
      isSelected: boolean,
    ): void => {
      if (!mediaItem.mediaItemId) {
        return;
      }
      if (selectedMediaItemIds === true) {
        setSelectedMediaItemIds(
          mediaItems
            .map(
              (filterResult) =>
                (filterResult && filterResult.mediaItemId) || "",
            )
            .filter((a) => a !== mediaItem.mediaItemId),
        );
        return;
      }
      setSelectedMediaItemIds(
        isSelected
          ? [...selectedMediaItemIds, mediaItem.mediaItemId]
          : selectedMediaItemIds.filter((a) => a !== mediaItem.mediaItemId),
      );
    },
    [mediaItems, selectedMediaItemIds, setSelectedMediaItemIds],
  );

  const visibleColumns = React.useMemo(() => {
    const result = [
      "title",
      "mediaType",
      "source",
      "ave",
      "reach",
      "sentiment",
      "searchTopicIds",
      "insertDate",
      "publicationDate",
      "isFavourite",
      "labelIds",
      "prValue",
      "circulation",
    ];
    if (auth?.jwt.customerRole === "restricted") {
      result.push("isOwned");
    }
    return result;
  }, [auth?.jwt.customerRole]);

  const columns = React.useMemo(() => {
    let columnLayouts: TMediaItemDashboardWidgetColumnLayoutSettings[] =
      settings?.columnLayouts
        ? JSON.parse(JSON.stringify(settings?.columnLayouts))
        : [];

    if (settings?.columnLayouts && !_.isArray(columnLayouts)) {
      // ** TEMP **
      // Update the columnLayouts to the new format (used to be an hashmap of { propName: { width } })
      columnLayouts = [];
      Object.entries(settings?.columnLayouts).forEach(
        ([key, value]: [string, any]) => {
          columnLayouts.push({
            ...value,
            propName: key,
          });
        },
      );
    }
    const defaultHiddenColumn = ["prValue", "circulation"];

    visibleColumns.forEach((column) => {
      if (columnLayouts.some((property) => property.propName === column)) {
        return;
      }
      columnLayouts.push({
        propName: column,
        show: !defaultHiddenColumn.includes(column),
      });
    });

    return columnLayouts;
  }, [settings?.columnLayouts, visibleColumns]);

  const reorder = React.useCallback(
    (startIndex: number, endIndex: number) => {
      const [removed] = columns.splice(startIndex, 1);
      columns.splice(endIndex, 0, removed);
      return columns;
    },
    [columns],
  );

  const onDragEnd = React.useCallback(
    (result: any) => {
      // dropped outside the list
      if (!result.destination || !onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        columnLayouts: reorder(result.source.index, result.destination.index),
      });
    },
    [onSettingsChange, reorder, settings, uid],
  );

  React.useEffect(() => {
    setMediaItems(new Array(filterCacheResponse?.count).fill(undefined));
  }, [
    filterCacheResponse?.count,
    filterCacheResponse?.token,
    setMediaItems,
    sort,
  ]);

  const popoverForm = React.useMemo(
    () =>
      onSettingsChange ? (
        <>
          <h5>{t("widgetSettings_display")}</h5>
          <IconRadioGroup
            value={settings?.display || "block"}
            onChange={(value) => {
              onSettingsChange(uid, {
                ...settings,
                display: value as IMediaItemDashboardWidgetSettings["display"],
              });
            }}
            items={[
              {
                value: "table",
                svg: MediaItemTableDisplay,
              },
              // {
              //   value: "compact",
              //   icon: "th",
              // },
              {
                value: "block",
                svg: MediaItemBlockDisplay,
              },
            ]}
          />
          {settings?.display === "table" ? (
            <>
              <h5>Kolommen</h5>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`views__dashboard-view__widgets__mediaitem-widget__settings__container${
                        snapshot.isDraggingOver
                          ? " views__dashboard-view__widgets__mediaitem-widget__settings__container--dragging-over"
                          : ""
                      }`}
                    >
                      {columns.map((column, index) => (
                        <Draggable
                          key={column.propName}
                          draggableId={column.propName}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                left: "auto",
                                top: "auto",
                                display: "flex",
                                alignItems: "center",
                              }}
                              className={`views__dashboard-view__widgets__mediaitem-widget__settings__item${
                                snapshot.isDragging
                                  ? " views__dashboard-view__widgets__mediaitem-widget__settings__item--dragging"
                                  : ""
                              }`}
                            >
                              <div className="views__dashboard-view__widgets__mediaitem-widget__settings__item__drag-handle">
                                <Drag />
                              </div>
                              <Checkbox
                                checked={
                                  column.show !== undefined ? column.show : true
                                }
                                value={column.propName}
                                onChange={(_value, checked) => {
                                  if (
                                    isDisplayDashboardOnly ||
                                    !onSettingsChange
                                  ) {
                                    return;
                                  }
                                  const newcolumnLayouts = [...columns];
                                  newcolumnLayouts[index].show = checked;
                                  onSettingsChange(uid, {
                                    ...settings,
                                    columnLayouts: newcolumnLayouts,
                                  });
                                }}
                              >
                                {t(column.propName)}
                              </Checkbox>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : null}
        </>
      ) : undefined,
    [
      columns,
      isDisplayDashboardOnly,
      onDragEnd,
      onSettingsChange,
      settings,
      t,
      uid,
    ],
  );

  const onOpenDeleteModal = React.useCallback(
    () => setShowDeleteModal(true),
    [],
  );

  const loadMoreMediaItems = React.useCallback(
    ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
      if (!filterCacheResponse?.token) {
        return Promise.reject(
          new Error("Cannot load mediaitems without token"),
        );
      }
      const limit = Math.max(stopIndex - startIndex, 1);
      setMediaItems((mediaItems) => {
        // if (mediaItems[startIndex]) {
        //   console.log(startIndex, "already loaded!");
        // }
        return mediaItems.map((mediaItem, mediaItemKey) =>
          mediaItemKey >= startIndex && mediaItemKey < startIndex + limit
            ? null
            : mediaItem,
        );
      });
      return axios
        .request<components["schemas"]["MediaItem"][]>({
          method: "post",
          url: "/filter/results",
          params: {
            filterCacheToken: filterCacheResponse.token,
            limit,
            offset: startIndex,
            sort,
          },
        })
        .then((res) => {
          setMediaItems((mediaItems) =>
            mediaItems.map((mediaItem, mediaItemKey) =>
              mediaItemKey >= startIndex && res.data[mediaItemKey - startIndex]
                ? res.data[mediaItemKey - startIndex]
                : mediaItem,
            ),
          );
        });
    },
    [filterCacheResponse?.token, sort],
  );

  const onBulkSentiment = React.useCallback(
    async (sentiment: ESentiment | null) => {
      const customerSentiment = sentiment === null ? undefined : sentiment;
      const recalculatePrValue = window.confirm(
        t("doYouWantToRecalculatePrValue"),
      );
      await startJob(
        {
          filterCacheToken: await getSelectionCacheToken(),
          jobType: recalculatePrValue
            ? "mediaItemSetSentimentAndPrValue"
            : "mediaItemSetSentimentOnly",
          sentiment: customerSentiment,
        },
        () => {
          // we need to refresh the widget to know any new value...
          if (recalculatePrValue) {
            const changedIndexes = [...mediaItems.keys()].filter(
              (mediaItemIndex) =>
                mediaItems[mediaItemIndex] &&
                selectedHydratedMediaItemIds.includes(
                  mediaItems[mediaItemIndex]?.mediaItemId as string,
                ),
            );
            loadMoreMediaItems({
              startIndex: Math.min(...changedIndexes),
              stopIndex: Math.max(...changedIndexes) + 1,
            });
            return;
          }

          setMediaItems((results) =>
            results.map((result: any) => {
              if (
                result &&
                selectedHydratedMediaItemIds.includes(
                  result.mediaItemId as string,
                )
              ) {
                result.customerSentiment = customerSentiment;
              }
              return result;
            }),
          );
        },
      );
      return;
    },
    [
      getSelectionCacheToken,
      loadMoreMediaItems,
      mediaItems,
      selectedHydratedMediaItemIds,
      t,
    ],
  );

  const header = React.useMemo(() => {
    return (
      <WidgetPanelHeader
        allowImageDownload={false}
        title={t("mediaItemWidget_title", formatInt(count))}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={popoverForm}
      >
        {onSettingsChange && count ? (
          <div
            className="d-flex flex-wrap align-items-center my-2"
            ref={headerRowRef}
          >
            <div className="d-flex align-items-center">
              <div style={{ marginLeft: -10 }}>
                <Checkbox
                  indeterminate={!isAllSelected && !!selectedItemCount}
                  onChange={() =>
                    setSelectedMediaItemIds(isAllSelected ? [] : true)
                  }
                  checked={isAllSelected}
                >
                  {width >= 355 ? (
                    <span style={{ fontSize: 14, color: "#1e324e" }}>
                      {selectedItemCount && !isAllSelected
                        ? t(
                            `xSelectedItem${
                              selectedItemCount === 1 ? "" : "s"
                            }`,
                            selectedItemCount,
                          )
                        : t("allItems")}
                    </span>
                  ) : null}
                </Checkbox>
              </div>
              {selectedItemCount ? (
                <BulkActionsBar
                  getSelectionCacheToken={getSelectionCacheToken}
                  onBulkFavourite={onBulkFavourite}
                  onBulkRead={onBulkRead}
                  onBulkSentiment={onBulkSentiment}
                  setFilterResults={setMediaItems}
                  onOpenDeleteModal={onOpenDeleteModal}
                  onPdfExport={onPdfExport}
                  patchMediaItem={onMediaItemChange}
                  selectedMediaItemIds={selectedHydratedMediaItemIds}
                  selectedHydratedMediaItems={selectedHydratedMediaItems}
                  toggleShowExcelModal={toggleShowExcelModal}
                  warnForIncompleteSelection={isAllSelected && isIncomplete}
                  width={width}
                />
              ) : null}
            </div>
            <div className="d-flex flex-grow-1 justify-content-end">
              <SelectPicker
                className="views__dashboard-view__widgets__mediaitem-widget__sort"
                cleanable={false}
                searchable={false}
                appearance="subtle"
                data={openapi.components.schemas.MediaItemSortType.enum.map(
                  (value) => ({
                    label: t(value),
                    value,
                  }),
                )}
                placeholder={t("Sort")}
                placement="bottomEnd"
                value={sort}
                onChange={onSortChange}
              />
            </div>
          </div>
        ) : null}
      </WidgetPanelHeader>
    );
  }, [
    count,
    getSelectionCacheToken,
    isAllSelected,
    isIncomplete,
    onBulkFavourite,
    onBulkRead,
    onBulkSentiment,
    onDelete,
    onMediaItemChange,
    onOpenDeleteModal,
    onPdfExport,
    onSettingsChange,
    onSettingsToggle,
    onSortChange,
    popoverForm,
    selectedHydratedMediaItemIds,
    selectedHydratedMediaItems,
    selectedItemCount,
    setSelectedMediaItemIds,
    sort,
    t,
    toggleShowExcelModal,
    width,
  ]);

  const isMediaItemLoaded = React.useCallback(
    ({ index }: any) => {
      return mediaItems ? mediaItems[index] !== undefined : false;
    },
    [mediaItems],
  );

  const gridHeight = React.useMemo(
    () => height - (isNarrowCasting || isDisplayDashboardOnly ? 50 : 100),
    [height, isDisplayDashboardOnly, isNarrowCasting],
  );

  const panelBody = React.useMemo(() => {
    if (mediaItems && mediaItems.length === 0) {
      return <InsufficientDataBody />;
    }

    return settings?.display === "table" ? (
      <MediaItemTable
        columnLayouts={columns}
        getSelected={isDisplayDashboardOnly ? undefined : getSelected}
        height={height}
        mediaItems={mediaItems}
        onItemClick={isDisplayDashboardOnly ? undefined : onMediaItemClick}
        onMediaItemChange={
          isDisplayDashboardOnly ? undefined : onMediaItemChange
        }
        onSettingsChange={onSettingsChange}
        isMediaItemLoaded={isMediaItemLoaded}
        loadMoreMediaItems={loadMoreMediaItems}
        setSelected={isDisplayDashboardOnly ? undefined : setSelected}
        settings={settings}
        widgetUid={uid}
      />
    ) : (
      <MediaItemGrid
        disabled={isDisplayDashboardOnly}
        filterCacheResponse={filterCacheResponse}
        getSelected={isDisplayDashboardOnly ? undefined : getSelected}
        height={gridHeight}
        isMediaItemLoaded={isMediaItemLoaded}
        loadMoreMediaItems={loadMoreMediaItems}
        mediaItems={mediaItems}
        onMediaItemChange={onMediaItemChange}
        onMediaItemClick={onMediaItemClick}
        setSelected={isDisplayDashboardOnly ? undefined : setSelected}
        settings={settings}
        width={width - 4}
      />
    );
  }, [
    columns,
    filterCacheResponse,
    getSelected,
    gridHeight,
    height,
    isDisplayDashboardOnly,
    isMediaItemLoaded,
    loadMoreMediaItems,
    mediaItems,
    onMediaItemChange,
    onMediaItemClick,
    onSettingsChange,
    setSelected,
    settings,
    uid,
    width,
  ]);

  return (
    <Panel
      bodyFill={true}
      bordered={true}
      className={`views__dashboard-view__widgets__mediaitem-widget views__dashboard-view__widgets__mediaitem-widget--${uid}`}
      header={header}
    >
      {panelBody}

      <ExcelDownloadSettingsModal
        getSelectionCacheToken={getSelectionCacheToken}
        open={showExcelModal}
        onClose={toggleShowExcelModal}
        sort={sort}
      />

      {showDeleteModal && setFilterCacheResponse ? (
        <MediaItemDeleteModal
          show={showDeleteModal}
          amountOfItemsToDelete={selectedItemCount}
          onConfirm={async (message?: string) => {
            setShowDeleteModal(false);
            await startJob(
              {
                filterCacheToken: await getSelectionCacheToken(),
                jobType: "mediaItemDeleteJob",
                message,
              },
              () => {
                //update results
                setFilterCacheResponse((filterCacheResponse) => ({
                  ...filterCacheResponse,
                  count: filterCacheResponse.count - selectedItemCount,
                }));
                setMediaItems((results) =>
                  results.filter((result: any) => {
                    // null placeholders and such are fine...
                    if (!result) {
                      return true;
                    }
                    return !selectedHydratedMediaItemIds.includes(
                      result.mediaItemId as string,
                    );
                  }),
                );
              },
            );
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      ) : null}
      {mediaItem ? (
        <MediaItemModal
          close={closeMediaItemModal}
          mediaItem={mediaItem}
          onMediaItemChange={(
            mediaItem: components["schemas"]["MediaItem"],
          ) => {
            setMediaItems((results) =>
              (results || []).map((result) =>
                result && result.mediaItemId === mediaItem.mediaItemId
                  ? mediaItem
                  : result,
              ),
            );
          }}
          onMediaItemDelete={onMediaItemDelete}
          navigateToPreviousItem={
            previousItem && previousItem.isOwned
              ? navigateToPreviousItem
              : undefined
          }
          navigateToNextItem={
            nextItem && nextItem.isOwned ? navigateToNextItem : undefined
          }
        />
      ) : undefined}
    </Panel>
  );
};

export default React.memo(MediaitemWidget);
