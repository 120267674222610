import React, { SVGProps } from "react";

const Flag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.428}
    height={15}
    viewBox="0 0 11.428 15"
    fill="currentColor"
    {...props}
  >
    <path
      id="prefix__Path_572"
      d="M1594.068 568.783a.575.575 0 00.575-.575v-13.1a.575.575 0 00-1.149 0v13.1a.575.575 0 00.574.575z"
      transform="translate(-1584.093 -553.783)"
    />
    <path
      id="prefix__Path_573"
      d="M1606.017 565.043a.753.753 0 00.612-.284.52.52 0 00-.255-.786l-6.128-2.909 6.1-2.615a.526.526 0 00.3-.777.794.794 0 00-.956-.24l-7.227 3.1a.574.574 0 00-.377.5.563.563 0 00.35.509l7.227 3.431a.835.835 0 00.354.071z"
      transform="translate(-1598.083 -555.439)"
    />
    <rect
      id="prefix__Rectangle_851"
      width={2.874}
      height={1.392}
      rx={0.436}
      transform="translate(8.553)"
    />
  </svg>
);
export default Flag;
