export const elementHasClassName = (
  element: HTMLElement | (EventTarget & Element),
  className: string
): boolean => {
  // seen classname of type SVGAnimatedString
  // seen "Cannot read properties of undefined (reading 'includes')"
  return (
    !!element.className &&
    element.className.includes &&
    element.className.includes(className)
  );
};

export const elementOrParentHasClassName = (
  element: HTMLElement,
  className: string
): boolean => {
  if (elementHasClassName(element, className)) {
    return true;
  }
  return element.parentElement !== null
    ? elementOrParentHasClassName(element.parentElement, className)
    : false;
};
