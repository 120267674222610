import React from "react";
import { Button, Modal } from "rsuite";
import "./index.scss";
import { I18nContext } from "../../provider/I18nProvider";
import { nl2p } from "../../inc/text";

interface ILabelAutomatorDeleteModalProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const LabelAutomatorDeleteModal = (props: ILabelAutomatorDeleteModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { show, onCancel, onConfirm } = props;
  return (
    <Modal
      size={"sm"}
      className="delete-modal"
      backdrop={true}
      show={show}
      onHide={onCancel}
    >
      <Modal.Body
        className="px-3 px-md-4 px-lg-5"
        style={{ maxHeight: undefined }}
      >
        <div className="row">
          <div className="d-none d-lg-block col-lg-6">
            <img
              className="img-fluid delete-modal__image"
              alt="media·web"
              src="/img/art/kranteter/kranteter.png"
              srcSet="/img/art/kranteter/kranteter@2x.png 2x, /img/art/kranteter/kranteter@3x.png 3x"
            />
          </div>
          <div className="col-lg-6">
            <h3>{t("areYouSure")}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: nl2p(t("labelAutomatorDeleteMessage")),
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row justify-content-center">
          <div className="col-auto">
            <Button
              onClick={onConfirm}
              appearance="primary"
              color="red"
              className="w-100"
            >
              {t("delete")}
            </Button>
            <div className="w-100" />
            <Button onClick={onCancel} appearance="subtle" className="w-100">
              {t("cancel")}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default LabelAutomatorDeleteModal;
