import React from "react";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  FormGroup,
  Icon,
  IconButton,
  Popover,
} from "rsuite";
import "./index.scss";
import Settings from "../../../../icons/Settings";
import { DATE_TIME_SEPARATED_FORMAT, localeFormat } from "../../../../inc/date";
import { ApiDataContext } from "../../../../provider/ApiDataProvider";
import { components } from "../../../../types/openapi";
import axios, { AxiosError } from "axios";
import EditorialIssuePreviewModal from "./EditorialIssuePreviewModal";
import EditorialIssueResendModal from "./EditorialIssueResendModal";
import { WhisperInstance } from "rsuite/lib/Whisper";
import EditorialIssueStatsModal from "./EditorialIssueStatsModal";
import { I18nContext } from "../../../../provider/I18nProvider";
import useEditorialIssues from "../../../../hooks/useEditorialIssues";
import MwWhisper from "../../../../components/MwWhisper";
import { IMaartenError } from "../../../../types";

interface IEditorialIssueColProps {
  editorial: components["schemas"]["Editorial"];
  editorialIssue: components["schemas"]["EditorialIssue"];
}

const EditorialIssueCol = ({
  editorial,
  editorialIssue,
}: IEditorialIssueColProps) => {
  const whisperRef = React.useRef<WhisperInstance>();
  const { t } = React.useContext(I18nContext);
  const { setEditorialIssues } = React.useContext(ApiDataContext);
  const editorialIssues = useEditorialIssues();

  const [deleteParams, setDeleteParams] = React.useState({
    deleteEditorialRubricItems: false,
  });
  const [showEditorialIssuePreviewModal, setShowEditorialIssuePreviewModal] =
    React.useState<boolean>(false);
  const [showEditorialIssueResendModal, setShowEditorialIssueResendModal] =
    React.useState<boolean>(false);
  const [showEditorialIssueStatsModal, setShowEditorialIssueStatsModal] =
    React.useState<boolean>(false);
  return (
    <div className="col-lg-6 col-xl-4 mb-4">
      <div className="editorial-issue-item">
        <div className="editorial-issue-item__top-bar">
          <h3 className="editorial-issue-item__top-bar__title">
            {editorial.name}
          </h3>
          <MwWhisper
            ref={whisperRef}
            trigger="active"
            placement="bottom"
            speaker={
              <Popover className="popover-without-arrow editorial-issue-item__top-bar__popover">
                {editorialIssue.sentDatetime ? (
                  <>
                    <div>
                      <Button
                        size="sm"
                        appearance="subtle"
                        onClick={() => {
                          setShowEditorialIssuePreviewModal(true);
                          whisperRef.current?.close();
                        }}
                        block
                      >
                        {t("preview")}
                      </Button>
                    </div>
                    <div>
                      <Button
                        size="sm"
                        appearance="subtle"
                        onClick={() => {
                          setShowEditorialIssueResendModal(true);
                          whisperRef.current?.close();
                        }}
                        block
                      >
                        {t("resend")}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Form
                    onSubmit={() => {
                      axios
                        .request({
                          url: `/editorialIssue/crud/${editorialIssue.editorialIssueId}`,
                          method: "delete",
                          params: deleteParams,
                        })
                        .then(() => {
                          if (!editorialIssues) {
                            return;
                          }
                          const newEditorialIssues = { ...editorialIssues };
                          delete newEditorialIssues[
                            editorialIssue.editorialIssueId as string
                          ];
                          setEditorialIssues(newEditorialIssues);
                        })
                        .catch((err: AxiosError<IMaartenError>) => {
                          Alert.error(
                            t(
                              err.response?.data.error || "genericErrorMessage",
                            ),
                          );
                        });
                    }}
                  >
                    <FormGroup>
                      <Checkbox
                        name="deleteEditorialRubricItems"
                        value={deleteParams.deleteEditorialRubricItems}
                        onChange={() => {
                          setDeleteParams((deleteParams) => ({
                            ...deleteParams,
                            deleteEditorialRubricItems:
                              !deleteParams.deleteEditorialRubricItems,
                          }));
                        }}
                      >
                        {t("editorialIssueResetMessagesAfterDelete")}
                      </Checkbox>
                    </FormGroup>
                    <Button color="red" type="submit">
                      {t("delete")}
                    </Button>
                  </Form>
                )}
              </Popover>
            }
          >
            <IconButton
              className="editorial-issue-item__top-bar__settings-button"
              ripple={false}
              icon={
                <Icon
                  style={{ color: "#e68c91" }}
                  icon="ellipsis-h"
                  componentClass={Settings}
                />
              }
            />
          </MwWhisper>
          <EditorialIssuePreviewModal
            show={showEditorialIssuePreviewModal}
            editorialIssueId={editorialIssue.editorialIssueId as string}
            onClose={() => setShowEditorialIssuePreviewModal(false)}
          />
          <EditorialIssueResendModal
            show={showEditorialIssueResendModal}
            editorialIssueId={editorialIssue.editorialIssueId as string}
            onClose={() => setShowEditorialIssueResendModal(false)}
          />
          <EditorialIssueStatsModal
            show={showEditorialIssueStatsModal}
            editorialIssueId={editorialIssue.editorialIssueId as string}
            onClose={() => setShowEditorialIssueStatsModal(false)}
          />
        </div>
        <div className="editorial-issue-item__content">
          <h4 className="editorial-issue-item__content__intro">
            {editorialIssue.subject}
          </h4>
          <div className="editorial-issue-item__content__meta">
            {editorialIssue.scheduledDatetime &&
            !editorialIssue.sentDatetime ? (
              <small className="d-block">
                {t(
                  "scheduledFor",
                  localeFormat(
                    new Date(editorialIssue.scheduledDatetime),
                    DATE_TIME_SEPARATED_FORMAT,
                  ),
                )}
              </small>
            ) : null}
            {editorialIssue.sentDatetime ? (
              <>
                <small className="d-block">
                  {t(
                    "sentOn",
                    localeFormat(
                      new Date(editorialIssue.sentDatetime),
                      DATE_TIME_SEPARATED_FORMAT,
                    ),
                  )}
                </small>
                {editorialIssue.openPercentage !== undefined ? (
                  <small className="d-block">
                    {t("openedPercentage", editorialIssue.openPercentage)}
                  </small>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
        {editorialIssue.sentDatetime ? (
          <div>
            <Button
              size="sm"
              color="blue"
              onClick={() => setShowEditorialIssueStatsModal(true)}
            >
              {t("stats")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default EditorialIssueCol;
