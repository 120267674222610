import React from "react";
import { Alert, Button, Loader } from "rsuite";
import { Prompt } from "react-router-dom";
import UserForm from "../../components/UserForm";
import { TSchemaFormErrors } from "../../components/SchemaFormBody";
import {
  focusError,
  validationErrorsToSchemaFormErrors,
} from "../../inc/schema";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../inc/ajv";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { resetPassword } from "../../components/UserForm/helpers";
import { TUser } from "../../types";
import { I18nContext } from "../../provider/I18nProvider";
import { AuthContext } from "../../provider/AuthProvider";
import "./index.scss";
import useMes from "../../hooks/useMes";

const UserSchema = openapi.components.schemas.User as oas30.SchemaObject;
const validate = ajv.compile(UserSchema);

const ProfileView = () => {
  const { t } = React.useContext(I18nContext);
  const { setMes, updateUser } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const [user, setUser] = React.useState<TUser>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const [status, setStatus] = React.useState<"ready" | "submitting">("ready");

  const mes = useMes();
  const originalUser = React.useMemo(() => {
    if (!mes || !auth || !auth.jwt.userId) {
      return undefined;
    }
    return mes.find(
      (user) =>
        user.userId === auth.jwt.userId &&
        user.groupId === auth.jwt.currentGroupId
    );
  }, [auth, mes]);
  React.useEffect(() => {
    if (!originalUser) {
      return;
    }
    setUser(originalUser);
  }, [originalUser]);

  const onSubmit = React.useCallback(() => {
    if (user && user.settings === null) {
      delete user.settings;
    }
    const isValid = validate(user);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!user) {
      return;
    }
    setErrors({});
    const isNew = !(user && user.userId);
    updateUser(user)
      .then(() => {
        if (isNew) {
          resetPassword(user);
        }
        Alert.success(t("profileUpdateSuccessfull"));
        // force refresh of "mes"
        setMes(undefined);
        setStatus("ready");
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [user, updateUser, t, setMes]);

  const formIsDirty = React.useMemo(() => {
    return JSON.stringify(originalUser) !== JSON.stringify(user);
  }, [originalUser, user]);

  if (!user || status === "submitting") {
    return <Loader />;
  }

  return (
    <div className="profile-view">
      <Prompt
        when={formIsDirty}
        message={() => t("unsavedChangedAreYouSure")}
      />
      <UserForm user={user} setUser={setUser} errors={errors} />
      <Button
        className="mb-5"
        onClick={onSubmit}
        appearance="primary"
        disabled={!formIsDirty}
        title={formIsDirty ? undefined : t("profileUpdateSuccessfull")}
      >
        {t("save")}
      </Button>
    </div>
  );
};

export default ProfileView;
