import React, { SVGProps } from "react";

const DatepickerInsert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.229}
    height={18}
    viewBox="0 0 16.229 18"
    {...props}
  >
    <g id="prefix__Datepicker_icon">
      <path
        id="prefix__Path_558"
        d="M1588.9 274.676a.709.709 0 01-.709-.71V270.1a.709.709 0 011.419 0v3.869a.709.709 0 01-.71.707z"
        transform="translate(-1584.047 -269.388)"
        style={{ fill: "#1e324e" }}
      />
      <path
        id="prefix__Path_559"
        d="M1601.648 274.676a.71.71 0 01-.71-.71V270.1a.71.71 0 011.42 0v3.869a.709.709 0 01-.71.707z"
        transform="translate(-1590.268 -269.388)"
        style={{ fill: "#1e324e" }}
      />
      <path
        id="prefix__Path_563"
        d="M1593.314 289.074h-10.194a3.02 3.02 0 01-3.017-3.016v-10.193a3.02 3.02 0 013.017-3.016.709.709 0 110 1.417 1.6 1.6 0 00-1.6 1.6v10.192a1.6 1.6 0 001.6 1.6h10.194a1.6 1.6 0 001.6-1.6v-10.193a1.6 1.6 0 00-1.6-1.6.709.709 0 010-1.417 3.019 3.019 0 013.017 3.016v10.192a3.019 3.019 0 01-3.017 3.018z"
        transform="translate(-1580.104 -271.074)"
        style={{ fill: "#1e324e" }}
      />
      <path
        id="prefix__Path_564"
        d="M1595.333 274.268h-2.47a.709.709 0 110-1.419h2.47a.709.709 0 010 1.419z"
        transform="translate(-1585.985 -271.074)"
        style={{ fill: "#1e324e" }}
      />
      <text
        style={{
          fontSize: 12,
          lineHeight: 1.25,
          fontFamily: "sans-serif",
          fill: "#3cbe8c",
        }}
        x="4.4222655"
        y="14.948047"
        id="text74"
      >
        <tspan id="tspan72" x="4.4222655" y="14.948047">
          T
        </tspan>
      </text>
    </g>
  </svg>
);
export default DatepickerInsert;
