import React from "react";
import { Loader, Modal } from "rsuite";
import { components } from "../../../../../types/openapi";
import axios from "axios";
import { I18nContext } from "../../../../../provider/I18nProvider";

interface IEditorialIssuePreviewModalProps {
  show: boolean;
  editorialIssueId: string;
  onClose: () => void;
}

const EditorialIssuePreviewModal = ({
  show,
  onClose,
  editorialIssueId,
}: IEditorialIssuePreviewModalProps) => {
  const { t } = React.useContext(I18nContext);
  const [preview, setPreview] = React.useState<
    components["schemas"]["EditorialPreview"] | null
  >();
  const [previewEditorialIssueId, setPreviewEditorialIssueId] =
    React.useState<string>();

  React.useEffect(() => {
    if (!show) {
      return;
    }

    if (
      preview === undefined ||
      (previewEditorialIssueId && previewEditorialIssueId !== editorialIssueId)
    ) {
      setPreview(null);
      setPreviewEditorialIssueId(editorialIssueId);
      axios.get(`/editorialIssue/preview/${editorialIssueId}`).then((res) => {
        setPreview(res.data);
      });
    }
  }, [editorialIssueId, preview, previewEditorialIssueId, show]);

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      <Modal.Header>
        <Modal.Title>{t("editorialIssuePreview")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: undefined }}>
        {preview ? (
          <div
            className="mb-3 px-2"
            dangerouslySetInnerHTML={{ __html: preview.html }}
          />
        ) : (
          <Loader center size="lg" />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default EditorialIssuePreviewModal;
