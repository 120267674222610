import { Icon, IconButton, Modal } from "rsuite";
import MediaItem from "../../../../components/MediaItem";
import React from "react";
import { components } from "../../../../types/openapi";
import ArrowRight from "../../../../icons/ArrowRight";
import ArrowLeft from "../../../../icons/ArrowLeft";
import "./index.scss";
import { LayoutContext } from "../../../../provider/LayoutProvider";

interface IMediaItemModalProps {
  mediaItem?: components["schemas"]["MediaItem"];
  close: () => void;
  navigateToPreviousItem?: () => void;
  navigateToNextItem?: () => void;
  onMediaItemChange: (mediaItem: components["schemas"]["MediaItem"]) => void;
  onMediaItemDelete: () => void;
}

const MediaItemModal = ({
  mediaItem,
  close,
  navigateToPreviousItem,
  navigateToNextItem,
  onMediaItemChange,
  onMediaItemDelete,
}: IMediaItemModalProps) => {
  const { windowInnerHeight } = React.useContext(LayoutContext);
  return mediaItem ? (
    <Modal
      show={!!mediaItem}
      onHide={close}
      size="lg"
      className="views__dashboard-view__media-item-modal"
      dialogClassName="views__dashboard-view__media-item-modal__dialog"
    >
      <Modal.Body style={{ maxHeight: windowInnerHeight }}>
        <div className="views__dashboard-view__media-item-modal__dialog__nav-button-container">
          <IconButton
            appearance="subtle"
            icon={<Icon icon="long-arrow-left" componentClass={ArrowLeft} />}
            size="sm"
            disabled={!navigateToPreviousItem}
            onClick={navigateToPreviousItem}
          />
          <IconButton
            appearance="subtle"
            icon={<Icon icon="long-arrow-right" componentClass={ArrowRight} />}
            size="sm"
            disabled={!navigateToNextItem}
            onClick={navigateToNextItem}
          />
        </div>
        {mediaItem ? (
          <MediaItem
            mediaItem={mediaItem}
            onChange={onMediaItemChange}
            onDelete={onMediaItemDelete}
            onClose={close}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  ) : null;
};

export default React.memo(MediaItemModal);
