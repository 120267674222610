import React from "react";
import ReloadIcon from "@rsuite/icons/Reload";
import { ESentiment, sentimentOptions } from "../../../inc/sentimentOptions";
import SentimentButton from "../SentimentButton";
import LabelIdsPicker from "../../form/LabelIdsPicker";
import { Alert, Button, InputGroup, Tooltip } from "rsuite";
import { components } from "../../../types/openapi";
import { I18nContext } from "../../../provider/I18nProvider";
import MwWhisper from "../../MwWhisper";
import { currency } from "../../../inc/numbers";
import CurrencyInput from "react-currency-input-field";

interface ISettingsSectionProps {
  mediaItem: components["schemas"]["MediaItem"];
  onChange: (
    mediaItemPartial: components["schemas"]["MediaItemPartial"],
    recalculatePrValue?: boolean,
  ) => Promise<void>;
  showDeleteModal: () => void;
}

const SettingsSection = ({
  mediaItem,
  onChange,
  showDeleteModal,
}: ISettingsSectionProps) => {
  const { t } = React.useContext(I18nContext);
  const [customerPrInputValue, setCustomerPrInputValue] = React.useState<
    string | undefined
  >();
  const prValue =
    mediaItem.customerPrValue === undefined
      ? mediaItem.prValue
      : mediaItem.customerPrValue;

  React.useEffect(() => {
    setCustomerPrInputValue(
      prValue === undefined
        ? ""
        : currency(prValue).substring(2).replaceAll(".", ""),
    );
  }, [prValue]);

  const isPrValueCustom = prValue !== mediaItem.prValue;
  const isSentimentCustom =
    typeof mediaItem.customerSentiment !== "undefined" &&
    mediaItem.sentiment !== mediaItem.customerSentiment;

  const submitPrValue = React.useCallback(
    (e: any) => {
      e.preventDefault();
      const customerPrValue: number | undefined =
        customerPrInputValue === undefined
          ? undefined
          : parseFloat(customerPrInputValue.replace(",", "."));
      if (customerPrValue === mediaItem.customerPrValue) {
        return;
      }
      onChange({
        customerPrValue:
          customerPrValue === undefined ? mediaItem.prValue : customerPrValue,
      }).then(() => {
        Alert.success(t("prValueHasBeenSaved"));
      });
    },
    [
      customerPrInputValue,
      mediaItem.customerPrValue,
      mediaItem.prValue,
      onChange,
      t,
    ],
  );

  const onSentimentToggle = React.useCallback(
    (customerSentiment: ESentiment) => {
      const doUpdateCustomerPrValue =
        !mediaItem.customerPrValue ||
        window.confirm(t("doYouWantToRecalculatePrValue"));
      onChange(
        {
          customerSentiment,
        },
        doUpdateCustomerPrValue,
      );
    },
    [mediaItem.customerPrValue, onChange, t],
  );

  const onValueChange = React.useCallback((value: string | undefined): void => {
    setCustomerPrInputValue(value);
  }, []);

  const currentSentiment =
    mediaItem.customerSentiment === undefined
      ? mediaItem.sentiment
      : mediaItem.customerSentiment;

  return (
    <>
      <div className="d-flex mt-4  mb-3 align-items-end">
        <h5 className="flex-grow-1">{t("sentiment")}</h5>
        <div style={{ visibility: isSentimentCustom ? "visible" : "hidden" }}>
          <Button
            onClick={() => {
              onSentimentToggle(mediaItem.sentiment as ESentiment);
            }}
            size="xs"
            title={`Originele sentiment: ${t(
              sentimentOptions[mediaItem.sentiment as 1].label,
            )}`}
          >
            <ReloadIcon />
          </Button>
        </div>
      </div>
      <div className="components__media-item__sentiments-row">
        {Object.values(sentimentOptions)
          .filter((option) => !!option.value)
          .map((sentimentOption) => {
            const active = currentSentiment === sentimentOption.value;
            return (
              <SentimentButton
                key={sentimentOption.value}
                active={active}
                sentimentOption={sentimentOption}
                onClick={onSentimentToggle}
                style={
                  active && isSentimentCustom
                    ? { fontWeight: "bold" }
                    : undefined
                }
              />
            );
          })}
      </div>
      <h5 className="mt-4">{t("labels")}</h5>
      <LabelIdsPicker
        value={mediaItem.labelIds}
        onChange={(labelIds) => {
          onChange({ labelIds });
        }}
      />
      <form onSubmit={submitPrValue}>
        <div className="d-flex mt-4 align-items-end">
          <h5 className="flex-grow-1">{t("prValue")}</h5>
          <div style={{ visibility: isPrValueCustom ? "visible" : "hidden" }}>
            <Button
              onClick={() => {
                onChange({ customerPrValue: mediaItem.prValue }).then(() => {
                  Alert.success(t("prValueHasBeenSaved"));
                });
              }}
              size="xs"
              title={`Originele PR-waarde: ${currency(mediaItem.prValue || 0)}`}
            >
              <ReloadIcon />
            </Button>
          </div>
        </div>
        <InputGroup inside>
          <CurrencyInput
            intlConfig={{
              locale: "nl-NL",
              currency: "EUR",
            }}
            className="rs-input"
            onValueChange={onValueChange}
            disableAbbreviations
            fixedDecimalLength={2}
            value={customerPrInputValue}
            onBlur={submitPrValue}
            style={isPrValueCustom ? { fontWeight: "bold" } : undefined}
          />
        </InputGroup>
      </form>
      <div className="mt-auto d-flex">
        <MwWhisper
          trigger="hover"
          speaker={<Tooltip>{t("mediaItemDeleteHint")}</Tooltip>}
        >
          <Button
            placement="right"
            appearance="subtle"
            size="xs"
            onClick={showDeleteModal}
          >
            {t("delete")}
          </Button>
        </MwWhisper>
        <Button
          placement="right"
          appearance="subtle"
          style={{ marginLeft: "auto" }}
          size="xs"
          href="https://support.mediaweb.eu/article/meer-over-onze-data"
          target="_blank"
        >
          {t("moreAboutThisData")}
        </Button>
      </div>
    </>
  );
};
export default SettingsSection;
