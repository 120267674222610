import React, { ReactNode } from "react";
import { Container, Loader, Modal, Button, Content } from "rsuite";
import "./index.scss";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { LayoutContext } from "../../provider/LayoutProvider";
import { isAfter } from "date-fns";
import useUserNavConfig from "./useUserNavConfig";
import AppHeader from "./AppHeader";
import { Redirect, useLocation } from "react-router-dom";
import { BootstrapSize, APP_PATH, LOGIN_PATH } from "../../inc/constants";
import { nl2p } from "../../inc/text";
import DashboardTemplateDialog from "./DashboardTemplateDialog";
import EditorialTour from "./EditorialTour";
import { I18nContext } from "../../provider/I18nProvider";
import HelpBar from "./HelpBar";
import AppSideBar from "./AppSideBar";
import { AuthContext } from "../../provider/AuthProvider";
import axios from "../../inc/axios";
import { ELocaleOptions } from "../../inc/i18n";
import useMes from "../../hooks/useMes";
import { sha256 } from "js-sha256";

interface IAppLayoutProps {
  children: ReactNode;
}

export interface IMediawebNews {
  id: number;
  date: string;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
    protected: false;
  };
  acf?: {
    englishTitle?: string;
    englishBody?: string;
  };
}

const AppLayout = (props: IAppLayoutProps) => {
  const {
    setContentContainerDiv,
    isTemplateDialogOpen,
    narrowCastingMode,
    openTourId,
    startTour,
    tawkLoaded,
    windowOuterWidth,
  } = React.useContext(LayoutContext);
  const { t, activeLanguage } = React.useContext(I18nContext);
  const { updateUser } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const [mediawebNews, setMediawebNews] =
    React.useState<IMediawebNews | null>();

  const navItems = useUserNavConfig();
  const users = useMes();
  const location = useLocation();

  const { pathname } = location;
  const currentNavItem = React.useMemo(
    () =>
      navItems.find(
        (navItem) =>
          navItem !== "-" && navItem.to && pathname.startsWith(navItem.to),
      ),
    [navItems, pathname],
  );
  const currentUser = React.useMemo(
    () =>
      users && auth
        ? users.find(
            (user) =>
              user.userId === auth.jwt.userId &&
              user.groupId === auth.jwt.currentGroupId,
          )
        : null,
    [auth, users],
  );
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = React.useState(false);

  const isInholland = auth ? auth?.jwt.customerRole === "inholland" : undefined;
  const isDashboardOnly = auth
    ? auth.jwt.userRoles.indexOf("dashboardOnly") >= 0
    : undefined;
  const isImpersonated = auth ? !!auth?.jwt.superUserId : undefined;

  React.useEffect(() => {
    if (
      !currentUser ||
      isInholland ||
      isImpersonated ||
      isDashboardOnly !== false ||
      mediawebNews !== undefined
    ) {
      return;
    }
    setMediawebNews(null);
    axios
      .get<IMediawebNews[]>(
        "https://www.mediainfogroep.nl/wp-json/wp/v2/mediaweb_news?per_page=1",
      )
      .then((res) => {
        const latestNews = res.data[0];
        if (
          !latestNews ||
          (currentUser.settings?.lastMediawebNewsDate &&
            latestNews.date &&
            !isAfter(
              new Date(latestNews.date),
              new Date(currentUser.settings.lastMediawebNewsDate),
            ))
        ) {
          return;
        }
        setMediawebNews(res.data[0]);
      })
      .catch(console.log);
  }, [currentUser, isInholland, isImpersonated, mediawebNews, isDashboardOnly]);

  React.useEffect(() => {
    const { roles = [] } = currentUser || {};
    const modalShouldOpen =
      pathname === `${APP_PATH}/myMedia` &&
      currentUser &&
      roles.indexOf("superuser") + roles.indexOf("dashboardOnly") > -2 &&
      !currentUser?.settings?.firstVisitDate &&
      !isInholland &&
      !auth?.jwt.superUserId;
    if (!modalShouldOpen || !currentUser) {
      return;
    }
    setIsWelcomeModalOpen(true);
    updateUser({
      ...currentUser,
      settings: {
        ...currentUser.settings,
        firstVisitDate: new Date().toISOString(),
      },
    });
  }, [auth?.jwt.superUserId, currentUser, isInholland, pathname, updateUser]);

  React.useEffect(() => {
    const { Tawk_API } = window as any;
    if (!currentUser || !tawkLoaded || !Tawk_API || isInholland) {
      return;
    }
    if (currentUser && Tawk_API.setAttributes) {
      Tawk_API.setAttributes(
        {
          name: currentUser.displayName,
          email: currentUser.userName,
          hash: sha256.hmac(
            "445bc981cf03a7bb17453c5b08efbb3f38c89e21",
            currentUser.userName,
          ),
        },
        (tawkErr?: Error) => {
          if (tawkErr) {
            console.log(tawkErr);
          }
        },
      );
    }
  }, [currentUser, isInholland, tawkLoaded]);

  const closeMediawebNews = React.useCallback(() => setMediawebNews(null), []);

  // switch user?
  if (auth === null) {
    return <Loader />;
  }

  if (!auth) {
    // User isn't logged in
    return (
      <Redirect
        to={`${LOGIN_PATH}?path=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`}
      />
    );
  }
  if (
    !auth.jwt.superUserId &&
    auth.jwt.passwordExpirationDate &&
    isAfter(new Date(), new Date(auth.jwt.passwordExpirationDate))
  ) {
    // Password will expire within a couple of days
    return <Redirect to="/login/expiredPassword" />;
  }
  const classNames = ["app-layout"];
  if (location.pathname.startsWith(`${APP_PATH}/mediaItem`)) {
    classNames.push("app-layout--media-item");
  }
  if (currentNavItem !== "-" && currentNavItem?.children) {
    classNames.push("app-layout--active-submenu");
  }

  const mediawebNewsTitle =
    activeLanguage === ELocaleOptions.NL
      ? mediawebNews?.title.rendered
      : mediawebNews?.acf?.englishTitle;
  const mediawebNewsBody =
    activeLanguage === ELocaleOptions.NL
      ? mediawebNews?.content.rendered
      : mediawebNews?.acf?.englishBody;

  return (
    <Container className={classNames.join(" ")}>
      {!isInholland ? (
        <AppSideBar
          currentNavItem={currentNavItem}
          isMobile={windowOuterWidth < BootstrapSize.MD}
          navItems={navItems}
        />
      ) : null}
      <Container
        // https://github.com/elrumordelaluz/reactour/issues/252#issuecomment-744083772
        // --- not compatible with Reactour
        className={openTourId ? undefined : "overflow-auto"}
        style={{
          background:
            !currentNavItem ||
            pathname === `${APP_PATH}/myMedia` ||
            pathname === `${APP_PATH}/search` ||
            pathname.startsWith(`${APP_PATH}/dashboards`)
              ? "#fafaf7"
              : "#fff",
        }}
      >
        <Content
          className={`app-layout__content app-layout__content--${narrowCastingMode}`}
        >
          <AppHeader />
          <div
            ref={(contentContainerDiv) => {
              if (contentContainerDiv) {
                setContentContainerDiv(contentContainerDiv);
              }
            }}
          >
            {props.children}
          </div>
        </Content>
      </Container>
      {isTemplateDialogOpen ? (
        <DashboardTemplateDialog show={isTemplateDialogOpen} />
      ) : null}
      {isWelcomeModalOpen ? (
        <Modal
          show={isWelcomeModalOpen}
          onHide={() => setIsWelcomeModalOpen(false)}
          size={"md"}
        >
          <Modal.Header>
            <Modal.Title>{t("welcomeModal_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mb-4">
              <img
                className="welcomeModal__start-image"
                alt="media·web"
                src={"/img/art/welkom/welkom@3x.png"}
                srcSet={`/img/art/welkom/welkom@2x.png 2x, /img/art/welkom/welkom@3x.png 3x`}
                style={{ maxWidth: "95%", maxHeight: "25vh" }}
              />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: nl2p(t("welcomeModal_message")),
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setIsWelcomeModalOpen(false);
                if (startTour) {
                  startTour();
                }
              }}
              appearance="primary"
            >
              {t("welcomeModal_startTour")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {!isWelcomeModalOpen &&
      mediawebNews &&
      mediawebNewsTitle &&
      mediawebNewsBody ? (
        <Modal show onHide={closeMediawebNews} size={"md"}>
          <Modal.Header>
            <Modal.Title>{mediawebNewsTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mb-4">
              <img
                className="welcomeModal__start-image"
                alt="media·web"
                src={"/img/art/welkom/welkom@3x.png"}
                srcSet={`/img/art/welkom/welkom@2x.png 2x, /img/art/welkom/welkom@3x.png 3x`}
                style={{ maxWidth: "95%", maxHeight: "25vh" }}
              />
            </div>
            <div dangerouslySetInnerHTML={{ __html: mediawebNewsBody }} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (!currentUser) {
                  return;
                }
                updateUser({
                  ...currentUser,
                  settings: {
                    ...currentUser.settings,
                    lastMediawebNewsDate: mediawebNews.date,
                  },
                });
                closeMediawebNews();
              }}
            >
              {t("closeAndDontShowAgain")}
            </Button>
            <Button onClick={closeMediawebNews} appearance="primary">
              {t("close")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      <EditorialTour />
      {isInholland || isDashboardOnly ? null : <HelpBar />}
    </Container>
  );
};

export default AppLayout;
