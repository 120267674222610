import React, { ChangeEvent } from "react";
import SearchEngineRequestBar from "./SearchEngineRequestBar";
import { components } from "../../types/openapi";
import openapi from "../../openapi.json";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../inc/schema";
import { oas30 } from "openapi3-ts";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "../../inc/axios";
import { AxiosError } from "axios";
import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  HelpBlock,
  Icon,
  IconButton,
} from "rsuite";
import SearchEngineResultsPanel from "./SearchEngineResultsPanel";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import AutosizeInput from "react-18-input-autosize";
import "./index.scss";
import { APP_PATH } from "../../inc/constants";
import ajv from "../../inc/ajv";
import { TSchemaFormErrors } from "../../components/SchemaFormBody";
import Prullie from "../../icons/Prullie";
import { I18nContext } from "../../provider/I18nProvider";
import { useSearchEngineQueries } from "../../hooks/useSearchEngineQueries";
import SearchEngineTour from "./SearchEngineTour";
import SaveAsModal from "./SaveAsModal";
import { ValidationError } from "ajv";
import { AuthContext } from "../../provider/AuthProvider";
import { cleanQuery } from "../../inc/data";
import MigIcon from "../../icons/Mig";
import { IMaartenError } from "../../types";

export interface ISearchHashData {
  searchEngineRequest?: components["schemas"]["SearchEngineRequest"];
}

const SearchEngineQuerySchema = openapi.components.schemas
  .SearchEngineQuery as oas30.SchemaObject;
const emptySearchEngineQuery = getEmptyObject<
  components["schemas"]["SearchEngineQuery"]
>(SearchEngineQuerySchema);
emptySearchEngineQuery.query.includeMediaPrint = true;
emptySearchEngineQuery.query.includeMediaWeb = true;
const validate = ajv.compile(SearchEngineQuerySchema);

const emptySearchEngineRequest = getEmptyObject<
  components["schemas"]["SearchEngineRequest"]
>(openapi.components.schemas.SearchEngineRequest as oas30.SchemaObject);
emptySearchEngineRequest.query.includeMediaPrint = true;
emptySearchEngineRequest.query.includeMediaWeb = true;

const SearchEngineView = () => {
  const { t } = React.useContext(I18nContext);
  const { setSearchEngineQueries } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);
  const searchEngineViewRef = React.useRef<HTMLDivElement>(null);

  const searchEngineQueries = useSearchEngineQueries();
  const params = useParams<{ searchEngineQueryId?: string }>();
  const history = useHistory();
  const location = useLocation();

  const [searchEngineQueryId, setSearchEngineQueryId] =
    React.useState<string>();
  const [name, setName] = React.useState<string>("");
  const [isAutomatic, setIsAutomatic] = React.useState<boolean>(false);
  const [isSaveAsOpen, setIsSaveAsOpen] = React.useState<boolean>();

  const [searchEngineRequest, setSearchEngineRequest] = React.useState<
    components["schemas"]["SearchEngineRequest"]
  >(emptySearchEngineRequest);
  const [activeSearchEngineRequest, setActiveSearchEngineRequest] =
    React.useState<components["schemas"]["SearchEngineRequest"]>();
  const [results, setResults] = React.useState<
    components["schemas"]["SearchEngineResults"] | null
  >();
  const [errors, setErrors] = React.useState<
    TSchemaFormErrors<components["schemas"]["SearchEngineQuery"]>
  >({});

  const locationHashData = React.useMemo<ISearchHashData>(() => {
    if (location.hash.length < 2) {
      return {};
    }
    try {
      return JSON.parse(
        decodeURIComponent(location.hash.substring(1)),
      ) as ISearchHashData;
    } catch (e) {
      console.log(e);
      return {};
    }
  }, [location.hash]);

  React.useEffect(() => {
    if (!searchEngineQueries) {
      return;
    }
    let newSearchEngineQuery = params.searchEngineQueryId
      ? searchEngineQueries[params.searchEngineQueryId]
      : emptySearchEngineQuery;
    if (
      newSearchEngineQuery &&
      searchEngineQueryId !== params.searchEngineQueryId
    ) {
      setSearchEngineQueryId(params.searchEngineQueryId);
    }
  }, [searchEngineQueryId, searchEngineQueries, params.searchEngineQueryId]);

  React.useEffect(() => {
    if (!searchEngineQueries || !searchEngineQueryId) {
      return;
    }
    const searchEngineQuery = searchEngineQueries[searchEngineQueryId];
    if (!searchEngineQuery) {
      return;
    }
    setName(searchEngineQuery.name);
    setIsAutomatic(searchEngineQuery.isAutomatic || false);
    setActiveSearchEngineRequest({
      ...emptySearchEngineRequest,
      query: searchEngineQuery.query,
    });
  }, [searchEngineQueries, searchEngineQueryId]);

  const search = React.useCallback(
    (searchEngineRequest: any) => {
      const hashData: ISearchHashData = {
        searchEngineRequest: {
          ...searchEngineRequest,
          query: cleanQuery({
            ...searchEngineRequest.query,
            sources: searchEngineRequest.query.sources,
            authors: searchEngineRequest.query.authors,
          }),
        },
      };
      history.push(
        `${location.pathname}#${encodeURIComponent(JSON.stringify(hashData))}`,
      );
    },
    [history, location.pathname],
  );

  const toggleSaveAsDialog = React.useCallback(() => {
    setIsSaveAsOpen((isSaveAsOpen) => !isSaveAsOpen);
  }, []);

  const stringifiedActiveSearchEngineRequest = React.useMemo(
    () =>
      activeSearchEngineRequest
        ? JSON.stringify(activeSearchEngineRequest)
        : null,
    [activeSearchEngineRequest],
  );

  React.useEffect(() => {
    if (
      !locationHashData.searchEngineRequest ||
      JSON.stringify(locationHashData.searchEngineRequest) ===
        stringifiedActiveSearchEngineRequest
    ) {
      return;
    }
    setActiveSearchEngineRequest(locationHashData.searchEngineRequest);
  }, [stringifiedActiveSearchEngineRequest, locationHashData]);

  React.useEffect(() => {
    if (!stringifiedActiveSearchEngineRequest) {
      return;
    }
    const activeSearchEngineRequest = JSON.parse(
      stringifiedActiveSearchEngineRequest,
    );
    if (!activeSearchEngineRequest?.query.matchText) {
      return;
    }
    // eslint-disable-next-line
    setResults(null);
    setSearchEngineRequest(activeSearchEngineRequest);
    axios
      .request<components["schemas"]["SearchEngineResults"]>({
        method: "post",
        url: "/searchEngineSearch",
        data: activeSearchEngineRequest,
        params: {
          limit: 50,
          offset: 0,
        },
      })
      .then((res) => {
        setResults(res.data);
      })
      .catch((err: AxiosError<IMaartenError>) => {
        Alert.error(
          t(
            err.response?.data.error ||
              "Zoeken mislukt, probeer het later nog eens",
          ),
        );
      });
  }, [stringifiedActiveSearchEngineRequest, t]);

  const onSortingTypeChange = React.useCallback(
    (idolSortType?: components["schemas"]["IdolSortType"]) => {
      if (idolSortType && searchEngineRequest?.idolSortType !== idolSortType) {
        search({ ...searchEngineRequest, idolSortType });
      }
    },
    [search, searchEngineRequest],
  );

  const isInholland = auth?.jwt.customerRole === "inholland";
  const currentUserRoles = auth ? auth.jwt.userRoles : [];

  const isSearchEngineRequestDirty = React.useMemo<boolean>(() => {
    if (!activeSearchEngineRequest && !searchEngineRequest.query.matchText) {
      return false;
    }
    return (
      JSON.stringify(activeSearchEngineRequest) !==
      JSON.stringify(searchEngineRequest)
    );
  }, [activeSearchEngineRequest, searchEngineRequest]);
  const isSearchEngineQueryDirty = React.useMemo<boolean>(() => {
    if (!searchEngineQueries || !activeSearchEngineRequest) {
      return false;
    }
    if (!searchEngineQueryId) {
      return true;
    }
    const originalSearchEngineQuery = searchEngineQueries[searchEngineQueryId];
    return (
      !originalSearchEngineQuery ||
      originalSearchEngineQuery.name !== name ||
      originalSearchEngineQuery.isAutomatic !== isAutomatic ||
      JSON.stringify(cleanQuery(activeSearchEngineRequest.query)) !==
        JSON.stringify(originalSearchEngineQuery.query)
    );
  }, [
    activeSearchEngineRequest,
    isAutomatic,
    name,
    searchEngineQueries,
    searchEngineQueryId,
  ]);

  const updateSearchEngineQuery = React.useCallback(
    async (searchEngineQuery: components["schemas"]["SearchEngineQuery"]) => {
      const { searchEngineQueryId } = searchEngineQuery;
      const res = await axios.request<
        components["schemas"]["SearchEngineQuery"]
      >({
        method: searchEngineQueryId ? "put" : "post",
        url: `/searchEngineQuery/crud${
          searchEngineQueryId ? `/${searchEngineQueryId}` : ""
        }`,
        data: searchEngineQuery,
      });
      setSearchEngineQueries({
        ...searchEngineQueries,
        [res.data.searchEngineQueryId as string]: {
          ...res.data,
          query: cleanQuery(res.data.query),
        },
      });
      return res.data;
    },
    [searchEngineQueries, setSearchEngineQueries],
  );

  const saveSearchEngineQuery = React.useCallback(
    async (searchEngineQuery: components["schemas"]["SearchEngineQuery"]) => {
      let isValid = validate(searchEngineQuery);
      let { errors } = validate;

      if (
        isValid &&
        searchEngineQueries &&
        Object.values(searchEngineQueries).find(
          (obj) =>
            obj.name === searchEngineQuery.name &&
            obj.searchEngineQueryId !== searchEngineQuery.searchEngineQueryId,
        )
      ) {
        isValid = false;
        errors = [
          {
            instancePath: ".name",
            keyword: "custom",
            message: "name already exists",
            schemaPath: "#/properties/name",
            params: {},
          },
        ];
      }

      if (!isValid && errors && errors.length) {
        setErrors(validationErrorsToSchemaFormErrors(errors));
        setTimeout(focusError, 200);
        throw new ValidationError(errors);
      }

      // mark it non-dirty
      if (searchEngineQuery.searchEngineQueryId) {
        setSearchEngineQueries((currentSearchEngineQueries) => ({
          ...currentSearchEngineQueries,
          [searchEngineQuery.searchEngineQueryId as string]: {
            ...searchEngineQuery,
            query: cleanQuery(searchEngineQuery.query),
          },
        }));
      }

      setErrors({});
      try {
        const updatedSearchEngineQuery = await updateSearchEngineQuery(
          searchEngineQuery,
        );
        setName(updatedSearchEngineQuery.name);
        setSearchEngineQueryId(updatedSearchEngineQuery.searchEngineQueryId);
        history.push(
          `${APP_PATH}/search/${updatedSearchEngineQuery.searchEngineQueryId}`,
        );
      } catch (err: any) {
        Alert.error(t(err.response?.data.error || "genericErrorMessage"));
      }
    },
    [
      history,
      searchEngineQueries,
      setSearchEngineQueries,
      t,
      updateSearchEngineQuery,
    ],
  );

  const onSearchEngineQuerySubmit = React.useCallback(
    (e?: React.FormEvent) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (
        !isSearchEngineQueryDirty ||
        isInholland ||
        !activeSearchEngineRequest
      ) {
        return;
      }
      saveSearchEngineQuery({
        isAutomatic,
        searchEngineQueryId,
        name: name || "",
        query: activeSearchEngineRequest.query,
      }).catch((err: AxiosError<IMaartenError>) => {
        Alert.error(
          t(
            err.response?.data.error ||
              "Opslaan zoekopdracht mislukt, probeer het later nog eens",
          ),
        );
      });
    },
    [
      activeSearchEngineRequest,
      isAutomatic,
      isInholland,
      isSearchEngineQueryDirty,
      name,
      saveSearchEngineQuery,
      searchEngineQueryId,
      t,
    ],
  );

  const onItemDelete = React.useCallback(
    (searchEngineQueryId: string) => {
      if (!window.confirm(t("deleteConfirmation"))) {
        return;
      }
      axios
        .delete(`/searchEngineQuery/crud/${searchEngineQueryId}`)
        .then(() => {
          setSearchEngineQueries((currentSearchEngineQueries) => {
            if (!currentSearchEngineQueries) {
              return currentSearchEngineQueries;
            }
            const newSearchEngineQueries = { ...currentSearchEngineQueries };
            delete newSearchEngineQueries[searchEngineQueryId];
            return newSearchEngineQueries;
          });
          Alert.success(t("deleteSuccessful"));
          history.push(`${APP_PATH}/search`);
        })
        .catch((err) => {
          Alert.error(t(err.message || "genericErrorMessage"));
        });
    },
    [history, setSearchEngineQueries, t],
  );

  const loadableSearchEngineQueries = React.useMemo(() => {
    return searchEngineQueries
      ? Object.values(searchEngineQueries).sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
        )
      : [];
  }, [searchEngineQueries]);

  return (
    <div
      ref={searchEngineViewRef}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      {!isInholland ? (
        <form className="mb-3 mb-md-4" onSubmit={onSearchEngineQuerySubmit}>
          <div
            className="row align-items-center"
            style={{ minHeight: 40, justifyContent: "space-between" }}
          >
            <div className="col-auto d-none d-sm-flex search-engine-view__col-title">
              <div>
                <AutosizeInput
                  value={name}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setName(event.target.value);
                  }}
                  onKeyDown={(event: KeyboardEvent) => {
                    if (
                      event.key !== "Escape" ||
                      !searchEngineQueryId ||
                      !searchEngineQueries ||
                      !searchEngineQueries[searchEngineQueryId]
                    ) {
                      return;
                    }
                    setName(searchEngineQueries[searchEngineQueryId].name);
                  }}
                  style={{ maxWidth: "100%" }}
                  className="search-engine-view__title-autosize-input"
                  placeholder={t("namelessSearchEngineQuery")}
                  inputClassName="rs-input search-engine-view__title-autosize-input__input"
                />
                {errors && errors["name"] ? (
                  <HelpBlock
                    style={{ color: "red", marginLeft: 6, marginBottom: -23 }}
                  >
                    {t(errors["name"])}
                  </HelpBlock>
                ) : null}
              </div>
              <ButtonGroup
                size="xs"
                className="search-engine-view__topbar__save-search-engine-buttons d-none d-sm-flex"
              >
                <Button
                  className="search-engine-view__topbar__save-search-engine-buttons__button"
                  disabled={!isSearchEngineQueryDirty}
                  appearance={
                    !searchEngineQueryId || isSearchEngineQueryDirty
                      ? "primary"
                      : "ghost"
                  }
                  type="submit"
                  title={
                    isSearchEngineQueryDirty
                      ? undefined
                      : t("searchEngineQueryCanBeSavedWhenDirty")
                  }
                >
                  {t("save")}
                </Button>
                {searchEngineQueryId ? (
                  <Dropdown
                    placement="bottomEnd"
                    disabled={!isSearchEngineQueryDirty}
                    renderTitle={() => (
                      <IconButton
                        className="search-engine-view__topbar__save-search-engine-buttons__menu"
                        icon={<Icon icon="angle-down" />}
                        disabled={!isSearchEngineQueryDirty}
                        appearance={
                          isSearchEngineQueryDirty ? "primary" : "ghost"
                        }
                      />
                    )}
                  >
                    <Dropdown.Item onSelect={onSearchEngineQuerySubmit}>
                      {t("save")}
                    </Dropdown.Item>
                    <Dropdown.Item onSelect={toggleSaveAsDialog}>
                      {t("saveAs")}
                    </Dropdown.Item>
                  </Dropdown>
                ) : null}
              </ButtonGroup>
              {currentUserRoles.indexOf("automaticSearchEngineQueryManager") >=
              0 ? (
                <Checkbox
                  checked={isAutomatic}
                  onChange={(_: unknown, checked) => {
                    setIsAutomatic(checked);
                  }}
                >
                  {t("automaticallyAddResults")}
                </Checkbox>
              ) : null}
            </div>
            {loadableSearchEngineQueries &&
            loadableSearchEngineQueries.length ? (
              <div className="col-auto">
                <Dropdown
                  className="search-engine-view__search-engine-selector"
                  appearance="ghost"
                  size="xs"
                  title={t("open")}
                  placement="bottomEnd"
                >
                  {loadableSearchEngineQueries.map((item) => (
                    <Dropdown.Item
                      className="search-engine-view__search-engine-selector__item"
                      key={item.searchEngineQueryId}
                      onSelect={() => {
                        history.push(
                          `${APP_PATH}/search/${item.searchEngineQueryId}`,
                        );
                      }}
                    >
                      {item.name}
                      <IconButton
                        placement={"right"}
                        ripple={false}
                        className="bg-transparent search-engine-view__search-engine-selector__item__icon-button ms-auto me-0"
                        size="xs"
                        appearance="subtle"
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onItemDelete(item.searchEngineQueryId as string);
                        }}
                        icon={<Icon icon="trash" componentClass={Prullie} />}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </div>
            ) : null}
          </div>
        </form>
      ) : null}
      <SearchEngineRequestBar
        isDirty={isSearchEngineRequestDirty}
        onSearch={search}
        searchEngineQueryId={searchEngineQueryId}
        searchEngineRequest={searchEngineRequest}
        onChange={setSearchEngineRequest}
        resetSearchEngineRequest={() => {
          setSearchEngineRequest(
            activeSearchEngineRequest || emptySearchEngineRequest,
          );
        }}
      />
      {results !== undefined ? (
        <SearchEngineResultsPanel
          hideButton={false}
          isMoreAvailable={true}
          isSelectable={!isInholland}
          onSortingTypeChange={onSortingTypeChange}
          searchEngineResults={results}
          searchEngineRequest={searchEngineRequest}
        />
      ) : (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center pt-5 flex-column">
          <h2>{t("navigation_search")}</h2>
          <p className="mb-3">{t("searchEngineView_startDescription")}</p>
          <img
            className="search-engine-view__start-image"
            alt="media·web zoeken"
            src={"/img/art/zoeken/zoeken@3x.png"}
            srcSet={`/img/art/zoeken/zoeken@2x.png 2x, /img/art/zoeken/zoeken@3x.png 3x`}
            style={{ height: 435, width: 404 }}
          />
        </div>
      )}
      <SearchEngineTour />
      {isSaveAsOpen && name && activeSearchEngineRequest ? (
        <SaveAsModal
          close={toggleSaveAsDialog}
          searchEngineQuery={{
            name,
            ...activeSearchEngineRequest,
          }}
          setSearchEngineQueryName={setName}
          saveSearchEngineQuery={saveSearchEngineQuery}
        />
      ) : null}
      {isInholland ? (
        <div
          style={{
            flex: 1,
            alignItems: "flex-end",
            display: "flex",
            marginBottom: -20,
          }}
        >
          <div
            style={{
              fontSize: 16,
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MigIcon style={{ height: 50, width: 50 }} />
            media·web
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchEngineView;
