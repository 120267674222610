import React from "react";
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Modal,
  Panel,
  SelectPicker,
} from "rsuite";
import Plus from "../../../icons/Plus";
import Times from "../../../icons/Times";
import MediaType from "../../../icons/MediaType";
import { components } from "../../../types/openapi";
import { LayoutContext } from "../../../provider/LayoutProvider";
import {
  advancedMatchesToString,
  TAdvancedMatches,
} from "../../../inc/advancedMatch";
import { BootstrapSize } from "../../../inc/constants";
import AdvancedMatchPanel from "../../../components/AdvancedMatchPanel";
import Signal from "../../../icons/Signal";
import AsyncPopover from "../../../components/AsyncPopover";
import NumberBadge from "../../../components/NumberBadge";
import { I18nContext } from "../../../provider/I18nProvider";
import AdvancedMatchInput from "../../../components/AdvancedMatchInput";
import { AuthContext } from "../../../provider/AuthProvider";
import BottomArrowToggleButton from "../../../components/BottomArrowToggleButton";

import "./index.scss";
import { normalizeDoubleQuotes } from "../../../inc/text";
import SearchEngineDateRange from "../../../components/SearchEngineDateRange";

interface ISearchEngineRequestBarProps {
  isDirty: boolean;
  onSearch: (
    SearchEngineRequest: components["schemas"]["SearchEngineRequest"],
  ) => void;
  onChange: (
    SearchEngineRequest: components["schemas"]["SearchEngineRequest"],
  ) => void;
  resetSearchEngineRequest: () => void;
  searchEngineQueryId?: string;
  searchEngineRequest: components["schemas"]["SearchEngineRequest"];
}

const emptyAdvancedMatches: TAdvancedMatches = {
  all: [],
  some: [],
  none: [],
};

let lastSearchEngineQueryId: string | undefined = undefined;

const SearchEngineRequestBar = ({
  isDirty,
  onChange,
  onSearch,
  resetSearchEngineRequest,
  searchEngineQueryId,
  searchEngineRequest,
}: ISearchEngineRequestBarProps) => {
  const { auth } = React.useContext(AuthContext);
  const [isAdvancedMatchPanelExpanded, setIsAdvancedMatchPanelExpanded] =
    React.useState(false);
  const [
    isSearchEngineRequestsBarExpanded,
    setIsSearchEngineRequestsBarExpanded,
  ] = React.useState(false);
  const [advancedMatches, setAdvancedMatches] =
    React.useState<TAdvancedMatches>(emptyAdvancedMatches);
  const [activeAdvancedMatches, setActiveAdvancedMatches] =
    React.useState<TAdvancedMatches>(emptyAdvancedMatches);
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);

  const search = React.useCallback(() => {
    setActiveAdvancedMatches(advancedMatches);
    if (!isAdvancedMatchPanelExpanded) {
      setAdvancedMatches(emptyAdvancedMatches);
    }
    onSearch(searchEngineRequest);
  }, [
    advancedMatches,
    isAdvancedMatchPanelExpanded,
    onSearch,
    searchEngineRequest,
  ]);

  const showInputIcon = windowOuterWidth > BootstrapSize.MD;
  const sourcesWhisperRef = React.useRef<any>();

  const resetSearchRequest = React.useCallback(() => {
    setAdvancedMatches(activeAdvancedMatches);
    resetSearchEngineRequest();
  }, [activeAdvancedMatches, resetSearchEngineRequest]);

  React.useEffect(() => {
    const handleClickOutsideModal = (event: any) => {
      const searchEngineRequestBarContainer = document.querySelector(
        ".search-engine-request-bar-container",
      );
      // If you click outside the search-engine-request-bar-container
      // and there aren't any open rs menus
      if (
        searchEngineRequestBarContainer &&
        !searchEngineRequestBarContainer.contains(event.target) &&
        !document.querySelectorAll(".rs-popover,.rs-picker-menu").length
      ) {
        resetSearchRequest();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideModal);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [resetSearchRequest]);

  // clear when new searchEngineQuery is loaded
  React.useEffect(() => {
    if (lastSearchEngineQueryId !== searchEngineQueryId) {
      setAdvancedMatches(emptyAdvancedMatches);
      lastSearchEngineQueryId = searchEngineQueryId;
    }
  }, [resetSearchRequest, searchEngineQueryId]);

  const selectedMediaTypes = React.useMemo(() => {
    const selectedMediaTypes = [];
    if (
      searchEngineRequest.query.includeMediaWeb ===
      searchEngineRequest.query.includeMediaPrint
    ) {
      selectedMediaTypes.push("web", "print");
    } else {
      selectedMediaTypes.push(
        searchEngineRequest.query.includeMediaWeb ? "web" : "print",
      );
    }
    return selectedMediaTypes;
  }, [
    searchEngineRequest.query.includeMediaPrint,
    searchEngineRequest.query.includeMediaWeb,
  ]);
  const isInholland = auth?.jwt.customerRole === "inholland";
  const selectedSourceCount = (
    searchEngineRequest.query.includeSourcesIds || []
  ).length;
  return (
    <div
      className={`search-engine-request-bar-container${
        isDirty ? " search-engine-request-bar-container--dirty" : ""
      }`}
    >
      {isDirty ? (
        <Modal
          // Fix for loosing focus, e-mail dd. 29-7
          autoFocus={false}
          show={true}
          onHide={() => resetSearchRequest()}
          dialogClassName="d-none"
          backdrop="static"
        />
      ) : null}
      {isDirty ? (
        <div className="search-engine-request-bar__searchEngineRequest-button">
          <Button
            className="border border-light"
            appearance="primary"
            size="lg"
            onClick={search}
            disabled={!isDirty || !searchEngineRequest.query.matchText}
          >
            Zoeken
          </Button>
        </div>
      ) : null}
      <Panel className="search-engine-request-bar" shaded>
        <div className="row justify-content-between align-items-center">
          <div
            className="col-12 col-xl order-xl-2 search-engine-request-bar__global-col"
            style={{ flexGrow: 0 }}
          >
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col col-xl-auto order-xl-2">
                <SearchEngineDateRange
                  searchEngineRequest={searchEngineRequest}
                  onChange={onChange}
                />
              </div>
              <div className="col-auto d-md-none">
                <IconButton
                  className="search-engine-request-bar__menu-toggle"
                  circle
                  appearance="subtle"
                  icon={
                    <Icon
                      icon="star"
                      componentClass={
                        isSearchEngineRequestsBarExpanded ? Times : Plus
                      }
                    />
                  }
                  size="sm"
                  onClick={() =>
                    setIsSearchEngineRequestsBarExpanded(
                      !isSearchEngineRequestsBarExpanded,
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={`col-12 col-xl-auto order-xl-1 search-engine-request-bar__searchEngineRequests-col ${
              isSearchEngineRequestsBarExpanded ||
              windowOuterWidth >= BootstrapSize.MD
                ? "open"
                : ""
            }`}
            style={{ flex: 1 }}
          >
            <Panel
              bodyFill
              collapsible
              expanded={
                isSearchEngineRequestsBarExpanded ||
                windowOuterWidth >= BootstrapSize.MD
              }
              className="search-engine-request-bar__searchEngineRequests-col__panel"
              style={{ display: "flex" }}
            >
              <div className="d-xl-flex align-items-center">
                <AdvancedMatchInput
                  editable={!isAdvancedMatchPanelExpanded}
                  queryMatchText={searchEngineRequest.query.matchText || ""}
                  onChange={(queryMatchText) => {
                    if (
                      JSON.stringify(advancedMatches) !==
                      JSON.stringify(emptyAdvancedMatches)
                    ) {
                      setAdvancedMatches(emptyAdvancedMatches);
                    }
                    onChange({
                      ...searchEngineRequest,
                      query: {
                        ...searchEngineRequest.query,
                        matchText: normalizeDoubleQuotes(queryMatchText),
                      },
                    });
                  }}
                  onEnterPress={search}
                />
                {isInholland ? null : (
                  <SelectPicker
                    cleanable={true}
                    disabled={!searchEngineRequest.query.matchText}
                    value={
                      selectedMediaTypes.length === 1
                        ? selectedMediaTypes[0]
                        : null
                    }
                    onChange={(filterMediaType) => {
                      onChange({
                        ...searchEngineRequest,
                        query: {
                          ...searchEngineRequest.query,
                          includeMediaPrint:
                            !filterMediaType || filterMediaType === "print",
                          includeMediaWeb:
                            !filterMediaType || filterMediaType === "web",
                        },
                      });
                    }}
                    placeholder={
                      <div>
                        <MediaType /> {t("mediaType")}
                      </div>
                    }
                    searchable={false}
                    data={[
                      { value: "print", label: "Print" },
                      { value: "web", label: "Web" },
                    ]}
                    renderValue={(_value: string, selectedOption: any) => {
                      return (
                        <div>
                          <MediaType /> {selectedOption.label}
                        </div>
                      );
                    }}
                    className={`search-engine-request-bar__searchEngineRequests-col__button ${
                      showInputIcon ? "" : "without-icon"
                    }`}
                    size="xs"
                    appearance="subtle"
                    icon={
                      showInputIcon ? (
                        <Icon icon="question" componentClass={MediaType} />
                      ) : undefined
                    }
                  />
                )}
                <Divider vertical />
                <AsyncPopover
                  key="sourceNames"
                  dataUrl="/source/crud?forSearchEngine=true"
                  nameProp="name"
                  valueProp="sourceId"
                  whisperRef={sourcesWhisperRef}
                  values={searchEngineRequest.query.includeSourcesIds || []}
                  onChange={(sourceNames) => {
                    onChange({
                      ...searchEngineRequest,
                      query: {
                        ...searchEngineRequest.query,
                        includeSourcesIds: sourceNames,
                      },
                    });
                  }}
                >
                  <span
                    className={
                      "search-engine-request-bar__source-button-container"
                    }
                  >
                    <NumberBadge number={selectedSourceCount}>
                      <IconButton
                        className={`filter-bar__filters-col__button ${
                          showInputIcon ? "" : "without-icon"
                        }${
                          searchEngineRequest.query.matchText
                            ? ""
                            : " filter-bar__filters-col__button--disabled"
                        }`}
                        appearance="subtle"
                        size="xs"
                        disabled={!searchEngineRequest.query.matchText}
                        icon={
                          showInputIcon ? (
                            <Icon icon="question" componentClass={Signal} />
                          ) : undefined
                        }
                      >
                        {t("sources")}
                        <span
                          className={
                            selectedSourceCount
                              ? "rs-picker-toggle-clean"
                              : "d-none"
                          }
                          role="button"
                          tabIndex={-1}
                          onClick={() => {
                            onChange({
                              ...searchEngineRequest,
                              query: {
                                ...searchEngineRequest.query,
                                includeSourcesIds: [],
                              },
                            });
                          }}
                        >
                          ✕
                        </span>
                      </IconButton>
                    </NumberBadge>
                  </span>
                </AsyncPopover>
                <Divider vertical className="d-none d-xl-inline-block" />
              </div>
            </Panel>
          </div>
        </div>
      </Panel>
      <AdvancedMatchPanel
        isExpanded={isAdvancedMatchPanelExpanded}
        advancedMatch={advancedMatches}
        onChange={(newAdvancedMatches) => {
          setAdvancedMatches(newAdvancedMatches);
          onChange({
            ...searchEngineRequest,
            query: {
              ...searchEngineRequest.query,
              matchText: normalizeDoubleQuotes(
                advancedMatchesToString(newAdvancedMatches),
              ),
            },
          });
        }}
        shaded
      />
      <div
        className={`views-search-view__search-request-bar__toolbar${
          isAdvancedMatchPanelExpanded
            ? " views-search-view__search-request-bar__toolbar--open"
            : ""
        }`}
      >
        <BottomArrowToggleButton
          isAdvancedMatchPanelExpanded={isAdvancedMatchPanelExpanded}
          setIsAdvancedMatchPanelExpanded={setIsAdvancedMatchPanelExpanded}
        />
      </div>
    </div>
  );
};
export default SearchEngineRequestBar;
