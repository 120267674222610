import React, { RefObject } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  Input,
  InputGroup,
  Loader,
  Popover,
} from "rsuite";
import "./index.scss";
import MagnifyingGlass from "../../icons/MagnifyingGlass";
import axios from "../../inc/axios";
import _ from "lodash";
import { sortSelectedOnTop } from "../../inc/sort";
import { containsWord } from "../../inc/query";
import { List } from "react-virtualized";
import { WhisperInstance } from "rsuite/lib/Whisper";
import { I18nContext } from "../../provider/I18nProvider";
import MwWhisper from "../MwWhisper";
import { TypeAttributes } from "rsuite/lib/@types/common";

interface IAsyncPopoverProps<T> {
  children: React.ReactNode;
  dataUrl: string;
  initialLabel?: string;
  nameProp: keyof T;
  onChange?: (values?: string[]) => void;
  placement?: TypeAttributes.Placement;
  valueProp: keyof T;
  values: string[];
  whisperRef: React.Ref<WhisperInstance>;
}

function AsyncPopover<T>(props: IAsyncPopoverProps<T>) {
  const {
    dataUrl,
    children,
    initialLabel,
    nameProp,
    onChange,
    placement = "bottom",
    valueProp,
    values,
    whisperRef,
  } = props;
  const { t } = React.useContext(I18nContext);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [hydratedSearchQuery, setHydratedSearchQuery] =
    React.useState<string>("");
  const [data, setData] = React.useState<T[] | null>();
  const [sortCounter, setSortCounter] = React.useState<number>(0);
  const sortedData = React.useMemo(() => {
    return data ? sortSelectedOnTop<T>(data, values, valueProp) : data;
  }, [data, valueProp, values]);

  const change = React.useCallback(
    (values: string[]) => {
      if (onChange) {
        onChange(values);
      }
    },
    [onChange],
  );
  const isAuthor = dataUrl === "/author/crud";

  const debouncedDataObjectsHydrate = React.useMemo(
    () =>
      _.debounce((searchQuery: string) => {
        if (searchQuery === hydratedSearchQuery) {
          return;
        }
        setHydratedSearchQuery(searchQuery);
        setData(null);
        axios
          .request<T[]>({
            method: isAuthor ? "post" : "get",
            url: dataUrl,
            params: isAuthor ? undefined : { query: searchQuery },
            data: isAuthor ? { query: searchQuery } : undefined,
          })
          .then((res) => {
            if (Array.isArray(res.data)) {
              setData(sortSelectedOnTop<T>(res.data, values, valueProp));
            }
          });
      }, 1000),
    [dataUrl, hydratedSearchQuery, isAuthor, valueProp, values],
  );
  React.useEffect(() => {
    if (values.length && typeof data === "undefined") {
      // sources are not hydratable by name...?
      if (dataUrl === "/source/crud" && valueProp === "name") {
        setData(
          values.map((name) => ({
            name,
          })) as any,
        );
        setSortCounter(sortCounter + 1);
        return;
      }
      setData(null);
      const propName = valueProp as string;
      axios
        .request<T[]>({
          method: isAuthor ? "post" : "get",
          url: dataUrl,
          params: isAuthor ? undefined : { [`${propName}s`]: values.join(",") },
          data: isAuthor ? { authorIds: values } : undefined,
        })
        .then((res) => {
          if (Array.isArray(res.data)) {
            setData(sortSelectedOnTop<T>(res.data, values, valueProp));
          }
        });
    }
  }, [setData, sortCounter, data, dataUrl, valueProp, values, isAuthor]);

  React.useEffect(() => {
    if (
      searchQuery &&
      (searchQuery.toLowerCase() === "x" || searchQuery.length > 1)
    ) {
      debouncedDataObjectsHydrate(searchQuery);
      return;
    }

    setData(undefined);
  }, [debouncedDataObjectsHydrate, searchQuery]);

  const filterWithQuery = React.useCallback(() => {
    return !sortedData
      ? []
      : containsWord(sortedData, nameProp as string, searchQuery);
  }, [nameProp, searchQuery, sortedData]);

  const options = React.useMemo<any[]>(() => {
    if (sortedData === null) {
      return [<Loader key="loader" className="m-2" />];
    }
    if (!sortedData) {
      return [
        <p key="empty" className="mt-2">
          {initialLabel || t("provideASearchQueryFirst")}
        </p>,
      ];
    }

    const filteredDataObjects = filterWithQuery();
    const options = sortedData.map((dataObject) => (
      <Checkbox
        className="checkbox-max-width"
        key={dataObject[valueProp] as any}
        value={dataObject[valueProp]}
        disabled={!onChange}
      >
        {dataObject[nameProp]}
      </Checkbox>
    ));
    if (options.length > 1 && onChange) {
      if (
        filteredDataObjects.length &&
        filteredDataObjects.length !== options.length &&
        searchQuery
      ) {
        options.unshift(
          <Button
            key="**"
            value="**"
            className="my-1"
            size="xs"
            block
            onClick={() => {
              change(
                _.uniq([
                  ...values,
                  ...filteredDataObjects.map(
                    (dataObject) => dataObject[valueProp] as any,
                  ),
                ]),
              );
              const node = (whisperRef as RefObject<WhisperInstance>).current;
              if (node) {
                node.close();
              }
            }}
          >
            {t("selectWithWord", searchQuery, filteredDataObjects.length)}
          </Button>,
        );
      }
      options.unshift(
        <Button
          key="*"
          value="*"
          className="my-1"
          size="xs"
          block
          onClick={() => {
            change(
              _.uniq([
                ...values,
                ...sortedData.map((dataObject) => dataObject[valueProp] as any),
              ]),
            );
            const node = (whisperRef as RefObject<WhisperInstance>).current;
            if (node) {
              node.close();
            }
          }}
        >
          {t("selectAllResults", sortedData.length)}
        </Button>,
      );
    }
    return options;
    // eslint-disable-next-line
  }, [
    sortCounter,
    sortedData,
    filterWithQuery,
    valueProp,
    nameProp,
    searchQuery,
    change,
    values,
    whisperRef,
  ]);

  return (
    <MwWhisper
      placement={placement}
      trigger="click"
      onClose={
        onChange
          ? () => {
              if (!values.length) {
                onChange();
              }
              setSortCounter(sortCounter + 1);
            }
          : undefined
      }
      ref={whisperRef}
      speaker={
        <Popover className="popover-without-arrow components__async-popover">
          <InputGroup inside>
            <InputGroup.Addon>
              <Icon icon="star" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              size="sm"
              value={searchQuery}
              onChange={setSearchQuery}
              disabled={!onChange}
              autoFocus
            />
          </InputGroup>
          <div>
            <CheckboxGroup value={values} onChange={change} disabled={true}>
              {[
                // leave array syntax to comply to CheckboxGroup PropTypes
                <List
                  key="optionList"
                  rowCount={options.length}
                  rowHeight={36}
                  width={280}
                  height={230}
                  rowRenderer={(rowProps) => (
                    <div key={rowProps.key} style={rowProps.style}>
                      {options[rowProps.index]}
                    </div>
                  )}
                />,
              ]}
            </CheckboxGroup>
          </div>
        </Popover>
      }
    >
      {children}
    </MwWhisper>
  );
}
export default AsyncPopover;
