import React, { SVGProps } from "react";

const Search = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24.211}
      height={10.979}
      viewBox="0 0 24.211 10.979"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Verkennen_icon">
        <g id="prefix__Group_338">
          <path
            id="prefix__Path_639"
            d="M1449.085 496.453a.585.585 0 01.46 0l1.317.561a1.55 1.55 0 00-3.1 0z"
            transform="translate(-1437.209 -495.42)"
          />
          <path
            id="prefix__Path_640"
            d="M1428.318 506.116a5.431 5.431 0 115.43-5.431 5.437 5.437 0 01-5.43 5.431zm0-9.687a4.256 4.256 0 104.256 4.256 4.261 4.261 0 00-4.256-4.255z"
            transform="translate(-1422.887 -495.255)"
          />
          <path
            id="prefix__Path_641"
            d="M1428.556 504.564a.587.587 0 01-.587-.587 3.48 3.48 0 013.476-3.476.587.587 0 010 1.175 2.3 2.3 0 00-2.3 2.3.587.587 0 01-.589.588z"
            transform="translate(-1425.813 -498.275)"
          />
          <path
            id="prefix__Path_642"
            d="M1460.444 504.564a.587.587 0 01-.587-.587 3.48 3.48 0 013.476-3.476.587.587 0 110 1.175 2.3 2.3 0 00-2.3 2.3.587.587 0 01-.589.588z"
            transform="translate(-1444.169 -498.275)"
          />
          <path
            id="prefix__Path_643"
            d="M1459.777 506.395a5.43 5.43 0 115.43-5.43 5.437 5.437 0 01-5.43 5.43zm0-9.687a4.256 4.256 0 104.256 4.257 4.261 4.261 0 00-4.256-4.257z"
            transform="translate(-1440.997 -495.416)"
          />
          <path
            id="prefix__Path_644"
            d="M1449.906 501.6a.582.582 0 01-.23-.047l-1.762-.751-1.762.751a.587.587 0 11-.46-1.08l1.992-.849a.586.586 0 01.46 0l1.992.849a.587.587 0 01-.23 1.127z"
            transform="translate(-1435.809 -497.743)"
          />
          <path
            id="prefix__Path_645"
            d="M1446.056 509.576a.587.587 0 01-.231-1.128l1.935-.825a.586.586 0 01.46 0l1.9.811a.587.587 0 11-.46 1.08l-1.675-.713-1.7.727a.592.592 0 01-.229.048z"
            transform="translate(-1435.886 -502.348)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Search;
