import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useEditorialRubricMediaItems = singletonHook(undefined, () => {
  const { editorialRubricMediaItems, setEditorialRubricMediaItems } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      editorialRubricMediaItems !== undefined ||
      !auth?.bearer ||
      !auth.jwt.currentCustomerLinkId
    ) {
      return;
    }
    setEditorialRubricMediaItems(null);
    axios
      .get<components["schemas"]["EditorialRubricMediaItem"][]>(
        "/editorialRubricMediaItem/crud",
      )
      .then((res) => {
        setEditorialRubricMediaItems(
          res.data.reduce<
            IHashMap<components["schemas"]["EditorialRubricMediaItem"]>
          >((prev, editorialRubricMediaItem) => {
            prev[
              editorialRubricMediaItem.editorialRubricMediaItemId as string
            ] = editorialRubricMediaItem;
            return prev;
          }, {}),
        );
      });
  }, [
    auth?.bearer,
    setEditorialRubricMediaItems,
    editorialRubricMediaItems,
    auth?.jwt.currentCustomerLinkId,
  ]);

  return editorialRubricMediaItems;
});

export default useEditorialRubricMediaItems;
