import React from "react";
import { components } from "../../../types/openapi";
import { Button, Checkbox, Nav } from "rsuite";
import IconNavItem from "../../IconNavItem";
import Favourite from "../../../icons/Favourite";
import FavouriteFilled from "../../../icons/FavouriteFilled";
import Flag from "../../../icons/Flag";
import FlagFilled from "../../../icons/FlagFilled";
import LexisNexis from "../../../icons/LexisNexis";
import ItemBody from "./ItemBody";
import { getMediaItemIcon } from "../../../icons";
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  localeFormat,
  parseDateWithOptionalTime,
} from "../../../inc/date";
import AddToEditorialNavItem from "../../AddToEditorialNavItem";
import "./index.scss";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import MediaItemGridItemLabels from "../../MediaItemGridItemLabels";
import MediaItemGridItemSearchTopics from "../../MediaItemGridItemSearchTopics";
import {
  MEDIA_ITEM_GRID_ITEM_WIDTH_LG,
  MEDIA_ITEM_GRID_ITEM_WIDTH_MD,
} from "../inc/constants";

interface IMediaItemGridItemProps {
  colspan: number;
  disabled?: boolean;
  display: "compact" | "block";
  mediaItem: components["schemas"]["MediaItem"];
  onChange?: (
    mediaItemId: string,
    data: components["schemas"]["MediaItemPartial"] & { isOwned?: true },
  ) => void;
  onClick?: (mediaItem: components["schemas"]["MediaItem"]) => void;
  isSelected: boolean;
  setSelected?: (
    mediaItem: components["schemas"]["MediaItem"],
    isSelected: boolean,
  ) => void;
  width: number;
  preserveSpaceForLabelSlider?: boolean;
}

const MediaItemGridItem = (props: IMediaItemGridItemProps) => {
  const {
    disabled,
    display,
    mediaItem,
    isSelected,
    setSelected,
    onChange,
    onClick,
    width,
  } = props;
  const {
    imageUrl,
    isFavourite,
    isLexisNexis,
    isRead,
    isOwned,
    mediaItemId,
    mediaType,
    publicationDate,
    source,
    title,
  } = mediaItem;

  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const onBodyClick = React.useCallback(() => {
    if (onClick) {
      onClick(mediaItem);
    }
  }, [mediaItem, onClick]);

  const onFavouriteClick = React.useCallback(() => {
    if (!mediaItemId || !onChange) {
      return;
    }
    onChange(mediaItemId, {
      isFavourite: !isFavourite,
    });
  }, [isFavourite, mediaItemId, onChange]);

  const onReadClick = React.useCallback(() => {
    if (!mediaItemId || !onChange) {
      return;
    }
    onChange(mediaItemId, {
      isRead: !isRead,
    });
  }, [onChange, mediaItemId, isRead]);

  const mediaItemIds = React.useMemo(
    () => (mediaItemId ? [mediaItemId] : []),
    [mediaItemId],
  );

  const isFavouriteIconComponent = React.useMemo(
    () => (isFavourite ? FavouriteFilled : Favourite),
    [isFavourite],
  );

  const isReadIconComponent = React.useMemo(
    () => (isRead ? Flag : FlagFilled),
    [isRead],
  );

  const finalUrl = React.useMemo((): string => {
    if (imageUrl) {
      return imageUrl;
    }
    if (mediaType === "social") {
      switch (source.toLowerCase()) {
        case "twitter":
          return "/img/social/mediaItem/twitter.png";
        case "facebook":
          return "/img/social/mediaItem/facebook.png";
        case "flickr":
          return "/img/social/flickr.png";
        case "foursquare":
          return "/img/social/foursquare.png";
        case "instagram":
          return "/img/social/mediaItem/instagram.png";
        case "linkedIn":
          return "/img/social/linkedin.png";
        case "pinterest":
          return "/img/social/mediaItem/pinterest.png";
        case "snapchat":
          return "/img/social/snapchat.png";
        case "tubmlr":
          return "/img/social/tumblr.png";
        case "vimeo":
          return "/img/social/vimeo.png";
        case "whatsapp":
          return "/img/social/whatsapp.png";
        case "youtube":
          return "/img/social/mediaItem/youtube.png";
        case "tiktok":
          return "/img/social/mediaItem/tiktok.png";
        case "x":
          return "/img/social/mediaItem/x.png";
      }
    }
    return "data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
  }, [imageUrl, mediaType, source]);

  const toggleSelected = React.useCallback(() => {
    if (setSelected) {
      setSelected(mediaItem, !isSelected);
    }
  }, [isSelected, mediaItem, setSelected]);

  let size: "xs" | "md" | "lg" = "xs";
  if (width >= MEDIA_ITEM_GRID_ITEM_WIDTH_MD) {
    size = "md";
  }
  if (width >= MEDIA_ITEM_GRID_ITEM_WIDTH_LG) {
    size = "lg";
  }

  if (!mediaItemId || !auth) {
    return null;
  }

  return (
    <div
      className={`views__dashboard-view__widgets__mediaitem-widget__mediaitem views__dashboard-view__widgets__mediaitem-widget__mediaitem--${display} views__dashboard-view__widgets__mediaitem-widget__mediaitem--${size}${
        disabled ? " pt-3" : ""
      }`}
    >
      {disabled ? null : (
        <div className="components__media-item-grid-item__action-bar">
          <div className="components__media-item-grid-item__action-bar__checkbox-container">
            <Checkbox
              disabled={disabled}
              checked={isSelected}
              onChange={toggleSelected}
            />
          </div>
          <Nav className="components__media-item-grid-item__action-bar__actions">
            <IconNavItem
              disabled={disabled}
              title={size === "xs" ? "" : t("favorite")}
              iconComponent={isFavouriteIconComponent}
              onClick={onFavouriteClick}
            />
            <AddToEditorialNavItem
              disabled={disabled}
              forBulk={false}
              hideTitle={size === "xs"}
              mediaItemIds={mediaItemIds}
              placement={size === "xs" ? "bottomEnd" : "bottom"}
            />
            <IconNavItem
              disabled={disabled}
              title={
                size === "xs" ? "" : t(mediaItem.isRead ? "read" : "unread")
              }
              iconComponent={isReadIconComponent}
              titleStyle={
                mediaItem.isRead
                  ? undefined
                  : { color: "#2D56A3", fontWeight: "bold" }
              }
              iconStyle={mediaItem.isRead ? undefined : { color: "#2D56A3" }}
              onClick={onReadClick}
            />
          </Nav>
        </div>
      )}
      <div
        style={{ display: "flex", cursor: disabled ? "default" : "pointer" }}
        onClick={onBodyClick}
      >
        <div className="views__dashboard-view__widgets__mediaitem-widget__image">
          <img
            src={finalUrl}
            alt={title}
            style={{ maxWidth: "100%", marginBottom: 10 }}
          />
        </div>
        <div className="views__dashboard-view__widgets__mediaitem-widget__content">
          <div className="views__dashboard-view__widgets__mediaitem-widget__meta-data views__dashboard-view__widgets__mediaitem-widget__meta-data--top">
            <div>
              {isLexisNexis ? (
                <i
                  title={t("poweredByLexisnexis")}
                  style={{
                    width: 14,
                    height: 14,
                  }}
                >
                  <LexisNexis
                    style={{
                      marginTop: -2,
                      width: "100%",
                      height: "100%",
                      filter: "grayscale(100%) opacity(0.4)",
                    }}
                  />
                </i>
              ) : null}
              <span className={isLexisNexis ? "" : "ps-0"}>
                {localeFormat(
                  parseDateWithOptionalTime(publicationDate),
                  ["radio", "tv"].includes(mediaType)
                    ? DATE_TIME_FORMAT
                    : DATE_FORMAT,
                )}
              </span>
            </div>
            <div className="views__dashboard-view__widgets__mediaitem-widget__meta-data__source">
              {getMediaItemIcon(mediaItem)}
              <span>{source}</span>
            </div>
          </div>
          {size !== "xs" ? <ItemBody mediaItem={mediaItem} /> : null}
        </div>
      </div>
      {size === "xs" ? <ItemBody mediaItem={mediaItem} /> : null}
      <MediaItemGridItemLabels mediaItem={mediaItem} />
      <MediaItemGridItemSearchTopics mediaItem={mediaItem} />
      {auth.jwt.customerRole === "restricted" && !isOwned ? (
        <Button onClick={onBodyClick}>Kopen</Button>
      ) : null}
    </div>
  );
};

export default MediaItemGridItem;
