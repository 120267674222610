import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { IHashMap } from "../inc/data";
import { components } from "../types/openapi";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useCategories = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { categories, setCategories } = React.useContext(ApiDataContext);

  React.useEffect(() => {
    if (categories !== undefined || !auth?.bearer) {
      return;
    }
    setCategories(null);
    axios
      .get<components["schemas"]["Category"][]>("/category/crud")
      .then((res) => {
        setCategories(
          res.data.reduce<IHashMap<components["schemas"]["Category"]>>(
            (prev, category) => {
              prev[category.categoryId as string] = category;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_category"));
      });
  }, [auth?.bearer, setCategories, categories, t]);

  return categories;
});

export default useCategories;
