import React from "react";
import {
  Alert,
  Checkbox,
  FlexboxGrid,
  Loader,
  Nav,
  Panel,
  SelectPicker,
} from "rsuite";
import "./index.scss";
import openapi from "../../../openapi.json";
import { components } from "../../../types/openapi";
import IconRadioGroup from "../../../components/IconRadioGroup";
import SchemaTable from "../../../components/SchemaTable";
import { oas30 } from "openapi3-ts";

import { formatInt } from "../../../inc/numbers";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import axios, { AxiosError } from "axios";
import BuyPopover from "./BuyPopover";
import MediaItemModal from "./MediaItemModal";
import { LayoutContext } from "../../../provider/LayoutProvider";
import MediaItemTableDisplay from "../../../icons/MediaItemTableDisplay";
import MediaItemBlockDisplay from "../../../icons/MediaItemBlockDisplay";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import { InView } from "react-intersection-observer";
import InViewSearchEngineResultItem from "./InViewSearchEngineResultItem";
import { IHashMap } from "../../../inc/data";
import {
  getColspan,
  getSearchEngineResultWidth,
} from "../../../components/MediaItemGrid";
import { IMaartenError } from "../../../types";

const LOAD_MORE_OFFSET = 10;

export interface ISearchEngineResultsPanelSettings {
  display?: "compact" | "block" | "table";
  hideButton: boolean;
  isMoreAvailable: boolean;
  isSelectable: boolean;
  onSortingTypeChange?: (
    sortType: components["schemas"]["SearchEngineRequest"]["idolSortType"],
  ) => void;
  searchEngineRequest: components["schemas"]["SearchEngineRequest"];
  searchEngineResults: components["schemas"]["SearchEngineResults"] | null;
  sortType?: components["schemas"]["SearchEngineRequest"]["idolSortType"];
}

interface ISettings {
  display: "block" | "table";
}

const SearchEngineResultsPanel = (props: ISearchEngineResultsPanelSettings) => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { windowOuterWidth, windowInnerHeight } =
    React.useContext(LayoutContext);
  const {
    hideButton,
    isMoreAvailable,
    isSelectable,
    searchEngineRequest,
    onSortingTypeChange,
  } = props;
  const [searchEngineResults, setSearchEngineResults] =
    React.useState<components["schemas"]["SearchEngineResult"][]>();
  const [mediaItem, setMediaItem] = React.useState<
    components["schemas"]["MediaItem"] | null
  >();
  const [settings, setSettings] = React.useState<ISettings>({
    display: "block",
  });

  const headerRowRef = React.useRef<HTMLDivElement>(null);
  const [searchEngineResultDetailsMap, setSearchEngineResultDetailsMap] =
    React.useState<
      IHashMap<components["schemas"]["SearchEngineResultDetails"] | null>
    >({});
  React.useEffect(() => {
    setSearchEngineResults(props.searchEngineResults?.items);
  }, [props.searchEngineResults]);
  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const onLoadMore = React.useCallback((): Promise<void> => {
    if (!searchEngineResults || !isMoreAvailable) {
      throw new Error(
        "Cannot load more when no SearchEngineResults are available?",
      );
    }
    return axios
      .request<components["schemas"]["SearchEngineResults"]>({
        method: "post",
        url: "/searchEngineSearch",
        data: searchEngineRequest,
        params: {
          limit: 25,
          offset: searchEngineResults.length,
        },
      })
      .then((res) => {
        setSearchEngineResults((SearchEngineResults) =>
          SearchEngineResults
            ? [...SearchEngineResults, ...res.data.items]
            : [],
        );
      })
      .catch((err: AxiosError<IMaartenError>) => {
        Alert.error(t(err.response?.data.error || "genericErrorMessage"));
      });
  }, [isMoreAvailable, searchEngineRequest, searchEngineResults, t]);

  React.useEffect(() => {
    if (!searchEngineResults || settings.display === "block") {
      return;
    }
    const toBeHydratedDetailsIds: string[] = searchEngineResults
      .filter(
        (res) =>
          searchEngineResultDetailsMap[res.searchEngineResultId as string] ===
          undefined,
      )
      .map((res) => res.searchEngineResultId as string);
    if (!toBeHydratedDetailsIds.length) {
      return;
    }
    setSearchEngineResultDetailsMap((searchEngineResultDetailsMap) => {
      const newSearchEngineResultDetailsMap = {
        ...searchEngineResultDetailsMap,
      };
      toBeHydratedDetailsIds.forEach(
        (toBeHydratedDetailsId) =>
          (newSearchEngineResultDetailsMap[toBeHydratedDetailsId] = null),
      );
      return newSearchEngineResultDetailsMap;
    });
    Promise.all(
      toBeHydratedDetailsIds.map((searchEngineResultId) =>
        axios.get<components["schemas"]["SearchEngineResultDetails"]>(
          `/searchEngineResult/details/${searchEngineResultId}`,
        ),
      ),
    ).then((results) => {
      setSearchEngineResultDetailsMap((searchEngineResultDetailsMap) => {
        const newSearchEngineResultDetailsMap = {
          ...searchEngineResultDetailsMap,
        };
        results.forEach((res) => {
          newSearchEngineResultDetailsMap[res.data.searchEngineResultId] =
            res.data;
        });
        return newSearchEngineResultDetailsMap;
      });
    });
  }, [searchEngineResultDetailsMap, searchEngineResults, settings.display]);

  React.useEffect(() => {
    const scrollingBody = headerRowRef.current?.parentElement?.parentElement
      ?.childNodes[1] as HTMLDivElement;
    if (!scrollingBody || !searchEngineResults) {
      return;
    }

    const onScroll = () => {
      const isBottom =
        scrollingBody.offsetHeight +
          scrollingBody.scrollTop +
          LOAD_MORE_OFFSET >=
        scrollingBody.scrollHeight;
      if (
        isMoreAvailable &&
        isBottom &&
        !isLoadingMore &&
        props.searchEngineResults &&
        props.searchEngineResults.count > searchEngineResults.length
      ) {
        setIsLoadingMore(true);
        onLoadMore().then(() => {
          setIsLoadingMore(false);
        });
      }
    };

    const hasScrollbar =
      scrollingBody.scrollHeight > scrollingBody.clientHeight;
    if (
      searchEngineResults.length &&
      !hasScrollbar &&
      !isLoadingMore &&
      props.searchEngineResults &&
      props.searchEngineResults.count > searchEngineResults.length
    ) {
      // Load more if the widget isn't fill completely and more items are available
      onScroll();
    }

    scrollingBody.addEventListener("scroll", onScroll);
    return () => {
      scrollingBody.removeEventListener("scroll", onScroll);
    };
  }, [
    props.searchEngineResults,
    searchEngineResults,
    isLoadingMore,
    onLoadMore,
    isMoreAvailable,
  ]);
  const [selectedSearchEngineResultIds, setSelectedSearchEngineResultIds] =
    React.useState<string[]>([]);

  const getSelected = React.useCallback(
    (searchEngineResult: components["schemas"]["SearchEngineResult"]) => {
      if (!isSelectable) {
        return false;
      }
      return Array.isArray(selectedSearchEngineResultIds)
        ? selectedSearchEngineResultIds.indexOf(
            searchEngineResult.searchEngineResultId as string,
          ) >= 0
        : true;
    },
    [isSelectable, selectedSearchEngineResultIds],
  );
  const isInholland = auth?.jwt.customerRole === "inholland";
  const setSelected = React.useCallback(
    (
      searchEngineResult: components["schemas"]["SearchEngineResult"],
      isSelected: boolean,
    ) => {
      if (
        !isSelectable ||
        !Array.isArray(selectedSearchEngineResultIds) ||
        !searchEngineResult.searchEngineResultId
      ) {
        return;
      }
      setSelectedSearchEngineResultIds(
        isSelected
          ? [
              ...selectedSearchEngineResultIds,
              searchEngineResult.searchEngineResultId,
            ]
          : selectedSearchEngineResultIds.filter(
              (a) => a !== searchEngineResult.searchEngineResultId,
            ),
      );
    },
    [
      isSelectable,
      selectedSearchEngineResultIds,
      setSelectedSearchEngineResultIds,
    ],
  );
  const onItemClick = React.useCallback(
    (searchEngineResult: components["schemas"]["SearchEngineResult"]) => {
      if (!auth) {
        return;
      }

      if (!isSelectable) {
        if (searchEngineResult.articles[0].pdfUrl) {
          window.open(
            `${searchEngineResult.articles[0].pdfUrl}?bearer=${auth.bearer}`,
          );
        }
        return;
      }

      const mediaItemId =
        searchEngineResultDetailsMap[
          searchEngineResult.searchEngineResultId as string
        ]?.mediaItemId;
      if (mediaItemId) {
        setMediaItem(null);
        axios
          .get<components["schemas"]["MediaItem"]>(
            `/mediaItem/crud/${mediaItemId}`,
          )
          .then((res) => {
            setMediaItem(res.data);
          });
        return;
      }
      if (!isInholland) {
        setSelected(searchEngineResult, !getSelected(searchEngineResult));
      }
    },
    [
      isSelectable,
      auth,
      searchEngineResultDetailsMap,
      isInholland,
      setSelected,
      getSelected,
    ],
  );

  const colspan = React.useMemo(() => {
    return getColspan(windowOuterWidth);
  }, [windowOuterWidth]);

  const searchEngineResultWidth = React.useMemo(() => {
    return getSearchEngineResultWidth(windowOuterWidth);
  }, [windowOuterWidth]);

  const header = React.useMemo(() => {
    if (!searchEngineResults) {
      return null;
    }
    const isAllSelected =
      searchEngineResults.length === selectedSearchEngineResultIds.length;
    const selectedItemCount = selectedSearchEngineResultIds.length;
    return (
      <WidgetPanelHeader
        title={t(
          "xMessages",
          props.searchEngineResults
            ? formatInt(Math.max(props.searchEngineResults.count, 0))
            : 0,
        )}
        popoverForm={
          <>
            <h5>{t("widgetSettings_display")}</h5>
            <IconRadioGroup
              value={settings.display || "block"}
              onChange={(value) => {
                setSettings({
                  ...settings,
                  display:
                    value as ISearchEngineResultsPanelSettings["display"] as "block",
                });
              }}
              items={[
                {
                  value: "table",
                  svg: MediaItemTableDisplay,
                },
                {
                  value: "block",
                  svg: MediaItemBlockDisplay,
                },
              ]}
            />
          </>
        }
      >
        <div className="d-flex align-items-center my-2" ref={headerRowRef}>
          <div style={{ marginLeft: -10 }}>
            {!isSelectable || isInholland ? null : (
              <Checkbox
                onChange={() =>
                  setSelectedSearchEngineResultIds(
                    isAllSelected
                      ? []
                      : searchEngineResults.map(
                          (searchEngineResult) =>
                            searchEngineResult.searchEngineResultId as string,
                        ),
                  )
                }
                checked={isAllSelected}
              >
                <span style={{ fontSize: 14, color: "#1e324e" }}>
                  {t("allItems")}
                </span>
              </Checkbox>
            )}
          </div>
          {selectedItemCount && !isInholland ? (
            <Nav
              className="views__search-view__widgets__bulk-action-bar"
              style={{
                marginLeft: 30,
                whiteSpace: "normal",
              }}
            >
              <BuyPopover
                searchEngineResultIds={selectedSearchEngineResultIds}
              />
            </Nav>
          ) : onSortingTypeChange ? (
            <div className="d-flex flex-grow-1 justify-content-end">
              <SelectPicker
                className="views__search-view__widgets__mediaitem-widget__sort"
                cleanable={false}
                searchable={false}
                appearance="subtle"
                data={openapi.components.schemas.IdolSortType.enum
                  .filter((value) => value !== "notsorted")
                  .map((value) => ({
                    label: t(value),
                    value,
                  }))}
                placeholder={t("Sort")}
                placement="bottomEnd"
                value={
                  searchEngineRequest.idolSortType ||
                  openapi.components.schemas.IdolSortType.default
                }
                onChange={onSortingTypeChange}
              />
            </div>
          ) : null}
        </div>
      </WidgetPanelHeader>
    );
  }, [
    isInholland,
    isSelectable,
    onSortingTypeChange,
    props.searchEngineResults,
    searchEngineRequest.idolSortType,
    searchEngineResults,
    selectedSearchEngineResultIds,
    settings,
    t,
  ]);

  if (!searchEngineResults) {
    return <Loader size="lg" backdrop vertical />;
  }

  return (
    <Panel
      bodyFill={true}
      bordered={true}
      style={{
        minWidth: 300,
        backgroundColor: "white",
        maxHeight: windowInnerHeight - (isInholland ? 200 : 250),
      }}
      header={header}
      className="views__search-view__widgets__mediaitem-widget"
    >
      {settings.display === "table" ? (
        <div
          style={{
            paddingLeft: isSelectable ? 0 : 20,
          }}
        >
          <SchemaTable<components["schemas"]["SearchEngineResult"]>
            getSelected={isSelectable ? getSelected : undefined}
            setSelected={isSelectable ? setSelected : undefined}
            schema={
              openapi.components.schemas
                .SearchEngineResult as oas30.SchemaObject
            }
            data={searchEngineResults}
            height={windowInnerHeight - (isInholland ? 306 : 120)}
            rowHeight={34}
            sortable={false} // sorting is done via server side
            onRowClick={onItemClick}
            isCheckboxDisabled={(item) =>
              !!searchEngineResultDetailsMap[item.searchEngineResultId || 0]
                ?.mediaItemId
            }
            onScroll={(x, y, table, previousY) => {
              const scrollPos = windowInnerHeight - y;
              const lastItemPos = searchEngineResults.length * 34;
              const isBottom = scrollPos - 150 > lastItemPos;
              if (
                isMoreAvailable &&
                isBottom &&
                !isLoadingMore &&
                props.searchEngineResults &&
                props.searchEngineResults.count > searchEngineResults.length
              ) {
                setIsLoadingMore(true);
                onLoadMore().then(() => {
                  table.scrollTop(-previousY);
                  setIsLoadingMore(false);
                });
              }
            }}
            columnsConfigs={[
              { name: "searchEngineResultId", hidden: true },
              { name: "archiveType", hidden: true },
              { name: "articles", hidden: true },
              { name: "imageUrl", hidden: true },
              { name: "publicationType", hidden: true },
              { name: "publisher", hidden: true },
              { name: "relevance", hidden: true },
              { name: "summary", hidden: true },
              {
                name: "searchEngineArticle" as any,
                hidden: true,
              },
              { name: "headline", flexGrow: 2 },
              { name: "publicationName" },
              { name: "publicationDate" },
            ]}
          />
        </div>
      ) : (
        <FlexboxGrid>
          {searchEngineResults.map((searchEngineResult) => {
            const searchEngineResultId =
              searchEngineResult.searchEngineResultId as string;
            return (
              <InView key={searchEngineResult.searchEngineResultId}>
                {({ inView, ref }) => {
                  return (
                    <InViewSearchEngineResultItem
                      colspan={colspan}
                      getSelected={getSelected}
                      hideButton={hideButton}
                      isInholland={isInholland}
                      isSelectable={isSelectable}
                      onItemClick={onItemClick}
                      searchEngineResult={searchEngineResult}
                      searchEngineResultDetailsMap={
                        searchEngineResultDetailsMap
                      }
                      searchEngineResultId={searchEngineResultId}
                      searchEngineResultWidth={searchEngineResultWidth}
                      setSearchEngineResultDetailsMap={
                        setSearchEngineResultDetailsMap
                      }
                      setSelected={setSelected}
                      settings={settings}
                      inView={inView}
                      innerRef={ref}
                    />
                  );
                }}
              </InView>
            );
          })}
          {isLoadingMore ? (
            <div className="mx-auto mb-5">
              <Loader size="lg" />
            </div>
          ) : null}
        </FlexboxGrid>
      )}
      {mediaItem ? (
        <MediaItemModal
          close={() => setMediaItem(undefined)}
          mediaItem={mediaItem}
          onMediaItemDelete={() => setMediaItem(undefined)}
        />
      ) : null}
    </Panel>
  );
};

export default React.memo(SearchEngineResultsPanel);
