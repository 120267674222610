import React from "react";
import SchemaTable from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../types/openapi";
import { Icon, IconButton } from "rsuite";
import Download from "../../icons/Download";
import axios from "axios";
import useCustomers from "../../hooks/useCustomers";
import useInvoices from "../../hooks/useInvoices";
import { AuthContext } from "../../provider/AuthProvider";
import { DATE_FORMAT_REVERSE, localeFormat } from "../../inc/date";
import { currency } from "../../inc/numbers";

const schema = openapi.components.schemas.Invoice as oas30.SchemaObject;

type TInvoice = components["schemas"]["Invoice"];

const InvoicesView = () => {
  const { auth } = React.useContext(AuthContext);

  const customers = useCustomers();
  const invoices = useInvoices();

  const sortedInvoices = React.useMemo(() => {
    return Object.values(invoices || {}).sort((a, b) => {
      return new Date(a.invoiceDate) < new Date(b.invoiceDate) ? 1 : -1;
    });
  }, [invoices]);

  return (
    <div className="invoice-view">
      <SchemaTable<TInvoice>
        schema={schema}
        sortable={false}
        data={sortedInvoices}
        columnsConfigs={[
          { name: "invoiceDate" },
          {
            name: "invoiceNo",
            renderCell: (rowData) => `${rowData.invoiceNo}`,
          },
          { name: "price", renderCell: (rowData) => currency(rowData.price) },
          {
            name: "download" as any,
            width: 120,
            align: "center",
            renderCell: (invoice) => (
              <IconButton
                size="sm"
                appearance="subtle"
                className="rs-btn-subtle--in-table"
                icon={<Icon icon="save" componentClass={Download} />}
                onClick={() => {
                  if (!customers || !auth?.jwt.currentCustomerLinkId) {
                    return;
                  }
                  const { invoiceDate, invoiceNo } = invoice;
                  window.open(
                    `${axios.defaults.baseURL}invoice/pdf/migfactuur_${
                      customers[auth.jwt.currentCustomerLinkId].customerNo
                    }_${invoiceNo}_${localeFormat(
                      new Date(invoiceDate),
                      DATE_FORMAT_REVERSE
                    )}.pdf?date=${localeFormat(
                      new Date(invoiceDate),
                      DATE_FORMAT_REVERSE
                    )}&bearer=${auth.bearer}`
                  );
                }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default InvoicesView;
