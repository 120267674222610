import React, { SVGProps } from "react";

const History = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="currentColor"
    {...props}
  >
    <g id="Historie_icon">
      <path
        d="M1584.729 1010.084a.7.7 0 0 1-.007-1.406l2.434-.024-.023-2.434a.7.7 0 0 1 .7-.71h.007a.7.7 0 0 1 .7.7l.031 3.137a.7.7 0 0 1-.7.71l-3.137.031z"
        transform="translate(-1583.991 -993.936)"
      />
      <path
        d="M1594.75 995.837a.7.7 0 0 1-.014-1.406l2.765-.055-.06-5.075a.7.7 0 0 1 .694-.711h.008a.7.7 0 0 1 .7.694l.068 5.772a.7.7 0 0 1-.689.712l-3.462.068z"
        transform="translate(-1588.923 -985.611)"
      />
      <path
        d="M1592.958 1000.724a.7.7 0 1 1 0-1.406 7.593 7.593 0 1 0-5.624-2.49.7.7 0 0 1-1.04.946 9 9 0 1 1 6.664 2.95z"
        transform="translate(-1583.958 -982.725)"
      />
    </g>
  </svg>
);

export default History;
