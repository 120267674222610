import React from "react";
import SchemaTable, { IColumnConfig } from "../../components/SchemaTable";
import { components } from "../../types/openapi";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import SubscriberModal from "./SubscriberModal";
import SubscribersModal from "./SubscribersModal";
import ActionButton from "../../components/ActionButton";
import { I18nContext } from "../../provider/I18nProvider";
import { Checkbox, Loader } from "rsuite";
import useEditorials from "../../hooks/useEditorials";
import useEditorialSubscribers from "../../hooks/useEditorialSubscribers";
import useSubscribers from "../../hooks/useSubscribers";
import { generateAndDownload } from "../../inc/excel";

const SubscribersView = () => {
  const { t } = React.useContext(I18nContext);
  const editorialMap = useEditorials();
  const editorialSubscriberMap = useEditorialSubscribers();
  const subscriberMap = useSubscribers();

  const [selectedSubscriberId, setSelectedSubscriberId] =
    React.useState<string>();
  const [showSubscribersModal, setShowSubscribersModal] =
    React.useState<boolean>(false);
  const [isInactiveVisible, setIsInactiveVisible] =
    React.useState<boolean>(false);

  const editorialSubscribers = React.useMemo(
    () => Object.values(editorialSubscriberMap || {}),
    [editorialSubscriberMap],
  );

  const getActiveSubscriberEditorialIds = React.useCallback(
    (subscriberId: string) =>
      editorialSubscribers
        .filter(
          (editorialSubscriber) =>
            editorialSubscriber.subscriberId === subscriberId &&
            (editorialSubscriber.useApp || editorialSubscriber.useMail),
        )
        .map((editorialSubscriber) => editorialSubscriber.editorialId),
    [editorialSubscribers],
  );

  const data = React.useMemo(
    () =>
      Object.values(subscriberMap || {}).filter((subscriber) => {
        if (isInactiveVisible) {
          return true;
        }
        return getActiveSubscriberEditorialIds(
          subscriber.subscriberId as string,
        ).length;
      }),
    [getActiveSubscriberEditorialIds, isInactiveVisible, subscriberMap],
  );

  const onExport = React.useCallback(
    (subscribers: components["schemas"]["Subscriber"][]) => {
      if (!editorialMap) {
        return;
      }

      generateAndDownload(
        "Abonnees",
        [
          { header: "Naam", key: "displayName", width: 40 },
          { header: "E-mail", key: "email", width: 40 },
          { header: "Lid van:" },
          ...Object.values(editorialMap).map((editorial) => {
            return {
              header: editorial.name,
              key: `editorial${editorial.editorialId}`,
            };
          }),
        ],
        subscribers.map((subscriber) => {
          const editorialIds = getActiveSubscriberEditorialIds(
            subscriber.subscriberId as string,
          );
          return {
            ...subscriber,
            ...editorialIds.reduce((prev, editorialId) => {
              prev[`editorial${editorialId}`] = "X";
              return prev;
            }, {} as any),
          };
        }),
      );
    },
    [editorialMap, getActiveSubscriberEditorialIds],
  );

  const columnsConfigs = React.useMemo<
    IColumnConfig<components["schemas"]["Subscriber"]>[]
  >(
    () => [
      { name: "subscriberId", hidden: true },
      { name: "bounced", hidden: true },
      {
        name: "email",

        sort: (a, b, sortAsc) =>
          a.email.toLowerCase().localeCompare(b.email.toLowerCase()) *
          (sortAsc ? 1 : -1),
        renderCell: (rowData) => rowData.email.toLowerCase(),
      },
      {
        name: "displayName",
        sort: (a, b, sortAsc) => {
          if (!a.displayName) {
            return sortAsc ? 1 : -1;
          }
          if (!b.displayName) {
            return sortAsc ? -1 : 1;
          }
          return (
            a.displayName
              .toLowerCase()
              .localeCompare(b.displayName.toLowerCase()) * (sortAsc ? 1 : -1)
          );
        },
        renderCell: (rowData) => rowData.displayName || "",
      },
      {
        name: "memberOf" as any,
        sortable: false,
        renderCell: (rowData) => {
          const editorialNames = getActiveSubscriberEditorialIds(
            rowData.subscriberId as string,
          ).map((editorialId) =>
            editorialMap && editorialMap[editorialId]
              ? editorialMap[editorialId].name
              : editorialId,
          );
          return (
            <span title={editorialNames.join(", \n")}>
              {editorialNames.join(", ")}
            </span>
          );
        },
      },
    ],
    [editorialMap, getActiveSubscriberEditorialIds],
  );

  const onRowClick = React.useCallback(
    (rowData: components["schemas"]["Subscriber"]) =>
      setSelectedSubscriberId(rowData.subscriberId),
    [],
  );

  const onInactiveVisibleChange = React.useCallback(() => {
    setIsInactiveVisible((x) => !x);
  }, []);

  if (!subscriberMap) {
    return <Loader />;
  }

  return (
    <div className="h-100">
      <SchemaTable<components["schemas"]["Subscriber"]>
        schema={openapi.components.schemas.Subscriber as oas30.SchemaObject}
        sortable
        globalSearch
        data={data}
        onExport={onExport}
        columnsConfigs={columnsConfigs}
        onRowClick={onRowClick}
      >
        <Checkbox
          checked={isInactiveVisible}
          onChange={onInactiveVisibleChange}
        >
          {t("showInactiveSubscribers")}
        </Checkbox>
        <em className="mx-3">
          &mdash; {t(`xSubscriber${data.length === 1 ? "" : "s"}`, data.length)}{" "}
          &mdash;
        </em>
        <>
          <ActionButton
            onClick={() => setShowSubscribersModal(true)}
            title={t("addAction")}
          />
          {showSubscribersModal ? (
            <SubscribersModal
              show={showSubscribersModal}
              onClose={() => setShowSubscribersModal(false)}
            />
          ) : null}
          {selectedSubscriberId ? (
            <SubscriberModal
              onClose={() => setSelectedSubscriberId(undefined)}
              show={!!selectedSubscriberId}
              subscriberId={selectedSubscriberId}
            />
          ) : null}
        </>
      </SchemaTable>
    </div>
  );
};
export default SubscribersView;
