import React, { SVGProps } from "react";

const SentimentPositive = (
  props: SVGProps<SVGSVGElement> & { title?: string }
) => {
  const { title, ...svgProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.768}
      height={15.767}
      viewBox="0 0 15.768 15.767"
      fill="currentColor"
      {...svgProps}
    >
      {title ? <title>{title}</title> : null}
      <path
        id="prefix__Path_574"
        d="M1584.945 422.337a7.883 7.883 0 117.884-7.884 7.892 7.892 0 01-7.884 7.884zm0-14.612a6.728 6.728 0 106.729 6.728 6.736 6.736 0 00-6.729-6.728z"
        transform="translate(-1577.062 -406.57)"
      />
      <path
        id="prefix__Path_575"
        d="M1591.956 435.208a3.165 3.165 0 01-2.73-1.575.578.578 0 011-.579 2 2 0 003.387.118.578.578 0 11.957.647 3.152 3.152 0 01-2.614 1.389z"
        transform="translate(-1584.103 -421.829)"
      />
      <path
        id="prefix__Path_576"
        d="M1586.021 418.946a.567.567 0 01-.173-.027.578.578 0 01-.378-.725 2.188 2.188 0 014.1-.2.578.578 0 11-1.064.45 1.033 1.033 0 00-1.936.092.577.577 0 01-.549.41z"
        transform="translate(-1581.945 -412.45)"
      />
      <path
        id="prefix__Path_577"
        d="M1597.588 418.946a.578.578 0 01-.552-.751 2.188 2.188 0 014.1-.2.578.578 0 11-1.064.45 1.033 1.033 0 00-1.936.091.578.578 0 01-.548.41z"
        transform="translate(-1588.683 -412.45)"
      />
    </svg>
  );
};
export default SentimentPositive;
