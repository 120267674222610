import React, { SVGProps } from "react";

const DragHandle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    {...props}
  >
    <g id="prefix__Drag_Handle_widgets" data-name="Drag Handle widgets">
      <path
        id="prefix__Path_2000"
        data-name="Path 2000"
        fill="#96afaf"
        d="M1923.87 360.934h-9.333a.889.889 0 110-1.778h9.333a.889.889 0 110 1.778z"
        transform="translate(-1896.171 -345.282)"
      />
      <path
        id="prefix__Path_2001"
        data-name="Path 2001"
        fill="#96afaf"
        d="M1924.261 362.916a.889.889 0 01-.63-1.517l3.856-3.847-3.856-3.847a.89.89 0 011.26-1.257l4.485 4.476a.887.887 0 010 1.257l-4.485 4.475a.887.887 0 01-.63.26z"
        transform="translate(-1899.637 -342.79)"
      />
      <path
        id="prefix__Path_2002"
        data-name="Path 2002"
        fill="#96afaf"
        d="M1898.9 360.934h-9.333a.889.889 0 110-1.778h9.333a.889.889 0 110 1.778z"
        transform="translate(-1887.27 -345.282)"
      />
      <path
        id="prefix__Path_2003"
        data-name="Path 2003"
        fill="#96afaf"
        d="M1891.864 362.916a.887.887 0 01-.63-.261l-4.485-4.475a.887.887 0 010-1.257l4.485-4.476a.889.889 0 011.259 1.257l-3.855 3.847 3.855 3.847a.889.889 0 01-.629 1.517z"
        transform="translate(-1886.489 -342.79)"
      />
      <path
        id="prefix__Path_2004"
        data-name="Path 2004"
        fill="#96afaf"
        d="M1909.306 375.48a.89.89 0 01-.891-.889v-9.314a.891.891 0 011.781 0v9.314a.89.89 0 01-.89.889z"
        transform="translate(-1894.306 -346.886)"
      />
      <path
        id="prefix__Path_2005"
        data-name="Path 2005"
        fill="#96afaf"
        d="M1906.821 380.366a.892.892 0 01-.63-.26l-4.484-4.476a.89.89 0 011.259-1.257l3.855 3.847 3.854-3.847a.89.89 0 011.26 1.257l-4.485 4.476a.889.889 0 01-.629.26z"
        transform="translate(-1891.821 -350.366)"
      />
      <path
        id="prefix__Path_2006"
        data-name="Path 2006"
        fill="#96afaf"
        d="M1909.306 351.236a.89.89 0 01-.891-.889v-9.314a.891.891 0 011.781 0v9.314a.89.89 0 01-.89.889z"
        transform="translate(-1894.306 -338.738)"
      />
      <path
        id="prefix__Path_2007"
        data-name="Path 2007"
        fill="#96afaf"
        d="M1902.336 344.207a.888.888 0 01-.629-1.517l4.484-4.476a.9.9 0 01.63-.26.891.891 0 01.629.26l4.485 4.476a.89.89 0 01-1.26 1.257l-3.854-3.847-3.855 3.847a.887.887 0 01-.63.26z"
        transform="translate(-1891.821 -337.954)"
      />
    </g>
  </svg>
);
export default DragHandle;
