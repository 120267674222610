import { Dropdown, Icon, IconButton } from "rsuite";
import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import { components } from "../../../types/openapi";
import { sentimentOptions } from "../../../inc/sentimentOptions";
import SentimentPositive from "../../../icons/SentimentPositive";
import CheckboxPopover from "../../CheckboxPopover";

interface ISentimentControlProps {
  controlType: "menuItem" | "button";
  disabled: boolean;
  filter: components["schemas"]["Filter"];
  expandAndChange: (filter: components["schemas"]["Filter"]) => void;
}

const SentimentControl = ({
  controlType,
  disabled,
  expandAndChange,
  filter,
}: ISentimentControlProps) => {
  const { t } = React.useContext(I18nContext);

  return (
    <CheckboxPopover
      values={filter.sentiments?.map((sentiment) => `${sentiment}`) || []}
      onChange={(sentiments) =>
        expandAndChange({
          ...filter,
          sentiments:
            sentiments?.map((sentiment) => parseInt(sentiment, 10)) || [],
        })
      }
      data={Object.values(sentimentOptions)
        .sort((a, b) => b.value - a.value)
        .map((option) => ({
          value: `${option.value}`,
          label: t(option.label),
        }))}
    >
      {controlType === "button" ? (
        <IconButton
          ripple={false}
          disabled={disabled}
          className={`filter-bar__filters-col__button`}
          size="xs"
          appearance="subtle"
          icon={<Icon icon="question" componentClass={SentimentPositive} />}
        >
          {t("sentiment")}
        </IconButton>
      ) : (
        <Dropdown.Item>{t("sentiment")}</Dropdown.Item>
      )}
    </CheckboxPopover>
  );
};

export default SentimentControl;
