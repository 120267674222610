/**
 * Replace newline with paragraph tags
 *
 * @param text string
 *
 * @return HTML
 */
export const nl2p = (text: string) => {
  let string = text;
  if (string.includes("\n")) {
    string = `<p>${string.replace("\n", "</p><p>")}</p>`;
  }
  return string;
};

/**
 * Replace newline with break tags
 *
 * @param text string
 *
 * @return HTML
 */
export const nl2br = (text: string) => {
  let string = text;
  if (string.includes("\n")) {
    string = string.replace("\n", "<br/>");
  }
  return string;
};

// https://util.unicode.org/UnicodeJsps/confusables.jsp?a=%22
export const normalizeDoubleQuotes = (newQuery: string) =>
  newQuery.replaceAll(/('')|＂|〃|ˮ|ײ|᳓|″|״|‶|ʺ|“|”|˝|‟|„/g, '"');
