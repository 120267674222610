import React from "react";
import { components } from "../../../../../../types/openapi";
import { I18nContext } from "../../../../../../provider/I18nProvider";
import { currency, formatInt } from "../../../../../../inc/numbers";
import useDimensions from "../../../../../../hooks/useDimensions";

import "./index.scss";

interface IItemBodyProps {
  benchmarkResult: components["schemas"]["BenchmarkResult"];
}

const CONTENT_HEIGHT = 104;
const TITLE_BOTTOM_MARGIN = 8;

const ItemBody = ({ benchmarkResult }: IItemBodyProps) => {
  const [headingRef] = useDimensions();
  const { t } = React.useContext(I18nContext);

  const { reach, ave } = benchmarkResult;

  return (
    <>
      <div style={{ minHeight: CONTENT_HEIGHT }}>
        <h4
          style={{ marginBottom: TITLE_BOTTOM_MARGIN - 2, paddingBottom: 2 }}
          ref={headingRef}
        >
          {benchmarkResult.title}
        </h4>
      </div>
      <div className="benchmark-result-grid-item__meta-data benchmark-result-grid-item__meta-data--bottom">
        {ave ? <div>AVE {currency(ave)}</div> : null}
        <div>{`${t("reach")} ${reach ? formatInt(reach) : "-"}`}</div>
      </div>
    </>
  );
};

export default ItemBody;
