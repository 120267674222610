import { components } from "../../../../types/openapi";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";

import { compact, currency, formatInt } from "../../../../inc/numbers";
import SentimentPositive from "../../../../icons/SentimentPositive";
import { ESentiment, sentimentOptions } from "../../../../inc/sentimentOptions";
import { Icon, IconButton, Popover } from "rsuite";
import CompassIcon from "../../../../icons/Compass";
import LabelIcon from "../../../../icons/Label";
import {
  DATE_COMPACT_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  localeFormat,
  parseDateWithOptionalTime,
} from "../../../../inc/date";
import FavouriteFilled from "../../../../icons/FavouriteFilled";
import Favourite from "../../../../icons/Favourite";
import SchemaTable from "../../../../components/SchemaTable";
import React from "react";
import CartCheck from "../../../../icons/CartCheck";
import { getMediaItemIcon } from "../../../../icons";
import MediaType from "../../../../icons/MediaType";
import { elementOrParentHasClassName } from "../../../../inc/dom";
import { I18nContext } from "../../../../provider/I18nProvider";
import useLabels from "../../../../hooks/useLabels";
import useSearchTopics from "../../../../hooks/useSearchTopics";
import LabelCell from "./LabelCell";
import MwWhisper from "../../../../components/MwWhisper";
import { InfiniteLoader } from "react-virtualized";
import {
  IMediaItemDashboardWidgetSettings,
  TMediaItemDashboardWidgetColumnLayoutSettings,
} from "../index";

interface IMediaItemTableProps {
  getSelected?: (mediaItem: components["schemas"]["MediaItem"]) => boolean;
  height: number;
  isMediaItemLoaded: (obj: { index: number }) => boolean;
  loadMoreMediaItems: (obj: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<void>;
  mediaItems: Array<components["schemas"]["MediaItem"] | null | undefined>;
  onItemClick?: (mediaItem: components["schemas"]["MediaItem"]) => void;
  onMediaItemChange?: (
    mediaItemId: string,
    data: components["schemas"]["MediaItemPartial"],
  ) => void;
  onSettingsChange?: (
    widgetUid: string,
    newSettings: IMediaItemDashboardWidgetSettings,
  ) => void;
  setSelected?: (
    mediaItem: components["schemas"]["MediaItem"],
    isSelected: boolean,
  ) => void;
  settings: IMediaItemDashboardWidgetSettings;
  widgetUid: string;
  columnLayouts: TMediaItemDashboardWidgetColumnLayoutSettings[];
}

const MediaItemTable = ({
  getSelected,
  height,
  isMediaItemLoaded,
  loadMoreMediaItems,
  mediaItems,
  onItemClick,
  onMediaItemChange,
  onSettingsChange,
  setSelected,
  settings,
  widgetUid,
  columnLayouts,
}: IMediaItemTableProps) => {
  // useWhyDidYouUpdate(props);
  const { t } = React.useContext(I18nContext);
  const labels = useLabels();
  const searchTopics = useSearchTopics();
  const [tableHeight, setTableHeight] = React.useState<number>(height - 120);

  const columnConfigMap = React.useMemo(
    () => ({
      title: {
        width: 250,
      },
      mediaType: {
        flexGrow: 0,
        align: "center",
        sortByValue: true,
        title: (
          <MediaType
            title={t("mediaType")}
            className="views__dashboard-view__widgets__mediaitem-widget__header--icon"
          />
        ),
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return (
            <span
              title={t(`mediaType_${rowData.mediaType}`)}
              className="views__dashboard-view__widgets__mediaitem-widget__cell--media-type"
            >
              {getMediaItemIcon(rowData)}
            </span>
          );
        },
      },
      source: {
        width: 150,
      },
      ave: {
        flexGrow: 0,
        sortByValue: true,
        title: "€",
        hoverTitle: t("ave"),
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return !rowData.ave ? (
            ""
          ) : (
            <span title={currency(rowData.ave)}>{compact(rowData.ave)}</span>
          );
        },
      },
      reach: {
        width: 100,
        sortByValue: true,
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return !rowData.reach ? (
            ""
          ) : (
            <span title={formatInt(rowData.reach)}>
              {compact(rowData.reach)}
            </span>
          );
        },
      },
      sentiment: {
        title: (
          <SentimentPositive
            title={t("sentiment")}
            className="views__dashboard-view__widgets__mediaitem-widget__header--icon"
          />
        ),
        align: "center",
        flexGrow: 0,
        renderCell: (mediaItem: components["schemas"]["MediaItem"]) => {
          const sentiment =
            mediaItem.customerSentiment === undefined
              ? mediaItem.sentiment
              : mediaItem.customerSentiment;

          if (!sentiment) {
            return "";
          }
          if (!(sentiment in sentimentOptions)) {
            return "";
          }
          const sentimentOption = sentimentOptions[sentiment as ESentiment];
          return (
            <span
              title={t(sentimentOption.label)}
              className="views__dashboard-view__widgets__mediaitem-widget__cell--sentiment"
            >
              <Icon
                icon="star"
                componentClass={sentimentOption.icon}
                style={{ color: sentimentOption.color }}
              />
            </span>
          );
        },
        sortByValue: true,
      },
      searchTopicIds: {
        title: (
          <CompassIcon
            title={t("searchTopics")}
            className="views__dashboard-view__widgets__mediaitem-widget__header--icon"
          />
        ),
        flexGrow: 0,
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          if (!rowData.searchTopicIds?.length || !searchTopics) {
            return null;
          }
          const searchTopicNames = rowData.searchTopicIds.map(
            (searchTopicId) =>
              searchTopics[searchTopicId]
                ? searchTopics[searchTopicId].name
                : searchTopicId,
          );
          return searchTopicNames.length ? (
            <MwWhisper
              trigger="hover"
              placement="bottomStart"
              speaker={
                <Popover>
                  {searchTopicNames.map((searchTopicName) => (
                    <div key={searchTopicName}>{searchTopicName}</div>
                  ))}
                </Popover>
              }
            >
              <div className="views__dashboard-view__widgets__mediaitem-widget__cell--icons views__dashboard-view__widgets__mediaitem-widget__cell--icons--outline">
                <CompassIcon />
                <span className="ms-2">{searchTopicNames.length}</span>
              </div>
            </MwWhisper>
          ) : null;
        },
      },
      labelIds: {
        title: (
          <LabelIcon
            title={t("labels")}
            className="views__dashboard-view__widgets__mediaitem-widget__header--icon"
          />
        ),
        flexGrow: 0,
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          if (!labels) {
            return null;
          }
          const labelNames = rowData.labelIds
            ? rowData.labelIds
                .filter((labelId) => !!labels[labelId])
                .map((labelId) => labels[labelId].name)
            : [];
          return (
            <MwWhisper
              trigger={labelNames.length ? "hover" : "none"}
              placement="bottomStart"
              speaker={
                <Popover>
                  {labelNames.map((foundLabel) => (
                    <div key={foundLabel}>{foundLabel}</div>
                  ))}
                </Popover>
              }
            >
              <div
                className="views__dashboard-view__widgets__mediaitem-widget__cell--icons"
                style={{ marginLeft: -3 }}
              >
                <LabelCell
                  value={rowData.labelIds || []}
                  onChange={
                    onMediaItemChange
                      ? (value) => {
                          onMediaItemChange(rowData.mediaItemId as string, {
                            labelIds: value,
                          });
                        }
                      : undefined
                  }
                />
                {labelNames.length ? (
                  <span className="ms-2 mt-n1">{labelNames.length}</span>
                ) : null}
              </div>
            </MwWhisper>
          );
        },
      },
      insertDate: {
        title: "added",
        width: 125,
        hoverTitle: "insertDateExplanation",
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return !rowData.insertDate ? (
            ""
          ) : (
            <span
              title={localeFormat(
                new Date(rowData.insertDate),
                DATE_TIME_FORMAT,
              )}
            >
              {localeFormat(new Date(rowData.insertDate), DATE_COMPACT_FORMAT)}
            </span>
          );
        },
      },
      publicationDate: {
        title: "date",
        width: 125,
        hoverTitle: "publicationDateExplanation",
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return !rowData.publicationDate ? (
            ""
          ) : (
            <span
              title={localeFormat(
                parseDateWithOptionalTime(rowData.publicationDate),
                DATE_TIME_FORMAT,
              )}
            >
              {localeFormat(
                parseDateWithOptionalTime(rowData.publicationDate),
                ["radio", "tv"].includes(rowData.mediaType)
                  ? DATE_TIME_FORMAT
                  : DATE_FORMAT,
              )}
            </span>
          );
        },
      },
      isFavourite: {
        title: "favorite",
        align: "center",
        width: 120,
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return (
            <IconButton
              appearance="subtle"
              className="rs-btn-subtle--in-table"
              size="xs"
              icon={
                <Icon
                  className="w-100"
                  icon="star"
                  componentClass={
                    rowData.isFavourite ? FavouriteFilled : Favourite
                  }
                />
              }
              onClick={() => {
                if (!onMediaItemChange) {
                  return;
                }
                onMediaItemChange(rowData.mediaItemId as string, {
                  isFavourite: !rowData.isFavourite,
                });
              }}
            />
          );
        },
      },
      isOwned: {
        title: t("isOwned"),
        hidden: true,
        align: "center",
        width: 120,
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return rowData.isOwned ? (
            <MwWhisper
              trigger="hover"
              placement="bottomStart"
              speaker={<Popover>{t("isOwned_full")}</Popover>}
            >
              <Icon
                className="w-100"
                icon="star"
                componentClass={CartCheck}
                onClick={onItemClick ? () => onItemClick(rowData) : undefined}
              />
            </MwWhisper>
          ) : null;
        },
      },
      prValue: {
        width: 120,
        sortByValue: true,
        renderCell: (mediaItem: components["schemas"]["MediaItem"]) => {
          const prValue =
            mediaItem.customerPrValue === undefined
              ? mediaItem.prValue
              : mediaItem.customerPrValue;
          return prValue ? (
            <span title={currency(prValue)}>{compact(prValue)}</span>
          ) : (
            ""
          );
        },
      },
      circulation: {
        width: 120,
        sortByValue: true,
        renderCell: (rowData: components["schemas"]["MediaItem"]) => {
          return !rowData.circulation ? (
            ""
          ) : (
            <span title={formatInt(rowData.circulation)}>
              {compact(rowData.circulation)}
            </span>
          );
        },
      },
    }),
    [labels, onItemClick, onMediaItemChange, searchTopics, t],
  );

  React.useEffect(() => {
    const panelBody = document.querySelector(
      `.views__dashboard-view__widgets__mediaitem-widget--${widgetUid} .rs-panel-body`,
    );

    // @ts-ignore
    const observer = new ResizeObserver((entries) => {
      setTableHeight(entries[0].contentRect.height);
    });

    if (panelBody) {
      observer.observe(panelBody);
      setTableHeight(panelBody.clientHeight);

      return () => {
        observer.disconnect();
      };
    }
  }, [widgetUid, settings.display]);

  const columnConfigs = React.useMemo(
    () =>
      columnLayouts
        .filter((columnLayout) => columnLayout.show)
        .map((columnLayout) => {
          const result = {
            ...columnConfigMap[columnLayout.propName as "title"],
            name: columnLayout.propName as string,
          };
          if (columnLayout.width) {
            result.width = columnLayout.width;
          }
          return result;
        }),
    [columnLayouts, columnConfigMap],
  );

  const columnResize = React.useCallback(
    (width: number, propName: string) => {
      if (!onSettingsChange) {
        return;
      }
      onSettingsChange(widgetUid, {
        ...settings,
        columnLayouts: columnLayouts.map((column) =>
          column.propName === propName ? { ...column, width } : column,
        ),
      });
    },
    [columnLayouts, onSettingsChange, settings, widgetUid],
  );

  const onRowClick = React.useCallback(
    (mediaItem: any, e: any) => {
      if (
        e.target &&
        onItemClick &&
        !elementOrParentHasClassName(e.target as HTMLElement, "rs-popover")
      ) {
        onItemClick(mediaItem);
      }
    },
    [onItemClick],
  );

  const rowClassName = React.useCallback(
    (rowData: any) => `${rowData.isRead ? "" : "font-weight-bold"}`,
    [],
  );

  const tableRenderer = React.useCallback(
    ({ onRowsRendered }: any) => {
      return (
        <SchemaTable<components["schemas"]["MediaItem"]>
          getSelected={getSelected}
          setSelected={setSelected}
          schema={openapi.components.schemas.MediaItem as oas30.SchemaObject}
          data={mediaItems as any}
          height={tableHeight}
          rowHeight={34}
          sortable={false}
          onResize={columnResize}
          onRowClick={onItemClick ? onRowClick : undefined}
          onRowsRendered={onRowsRendered}
          rowClassName={rowClassName}
          resizable
          columnsConfigs={columnConfigs}
          isUnconfiguredHidden
          // ref={registerChild}
        />
      );
    },
    [
      columnConfigs,
      columnResize,
      getSelected,
      mediaItems,
      onItemClick,
      onRowClick,
      rowClassName,
      setSelected,
      tableHeight,
    ],
  );

  return (
    <InfiniteLoader
      isRowLoaded={isMediaItemLoaded}
      loadMoreRows={loadMoreMediaItems}
      rowCount={mediaItems.length}
    >
      {tableRenderer}
    </InfiniteLoader>
  );
};

export default MediaItemTable;
