import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Button,
  Checkbox,
  Form,
  FormGroup,
  HelpBlock,
  Modal,
  SelectPicker,
} from "rsuite";
import "./index.scss";
import openapi from "../../../../openapi.json";
import { startJob } from "../../../../inc/job";
import { ApiDataContext } from "../../../../provider/ApiDataProvider";
import { IFilterXlsxJobConfig } from "../../../../types";
import { components } from "../../../../types/openapi";
import Drag from "../../../../icons/Drag";
import { I18nContext } from "../../../../provider/I18nProvider";
import { AuthContext } from "../../../../provider/AuthProvider";
import { ELocaleOptions } from "../../../../inc/i18n";
import useMes from "../../../../hooks/useMes";

interface IExcelDownloadSettingsModalProps {
  getSelectionCacheToken: () => Promise<string>;
  open: boolean;
  onClose: () => void;
  sort: components["schemas"]["MediaItemSortType"];
}

const initialConfig = {
  options: openapi.components.schemas.Column.enum.map((name) => ({
    name: name as IFilterXlsxJobConfig["options"][0]["name"],
    isSelected: true,
  })),
  showAveAs: "cumulative" as components["schemas"]["Job"]["showAveAs"],
};

const ExcelDownloadSettingsModal = ({
  getSelectionCacheToken,
  open,
  onClose,
  sort,
}: IExcelDownloadSettingsModalProps) => {
  const { activeLanguage, t } = React.useContext(I18nContext);
  const { updateUser } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const users = useMes();

  const currentUser = React.useMemo(
    () =>
      users && auth
        ? users.find(
            (user) =>
              user.userId === auth.jwt.userId &&
              user.groupId === auth.jwt.currentGroupId,
          )
        : null,
    [auth, users],
  );
  const [options, setOptions] = React.useState<IFilterXlsxJobConfig["options"]>(
    initialConfig.options,
  );
  const [showAveAs, setShowAveAs] = React.useState<
    components["schemas"]["Job"]["showAveAs"]
  >(initialConfig.showAveAs);

  React.useEffect(() => {
    if (currentUser?.settings?.filterXlsxJobConfig) {
      // make sure to refresh whenever preferences don't match openapi definition
      if (
        currentUser.settings.filterXlsxJobConfig.options.length ===
        initialConfig.options.length
      ) {
        setOptions(currentUser.settings.filterXlsxJobConfig.options);
      }
      setShowAveAs(currentUser.settings.filterXlsxJobConfig.showAveAs);
    }
  }, [currentUser]);

  const reorder = React.useCallback(
    (startIndex: number, endIndex: number ) => {
      const [removed] = options.splice(startIndex, 1);
      options.splice(endIndex, 0, removed);
      return options;
    },
    [options],
  );

  const onDragEnd = React.useCallback(
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      setOptions(reorder(result.source.index, result.destination.index));
    },
    [reorder],
  );

  const onCheckboxChange = React.useCallback(
    (column: string, isSelected: boolean) => {
      setOptions((options) =>
        options.map((option) =>
          option.name === column ? { ...option, isSelected } : option,
        ),
      );
    },
    [],
  );

  const onSubmit = React.useCallback(async () => {
    if (!currentUser) {
      throw new Error("Missing currentUser");
    }
    onClose();
    await updateUser({
      ...currentUser,
      settings: {
        ...currentUser.settings,
        filterXlsxJobConfig: { options, showAveAs },
      },
    });
    await startJob({
      filterCacheToken: await getSelectionCacheToken(),
      jobType: "filterXlsxJob",
      columns: options
        .filter((option) => option.isSelected)
        .map((option) => option.name),
      showAveAs,
      sort,
      locale: activeLanguage === ELocaleOptions.NL ? "nl_NL" : "en_GB",
    });
  }, [
    activeLanguage,
    currentUser,
    getSelectionCacheToken,
    onClose,
    options,
    showAveAs,
    sort,
    updateUser,
  ]);
  const isOneSelected = !!options.find((option) => option.isSelected);
  const isAllSelected = !options.find((option) => !option.isSelected);
  const onSelectAllChange = React.useCallback(() => {
    setOptions((options) =>
      options.map((option) => ({ ...option, isSelected: !isAllSelected })),
    );
  }, [isAllSelected]);

  return (
    <Modal size="md" show={open} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{t("generateExcelExport")}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="excel-download-settings"
        style={
          // overrule inline styling
          { maxHeight: undefined }
        }
      >
        <Form>
          <FormGroup>
            <HelpBlock>{t("xlsColumnHelp")}</HelpBlock>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="columns">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`excel-download-settings__container${
                      snapshot.isDraggingOver
                        ? " excel-download-settings__container--dragging-over"
                        : ""
                    }`}
                  >
                    {options.map((column, index) => (
                      <Draggable
                        key={column.name}
                        draggableId={column.name}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              left: "auto",
                              top: "auto",
                              display: "flex",
                              alignItems: "center",
                            }}
                            className={`excel-download-settings__item${
                              snapshot.isDragging
                                ? " excel-download-settings__item--dragging"
                                : ""
                            }`}
                          >
                            <div className="excel-download-settings__item__drag-handle">
                              <Drag />
                            </div>
                            <Checkbox
                              checked={column.isSelected}
                              value={column.name}
                              onChange={onCheckboxChange}
                            >
                              {t(column.name)}
                            </Checkbox>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex pt-3">
          <div style={{ flex: 1, textAlign: "left" }}>
            <Checkbox
              name="selectAll"
              className="d-inline-block"
              checked={isAllSelected}
              onChange={onSelectAllChange}
            >
              {t("selectAll")}
            </Checkbox>
            <SelectPicker
              placeholder={t("showAveAs")}
              value={showAveAs}
              data={openapi.components.schemas.Job.properties.showAveAs.enum.map(
                (option) => ({
                  value: option,
                  label: t(`showAveAs_${option}`),
                }),
              )}
              onChange={setShowAveAs}
              searchable={false}
              cleanable={false}
              preventOverflow
              size="sm"
              className="ms-3"
            />
          </div>
          <Button
            onClick={onSubmit}
            appearance="primary"
            disabled={!isOneSelected}
          >
            {t("generateXls")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default ExcelDownloadSettingsModal;
