import React, { SVGProps } from "react";

const PasswordShow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Wachtwoord zien"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={15.532}
    viewBox="0 0 24 15.532"
    {...props}
  >
    <path
      id="prefix__Path_2199"
      data-name="Path 2199"
      fill="#96afaf"
      d="M1647.548 862.959c-6.522 0-11.642-7.021-11.856-7.32a.764.764 0 010-.892c.214-.3 5.334-7.32 11.856-7.32s11.642 7.022 11.856 7.32a.764.764 0 010 .892c-.214.299-5.334 7.32-11.856 7.32zm-10.27-7.766c1.206 1.5 5.415 6.239 10.27 6.239s9.064-4.743 10.27-6.239c-1.206-1.5-5.415-6.239-10.27-6.239s-9.064 4.746-10.27 6.239z"
      transform="translate(-1635.548 -847.427)"
    />
    <circle
      id="prefix__Ellipse_393"
      data-name="Ellipse 393"
      fill="#96afaf"
      cx={3.7}
      cy={3.7}
      r={3.7}
      transform="translate(8.3 4.066)"
    />
  </svg>
);

export default PasswordShow;
