import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { AuthContext } from "../provider/AuthProvider";

const useCustomers = singletonHook(undefined, () => {
  const { customers, setCustomers } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (customers !== undefined || !auth?.bearer) {
      return;
    }
    setCustomers(null);
    axios
      .get<components["schemas"]["Customer"][]>("/customer/crud")
      .then((res) => {
        setCustomers(
          res.data
            .sort(
              (
                a: components["schemas"]["Customer"],
                b: components["schemas"]["Customer"]
              ) => (a.customerName < b.customerName ? -1 : 1)
            )
            .reduce<IHashMap<components["schemas"]["Customer"]>>(
              (prev, customer) => {
                prev[customer.customerLinkId as string] = customer;
                return prev;
              },
              {}
            )
        );
      });
  }, [auth?.bearer, setCustomers, customers]);

  return customers;
});

export default useCustomers;
