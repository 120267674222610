import React, { SVGProps } from "react";

const Drag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={16.667}
    viewBox="0 0 9 16.667"
    fill="currentColor"
    {...props}
  >
    <circle
      id="prefix__Ellipse_330"
      data-name="Ellipse 330"
      cx={1.5}
      cy={1.5}
      r={1.5}
    />
    <circle
      id="prefix__Ellipse_333"
      data-name="Ellipse 333"
      cx={1.5}
      cy={1.5}
      r={1.5}
      transform="translate(6)"
    />
    <circle
      id="prefix__Ellipse_331"
      data-name="Ellipse 331"
      cx={1.5}
      cy={1.5}
      r={1.5}
      transform="translate(0 6.833)"
    />
    <circle
      id="prefix__Ellipse_334"
      data-name="Ellipse 334"
      cx={1.5}
      cy={1.5}
      r={1.5}
      transform="translate(6 6.833)"
    />
    <circle
      id="prefix__Ellipse_332"
      data-name="Ellipse 332"
      cx={1.5}
      cy={1.5}
      r={1.5}
      transform="translate(0 13.667)"
    />
    <circle
      id="prefix__Ellipse_335"
      data-name="Ellipse 335"
      cx={1.5}
      cy={1.5}
      r={1.5}
      transform="translate(6 13.667)"
    />
  </svg>
);
export default Drag;
