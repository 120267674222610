import React from "react";
import { components } from "../../../../types/openapi";
import { Loader, Message } from "rsuite";
import {
  FacebookEmbed,
  InstagramEmbed,
  PinterestEmbed,
  TikTokEmbed,
  TwitterEmbed,
  YouTubeEmbed,
} from "react-social-media-embed";
import "./index.scss";
import { I18nContext } from "../../../../provider/I18nProvider";

interface IMessageTabProps {
  mediaItem?: components["schemas"]["MediaItem"];
  mediaItemView?: components["schemas"]["MediaItemView"];
}

const MessageTab = ({ mediaItem, mediaItemView }: IMessageTabProps) => {
  const [isMessageMissing, setIsMessageMissing] = React.useState(false);
  const { t } = React.useContext(I18nContext);

  const body = React.useMemo(() => {
    if (!mediaItem?.source || !mediaItemView?.sourceUrl) {
      return null;
    }
    switch (mediaItem.source.toLowerCase()) {
      case "facebook":
        let { sourceUrl } = mediaItemView;
        // e.g. https://www.facebook.com/608814422530649_859385908659284
        const invalidUrlMatch = sourceUrl.match(/facebook\.com\/(\d+)_(\d+)$/);
        if (invalidUrlMatch) {
          sourceUrl = `https://www.facebook.com/${invalidUrlMatch[1]}/posts/${invalidUrlMatch[2]}`;
        }
        return <FacebookEmbed url={sourceUrl} width="100%" />;

      case "twitter":
        return (
          <TwitterEmbed
            url={mediaItemView.sourceUrl}
            width="100%"
            twitterTweetEmbedProps={
              {
                onLoad: (tweet: any) => {
                  if (!tweet) {
                    setIsMessageMissing(true);
                  }
                },
              } as any
            }
          />
        );

      case "youtube":
        return <YouTubeEmbed url={mediaItemView.sourceUrl} width="100%" />;

      case "instagram":
        return <InstagramEmbed url={mediaItemView.sourceUrl} width="100%" />;

      case "pinterest":
        return (
          <PinterestEmbed
            url={mediaItemView.sourceUrl}
            width="100%"
            height={1100}
          />
        );

      case "tiktok":
        return <TikTokEmbed url={mediaItemView.sourceUrl} width="100%" />;

      default:
        return <div>Not yet available</div>;
    }
  }, [mediaItem?.source, mediaItemView]);

  if (isMessageMissing) {
    return (
      <Message
        type="error"
        title={t("messageNotFoundTitle")}
        description={<p>{t("messageNotFoundBody")}</p>}
      />
    );
  }

  if (!mediaItem || !mediaItemView) {
    return <Loader />;
  }

  return <div className="components__media-item__message-tab">{body}</div>;
};

export default MessageTab;
