import React from "react";
import IconNavItem from "../../IconNavItem";
import { BootstrapSize } from "../../../inc/constants";
import FavouriteFilled from "../../../icons/FavouriteFilled";
import Favourite from "../../../icons/Favourite";
import FlagFilled from "../../../icons/FlagFilled";
import Flag from "../../../icons/Flag";
import { Nav } from "rsuite";
import Export from "../../../icons/Export";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { components } from "../../../types/openapi";
import AddToEditorialNavItem from "../../AddToEditorialNavItem";
import CopyToClipboardPopover from "../../CopyToClipboardPopover";

import "./index.scss";
import { I18nContext } from "../../../provider/I18nProvider";

interface IActionsSectionProps {
  mediaItem: components["schemas"]["MediaItem"];
  onChange: (
    mediaItemPartial: components["schemas"]["MediaItemPartial"]
  ) => Promise<void>;
}

const ActionsSection = ({ mediaItem, onChange }: IActionsSectionProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  if (!mediaItem.mediaItemId) {
    return null;
  }
  return (
    <Nav className="components__media-item__actions-section">
      <IconNavItem
        title={windowOuterWidth >= BootstrapSize.MD ? t("favorite") : ""}
        iconComponent={mediaItem.isFavourite ? FavouriteFilled : Favourite}
        onClick={() =>
          onChange({
            isFavourite: !mediaItem.isFavourite,
          })
        }
      />
      <AddToEditorialNavItem
        forBulk={false}
        hideTitle={windowOuterWidth < BootstrapSize.MD}
        mediaItemIds={[mediaItem.mediaItemId]}
        placement="bottom"
      />
      <IconNavItem
        title={
          windowOuterWidth >= BootstrapSize.MD
            ? t(mediaItem.isRead ? "read" : "unread")
            : ""
        }
        iconComponent={mediaItem.isRead ? Flag : FlagFilled}
        titleStyle={
          mediaItem.isRead
            ? undefined
            : { color: "#2D56A3", fontWeight: "bold" }
        }
        iconStyle={mediaItem.isRead ? undefined : { color: "#2D56A3" }}
        onClick={() =>
          onChange({
            isRead: !mediaItem.isRead,
          })
        }
      />
      <CopyToClipboardPopover value={mediaItem.url}>
        <IconNavItem
          title={windowOuterWidth >= BootstrapSize.MD ? t("share") : ""}
          iconComponent={Export}
        />
      </CopyToClipboardPopover>
    </Nav>
  );
};
export default ActionsSection;
