import { Alert, Icon, IconButton, Loader, Nav } from "rsuite";
import React from "react";
import { components } from "../../types/openapi";
import axios from "../../inc/axios";
import "./index.scss";
import { useLocation } from "react-router-dom";
import MediaItemDeleteModal from "../MediaItemDeleteModal";
import { AxiosError } from "axios";
import { APP_PATH, BootstrapSize } from "../../inc/constants";
import Expand from "../../icons/Expand";
import MetaDataSection from "./MetaDataSection";
import ActionsSection from "./ActionsSection";
import MainContentSection from "./MainContentSection";
import SettingsSection from "./SettingsSection";
import { I18nContext } from "../../provider/I18nProvider";
import { AuthContext } from "../../provider/AuthProvider";
import { LayoutContext } from "../../provider/LayoutProvider";
import { IMaartenError } from "../../types";

interface IMediaItemProps {
  mediaItem: components["schemas"]["MediaItem"];
  onChange?: (mediaItem: components["schemas"]["MediaItem"]) => void;
  onDelete: () => void;
  onClose?: () => void;
}

export type TMediaItemTabType =
  | "text"
  | "cover"
  | "pdf"
  | "screenshot"
  | "page"
  | "website"
  | "fragment"
  | "message";

const MediaItem = ({
  mediaItem,
  onChange,
  onClose,
  onDelete,
}: IMediaItemProps) => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const isMounted = React.useRef(false);
  const location = useLocation();

  const urlSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [details, setDetails] =
    React.useState<components["schemas"]["MediaItemDetails"]>();
  const [mediaItemView, setMediaItemView] =
    React.useState<components["schemas"]["MediaItemView"]>();

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (!auth?.jwt.superUserId && !mediaItem.isRead && onChange) {
      onChange({ ...mediaItem, isRead: true });
    }
  }, [auth?.jwt.superUserId, mediaItem, onChange]);

  const availableTabTypes = React.useMemo(() => {
    const availableTabTypes: TMediaItemTabType[] = [];
    if (!mediaItemView || !mediaItem) {
      return availableTabTypes;
    }
    if (mediaItemView?.pdfUrl) {
      availableTabTypes.push("pdf");
    }
    if (mediaItemView?.imageUrl) {
      availableTabTypes.push("screenshot");
    }
    if (
      [
        "facebook",
        "twitter",
        "youtube",
        "instagram",
        "pinterest",
        "tiktok",
      ].includes(mediaItem.source.toLowerCase())
    ) {
      availableTabTypes.push("message");
    }
    availableTabTypes.push("text");
    if (mediaItem.pageImageUrl) {
      availableTabTypes.push("page");
    }
    if (mediaItem.coverPageImageUrl) {
      availableTabTypes.push("cover");
    }
    if (
      mediaItem.url &&
      ["radio", "podcast", "tv"].indexOf(mediaItem.mediaType) >= 0
    ) {
      availableTabTypes.push("fragment");
    } else {
      if (mediaItemView?.sourceUrl) {
        availableTabTypes.push("website");
      }
    }

    return availableTabTypes;
  }, [mediaItemView, mediaItem]);

  let defaultActiveTab =
    (urlSearchParams.get("activeTab") as TMediaItemTabType) ||
    availableTabTypes[0];
  if (
    mediaItem &&
    mediaItem.mediaType === "print" &&
    windowOuterWidth < BootstrapSize.SM
  ) {
    defaultActiveTab = "text";
  }
  const [activeTab, setActiveTab] =
    React.useState<TMediaItemTabType>(defaultActiveTab);
  React.useEffect(() => {
    if (
      availableTabTypes.length &&
      availableTabTypes.indexOf(activeTab) === -1
    ) {
      if (
        defaultActiveTab &&
        availableTabTypes.indexOf(defaultActiveTab) >= 0
      ) {
        setActiveTab(defaultActiveTab);
        return;
      }
      setActiveTab(availableTabTypes[0]);
    }
  }, [activeTab, availableTabTypes, defaultActiveTab]);

  const onPartialChange = React.useCallback(
    (
      mediaItemPartial: components["schemas"]["MediaItemPartial"],
      recalculatePrValue?: boolean,
    ) => {
      if (!mediaItem || !onChange) {
        return Promise.reject(new Error("Cannot update Mediaitem"));
      }
      onChange({
        ...mediaItem,
        ...mediaItemPartial,
      });
      return axios
        .request({
          url: `/mediaItem/crud/${mediaItem.mediaItemId}`,
          method: "patch",
          data: mediaItemPartial,
          params: { recalculatePrValue },
        })
        .then((res) => {
          onChange(res.data as components["schemas"]["MediaItem"]);
          return Promise.resolve();
        })
        .catch((err) => {
          console.log(err);
          Alert.error(t("invalidData"));
          return Promise.reject(err);
        });
    },
    [mediaItem, onChange, t],
  );

  React.useEffect(() => {
    setDetails(undefined);
    setMediaItemView(undefined);
    if (!mediaItem || !mediaItem.isOwned || !auth) {
      return;
    }
    axios
      .get<components["schemas"]["MediaItemDetails"]>(
        `/mediaItem/details/${mediaItem.mediaItemId}`,
      )
      .then((res) => {
        if (isMounted.current) {
          setDetails(res.data);
        }
      });
    axios
      .get<components["schemas"]["MediaItemView"]>(
        `${mediaItem.url}?format=json&bearer=${auth.bearer}`,
      )
      .then((res) => {
        if (isMounted.current) {
          setMediaItemView({
            ...res.data,
            // lexisNexisUrl: `${window.location.origin}/mock/lexisNexisView.json?`,
          });
        }
      });

    // do not reload data with new auth or bearer! Every hit is counted!
    // eslint-disable-next-line
  }, [mediaItem?.mediaItemId]);

  if (!auth || !mediaItem) {
    return <Loader />;
  }

  // console.log(mediaItem);

  return (
    <div className="components__media-item">
      <div className="row">
        <div className="components__media-item__actions col-12 d-flex d-md-none">
          <IconButton icon={<Icon icon="close" />} onClick={onClose} circle />
          <div style={{ flex: 1 }}>
            <ActionsSection mediaItem={mediaItem} onChange={onPartialChange} />
          </div>
        </div>
        <div className="components__media-item__meta-data col-12 d-md-none mb-5">
          <MetaDataSection mediaItem={mediaItem} details={details} />
        </div>
        <div className="components__media-item__main-content col-md-8 col-lg-9 order-md-2">
          <div className="components__media-item__main-content__content">
            <div className="d-none d-md-block">
              <ActionsSection
                mediaItem={mediaItem}
                onChange={onPartialChange}
              />
            </div>
            <div className="ms-md-3 pe-md-3 components__media-item__right">
              <MainContentSection
                mediaItem={mediaItem}
                mediaItemView={mediaItemView}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                availableTabTypes={availableTabTypes}
              />
            </div>
          </div>
        </div>
        <div className="components__media-item__settings col-md-4 col-lg-3 order-md-1 pe-0">
          <div className="components__media-item__settings__content">
            {onClose ? (
              <Nav className="d-none d-md-block">
                <Nav.Item
                  icon={<Icon icon="close" className="me-0" />}
                  onClick={onClose}
                />
                <Nav.Item
                  style={{ float: "right" }}
                  href={`${APP_PATH}/mediaItem/${auth.jwt.currentCustomerLinkId}/${mediaItem.mediaItemId}?activeTab=${activeTab}`}
                  target="_blank"
                >
                  {t("openAsPage")}
                  <Icon
                    icon="expand"
                    componentClass={Expand}
                    style={{
                      marginLeft: 10,
                      marginRight: 0,
                      marginBottom: -1,
                    }}
                  />
                </Nav.Item>
              </Nav>
            ) : null}
            <div className="components__media-item__left-column">
              <div className="d-none d-md-block">
                <MetaDataSection mediaItem={mediaItem} details={details} />
              </div>
              <SettingsSection
                mediaItem={mediaItem}
                onChange={onPartialChange}
                showDeleteModal={() => setShowDeleteModal(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <MediaItemDeleteModal
        show={showDeleteModal}
        onConfirm={(message?: string) => {
          setShowDeleteModal(false);
          axios
            .request({
              method: "delete",
              url: `/mediaItem/crud/${mediaItem.mediaItemId}`,
              params: message ? { message } : undefined,
            })
            .then(() => {
              Alert.success(t("deleteSuccessful"));
              onDelete();
            })
            .catch((err: AxiosError<IMaartenError>) => {
              Alert.error(t(err.response?.data.error || "genericErrorMessage"));
            });
        }}
        onCancel={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default MediaItem;
