import MediaItemGridItem from "./MediaItemGridItem";
import React, { CSSProperties } from "react";
import { components } from "../../types/openapi";
import { VariableSizeGrid } from "react-window";
import { Loader } from "rsuite";
import {
  ENarrowCastingMode,
  LayoutContext,
} from "../../provider/LayoutProvider";
import animate from "./inc/animate";
import { range } from "lodash";
import { MEDIA_ITEM_GRID_ITEM_WIDTH_MD } from "./inc/constants";
import { areEqual } from "react-window";
import { LOAD_MEDIAITEM_COUNT } from "../../inc/constants";
import { IMediaItemDashboardWidgetSettings } from "../dashboardWidgets/MediaitemDashboardWidget";

export const getColspan = (width: number) => {
  if (width > 2650) {
    return 6;
  } else if (width > 1750) {
    return 8;
  } else if (width > 850) {
    return 12;
  } else {
    return 24;
  }
};

export const getSearchEngineResultWidth = (width: number) => {
  if (width > 2650) {
    return Math.floor((width - 8) / 4);
  } else if (width > 1750) {
    return Math.floor((width - 8) / 3);
  } else if (width > 850) {
    return Math.floor((width - 8) / 2);
  } else {
    return Math.floor(width - 8);
  }
};

interface IMediaItemGridProps {
  disabled?: boolean;
  filterCacheResponse?: components["schemas"]["FilterCacheResponse"] | null;
  getSelected?: (mediaItem: components["schemas"]["MediaItem"]) => boolean;
  height: number;
  isMediaItemLoaded: (obj: { index: number }) => boolean;
  loadMoreMediaItems: (obj: {
    startIndex: number;
    stopIndex: number;
  }) => Promise<void>;
  mediaItems: Array<components["schemas"]["MediaItem"] | null | undefined>;
  onMediaItemClick?: (mediaItem: components["schemas"]["MediaItem"]) => void;
  onMediaItemChange?: (
    mediaItemId: string,
    data: components["schemas"]["MediaItemPartial"],
  ) => void;
  setSelected?: (
    mediaItem: components["schemas"]["MediaItem"],
    isSelected: boolean,
  ) => void;
  width: number;
  settings?: IMediaItemDashboardWidgetSettings;
}

const MediaItemGrid = ({
  disabled,
  filterCacheResponse,
  getSelected,
  height,
  isMediaItemLoaded,
  loadMoreMediaItems,
  mediaItems,
  onMediaItemClick,
  onMediaItemChange,
  setSelected,
  settings,
  width,
}: IMediaItemGridProps) => {
  const { narrowCastingMode } = React.useContext(LayoutContext);
  const colspan = React.useMemo(() => getColspan(width), [width]);
  const columnWidth = React.useMemo(
    () => getSearchEngineResultWidth(width),
    [width],
  );
  const variableSizeGridRef = React.useRef<VariableSizeGrid>(null);
  const [animationScrollTop, setAnimationScrollTop] = React.useState<
    number | undefined
  >();
  const scrollTopRef = React.useRef(0);
  const prevMediaItemsRef =
    React.useRef<
      Array<components["schemas"]["MediaItem"] | null | undefined>
    >();

  const isNarrowCasting =
    narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF;
  const columnCount = 24 / colspan;

  React.useEffect(() => {
    const updatedIndexes = mediaItems
      ? [...mediaItems.keys()].filter((index) => {
          return (
            mediaItems[index] &&
            prevMediaItemsRef.current &&
            mediaItems[index] !== prevMediaItemsRef.current[index]
          );
        })
      : [];
    if (updatedIndexes.length && variableSizeGridRef.current) {
      variableSizeGridRef.current.resetAfterRowIndex(
        Math.floor(Math.min(...updatedIndexes) / columnCount),
      );
    }
    prevMediaItemsRef.current = mediaItems;
  }, [columnCount, mediaItems]);

  const getRowHeight = React.useCallback(
    (rowIndex: number) => {
      const mediaItemsInRow = range(
        rowIndex * columnCount,
        (rowIndex + 1) * columnCount,
      )
        .map((mediaItemIndex) => mediaItems[mediaItemIndex])
        .filter((item) => !!item) as components["schemas"]["MediaItem"][];
      const defaultHeight =
        columnWidth >= MEDIA_ITEM_GRID_ITEM_WIDTH_MD ? 270 : 370;
      const cellHeights = mediaItemsInRow.map((mediaItem) => {
        let result = defaultHeight;
        if (isNarrowCasting || !mediaItem?.labelIds?.length) {
          result -= 40;
        }
        if (isNarrowCasting || !mediaItem?.searchTopicIds?.length) {
          result -= 40;
        }
        if (isNarrowCasting) {
          result -= 20;
        }
        return result;
      });
      return cellHeights.length ? Math.max(...cellHeights) : defaultHeight;
    },
    [columnCount, columnWidth, isNarrowCasting, mediaItems],
  );

  const rowCount = React.useMemo(
    () =>
      filterCacheResponse?.count
        ? Math.ceil(filterCacheResponse.count / columnCount)
        : 0,
    [columnCount, filterCacheResponse?.count],
  );

  React.useEffect(() => {
    const autoScrollInterval = setInterval(() => {
      if (!isNarrowCasting) {
        return;
      }

      let currentVisibleRowIndex = 0;
      let currentVisibleRowY = 0;
      let totalGridHeight = 0;
      let nextVisibleRowIndex = -1;
      let nextVisibleRowY = -1;
      for (let index = 0; index < rowCount; index++) {
        const rowHeight = getRowHeight(index);
        if (scrollTopRef.current > totalGridHeight) {
          currentVisibleRowY = totalGridHeight + rowHeight;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          currentVisibleRowIndex = index + 1;
        } else {
          if (nextVisibleRowIndex === -1) {
            if (index + 1 < rowCount) {
              nextVisibleRowY = totalGridHeight + rowHeight;
              nextVisibleRowIndex = index + 1;
            } else {
              nextVisibleRowY = 0;
              nextVisibleRowIndex = 0;
            }
          }
        }
        totalGridHeight += rowHeight;
      }
      const toValue =
        scrollTopRef.current === currentVisibleRowY
          ? nextVisibleRowY
          : currentVisibleRowY;

      // // scroll to next message
      animate({
        fromValue: scrollTopRef.current,
        toValue,
        onUpdate: (newScrollTop, callback) => {
          setAnimationScrollTop(newScrollTop);
          callback();
        },
        onComplete: () => {
          setAnimationScrollTop(undefined);
        },
      });
    }, 30_000);

    return () => {
      clearInterval(autoScrollInterval);
    };
  }, [animationScrollTop, getRowHeight, isNarrowCasting, rowCount]);

  const Cell = React.useCallback(
    ({
      columnIndex,
      rowIndex,
      style,
    }: {
      columnIndex: number;
      rowIndex: number;
      style: CSSProperties;
    }) => {
      const mediaItemIndex = rowIndex * columnCount + columnIndex;
      const mediaItem = mediaItems[mediaItemIndex] as
        | components["schemas"]["MediaItem"]
        | undefined;
      const isSelected =
        getSelected && mediaItem ? getSelected(mediaItem) : false;
      return (
        <div style={style}>
          {mediaItem ? (
            <MediaItemGridItem
              key={mediaItem.mediaItemId}
              colspan={colspan}
              disabled={disabled}
              display={(settings?.display || "block") as "block"}
              isSelected={isSelected}
              mediaItem={mediaItem}
              onChange={onMediaItemChange}
              onClick={onMediaItemClick}
              setSelected={setSelected}
              width={columnWidth}
            />
          ) : mediaItems[mediaItemIndex] === null ? (
            <Loader center size="lg" />
          ) : null}
        </div>
      );
    },
    [
      colspan,
      columnCount,
      columnWidth,
      disabled,
      getSelected,
      mediaItems,
      onMediaItemChange,
      onMediaItemClick,
      setSelected,
      settings?.display,
    ],
  );

  const onItemsRendered = React.useCallback(
    ({
      overscanRowStartIndex,
      overscanRowStopIndex,
    }: {
      overscanRowStartIndex: number;
      overscanRowStopIndex: number;
    }) => {
      const loadMorePayload = {
        startIndex: -1,
        stopIndex: -1,
      };
      range(
        overscanRowStartIndex * columnCount,
        overscanRowStopIndex * columnCount + 1,
      ).forEach((index) => {
        if (!isMediaItemLoaded({ index })) {
          if (loadMorePayload.startIndex === -1) {
            loadMorePayload.startIndex = index;
          }
          loadMorePayload.stopIndex = index;
        }
      });
      if (loadMorePayload.startIndex >= 0) {
        // load at least 12 items...
        if (
          loadMorePayload.stopIndex - loadMorePayload.startIndex <
          LOAD_MEDIAITEM_COUNT
        ) {
          loadMorePayload.stopIndex =
            loadMorePayload.startIndex + LOAD_MEDIAITEM_COUNT;
        }
        loadMoreMediaItems(loadMorePayload);
      }
    },
    [columnCount, isMediaItemLoaded, loadMoreMediaItems],
  );

  // DO NOT REMOVE THIS
  // Make sure the grid is updated whenever e.g. sorting changes
  React.useEffect(() => {
    const isReset =
      mediaItems.length &&
      mediaItems.findIndex((val) => val !== undefined) === -1;
    const variableSizeGrid = variableSizeGridRef.current;
    if (isReset && variableSizeGrid) {
      variableSizeGrid.scrollTo({ scrollTop: 0 });
      loadMoreMediaItems({
        startIndex: 0,
        stopIndex: columnCount,
      });
    }
  }, [
    columnCount,
    getRowHeight,
    loadMoreMediaItems,
    mediaItems,
    onItemsRendered,
  ]);

  // DO NOT REMOVE THIS
  // Make sure the grid is updated whenever e.g. sorting changes
  React.useEffect(() => {
    const isReset =
      mediaItems.length &&
      mediaItems.findIndex((val) => val !== undefined) === -1;
    const variableSizeGrid = variableSizeGridRef.current;
    if (isReset && variableSizeGrid) {
      variableSizeGrid.scrollTo({ scrollTop: 0 });
      const rowCount = Math.max(Math.ceil(height / getRowHeight(0)), 1);
      loadMoreMediaItems({
        startIndex: 0,
        stopIndex: Math.min(rowCount * columnCount, mediaItems.length),
      });
    }
  }, [
    columnCount,
    getRowHeight,
    height,
    loadMoreMediaItems,
    mediaItems,
    onItemsRendered,
  ]);

  const getColumnWidth = React.useCallback(() => columnWidth, [columnWidth]);

  return (
    <VariableSizeGrid
      // required for resizing to work properly
      key={width}
      ref={variableSizeGridRef}
      onItemsRendered={onItemsRendered}
      columnCount={columnCount}
      columnWidth={getColumnWidth}
      height={height}
      rowCount={Math.ceil(mediaItems.length / columnCount)}
      rowHeight={getRowHeight}
      width={width}
    >
      {Cell}
    </VariableSizeGrid>
  );
};

export default React.memo(MediaItemGrid, areEqual);
