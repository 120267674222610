import React from "react";
import { Checkbox, Loader, Panel, SelectPicker } from "rsuite";
import openapi from "../../../openapi.json";
import { IBenchmarkWidgetProps, TBenchmarkHashData } from "../index";
import {
  ENarrowCastingMode,
  LayoutContext,
} from "../../../provider/LayoutProvider";
import axios from "axios";
import { components } from "../../../types/openapi";
import _, { debounce } from "lodash";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import IconRadioGroup from "../../../components/IconRadioGroup";
import { formatInt } from "../../../inc/numbers";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Drag from "../../../icons/Drag";
import BenchmarkResultTable from "./BenchmarkResultTable";
import BenchmarkResultGrid from "./BenchmarkResultGrid";
import { I18nContext } from "../../../provider/I18nProvider";
import { LOAD_BENCHMARKRESULT_COUNT } from "../../../inc/constants";
import MediaItemTableDisplay from "../../../icons/MediaItemTableDisplay";
import MediaItemBlockDisplay from "../../../icons/MediaItemBlockDisplay";
import { useHistory } from "react-router-dom";
import { stringifyApiCacheBenchmark } from "../../../inc/benchmark";
import "./index.scss";
import { useBenchmarkHashData } from "../../../views/BenchmarkView/inc/hooks";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";

export type TBenchmarkResultBenchmarkWidgetcolumnLayoutSettings = {
  propName: string;
  width?: number;
  show?: boolean;
};

export interface IBenchmarkResultBenchmarkWidgetSettings {
  display?: "compact" | "block" | "table";
  notes?: string;
  sort?: components["schemas"]["IdolSortType"];
  columnLayouts?: TBenchmarkResultBenchmarkWidgetcolumnLayoutSettings[];
}

const BenchmarkResultBenchmarkWidget = ({
  benchmark,
  height,
  onDelete,
  onNotesChange,
  onSettingsChange,
  onSettingsToggle,
  settings,
  uid,
  width,
}: IBenchmarkWidgetProps<IBenchmarkResultBenchmarkWidgetSettings>) => {
  const { narrowCastingMode } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const [benchmarkResults, setBenchmarkResults] =
    React.useState<
      Array<components["schemas"]["BenchmarkResult"] | null | undefined>
    >();
  const [forcedGridUpdateCount, setForcedGridUpdateCount] = React.useState(0);

  const locationHashData = useBenchmarkHashData();

  React.useEffect(() => {
    setForcedGridUpdateCount(
      (forcedGridUpdateCount) => forcedGridUpdateCount + 1,
    );
  }, [narrowCastingMode, width, settings]);

  const headerRowRef = React.useRef<HTMLDivElement>(null);
  const count = benchmarkResults?.length || 0;
  const isDisplayBenchmarkOnly = !onSettingsChange;
  const isNarrowCasting =
    narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF;
  const history = useHistory();

  const onSortChange = React.useCallback(
    (sort: components["schemas"]["MediaItemSortType"]) => {
      const { matchTexts, searchEngineRequest } = benchmark;
      const hashData: TBenchmarkHashData = {
        matchTexts,
        searchEngineRequest,
        sort,
      };
      history.push(
        `${history.location.pathname}#${encodeURIComponent(
          JSON.stringify(hashData),
        )}`,
      );
    },
    [benchmark, history],
  );
  const currentIndex = -1;
  const previousItem = benchmarkResults
    ? benchmarkResults[currentIndex - 1]
    : null;
  const nextItem = benchmarkResults ? benchmarkResults[currentIndex + 1] : null;

  React.useEffect(() => {
    if (currentIndex < 0 || !benchmarkResults) {
      return;
    }

    // If there are not-yet-loaded benchmarkResults before the current selected and opened one, load them
    if (previousItem === undefined && currentIndex > 0) {
      // load data before the current item
      const stopIndex = currentIndex - 1;
      loadMoreBenchmarkResults({
        startIndex: Math.max(stopIndex - LOAD_BENCHMARKRESULT_COUNT, 0),
        stopIndex,
      });
    }

    // If there are not-yet-loaded benchmarkResults after the current selected and opened one, load them
    if (nextItem === undefined && benchmarkResults.length > currentIndex + 1) {
      const startIndex = currentIndex + 1;
      loadMoreBenchmarkResults({
        startIndex,
        stopIndex: Math.min(
          startIndex + LOAD_BENCHMARKRESULT_COUNT,
          benchmarkResults.length - 1,
        ),
      });
    }
  });

  const visibleColumns = React.useMemo(() => {
    return Object.keys(
      openapi.components.schemas.BenchmarkResult.properties,
    ).filter((prop) => prop !== "imageUrl");
  }, []);

  const columns = React.useMemo(() => {
    let columnLayouts: TBenchmarkResultBenchmarkWidgetcolumnLayoutSettings[] =
      settings?.columnLayouts
        ? JSON.parse(JSON.stringify(settings?.columnLayouts))
        : [];

    if (settings?.columnLayouts && !_.isArray(columnLayouts)) {
      // ** TEMP **
      // Update the columnLayouts to the new format (used to be an hashmap of { propName: { width } })
      columnLayouts = [];
      Object.entries(settings?.columnLayouts).forEach(
        ([key, value]: [string, any]) => {
          columnLayouts.push({
            ...value,
            propName: key,
          });
        },
      );
    }
    const defaultHiddenColumn = ["circulation"];

    visibleColumns.forEach((column) => {
      if (columnLayouts.some((property) => property.propName === column)) {
        return;
      }
      columnLayouts.push({
        propName: column,
        show: !defaultHiddenColumn.includes(column),
      });
    });

    return columnLayouts;
  }, [settings?.columnLayouts, visibleColumns]);

  const reorder = React.useCallback(
    (startIndex: number, endIndex: number) => {
      const [removed] = columns.splice(startIndex, 1);
      columns.splice(endIndex, 0, removed);
      return columns;
    },
    [columns],
  );

  const onDragEnd = React.useCallback(
    (result: any) => {
      // dropped outside the list
      if (!result.destination || !onSettingsChange) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        columnLayouts: reorder(result.source.index, result.destination.index),
      });
    },
    [onSettingsChange, reorder, settings, uid],
  );

  const popoverForm = React.useMemo(
    () =>
      onSettingsChange ? (
        <>
          <h5>{t("widgetSettings_display")}</h5>
          <IconRadioGroup
            value={settings?.display || "block"}
            onChange={(value) => {
              onSettingsChange(uid, {
                ...settings,
                display:
                  value as IBenchmarkResultBenchmarkWidgetSettings["display"],
              });
            }}
            items={[
              {
                value: "table",
                svg: MediaItemTableDisplay,
              },
              {
                value: "block",
                svg: MediaItemBlockDisplay,
              },
            ]}
          />
          {settings?.display === "table" ? (
            <>
              <h5>Kolommen</h5>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="columns">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`benchmark-result-widget__settings__container${
                        snapshot.isDraggingOver
                          ? " benchmark-result-widget__settings__container--dragging-over"
                          : ""
                      }`}
                    >
                      {columns.map((column, index) => (
                        <Draggable
                          key={column.propName}
                          draggableId={column.propName}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                left: "auto",
                                top: "auto",
                                display: "flex",
                                alignItems: "center",
                              }}
                              className={`benchmark-result-widget__settings__item${
                                snapshot.isDragging
                                  ? " benchmark-result-widget__settings__item--dragging"
                                  : ""
                              }`}
                            >
                              <div className="benchmark-result-widget__settings__item__drag-handle">
                                <Drag />
                              </div>
                              <Checkbox
                                checked={
                                  column.show !== undefined ? column.show : true
                                }
                                value={column.propName}
                                onChange={(_value, checked) => {
                                  if (
                                    isDisplayBenchmarkOnly ||
                                    !onSettingsChange
                                  ) {
                                    return;
                                  }
                                  const newcolumnLayouts = [...columns];
                                  newcolumnLayouts[index].show = checked;
                                  onSettingsChange(uid, {
                                    ...settings,
                                    columnLayouts: newcolumnLayouts,
                                  });
                                }}
                              >
                                {t(column.propName)}
                              </Checkbox>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          ) : null}
        </>
      ) : undefined,
    [
      columns,
      isDisplayBenchmarkOnly,
      onDragEnd,
      onSettingsChange,
      settings,
      t,
      uid,
    ],
  );

  const stringifiedBenchmark = React.useMemo(
    () => stringifyApiCacheBenchmark(benchmark),
    [benchmark],
  );

  const loadMoreBenchmarkResults = React.useCallback(
    ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
      if (!stringifiedBenchmark) {
        return Promise.reject(new Error("No benchmark to load more items for"));
      }
      const limit = Math.max(stopIndex - startIndex, 1);
      setBenchmarkResults((benchmarkResults) => {
        const newResults = benchmarkResults ? [...benchmarkResults] : [];
        for (let i = startIndex; i < startIndex + limit; i++) {
          newResults[i] = null;
        }
        return newResults;
      });
      return axios
        .request<components["schemas"]["BenchmarkResults"]>({
          method: "post",
          url: "/benchmark/result",
          data: JSON.parse(stringifiedBenchmark),
          params: {
            limit,
            offset: startIndex,
            sort:
              locationHashData?.sort ||
              openapi.components.schemas.MediaItemSortType.default,
          },
        })
        .then((res) => {
          setBenchmarkResults((benchmarkResults) => {
            const newResults = benchmarkResults ? [...benchmarkResults] : [];
            const { count, items } = res.data;
            if (!items) {
              return newResults;
            }

            if (newResults.length > count) {
              newResults.splice(count, newResults.length - count);
            }

            for (let i = 0; i < count; i++) {
              const newItem = items[i - startIndex];
              if (newItem) {
                newResults[i] = newItem;
                continue;
              }
              // DO NOT Fill with array.length or new Array(length) since empty array positions break rsuite table
              if (newResults.length <= i) {
                newResults.push(undefined);
              }
            }
            return newResults;
          });
        });
    },
    [locationHashData?.sort, stringifiedBenchmark],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(
    debounce(
      () => {
        loadMoreBenchmarkResults({
          startIndex: 0,
          stopIndex: LOAD_BENCHMARKRESULT_COUNT,
        });
      },
      100,
      { leading: true, trailing: false },
    ),
    [loadMoreBenchmarkResults],
  );

  const sortTypeOptions = React.useMemo(
    () =>
      openapi.components.schemas.MediaItemSortType.enum
        .filter(
          (a) =>
            ![
              "mediaItemSortType_prValueAsc",
              "mediaItemSortType_prValueDesc",
            ].includes(a),
        )
        .map((value) => ({
          label: t(value),
          value,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [t],
  );

  const header = React.useMemo(() => {
    return (
      <WidgetPanelHeader
        notes={settings?.notes}
        onNotesChange={onNotesChange}
        allowImageDownload={false}
        title={t("benchmarkResultBenchmarkWidget_title", formatInt(count))}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={popoverForm}
      >
        {onSettingsChange && benchmarkResults?.length ? (
          <div
            className="d-flex flex-wrap align-items-center my-2"
            ref={headerRowRef}
          >
            <div className="d-flex flex-grow-1 justify-content-end">
              <SelectPicker
                className="benchmark-result-widget__sort"
                cleanable={false}
                searchable={false}
                appearance="subtle"
                menuStyle={{ width: 200 }}
                data={sortTypeOptions}
                placeholder={t("Sort")}
                placement="bottomEnd"
                value={locationHashData?.sort}
                onChange={onSortChange}
              />
            </div>
          </div>
        ) : null}
      </WidgetPanelHeader>
    );
  }, [
    benchmarkResults?.length,
    count,
    locationHashData?.sort,
    onDelete,
    onNotesChange,
    onSettingsChange,
    onSettingsToggle,
    onSortChange,
    popoverForm,
    settings?.notes,
    sortTypeOptions,
    t,
  ]);

  const isBenchmarkResultLoaded = React.useCallback(
    ({ index }: { index: number }) => {
      return benchmarkResults ? benchmarkResults[index] !== undefined : false;
    },
    [benchmarkResults],
  );

  const gridHeight = React.useMemo(
    () => height - (isNarrowCasting || isDisplayBenchmarkOnly ? 50 : 100),
    [height, isDisplayBenchmarkOnly, isNarrowCasting],
  );

  const panelBody = React.useMemo(() => {
    if (!benchmarkResults) {
      return <Loader />;
    }
    if (benchmarkResults.length === 0) {
      return <InsufficientDataBody />;
    }
    return settings?.display === "table" ? (
      <BenchmarkResultTable
        benchmarkResults={benchmarkResults}
        columnLayouts={columns}
        height={height}
        onSettingsChange={onSettingsChange}
        isBenchmarkResultLoaded={isBenchmarkResultLoaded}
        loadMoreBenchmarkResults={loadMoreBenchmarkResults}
        settings={settings}
        widgetUid={uid}
      />
    ) : (
      <BenchmarkResultGrid
        benchmarkResults={benchmarkResults}
        disabled={isDisplayBenchmarkOnly}
        height={gridHeight}
        isBenchmarkResultLoaded={isBenchmarkResultLoaded}
        key={forcedGridUpdateCount}
        loadMoreBenchmarkResults={loadMoreBenchmarkResults}
        settings={settings}
        width={width - 4}
      />
    );
  }, [
    benchmarkResults,
    columns,
    forcedGridUpdateCount,
    gridHeight,
    height,
    isBenchmarkResultLoaded,
    isDisplayBenchmarkOnly,
    loadMoreBenchmarkResults,
    onSettingsChange,
    settings,
    uid,
    width,
  ]);

  if (!benchmarkResults) {
    return <Loader />;
  }

  return (
    <Panel
      bodyFill={true}
      bordered={true}
      className={`benchmark-result-widget benchmark-result-widget--${uid}`}
      header={header}
    >
      {panelBody}
    </Panel>
  );
};

export default React.memo(BenchmarkResultBenchmarkWidget);
