import React from "react";
import { Dropdown, Nav } from "rsuite";
import "./index.scss";
import { TypeAttributes } from "rsuite/lib/@types/common";

interface IDropdownNavItemProps {
  className?: string;
  disabled?: boolean;
  placement?: TypeAttributes.Placement8;
  title: string;
  iconComponent: any;
  children: any;
}

const DropdownNavItem = (props: IDropdownNavItemProps) => {
  const { disabled, placement, title, children, className } = props;
  const IconComponent = props.iconComponent;
  return (
    <Nav.Item
      disabled={disabled}
      className={`components__dropdown-nav-item${
        className ? ` ${className}` : ""
      }`}
      renderItem={() => (
        <Dropdown
          placement={placement}
          disabled={disabled}
          title={
            <>
              {title ? (
                <span className="components__dropdown-nav-item__title">
                  {title}
                </span>
              ) : null}
              <IconComponent className="icon" />
            </>
          }
        >
          {children}
        </Dropdown>
      )}
    />
  );
};

export default DropdownNavItem;
