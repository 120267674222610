import React, { Dispatch, SetStateAction } from "react";
import { components } from "../../../types/openapi";
import { BootstrapSize } from "../../../inc/constants";
import AutosizeInput from "react-18-input-autosize";
import { HelpBlock } from "rsuite";
import FilterBar from "../../../components/FilterBar";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import {
  IDashboardWidget,
  DashboardWidgetContainer,
  dashboardWidgetDefinitions,
} from "../../../components/dashboardWidgets";
import { LayoutContext } from "../../../provider/LayoutProvider";
import "../index.scss";
import { TDashboard } from "../../../types";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import EmptyDashboardView from "./EmptyDashboardView";
import axios from "../../../inc/axios";
import useCustomers from "../../../hooks/useCustomers";
import { IPeriods } from "../../../inc/date";
import "./index.scss";

interface IExistingDashboardViewProps {
  actionsMenu?: React.ReactElement;
  compareFilterCacheResponse?:
    | components["schemas"]["FilterCacheResponse"]
    | null;
  comparePeriod?: components["schemas"]["Period"];
  dashboard: TDashboard;
  dashboardWidth: number;
  filter?: components["schemas"]["Filter"];
  filterCacheResponse?: components["schemas"]["FilterCacheResponse"] | null;
  isDirty?: boolean;
  onClick?: () => void;
  onComparePeriodChange?: (
    period: components["schemas"]["Period"] | undefined,
  ) => void;
  onDashboardChange?: (dashboard: TDashboard) => void;
  onDrawerToggle?: (open: boolean) => void;
  onFilterChange?: (filter?: components["schemas"]["Filter"]) => void;
  onFilterReset?: () => void;
  onPeriodsChange?: (periods: IPeriods) => void;
  onSubmit?: (e?: React.FormEvent) => void;
  period: components["schemas"]["Period"];
  saveButton?: React.ReactElement;
  setFilterCacheResponse: Dispatch<
    SetStateAction<
      components["schemas"]["FilterCacheResponse"] | null | undefined
    >
  >;
  titleInputError?: string;
  titleInputOnKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  titleInputRef?: React.Ref<HTMLInputElement>;
}

const ExistingDashboardView = ({
  actionsMenu,
  compareFilterCacheResponse,
  comparePeriod,
  dashboard,
  dashboardWidth,
  filter,
  filterCacheResponse,
  isDirty,
  onClick,
  onDashboardChange,
  onDrawerToggle,
  onFilterChange,
  onFilterReset,
  onPeriodsChange,
  onSubmit,
  period,
  saveButton,
  setFilterCacheResponse,
  titleInputError,
  titleInputOnKeyDown,
  titleInputRef,
}: IExistingDashboardViewProps) => {
  const { auth } = React.useContext(AuthContext);
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const layouts = React.useMemo(
    () => ({
      xs:
        dashboard?.config.widgets?.map((widget) => ({
          ...widget.layouts.xs,
          ...dashboardWidgetDefinitions[widget.type],
          i: widget.uid,
        })) || [],
      lg:
        dashboard?.config.widgets?.map((widget) => ({
          ...widget.layouts.lg,
          ...dashboardWidgetDefinitions[widget.type],
          i: widget.uid,
        })) || [],
    }),
    [dashboard?.config.widgets],
  );
  const customers = useCustomers();

  const [layoutMounted, setLayoutMounted] = React.useState(false);
  React.useEffect(() => {
    setLayoutMounted(false);
    setTimeout(() => {
      setLayoutMounted(true);
    }, 1);
  }, [dashboard.dashboardId]);

  const onLayoutChange = React.useCallback(
    (currentLayout: any, updatedLayouts: any) => {
      // Start listing for dashboard changes after mount: let an opened dashbaord configure itself to the current browser
      // width before reporting dirtyness.
      if (!layoutMounted || !onDashboardChange || !dashboard) {
        return;
      }

      onDashboardChange({
        ...dashboard,
        config: {
          ...dashboard.config,
          widgets: dashboard.config.widgets?.map(
            (widget, index): IDashboardWidget => ({
              ...widget,
              layouts: {
                xs: {
                  ...updatedLayouts["xs"][index],
                  x: 0,
                  w: 1,
                  h: updatedLayouts["xs"][index].h || 3,
                },
                lg: {
                  ...updatedLayouts["lg"][index],
                },
              },
            }),
          ),
        },
      });
    },
    [dashboard, layoutMounted, onDashboardChange],
  );

  const onSettingsChange = React.useCallback(
    (widgetUid: string, newSettings: any) => {
      if (!onDashboardChange || !dashboard.config.widgets) {
        return;
      }
      onDashboardChange({
        ...dashboard,
        config: {
          ...dashboard.config,
          widgets: dashboard.config.widgets.map((existingWidget) => {
            if (existingWidget.uid === widgetUid) {
              existingWidget.widgetConfig = newSettings;
            }
            return existingWidget;
          }),
        },
      });
    },
    [dashboard, onDashboardChange],
  );

  const currentCustomerIcon = React.useMemo(() => {
    const currentCustomer =
      customers && auth?.jwt.currentCustomerLinkId
        ? customers[auth.jwt.currentCustomerLinkId]
        : undefined;
    if (currentCustomer?.logoFileId) {
      return (
        <img
          className="narrowCastingCurrentCustomerIcon"
          src={`${axios.defaults.baseURL}file/crud/${currentCustomer.logoFileId}?bearer=${auth?.bearer}`}
          alt=""
        />
      );
    }
    return null;
  }, [auth?.bearer, auth?.jwt.currentCustomerLinkId, customers]);

  const { widgets } = dashboard.config;
  if (!auth) {
    return null;
  }

  return (
    <div className="h-100" onClick={onClick}>
      <form
        style={{ paddingLeft: windowOuterWidth < BootstrapSize.MD ? 40 : 0 }}
        onSubmit={onSubmit}
      >
        <div
          className="row align-items-center"
          style={{ minHeight: 40, justifyContent: "space-between" }}
        >
          <div className="col-auto col-sm d-flex views__dashboard-view__existing-dashboard-view__col-dashboard-title">
            {onDashboardChange ? (
              <div>
                <AutosizeInput
                  value={dashboard?.name || ""}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (dashboard && onDashboardChange) {
                      onDashboardChange({
                        ...dashboard,
                        name: event.target.value,
                      });
                    }
                  }}
                  disabled={!onFilterChange}
                  onKeyDown={titleInputOnKeyDown}
                  style={{ maxWidth: "100%", minWidth: 200 }}
                  className="views__dashboard-view__existing-dashboard-view__title-autosize-input"
                  placeholder={t(
                    dashboard.dashboardId ? "namelessDashboard" : "myMedia",
                  )}
                  inputClassName="rs-input dashboard-view__topbar__title-input"
                  ref={titleInputRef as any} // Allows focus on dashboard switch
                  autoFocus={!dashboard?.name} // Focus on load
                />
                {titleInputError ? (
                  <HelpBlock
                    style={{ color: "red", marginLeft: 6, marginBottom: -23 }}
                  >
                    {t(titleInputError)}
                  </HelpBlock>
                ) : null}
              </div>
            ) : (
              <h3 style={{ whiteSpace: "nowrap", padding: "8px 0 0 8px" }}>
                {dashboard?.name}
              </h3>
            )}
            {onDashboardChange ? saveButton : null}
          </div>
          {actionsMenu || currentCustomerIcon ? (
            <div className="col-auto ms-auto">
              {actionsMenu}
              {currentCustomerIcon}
            </div>
          ) : null}
        </div>
      </form>
      <FilterBar
        comparePeriod={comparePeriod}
        isDirty={isDirty}
        isLoading={filterCacheResponse === null}
        filter={filter}
        onChange={onFilterChange}
        onPeriodsChange={onPeriodsChange}
        period={period}
        resetFilter={onFilterReset}
        width={dashboardWidth}
      />
      {widgets?.length ? (
        <ResponsiveGridLayout
          draggableHandle=".drag-handle"
          className={`views__dashboard-view__grid-layout views__dashboard-view__grid-layout--${
            onDashboardChange ? "draggable" : "undraggable"
          }`}
          isDraggable={!!onDashboardChange}
          rowHeight={40}
          breakpoints={{ lg: BootstrapSize.LG, xs: 0 }}
          cols={{ lg: 3, xs: 1 }}
          width={dashboardWidth + 56}
          margin={[30, 30]}
          style={{
            marginLeft: -30,
          }}
          layouts={layouts}
          onLayoutChange={onLayoutChange}
        >
          {widgets?.map((widget, index) => (
            <DashboardWidgetContainer
              compareFilterCacheResponse={compareFilterCacheResponse}
              comparePeriod={comparePeriod}
              dashboardWidth={dashboardWidth + 48}
              disabled={!onFilterChange}
              filter={filter}
              filterCacheResponse={filterCacheResponse}
              key={widget.uid}
              onDelete={() => {
                onDashboardChange &&
                  onDashboardChange({
                    ...dashboard,
                    config: {
                      ...dashboard.config,
                      widgets: widgets.filter(
                        (existingWidget, existingIndex) =>
                          index !== existingIndex,
                      ),
                    },
                  });
              }}
              onSettingsChange={onSettingsChange}
              period={period}
              setFilterCacheResponse={setFilterCacheResponse}
              widget={widget}
            />
          ))}
        </ResponsiveGridLayout>
      ) : (
        <EmptyDashboardView onDrawerToggle={onDrawerToggle} />
      )}
    </div>
  );
};
export default React.memo(ExistingDashboardView);
