export const containsWord = (
  data: any[],
  nameProp: string,
  searchQuery: string
) => {
  if (!data) {
    return [];
  }
  return data.filter((dataObject) =>
    dataContainsWord(dataObject[nameProp], searchQuery)
  );
};

export const dataContainsWord = (data: string, searchQuery: string) =>
  data.match(
    new RegExp(
      `(\\s|^|\\.)${searchQuery.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      )}(\\s|$|\\.)`,
      "i"
    )
  );

export const containsWordThatStartWith = <T>(
  data: T[],
  nameProp: string,
  searchQuery: string
): T[] => {
  if (!data) {
    return [];
  }
  return data.filter((dataObject) =>
    dataContainsWordThatStartWith((dataObject as any)[nameProp], searchQuery)
  );
};

export const dataContainsWordThatStartWith = (
  data: string,
  searchQuery: string
) =>
  data.match(
    new RegExp(
      `(\\s|^|\\.)${searchQuery.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")}`,
      "i"
    )
  );

export const dataIsEmail = (data: string) => data.match(/\S+@\S+\.\S+/);
