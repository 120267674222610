import { Icon } from "rsuite";
import { ESentiment, sentimentOptions } from "../../../../inc/sentimentOptions";
import React from "react";
import { components } from "../../../../types/openapi";
import { currency, formatInt } from "../../../../inc/numbers";
import { I18nContext } from "../../../../provider/I18nProvider";
import useDimensions from "../../../../hooks/useDimensions";

import "./index.scss";

interface IItemBodyProps {
  mediaItem: components["schemas"]["MediaItem"];
}

const CONTENT_HEIGHT = 104;
const TITLE_BOTTOM_MARGIN = 8;
const BODY_LINE_HEIGHT = 16;

const ItemBody = ({ mediaItem }: IItemBodyProps) => {
  const [headingRef, headingDimensions] = useDimensions();
  const { t } = React.useContext(I18nContext);

  const { body, reach, ave } = mediaItem;
  const bodyHeight = React.useMemo(
    () =>
      Math.max(
        headingDimensions?.height
          ? Math.floor(
              (CONTENT_HEIGHT -
                headingDimensions.height -
                TITLE_BOTTOM_MARGIN) /
                BODY_LINE_HEIGHT,
            ) * BODY_LINE_HEIGHT
          : CONTENT_HEIGHT,
        0,
      ),
    [headingDimensions?.height],
  );

  const bodyText = React.useMemo(() => {
    // Trim body to 150 characters
    const trimmedBody = body.substr(0, 150);

    // Get all match indexes of a 'clean' spot (i.e. where the string has a dot, comma or space)
    const matchIndexes = Array.from(
      trimmedBody.matchAll(/[\s.,]/g),
      (match) => match.index || 0,
    );

    // Trim the body text to the last 'clean' index, resulting in a clean cut text
    return trimmedBody.substr(0, Math.max(...matchIndexes) || 150);
  }, [body]);

  const prValue =
    mediaItem.customerPrValue === undefined
      ? mediaItem.prValue
      : mediaItem.customerPrValue;
  const sentiment =
    mediaItem.customerSentiment === undefined
      ? mediaItem.sentiment
      : mediaItem.customerSentiment;

  return (
    <>
      <div style={{ minHeight: CONTENT_HEIGHT }}>
        <h4
          style={{ marginBottom: TITLE_BOTTOM_MARGIN - 2, paddingBottom: 2 }}
          ref={headingRef}
        >
          {mediaItem.title}
        </h4>
        <p
          style={{
            fontSize: 12,
            lineHeight: "16px",
            height: bodyHeight,
            overflow: "hidden",
          }}
        >
          {bodyText}
        </p>
      </div>
      <div className="views__dashboard-view__widgets__mediaitem-widget__meta-data views__dashboard-view__widgets__mediaitem-widget__meta-data--bottom">
        {ave ? <div>AVE {currency(ave)}</div> : null}
        <div>{`${t("reach")} ${reach ? formatInt(reach) : "-"}`}</div>
        {prValue ? <div>{`PR ${currency(prValue)}`}</div> : null}
        {sentiment ? (
          <div>
            <Icon
              style={{
                position: "relative",
                height: 12,
                width: 12,
                color: sentimentOptions[sentiment as ESentiment].color,
              }}
              icon="star"
              componentClass={sentimentOptions[sentiment as ESentiment].icon}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ItemBody;
