import {
  Badge,
  Button,
  ButtonToolbar,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Icon,
  IconButton,
  Popover,
  Tooltip,
  Whisper,
} from "rsuite";
import Prullie from "../../../../../../icons/Prullie";
import SwitchEditorialRubric from "../../../../../../icons/SwitchEditorialRubric";
import { Draggable } from "react-beautiful-dnd";
import React from "react";
import { components } from "../../../../../../types/openapi";
import _ from "lodash";
import Eye from "../../../../../../icons/Eye";
import axios from "axios";
import Drag from "../../../../../../icons/Drag";
import {
  DATE_FORMAT,
  localeFormat,
  parseDateWithOptionalTime,
} from "../../../../../../inc/date";
import { getMediaItemIcon } from "../../../../../../icons";
import { I18nContext } from "../../../../../../provider/I18nProvider";
import MediaItemGridItemSearchTopics from "../../../../../../components/MediaItemGridItemSearchTopics";
import MediaItemGridItemLabels from "../../../../../../components/MediaItemGridItemLabels";
import openapi from "../../../../../../openapi.json";

import "./index.scss";

const maxBodyLength =
  openapi["components"]["schemas"]["EditorialMediaItem"]["properties"]["body"]
    .maxLength;

interface IDraggableMediaItemProps {
  draggableId: string;
  editorialEditorialRubrics: components["schemas"]["EditorialRubric"][];
  editorialMediaItem: components["schemas"]["EditorialMediaItem"];
  isInEditorialRubricIds: string[];
  mediaItem: components["schemas"]["MediaItem"] | null;
  mediaItemIndex: number;
  addMediaItem: (editorialRubricId: string, mediaItemId: string) => void;
  removeMediaItem: (editorialRubricId: string, mediaItemId: string) => void;
  updateEditorialMediaItem: (
    editorialMediaItem: components["schemas"]["EditorialMediaItem"]
  ) => void;
  container: Element;
}

const DraggableMediaItem = (props: IDraggableMediaItemProps) => {
  const {
    addMediaItem,
    draggableId,
    editorialEditorialRubrics,
    isInEditorialRubricIds,
    mediaItem,
    mediaItemIndex,
    removeMediaItem,
    updateEditorialMediaItem,
    container,
  } = props;
  const { t } = React.useContext(I18nContext);
  const [isEditable, setIsEditable] = React.useState(false);
  const [editorialMediaItem, setEditorialMediaItem] = React.useState<
    components["schemas"]["EditorialMediaItem"]
  >(props.editorialMediaItem);
  const [selectedEditorialRubricIds, setSelectedEditorialRubricIds] =
    React.useState<string[]>();
  const [fetchingMediaItem, setFetchingMediaItem] =
    React.useState<boolean>(false);
  React.useEffect(() => {
    if (!isEditable) {
      setEditorialMediaItem(editorialMediaItem);
    }
  }, [isEditable, editorialMediaItem]);
  React.useEffect(() => {
    if (!selectedEditorialRubricIds) {
      return;
    }
    const handleClickOutsideMenu = (event: any) => {
      const popover = document.querySelector(".rs-popover");
      if (!popover || !popover.contains(event.target)) {
        setSelectedEditorialRubricIds(undefined);
        return;
      }
    };
    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [selectedEditorialRubricIds]);

  const openMediaItem = React.useCallback(() => {
    if (typeof window === "undefined" || fetchingMediaItem) {
      return;
    }
    setFetchingMediaItem(true);
    axios
      .get<components["schemas"]["MediaItem"]>(
        `/mediaItem/crud/${editorialMediaItem.mediaItemId as string}`
      )
      .then((res) => {
        setFetchingMediaItem(false);
        window.open(res.data.url, "_blank")?.focus();
      });
  }, [editorialMediaItem.mediaItemId, fetchingMediaItem]);

  const mediaItemId = editorialMediaItem.mediaItemId as string;
  const mediaItemIsUsedInMultipleRubrics = isInEditorialRubricIds.length > 1;

  return (
    <Draggable draggableId={draggableId} index={mediaItemIndex}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            top: snapshot.isDragging
              ? (provided.draggableProps.style as any)?.top - 30
              : undefined,
            left: "auto",
          }}
          className={`editorial-issue-create-modal__rubric__media-item${
            snapshot.isDragging
              ? " editorial-issue-create-modal__rubric__media-item--dragging"
              : ""
          }`}
        >
          <Form>
            <div
              className="editorial-issue-create-modal__rubric__media-item__drag-handle"
              {...provided.dragHandleProps}
            >
              <Drag />
            </div>

            <div className="editorial-issue-create-modal__rubric__media-item__image">
              {editorialMediaItem.imageUrl ? (
                <img
                  className="img-fluid"
                  src={editorialMediaItem.imageUrl}
                  alt={editorialMediaItem.title}
                />
              ) : null}
            </div>
            <div className="editorial-issue-create-modal__rubric__media-item__content">
              {isEditable ? (
                <FormGroup>
                  <ControlLabel>Titel</ControlLabel>
                  <FormControl
                    name="title"
                    value={editorialMediaItem.title}
                    className="w-100"
                    onChange={(title) => {
                      setEditorialMediaItem({
                        ...editorialMediaItem,
                        title,
                      });
                    }}
                  />
                </FormGroup>
              ) : (
                <>
                  <h4 className="editorial-issue-create-modal__rubric__media-item__content__title mb-0">
                    {editorialMediaItem.title}
                  </h4>
                  <div className="editorial-issue-create-modal__rubric__media-item__content__meta mb-3">
                    <div className="editorial-issue-create-modal__rubric__media-item__content__meta__item">
                      {localeFormat(
                        parseDateWithOptionalTime(
                          editorialMediaItem.publicationDate
                        ),
                        DATE_FORMAT
                      )}
                    </div>
                    <div className="editorial-issue-create-modal__rubric__media-item__content__meta__item">
                      {getMediaItemIcon(
                        editorialMediaItem as components["schemas"]["MediaItem"]
                      )}
                      <span>{editorialMediaItem.source}</span>
                    </div>
                  </div>
                </>
              )}
              {isEditable ? (
                <FormGroup>
                  <ControlLabel>Inhoud</ControlLabel>
                  <FormControl
                    rows={5}
                    name="body"
                    className="w-100"
                    componentClass="textarea"
                    value={editorialMediaItem.body}
                    onChange={(newBody) => {
                      setEditorialMediaItem({
                        ...editorialMediaItem,
                        body: newBody.substr(0, maxBodyLength),
                      });
                    }}
                  />
                  <HelpBlock>
                    Gebruikt {editorialMediaItem.body.length} van{" "}
                    {maxBodyLength} karakters
                  </HelpBlock>
                </FormGroup>
              ) : (
                <>
                  <p className="editorial-issue-create-modal__rubric__media-item__content__intro">
                    {editorialMediaItem.body}
                  </p>
                  {mediaItem ? (
                    <MediaItemGridItemLabels mediaItem={mediaItem} />
                  ) : null}
                  {mediaItem ? (
                    <MediaItemGridItemSearchTopics mediaItem={mediaItem} />
                  ) : null}
                </>
              )}
              {isEditable ? (
                <ButtonToolbar>
                  <Button
                    appearance="primary"
                    type="submit"
                    onClick={() => {
                      updateEditorialMediaItem(editorialMediaItem);
                      setIsEditable(false);
                    }}
                  >
                    {t("save")}
                  </Button>
                  <Button
                    appearance="default"
                    onClick={() => setIsEditable(false)}
                  >
                    {t("cancel")}
                  </Button>
                </ButtonToolbar>
              ) : null}
            </div>
            <ul className="list-unstyled editorial-issue-create-modal__rubric__media-item__actions">
              <li>
                <IconButton
                  size="xs"
                  appearance="subtle"
                  icon={<Icon icon="eye" componentClass={Eye} />}
                  onClick={openMediaItem}
                  disabled={fetchingMediaItem}
                />
              </li>
              <li>
                <IconButton
                  size="xs"
                  appearance="subtle"
                  icon={<Icon icon="pencil" />}
                  onClick={() => {
                    setIsEditable((isEditable) => !isEditable);
                  }}
                />
              </li>
              <li>
                <IconButton
                  size="xs"
                  appearance="subtle"
                  icon={<Icon icon="trash" componentClass={Prullie} />}
                  onClick={() => {
                    isInEditorialRubricIds.forEach((editorialRubricId) =>
                      removeMediaItem(editorialRubricId, mediaItemId)
                    );
                  }}
                />
              </li>
              {editorialEditorialRubrics.length > 1 ? (
                <li>
                  <Whisper
                    preventOverflow
                    placement="auto"
                    open={!!selectedEditorialRubricIds}
                    container={container as HTMLElement}
                    speaker={
                      <Popover
                        full
                        className="editorial-issue-create-modal__rubric__media-item__actions__editorial-rubrics-popover popover-without-arrow"
                      >
                        <div className="editorial-issue-create-modal__rubric__media-item__actions__editorial-rubrics-popover__rubrics">
                          {editorialEditorialRubrics.map((editorialRubric) => {
                            const isRubricSelected =
                              selectedEditorialRubricIds &&
                              selectedEditorialRubricIds.indexOf(
                                editorialRubric.editorialRubricId as string
                              ) >= 0;
                            return (
                              <Checkbox
                                key={editorialRubric.editorialRubricId}
                                disabled={
                                  isRubricSelected &&
                                  selectedEditorialRubricIds &&
                                  selectedEditorialRubricIds.length === 1
                                }
                                checked={isRubricSelected}
                                onClick={() => {
                                  if (!selectedEditorialRubricIds) {
                                    return;
                                  }
                                  const editorialRubricId =
                                    editorialRubric.editorialRubricId as string;
                                  setSelectedEditorialRubricIds(
                                    isRubricSelected
                                      ? selectedEditorialRubricIds.filter(
                                          (selectedEditorialRubricId) =>
                                            selectedEditorialRubricId !==
                                            editorialRubricId
                                        )
                                      : [
                                          ...selectedEditorialRubricIds,
                                          editorialRubricId,
                                        ]
                                  );
                                }}
                              >
                                {editorialRubric.name}
                              </Checkbox>
                            );
                          })}
                        </div>
                        <Button
                          block
                          disabled={
                            JSON.stringify(isInEditorialRubricIds) ===
                            JSON.stringify(selectedEditorialRubricIds)
                          }
                          onClick={() => {
                            if (!selectedEditorialRubricIds) {
                              return;
                            }
                            _.difference(
                              selectedEditorialRubricIds,
                              isInEditorialRubricIds
                            ).forEach((editorialRubricId) => {
                              addMediaItem(editorialRubricId, mediaItemId);
                            });
                            _.difference(
                              isInEditorialRubricIds,
                              selectedEditorialRubricIds
                            ).forEach((editorialRubricId) => {
                              removeMediaItem(editorialRubricId, mediaItemId);
                            });
                            setSelectedEditorialRubricIds(undefined);
                          }}
                        >
                          {t("toApply")}
                        </Button>
                      </Popover>
                    }
                  >
                    <Whisper
                      placement="top"
                      trigger={
                        mediaItemIsUsedInMultipleRubrics ? "hover" : "none"
                      }
                      speaker={
                        <Tooltip>
                          {t("thisMediaItemIsUsedInMultipleRubrics")}
                        </Tooltip>
                      }
                    >
                      <Button
                        appearance="subtle"
                        componentClass="a"
                        className="mt-2 p-0 overflow-visible"
                        onClick={() => {
                          setSelectedEditorialRubricIds(isInEditorialRubricIds);
                        }}
                      >
                        <div>
                          <Badge
                            content={
                              mediaItemIsUsedInMultipleRubrics ? "!" : false
                            }
                          >
                            <SwitchEditorialRubric
                              style={{
                                marginLeft: 1,
                              }}
                            />
                          </Badge>
                        </div>
                      </Button>
                    </Whisper>
                  </Whisper>
                </li>
              ) : null}
            </ul>
          </Form>
        </li>
      )}
    </Draggable>
  );
};

export default DraggableMediaItem;
