import React, { SVGProps } from "react";

const Signal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14.814}
    viewBox="0 0 14 14.814"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Group_1215" transform="translate(-1575.409 -618.265)">
      <path
        id="prefix__Path_579"
        d="M1590.3 630.188a1.77 1.77 0 111.77-1.77 1.772 1.772 0 01-1.77 1.77zm0-2.676a.907.907 0 10.906.907.908.908 0 00-.906-.908z"
        transform="translate(-7.895 -5.059)"
      />
      <path
        id="prefix__Path_580"
        d="M1593.257 645.319a.431.431 0 01-.432-.432v-4.907a.432.432 0 01.863 0v4.907a.431.431 0 01-.431.432z"
        transform="translate(-10.848 -12.24)"
      />
      <path
        id="prefix__Path_581"
        d="M1600.8 628.964a.432.432 0 01-.305-.737 2.9 2.9 0 000-4.1.432.432 0 01.61-.61 3.758 3.758 0 010 5.316.43.43 0 01-.305.131z"
        transform="translate(-15.385 -3.53)"
      />
      <path
        id="prefix__Path_582"
        d="M1583.562 628.964a.429.429 0 01-.3-.126 3.758 3.758 0 010-5.316.431.431 0 11.61.61 2.9 2.9 0 000 4.1.432.432 0 01-.305.737z"
        transform="translate(-4.159 -3.53)"
      />
      <path
        id="prefix__Path_583"
        d="M1577.478 627.034a.43.43 0 01-.305-.126 6.022 6.022 0 010-8.516.432.432 0 01.61.61 5.159 5.159 0 000 7.3.432.432 0 01-.305.737z"
      />
      <path
        id="prefix__Path_584"
        d="M1605.422 627.034a.432.432 0 01-.305-.737 5.158 5.158 0 000-7.3.432.432 0 11.611-.61 6.022 6.022 0 010 8.516.431.431 0 01-.306.131z"
        transform="translate(-18.082)"
      />
    </g>
  </svg>
);
export default Signal;
