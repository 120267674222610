import React, { SVGProps } from "react";

const Compass = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
  const { title, ...svgProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Zoekopdracht_icoon"
      width={18.027}
      height={18.027}
      viewBox="0 0 18.027 18.027"
      fill="currentColor"
      {...svgProps}
    >
      {title ? <title>{title}</title> : null}
      <g id="prefix__Group_1426" transform="translate(-1721.243 -614.859)">
        <path
          id="prefix__Path_1696"
          d="M1733.107 634.7a.968.968 0 01-1.233-1.279l2.1-5.041a.969.969 0 01.555-.535l5.24-1.956a.968.968 0 011.231 1.281l-2.089 5.016a1.035 1.035 0 01-.592.57z"
          transform="translate(-6.181 -6.424)"
        />
        <circle
          id="prefix__Ellipse_293"
          cx={1.363}
          cy={1.363}
          r={1.363}
          fill="#fff"
          transform="translate(1728.894 622.509)"
        />
        <path
          id="prefix__Path_1697"
          d="M1730.256 632.886a9.013 9.013 0 116.377-2.637 8.99 8.99 0 01-6.377 2.637zm0-16.88a7.867 7.867 0 105.565 2.3 7.848 7.848 0 00-5.564-2.301z"
        />
      </g>
    </svg>
  );
};

export default Compass;
