import React, { SVGProps } from "react";

const CopyLink = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="currentColor"
      {...props}
    >
      <path
        id="prefix__Path_1897"
        d="M32.756 49.387a.688.688 0 01.972.974q-1.49 1.486-1.646 1.639l-.985.985a3.957 3.957 0 01-5.6-5.6l3.896-3.885a3.957 3.957 0 015.6 0 .688.688 0 01-.973.973 2.579 2.579 0 00-3.649 0l-3.893 3.893a2.58 2.58 0 003.649 3.649l.986-.985 1.646-1.644z"
        transform="translate(-24.342 -36.149)"
      />
      <path
        id="prefix__Path_1898"
        d="M47.56 29.1a.688.688 0 01-.972-.974l1.645-1.642.985-.985a3.957 3.957 0 015.6 5.6l-3.895 3.891a3.957 3.957 0 01-5.6 0 .688.688 0 01.973-.973 2.579 2.579 0 003.649 0l3.893-3.893a2.58 2.58 0 00-3.649-3.649l-.986.985-1.643 1.64z"
        transform="translate(-37.975 -24.342)"
      />
    </svg>
  );
};

export default CopyLink;
