import * as React from "react";
import { SVGProps } from "react";

const Invoice = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.976}
    height={15.421}
    viewBox="0 0 12.976 15.421"
    fill="currentColor"
    {...props}
  >
    <path
      d="M1365 195.61l-1.817-1.659c-.009-.008-.021-.012-.031-.019a.514.514 0 00-.116-.072.548.548 0 00-.055-.02.532.532 0 00-.125-.023c-.012 0-.023-.007-.035-.007h-8.412a.537.537 0 00-.537.537v3.575a.537.537 0 001.073 0v-3.039h7.339v1.279a.537.537 0 00.537.537h1.281v11.458h-9.156v-.782a.537.537 0 00-1.073 0v1.318a.537.537 0 00.537.537h10.23a.537.537 0 00.537-.537v-12.687a.535.535 0 00-.177-.396z"
      transform="translate(-1352.203 -193.811)"
    />
    <path
      d="M1353.345 214.372a2.729 2.729 0 001.662-.575.423.423 0 00-.513-.673 1.888 1.888 0 01-1.148.4 2.181 2.181 0 01-1.936-1.444h2.52a.423.423 0 100-.847h-2.742a2.976 2.976 0 010-.781h2.678a.423.423 0 100-.847h-2.456a2.182 2.182 0 011.936-1.443 1.888 1.888 0 011.148.4.423.423 0 00.513-.674 2.728 2.728 0 00-1.662-.575 3.093 3.093 0 00-2.835 2.289h-.522a.423.423 0 100 .847h.346a3.877 3.877 0 000 .782h-.346a.425.425 0 00-.423.424.424.424 0 00.423.423h.522a3.092 3.092 0 002.835 2.294z"
      transform="translate(-1349.564 -202.08)"
    />
  </svg>
);

export default Invoice;
