import {
  Button,
  Divider,
  Icon,
  IconButton,
  Modal,
  Panel,
  SelectPicker,
} from "rsuite";
import Times from "../../../icons/Times";
import Plus from "../../../icons/Plus";
import { BootstrapSize } from "../../../inc/constants";
import ActionButton from "../../../components/ActionButton";
import MediaType from "../../../icons/MediaType";
import AsyncPopover from "../../../components/AsyncPopover";
import NumberBadge from "../../../components/NumberBadge";
import Signal from "../../../icons/Signal";
import React from "react";
import { components } from "../../../types/openapi";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { I18nContext } from "../../../provider/I18nProvider";
import AnchoredLightboxedItem from "../../../components/AnchoredLightboxedItem";
import BenchmarkMatchTextPanel from "./BenchmarkMatchTextPanel";
import MwTag from "../../../components/MwTag";
import { TBenchmark } from "../../../types";
import openapi from "../../../openapi.json";
import { getEmptyObject } from "../../../inc/schema";
import { oas30 } from "openapi3-ts";
import { useHistory } from "react-router-dom";
import { TBenchmarkHashData } from "../../../components/benchmarkWidgets";
import { isEqual } from "lodash";
import SearchEngineDateRange from "../../../components/SearchEngineDateRange";

import "./index.scss";

interface ISearchEngineRequestsBarProps {
  benchmark: TBenchmark;
  disabled?: boolean;
  isLoading: boolean;
}

const BenchmarkBar = ({
  benchmark,
  disabled,
}: ISearchEngineRequestsBarProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);

  const [formMatchTexts, setFormMatchTexts] = React.useState<
    components["schemas"]["BenchmarkMatchText"][]
  >([]);
  const [formSearchEngineRequest, setFormSearchEngineRequest] = React.useState<
    components["schemas"]["SearchEngineRequest"]
  >(benchmark.searchEngineRequest);

  React.useEffect(() => {
    setFormMatchTexts(benchmark.matchTexts);
  }, [benchmark.matchTexts]);

  React.useEffect(() => {
    setFormSearchEngineRequest(benchmark.searchEngineRequest);
  }, [benchmark.searchEngineRequest]);

  const [editBenchmarkMatchTextConfig, setEditBenchmarkMatchTextConfig] =
    React.useState<
      | {
          x: number;
          y: number;
          matchTextIndex: number;
          matchText: components["schemas"]["BenchmarkMatchText"];
        }
      | undefined
    >();
  const [
    isSearchEngineRequestsBarExpanded,
    setIsSearchEngineRequestsBarExpanded,
  ] = React.useState(false);

  const sourcesWhisperRef = React.useRef<any>();
  const history = useHistory();

  const showInputIcon = windowOuterWidth > BootstrapSize.MD;
  const selectedSourceCount = formSearchEngineRequest
    ? (formSearchEngineRequest.query.includeSourcesIds || []).length
    : 0;

  const selectedMediaTypes = React.useMemo(() => {
    const selectedMediaTypes = [];
    if (!formSearchEngineRequest) {
    }
    if (
      formSearchEngineRequest.query.includeMediaWeb ===
      formSearchEngineRequest.query.includeMediaPrint
    ) {
      selectedMediaTypes.push("web", "print");
    } else {
      selectedMediaTypes.push(
        formSearchEngineRequest.query.includeMediaWeb ? "web" : "print",
      );
    }
    return selectedMediaTypes;
  }, [formSearchEngineRequest]);

  const onEditMatchTextButtonClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      if (editBenchmarkMatchTextConfig) {
        setEditBenchmarkMatchTextConfig(undefined);
      }
      const button = e.target as HTMLButtonElement;
      if (!button) {
        console.log("Could not detect anchor button");
        return;
      }

      const rect = button.getBoundingClientRect();
      let matchTextIndex: number;
      let matchText: components["schemas"]["BenchmarkMatchText"];
      if (button.dataset.index === undefined) {
        matchTextIndex = -1;
        matchText = getEmptyObject(
          openapi.components.schemas.BenchmarkMatchText as oas30.SchemaObject,
        );
      } else {
        matchTextIndex = parseInt(button.dataset.index);
        matchText = { ...formMatchTexts[matchTextIndex] };
      }

      setEditBenchmarkMatchTextConfig({
        x: Math.round(rect.x),
        y: Math.round(rect.y + rect.height + 4),
        matchTextIndex,
        matchText,
      });
    },
    [editBenchmarkMatchTextConfig, formMatchTexts],
  );

  const onMatchTextDeleteClick = React.useCallback(
    (e: any) => {
      const name = e.target.parentNode.innerText;
      setFormMatchTexts(formMatchTexts.filter((x) => x.name !== name));
    },
    [formMatchTexts],
  );

  const onSearchEngineRequestChange = React.useCallback(
    (formSearchEngineRequest: components["schemas"]["SearchEngineRequest"]) => {
      setFormSearchEngineRequest(formSearchEngineRequest);
    },
    [],
  );

  const startFilter = React.useCallback(() => {
    const hashData: TBenchmarkHashData = {
      matchTexts: formMatchTexts,
      searchEngineRequest: formSearchEngineRequest,
      sort: openapi.components.schemas.MediaItemSortType
        .default as components["schemas"]["MediaItemSortType"],
    };
    history.push(
      `${history.location.pathname}#${encodeURIComponent(
        JSON.stringify(hashData),
      )}`,
    );
  }, [formMatchTexts, formSearchEngineRequest, history]);

  const isFormDirty = React.useMemo(
    () =>
      !isEqual(benchmark.matchTexts, formMatchTexts) ||
      !isEqual(benchmark.searchEngineRequest, formSearchEngineRequest),
    [
      benchmark.matchTexts,
      benchmark.searchEngineRequest,
      formMatchTexts,
      formSearchEngineRequest,
    ],
  );

  const resetForm = React.useCallback(() => {
    setFormMatchTexts(benchmark.matchTexts);
    setFormSearchEngineRequest(benchmark.searchEngineRequest);
  }, [benchmark.matchTexts, benchmark.searchEngineRequest]);

  React.useEffect(() => {
    if (!isFormDirty) {
      return;
    }
    const handleClickOutsideModal = (event: any) => {
      const benchmarkBarContainer = document.querySelector(
        ".benchmark-bar-container",
      );
      const benchmarkBarPanel = document.querySelector(".benchmark-bar__panel");
      // If you click outside the benchmark-bar-container
      // and there aren't any open rs menus
      if (
        (!benchmarkBarContainer ||
          !benchmarkBarContainer.contains(event.target)) &&
        (!benchmarkBarPanel || !benchmarkBarPanel.contains(event.target)) &&
        !document.querySelectorAll(".rs-popover,.rs-picker-menu").length
      ) {
        resetForm();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideModal);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [isFormDirty, resetForm]);

  return (
    <div
      className={`benchmark-bar-container${
        isFormDirty ? " benchmark-bar-container--dirty" : ""
      }`}
    >
      {isFormDirty ? (
        <Modal
          show
          onHide={resetForm}
          dialogClassName="d-none"
          backdrop="static"
        />
      ) : null}
      {isFormDirty && !editBenchmarkMatchTextConfig ? (
        <div className="benchmark-bar__apply-button">
          <Button
            appearance="primary"
            size="lg"
            className="border border-light"
            onClick={startFilter}
          >
            {t("toApply")}
          </Button>
        </div>
      ) : null}
      <Panel className="benchmark-bar" shaded>
        <div className="row justify-content-between align-items-center">
          <div
            className="col-12 col-xl order-xl-2 benchmark-bar__global-col"
            style={{ flexGrow: 0 }}
          >
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col col-xl-auto order-xl-2">
                <SearchEngineDateRange
                  searchEngineRequest={formSearchEngineRequest}
                  onChange={disabled ? undefined : onSearchEngineRequestChange}
                />
              </div>
              <div className="col-auto d-md-none">
                <IconButton
                  className="benchmark-bar__menu-toggle"
                  circle
                  appearance="subtle"
                  icon={
                    <Icon
                      icon="star"
                      componentClass={
                        isSearchEngineRequestsBarExpanded ? Times : Plus
                      }
                    />
                  }
                  size="sm"
                  onClick={() =>
                    setIsSearchEngineRequestsBarExpanded(
                      !isSearchEngineRequestsBarExpanded,
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={`col-12 col-xl-auto order-xl-1 benchmark-bar__value-col ${
              isSearchEngineRequestsBarExpanded ||
              windowOuterWidth >= BootstrapSize.MD
                ? "open"
                : ""
            }`}
            style={{ flex: 1 }}
          >
            <Panel
              expanded={
                isSearchEngineRequestsBarExpanded ||
                windowOuterWidth >= BootstrapSize.MD
              }
              className="benchmark-bar__value-col__panel"
              style={{ display: "flex" }}
            >
              <div className="d-xl-flex flex-grow-1 align-items-center">
                <div className="flex-grow-1">
                  {formMatchTexts.map((benchmarkMatchText, index) => (
                    <MwTag
                      onClose={disabled ? undefined : onMatchTextDeleteClick}
                      size="lg"
                      color="cyan"
                      key={benchmarkMatchText.name}
                    >
                      {benchmarkMatchText.name}
                      {disabled ? null : (
                        <IconButton
                          icon={
                            <Icon icon="pencil" inverse data-index={index} />
                          }
                          circle
                          appearance={"subtle"}
                          className="ms-2"
                          size="xs"
                          onClick={onEditMatchTextButtonClick}
                        />
                      )}
                    </MwTag>
                  ))}
                  {disabled ? null : (
                    <ActionButton
                      className="benchmark-bar__action-button"
                      placement="left"
                      title={t("matchTextPlaceholder")}
                      onClick={onEditMatchTextButtonClick}
                      disabled={
                        formMatchTexts.length >=
                        openapi.components.schemas.Benchmark.properties
                          .matchTexts.maxItems
                      }
                    />
                  )}
                </div>
                <SelectPicker
                  cleanable={true}
                  disabled={disabled}
                  value={
                    selectedMediaTypes.length === 1
                      ? selectedMediaTypes[0]
                      : null
                  }
                  onChange={(filterMediaType) => {
                    onSearchEngineRequestChange({
                      ...formSearchEngineRequest,
                      query: {
                        ...formSearchEngineRequest.query,
                        includeMediaPrint:
                          !filterMediaType || filterMediaType === "print",
                        includeMediaWeb:
                          !filterMediaType || filterMediaType === "web",
                      },
                    });
                  }}
                  placeholder={
                    <div>
                      <MediaType /> {t("mediaType")}
                    </div>
                  }
                  searchable={false}
                  data={[
                    { value: "print", label: "Print" },
                    { value: "web", label: "Web" },
                  ]}
                  renderValue={(_value: string, selectedOption: any) => {
                    return (
                      <div>
                        <MediaType /> {selectedOption.label}
                      </div>
                    );
                  }}
                  className={`benchmark-bar__value-col__button ${
                    showInputIcon ? "" : "without-icon"
                  }`}
                  size="xs"
                  appearance="subtle"
                  icon={
                    showInputIcon ? (
                      <Icon icon="question" componentClass={MediaType} />
                    ) : undefined
                  }
                />
                <Divider vertical />
                <AsyncPopover
                  key="sourceNames"
                  dataUrl="/source/crud?forSearchEngine=true"
                  nameProp="name"
                  valueProp="sourceId"
                  whisperRef={sourcesWhisperRef}
                  values={formSearchEngineRequest.query.includeSourcesIds || []}
                  onChange={
                    disabled
                      ? undefined
                      : (sourceNames) => {
                          onSearchEngineRequestChange({
                            ...formSearchEngineRequest,
                            query: {
                              ...formSearchEngineRequest.query,
                              includeSourcesIds: sourceNames,
                            },
                          });
                        }
                  }
                >
                  <span className={"benchmark-bar__source-button-container"}>
                    <NumberBadge number={selectedSourceCount}>
                      <IconButton
                        className={`benchmark-bar__filters-col__button ${
                          showInputIcon ? "" : "without-icon"
                        }`}
                        disabled={disabled}
                        appearance="subtle"
                        size="xs"
                        icon={
                          showInputIcon ? (
                            <Icon icon="question" componentClass={Signal} />
                          ) : undefined
                        }
                      >
                        {t("sources")}
                        <span
                          className={
                            selectedSourceCount
                              ? "rs-picker-toggle-clean"
                              : "d-none"
                          }
                          role="button"
                          tabIndex={-1}
                          onClick={() => {
                            onSearchEngineRequestChange({
                              ...formSearchEngineRequest,
                              query: {
                                ...formSearchEngineRequest.query,
                                includeSourcesIds: [],
                              },
                            });
                          }}
                        >
                          ✕
                        </span>
                      </IconButton>
                    </NumberBadge>
                  </span>
                </AsyncPopover>
                <Divider vertical className="d-none d-xl-inline-block" />
              </div>
            </Panel>
          </div>
        </div>
        {editBenchmarkMatchTextConfig ? (
          <AnchoredLightboxedItem
            anchorPosition="topLeft"
            className="benchmark-match-text-panel-container"
            anchorPoint={editBenchmarkMatchTextConfig}
            onClose={() => {
              setEditBenchmarkMatchTextConfig(undefined);
            }}
          >
            <BenchmarkMatchTextPanel
              onChange={(
                updatedMatchText: components["schemas"]["BenchmarkMatchText"],
              ) => {
                setEditBenchmarkMatchTextConfig(
                  (editBenchmarkMatchTextConfig) =>
                    editBenchmarkMatchTextConfig
                      ? {
                          ...editBenchmarkMatchTextConfig,
                          matchText: updatedMatchText,
                        }
                      : editBenchmarkMatchTextConfig,
                );
              }}
              value={editBenchmarkMatchTextConfig.matchText}
              onClose={(newBenchmarkMatchText) => {
                if (benchmark && newBenchmarkMatchText) {
                  const newMatchTexts = [...formMatchTexts];
                  if (editBenchmarkMatchTextConfig.matchTextIndex >= 0) {
                    newMatchTexts[editBenchmarkMatchTextConfig.matchTextIndex] =
                      newBenchmarkMatchText;
                  } else {
                    newMatchTexts.push(newBenchmarkMatchText);
                  }
                  setFormMatchTexts(newMatchTexts);
                }
                setEditBenchmarkMatchTextConfig(undefined);
              }}
            />
          </AnchoredLightboxedItem>
        ) : null}
      </Panel>
    </div>
  );
};

export default React.memo(BenchmarkBar);
