import React from "react";
import { TypeAttributes } from "rsuite/lib/@types/common";
import LabelIcon from "../../icons/Label";
import "./index.scss";
import { components } from "../../types/openapi";
import MwTag from "../MwTag";

interface ILabelTagProps {
  isExcluded?: boolean;
  onClose?: () => void;
  onClick?: () => void;
  color?: TypeAttributes.Color;
  size?: "sm" | "lg";
  rounded?: boolean;
  label: components["schemas"]["Label"];
  className?: string;
}

const LabelTag = (props: ILabelTagProps) => {
  const {
    isExcluded,
    size = "sm",
    onClose,
    onClick,
    color,
    label,
    rounded,
    className = "",
  } = props;

  const tagClassNames: string[] = [className];
  if (isExcluded) {
    tagClassNames.push("components__label-tag--excluded");
  }

  return (
    <MwTag
      className={tagClassNames.join(" ")}
      onClose={onClose}
      onClick={onClick}
      color={color}
      size={size}
      rounded={rounded}
      iconComponent={LabelIcon}
    >
      {label.name}
    </MwTag>
  );
};
export default LabelTag;
