import { Layout } from "react-grid-layout";
import React from "react";
import { LayoutContext } from "../../provider/LayoutProvider";
import ReactGA from "react-ga";
import { components } from "../../types/openapi";
import { Loader } from "rsuite";
import { BootstrapSize } from "../../inc/constants";
import DragHandle from "../DragHandle";
import BenchmarkResultBenchmarkWidget from "./BenchmarkResultBenchmarkWidget";
import WidgetTypeMediaItem from "../../icons/WidgetTypeMediaItem";
import HightlightBenchmarkWidget from "./HighlightBenchmarkWidget";
import WidgetTypeHighlight from "../../icons/WidgetTypeHighlight";
import MediaTypeBenchmarkWidget from "./MediaTypeBenchmarkWidget";
import WidgetTypeMedia from "../../icons/WidgetTypeMedia";
import { EChartType } from "../../inc/enums";
import { TBenchmark } from "../../types";
import WidgetTypeSource from "../../icons/WidgetTypeSource";
import SourceBenchmarkWidget from "./SourceBenchmarkWidget";
import TrendBenchmarkWidget from "./TrendBenchmarkWidget";
import WidgetTypeTrend from "../../icons/WidgetTypeTrend";
import { v4 } from "uuid";
import AuthorBenchmarkWidget from "./AuthorBenchmarkWidget";
import WidgetTypeAuthor from "../../icons/WidgetTypeAuthor";
import CategoryBenchmarkWidget from "./CategoryBenchmarkWidget";
import WidgetTypeCategory from "../../icons/WidgetTypeCategory";

export enum EBenchmarkWidgetType {
  WIDGET_TYPE_MEDIAITEM = "WIDGET_TYPE_MEDIAITEM",
  WIDGET_TYPE_HIGHLIGHT = "WIDGET_TYPE_HIGHLIGHT",
  WIDGET_TYPE_TREND = "WIDGET_TYPE_TREND",
  WIDGET_TYPE_MEDIA_TYPE = "WIDGET_TYPE_MEDIA_TYPE",
  WIDGET_TYPE_SOURCE = "WIDGET_TYPE_SOURCE",
  WIDGET_TYPE_AUTHOR = "WIDGET_TYPE_AUTHOR",
  WIDGET_TYPE_CATEGORY = "WIDGET_TYPE_CATEGORY",
}

export interface IBenchmarkWidget<T = any> {
  uid: string;
  type: EBenchmarkWidgetType;
  layouts: {
    xs: Omit<Layout, "i">;
    lg: Omit<Layout, "i">;
  };
  widgetConfig: T;
}

interface IBenchmarkWidgetContainerProps {
  benchmark: TBenchmark;
  children?: React.ReactNode;
  className?: string;
  benchmarkWidth: number;
  onDelete?: () => void;
  onSettingsChange?: (widgetUid: string, newSettings: any) => void;
  style?: React.CSSProperties;
  widget: IBenchmarkWidget;
}

export type TBenchmarkHashData = {
  sort: components["schemas"]["MediaItemSortType"];
} & Pick<
  components["schemas"]["Benchmark"],
  "matchTexts" | "searchEngineRequest"
>;

export interface IBenchmarkWidgetProps<Settings> {
  benchmark: TBenchmark;
  colWidth: number;
  height: number;
  onDelete?: () => void;
  onNotesChange: (newNotes: string) => void;
  onSettingsChange?: (widgetUid: string, newSettings: Settings) => void;
  onSettingsToggle?: (isOpen: boolean) => void;
  settings?: Settings;
  uid: string;
  width: number;
}

export enum EBenchmarkSplitType {
  none = "none",
  web = "web",
  print = "print",
  category = "category",
  author = "author",
}

export enum EValueType {
  count = "count",
  summedAve = "summedAve",
  summedReach = "summedReach",
}

interface IBenchmarkWidgetDefinition<T = any> {
  component: (props: IBenchmarkWidgetProps<any>) => JSX.Element;
  menuIcon: React.ElementType<React.SVGProps<SVGSVGElement>>;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  defaultW: number;
  defaultH: number;
  widgetConfig: T;
}

export const newBenchmarkWidget = (
  type: EBenchmarkWidgetType,
  widgetConfig?: unknown,
): IBenchmarkWidget => {
  const widgetType = benchmarkWidgetDefinitions[type];
  return {
    ...widgetType,
    type,
    uid: v4(),
    layouts: {
      xs: {
        x: 0,
        y: 0,
        w: widgetType.defaultW,
        h: widgetType.defaultH,
      },
      lg: {
        x: 0,
        y: 0,
        w: widgetType.defaultW,
        h: widgetType.defaultH,
      },
    },
    widgetConfig,
  };
};

export const benchmarkWidgetDefinitions: {
  [widgetId: string]: IBenchmarkWidgetDefinition;
} = {
  [EBenchmarkWidgetType.WIDGET_TYPE_MEDIAITEM]: {
    component: BenchmarkResultBenchmarkWidget,
    menuIcon: WidgetTypeMediaItem,
    minW: 1,
    maxW: 3,
    minH: 3,
    defaultW: 3,
    defaultH: 8,
    widgetConfig: {
      display: "block",
      sort: "publicationDateDesc",
      columnLayout: [],
    },
  } as IBenchmarkWidgetDefinition,
  [EBenchmarkWidgetType.WIDGET_TYPE_HIGHLIGHT]: {
    component: HightlightBenchmarkWidget,
    menuIcon: WidgetTypeHighlight,
    minW: 1,
    maxW: 3,
    minH: 3,
    maxH: 9,
    defaultW: 2,
    defaultH: 4,
    widgetConfig: {},
  },
  [EBenchmarkWidgetType.WIDGET_TYPE_MEDIA_TYPE]: {
    component: MediaTypeBenchmarkWidget,
    menuIcon: WidgetTypeMedia,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      showValueTypeInTitle: false,
    },
  } as IBenchmarkWidgetDefinition,
  [EBenchmarkWidgetType.WIDGET_TYPE_SOURCE]: {
    component: SourceBenchmarkWidget,
    menuIcon: WidgetTypeSource,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
  [EBenchmarkWidgetType.WIDGET_TYPE_TREND]: {
    component: TrendBenchmarkWidget,
    menuIcon: WidgetTypeTrend,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 3,
    defaultH: 8,
    widgetConfig: {
      chartType: EChartType.curved,
      valueType: EValueType.count,
      showValueTypeInTitle: false,
      limit: 5,
      showRemaining: false,
    },
  },
  [EBenchmarkWidgetType.WIDGET_TYPE_AUTHOR]: {
    component: AuthorBenchmarkWidget,
    menuIcon: WidgetTypeAuthor,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
  [EBenchmarkWidgetType.WIDGET_TYPE_CATEGORY]: {
    component: CategoryBenchmarkWidget,
    menuIcon: WidgetTypeCategory,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
};

export const BenchmarkWidgetContainer = React.forwardRef(
  (
    props: IBenchmarkWidgetContainerProps,
    ref: React.LegacyRef<HTMLDivElement>,
  ) => {
    const { windowOuterWidth } = React.useContext(LayoutContext);

    const {
      benchmark,
      benchmarkWidth,
      onDelete,
      onSettingsChange,
      widget,
      ...divProps
    } = props;
    const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
      if (isSettingsOpen) {
        ReactGA.event({
          category: "Widgets",
          action: `Settings geopend`,
        });
      }
    }, [isSettingsOpen]);

    const widgetDefinition = benchmarkWidgetDefinitions[widget.type];
    let width = -1;
    if (props.style?.width) {
      width = parseInt(`${props.style.width}`, 10);
      if (width > benchmarkWidth - 20) {
        props.style.width = `${benchmarkWidth - 20}px`;
      }
    }
    let height = -1;
    if (props.style?.height) {
      height = parseInt(`${props.style.height}`, 10) - 23;
    }

    const colWidth = React.useMemo(
      () =>
        windowOuterWidth >= BootstrapSize.LG
          ? Math.ceil((width / windowOuterWidth) * 3)
          : 1,
      [width, windowOuterWidth],
    );

    const onNotesChange = React.useCallback(
      (notes: string) => {
        if (!onSettingsChange) {
          return;
        }
        onSettingsChange(widget.uid, { ...widget.widgetConfig, notes });
      },
      [onSettingsChange, widget.uid, widget.widgetConfig],
    );

    return (
      <div
        {...divProps}
        className={`${divProps.className} widget ${
          isSettingsOpen ? "widget--open-settings" : ""
        }`}
        ref={ref}
      >
        {widgetDefinition ? (
          <>
            <DragHandle />
            <widgetDefinition.component
              {...widget.widgetConfig}
              benchmark={benchmark}
              colWidth={colWidth}
              height={height}
              onDelete={onDelete}
              onNotesChange={onSettingsChange ? onNotesChange : undefined}
              onSettingsChange={onSettingsChange}
              onSettingsToggle={setIsSettingsOpen}
              settings={widget.widgetConfig}
              uid={widget.uid}
              width={width}
            />
          </>
        ) : (
          <Loader size="md" backdrop vertical />
        )}
        {/* <!-- The drag handle --> */}
        {props.children}
      </div>
    );
  },
);
