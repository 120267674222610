import React from "react";
import { Table, Icon, Input, InputGroup, Checkbox, Loader } from "rsuite";
import "./index.less";
import { oas30 } from "openapi3-ts";

import { getReferencedSchema, getReferencedSchemaName } from "../../inc/schema";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { LayoutContext } from "../../provider/LayoutProvider";
import CheckboxCell from "./CheckboxCell";
import { currency, formatInt } from "../../inc/numbers";
import { DATE_FORMAT, DATE_TIME_FORMAT, localeFormat } from "../../inc/date";
import { I18nContext } from "../../provider/I18nProvider";
import { TableInstance } from "rsuite/lib/Table/Table";
import { elementHasClassName } from "../../inc/dom";
import ActionButton from "../ActionButton";
import Export from "../../icons/Export";
import { debounce, lowerFirst } from "lodash";

const { Column, HeaderCell, Cell } = Table;

export interface IColumnConfig<T> {
  align?: "center";
  flexGrow?: number;
  hidden?: boolean;
  hoverTitle?: string;
  name: keyof T | string;
  renderCell?: (
    rowData: T,
    index: number,
  ) => string | React.ReactElement | null;
  renderData?: (rowData: T, index: number) => string;
  sort?: (a: T, b: T, sortAsc: boolean) => number;
  sortByValue?: boolean;
  sortable?: boolean;
  title?: string | React.ReactElement;
  width?: number;
}

interface ISchemaTableProps<T> {
  actionBar?: JSX.Element;
  autoHeight?: boolean;
  children?: React.ReactNode;
  columnsConfigs?: IColumnConfig<T>[];
  dataFilter?: (data: IRenderData[], query: string) => IRenderData[];
  dataSorter?: (a: T, b: T, query: string) => number;
  height?: number;
  isUnconfiguredHidden?: boolean;
  rowHeight?: number;
  onExport?: (rows: T[]) => void;
  onResize?: (width: number, propName: string) => void;
  onRowClick?: (rowData: T, event: MouseEvent) => void;
  onRowsRendered?: (obj: { startIndex: number; stopIndex: number }) => void;
  onScroll?: (
    scrollX: number,
    scrollY: number,
    table: TableInstance,
    previousY: number,
  ) => void;
  schema: oas30.SchemaObject;
  data?: (T | null | undefined)[];
  globalSearch?: boolean;
  getSelected?: (item: T, index: number) => boolean;
  setSelected?: (item: T, selected: boolean, index: number) => void;
  getSelectAll?: () => boolean;
  setSelectAll?: (selected: boolean) => void;
  isCheckboxDisabled?: (item: T) => boolean;
  sortable?: boolean;
  resizable?: boolean;
  loading?: boolean;
  rowClassName?: (rowData: T) => string;
  pagination?: string;
}

export interface IRenderData {
  _index: number;
  [key: string]: any;
}

export default function SchemaTable<T>({
  actionBar,
  autoHeight,
  children,
  columnsConfigs,
  data,
  dataFilter,
  dataSorter,
  height,
  isUnconfiguredHidden,
  rowHeight,
  schema,
  globalSearch,
  onExport,
  onScroll,
  onResize,
  onRowClick,
  onRowsRendered,
  getSelected,
  setSelected,
  getSelectAll,
  setSelectAll,
  isCheckboxDisabled,
  sortable,
  resizable,
  loading,
  rowClassName,
}: ISchemaTableProps<T>) {
  // useWhyDidYouUpdate(props);
  const apiDataContext = React.useContext(ApiDataContext);
  const { windowInnerHeight } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);

  const [sortColumn, setSortColumn] = React.useState<keyof T>();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [sortAsc, setSortAsc] = React.useState<boolean>(false);
  const [renderData, setRenderData] = React.useState<IRenderData[]>();
  const [layoutInitialized, setLayoutInitialized] =
    React.useState<boolean>(false);

  const tableRef = React.useRef<TableInstance>(null);
  const lastPosYRef = React.useRef(0);
  const renderedSetRef = React.useRef(new Set<number>());
  const properties = React.useMemo(() => schema.properties || {}, [schema]);

  const flush = React.useMemo(
    () =>
      debounce(() => {
        if (!onRowsRendered || !renderedSetRef.current.size) {
          return;
        }
        const rowIndexes = [...renderedSetRef.current.values()];
        const startIndex = Math.min(...rowIndexes);
        const stopIndex = Math.max(...rowIndexes);
        renderedSetRef.current.clear();
        if (onRowsRendered) {
          onRowsRendered({ startIndex, stopIndex });
        }
      }),
    [onRowsRendered],
  );

  const config = React.useMemo<IColumnConfig<T>[]>(() => {
    const config = columnsConfigs ? [...columnsConfigs] : [];
    Object.keys(properties).forEach((propertyName) => {
      if (
        !isUnconfiguredHidden &&
        (!columnsConfigs ||
          !columnsConfigs.find(
            (columnsConfig) => columnsConfig.name === propertyName,
          ))
      ) {
        config.push({
          name: propertyName as keyof T,
        });
      }
    });
    return config;
  }, [columnsConfigs, isUnconfiguredHidden, properties]);

  const getAlign = React.useCallback(
    (columnName: any): "left" | "center" | "right" => {
      const propSchema = properties[columnName] as oas30.SchemaObject;
      if (!propSchema) {
        return "left";
      }
      switch (propSchema.type) {
        case "number":
        case "integer":
          return "right";
      }
      return "left";
    },
    [properties],
  );

  // Collect all data that should be displayed in the form
  React.useEffect(() => {
    setRenderData(
      data
        ? data.map((item, index) => {
            if (!item) {
              return {
                _index: index,
              };
            }
            return config.reduce(
              (prev: IRenderData, colConfig) => {
                const columnName = colConfig.name as string;
                let propSchema = properties[columnName] as oas30.SchemaObject;
                let propValue = item[columnName as keyof T];

                if (!propSchema) {
                  propSchema = {
                    type: undefined,
                  };
                }

                if (colConfig?.renderData) {
                  prev[columnName] = colConfig?.renderData(item, index);
                  return prev;
                }

                switch (propSchema.type) {
                  case "boolean":
                    prev[columnName] = (
                      <Icon icon={propValue ? "check" : "close"} />
                    );
                    return prev;

                  case "integer":
                    prev[columnName] = propValue
                      ? formatInt(propValue as any)
                      : undefined;
                    return prev;

                  case "number":
                    if (propSchema.format === "float") {
                      prev[columnName] = propValue
                        ? currency(propValue as any)
                        : undefined;
                      return prev;
                    }
                    break;

                  case "string":
                    switch (propSchema.format) {
                      case "date-time":
                        prev[columnName] = propValue
                          ? localeFormat(
                              new Date(propValue as any),
                              DATE_TIME_FORMAT,
                            )
                          : "";
                        return prev;

                      case "date":
                        prev[columnName] = propValue
                          ? localeFormat(
                              new Date(propValue as any),
                              DATE_FORMAT,
                            )
                          : "";
                        return prev;
                    }
                }

                // Return all items, with there original key and value,
                // that arent a reference (i.e. items that dont end with 'Id' or 'Ids')
                const referencedSchemaName =
                  getReferencedSchemaName(columnName);
                if (!referencedSchemaName) {
                  prev[columnName] = propValue;
                  return prev;
                }

                // Return all items, with there original key and value,
                // that cant be referenced (e.g. mismatching/unknown referencedSchemaName)
                const referencedSchema = getReferencedSchema(
                  referencedSchemaName || "",
                );
                if (!referencedSchema) {
                  prev[columnName] = propValue;
                  return prev;
                }

                const itemMap =
                  (apiDataContext as any)[
                    `${lowerFirst(referencedSchemaName)}s`
                  ] || {};
                if (itemMap && columnName.endsWith("Id")) {
                  const item = itemMap[propValue];
                  prev[columnName] = item
                    ? item[`${lowerFirst(referencedSchemaName)}Name`]
                    : "";
                  return prev;
                }

                if (columnName.endsWith("Ids")) {
                  const items = ((propValue as any as string[]) || [])
                    .map((itemId: string) => itemMap[itemId])
                    .filter((item) => !!item);
                  prev[columnName] = items
                    .map(
                      (item: any) =>
                        item[`${lowerFirst(referencedSchemaName)}Name`],
                    )
                    .join(", ");
                  return prev;
                }
                return prev;
              },
              {
                _index: index,
              },
            );
          })
        : undefined,
    );
  }, [apiDataContext, config, data, properties]);

  React.useEffect(() => {
    const timeout = window.setTimeout(() => {
      setLayoutInitialized(true);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  const propNames = React.useMemo(() => Object.keys(properties), [properties]);

  // Filter the data based on the searchQuery
  const filteredData = React.useMemo(() => {
    if (!renderData || searchQuery === "") {
      return renderData;
    }
    const lcQuery = searchQuery.toLowerCase();
    return dataFilter
      ? dataFilter(renderData, lcQuery)
      : renderData.filter((item) => {
          if (
            config.find((columnConfig) =>
              `${item[columnConfig.name as string]}`
                .toLowerCase()
                .includes(lcQuery),
            )
          ) {
            return true;
          }
          if (!data) {
            return false;
          }
          const schemaData = data[item._index];
          if (!schemaData) {
            return false;
          }
          return !!propNames.find((propName) =>
            `${schemaData[propName as keyof T]}`
              .toLowerCase()
              .includes(lcQuery),
          );
        });
  }, [renderData, searchQuery, dataFilter, data, config, propNames]);

  // Sort the filtered data
  const sortedData = React.useMemo(() => {
    if (!sortColumn || !filteredData?.length || !data) {
      return filteredData && dataSorter && data
        ? filteredData.sort((a, b) => {
            const aData = data[a._index];
            const bData = data[b._index];
            if (!aData) {
              return 1;
            }
            if (!bData) {
              return -1;
            }
            return dataSorter(aData, bData, searchQuery);
          })
        : filteredData;
    }
    const columnConfig = config.find(
      (columnsConfig) => columnsConfig.name === sortColumn,
    ) as IColumnConfig<T>;
    const columnSort = columnConfig.sort;
    if (columnSort) {
      return filteredData.sort((a, b) => {
        const aData = data[a._index];
        const bData = data[b._index];
        if (!aData) {
          return 1;
        }
        if (!bData) {
          return -1;
        }
        return columnSort(aData, bData, sortAsc);
      });
    }

    const sortSchema = properties[sortColumn as string] as
      | oas30.SchemaObject
      | undefined;
    return filteredData.sort((a, b) => {
      // sort should happen on "render data" or on actual data (e.g. for bools)
      let { sortByValue } = columnConfig;
      if (sortByValue === undefined) {
        sortByValue =
          !sortSchema ||
          sortSchema.type === "boolean" ||
          sortSchema.format === "date" ||
          sortSchema.format === "date-time";
      }
      const aData = data[a._index];
      const bData = data[b._index];
      if (!aData) {
        return 1;
      }
      if (!bData) {
        return -1;
      }
      const x = sortByValue ? aData[sortColumn] : a[sortColumn as string];
      const y = sortByValue ? bData[sortColumn] : b[sortColumn as string];
      if (x === y) {
        return 0;
      }
      return (x < y ? 1 : -1) * (sortAsc ? 1 : -1);
    });
  }, [
    sortColumn,
    filteredData,
    data,
    config,
    properties,
    dataSorter,
    searchQuery,
    sortAsc,
  ]);

  const onSortColumn = React.useCallback((sortColumn: any, sortType: any) => {
    setSortColumn(sortColumn);
    setSortAsc(sortType === "asc");
  }, []);

  const renderColTitle = React.useCallback(
    (
      propName: string,
      colConfig?: IColumnConfig<any>,
    ): string | React.ReactElement => {
      if (!colConfig || colConfig?.title === undefined) {
        return t(propName);
      }
      return typeof colConfig.title === "string"
        ? t(colConfig.title)
        : colConfig.title;
    },
    [t],
  );

  const onExportClick = React.useCallback(() => {
    if (!data || !onExport || !sortedData) {
      return;
    }
    onExport(
      sortedData
        .map((renderDataRow) => data[renderDataRow._index])
        .filter((a) => !!a) as T[],
    );
  }, [data, onExport, sortedData]);

  return (
    <>
      {globalSearch || children || onExport ? (
        <div className="schema-table__topbar">
          <div className="row">
            {globalSearch ? (
              <div className="col col-md-6 offset-2 offset-sm-1 offset-md-0">
                <InputGroup
                  size="sm"
                  inside
                  className="schema-table__topbar__search"
                >
                  <InputGroup.Addon>
                    <Icon icon="search" />
                  </InputGroup.Addon>
                  <Input
                    placeholder={`${t("search")}...`}
                    value={searchQuery}
                    onChange={(value) => setSearchQuery(value)}
                  />
                </InputGroup>
              </div>
            ) : null}
            <div
              className={`${
                globalSearch ? "col-auto col-md-6" : "col-md-12"
              } schema-table__topbar__col--right`}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {children}
              {onExport && sortedData && data ? (
                <ActionButton
                  icon={<Icon icon="export" componentClass={Export} />}
                  onClick={onExportClick}
                  title={t("exportAction")}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {actionBar}
      {data ? (
        <Table
          key={
            // Fixes ReactTable virtualized render bug
            // Fixes http://otrs.knipsel.lan/otrs/index.pl?Action=AgentTicketZoom;TicketID=12802
            layoutInitialized ? "initialized" : "uninitialized"
          }
          shouldUpdateScroll={false}
          ref={tableRef}
          className={`schema-table__overview${
            onRowClick && data ? " schema-table__overview--clickable-rows" : ""
          }`}
          data={sortedData}
          loading={loading}
          virtualized
          onRowClick={
            onRowClick && data
              ? (rowData: IRenderData, e: any) => {
                  const obj = data[rowData._index];
                  if (elementHasClassName(e.target, "checkbox") || !obj) {
                    return;
                  }
                  onRowClick(obj, e);
                }
              : undefined
          }
          onScroll={(scrollX: number, scrollY: number) => {
            const previousY = lastPosYRef.current;
            lastPosYRef.current = scrollY;
            const tableInstance = tableRef.current;
            if (onScroll && tableInstance) {
              onScroll(scrollX, scrollY, tableInstance, previousY);
            }
          }}
          onSortColumn={onSortColumn}
          sortColumn={sortColumn as string}
          autoHeight={autoHeight}
          height={height || windowInnerHeight - 148}
          rowHeight={rowHeight || 67}
        >
          {getSelected && setSelected && data ? (
            /* @ts-ignore */
            <Column width={40}>
              {getSelectAll && setSelectAll ? (
                <HeaderCell className="schema-table__select-cell">
                  <Checkbox
                    checked={getSelectAll()}
                    onChange={(_: unknown, selected) => setSelectAll(selected)}
                  />
                </HeaderCell>
              ) : (
                <HeaderCell />
              )}
              <CheckboxCell
                isDisabled={(rowData: IRenderData) => {
                  const item = data[rowData._index];
                  if (!item || !isCheckboxDisabled) {
                    return false;
                  }
                  return isCheckboxDisabled(item);
                }}
                getSelected={(rowData: IRenderData) => {
                  const item = data[rowData._index];
                  if (!item) {
                    return false;
                  }
                  return getSelected(item, rowData._index);
                }}
                onChange={(rowData: IRenderData, selected: boolean) => {
                  const item = data[rowData._index];
                  if (!item) {
                    return;
                  }
                  setSelected(item, selected, rowData._index);
                }}
              />
            </Column>
          ) : null}
          {config
            .filter((columnConfig) => !columnConfig.hidden)
            .map((columnConfig, columnIndex) => {
              const propName = columnConfig.name as string;
              return (
                /* @ts-ignore */
                <Column
                  sortable={
                    columnConfig?.sortable !== undefined
                      ? columnConfig.sortable
                      : sortable
                  }
                  width={columnConfig?.width}
                  flexGrow={
                    columnConfig?.width || resizable
                      ? undefined
                      : columnConfig?.flexGrow !== undefined
                      ? columnConfig.flexGrow
                      : 1
                  }
                  resizable={resizable}
                  onResize={(columnWidth?: number) =>
                    onResize && columnWidth
                      ? onResize(columnWidth, propName)
                      : {}
                  }
                  key={propName}
                  align={columnConfig?.align || getAlign(propName)}
                >
                  <HeaderCell>
                    <span title={t(columnConfig?.hoverTitle || "")}>
                      {renderColTitle(propName, columnConfig)}
                    </span>
                  </HeaderCell>
                  <Cell dataKey={propName}>
                    {(rowData: IRenderData, rowIndex: number) => {
                      const obj = data ? data[rowData._index] : null;
                      if (!obj && onRowsRendered) {
                        renderedSetRef.current.add(rowIndex);
                        flush();
                        return columnIndex === 0 ? <Loader /> : null;
                      }
                      const cellBody =
                        columnConfig && columnConfig.renderCell && obj
                          ? columnConfig.renderCell(obj, rowData._index)
                          : rowData[propName];
                      return (
                        <span
                          key={rowIndex}
                          className={`schema-table__overview__cell ${
                            obj && rowClassName ? rowClassName(obj) : ""
                          }`}
                          title={
                            typeof cellBody === "string" ? cellBody : undefined
                          }
                        >
                          {cellBody}
                        </span>
                      );
                    }}
                  </Cell>
                </Column>
              );
            })}
        </Table>
      ) : (
        <Loader center size="lg" />
      )}
    </>
  );
}
