import { Button, Col, FlexboxGrid } from "rsuite";
import React from "react";
import { I18nContext } from "../../../../provider/I18nProvider";

interface IEmptyDashboardViewProps {
  onDrawerToggle?: (open: boolean) => void;
}
const EmptyDashboardView = ({ onDrawerToggle }: IEmptyDashboardViewProps) => {
  const { t } = React.useContext(I18nContext);

  return (
    <FlexboxGrid
      justify="center"
      className="dashboard-view__content__image-container"
    >
      <FlexboxGrid.Item colspan={24}>
        <h2 className="mt-5">{t("dashboardView_emptyDashboard")}</h2>
        <p>{t("dashboardView_emptyDashboardDescription")}</p>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={24}>
        <img
          alt={t("dashboardView_emptyDashboardAlt")}
          src="/img/art/lookout/lookout.png"
          srcSet="/img/art/lookout/lookout@2x.png 2x, /img/art/lookout/lookout@3x.png 3x"
          className="dashboard-view__content__image-container__image"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        className="dashboard-view__content__button-wrapper"
        componentClass={Col}
        colspan={24}
        sm={12}
        md={10}
      >
        <Button
          className="dashboard-view__content__button-wrapper__button"
          appearance="primary"
          color="blue"
          size="xs"
          onClick={() => onDrawerToggle && onDrawerToggle(true)}
        >
          {t("dashboardView_addWidget")}
        </Button>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default EmptyDashboardView;
