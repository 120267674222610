import { differenceInSeconds } from "date-fns";
import React, { Dispatch, SetStateAction } from "react";
import axios from "../../inc/axios";
import { components } from "../../types/openapi";
import { TBenchmark, TDashboard, TUser } from "../../types";
import { emptyFn, IHashMap } from "../../inc/data";
import useQueryParameters from "../../hooks/useQueryParameters";
import { AuthContext } from "../AuthProvider";
import { useHistory } from "react-router-dom";
// @ts-ignore
import { Alert } from "rsuite";
import { APP_PATH } from "../../inc/constants";

export interface IApiDataContext {
  benchmarks?: IHashMap<TBenchmark> | null;
  categories?: IHashMap<components["schemas"]["Category"]> | null;
  createLabelAutomator: (
    labelAutomator: components["schemas"]["LabelAutomator"],
  ) => Promise<components["schemas"]["LabelAutomator"]>;
  customers?: IHashMap<components["schemas"]["Customer"]> | null;
  dashboards?: IHashMap<TDashboard> | null;
  benchmarkAccesses?: IHashMap<components["schemas"]["BenchmarkAccess"]> | null;
  dashboardAccesses?: IHashMap<components["schemas"]["DashboardAccess"]> | null;
  deleteRequests?: IHashMap<components["schemas"]["DeleteRequest"]> | null;
  editorials?: IHashMap<components["schemas"]["Editorial"]> | null;
  editorialIssues?: IHashMap<components["schemas"]["EditorialIssue"]> | null;
  editorialRubrics?: IHashMap<components["schemas"]["EditorialRubric"]> | null;
  editorialRubricMediaItems?: IHashMap<
    components["schemas"]["EditorialRubricMediaItem"]
  > | null;
  editorialSubscribers?: IHashMap<
    components["schemas"]["EditorialSubscriber"]
  > | null;
  groups?: IHashMap<components["schemas"]["Group"]> | null;
  impersonate: (userId: string, customerLinkId?: string) => void;
  labelAutomators?: IHashMap<components["schemas"]["LabelAutomator"]> | null;
  labels?: IHashMap<components["schemas"]["Label"]> | null;
  mes?: TUser[] | null;
  newContentNotificationCustomerUserLinks?:
    | components["schemas"]["NewContentNotificationCustomerUserLink"][]
    | null;
  searchTopics?: IHashMap<components["schemas"]["SearchTopic"]> | null;
  subscribers?: IHashMap<components["schemas"]["Subscriber"]> | null;
  contentExports?: IHashMap<components["schemas"]["ContentExport"]> | null;
  rssExports?: IHashMap<components["schemas"]["RssExport"]> | null;
  invoices?: IHashMap<components["schemas"]["Invoice"]> | null;
  mediaItemPurchases?: IHashMap<
    components["schemas"]["MediaItemPurchase"]
  > | null;
  newContentNotifications?: IHashMap<
    components["schemas"]["NewContentNotification"]
  > | null;
  newsAlerts?: IHashMap<components["schemas"]["NewsAlert"]> | null;
  searchEngineQueries?: IHashMap<
    components["schemas"]["SearchEngineQuery"]
  > | null;
  setBenchmarks: Dispatch<
    SetStateAction<IHashMap<TBenchmark> | undefined | null>
  >;
  setCategories: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["Category"]> | undefined | null
    >
  >;
  setCurrentCustomer: (customerId: string) => void;
  setCustomers: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["Customer"]> | undefined | null
    >
  >;
  setBenchmarkAccesses: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["BenchmarkAccess"]> | undefined | null
    >
  >;
  setDashboardAccesses: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["DashboardAccess"]> | undefined | null
    >
  >;
  setDeleteRequests: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["DeleteRequest"]> | undefined | null
    >
  >;
  setEditorials: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["Editorial"]> | undefined | null
    >
  >;
  setEditorialIssues: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["EditorialIssue"]> | undefined | null
    >
  >;
  setEditorialSubscribers: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["EditorialSubscriber"]> | undefined | null
    >
  >;
  setEditorialRubrics: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["EditorialRubric"]> | undefined | null
    >
  >;
  setEditorialRubricMediaItems: Dispatch<
    SetStateAction<
      | IHashMap<components["schemas"]["EditorialRubricMediaItem"]>
      | undefined
      | null
    >
  >;
  setMediaItemPurchases: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["MediaItemPurchase"]> | undefined | null
    >
  >;
  setNewContentNotifications: Dispatch<
    SetStateAction<
      | IHashMap<components["schemas"]["NewContentNotification"]>
      | undefined
      | null
    >
  >;
  setNewsAlerts: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["NewsAlert"]> | undefined | null
    >
  >;
  setNewContentNotificationCustomerUserLinks: Dispatch<
    SetStateAction<
      | components["schemas"]["NewContentNotificationCustomerUserLink"][]
      | undefined
      | null
    >
  >;
  setSubscribers: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["Subscriber"]> | undefined | null
    >
  >;
  setGroups: Dispatch<
    SetStateAction<IHashMap<components["schemas"]["Group"]> | undefined | null>
  >;
  setInvoices: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["Invoice"]> | undefined | null
    >
  >;
  setLabels: Dispatch<
    SetStateAction<IHashMap<components["schemas"]["Label"]> | undefined | null>
  >;
  setLabelAutomators: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["LabelAutomator"]> | undefined | null
    >
  >;
  setContentExports: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["ContentExport"]> | undefined | null
    >
  >;
  setMes: Dispatch<SetStateAction<TUser[] | undefined | null>>;
  setRssExports: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["RssExport"]> | undefined | null
    >
  >;
  setSearchEngineQueries: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["SearchEngineQuery"]> | undefined | null
    >
  >;
  setSearchTopics: Dispatch<
    SetStateAction<
      IHashMap<components["schemas"]["SearchTopic"]> | undefined | null
    >
  >;
  setUsers: Dispatch<SetStateAction<TUser[] | undefined | null>>;
  setDashboards: Dispatch<
    SetStateAction<IHashMap<TDashboard> | undefined | null>
  >;
  updateDashboard: (
    dashboard: TDashboard,
    params?: { pressReleaseUrl: string; publishDate?: string },
  ) => Promise<TDashboard>;
  updateLabel: (
    label: components["schemas"]["Label"],
  ) => Promise<components["schemas"]["Label"]>;
  updateContentExport: (
    contentExport: components["schemas"]["ContentExport"],
  ) => Promise<components["schemas"]["ContentExport"]>;
  updateRssExport: (
    rss: components["schemas"]["RssExport"],
  ) => Promise<components["schemas"]["RssExport"]>;
  updateUser: (user: TUser) => Promise<TUser>;
  users?: TUser[] | null;
}

const promiseFn = () => Promise.reject(new Error("Not yet initialized"));

export const ApiDataContext = React.createContext<IApiDataContext>({
  createLabelAutomator: promiseFn,
  impersonate: emptyFn,
  setBenchmarks: emptyFn,
  setBenchmarkAccesses: emptyFn,
  setCategories: emptyFn,
  setCurrentCustomer: emptyFn,
  setCustomers: emptyFn,
  setDashboards: emptyFn,
  setDashboardAccesses: emptyFn,
  setDeleteRequests: emptyFn,
  setEditorials: emptyFn,
  setEditorialIssues: emptyFn,
  setEditorialSubscribers: emptyFn,
  setEditorialRubrics: emptyFn,
  setEditorialRubricMediaItems: emptyFn,
  setMediaItemPurchases: emptyFn,
  setSubscribers: emptyFn,
  setGroups: emptyFn,
  setInvoices: emptyFn,
  setLabelAutomators: emptyFn,
  setLabels: emptyFn,
  setMes: emptyFn,
  setNewContentNotifications: emptyFn,
  setNewContentNotificationCustomerUserLinks: emptyFn,
  setNewsAlerts: emptyFn,
  setContentExports: emptyFn,
  setRssExports: emptyFn,
  setSearchEngineQueries: emptyFn,
  setSearchTopics: emptyFn,
  setUsers: emptyFn,
  updateContentExport: promiseFn,
  updateDashboard: promiseFn,
  updateLabel: promiseFn,
  updateRssExport: promiseFn,
  updateUser: promiseFn,
});

const ApiDataProvider = ({ children }: any) => {
  const { auth, setBearer } = React.useContext(AuthContext);
  const [searchEngineQueries, setSearchEngineQueries] = React.useState<IHashMap<
    components["schemas"]["SearchEngineQuery"]
  > | null>();
  const [benchmarkAccesses, setBenchmarkAccesses] = React.useState<
    IHashMap<components["schemas"]["BenchmarkAccess"]> | null | undefined
  >();
  const [benchmarks, setBenchmarks] = React.useState<
    IHashMap<TBenchmark> | null | undefined
  >();
  const [categories, setCategories] = React.useState<IHashMap<
    components["schemas"]["Category"]
  > | null>();
  const [customers, setCustomers] = React.useState<IHashMap<
    components["schemas"]["Customer"]
  > | null>();
  const [groups, setGroups] = React.useState<IHashMap<
    components["schemas"]["Group"]
  > | null>();
  const [invoices, setInvoices] = React.useState<IHashMap<
    components["schemas"]["Invoice"]
  > | null>();
  // Please note: userIds are not unique! A user may exist in several groups for superusers
  const [users, setUsers] = React.useState<TUser[] | null>();
  const [dashboards, setDashboards] =
    React.useState<IHashMap<TDashboard> | null>();
  const [deleteRequests, setDeleteRequests] = React.useState<IHashMap<
    components["schemas"]["DeleteRequest"]
  > | null>();
  const [editorials, setEditorials] = React.useState<IHashMap<
    components["schemas"]["Editorial"]
  > | null>();
  const [editorialRubrics, setEditorialRubrics] = React.useState<IHashMap<
    components["schemas"]["EditorialRubric"]
  > | null>();
  const [editorialRubricMediaItems, setEditorialRubricMediaItems] =
    React.useState<IHashMap<
      components["schemas"]["EditorialRubricMediaItem"]
    > | null>();
  const [editorialIssues, setEditorialIssues] = React.useState<IHashMap<
    components["schemas"]["EditorialIssue"]
  > | null>();
  const [editorialSubscribers, setEditorialSubscribers] =
    React.useState<IHashMap<
      components["schemas"]["EditorialSubscriber"]
    > | null>();
  const [labels, setLabels] = React.useState<IHashMap<
    components["schemas"]["Label"]
  > | null>();
  const [labelAutomators, setLabelAutomators] = React.useState<IHashMap<
    components["schemas"]["LabelAutomator"]
  > | null>();
  const [mediaItemPurchases, setMediaItemPurchases] = React.useState<IHashMap<
    components["schemas"]["MediaItemPurchase"]
  > | null>();
  const [searchTopics, setSearchTopics] = React.useState<IHashMap<
    components["schemas"]["SearchTopic"]
  > | null>();
  const [subscribers, setSubscribers] = React.useState<IHashMap<
    components["schemas"]["Subscriber"]
  > | null>();
  const [newContentNotifications, setNewContentNotifications] =
    React.useState<IHashMap<
      components["schemas"]["NewContentNotification"]
    > | null>();
  const [
    newContentNotificationCustomerUserLinks,
    setNewContentNotificationCustomerUserLinks,
  ] = React.useState<
    components["schemas"]["NewContentNotificationCustomerUserLink"][] | null
  >();
  const [newsAlerts, setNewsAlerts] = React.useState<IHashMap<
    components["schemas"]["NewsAlert"]
  > | null>();
  const [dashboardAccesses, setDashboardAccesses] = React.useState<IHashMap<
    components["schemas"]["DashboardAccess"]
  > | null>();
  const [contentExports, setContentExports] = React.useState<IHashMap<
    components["schemas"]["ContentExport"]
  > | null>();
  const [mes, setMes] = React.useState<TUser[] | null>();
  const [rssExports, setRssExports] = React.useState<IHashMap<
    components["schemas"]["RssExport"]
  > | null>();
  const queryParameters = useQueryParameters<{ customerLinkId: string }>();
  const history = useHistory();

  const updateDashboard = React.useCallback(
    (
      dashboard: TDashboard,
      params?: { pressReleaseUrl: string; publishDate?: string },
    ) => {
      const { dashboardId } = dashboard;
      return axios
        .request<TDashboard>({
          method: dashboardId ? "put" : "post",
          url: `/dashboard/crud${dashboardId ? `/${dashboardId}` : ""}`,
          data: dashboard,
          params,
        })
        .then((res) => {
          setDashboards((dashboards) => ({
            ...dashboards,
            [res.data.dashboardId as string]: res.data,
          }));
          return res.data;
        });
    },
    [],
  );

  const updateLabel = React.useCallback(
    (label: components["schemas"]["Label"]) => {
      const { labelId } = label;
      return axios
        .request<components["schemas"]["Label"]>({
          method: labelId ? "put" : "post",
          url: `/label/crud${labelId ? `/${labelId}` : ""}`,
          data: label,
        })
        .then((res) => {
          setLabels({
            ...labels,
            [res.data.labelId as string]: res.data,
          });
          return res.data;
        });
    },
    [labels],
  );

  const updateContentExport = React.useCallback(
    (contentExport: components["schemas"]["ContentExport"]) => {
      const { contentExportId } = contentExport;
      return axios
        .request<components["schemas"]["ContentExport"]>({
          method: contentExportId ? "put" : "post",
          url: `/contentExport/crud${
            contentExportId ? `/${contentExportId}` : ""
          }`,
          data: contentExport,
        })
        .then((res) => {
          setContentExports((contentExports) => ({
            ...contentExports,
            [res.data.contentExportId as string]: res.data,
          }));
          return res.data;
        });
    },
    [],
  );

  const updateRssExport = React.useCallback(
    (rssExport: components["schemas"]["RssExport"]) => {
      const { rssExportId } = rssExport;
      return axios
        .request<components["schemas"]["RssExport"]>({
          method: rssExportId ? "put" : "post",
          url: `/rssExport/crud${rssExportId ? `/${rssExportId}` : ""}`,
          data: rssExport,
        })
        .then((res) => {
          setRssExports((rssExports) => ({
            ...rssExports,
            [res.data.rssExportId as string]: res.data,
          }));
          return res.data;
        });
    },
    [],
  );

  const createLabelAutomator = React.useCallback(
    (labelAutomator: components["schemas"]["LabelAutomator"]) => {
      if (labelAutomator.labelAutomatorId !== undefined) {
        throw new Error("A new label automator cannot have an id");
      }
      return axios
        .request<components["schemas"]["LabelAutomator"]>({
          method: "post",
          url: "/labelAutomator/crud",
          data: labelAutomator,
        })
        .then((res) => {
          setLabelAutomators({
            ...labelAutomators,
            [res.data.labelAutomatorId as string]: res.data,
          });
          return res.data;
        });
    },
    [labelAutomators],
  );

  const refreshToken = React.useCallback(
    (params: { customerLinkId?: string; userId?: string }): Promise<void> => {
      if (!auth) {
        return Promise.reject(new Error("Cannot refresh token: missing auth"));
      }
      // make sure the dashboard will not load results until the user switch is complete...
      setDashboards(null);
      return axios
        .request({
          method: "post",
          url: "/auth/refreshToken",
          params,
          headers: {
            // pass this explicitly! interceptor will miss it since it is triggered on next frame
            Authorization: `Bearer ${auth.bearer}`,
          },
        })
        .then((res) => {
          setBearer(res.data.bearer);
          if (params.customerLinkId || params.userId) {
            setDashboards(undefined);
            setDashboardAccesses(undefined);
            setLabels(undefined);
            setSearchTopics(undefined);
            setInvoices(undefined);
            setDeleteRequests(undefined);
            setEditorials(undefined);
            setEditorialRubricMediaItems(undefined);
            setEditorialRubrics(undefined);
            setEditorialIssues(undefined);
            setEditorialSubscribers(undefined);
            setInvoices(undefined);
            setLabelAutomators(undefined);
            setLabels(undefined);
            setMediaItemPurchases(undefined);
            setNewContentNotifications(undefined);
            setNewsAlerts(undefined);
            setRssExports(undefined);
            setSearchEngineQueries(undefined);
            setSearchTopics(undefined);
            setSubscribers(undefined);
            // users and customers can change when switching customer when the user is in multiple groups
            setMes(undefined);
            setUsers(undefined);
            setCustomers(undefined);
            setBenchmarks(undefined);
            if (params.userId) {
              setGroups(undefined);
            }
          }
        });
    },
    [auth, setBearer],
  );

  const setCurrentCustomer = React.useCallback(
    (customerLinkId: string): void => {
      if (!auth) {
        throw new Error("Not logged in");
      }
      const { currentCustomerLinkId, userId } = auth.jwt;
      if (currentCustomerLinkId === customerLinkId) {
        console.log("Not switching customer: already there...");
        return;
      }
      localStorage.setItem(`customerLinkId_${userId}`, customerLinkId);
      refreshToken({ customerLinkId }).catch((e: any) => {
        Alert.error(`Wisselen naar klantaccount niet mogelijk: ${e.message}`);
      });
    },
    [auth, refreshToken],
  );

  const updateUser = React.useCallback(
    (user: TUser) => {
      const isNew = !user.userId;
      return axios
        .request<TUser>({
          url: `/user/crud${isNew ? "" : `/${user.userId}`}`,
          method: isNew ? "post" : "put",
          data: user,
        })
        .then((res) => {
          const updatedUser = res.data;
          // rehydrate personal profiles if the user changes himself / herself....
          if (mes && mes.find((me) => me.userId === user.userId)) {
            setMes(undefined);
          }
          setUsers((users) =>
            users
              ? isNew
                ? [...users, updatedUser]
                : users.map((user) =>
                    user.userId === updatedUser.userId &&
                    user.groupId === updatedUser.groupId
                      ? updatedUser
                      : user,
                  )
              : users,
          );
          return updatedUser;
        });
    },
    [mes],
  );

  const impersonate = React.useCallback(
    (userId: string, customerLinkId?: string) => {
      return refreshToken({ userId, customerLinkId })
        .then(() => {
          // routing can only be correct after role hydration
          setTimeout(() => {
            history.push(APP_PATH);
          }, 1);
        })
        .catch((e: any) => {
          console.log(e);
          // Change user failed... Incorrect tokens?! Clear everything!
          localStorage.clear();
          setBearer(undefined);
          window.location.href = "/";
        });
    },
    [history, refreshToken, setBearer],
  );

  React.useEffect(() => {
    const clockInterval = setInterval(() => {
      if (!auth) {
        return;
      }
      const { issuedAt, jwt } = auth;
      const age = differenceInSeconds(new Date(), issuedAt);
      if (age > jwt.tokenExpirationDuration - 60) {
        refreshToken({}).catch((e: any) => {
          console.log(e);
          history.push("/logout");
        });
      }
    }, 30000);
    return () => {
      clearInterval(clockInterval);
    };
  }, [refreshToken, auth, history]);

  React.useEffect(() => {
    if (
      customers &&
      queryParameters.customerLinkId &&
      auth?.jwt.currentCustomerLinkId !== queryParameters.customerLinkId &&
      customers[queryParameters.customerLinkId]
    ) {
      setCurrentCustomer(queryParameters.customerLinkId);
    }
  }, [
    auth?.jwt.currentCustomerLinkId,
    customers,
    queryParameters.customerLinkId,
    setCurrentCustomer,
  ]);

  const apiData = {
    benchmarks,
    benchmarkAccesses,
    categories,
    createLabelAutomator,
    customers,
    dashboards,
    dashboardAccesses,
    deleteRequests,
    editorialIssues,
    editorialRubrics,
    editorialRubricMediaItems,
    editorialSubscribers,
    editorials,
    groups,
    impersonate,
    invoices,
    labelAutomators,
    labels,
    mediaItemPurchases,
    mes,
    newContentNotifications,
    newContentNotificationCustomerUserLinks,
    newsAlerts,
    contentExports,
    rssExports,
    searchEngineQueries,
    searchTopics,
    setBenchmarkAccesses,
    setBenchmarks,
    setCategories,
    setCurrentCustomer,
    setCustomers,
    setDashboards,
    setDashboardAccesses,
    setDeleteRequests,
    setEditorials,
    setEditorialIssues,
    setEditorialSubscribers,
    setEditorialRubrics,
    setEditorialRubricMediaItems,
    setSubscribers,
    setGroups,
    setInvoices,
    setLabelAutomators,
    setLabels,
    setMediaItemPurchases,
    setMes,
    setNewContentNotifications,
    setNewContentNotificationCustomerUserLinks,
    setNewsAlerts,
    setContentExports,
    setRssExports,
    setSearchEngineQueries,
    setSearchTopics,
    setUsers,
    subscribers,
    updateDashboard,
    updateLabel,
    updateContentExport,
    updateRssExport,
    updateUser,
    users,
  };

  // Add global handle for debugging purposes
  if (process.env.NODE_ENV === "development") {
    // @ts-ignore
    window.apiData = apiData;
  }

  return (
    <ApiDataContext.Provider value={apiData}>
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;
