import React, { SVGProps } from "react";

const MediaItemBlockDisplay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Weergave_BW_fotos"
    width={57.313}
    height={27.529}
    viewBox="0 0 57.313 27.529"
    fill="#96afaf"
    {...props}
  >
    <path
      id="prefix__Path_1982"
      d="M1603.266 1361.014h-17.292a5.125 5.125 0 01-5.119-5.119V1338.6a5.124 5.124 0 015.119-5.118h17.292a5.123 5.123 0 015.118 5.118v17.3a5.124 5.124 0 01-5.118 5.114zm-17.292-25.123a2.716 2.716 0 00-2.713 2.713v17.296a2.717 2.717 0 002.713 2.714h17.292a2.717 2.717 0 002.714-2.714v-17.3a2.716 2.716 0 00-2.714-2.713z"
      transform="translate(-1580.855 -1333.485)"
    />
    <path
      id="prefix__Path_1983"
      d="M1602.5 1362.616a1.2 1.2 0 01-.964-.48l-10.281-13.709-8.326 2.271a1.2 1.2 0 01-.633-2.32l9.115-2.487a1.2 1.2 0 011.279.439l10.771 14.363a1.2 1.2 0 01-.961 1.923z"
      transform="translate(-1580.927 -1335.106)"
    />
    <path
      id="prefix__Path_1984"
      d="M1602.24 1346.826a3.826 3.826 0 113.828-3.826 3.83 3.83 0 01-3.828 3.826zm0-5.248a1.422 1.422 0 101.422 1.422 1.424 1.424 0 00-1.422-1.422z"
      transform="translate(-1583.158 -1334.23)"
    />
    <path
      id="prefix__Path_1985"
      d="M1638.468 1338.811h-18.621a1.2 1.2 0 010-2.4h18.621a1.2 1.2 0 110 2.4z"
      transform="translate(-1585.81 -1333.868)"
    />
    <path
      id="prefix__Path_1986"
      d="M1641.921 1350.1h-22.074a1.2 1.2 0 010-2.405h22.074a1.2 1.2 0 010 2.405z"
      transform="translate(-1585.81 -1335.347)"
    />
    <path
      id="prefix__Path_1987"
      d="M1632.254 1361.764h-12.407a1.2 1.2 0 010-2.405h12.407a1.2 1.2 0 010 2.405z"
      transform="translate(-1585.81 -1336.878)"
    />
  </svg>
);
export default MediaItemBlockDisplay;
