import React from "react";
import { components } from "../../../types/openapi";
import EditorialIssueCol from "./EditorialIssueCol";
import { I18nContext } from "../../../provider/I18nProvider";
import useEditorialIssues from "../../../hooks/useEditorialIssues";
import { Loader, Panel } from "rsuite";

interface IEditorialItemProps {
  editorial: components["schemas"]["Editorial"];
}
const EditorialItem = ({ editorial }: IEditorialItemProps) => {
  const { t } = React.useContext(I18nContext);
  const editorialIssues = useEditorialIssues();

  const editorialEditorialIssues = React.useMemo(
    () =>
      editorialIssues
        ? Object.values(editorialIssues)
            .filter(
              (editorialIssue) =>
                editorialIssue.editorialId === editorial.editorialId
            )
            .sort((a, b) => {
              if (!a.sentDatetime && !b.sentDatetime) {
                return new Date(a.scheduledDatetime!) <
                  new Date(b.scheduledDatetime!)
                  ? -1
                  : 1;
              }
              if (!a.sentDatetime) {
                return -1;
              }
              if (!b.sentDatetime) {
                return 1;
              }
              return new Date(a.sentDatetime) < new Date(b.sentDatetime)
                ? 1
                : -1;
            })
        : undefined,
    [editorial.editorialId, editorialIssues]
  );

  if (!editorialEditorialIssues) {
    return <Loader />;
  }

  return (
    <Panel header={editorial.name} collapsible defaultExpanded className="mb-3">
      <div className="row pe-5 mb-5">
        {editorialEditorialIssues.length ? (
          editorialEditorialIssues.map((editorialIssue, index) => (
            <EditorialIssueCol
              key={index}
              editorialIssue={editorialIssue}
              editorial={editorial}
            />
          ))
        ) : (
          <div className="col-12 mb-4">{t("editorialIssueNoEditions")}</div>
        )}
      </div>
    </Panel>
  );
};

export default EditorialItem;
