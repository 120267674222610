import React, { SVGProps } from "react";

const Account = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.232}
      height={21.055}
      viewBox="0 0 16.232 21.055"
      fill="currentColor"
      {...props}
    >
      <g id="prefix__Group_341" data-name="Group 341">
        <path
          id="prefix__Path_648"
          data-name="Path 648"
          d="M1444.217 600.33h-12.181a2.1 2.1 0 01-2.1-2.1.529.529 0 010-.062l.379-3.271a5.884 5.884 0 012.9-4.746.538.538 0 01.492.956 4.979 4.979 0 00-2.325 3.925l-.373 3.226a1.023 1.023 0 001.021.994h12.181a.879.879 0 00.877-.853l-.376-3.528c-.288-1.979-1-3.229-2.185-3.8a.538.538 0 11.47-.968c1.526.742 2.436 2.258 2.782 4.637l.382 3.58a.5.5 0 010 .057 1.956 1.956 0 01-1.944 1.953z"
          transform="translate(-1429.938 -579.275)"
        />
        <path
          id="prefix__Path_649"
          data-name="Path 649"
          d="M1441.874 573.7a5.688 5.688 0 115.688-5.688 5.694 5.694 0 01-5.688 5.688zm0-10.3a4.612 4.612 0 104.612 4.612 4.617 4.617 0 00-4.612-4.612z"
          transform="translate(-1433.759 -562.326)"
        />
        <g
          id="prefix__Group_340"
          data-name="Group 340"
          transform="translate(3.623 16.137)"
        >
          <path
            id="prefix__Path_650"
            data-name="Path 650"
            d="M1439.8 608.768a.538.538 0 01-.538-.538v-3.842a.538.538 0 011.075 0v3.842a.537.537 0 01-.537.538z"
            transform="translate(-1439.26 -603.851)"
          />
          <path
            id="prefix__Path_651"
            data-name="Path 651"
            d="M1460.154 608.768a.538.538 0 01-.538-.538v-3.842a.538.538 0 011.076 0v3.842a.538.538 0 01-.538.538z"
            transform="translate(-1451.705 -603.851)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Account;
