import React, { SVGProps } from "react";

const EditorialFilled = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      data-name="Zet in nwsbrief fill"
      xmlns="http://www.w3.org/2000/svg"
      width={14.33}
      height={11.954}
      viewBox="0 0 14.33 11.954"
      fill="currentColor"
      {...props}
    >
      <path
        data-name="Path 1873"
        d="M5.884 7.2l-.786-.405L13.672.001.251 4.326a.36.36 0 00-.065.658l4.552 2.532v4.079a.36.36 0 00.23.336.36.36 0 00.4-.093L6.859 10.2a.36.36 0 00-.533-.485l-.863.949V7.918l5.315 2.958a.359.359 0 00.175.045.365.365 0 00.136-.026.362.362 0 00.21-.231L14.335.424z"
      />
    </svg>
  );
};

export default EditorialFilled;
