import React from "react";
import { DateRangePicker, Icon, IconButton } from "rsuite";
import { components } from "../../types/openapi";
import { LayoutContext } from "../../provider/LayoutProvider";
import { BootstrapSize } from "../../inc/constants";
import openapi from "../../openapi.json";
import DateRangeHeader from "./DateRangeHeader";
import {
  DATE_FORMAT,
  DATE_FORMAT_REVERSE,
  localeFormat,
  periodTypeToDateRange,
  TDateRange,
} from "../../inc/date";
import Datepicker from "../../icons/Datepicker";
import { I18nContext } from "../../provider/I18nProvider";
import { createRoot } from "react-dom/client";

interface IDateRangeProps {
  searchEngineRequest: components["schemas"]["SearchEngineRequest"];
  onChange?: (
    searchEngineRequest: components["schemas"]["SearchEngineRequest"],
  ) => void;
}

const periodTypeConfigs =
  openapi.components.schemas.SearchEngineRequest.properties.periodType.enum.reduce<{
    [label: string]: {
      dateRange: [Date, Date];
      stringifiedDateRange: string;
    };
  }>((prev, periodType) => {
    if (periodType === "all") {
      prev[periodType] = {
        dateRange: [new Date("2010-01-01"), new Date()],
        stringifiedDateRange: JSON.stringify([
          new Date("2010-01-01"),
          new Date(),
        ]),
      };
      return prev;
    }

    let dateRange = periodTypeToDateRange(
      periodType as components["schemas"]["SearchEngineRequest"]["periodType"],
    );
    if (!dateRange) {
      return prev;
    }
    prev[periodType] = {
      dateRange,
      stringifiedDateRange: JSON.stringify(dateRange),
    };
    return prev;
  }, {});

const SearchEngineDateRange = ({
  onChange,
  searchEngineRequest,
}: IDateRangeProps) => {
  const { windowOuterWidth } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const value = React.useMemo<TDateRange>(() => {
    switch (searchEngineRequest.periodType) {
      case "custom":
        return searchEngineRequest.startDate && searchEngineRequest.endDate
          ? [
              new Date(searchEngineRequest.startDate),
              new Date(searchEngineRequest.endDate),
            ]
          : [];

      default:
        return periodTypeToDateRange(searchEngineRequest.periodType) || [];
    }
  }, [
    searchEngineRequest.endDate,
    searchEngineRequest.periodType,
    searchEngineRequest.startDate,
  ]);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    const handleClickOutsideDateRangePicker = (event: any) => {
      const dateRangePickers = document.getElementsByClassName(
        "rs-picker-daterange-menu",
      );
      if (
        dateRangePickers.length &&
        !dateRangePickers[0].contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideDateRangePicker);

    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideDateRangePicker,
      );
    };
  }, [isOpen]);

  const renderDatePickerHeader = React.useCallback(() => {
    if (typeof window === "undefined") {
      return;
    }
    const dateRangeHeaders = window.document.getElementsByClassName(
      "rs-picker-daterange-header",
    );
    if (
      !dateRangeHeaders.length ||
      dateRangeHeaders[0].innerHTML !== t("all")
    ) {
      return;
    }

    createRoot(dateRangeHeaders[0]).render(
      <DateRangeHeader
        periodType={searchEngineRequest.periodType}
        startDate={
          searchEngineRequest.startDate
            ? new Date(searchEngineRequest.startDate)
            : new Date()
        }
        endDate={
          searchEngineRequest.endDate
            ? new Date(searchEngineRequest.endDate)
            : new Date()
        }
      />,
    );
  }, [searchEngineRequest, t]);

  return (
    <DateRangePicker
      appearance="subtle"
      disabled={!onChange}
      className={`date-range`}
      cleanable={false}
      placement={
        windowOuterWidth < BootstrapSize.XL ? "bottomStart" : "bottomEnd"
      }
      format={DATE_FORMAT}
      size="xs"
      open={isOpen}
      onEntering={renderDatePickerHeader}
      onClick={onChange ? () => setIsOpen(!isOpen) : undefined}
      onOk={() => setIsOpen(false)}
      showOneCalendar={windowOuterWidth < BootstrapSize.SM}
      ranges={Object.keys(periodTypeConfigs).map((periodType) => ({
        label: t(periodType),
        value: periodTypeConfigs[periodType].dateRange,
      }))}
      renderValue={(value) => (
        <DateRangeHeader
          periodType={searchEngineRequest.periodType}
          startDate={value[0] as Date}
          endDate={value[1] as Date}
        />
      )}
      onChange={(value) => {
        if (!onChange) {
          return;
        }
        const stringifiedValue = JSON.stringify(value);
        const periodType = Object.keys(periodTypeConfigs).find(
          (period) =>
            periodTypeConfigs[period].stringifiedDateRange === stringifiedValue,
        ) as
          | components["schemas"]["SearchEngineRequest"]["periodType"]
          | undefined;
        onChange(
          (value[0] && !periodType) || periodType === "custom"
            ? {
                ...searchEngineRequest,
                periodType: "custom",
                startDate: localeFormat(value[0] as Date, DATE_FORMAT_REVERSE),
                endDate: localeFormat(value[1] as Date, DATE_FORMAT_REVERSE),
              }
            : {
                ...searchEngineRequest,
                periodType: periodType || "all",
                startDate: undefined,
                endDate: undefined,
              },
        );
        setIsOpen(false);
      }}
      placeholder={t("all")}
      value={value}
      toggleComponentClass={IconButton}
      icon={
        <Icon
          icon="avatar"
          style={{ color: "#1e324e" }}
          componentClass={Datepicker}
        />
      }
    />
  );
};
export default SearchEngineDateRange;
