import { Progress, Message } from "rsuite";
import React from "react";
import { components } from "../../types/openapi";
import { TypeAttributes } from "rsuite/lib/@types/common";
import axios from "../../inc/axios";
import { AUTH_LOCAL_STORAGE_KEY } from "../../provider/AuthProvider";
import { I18nContext } from "../../provider/I18nProvider";

interface IJobProgressMessageProps {
  onFinish?: () => void;
  onClose: () => void;
  job: components["schemas"]["Job"];
  jobStatus: components["schemas"]["JobStatus"];
}

const JobProgressMessage = (props: IJobProgressMessageProps) => {
  const { job, onClose, onFinish } = props;
  const { t } = React.useContext(I18nContext);
  const localAuth = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  const auth = localAuth ? JSON.parse(localAuth) : undefined;
  const [jobStatus, setJobStatus] = React.useState<
    components["schemas"]["JobStatus"]
  >(props.jobStatus);
  React.useEffect(() => {
    if (
      !job.jobId ||
      ["running", "wait"].indexOf(jobStatus.jobStatus as string) === -1
    ) {
      return;
    }
    const updateJobStatus = () => {
      axios
        .get<components["schemas"]["JobStatus"]>(`/job/crud/${job.jobId}`)
        .then((res) => {
          if (res.data.jobStatus === "finished" && onFinish) {
            onFinish();
          }
          setJobStatus(res.data);
        })
        .catch((e:any) => {
          setJobStatus((jobStatus) => ({
            ...jobStatus,
            jobStatus: "error",
            result: e.message,
          }));
        });
    };
    const intervalId = setInterval(updateJobStatus, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [job, jobStatus, onFinish]);

  let title: string;
  let type: TypeAttributes.Status;
  let description: React.ReactNode;

  switch (jobStatus.jobStatus) {
    case "wait":
      title = t("backgroundTask_waiting");
      type = "info";
      description = (
        <div className="mt-4">
          <Progress.Circle percent={0} />
        </div>
      );
      break;

    case "error":
      title = t("backgroundTask_error");
      type = "error";
      description = (
        <div>{t(jobStatus.result || "genericErrorMessage_short")}</div>
      );
      break;

    case "finished":
      title = t("backgroundTask_finished");
      type = "success";
      let extension = "";
      if (job.jobType.indexOf("Xlsx") >= 0) {
        extension = "xlsx";
      }
      if (job.jobType.indexOf("Pdf") >= 0) {
        extension = "pdf";
      }
      const url =
        auth && extension
          ? `${axios.defaults.baseURL}job/download/${job.jobId}/export.${extension}?bearer=${auth.bearer}`
          : null;
      if (url) {
        window.open(url);
        description = (
          <a href={url} target="_blank" rel="noreferrer">
            {t("downloadNow")}
          </a>
        );
      } else {
        setTimeout(onClose, 5000);
        description = <div>{t("thisTaskIsReady")}</div>;
      }
      break;

    case "running":
    default:
      type = "info";
      title = t("backgroundTask_running");
      description = (
        <div className="mt-4">
          <Progress.Circle percent={jobStatus.progress || 0} />
        </div>
      );
  }

  return <Message type={type} title={title} description={description} />;
};

export default JobProgressMessage;
