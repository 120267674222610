import React from "react";
import { components } from "../../../../../types/openapi";
import useDimensions from "../../../../../hooks/useDimensions";

import "./index.scss";

interface IItemBodyProps {
  searchEngineResult: components["schemas"]["SearchEngineResult"];
}

const CONTENT_HEIGHT = 104;
const TITLE_BOTTOM_MARGIN = 8;
const BODY_LINE_HEIGHT = 16;

const ItemBody = ({ searchEngineResult }: IItemBodyProps) => {
  const [headingRef, headingDimensions] = useDimensions();
  const { headline, summary = "" } = searchEngineResult;
  const bodyHeight = React.useMemo(
    () =>
      Math.max(
        headingDimensions?.height
          ? Math.floor(
              (CONTENT_HEIGHT - headingDimensions.height - 8) /
                BODY_LINE_HEIGHT,
            ) * BODY_LINE_HEIGHT
          : CONTENT_HEIGHT,
        0,
      ),
    [headingDimensions?.height],
  );

  return (
    <>
      <div style={{ minHeight: CONTENT_HEIGHT }}>
        <h4 style={{ marginBottom: TITLE_BOTTOM_MARGIN }} ref={headingRef}>
          {headline}
        </h4>
        <p
          style={{
            fontSize: 12,
            lineHeight: "16px",
            height: bodyHeight,
            overflow: "hidden",
          }}
          dangerouslySetInnerHTML={{
            __html: summary.substr(0, 150).replace(/###/g, "strong"),
          }}
        />
      </div>
    </>
  );
};

export default ItemBody;
