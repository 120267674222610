import React from "react";
import SchemaTable from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../types/openapi";
import { Alert, Icon, IconButton, Popover } from "rsuite";
import { getDeleteRequestIcon } from "../../icons";
import { I18nContext } from "../../provider/I18nProvider";
import useUsers from "../../hooks/useUsers";
import useDeleteRequests from "../../hooks/useDeleteRequests";
import MwWhisper from "../../components/MwWhisper";
import { AuthContext } from "../../provider/AuthProvider";
import { format } from "date-fns";
import axios, { AxiosError } from "axios";
import { DATE_FORMAT, parseDateWithOptionalTime } from "../../inc/date";
import HelpIcon from "../../components/HelpIcon";
import { IMaartenError } from "../../types";

const schema = openapi.components.schemas.DeleteRequest as oas30.SchemaObject;

const DeleteRequestsView = () => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { deleteRequests, refresh } = useDeleteRequests();

  // Always refresh when view is mounted
  // eslint-disable-next-line
  React.useEffect(refresh, []);
  const users = useUsers();

  return (
    <SchemaTable<components["schemas"]["DeleteRequest"]>
      data={deleteRequests ? Object.values(deleteRequests) : []}
      schema={schema}
      globalSearch
      sortable
      columnsConfigs={[
        {
          name: "customerMediaItemId",
          hidden: true,
        },
        {
          name: "mediaType",
          flexGrow: 0,
          align: "center",
          renderCell: (rowData) => {
            const icon = getDeleteRequestIcon(rowData);
            return icon ? (
              <MwWhisper
                trigger="hover"
                speaker={<Popover>{rowData.mediaType}</Popover>}
              >
                {icon}
              </MwWhisper>
            ) : (
              rowData.mediaType || ""
            );
          },
        },
        {
          name: "publicationDate",
          width: 120,
          renderCell: (rowData) =>
            rowData.publicationDate
              ? format(
                  parseDateWithOptionalTime(rowData.publicationDate),
                  DATE_FORMAT,
                )
              : "",
        },
        { name: "source" },
        { name: "title" },
        {
          name: "userId",
          title: t("deletedBy"),
          renderCell: (rowData) => {
            if (!auth || !users) {
              return "...";
            }
            const user = users.find(
              (user) =>
                user.userId === rowData.userId &&
                user.groupId === auth.jwt.currentGroupId,
            );
            return user ? user.userName : "";
          },
        },
        { name: "reason" },
        {
          name: "deleteRequestStatus",
          width: 250,
          renderCell: (rowData) => {
            return (
              <div className="d-flex align-items-center">
                <IconButton
                  appearance="subtle"
                  className="me-1"
                  size="sm"
                  disabled={rowData.deleteRequestStatus === "creditApproved"}
                  onClick={() => {
                    axios
                      .request({
                        method: "delete",
                        url: `/deleteRequest/crud/${rowData.customerMediaItemId}`,
                      })
                      .then(refresh)
                      .catch((err: AxiosError<IMaartenError>) => {
                        Alert.error(
                          t(err.response?.data.error || "genericErrorMessage"),
                        );
                      });
                  }}
                  icon={<Icon icon="history" title="Creditatie terugdraaien" />}
                />
                <span>
                  {t(
                    rowData.reason && rowData.deleteRequestStatus
                      ? rowData.deleteRequestStatus
                      : "deleted",
                  )}
                </span>
                {rowData.rejectReason ? (
                  <HelpIcon>{rowData.rejectReason}</HelpIcon>
                ) : null}
              </div>
            );
          },
        },
        { name: "rejectReason", hidden: true },
      ]}
    />
  );
};
export default DeleteRequestsView;
