import React, { SVGProps } from "react";

const MagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12.166}
    viewBox="0 0 12 12.166"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Group_1197">
      <path
        id="prefix__Path_1587"
        d="M5.247 10.494a5.247 5.247 0 115.248-5.247 5.253 5.253 0 01-5.248 5.247m0-9.36A4.113 4.113 0 109.36 5.247a4.117 4.117 0 00-4.113-4.112"
      />
      <path
        id="prefix__Path_1588"
        d="M5.649 9.171a.567.567 0 01-.567-.571 3.363 3.363 0 013.359-3.355.567.567 0 010 1.135A2.226 2.226 0 006.217 8.6a.567.567 0 01-.567.567"
        transform="translate(-2.998 -3.094)"
      />
      <path
        id="prefix__Path_1589"
        d="M24.86 25.832a.567.567 0 01-.4-.166l-1.534-1.534a.567.567 0 01.8-.8l1.534 1.534a.567.567 0 01-.4.968"
        transform="translate(-13.427 -13.666)"
      />
    </g>
  </svg>
);
export default MagnifyingGlass;
