import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  ControlLabel,
  FlexboxGrid,
  Form,
  HelpBlock,
  Icon,
  SelectPicker,
  Table,
} from "rsuite";

import SchemaFormBody, { TSchemaFormErrors } from "../SchemaFormBody";
import openapi from "../../openapi.json";
import React from "react";
import { oas30 } from "openapi3-ts";
import { components } from "../../types/openapi";
import { resetPassword } from "./helpers";
import { I18nContext } from "../../provider/I18nProvider";
import { uniq } from "lodash";
import useCustomers from "../../hooks/useCustomers";
import useGroups from "../../hooks/useGroups";
import CheckCell from "../table/CheckCell";
import { AuthContext } from "../../provider/AuthProvider";

import "./index.scss";
import useNewContentNotificationCustomerUserLinks from "../../hooks/useNewContentNotificationCustomerUserLinks";

const UserSchema = openapi.components.schemas.User as oas30.SchemaObject;
const { properties } = UserSchema;
if (!properties) {
  throw new Error("Incomplete user schema");
}
const { customerLinkIds, groupId, roles, ...filteredProperties } = properties;

interface IUserFormProps {
  errors: TSchemaFormErrors<components["schemas"]["User"]>;
  setUser: (user: components["schemas"]["User"]) => void;
  user: components["schemas"]["User"];
}

const UserForm = ({ errors, setUser, user }: IUserFormProps) => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const [updateHack, setUpdateHack] = React.useState(0);
  const [isInactiveVisible, setIsInactiveVisible] =
    React.useState<boolean>(false);

  const { newContentNotificationCustomerUserLinks, hydrate } =
    useNewContentNotificationCustomerUserLinks();

  const currentUserRoles = auth ? auth.jwt.userRoles : [];
  const isNewUser = !user.userId;
  const groups = useGroups();
  const customers = useCustomers();

  React.useEffect(() => {
    hydrate(user.groupId);
  }, [hydrate, user.groupId]);

  const groupCustomers = React.useMemo(
    () =>
      customers
        ? Object.values(customers)
            .filter((customer) => customer.groupId === user?.groupId)
            .sort((a, b) => {
              const aIsSelected =
                user.customerLinkIds.indexOf(a.customerLinkId as string) >= 0;
              const bIsSelected =
                user.customerLinkIds.indexOf(b.customerLinkId as string) >= 0;
              if (aIsSelected !== bIsSelected) {
                return aIsSelected ? -1 : 1;
              }
              return a.customerName < b.customerName ? -1 : 1;
            })
        : [],
    // https://www.notion.so/c700a5b224564b5395ec6c30144d735a?v=886ff64714724bb2a742a4953b2fdf44&p=363ecaa89ae34b2e84cbe573b4b60fe4
    // Alleen klanten sorteren na opslaan
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customers, user?.groupId, user?.userId],
  );

  const onHasAccessToAllChange = React.useCallback(
    (_: unknown, selected: boolean) => {
      if (selected) {
        setUser({
          ...user,
          customerLinkIds: uniq([
            ...user.customerLinkIds,
            ...groupCustomers
              .filter((customer) =>
                isInactiveVisible ? true : customer.active,
              )
              .map((customer) => customer.customerLinkId as string),
          ]),
        });
        return;
      }
      setUser({
        ...user,
        customerLinkIds: user.customerLinkIds.filter(
          (customerLinkId) =>
            !groupCustomers.find(
              (customer) => customer.customerLinkId === customerLinkId,
            ),
        ),
      });
    },
    [groupCustomers, isInactiveVisible, setUser, user],
  );

  const data = React.useMemo(
    () =>
      groupCustomers
        .filter((customer) => (isInactiveVisible ? true : customer.active))
        .map((customer) => ({
          ...customer,
          isSelected:
            user.customerLinkIds.indexOf(customer.customerLinkId as string) >=
            0,
        })),
    [groupCustomers, user.customerLinkIds, isInactiveVisible],
  );

  const toggleCustomerSelected = React.useCallback(
    (customer: components["schemas"]["Customer"]) => {
      const customerLinkIds = user.customerLinkIds.filter(
        (customerLinkId) => customerLinkId !== customer.customerLinkId,
      );
      setUser({
        ...user,
        customerLinkIds:
          customerLinkIds.length === user.customerLinkIds.length
            ? [...user.customerLinkIds, customer.customerLinkId as string]
            : customerLinkIds,
      });
    },
    [setUser, user],
  );

  React.useEffect(() => {
    const hackTimeout = setTimeout(() => {
      setUpdateHack((updateHack) => updateHack + 1);
    }, 1000);
    return () => {
      if (hackTimeout) {
        clearTimeout(hackTimeout);
      }
    };
  }, []);

  const inactiveCustomerCount = React.useMemo(
    () => groupCustomers.filter((customer) => !customer.active).length,
    [groupCustomers],
  );

  const isCurrentUserSuperUser = currentUserRoles.indexOf("superuser") >= 0;
  const isCurrentUserManager = currentUserRoles.indexOf("manager") >= 0;
  const canEditRights = isCurrentUserSuperUser || isCurrentUserManager;
  const userRoles = user.roles || [];
  const hasAccessToSomeGroupCustomers = !!groupCustomers.find(
    (groupCustomer) =>
      user.customerLinkIds.indexOf(groupCustomer.customerLinkId as string) >= 0,
  );
  const hasAccessToAllGroupCustomers = !groupCustomers.find(
    (groupCustomer) =>
      user.customerLinkIds.indexOf(groupCustomer.customerLinkId as string) ===
      -1,
  );

  return (
    <Form className="modal__content__form user-form">
      <FlexboxGrid className="modal__content__form__rights">
        <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
          <div className="modal__content__form__profile">
            <h4 className="modal__content__form__profile__title">
              {t("userForm_personal")}
            </h4>
            <SchemaFormBody<components["schemas"]["User"]>
              schema={{
                ...UserSchema,
                properties: filteredProperties,
              }}
              config={
                isNewUser
                  ? {}
                  : {
                      userName: {
                        label: (
                          <ControlLabel className="label" htmlFor={`userName`}>
                            {t("userName")}
                          </ControlLabel>
                        ),
                        formControlProps: {
                          plaintext: true,
                        },
                      },
                    }
              }
              onChange={(updatedUser) => {
                setUser(updatedUser);
              }}
              value={user}
              errors={errors}
              inputClassName="input-gray"
            />
          </div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} xs={24} md={12}>
          <div>
            <h4>{t("password")}</h4>
            {user.userId ? (
              <Button
                appearance="ghost"
                className="btn--custom"
                size="xs"
                color="blue"
                onClick={() => resetPassword(user)}
              >
                {t("userForm_requestNewPassword")}
              </Button>
            ) : (
              <p>{t("userForm_requestFirstPassword")}</p>
            )}
            <h4 className="mt-5">{t("userForm_rights")}</h4>
            {!canEditRights ? (
              <HelpBlock className="mb-3">
                {t("userForm_noRightToSettingsMessage")}
              </HelpBlock>
            ) : null}
            <CheckboxGroup name="checkboxList">
              {openapi.components.schemas.UserRole.enum.map((role) => {
                if (
                  ["superuser", "dashboardOnly", "benchmarkOnly"].indexOf(
                    role,
                  ) >= 0
                ) {
                  return null;
                }
                if (
                  role === "automaticSearchEngineQueryManager" &&
                  !isCurrentUserSuperUser
                ) {
                  return null;
                }
                const isChecked = userRoles.indexOf(role as "manager") >= 0;
                return (
                  <Checkbox
                    disabled={!canEditRights}
                    key={role}
                    checked={userRoles.indexOf(role as "manager") >= 0}
                    onChange={() => {
                      const newUser = {
                        ...user,
                        roles: isChecked
                          ? userRoles.filter((userRole) => role !== userRole)
                          : [...userRoles, role as "manager"],
                      };
                      if (
                        !isChecked &&
                        role === "manager" &&
                        userRoles.indexOf("invoice") === -1
                      ) {
                        newUser.roles.push("invoice");
                      }
                      setUser(newUser);
                    }}
                  >
                    {t(role)}
                  </Checkbox>
                );
              })}
            </CheckboxGroup>
          </div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <hr />
      <FlexboxGrid className="modal__content__form__rights">
        <FlexboxGrid.Item
          className="modal__content__form__right"
          componentClass={Col}
          xs={24}
        >
          <div className="d-flex">
            <h4 className="flex-grow-1">{t("userForm_accessToAccounts")}</h4>
            <Checkbox
              className="float-right"
              checked={isInactiveVisible}
              disabled={inactiveCustomerCount < 1}
              onChange={() => {
                setIsInactiveVisible((isInactiveVisible) => !isInactiveVisible);
              }}
            >
              {t("userForm__showInactiveAccounts")}
            </Checkbox>
          </div>
          {!canEditRights ? (
            <HelpBlock className="mb-3">
              {t("userForm_noRightToSettingsMessage")}
            </HelpBlock>
          ) : null}
          {errors && errors.customerLinkIds ? (
            <HelpBlock>{t(errors.customerLinkIds)}</HelpBlock>
          ) : null}
          {currentUserRoles.indexOf("superuser") >= 0 && groups ? (
            <SelectPicker
              labelKey="groupName"
              valueKey="groupId"
              data={groups ? Object.values(groups) : []}
              value={user.groupId}
              onChange={(groupId) => {
                setUser({
                  ...user,
                  groupId,
                });
              }}
              style={{ width: 300 }}
              placeholder={t("userForm_chooseAGroup")}
            />
          ) : null}
          <hr />
          <Table
            height={400 + updateHack}
            data={data}
            rowHeight={67}
            onRowClick={toggleCustomerSelected}
            rowClassName={(customer) =>
              `user-form__customer-table-row user-form__customer-table-row--${
                customer && customer.active ? "active" : "inactive"
              }`
            }
          >
            {/* @ts-ignore */}
            <Table.Column width={50} align="center">
              <Table.HeaderCell className="user-form__check-all-header">
                <div>
                  <Checkbox
                    inline
                    disabled={!canEditRights}
                    checked={hasAccessToAllGroupCustomers}
                    indeterminate={
                      !hasAccessToAllGroupCustomers &&
                      hasAccessToSomeGroupCustomers
                    }
                    onChange={onHasAccessToAllChange}
                  />
                </div>
              </Table.HeaderCell>
              <CheckCell
                dataKey="isSelected"
                disabled={!canEditRights}
                // onChange={toggleCustomerSelected}
              />
            </Table.Column>
            {/* @ts-ignore */}
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>{t("name")}</Table.HeaderCell>
              <Table.Cell dataKey={"customerName"} />
            </Table.Column>
            {/* @ts-ignore */}
            <Table.Column width={120} align="center">
              <Table.HeaderCell>{t("notifications")}</Table.HeaderCell>
              <Table.Cell dataKey={"customerId"}>
                {(customer: components["schemas"]["Customer"]) => {
                  const hasNotifications =
                    !!newContentNotificationCustomerUserLinks?.find(
                      (newContentNotificationCustomerUserLink) =>
                        newContentNotificationCustomerUserLink.customerLinkId ===
                          customer.customerLinkId &&
                        newContentNotificationCustomerUserLink.userId ===
                          user.userId,
                    );
                  return hasNotifications ? <Icon icon="check" /> : null;
                }}
              </Table.Cell>
            </Table.Column>
            {/* @ts-ignore */}
            <Table.Column width={100} align="center">
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey={"active"}>
                {(rowData: components["schemas"]["Customer"]) =>
                  rowData.active ? (
                    <div>Actief</div>
                  ) : (
                    <Icon
                      icon={"unlink"}
                      style={{ marginLeft: 4 }}
                      title={t("inactiveCustomerTooltip")}
                    />
                  )
                }
              </Table.Cell>
            </Table.Column>
          </Table>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
};

export default UserForm;
