import React, { SVGProps } from "react";

function QuestionMark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <circle cx={7} cy={7} r={7} fill="#1e324e" />
      <text
        fill="#fff"
        fontFamily="Gelion-Regular, Gelion"
        fontSize={11}
        transform="translate(7 11)"
      >
        <tspan x={-2.596} y={0}>
          {"?"}
        </tspan>
      </text>
    </svg>
  );
}

export default QuestionMark;
