import React from "react";
import { sum } from "lodash";
import { ESplitType, EValueType } from "../index";
import { chartSortedSentiments } from "../../../inc/sentimentOptions";
import { components } from "../../../types/openapi";
import { EChartType } from "../../../inc/enums";
import { I18nContext } from "../../../provider/I18nProvider";
import { REMAINING_AUTHOR_NAME } from "../../../inc/constants";

export function getSortedAnalysisItemKeys(
  analysis: components["schemas"]["Analysis"],
  splitType: ESplitType
) {
  const valueGroupKeys = [...analysis.valueGroup.keys()];
  if (splitType === ESplitType.sentiment) {
    // E.g. chartSortedSentiments = [1,2,3,0] and valueGroup is ["2"]
    return valueGroupKeys.sort((keyA, keyB) =>
      chartSortedSentiments.indexOf(parseInt(analysis.valueGroup[keyA], 10)) >
      chartSortedSentiments.indexOf(parseInt(analysis.valueGroup[keyB], 10))
        ? 1
        : -1
    );
  }
  return valueGroupKeys.sort((keyA, keyB) =>
    analysis.items.reduce((prev, item) => prev + item.values[keyA], 0) >
    analysis.items.reduce((prev, item) => prev + item.values[keyB], 0)
      ? -1
      : 1
  );
}

export function getSortedAnalysisSplitItemKeys(
  analysisSplit: components["schemas"]["AnalysisSplit"],
  filter?: string
) {
  const result = [...analysisSplit.items.keys()].sort((keyA, keyB) => {
    if (analysisSplit.perGroup[keyA] === REMAINING_AUTHOR_NAME) {
      return 1;
    }
    if (analysisSplit.perGroup[keyB] === REMAINING_AUTHOR_NAME) {
      return -1;
    }
    return sum(analysisSplit?.items[keyA]) > sum(analysisSplit?.items[keyB])
      ? -1
      : 1;
  });
  if (!filter) {
    return result;
  }
  const valueGroupIndex = analysisSplit.valueGroup.indexOf(filter);
  if (valueGroupIndex < 0) {
    return result;
  }
  return result.filter(
    (itemKey) => !!analysisSplit.items[itemKey][valueGroupIndex]
  );
}

export function getVisibleValueGroupKeys(
  analysisSplit: components["schemas"]["AnalysisSplit"],
  splitType: ESplitType
) {
  if (splitType === ESplitType.sentiment) {
    return chartSortedSentiments;
  }
  return analysisSplit
    ? [...analysisSplit.valueGroup.keys()].filter((valueGroupKey) =>
        analysisSplit.items.find((item) => item[valueGroupKey] > 0)
      )
    : [];
}

export function getSeriesMaxValue(
  series: ApexAxisChartSeries | ApexNonAxisChartSeries
) {
  if (!series.length) {
    return 0;
  }
  if (typeof series[0] === "number") {
    return Math.max(...(series as ApexNonAxisChartSeries));
  }
  return Math.max(
    ...(series[0].data as number[]).map((_number, dataKey) =>
      (series as ApexAxisChartSeries).reduce(
        (prev, serie) => prev + (serie.data[dataKey] as number),
        0
      )
    )
  );
}

export function useSeries(
  analysisSplit: components["schemas"]["AnalysisSplit"] | null | undefined,
  chartType: EChartType,
  splitType: ESplitType,
  valueType: EValueType,
  filter: string,
  limit: number,
  addRemaining: boolean,
  customValueGroups?: string[]
) {
  const { t } = React.useContext(I18nContext);
  const sortedAnalysisSplitItemKeys = React.useMemo(
    () =>
      analysisSplit
        ? getSortedAnalysisSplitItemKeys(analysisSplit, filter)
        : [],
    [analysisSplit, filter]
  );
  const visibleValueGroupKeys = React.useMemo(
    () =>
      analysisSplit ? getVisibleValueGroupKeys(analysisSplit, splitType) : [],
    [analysisSplit, splitType]
  );
  if (!analysisSplit || !analysisSplit.valueGroup.length) {
    return [];
  }

  const visibleItems = (
    customValueGroups
      ? customValueGroups.map((selectedValueGroup) =>
          analysisSplit.perGroup.indexOf(selectedValueGroup)
        )
      : sortedAnalysisSplitItemKeys.slice(0, limit)
  )
    .map((itemKey) => analysisSplit.items[itemKey])
    .filter((result) => !!result);
  if (!customValueGroups && addRemaining) {
    visibleItems.push(
      sortedAnalysisSplitItemKeys.slice(limit).reduce(
        (prev, remainingItemKey) => {
          analysisSplit.items[remainingItemKey].forEach((value, key) => {
            prev[key] += value;
          });
          return prev;
        },
        analysisSplit.valueGroup.map(() => 0)
      )
    );
  }
  if (chartType === EChartType.donut) {
    return visibleItems.map((item) => sum(item)) as ApexNonAxisChartSeries;
  }

  switch (splitType) {
    case ESplitType.sentiment:
      return visibleValueGroupKeys.map((sentiment) => {
        return {
          name: t(`sentiment${sentiment}`),
          data: visibleItems.map((item) => item[sentiment]),
        };
      }) as ApexAxisChartSeries;

    case ESplitType.mediatype:
      return visibleValueGroupKeys.map((key) => {
        return {
          name: t(`mediaType_${analysisSplit.valueGroup[key]}`),
          data: visibleItems.map((item) => item[key]),
        };
      }) as ApexAxisChartSeries;

    default:
      return [
        {
          name: t(`valueType_${valueType}`),
          data: visibleItems.map((item) => sum(item)),
        },
      ] as ApexAxisChartSeries;
  }
}
