import { CheckTree } from "rsuite";
import React from "react";
import useEditorials from "../../hooks/useEditorials";
import useEditorialRubrics from "../../hooks/useEditorialRubrics";

interface IEditorialRubrickCheckTreeProps {
  canSelectEditorials: boolean;
  forBulk?: boolean;
  value: string[];
  onToggle: (toggledEditorialRubricIds: string[], checked: boolean) => void;
}

const EditorialRubricCheckTree = ({
  canSelectEditorials,
  forBulk,
  value,
  onToggle,
}: IEditorialRubrickCheckTreeProps) => {
  const editorials = useEditorials();
  const editorialRubrics = useEditorialRubrics();
  const [expanded, setExpanded] = React.useState<string[]>();
  const data = React.useMemo(() => {
    if (!editorialRubrics || !editorials) {
      return [];
    }

    return Object.values(editorials).map((editorial) => ({
      value: `editorial__${editorial.editorialId}`,
      label: editorial.name,
      children: Object.values(editorialRubrics)
        .filter(
          (editorialRubric) =>
            editorialRubric.editorialId === editorial.editorialId
        )
        .map((editorialRubric) => ({
          value: editorialRubric.editorialRubricId,
          label: editorialRubric.name,
          // For the bulk display,
          // show a difference between all mediaitems are present in rubric
          // OR some mediaitems are present in rubric
          children: forBulk
            ? [
                {
                  value: `${editorialRubric.editorialRubricId}_0`,
                  label: "",
                },
                {
                  value: `${editorialRubric.editorialRubricId}_1`,
                  label: "",
                },
              ]
            : undefined,
        })),
    }));
  }, [editorialRubrics, editorials, forBulk]);

  React.useEffect(() => {
    if (expanded !== undefined || !editorials) {
      return;
    }
    const editorialIds = Object.keys(editorials);
    setExpanded(
      editorialIds.length === 1 ? [`editorial__${editorialIds[0]}`] : []
    );
  }, [editorials, expanded]);

  return (
    <CheckTree
      // required for re-render
      value={value}
      data={data}
      onSelect={(selectedNode) => {
        if (!editorialRubrics) {
          return;
        }
        if (selectedNode.value.startsWith("editorial__")) {
          const editorialId = selectedNode.value.substr(11);
          onToggle(
            Object.values(editorialRubrics)
              .filter(
                (editorialRubric) => editorialRubric.editorialId === editorialId
              )
              .map(
                (editorialRubric) => editorialRubric.editorialRubricId as string
              ),
            selectedNode.check
          );
          return;
        }
        onToggle([selectedNode.value], selectedNode.check);
      }}
      expandItemValues={expanded}
      onExpand={setExpanded}
      uncheckableItemValues={
        editorials && !canSelectEditorials
          ? Object.values(editorials).map(
              (editorial) => `editorial__${editorial.editorialId}`
            )
          : []
      }
      renderTreeNode={(node) => {
        if (!!node.parent) {
          return node.label;
        }

        const isActive = node.children?.some(
          (child: any) =>
            child.check ||
            (child?.children?.length &&
              child?.children.some((innerChild: any) => innerChild.check))
        );
        return (
          <span
            className={`components__add-to-editorial-nav-item__popover__node--parent ${
              isActive ? "font-weight-bold" : ""
            }`}
            onClick={() => {
              if (node.parentNode !== undefined) {
                // Only top level nodes should be expanded
                return;
              }

              setExpanded((currentExpanded) => {
                const newExpanded = currentExpanded ? [...currentExpanded] : [];
                const index = newExpanded.indexOf(node.value);

                // If node is already expanded, close that node
                if (index > -1) {
                  newExpanded.splice(index, 1);
                  return newExpanded;
                }

                // Add the current node the the expanded nodes
                return [...newExpanded, node.value];
              });
            }}
          >
            {node.label}
          </span>
        );
      }}
    />
  );
};

export default EditorialRubricCheckTree;
