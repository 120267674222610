import {
  TLocale,
  TLocaleCalendar,
  TLocaleData,
  TLocaleRsuiteData,
} from "./index";

const db: TLocaleData = {
  "Aan een woord niet genoeg":
    "Doesn't a single word suffice? The wizard wil help you compose a complex search request. Combine words or exclude them from your request.",
  "Aanpassen widgets": "Modify widgets",
  "Actieve filterbalk": "Active filter bar",
  "Bad Request": "Could not retrieve data. Please try again later",
  "Bijvoorbeeld het type of sentiment":
    "To zoom in on specific aspects within your monitored items. For example on media type or sentiment.",
  "Check de resultaten": "Check results",
  "Dan kan je de statistieken van die edities hier terugvinden":
    "Then you can find the statistics of those editions here.",
  "Dan kun je de naam hier aanpassen":
    "Then you can edit the name of this dashboard here, so that you can easily find it back in your navigation bar.",
  "Dashboard already exists": "There is already a dashboard with this name.",
  "Dashboards beheren": "Manage dashboards",
  "Dat kan hier met de zoekopdracht die jij zelf samenstelt":
    "Here we'll take you through the required steps to compose your own search request.",
  "Dat kun je hier terugvinden":
    "You can see that here. Besides, you can add recipients. This can be done in two ways: add a list of recipients at once or add them individually.",
  "De actieve punten in de filter verschijnen in deze balk":
    "The active filter categories appear in this bar as chips.",
  "De perfecte zoekopdracht samengesteld":
    "Have your found the perfect search request? Save it to re-use it a later time.",
  "De widgets zijn volledig zelf in te delen":
    "The widgets are completely customizable. You can change the size and order and add or remove widgets.",
  "Dit is de nieuwsbriefmodule": "This is the newsletter module",
  "Editorial already exists":
    "A newsletter with this name already exists. Please change the name of this newsletter.",
  "Heb je een editie verstuurd": "Did you send an edition?",
  "Hier kun je opgeslagen dashboards terugvinden of verwijderen":
    "Here you can retrieve or delete saved dashboards and possibly add new ones.",
  "Hier kun je zelf nieuwsbrieven aanmaken":
    "Here you can create your own newsletters to inform your stakeholders",
  "Hier staan je widgets": "Here are your widgets",
  "Hier vind je een nieuwsbrief die al is ingesteld":
    "Here you can find a newsletter that has already been set up",
  "Hier voeg je widgets toe": "Add widgets here",
  "In de widgets wordt je data getoond":
    "Widgets display your data. Widgets respond to your filter and vice versa. This is the message widget but there are many more.",
  "Is je dashboard naar wens": "Are you happy with your dashboard?",
  "Klik hier om widgets toe te voegen":
    "Click here to add widgets. You can choose from several types such as the message, highlight or label widget.",
  "LabelAutomator already exists":
    "An automator for this label already exists.",
  "Meer informatie over nieuwsbrieven": "More information on newsletters?",
  "Met de filter kun je de resultaten aanpassen":
    "Apply a filter to your dashboard",
  "Neem contact op met je accountmanager of kijk op onze supportpagina":
    "Take a look at the <a href='https://support.mediaweb.eu/' target='_blank'>support page</a> or contact your account manager.",
  "Network Error":
    "Something went wrong with your request. Try again later or contact us.",
  "No Filter": "No filter",
  "No mediaitems found": "No media items found for the PDF",
  "Request failed with status code 404": "This could not be found.",
  "Request failed with status code 405": "Incomplete request",
  "Request failed with status code 429":
    "Another PDF task is in progress. Try again later.",
  "Schaf de artikelen aan die je nodig hebt":
    'Purchase any article you require. You\'ll find these articles in your dashboards, marked with a search request called "Vanuit zoekmachine". Optionally add a label to your purchased articles.',
  "Sla je zoekopdracht op": "Save your search request",
  "Stel je zoekopdracht samen": "Compose your search request",
  "Vanuit hier kun je gemakkelijk en snel een editie versturen":
    "From here you can easily and quickly send an edition.",
  "Via deze knop kun je de widget aanpassen of verwijderen":
    "This button allows you to modify or delete the widget.",
  "Voer een zoekterm in en specificeer eventueel op mediumtype":
    "Enter you search term and optionally specify the medium type, source and/or period.",
  "Wil je naar een ander account":
    "Do you want to switch to another account? Then you can switch here. Each account has its own dashboards.",
  "Wil je zien wie je nieuwsbrieven ontvangt":
    "Would you like to see who is receiving your newsletter editions?",
  "Wissel van account": "Switch account",
  "Zelf zoeken in onze database": "Search our database yourself?",
  "editorial-issue-create-modal__disabled-title":
    "There are no subscribers yet. Please add subscribers first",
  "email already used by another subscriber":
    "There is already a subscriber with this e-mailaddress",
  "invalid email format": "This is not a valid email address.",
  "name already exists": "This name already exists",
  search: "Search",
  "must NOT be shorter than 1 characters": "This field should not be empty",
  "must NOT be shorter than 2 characters":
    "This field needs at least 2 characters",
  "must NOT be shorter than 5 characters":
    "This field needs at least 5 characters",
  "must NOT have fewer than 1 items": "At least 1 item must be chosen.",
  "should have filter": "Filter is required",
  'must match format "email"': "This should be a (valid) e-mail address.",
  'must match format "password"':
    "A password must consist of at least 8 characters, including a lowercase letter, an uppercase letter, a number, and a punctuation mark.",
  'must match format "uri"':
    "A valid URL begins with http:// or https:// followed by a domain name.",
  'must match pattern "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"':
    "A password must consist of at least 8 characters, including a lowercase letter, an uppercase letter, a number, and a punctuation mark.",
  'must match pattern "^(\\*|(([1-5]?[0-9])|(([1-5]?[0-9])-([1-5]?[0-9])))(?:,(([1-5]?[0-9])|(([1-5]?[0-9])-([1-5]?[0-9]))))*)\\s(\\*|((1?[0-9]|2[0-3])|((1?[0-9]|2[0-3])-(1?[0-9]|2[0-3])))(?:,((1?[0-9]|2[0-3])|((1?[0-9]|2[0-3])-(1?[0-9]|2[0-3]))))*)\\s(\\*|(([1-9]|[1-2][0-9]|3[0-1])|(([1-9]|[1-2][0-9]|3[0-1])-([1-9]|[1-2][0-9]|3[0-1])))(?:,(([1-9]|[1-2][0-9]|3[0-1])|(([1-9]|[1-2][0-9]|3[0-1])-([1-9]|[1-2][0-9]|3[0-1]))))*)\\s(\\*|(([1-9]|1[0-2]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([1-9]|1[0-2])-([1-9]|1[0-2]))|((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))))(?:,(([1-9]|1[0-2]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([1-9]|1[0-2])-([1-9]|1[0-2]))|((JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))))*)\\s(\\*|(([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)|((([0-6])-([0-6]))|((SUN|MON|TUE|WED|THU|FRI|SAT)-(SUN|MON|TUE|WED|THU|FRI|SAT))))(?:,(([0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)|((([0-6])-([0-6]))|((SUN|MON|TUE|WED|THU|FRI|SAT)-(SUN|MON|TUE|WED|THU|FRI|SAT)))))*)(\\s([1-2][0-9][0-9][0-9])(-([1-2][0-9][0-9][0-9]))?)?$"':
    "Must be a valid repeat pattern.",
  'must match pattern "^https?://.*facebook|flickr|foursquare|instagram|linkedin|pinterest|snapchat|tumblr|twitter|vimeo|whatsapp|youtube"':
    "This should be a valid social media URL",
  Ave: "AVE",
  Count: "Count",
  Email: "E-mail",
  Hulptool: "Wizard",
  LabelAlreadyInFilter: "Label is already active in filter",
  Login: "Login",
  MediaType: "Media type",
  PrValue: "PR Value",
  Reach: "Reach",
  Sort: "Sorting",
  Submit: "Submit",
  TEMPLATE_TYPE_ANALYSIS: "Analyse",
  TEMPLATE_TYPE_EMPTY: "Empty",
  TEMPLATE_TYPE_MONITORING: "Monitoring",
  TEMPLATE_TYPE_READING: "Read",
  WIDGET_TYPE_AUTHOR: "Authors",
  WIDGET_TYPE_AUTHOR_DESCRIPTION:
    "Check the most popular authors within your searches",
  WIDGET_TYPE_CATEGORY: "Categories",
  WIDGET_TYPE_CATEGORY_DESCRIPTION:
    "Check the most popular categories within your searches",
  WIDGET_TYPE_HIGHLIGHT: "Highlights",
  WIDGET_TYPE_HIGHLIGHT_DESCRIPTION:
    "Number of posts, reach, AVE and sentiment at a glance.",
  WIDGET_TYPE_LABEL: "Labels",
  WIDGET_TYPE_LABEL_DESCRIPTION:
    "Check the most popular labels within your searches",
  WIDGET_TYPE_MEDIAITEM: "Messages",
  WIDGET_TYPE_MEDIAITEM_DESCRIPTION:
    "Check your messages in one clear overview.",
  WIDGET_TYPE_MEDIA_TYPE: "Media type",
  WIDGET_TYPE_MEDIA_TYPE_DESCRIPTION: "Check your results over time.",
  WIDGET_TYPE_SEARCH_TOPIC: "Search topics",
  WIDGET_TYPE_SEARCH_TOPIC_DESCRIPTION: "Check the most popular search topics ",
  WIDGET_TYPE_SOURCE: "Sources",
  WIDGET_TYPE_SOURCE_DESCRIPTION:
    "Check the most popular sources within your searches",
  WIDGET_TYPE_TREND: "Trend",
  WIDGET_TYPE_TREND_DESCRIPTION: "Check your results over time.",
  accountManager: "Account manager",
  achtung: "Please note!",
  active: "Active",
  activeSourcesView_help:
    "Is there a source not listed that you would like to monitor? Or do you have a suggestion for our reach numbers?",
  activeSourcesView_letUsKnow: "Let us know via chat!",
  activeSourcesView_startDescription:
    "Search our sources list, to check if the source you'ld like to use is being monitored.",
  add: "Add",
  addAction: "Add",
  addAllSubscribersToApp: "Add all subscribers to App",
  addAllSubscribersToEmail: "Add all subscribers to Email",
  memberOfEmail: "E-mail newsletter",
  memberOfApp: "App user",
  addArticle: "Add article",
  addArticleTitle:
    "All articles you add from these search-results can be found in your media marked with searchtopic 'Vanuit zoekmachine'.",
  addEditorialRubric: "Add newsletter section",
  addEmail: "Add e-mail address",
  addItemsManually: "Add items manually",
  addLabels: "Add labels",
  addSearchResultMultiple:
    "You are about to add multiple search results to your media. This may influence your subscription costs.",
  addSearchResultSingle:
    "You are about to add a search result to your media. This may influence your subscription costs.",
  addSubscribers: "Add subscribers",
  addSubscribersConfirmation:
    "I hereby confirm that the above individuals have given their consent to become a subscriber to this newsletter",
  addToEditorial: "Add to newsletter",
  added: "Added",
  addedToEditorial: "Sent in newsletter",
  addedToEditorialRubric: "Selected for newsletter section",
  advancedMatchConfig_allOfThese: "All of these:",
  advancedMatchConfig_noneOfThese: "None of these",
  advancedMatchConfig_oneOfThese: "One of these:",
  advancedMatchConfig_searchArticlesWith: "Search articles with:",
  alerts: "Notifications",
  alertsView_startDescription:
    "Define a personal notification to receive updates per e-mail whenever a new article has been published.",
  alertsView_startTitle: "No notifications configured yet",
  all: "All",
  allItems: "All items",
  allowDuplicateMediaItemsAcrossSubjects: "Allow repetion of media items",
  allowDuplicateMediaItemsAcrossSubjects_description:
    "This option allows the same item for more newsletter sections",
  allowDuplicateMediaItemsAcrossSubjects_help:
    "When a media items belongs to more than one newsletter section, this item will regularly be added to the first newsletter section. \nIf you want the media item to appear in more than one section check this box.",
  apiDataProvider_fetchingFailed_category:
    "The retrieval of categories was unsuccessful",
  apiDataProvider_fetchingFailed_group:
    "The retrieval of rss exports was unsuccessful",
  apiDataProvider_fetchingFailed_invoice:
    "The retrieval of invoices was unsuccessful",
  apiDataProvider_fetchingFailed_label:
    "The retrieval of search engine search topics was unsuccessful",
  apiDataProvider_fetchingFailed_labelAutomator:
    "The retrieval of label automators was unsuccessful",
  apiDataProvider_fetchingFailed_newContentNotification:
    "The retrieval of periodic notifications was unsuccessful",
  apiDataProvider_fetchingFailed_newsAlert:
    "The retrieval of instant notifications was unsuccessful",
  apiDataProvider_fetchingFailed_rssExport:
    "The retrieval of rss exports was unsuccessful",
  apiDataProvider_fetchingFailed_searchEngineQuery:
    "The retrieval of search engine search topics was unsuccessful",
  apiDataProvider_fetchingFailed_searchTopic:
    "The retrieval of search topics was unsuccessful",
  apiDataProvider_fetchingFailed_subscriber:
    "The retrieval of subscribers was unsuccessful",
  apiDataProvider_fetchingFailed_benchmark:
    "The retrieval of analyses was unsuccessful",
  apiDataProvider_firstDashboardWillBeCreated:
    "Your first dashboard will be prepared...",
  apply: "Apply",
  applyEditorials: "Apply newsletters",
  applyEditorialsPlaceholder: "Select newsletters",
  approved: "Approved",
  areYouSure: "Are you sure?",
  areYouSureDeleteUser:
    "Are you sure? This will remove the account of {0}. This can not be reverted.",
  areYouSureUnsavedChanges:
    "Are you sure you want to continue without saving changes?",
  areYourSureDeleteEditorialEditorialRubricMediaItems:
    "When you delete your selection, all selected articles for the upcoming edition will be removed. You can start creating a new selction. Are you sure you want to delete the selection?",
  author: "Author",
  authorAsyncPopoverInitialLabel: "Search for an author...",
  authors: "Authors",
  authorsX: "Authors ({0})",
  automaticEditorialHasRubricsWithoutFilters:
    "There are sections that do not contain a filter. This means that articles from these sections will not be automatically prepared for your newsletter. Are you sure you want to continue?",
  automaticEditorialRubricWithoutFilter_help:
    "Notice! You have not set a filter for this section. This means that posts will not be automatically prepared for this section.",
  automaticSearchEngineQueryManager:
    "Option to add search results automatically (agent)",
  automatically: "Automatic",
  automaticallyAddResults: "Results are automaticly added",
  automaticallySendEditorial: "Send automatically",
  ave: "AVE",
  aveAsc: "AVE ascending",
  aveDesc: "AVE descending",
  aveMeaning: "Advertising Value Equivalency",
  backOfficer: "Backofficer",
  backgroundTask_error: "Error on background job",
  backgroundTask_finished: "Background job completed",
  backgroundTask_running: "Background job active",
  backgroundTask_waiting: "Busy starting...",
  buy: "Add",
  cancel: "Cancel",
  categories: "Categories",
  category: "Category",
  categoryAddedToFilter: "Added category {0} ",
  chooseTemplate: "Choose template",
  circulation: "Circulation",
  clickCount: "Number of times opened",
  close: "Close",
  closeAdvancedSearchEngineMatch: "Close wizard",
  closeAndDontShowAgain: "Close and don't show again",
  col_area: "Distribution area",
  col_author: "Author",
  col_ave: "AVE",
  col_category: "Category",
  col_circulation: "Circulation",
  col_insertDate: "Insert date",
  col_isFavourite: "Favorite",
  col_isInEditorial: "In newsletter",
  col_isRead: "Read",
  col_labels: "Labels",
  col_mediaType: "Media type",
  col_page: "Page",
  col_prValue: "PR-value",
  col_publicationDate: "Publication date",
  col_publicationIssue: "Publication issue",
  col_reach: "Reach",
  col_searchTopics: "Search topics",
  col_sentiment: "Sentiment",
  col_source: "Source",
  col_title: "Titel",
  col_url: "Link",
  col_wordCount: "Word count",
  columns: "Columns",
  combine: "Combine",
  components_MediaItem_LinkedArticlesAve: "AVE",
  components_MediaItem_LinkedArticlesCirculation: "Circulation",
  components_MediaItem_LinkedArticlesDate: "Date",
  components_MediaItem_LinkedArticlesFrequency: "Frequency",
  components_MediaItem_LinkedArticlesName: "Name",
  components_MediaItem_LinkedArticlesPage: "Page",
  components_MediaItem_LinkedArticlesRegion: "Region",
  components_MediaItem_doubleArticle: "Double article",
  components_MediaItem_doubleArticles: "Double articles",
  components_MediaItem_hide: "Hide",
  components_MediaItem_hideLog: "Hide log",
  components_MediaItem_issueName: "Edition",
  components_MediaItem_log_noResults:
    "At the moment there is no log of this item",
  components_MediaItem_show: "Show",
  components_MediaItem_showLog: "Show log",
  components_MediaItem_source: "Source",
  copied: "Copied",
  copyLink: "Copy link",
  count: "Count",
  cover: "Cover",
  createAlert: "Create notification",
  createAndSend: "Create and send",
  createDashboard: "Create dashboard",
  createDashboardTitle: "Add dashboard",
  createFirstEditorial: "Set up first newsletter",
  createModal_noArticles_selected:
    "There are no articles selected for this newsletter edition",
  createOption: 'Add label: "{0}"',
  createSubscribers: "Create subscribers",
  createdAt: "Created at",
  creditApproved: "Approved",
  creditRejected: "Rejected",
  creditRequestDeleteTypeCreditRequest:
    "Selection does NOT meet search criteria (reason is required)",
  creditRequestDeleteTypeDeleteRequest:
    "Selection meets search criteria (delete only)",
  creditRequestDeleteTypeIsRequired: "Please select an option",
  creditRequestMessageIsRequired:
    "It is important that we know why the selection does not match the search criteria. This to prevent future mistakes.",
  cron: "Repetition",
  cronEditorInputLabel_days: "Day(s)",
  cronEditorInputLabel_frequency: "Frequency of sending",
  cronEditorInputLabel_hours: "Since",
  cron_label_friday: "Fr",
  cron_label_monday: "Mo",
  cron_label_saturday: "Sa",
  cron_label_sunday: "Su",
  cron_label_thursday: "Th",
  cron_label_tuesday: "Tu",
  cron_label_wednesday: "We",
  currentPassword: "Former password",
  currentlyInDevelopment:
    "Currently under development. Usable as filter in the near future",
  customer: "Customer",
  customerLinkIds: "Customers",
  customerName: "Customer name",
  customerNo: "Customer number",
  customerPrValue: "PR value",
  customerSentiment: "Sentiment",
  customers: "Customers",
  daily: "Daily",
  dashboard: "Dashboard",
  dashboardCanBeSavedWhenDirty:
    "A dashboard can be saved only after changing it",
  dashboardShareRssDescription: "Export as RSS",
  dashboardShareRssTitle: "RSS",
  dashboardTemplateDialog_chooseATemplate: "Choose a preset in this menu",
  dashboardTemplateDialog_previewAndChoose:
    "Preview and choose one of the presets here",
  dashboardTemplateDialog_templateDescription:
    "Dashboards are still customizable after choosing a preset.\nWidgets can be added, deleted or changed in size or order.",
  dashboardTemplateDialog_templateType_analyse_description:
    "This template is suitable for analyzing your data. With graph widgets and instant insight figures you have insight about all your media items.",
  dashboardTemplateDialog_templateType_empty_description:
    "Start with an empty dashboard. With a few clicks, you can arrange the dashboard to your liking.",
  dashboardTemplateDialog_templateType_monitoring_description:
    "This template has all the widgets you need for complete monitoring.",
  dashboardTemplateDialog_templateType_read_description:
    "A preset suitable for seeing as many media items as possible at a glance.",
  dashboardTemplateDialog_withThisButtonYouCanCreateADashboard:
    "This button allows you to create a dashboard with the previewed preset.",
  dashboardTemplatesButtonConfirm:
    "You are about to modify your dashboard to a different template. You will loose the current configuration of your widgets. Are you sure you want to continue?",
  dashboardView_addWidget: "Add widget",
  dashboardView_emptyDashboard: "This dashboard is empty",
  dashboardView_emptyDashboardAlt: "This dashboard doesn't have any widgets",
  dashboardView_emptyDashboardDescription: "Add widgets to show your data.",
  dashboards: "Dashboards",
  date: "Date",
  defaultIntroText: "Default intro",
  defaultSenderName: "Default name",
  defaultSubject: "Default subject",
  delete: "Delete",
  deleteConfirmation: "Are you sure you want to delete this?",
  deleteEditorial: "Delete settings and newsletter editions",
  deleteEditorialEditorialRubricMediaItems: "Remove selection",
  deleteRequestStatus: "Status",
  deleteRubric: "Delete section",
  deleteSuccessful: "Succesfully deleted",
  deleteUser: "Delete user",
  deleted: "Deleted",
  deletedBy: "Deleted by",
  description: "Description",
  displayName: "Name",
  distributionProvinces: "Provinces",
  doYouWantToRecalculatePrValue: "Do you want to recalculate PR-value?",
  doubleArticles: "Doubles",
  download: "Download",
  downloadAsExcel: "Download as Excel",
  downloadAsPdf: "Download as PDF",
  downloadNow: "Downloading now",
  drenthe: "Drenthe",
  edit: "Edit",
  editAlert: "Edit notification",
  editEditorialConfig: "Edit newsletter configuration",
  editFilter: "Edit filter",
  editingFilter: "Edit filter",
  editorial: "Newsletter",
  editorialConfigView__bodyFont: "Body font",
  editorialConfigView__editorialIssueTitle: "Nieuwsbrief-editie titel",
  editorialConfigView__editorialIssueTitleHelper:
    "Deze klantnaam komt boven de nieuwsbrief te staan.",
  editorialConfigView__facebook: "Facebook URL",
  editorialConfigView__footer: "Regel footer",
  editorialConfigView__footerBackground: "Footer achtergrondkleur",
  editorialConfigView__footerColor: "Footer tekst kleur",
  editorialConfigView__footerColumn: "Footer kolom tekst",
  editorialConfigView__footerColumnHeading: "Footer kolom Header",
  editorialConfigView__footerColumnLinkLabel: "Footer kolom link tekst",
  editorialConfigView__footerColumnLinkUrl: "Footer kolom link URL",
  editorialConfigView__footerLink: "Footer link",
  editorialConfigView__footerLinkColor: "Footer links kleur",
  editorialConfigView__footerLinkUnderline: "Footer links onderstrepen",
  editorialConfigView__header__body: "Body",
  editorialConfigView__header__common: "Algemeen",
  editorialConfigView__header__footer: "Footer",
  editorialConfigView__header__headers: "Headers",
  editorialConfigView__header__show_per_article: "Toon per bericht",
  editorialConfigView__header__socials: "Socials",
  editorialConfigView__heading1: "Headers Kleur Rubrieken",
  editorialConfigView__heading2: "Headers Kleur Artikelen",
  editorialConfigView__heading3: "Footer Kleur Kop",
  editorialConfigView__headingContent: "Content",
  editorialConfigView__headingFont: "Headers Font",
  editorialConfigView__heroImageUrl: "Hero afbeelding",
  editorialConfigView__link: "Link kleur",
  editorialConfigView__linkUnderline: "Links onderstrepen",
  editorialConfigView__menuBackground: "Menu achtergrondkleur",
  editorialConfigView__menuColor: "Menu tekst kleur",
  editorialConfigView__metadata: "Metadata kleur",
  editorialConfigView__showAve: "Toon AVE",
  editorialConfigView__showCirculation: "Toon oplage",
  editorialConfigView__showReach: "Toon bereik",
  editorialConfigView__showSingleRubricName:
    "Headerrubriek tonen indien 1 rubriek aanwezig in nieuwsbrief?",
  editorialConfigView__socialMedia1: "Social Media 1",
  editorialConfigView__socialMedia2: "Social Media 2",
  editorialConfigView__socialMedia3: "Social Media 3",
  editorialConfigView__socialMedia4: "Social Media 4",
  editorialConfigView__socialMedia5: "Social Media 5",
  editorialConfigView__socialMedia6: "Social Media 6",
  editorialConfigView__socialMedia7: "Social Media 7",
  editorialConfigView__socialMedia8: "Social Media 8",
  editorialConfigView__templateType: "Sjabloon type",
  editorialConfigView__textColor: "Tekst kleur",
  editorialConfigView__twitter: "Twitter URL",
  editorialConfigView__youTube: "YouTube URL",
  editorialDeleteConfirmation:
    "Are you sure you want to delete this newsletter? This cannot be reversed.",
  editorialIssue: "Newsletter edition",
  editorialIssueNoEditions: "No newsletter issues yet",
  editorialIssueNoMediaitems:
    "Firstly, wait for your filters to add articles to the section or add articles from your dashboard manually",
  editorialIssuePreview: "Newsletter issue preview",
  editorialIssueResend: "Resend newsletter issue",
  editorialIssueResetMessagesAfterDelete:
    "Reset messages from this edition for next newsletter",
  editorialIssueSchedule: "Schedule newsletter issue",
  editorialIssueStats: "Newsletter issue statistics",
  editorialIssueSuccessfullyResend: "Newsletter issue successfully re-sent.",
  editorialIssueTest: "Test newsletter issue",
  editorialIssueTestDescription:
    "You can send a test to yourself at an e-mail address of your choosing.",
  editorialRubric: "Section",
  editorialRubricFilter_help:
    "Would you like to add media-items for this section automatically? Create a filter. In case you want to do this manualy, you don't have to add a filter",
  editorialRubricNameIsAlreadyUsed:
    "This name has already been used for this newsletter. Choose another name.",
  editorialRubricNamePlaceholder: "Fill in section title",
  editorialRubrics: "Sections",
  editorialRubricsHelp:
    "Newly added articles will be assigned to sections automatically. In case an existing article is required for your editorial, please add it using the option on the Dashboard.",
  editorialTitle: "Newsletter title",
  editorialTour_1_1:
    "You are about to set up a newsletter. To get you started, we'd like to explain how you can do this in a few steps.",
  editorialTour_1_2:
    "Please note that there are costs associated with the actual sending of a newsletter. If you are not aware of this, please contact your account manager.",
  editorialTour_2_1: "Overview with the correct title",
  editorialTour_2_2:
    "The specified title can only be seen by the person creating the newsletters.",
  editorialTour_3_1: "Define sender",
  editorialTour_3_2:
    "Enter the name of the sender, for example 'Communication department' or the name of your organisation.",
  editorialTour_4_1: "Recognizability through consistency",
  editorialTour_4_2:
    "Ensure recognition in the mailbox by a standardized subject. This can still be adjusted per edition (e.g. by adding an edition number to your subject).",
  editorialTour_5_1: "Define your structure",
  editorialTour_5_2:
    "Cluster your items for your newsletter by subject, medium or sentiment. Populate your headings automatically using a filter, or set no filter and do the selection manually.",
  editorialTour_6_1: "Choose your subscribers",
  editorialTour_6_2:
    'Choose from your existing subscribers, or add new ones. If you want to add a list of new subscribers, go to "Subscribers" in your navigation.',
  editorialTour_7_1: " Automatically send your newsletters?",
  editorialTour_7_2:
    "It's possible! Our system can automatically send newsletters with articles that are selected since the last edition was sent. For this you need to have a filter set in the sections.",
  editorialTour_8_1: "Newsletter in your organization's style?",
  editorialTour_8_2:
    "Please contact your account manager to discuss the possibilities.",
  editorials: "Newsletter",
  email: "E-mail",
  emailAddress: "E-mail address",
  emailSent: "The e-mail was sent.",
  excludeFromResults: "Exclude from results",
  export: "Export",
  exportAction: "Export",
  false: "No",
  favorite: "Favorite",
  fetchPreview: "Retrieve example",
  filter: "Filter",
  filterDateChanged: "Date in filter has been adjusted.",
  filterDeleteConfirmation: "Are you sure you want to remove this filter?",
  filterEditConfirmation: "Are you sure you want to edit this filter?",
  filterHasBeenUpdated: "Filter adjusted.",
  filterPdfJob: "Retrieve PDF",
  filterQuery: "Query",
  filterXlsxJob: "Excel export",
  filterXlsxJobLimited: "Export export (beperkt)",
  filter_podcast: "Podcast",
  filter_print: "Print",
  filter_radio: "Radio",
  filter_rss: "RSS",
  filter_social: "Social",
  filter_tv: "TV",
  filter_web: "Web",
  flevoland: "Flevoland",
  forgotOldPassword: "Forgot old password?",
  forgotPassword: "Forgot password?",
  formSaved: "Form saved",
  fragment: "Fragment",
  friesland: "Friesland",
  gelderland: "Gelderland",
  generateExcelExport: "Generate Excel export ",
  generateXls: "Generate Excel file ",
  genericErrorMessage: "Something went wrong, try again later",
  genericErrorMessage_short: "Something went wrong",
  goToSend: "Go to send",
  groningen: "Groningen",
  group: "Group",
  groupId: "Group",
  groupIds: "Group ID",
  groupName: "Group Name",
  groups: "Groups",
  headline: "Title",
  helpOptions: "Help options",
  hideImages: "Hide images",
  highlightWidget_ave: "AVE",
  highlightWidget_aveDescription: "Compared with: {0} to {1}. AVE: {2}",
  highlightWidget_pr: "PR-waarde",
  highlightWidget_prDescription: "Compared with: {0} to {1}. PR value: {2}",
  highlightWidget_messages: "Messages",
  highlightWidget_messagesDescription:
    "Compared with: {0} to {1}. Messages: {2}",
  highlightWidget_reach: "Reach",
  highlightWidget_reachDescription: "Compared with: {0} to {1}. Reach: {2}",
  highlightWidget_sentiment: "Sentiment",
  highlightWidget_sentimentDescription: "{0} positive, {1} negative ({2}%)",
  highlightWidget_sentimentDescriptionWithDates:
    "Compared with: {0} to {1}: {2} positive, {3} negative ({4}%)",
  impersonate: "Switch",
  importCsv: "Import csv",
  inactiveCustomerTooltip: "This customer is inactive",
  inactiveSearchTopics_hide: "Hide inactive search topics",
  inactiveSearchTopics_show: "Show inactive search topics",
  inholland: "Inholland",
  insertDate: "Insert date",
  insertDateAsc: "Insert date ascending",
  insertDateDesc: "Insert date descending",
  insertDateExplanation: "Moment when article appeared in your dashboard.",
  instant: "Instant",
  instantDescription:
    "With instant notification you get an immediate email for every new message that comes in. This is useful",
  insufficientDataForInterval:
    "Insufficient data for interval. Please update date filter or interval.",
  insufficientDataForWidget: "Insufficient filter results to display data",
  interval_auto: "Auto",
  interval_day: "Day",
  interval_month: "Month",
  interval_quarter: "Quarter",
  interval_week: "Week",
  interval_year: "Year",
  intro: "Introduction",
  introduction: "Introduction",
  invalidData: "The data is incorrect! Please check your details.",
  invalidEmailAddresses: "Invalid e-mail addresses. ",
  invalidToken: "This link has expired :(",
  invalidTokenDescription:
    "The link has now expired. You need a new link. Click below to request a new reset link.",
  invoice: "Invoice",
  invoiceDate: "Date",
  invoiceNo: "Invoice number",
  isAutomatic: "Automatically add results",
  isFavourite: "Favorite",
  isOwned: "Purchased",
  isOwned_full: "Is purchased",
  isRead: "Read",
  label: "Label",
  labelAddedToFilter: "Label {0} added",
  labelAutomator: "Label automator",
  labelAutomatorDeleteMessage:
    "Are you sure?\nYou are about to delete your label automator. This stops the automatic assignment of this label. This label remains in place, and visible on media items where this label has already been assigned.",
  labelCanNotBeDeleted:
    "Label can not be deleted: it is still in use by an article.",
  labelId: "Label",
  labelIds: "Labels",
  labelToAssign: "Label applied",
  labels: "Labels",
  language_EN: "EN",
  language_NL: "NL",
  lastIndexDate: "Last retrieved at",
  lastMonth: "Past 30 days",
  lastQuarter: "Past 90 days",
  lastWeek: "Past 7 days",
  layout: "Layout",
  limburg: "Limburg",
  loading: "Loading…",
  log: "Log",
  loggedInAs: "Signed in as:",
  loginFailed: "Login failed",
  loginForm_title: "Login media·web",
  loginForm_usernameAndPasswordCombinationInvalid:
    "The combination username / password is not known to us. Have you forgotten your password? Click on the link below.",
  logoFileId: "Logo",
  lostPasswordBody:
    "No problem! It happens to the best of us. Enter your email address below to receive a reset link.",
  lostPasswordTitle: "Forgotten password",
  makeUp: "Prepare",
  manager: "Manager",
  manualAdd: "From search engine",
  matchTextPlaceholder: "Enter search",
  mediaItemAddLabelIds: "Link label(s) to messages",
  mediaItemDeleteExplanationPlaceholder:
    "Please describe why these articles do not match the search request",
  mediaItemDeleteHint:
    "Delete message\nDelete message and report as not correct.",
  mediaItemDeleteJob: "Delete messages",
  mediaItemDeleteMessage:
    "You are about to delete one message. This cannot be undone.",
  mediaItemPurchaseOffer:
    "You did not purchase this article yet. In case you want to read this article, purchase this article.\n\nWould you like to purchase this article?",
  mediaItemSetFavourite: "Mark items as favorite",
  mediaItemSetRead: "Mark items as read",
  mediaItemSetSentimentAndPrValue: "Set items sentiment and PR value",
  mediaItemSetSentimentOnly: "Set items sentiment",
  mediaItemSetUnfavourite: "Mark items as not favorite",
  mediaItemSetUnread: "Mark items as unread",
  mediaItemWidget_title: "{0} Messages",
  mediaItemsDeleteMessage:
    "You are about to delete {0} posts. This cannot be undone.",
  mediaType: "Type",
  mediaTypeAddedToFilter: "Added Media type {0} ",
  mediaType_podcast: "Podcast",
  mediaType_print: "Print",
  mediaType_radio: "Radio",
  mediaType_rss: "RSS",
  mediaType_social: "Social",
  mediaType_tv: "TV",
  mediaType_web: "Web",
  memberOf: "Subscribed to",
  monthly: "Monthly",
  moreOptions: "More options",
  myMedia: "My media",
  myMediaTemplatesButtonConfirm:
    'You are about to modify "My media" to a different template. You will loose the current configuration of your widgets. Are you sure you want to continue?',
  myMediaTourBody11:
    "You can start a chat via the rescue buoy at the bottom right, reopen the tour or visit the <a href='https://support.mediaweb.eu/' target='_blank'>support page</a>. Good luck!",
  myMediaTourBody1:
    "Your personal landing page within media·web is customizable by configuring widgets.",
  myMediaTourBody8:
    'Add a name and save your configuration + filter as a dashboard. The configuration of "My media" will be save automatically.',
  myMediaTourHeading11: "Do you have any further questions?",
  myMediaTourHeading1: 'Welcome to "My media"',
  myMediaTourHeading8: "Save as dashboard",
  name: "Name",
  namelessDashboard: "Dashboard",
  namelessEditorial: "Untitled newsletter",
  namelessBenchmark: "Unnamed analysis",
  namelessSearchEngineQuery: "Unnamed search engine task",
  navigationTourNotAvailable: "Tour not available on this page.",
  navigation_account: "Account",
  navigation_admin: "Settings",
  navigation_admin_content: "Content exports",
  navigation_admin_customer: "Display",
  navigation_admin_deletedMediaItems: "Deleted items",
  navigation_admin_invoices: "Invoices",
  navigation_admin_labels: "Labels",
  navigation_admin_mediaItemPurchases: "Added items",
  navigation_admin_rss: "Rss",
  navigation_admin_searchTopics: "Search topics",
  navigation_admin_source: "Sources",
  navigation_admin_users: "Users",
  navigation_alerts: "Notifications",
  navigation_analysis: "Analysis",
  navigation_customers: "Manage customers",
  navigation_dashboards: "Dashboards",
  navigation_downloadTranslations: "Download translations",
  navigation_editorials: "Newsletter",
  navigation_editorials_issues: "Sent issues",
  navigation_editorials_overview: "Newsletters",
  navigation_editorials_subscribers: "Subscribers",
  navigation_groups: "Manage groups",
  navigation_help: "Help",
  navigation_logout: "Logout",
  navigation_search: "Search engine",
  navigation_tour: "Walkthrough",
  navigation_users: "Manage users",
  negative: "Negative",
  neutral: "Neutral",
  newAlert: "New notification",
  newAlertWithThisFilterAlreadyExists:
    "A notification already exists with these settings!",
  newContentNotification: "Periodic notification",
  newDashboard: "New dashboard",
  newEditorial: "New newsletter",
  newFilter: "New filter",
  newGroup: "New group",
  newLabelAutomator: "New Label Automator",
  newPassword: "New password",
  newPasswordRepeat: "Repeat new password",
  newPasswordRepeatIsntEqualToNewPassword:
    "Should be the same as the new password",
  newPasswordShouldBeNew:
    "You can't choose the same password as your current password. Please type a new password.",
  newRssExport: "New RSS export",
  newfirst: "Publication date descending",
  newsAlert: "Instant notification",
  newsAlertDescriptionWithDates:
    "You will receive an immediate email from {0} to {1} for each newly published post.",
  newsAlertOptionFilterDescription:
    "Setting a filter is not mandatory. When no filter is set, all posts that have newly appeared in media·web will be shown.",
  newsAlertPeriodError:
    "Set a start and end date with a maximum period of 3 months.",
  newsAlertWithDates: "Instant from {0} to {1}",
  nextEdition: "Next edition: {0}",
  noActiveEditorials: "You haven't set up any newsletters yet.",
  noDoubles: "excl. doubles",
  noEditorialsFound: "You don't have any newsletters yet.",
  noEditorialsFound_description:
    "A newsletter is needed to send newsletter issues. Create one below.",
  noLabelAutomatorsFound: "No label automators found",
  noLabelsFound: "No labels found.",
  noLimit: "No limit",
  noResultsText: "No results found.",
  none: "None",
  noordBrabant: "Noord-Brabant",
  noordHolland: "Noord-Holland",
  notifications: "Notifications",
  notificationsFor: "Notifications for",
  null: "not known",
  oldfirst: "Publication date ascending",
  oneMomentPlease: "One moment please...",
  open: "Open",
  openAsPage: "Open as full page",
  openManual: "Open manual",
  openedPercentage: "Opened: {0}%",
  optionalAddLabels: "Optionally: add the following labels",
  overijssel: "Overijssel",
  page: "Full page",
  password: "Password",
  passwordExpiredDescription:
    "Due to the increase in security requirements, we ask that you create a new password.",
  passwordExpiredTitle: "Password expired",
  pdf: "PDF",
  pending: "Pending",
  period: "Period",
  periodic: "Periodic",
  periodicDescription:
    "Set how often per day, per week or per month you want to be notified of the new items that have been selected for you.",
  plusEditorial: "+ Newsletter",
  plusLabel: "+ Label",
  positive: "Positive",
  poweredByLexisnexis: "Powered by LexisNexis",
  prValue: "PR-value",
  prValueAsc: "PR-value ascending",
  prValueDesc: "PR-value descending",
  prValueHasBeenSaved: "PR-value has been saved",
  preview: "Preview",
  price: "Price",
  profileUpdateSuccessfull: "Account settings are saved with last changes",
  provideASearchQueryFirst: "Provide a search query first",
  publicationDate: "Publication date",
  publicationDateAsc: "Publication date ascending",
  publicationDateDesc: "Publication date descending",
  publicationDateExplanation: "Date of publication",
  publicationName: "Publication",
  publicationalphabetical: "Publication A-Z",
  purchaseDatetime: "Purchase date",
  purchaseStatus: "Purchase status",
  reach: "Reach",
  reachAsc: "Reach ascending",
  reachDesc: "Reach descending",
  read: "Read",
  readyDatetime: "Purchase ready at:",
  reason: "Reason",
  regular: "Normal",
  rejectReason: "Reject reason",
  rejected: "Rejected",
  relevance: "Relevance",
  remainingAuthors: "Remaining authors",
  remainingCategories: "Remaining categories",
  remainingLabels: "Remaining labels",
  remainingSearchTopics: "Remaining search topics",
  remainingSources: "Remaining sources",
  remove: "Delete",
  removeLabels: "Remove labels",
  resend: "Resend ",
  resetAgain: "Reset again",
  resetOriginal: "Reset original",
  resetPassword: "Reset password",
  restricted: "Restricted",
  role: "Role",
  roles: "Roles",
  rss: "RSS",
  save: "Save",
  saveAs: "Save as…",
  saveAsDashboard: "Save as dashboard",
  saveDashboardAs: "Save Dashboard as...",
  saveSearchEngineQueryAs: "Save search-request as...",
  saving: "Saving…",
  scheduleEditorialIssue: "Schedule newsletter issue",
  scheduleSending: "Schedule sending",
  scheduledFor: "Scheduled for: {0}",
  screenshot: "Screenshot",
  searchAuthor: '"{0}" search in authors',
  searchByCustomer: "Search topic from Search engine",
  searchByMig: "Search topic by Media Info Group",
  searchEngineQueryCanBeSavedWhenDirty:
    "A search-request can be saved only after it has been executed.",
  searchEngineView_startDescription:
    "Enter a search term so we can find the proper articles from our database",
  searchInText: '"{0}" search in tekst',
  searchLabel: "Search label...",
  searchQueries: "Search topics",
  searchQuery: "Search topic",
  searchSource: '"{0}" search in sources',
  searchTopic: "Search topic",
  searchTopicAddedToFilter: "Search topic {0} added",
  searchTopicIds: "Search topics",
  searchTopicXlsxJob: "Export search topics",
  searchTopics: "Search topics",
  searchTopicsX: "Search topics ({0})",
  searchType: "Type",
  searchWithinResults: "Search within results",
  selectAll: "Select all",
  selectAllResults: "Add all results ({0})",
  selectWithWord: 'Add all with word "{0}" ({1})',
  send: "Send",
  sendDirect: "Send directly",
  sendEditorialIssue: "Send newsletter issue",
  sendEditorialIssueTest: "Send newsletter issue test",
  sendScheduled: "Schedule sending",
  sendTestMail: "Send test mail",
  senderName: "Sender name",
  sentOn: "Send: {0}",
  sentiment0: "Not defined",
  sentiment1: "Negative",
  sentiment2: "Neutral",
  sentiment3: "Positive",
  sentiment: "Sentiment",
  sentimentUndefined: "Sentiment undefined",
  sentimentundefined: "Unknown",
  setFilter: "Set filter",
  setNewPassword: "Set new password",
  setValue: "Set",
  settingFilter: "Set filter",
  share: "Share",
  showAveAs: "Show AVE as",
  showAveAs_cumulative: "Doubles as cumulative",
  showAveAs_linkedArticles: "Doubles per line",
  showAveAs_original: "No doubles",
  showPdfArea: "Show area in PDF",
  showPdfAve: "Show AVE in PDF",
  showPdfCirculation: "Show circulation in PDF",
  showPdfCumulativeAve: "Show AVE (cumulative) in PDF",
  showPdfCumulativeCirculation: "Show circulation (cumulative) in PDF",
  showPdfCumulativeReach: "Show reach (cumulative) in PDF",
  showPdfFrequency: "Show frequency in PDF",
  showPdfPageNumber: "Show pagenumber in PDF",
  showPdfPublication: "Show publication in PDF",
  showPdfPublicationDate: "Show publication date in PDF",
  showPdfReach: "Show reach in PDF",
  showPdfRegion: "Show region in PDF",
  showRemaining: "Show remaining",
  showValueTypeInTitleCheckboxTitle:
    "Can only be turned off when a graph is displayed with axes",
  source: "Source",
  sourceAddedToFilter: "Source {0} added",
  sourceAsc: "Source (A-Z)",
  sourceAsyncPopoverInitialLabel: "Search for a source...",
  sourceDesc: "Source (Z-A)",
  sources: "Sources",
  sourcesX: "Sources ({0})",
  splitType_author: "Author",
  splitType_category: "Category",
  splitType_labels: "Label",
  splitType_mediatype: "Media type",
  splitType_none: "No splitting",
  splitType_searchtopic: "Search topic",
  splitType_sentiment: "Sentiment",
  splitType_source: "Source",
  startWithATemplate: "Start with a template",
  startWithAnEmptyDashboard: "Start with an empty dashboard",
  stats: "Statistics",
  status: "Status",
  subject: "Subject",
  subscribed: "Subscribed",
  subscriber: "Subscriber",
  subscriberDisplayNameIsAlreadyUsed:
    "A subscriber already exists with this name.",
  subscriberEmailAddressIsAlreadyUsed:
    "A subscriber already exists with this email address.",
  subscriberEmailAddresses: "Enter one or more email addresses:",
  subscriberEmailAddressesHint:
    '(*Underline characters allowed: space, enter, "," or ")',
  subscribers: "Subscribers",
  success: "Success",
  summedAve: "AVE",
  summedPrValue: "PR-value",
  summedReach: "Reach",
  superuser: "Superuser",
  templates: "Templates",
  testMailHasBeenSend: "Newsletter edition test has been sent!",
  text: "Text",
  thisDay: "Today",
  thisMediaItemIsUsedInMultipleRubrics:
    "This article appears in multiple sections.",
  thisTaskIsReady: "This task has been completed",
  title: "Title",
  titlealphabetical: "Title A-Z",
  toApply: "Apply",
  today: "Today",
  topOwn: "Own",
  topXResults: "Top {0}",
  true: "Yes",
  tryAgain: "Try again",
  type: "Type",
  unfavorite: "Not favorite",
  unread: "Unread",
  unsaveDashboardChanges:
    "Want to continue without saving your dashboard changes?",
  unsavedChangedAreYouSure:
    "The form has unsaved changes. Are you sure you want to leave this page?",
  unsubscribe: "Unsubscribe",
  unsubscribe_description:
    "Use the button below to unsubscribe from this newsletter. If you are a subscribed to multiple newsletters, you will need to unsubscribe separately",
  unsubscribe_error: "Failed",
  unsubscribe_inProgress: "Busy unsubscribing",
  unsubscribe_success: "Successfully unsubscribed",
  unsubscribed: "Unsubscribed",
  url: "URL",
  useAdvancedSearchEngineMatch: "Use wizard",
  usedIn: "Used in",
  userForm__showInactiveAccounts: "Show inactive accounts",
  userForm_accessToAccounts: "Access to accounts:",
  userForm_chooseAGroup: "Choose a group",
  userForm_noRightToSettingsMessage:
    "If you want to change this, please contact the administrator of your account.",
  userForm_personal: "Personal",
  userForm_requestFirstPassword:
    "After saving, the user will receive an email with instructions on how to set a password.",
  userForm_requestNewPassword: "Apply for new one",
  userForm_rights: "Permissions",
  userId: "ID",
  userName: "E-mail address",
  userReportXlsxJob: "Export report",
  users: "Users",
  utrecht: "Utrecht",
  valueType_count: "Volume",
  valueType_summedAve: "AVE",
  valueType_summedPrValue: "PR-value",
  valueType_summedReach: "Reach",
  view_LabelAutomatorModal_labelIdTitle: "Choose label",
  view_LabelAutomatorModal_refineLabelAutomator: "Refine Label Automator",
  view_LabelAutomatorModal_searchTopicIdsTitle: "Search within search topic",
  view_LabelAutomatorModal_selectALabel: "You have to select a label",
  waitForApproval: "Wait for approval",
  web: "Website",
  website: "Website",
  weekly: "Weekly",
  welcomeModal_message:
    "Is this your first time in media·web? We'd like to take you through the main sections of the portal. If you want to start the tour at a later time, you can invoke it via the life buoy at the bottom right of your screen. Good luck!",
  welcomeModal_startTour: "Start walkthrough",
  welcomeModal_title: "Welcome to media·web",
  widgetDrawer_amountOfActiveWidgetsDescription:
    "Number of times this widget is shown in this dashboard",
  widgetSettings_display: "Display:",
  widgetSettings_filter: "Filter:",
  widgetSettings_interval: "Interval:",
  widgetSettings_limitedResults: "Limit results:",
  widgetSettings_splitType: "Split by:",
  widgetSettings_valueType: "Show data:",
  widgets: "Widgets",
  wordCount: "Word count",
  xMessages: "{0} Messages",
  xSelectedItem: "{0} item",
  xSelectedItems: "{0} items",
  xSubscriber: "{0} Subscriber",
  xSubscribers: "{0} Subscribers",
  xlsColumnHelp:
    "Select and/or sort the columns you want to see in the Excel export. We will save your preferences for next time.",
  yearToDate: "Year to date",
  youHaveBeenUnsubscribed: "You are unsubscribed",
  zeeland: "Zeeland",
  zuidHolland: "Zuid-Holland",
  addedToEditorialRubricX: "Added to section ({0})",
  createPassword: "Create password",
  createPasswordBody: "Create a password once to login",
  dashboardAccessTokenExpiredBody:
    "You just received an e-mail at {0} with an updated link. Please use this new link to access your dashboard.",
  dashboardAccessTokenExpiredTitle: "The link you use is expired.",
  dashboardAccessTokenUnknownBody: "Please contact your administrator.",
  dashboardAccessTokenUnknownTitle: "No access.",
  newContentExport: "New content export",
  newSubscriber: "New subscriber",
  activeFilters: "Active filters",
  restoreOriginalFilter: "Restore original filter",
  narrowcastingTitle: "Narrowcasting-mode",
  narrowcastingBody:
    "Narrowcasting is a way to display live data on a screen. Using this mode, menus, buttons and bars are hidden. You can use the ESC key to leave narrowcasting mode.",
  stop: "Stop",
  start: "Start",
  managerUser: "Manager",
  regularUser: "Regular user",
  dashboardOnlyUser: "Shared-dashboard user",
  shareBenchmarkTitle: "Share benchmark",
  shareDashboardTitle: "Share dashboard",
  shareBenchmarkBody:
    "Anybody with the link can view the benchmark with the current settings. The benchmark can not be changed",
  shareDashboardBody:
    "Anybody with the link can view the dashboard with the current settings. The dashboard can not be changed",
  notFoundViewTitle: "Something went wrong :(",
  noAccessViewTitle: "No access",
  noAccessViewBody: "Please contact your administrator.",
  "Subscriber,Self unsubscribed":
    "This subscriber did unsubscribe previously. Re-subscribing can not be done by a third person.",
  benchmarkAccessLinkSent:
    "An e-mail has been sent with a link to this benchmark.",
  dashboardAccessLinkSent:
    "An e-mail has been sent with a link to this dashboard.",
  benchmarkSharedWith: "Benchmark shared with",
  dashboardSharedWith: "Dashboard shared with",
  benchmarkNotSharedYet: "Benchmark not shared yet",
  dashboardNotSharedYet: "Dashboard not shared yet",
  xDeleteMediaItemsCanNotBeDisplayed:
    "{0} articles have been deleted from media·web. These can no longer be displayed.",
  compareTo: "Compare to",
  previousYear: "Same period, previous year",
  previousPeriod: "Previous period",
  customPeriod: "Custom period",
  comparedWith: "Compared to",
  comparedWith_lastWeek: "Compared to last week",
  comparedWith_lastMonth: "Compared to last 30 days",
  comparedWith_lastQuarter: "Compared to last 90 days",
  comparedWith_thisDay: "Compared to yesterday",
  comparedWith_yearToDate: "Compared to last year",
  highlightWidget_compareMessages: "Messages in compared period",
  showTrend: "Show trend percentages",
  showHighlightWidgetItemType_MESSAGES: "Show messages",
  showHighlightWidgetItemType_REACH: "Show reach",
  showHighlightWidgetItemType_AVE: "Show AVE",
  showHighlightWidgetItemType_SENTIMENT: "Show sentiment",
  showHighlightWidgetItemType_PR: "Show PR value",
  visibleHighlightWidgetItemTypes: "Visible items",
  analysis: "Analysis",
  benchmarkCanBeSavedWhenDirty:
    "An analysis can be saved only after changing it",
  benchmarkView_startTitle: "Start an analysis",
  benchmarkView_startBody: "Please enter a first search term to start",
  pressReleaseName: "Name of press release",
  customerId: "Customer",
  prDashboardUrl: "PR-Dashboard URL",
  pressReleaseUrl: "Press release URL",
  'must match format "url"': "should contain a valid URL",
  saveSuccessful: "Het opslaan is gelukt.",
  "benchmarkView__no-access-title": "This module has not been activated",
  "benchmarkView__no-access-body":
    '<p>Using this module you can analyse and benchmark data outside of your own search topics.<p><strong>Interested?</</strong> PLease take a look at <a href="https://support.mediaweb.eu/category/analyse" target="_blank">https://support.mediaweb.eu/category/analyse</a> or contact your account manager.</p>',
  benchmarkView_disclaimer:
    '<p></p><p>Create your search <a href="https://support.mediaweb.eu/article/hoe-stel-ik-een-complexe-zoekopdracht-samen-in-de-zoekmachine" target="_blank">as targeted as possible</a>. For more information read our <a href="https://support.mediaweb.eu/category/analyse" target="_blank">support page</a>.</p>',
  addWidgets: "Add widgets",
  benchmarkView_noWidgetsWarning:
    "Results have been retrieved. Add a widget to view the results",
  dashboardName: "Name of the dashboard",
  message: "Message",
  messageNotFoundTitle: "Message not found",
  messageNotFoundBody:
    "The message may be deleted or the access rights have been changed",
  articlesAreBeingProcessed: "Articles are being processed",
  articlesAreBeingProcessedBody:
    "Adding these articles to your dashboard may take a while. A preview of the added articles can be found here:",
  article: "Article",
  buyArticle: "Buy article",
  buyArticles: "Buy {0} articles",
  addedArticle: "Article added",
  app: "App",
  allSubscribersAreMailSubscribers: "Subscribe all for newsletter",
  allSubscribersAreAppSubscribers: "Subscribe all for app usage",
  useApp: "App",
  useMail: "Email",
  appEnabled: "Use App",
  matchText: "Match text",
  updateSearch: "Update match text",
  notes: "Notes",
  appConfig: "App configuration",
  logoUrlLight: "Logo (light mode)",
  logoUrlDark: "Logo (dark mode)",
  "must NOT have fewer than 1 characters": "This field cannot be empty",
  sourceName: "Source",
  benchmarkNames: "Searchterm(s)",
  widgetSettings_bechmarkSplitType: "Filter:",
  bechmarkSplitType_none: "None",
  bechmarkSplitType_web: "Web",
  bechmarkSplitType_print: "Print",
  benchmarkName: "Name",
  benchmarkInvalid: "Benchmark invalid",
  bechmarkSplitType_category: "Category",
  bechmarkSplitType_author: "Author",
  mediaItemSortType_aveAsc: "AVE ascending",
  mediaItemSortType_aveDesc: "AVE descending",
  mediaItemSortType_insertDateAsc: "Insert date ascending",
  mediaItemSortType_insertDateDesc: "Insert date descending",
  mediaItemSortType_prValueAsc: "PR value ascending",
  mediaItemSortType_prValueDesc: "PR value descending",
  mediaItemSortType_publicationDateAsc: "Publication date ascending",
  mediaItemSortType_publicationDateDesc: "Publication date descending",
  mediaItemSortType_reachAsc: "Reach ascending",
  mediaItemSortType_reachDesc: "Reach descending",
  mediaItemSortType_sourceAsc: "Source (A-Z)",
  mediaItemSortType_sourceDesc: "Source (Z-A)",
  preparing: "Preparing...",
  loadingResults: "Loading {0} results",
  showInactiveSubscribers: "Show inactive subscribers",
  atNotAllowed: "The character '@' is not allowed",
  moreAboutThisData: "More about this data",
  benchmarkResultBenchmarkWidget_title: "{0} Articles",
};

const Calendar: TLocaleCalendar = {
  formattedDayPattern: "MMM DD, YYYY",
  formattedMonthPattern: "MMM, YYYY",
  friday: "Fr",
  hours: "Hours",
  minutes: "Minutes",
  monday: "Mo",
  ok: "OK",
  saturday: "Sa",
  seconds: "Seconds",
  sunday: "Su",
  thursday: "Th",
  today: "Today",
  tuesday: "Tu",
  wednesday: "We",
  yesterday: "Yesterday",
};

const rsuiteLocale: TLocaleRsuiteData = {
  Pagination: {
    first: "First",
    last: "Last",
    more: "More",
    next: "Next",
    prev: "Previous",
  },
  Table: {
    emptyMessage: "No data found",
    loading: "Loading...",
  },
  TablePagination: {
    lengthMenuInfo: "{0} / page",
    totalInfo: "Total: {0}",
  },
  Calendar,
  DatePicker: Calendar,
  DateRangePicker: { ...Calendar, last7Days: "Last 7 days" },
  Picker: {
    checkAll: "All",
    noResultsText: "No results found",
    placeholder: "Select",
    searchPlaceholder: "Search",
  },
  InputPicker: {
    createOption: 'Add label: "{0}"',
    newItem: "New item",
  },
  Uploader: {
    complete: "Ready",
    emptyFile: "Empty file",
    error: "Error",
    inited: "Initial",
    progress: "Uploading",
    upload: "Upload",
  },
};

export const locale: TLocale = {
  db,
  rsuite: rsuiteLocale,
};
