import React from "react";
import App from "./App";
import ApiDataProvider from "./provider/ApiDataProvider";
import LayoutProvider from "./provider/LayoutProvider";
import { BrowserRouter as Router } from "react-router-dom";
import I18nProvider from "./provider/I18nProvider";
import { SingletonHooksContainer } from "react-singleton-hook";
import AuthProvider from "./provider/AuthProvider";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import "react-virtualized/styles.css";

const { NODE_ENV = "unknown", REACT_APP_BUILD_ID = "unknown" } = process.env;

if (NODE_ENV === "production") {
  const release = REACT_APP_BUILD_ID.replace(/\D/g, "");
  Sentry.init({
    dsn: "https://f77c0c46d60440228f7b5bfd65ec3aa8@o1110953.ingest.sentry.io/4504451494576128",
    release,
  });

  ((window: any) => {
    window.Tawk_API = { hydrated: false };
    window.Tawk_LoadStart = new Date();
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/5943d6d1e9c6d324a4735c8e/default";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    const { parentNode } = s0;
    if (parentNode) {
      parentNode.insertBefore(s1, s0);
    }
  })(window);
}

const root = createRoot(document.getElementById("root")!);
root.render(
  <Router>
    <I18nProvider>
      <AuthProvider>
        <LayoutProvider>
          <ApiDataProvider>
            <SingletonHooksContainer />
            <App />
          </ApiDataProvider>
        </LayoutProvider>
      </AuthProvider>
    </I18nProvider>
  </Router>,
);
