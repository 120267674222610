import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useLabels = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { labels, setLabels } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      labels !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    setLabels(null);
    axios
      .get<components["schemas"]["Label"][]>("/label/crud")
      .then((res) => {
        setLabels(
          res.data.reduce<IHashMap<components["schemas"]["Label"]>>(
            (prev, label) => {
              prev[label.labelId as string] = label;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_label"));
      });
  }, [auth?.bearer, setLabels, labels, t, auth?.jwt.userRoles]);

  return labels;
});

export default useLabels;
