import React from "react";
import "./index.scss";

interface ILightboxProps {
  className?: string;
  onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
  transparent?: boolean;
}

const Lightbox = ({
  children,
  className,
  onClose,
  style,
  transparent,
}: ILightboxProps) => {
  const lightboxRef = React.useRef(null);
  const onLightboxClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.target === lightboxRef.current) {
        onClose(e);
      }
    },
    [onClose]
  );

  return (
    <div
      className={`lightbox${transparent ? " lightbox--transparent" : ""}${
        className ? ` ${className}` : ""
      }`}
      onClick={onLightboxClick}
      ref={lightboxRef}
      style={style}
    >
      {children}
    </div>
  );
};

export default React.memo(Lightbox);
