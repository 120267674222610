import { Icon, Tag } from "rsuite";
import React from "react";
import "./index.scss";
import { TypeAttributes } from "rsuite/lib/@types/common";

interface IMwTagProps {
  children?: React.ReactNode;
  className?: string;
  color?: TypeAttributes.Color;
  iconComponent?: React.ElementType;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onClick?: () => void;
  rounded?: boolean;
  size?: "sm" | "lg";
}

const MwTag = (props: IMwTagProps) => {
  const {
    children,
    className,
    color,
    iconComponent,
    onClose,
    onClick,
    rounded,
    size = "sm",
  } = props;

  const labelClassName = React.useMemo(() => {
    const classes = ["components__mw-tag", `components__mw-tag-${size}`];
    if (iconComponent) {
      classes.push("components__mw-tag-icon");
    }
    if (rounded) {
      classes.push("components__mw-tag-rounded");
    }
    if (className) {
      classes.push(className);
    }
    return classes.join(" ");
  }, [className, iconComponent, rounded, size]);

  return (
    <Tag
      componentClass={onClick ? "button" : undefined}
      className={labelClassName}
      closable={!!onClose}
      color={color}
      onClose={onClose}
      onClick={onClick}
    >
      {iconComponent ? (
        <Icon
          className="components__mw-tag__icon"
          icon="star"
          componentClass={iconComponent}
        />
      ) : null}
      {children}
    </Tag>
  );
};
export default MwTag;
