import React from "react";
import { Checkbox, CheckboxGroup, Icon, Popover } from "rsuite";
import MwTag from "../../../MwTag";
import MwWhisper from "../../../MwWhisper";
import openapi from "../../../../openapi.json";
import { I18nContext } from "../../../../provider/I18nProvider";
import { components } from "../../../../types/openapi";

const distributionProvinces =
  openapi.components.schemas.DistributionProvince.enum;

interface IDistributionProvincePopoverProps {
  whisperRef: React.Ref<any>;
  value: string[];
  onChange?: (
    newValue?: components["schemas"]["DistributionProvince"][]
  ) => void;
}

const DistributionProvincePopover = ({
  onChange,
  value,
  whisperRef,
}: IDistributionProvincePopoverProps) => {
  const { t } = React.useContext(I18nContext);

  return (
    <MwWhisper
      placement="bottomStart"
      trigger="click"
      onClose={
        onChange
          ? () => {
              if (!value.length) {
                onChange();
              }
            }
          : undefined
      }
      ref={whisperRef}
      speaker={
        <Popover className="popover-without-arrow checkbox-popover">
          <CheckboxGroup value={value} onChange={onChange}>
            {distributionProvinces
              .filter((province) =>
                onChange ? true : value.indexOf(province) >= 0
              )
              .map((distributionProvince) => (
                <Checkbox
                  disabled={!onChange}
                  className="checkbox-max-width"
                  key={distributionProvince}
                  value={distributionProvince}
                >
                  {t(distributionProvince)}
                </Checkbox>
              ))}
          </CheckboxGroup>
        </Popover>
      }
    >
      <MwTag
        iconComponent={() => (
          <Icon
            icon="location-arrow"
            style={{ position: "absolute", left: 8, top: 5 }}
          />
        )}
        onClose={onChange ? () => onChange() : undefined}
        color="cyan"
        size="lg"
      >
        {t("distributionProvinces")} ({value.length})
      </MwTag>
    </MwWhisper>
  );
};

export default DistributionProvincePopover;
