import React from "react";
import { Button, ButtonGroup, ControlLabel, FormGroup } from "rsuite";
import SelectGrid from "./SelectGrid";
import { I18nContext } from "../../../provider/I18nProvider";
import { getCronFrequency, TCronFrequency } from "../../../inc/cron";
import usePrevious from "../../../hooks/usePrevious";
import { range } from "lodash";

interface ICronEditorProps {
  value?: string;
  onChange?: (
    newCron: string,
    event: React.SyntheticEvent<HTMLElement, Event>,
  ) => void;
}

const CRON_PLACEHOLDER = "x";
// Use an invalid cron value as default, ensuring that all necessary values are being filled
// (i.e. if user is on weekly tab, both hour and day should be selected)
const DEFAULT_CRON_VALUE = `0 ${CRON_PLACEHOLDER} ${CRON_PLACEHOLDER} * ${CRON_PLACEHOLDER}`;
const dummyEvent = new Event("change") as any;

const CronEditor = ({ value = "", onChange }: ICronEditorProps) => {
  const { t } = React.useContext(I18nContext);
  const [cronTab, setCronTab] = React.useState<TCronFrequency>();

  const valueParts = value.split(" ");
  const cronParts =
    valueParts.length === 5 ? valueParts : DEFAULT_CRON_VALUE.split(" ");

  // Set active cron tab based on value
  React.useEffect(() => {
    if (cronTab !== undefined) {
      return;
    }

    const cronFrequency = getCronFrequency(cronParts);
    if (cronFrequency) {
      setCronTab(cronFrequency);
    }
  }, [cronParts, cronTab]);
  const previousCronTab = usePrevious(cronTab);

  // Cleanup day-of-month and/or day-of-week on cron tab change
  React.useEffect(() => {
    if (cronTab === previousCronTab || !onChange) {
      return;
    }
    if (
      cronTab === "weekly" &&
      (cronParts[2] !== "*" ||
        (cronParts[4] !== CRON_PLACEHOLDER && !!previousCronTab))
    ) {
      cronParts[2] = "*";
      cronParts[4] = !!previousCronTab ? CRON_PLACEHOLDER : cronParts[4];
      onChange(cronParts.join(" "), dummyEvent);
      return;
    }
    if (
      cronTab === "monthly" &&
      ((cronParts[2] !== CRON_PLACEHOLDER && !!previousCronTab) ||
        cronParts[4] !== "*")
    ) {
      cronParts[2] = !!previousCronTab ? CRON_PLACEHOLDER : cronParts[2];
      cronParts[4] = "*";
      onChange(cronParts.join(" "), dummyEvent);
      return;
    }
    if (cronTab === "daily" && (cronParts[2] !== "*" || cronParts[4] !== "*")) {
      cronParts[2] = "*";
      cronParts[4] = "*";
      onChange(cronParts.join(" "), dummyEvent);
      return;
    }
  }, [cronParts, cronTab, onChange, previousCronTab]);

  const getTabValues = React.useCallback((tabValue: string) => {
    if (tabValue === "*" || tabValue === CRON_PLACEHOLDER) {
      return [];
    }
    return tabValue.split(",").reduce((carry: string[], current: string) => {
      if (current.includes("-")) {
        // Allow tab range
        const parts = current.split("-");
        for (let i = parseInt(parts[0], 10); i <= parseInt(parts[1], 10); i++) {
          carry.push(`${i}`);
        }
      } else {
        carry.push(current);
      }
      return carry;
    }, []);
  }, []);

  const sortArrayOfNumericStrings = (values: string[]) =>
    values.map((value) => parseInt(value, 10)).sort((a, b) => a - b);

  const hourlyData = [];
  for (let hour = 0; hour < 24; hour++) {
    hourlyData.push({ value: `${hour}`, label: `${hour}h` });
  }

  const monthlyData = range(1, 32).map((day) => ({
    value: `${day}`,
    label: `${day}`,
  }));

  return (
    <>
      <FormGroup className="mt-3">
        <ControlLabel className="label">
          {t("cronEditorInputLabel_frequency")}
        </ControlLabel>
        <ButtonGroup justified>
          {["daily", "weekly", "monthly"].map((cronType, index) => (
            <Button
              key={index}
              size="sm"
              appearance={cronTab === cronType ? "primary" : "subtle"}
              onClick={() =>
                setCronTab(cronType as "daily" | "weekly" | "monthly")
              }
            >
              {t(cronType)}
            </Button>
          ))}
        </ButtonGroup>
      </FormGroup>

      {cronTab === "weekly" ? (
        <FormGroup>
          <ControlLabel className="label">
            {t("cronEditorInputLabel_days")}
          </ControlLabel>
          <SelectGrid
            values={getTabValues(cronParts[4])}
            data={[
              [
                { value: "1", label: t("cron_label_monday") },
                { value: "2", label: t("cron_label_tuesday") },
                { value: "3", label: t("cron_label_wednesday") },
                { value: "4", label: t("cron_label_thursday") },
                { value: "5", label: t("cron_label_friday") },
                { value: "6", label: t("cron_label_saturday") },
                { value: "0", label: t("cron_label_sunday") },
              ],
            ]}
            onChange={(newValues) => {
              cronParts[4] = newValues.length
                ? sortArrayOfNumericStrings(newValues).join(",")
                : CRON_PLACEHOLDER;
              if (onChange) {
                onChange(cronParts.join(" "), dummyEvent);
              }
            }}
          />
        </FormGroup>
      ) : null}

      {cronTab === "monthly" ? (
        <FormGroup>
          <ControlLabel className="label">
            {t("cronEditorInputLabel_days")}
          </ControlLabel>
          <SelectGrid
            values={getTabValues(cronParts[2])}
            data={[
              [...monthlyData.slice(0, 6)],
              [...monthlyData.slice(6, 12)],
              [...monthlyData.slice(12, 18)],
              [...monthlyData.slice(18, 24)],
              [...monthlyData.slice(24, 30)],
              [
                {
                  value: `${31}`,
                  label: `${31}`,
                },
                null,
                null,
                null,
                null,
                null,
              ],
            ]}
            onChange={(newValues) => {
              cronParts[2] = newValues.length
                ? sortArrayOfNumericStrings(newValues).join(",")
                : CRON_PLACEHOLDER;
              if (onChange) {
                onChange(cronParts.join(" "), dummyEvent);
              }
            }}
          />
        </FormGroup>
      ) : null}

      <FormGroup>
        <ControlLabel className="label">
          {t("cronEditorInputLabel_hours")}
        </ControlLabel>
        <SelectGrid
          values={getTabValues(cronParts[1])}
          data={[
            [...hourlyData.slice(0, 6)],
            [...hourlyData.slice(6, 12)],
            [...hourlyData.slice(12, 18)],
            [...hourlyData.slice(18, 24)],
          ]}
          onChange={(newValues) => {
            cronParts[1] = newValues.length
              ? sortArrayOfNumericStrings(newValues).join(",")
              : CRON_PLACEHOLDER;
            if (onChange) {
              onChange(cronParts.join(" "), dummyEvent);
            }
          }}
        />
      </FormGroup>
    </>
  );
};
export default CronEditor;
