import { Whisper } from "rsuite";
import { WhisperProps } from "rsuite/lib/Whisper/Whisper";
import React from "react";

// 1. Render placeholder
// 2. Find it in the dom
// 3. Install whisper / speaker as intended with container at the right place

const MwWhisper = React.forwardRef(
  (props: WhisperProps & { id?: string }, ref) => {
    const [container, setContainer] = React.useState<HTMLElement | null>(null);

    if (!container) {
      return (
        <div
          ref={(placeholderEl) => {
            if (!placeholderEl) {
              return;
            }
            let newContainer = placeholderEl.closest(
              ".rs-modal-header,.rs-modal-body,.app-layout__content"
            ) as HTMLElement | null;
            if (!newContainer) {
              if (process.env.NODE_ENV === "development") {
                // throw new Error("MwWhisper can not find container!");
              }
              newContainer = window.document.body;
            }
            setContainer(newContainer);
          }}
        />
      );
    }

    return (
      <Whisper
        placement="auto"
        container={container}
        onOpen={() => {
          try {
            // @ts-ignore
            const speakerClassName = props.speaker.props.className;
            if (speakerClassName) {
              const speakerEl =
                container.getElementsByClassName(speakerClassName)[0];
              // @ts-ignore
              if (speakerEl) {
                // @ts-ignore
                if (speakerEl.scrollIntoViewIfNeeded) {
                  // @ts-ignore
                  speakerEl.scrollIntoViewIfNeeded({ behavior: "smooth" });
                  return;
                }
                speakerEl.scrollIntoView({ behavior: "smooth" });
              }
            }
          } catch (e) {
            console.log(e);
          }
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

export default MwWhisper;
