import * as React from "react";
import { SVGProps } from "react";

const WidgetTypeSearchTopic = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46.436}
      height={46.437}
      viewBox="0 0 46.436 46.437"
      {...props}
    >
      <g transform="translate(-1721.243 -614.859)">
        <path
          fill="#e68c91"
          d="M1735.881 649.427a2.494 2.494 0 01-3.175-3.3l5.408-12.986a2.5 2.5 0 011.431-1.378l13.5-5.038a2.494 2.494 0 013.175 3.3l-5.38 12.92a2.665 2.665 0 01-1.525 1.47z"
        />
        <circle
          cx={3.511}
          cy={3.511}
          r={3.511}
          fill="#fff"
          transform="translate(1740.95 634.566)"
        />
        <path
          fill="#96afaf"
          d="M1744.461 661.3a23.219 23.219 0 1116.426-6.793 23.159 23.159 0 01-16.426 6.793zm0-43.484a20.266 20.266 0 1014.336 5.929 20.217 20.217 0 00-14.335-5.933z"
        />
      </g>
    </svg>
  );
};

export default WidgetTypeSearchTopic;
