import React, { RefObject } from "react";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { Divider, Icon, Input, InputGroup, Popover, TagGroup } from "rsuite";
import MagnifyingGlass from "../../../icons/MagnifyingGlass";
import "../index.scss";
import LabelIcon from "../../../icons/Label";
import LabelTag from "../../LabelTag";
import { containsWordThatStartWith } from "../../../inc/query";
import MwTag from "../../MwTag";
import { TypeAttributes } from "rsuite/lib/@types/common";
import { I18nContext } from "../../../provider/I18nProvider";
import { components } from "../../../types/openapi";
import useLabels from "../../../hooks/useLabels";
import MwWhisper from "../../MwWhisper";

interface ILabelPopoverSelectProps {
  placement?: TypeAttributes.Placement;
  popoverClasses?: string;
  children: React.ReactNode;
  value?: string;
  onChange: (label: components["schemas"]["Label"]) => void;
}

const LabelPopoverSelect = ({
  value,
  children,
  placement,
  popoverClasses,
  onChange,
}: ILabelPopoverSelectProps) => {
  const { t } = React.useContext(I18nContext);
  const { updateLabel } = React.useContext(ApiDataContext);
  const labels = useLabels();
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const results = React.useMemo(
    () =>
      containsWordThatStartWith(
        Object.values(labels || {}),
        "name",
        searchQuery
      ),
    [labels, searchQuery]
  );

  const triggerRef: RefObject<any> = React.useRef();
  const apply = React.useCallback(
    (label: components["schemas"]["Label"]) => {
      onChange(label);
      if (triggerRef.current) {
        triggerRef.current.hide();
      }
    },
    [onChange, triggerRef]
  );

  const renderedResults = React.useMemo(() => {
    if (!results.length) {
      return searchQuery.trim().length ? (
        <MwTag
          size="lg"
          iconComponent={LabelIcon}
          color="cyan"
          rounded
          onClick={() => {
            updateLabel({
              name: searchQuery.trim(),
            }).then((label) => {
              if (label.labelId) {
                apply(label);
              }
            });
          }}
        >
          {t("createOption", searchQuery)}
        </MwTag>
      ) : null;
    }
    return (
      <>
        {results.map((result) => (
          <LabelTag
            key={result.labelId}
            size="lg"
            color={value === result.labelId ? "green" : "cyan"}
            rounded
            onClick={() => apply(result)}
            label={result}
          />
        ))}
      </>
    );
  }, [results, searchQuery, t, updateLabel, apply, value]);

  return (
    <MwWhisper
      placement={placement || "bottom"}
      triggerRef={triggerRef}
      trigger="click"
      speaker={
        <Popover
          className={`popover-without-arrow label-popover ${popoverClasses}`}
        >
          <InputGroup className="label-popover__search-input-group" inside>
            <InputGroup.Addon>
              <Icon icon="star" componentClass={MagnifyingGlass} />
            </InputGroup.Addon>
            <Input
              className="label-popover__search-input"
              size="sm"
              value={searchQuery}
              onChange={(value) => setSearchQuery(value)}
            />
          </InputGroup>
          <Divider />
          <TagGroup className="label-popover__results">
            {renderedResults}
          </TagGroup>
        </Popover>
      }
    >
      {children}
    </MwWhisper>
  );
};
export default LabelPopoverSelect;
