import React, { SVGProps } from "react";

const ChartPie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25.998}
    height={26.001}
    viewBox="0 0 25.998 26.001"
    {...props}
  >
    <defs>
      <style>{".prefix__cls-1{fill:#9baeaf}"}</style>
    </defs>
    <g id="prefix__Component_85_1">
      <path
        id="prefix__Subtraction_1"
        d="M16771 5271v-12h-12a12 12 0 1112 12z"
        className="prefix__cls-1"
        transform="rotate(90 11015 -5742)"
      />
      <path
        id="prefix__Intersection_1"
        d="M16756 5266a12 12 0 0112-12v12z"
        className="prefix__cls-1"
        transform="translate(-16756 -5254.002)"
      />
    </g>
  </svg>
);

export default ChartPie;
