import React from "react";
import { Icon } from "rsuite";
import "./index.scss";
import DragHandleIcon from "../../icons/DragHandle";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";

interface IDragHandle {
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const DragHandle = ({ dragHandleProps }: IDragHandle) => (
  <div className="drag-handle" {...dragHandleProps}>
    <Icon icon="arrows" componentClass={DragHandleIcon} />
  </div>
);
export default DragHandle;
