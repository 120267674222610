import React from "react";
import SchemaTable from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../types/openapi";
import { capitalize } from "lodash";
import { I18nContext } from "../../provider/I18nProvider";
import useUsers from "../../hooks/useUsers";
import useMediaItemPurchases from "../../hooks/useMediaItemPurchases";
import { generateAndDownload } from "../../inc/excel";
import { DATE_FORMAT } from "../../inc/date";
import { format } from "date-fns";
import { IHashMap } from "../../inc/data";
import { AuthContext } from "../../provider/AuthProvider";

const schema = openapi.components.schemas.MediaItemPurchase as oas30.SchemaObject;

const MediaItemPurchasesView = () => {
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const users = useUsers();
  const mediaItemPurchaseMap = useMediaItemPurchases();

  const currentUserRoles = auth ? auth.jwt.userRoles : [];

  const data = React.useMemo(() => {
    return mediaItemPurchaseMap
      ? Object.values(mediaItemPurchaseMap)
      : undefined;
  }, [mediaItemPurchaseMap]);

  const userMap = React.useMemo(() => {
    return users
      ? users.reduce<IHashMap<components["schemas"]["User"]>>((prev, user) => {
          prev[user.userId as string] = user;
          return prev;
        }, {})
      : {};
  }, [users]);

  const onExport = React.useCallback(
    (mediaItemPurchases: components["schemas"]["MediaItemPurchase"][]) => {
      generateAndDownload(
        t("navigation_admin_mediaItemPurchases"),
        [
          {
            header: t("purchaseDatetime"),
            key: "purchaseDatetimeFormatted",
            width: 20,
          },
          { header: t("title"), key: "title", width: 40 },
          { header: t("mediaType"), key: "mediaType" },
          {
            header: "Aanschaf door gebruikersnaam",
            key: "purchaseUserName",
            width: 20,
          },
          { header: "Aanschaf door", key: "purchaseDisplayName", width: 20 },
          { header: t("purchaseStatus"), key: "purchaseStatus" },
        ],
        mediaItemPurchases.map((mediaItemPurchase) => ({
          ...mediaItemPurchase,
          purchaseDatetimeFormatted: format(
            new Date(mediaItemPurchase.purchaseDatetime),
            DATE_FORMAT
          ),
          purchaseUserName: userMap[mediaItemPurchase.userId]
            ? userMap[mediaItemPurchase.userId].userName
            : `Gebruiker ${mediaItemPurchase.userId}`,
          purchaseDisplayName: userMap[mediaItemPurchase.userId]
            ? userMap[mediaItemPurchase.userId].displayName
            : `Gebruiker ${mediaItemPurchase.userId}`,
          mediaType: capitalize(mediaItemPurchase.mediaType),
          purchaseStatus: t(mediaItemPurchase.purchaseStatus || ""),
        }))
      );
    },
    [t, userMap]
  );

  return (
    <SchemaTable<components["schemas"]["MediaItemPurchase"]>
      data={data}
      onExport={
        currentUserRoles.indexOf("manager") === -1 ? undefined : onExport
      }
      schema={schema}
      globalSearch
      loading={!data}
      sortable
      columnsConfigs={[
        { name: "labelIds", hidden: true },
        { name: "mediaItemPurchaseId", hidden: true },
        { name: "mediaItemId", hidden: true },
        { name: "searchEngineResultId", hidden: true },
        { name: "url", hidden: true },
        { name: "readyDatetime", hidden: true },
        { name: "purchaseDatetime", width: 170 },
        { name: "title" },
        {
          name: "mediaType",
          renderCell: (rowData) =>
            rowData.mediaType ? capitalize(rowData.mediaType) : "",
          width: 100,
        },
        {
          name: "userId",
          title: "Aanschaf door",
          width: 250,
          hidden: currentUserRoles.indexOf("manager") === -1,
          renderCell: (rowData) => {
            if (!userMap) {
              return "...";
            }
            const user = userMap[rowData.userId];
            return user ? user.userName : "";
          },
        },
        {
          name: "purchaseStatus",
          renderCell: (rowData) =>
            rowData.purchaseStatus && rowData.purchaseStatus !== "success"!
              ? t(rowData?.purchaseStatus)
              : "Toegevoegd",
          width: 170,
        },
      ]}
    />
  );
};
export default MediaItemPurchasesView;
