import React from "react";
import { Alert } from "rsuite";
import { ApiDataContext } from "../../../../provider/ApiDataProvider";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { INavItemProps } from "../../useUserNavConfig";
import { I18nContext } from "../../../../provider/I18nProvider";
import useDashboards from "../../../../hooks/useDashboards";
import DashboardDropDown from "./DashboardDropDown";
import DashboardDropRight from "./DashboardDropRight";
import { LayoutContext } from "../../../../provider/LayoutProvider";

import "./index.scss";

const DashboardSubmenu = ({ title, to }: INavItemProps) => {
  const { setDashboards } = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const { isSidebarOpen } = React.useContext(LayoutContext);
  const dashboards = useDashboards();

  const history = useHistory();

  const items = React.useMemo(
    () =>
      (dashboards ? Object.values(dashboards) : [])
        .filter((dashboard) => !!dashboard.dashboardId)
        .map((dashboard) => ({
          id: dashboard.dashboardId as string,
          title: dashboard.name,
        }))
        .sort((a, b) =>
          a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1,
        ),
    [dashboards],
  );

  const onItemSelect = React.useCallback(
    (dashboardId: string) => history.push(`${to}/${dashboardId}`),
    [history, to],
  );

  const onItemDelete = React.useCallback(
    (dashboardId: string) => {
      if (!window.confirm(t("deleteConfirmation"))) {
        return;
      }
      axios
        .delete(`/dashboard/crud/${dashboardId}`)
        .then(() => {
          setDashboards((dashboards) => {
            if (!dashboards) {
              return dashboards;
            }
            const newDashboards = { ...dashboards };
            delete newDashboards[dashboardId];
            return newDashboards;
          });
          Alert.success(t("deleteSuccessful"));
          history.push(to);
        })
        .catch((err) => {
          Alert.error(t(err.message || "genericErrorMessage"));
        });
    },
    [history, setDashboards, t, to],
  );

  return isSidebarOpen ? (
    <DashboardDropDown
      title={title}
      baseNavigation={to}
      items={items}
      onItemSelect={onItemSelect}
      onItemDeleteClick={onItemDelete}
    />
  ) : (
    <DashboardDropRight
      title={title}
      baseNavigation={to}
      items={items}
      onItemSelect={onItemSelect}
    />
  );
};
export default DashboardSubmenu;
