import { Icon, Tooltip } from "rsuite";
import MwWhisper from "../MwWhisper";
import React from "react";
import "./index.scss";

interface IHelpIconProps {
  children: string;
  href?: string;
}

const HelpIcon = ({ children, href }: IHelpIconProps) => (
  <MwWhisper trigger="hover" speaker={<Tooltip>{children}</Tooltip>}>
    {href ? (
      <a href={href} target="_blank" rel="noreferrer">
        <Icon icon="question" className="help-icon" />
      </a>
    ) : (
      <Icon icon="question" className="help-icon" />
    )}
  </MwWhisper>
);

export default HelpIcon;
