import React from "react";
import { APP_PATH, LOGIN_PATH } from "../../../inc/constants";
import { paths } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { Alert, Button, ButtonToolbar, Form, FormGroup } from "rsuite";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import {
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../../inc/ajv";
import { Redirect, useHistory } from "react-router-dom";
import LoginLayout from "../../../layout/LoginLayout";
import "./index.scss";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";

type TNewPasswordRequest =
  paths["/auth/newPassword"]["post"]["requestBody"]["content"]["application/json"] & {
    newPasswordRepeat: string;
  };

const schema = openapi.paths["/auth/newPassword"].post.requestBody.content[
  "application/json"
].schema as oas30.SchemaObject;
const NewPasswordRequestSchema = {
  ...schema,
  properties: {
    ...schema.properties,
    newPasswordRepeat: {
      ...schema.properties?.newPassword,
    },
  },
  required: [...(schema.required || []), "newPasswordRepeat"],
} as oas30.SchemaObject;
const validate = ajv.compile({
  ...NewPasswordRequestSchema,
  properties: {
    ...NewPasswordRequestSchema.properties,
    currentPassword: {
      ...NewPasswordRequestSchema.properties?.currentPassword,
      format: undefined,
      minLength: 1,
    },
  },
});
const ExpiredPasswordView = () => {
  const [newPasswordRequest, setNewPasswordRequest] =
    React.useState<TNewPasswordRequest>(
      getEmptyObject<TNewPasswordRequest>(NewPasswordRequestSchema),
    );
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const [status, setStatus] = React.useState<"idle" | "pending">("idle");
  const { auth, setBearer } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const history = useHistory();
  if (!auth) {
    return <Redirect to={LOGIN_PATH} />;
  }

  return (
    <LoginLayout
      img="/img/art/key/key@3x.png"
      img2x="/img/art/key/key@3x.png"
      img3x="/img/art/key/key@3x.png"
    >
      <h1>{t("passwordExpiredTitle")}</h1>
      <p>{t("passwordExpiredDescription")}</p>
      <Form
        style={{ marginTop: 40 }}
        onSubmit={() => {
          const { newPasswordRepeat, ...request } = newPasswordRequest;
          if (newPasswordRepeat !== request.newPassword) {
            setErrors({
              newPasswordRepeat: t("newPasswordRepeatIsntEqualToNewPassword"),
            });
            return;
          }
          if (request.newPassword === request.currentPassword) {
            setErrors({
              newPassword: t("newPasswordShouldBeNew"),
            });
            return;
          }
          const isValid = validate(newPasswordRequest);
          if (!isValid && validate.errors && validate.errors.length) {
            console.log(validate.errors);
            setErrors(validationErrorsToSchemaFormErrors(validate.errors));
            return;
          }
          setStatus("pending");
          setErrors({});
          axios
            .post("/auth/newPassword", request)
            .then((res) => {
              setBearer(res.data.bearer);
              history.push(APP_PATH);
            })
            .catch(() => {
              setStatus("idle");
              Alert.error(t("invalidData"));
            });
        }}
      >
        <SchemaFormBody<TNewPasswordRequest>
          errors={errors}
          schema={NewPasswordRequestSchema}
          value={newPasswordRequest}
          onChange={setNewPasswordRequest}
          disabled={status !== "idle"}
        />
        <FormGroup style={{ marginTop: 40 }}>
          <ButtonToolbar>
            <Button
              type="submit"
              appearance="primary"
              disabled={status !== "idle"}
            >
              {t("setNewPassword")}
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
      <Button
        appearance="link"
        errors={errors}
        className="p-0 mt-5"
        onClick={() => {
          setBearer(undefined);
          window.location.href = "/login/lostPassword";
        }}
      >
        {t("forgotOldPassword")}
      </Button>
    </LoginLayout>
  );
};

export default ExpiredPasswordView;
