import React from "react";
import { Popover } from "rsuite";
import { SketchPicker } from "react-color";
import "./index.scss";
import MwWhisper from "../../MwWhisper";

interface IColorPickerProps {
  value?: string;
  onChange?: (
    newValue: string,
    event: React.SyntheticEvent<HTMLElement, Event>,
  ) => void;
}

const ColorPicker = (props: IColorPickerProps) => {
  const { value, onChange } = props;

  const colorPickerDisplay = React.useMemo(
    () => (
      <div className="color-picker">
        <div className="color-picker__inner" style={{ background: value }} />
      </div>
    ),
    [value],
  );

  if (!onChange) {
    return <>{colorPickerDisplay}</>;
  }

  return (
    <MwWhisper
      trigger="active"
      placement="bottom"
      preventOverflow
      speaker={
        <Popover className="p-0">
          <SketchPicker
            color={value}
            onChange={(color) => {
              onChange(color.hex, new Event("change") as any);
            }}
          />
        </Popover>
      }
    >
      {colorPickerDisplay}
    </MwWhisper>
  );
};

export default React.memo(ColorPicker);
