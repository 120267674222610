import React from "react";
import { components } from "../../../../../types/openapi";
import AdvancedSearchEngineResultsBase from "../AdvancedSearchEngineResultsBase";
import _ from "lodash";

interface IAdvancedSearchEngineResultsLabelProps {
  title: string;
  searchQuery: string;
  results?: components["schemas"]["Label"][] | null;
  onChange: (ids: string[][]) => void;
  activeIds: string[][];
}

const AdvancedSearchEngineResultsLabel = (
  props: IAdvancedSearchEngineResultsLabelProps,
) => {
  const { title, searchQuery, results, activeIds } = props;
  // The CheckboxGroup can only detect values from single dimensional array
  const values = React.useMemo(
    () => activeIds.filter((ids) => ids.length === 1).map((ids) => ids[0]),
    [activeIds],
  );

  const getResultId = React.useCallback(
    (result: any) => (result as components["schemas"]["Label"]).labelId || "",
    [],
  );

  const allResultIds = React.useMemo(
    () => results?.map((result) => getResultId(result)) || [],
    [getResultId, results],
  );

  return (
    <AdvancedSearchEngineResultsBase
      title={title}
      searchQuery={searchQuery}
      results={results}
      values={_.intersection(values, allResultIds)} // Only return the values that are part of the result set
      onChange={(ids: string[]) => {
        // Return the values (that aren't part of the result set) and the new ids
        const otherIds = _.difference(values, allResultIds);

        // Merge the old single labels, new single labels and the combine labels filters
        return props.onChange([
          ...activeIds.filter((ids) => ids.length === 2),
          ...otherIds.map((id) => [id]), // Make ids multi dimensional
          ...ids.map((id) => [id]), // Make ids multi dimensional
        ]);
      }}
      getResultId={getResultId}
      allResultIds={allResultIds}
    />
  );
};
export default AdvancedSearchEngineResultsLabel;
