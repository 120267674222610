import React, { SVGProps } from "react";

const FavouriteFilled = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.377}
      height={10.656}
      viewBox="0 0 118.417 101.958"
      fill="currentColor"
      {...props}
    >
      <path d="M84.895 0a33.346 33.346 0 00-25.742 12.8A33.41 33.41 0 0033.426 0h-.336A34.116 34.116 0 00.018 35c0 42.854 54.547 65.665 56.866 66.617a4.568 4.568 0 001.717.334 4.488 4.488 0 001.762-.357 134.884 134.884 0 0015.8-8.14c27.639-16.786 42.246-37.013 42.246-58.383A34.33 34.33 0 0084.895 0z" />
    </svg>
  );
};

export default FavouriteFilled;
