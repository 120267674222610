import React from "react";
import { components } from "../../../../types/openapi";
import BulkLabelButton from "./BulkLabelButton";
import FavouriteFilled from "../../../../icons/FavouriteFilled";
import { Divider, Dropdown, Icon, Nav } from "rsuite";
import Favourite from "../../../../icons/Favourite";
import DropdownNavItem from "../../../../components/DropdownNavItem";
import AddToEditorialNavItem from "../../../../components/AddToEditorialNavItem";
import FlagFilled from "../../../../icons/FlagFilled";
import Flag from "../../../../icons/Flag";
import Export from "../../../../icons/Export";
import IconNavItem from "../../../../components/IconNavItem";
import Prullie from "../../../../icons/Prullie";
import MoreOptionsPopover from "./MoreOptionsPopover";
import { I18nContext } from "../../../../provider/I18nProvider";
import SentimentPositive from "../../../../icons/SentimentPositive";
import ReloadIcon from "@rsuite/icons/Reload";
import { ESentiment, sentimentOptions } from "../../../../inc/sentimentOptions";

interface IBulkActionsBarProps {
  getSelectionCacheToken: () => Promise<string>;
  onBulkFavourite: (favourite: boolean) => void;
  onBulkRead: (read: boolean) => void;
  onBulkSentiment: (sentiment: ESentiment | null) => void;
  onOpenDeleteModal: () => void;
  onPdfExport: () => void;
  patchMediaItem: (
    mediaItemId: string,
    data: components["schemas"]["MediaItemPartial"]
  ) => void;
  selectedHydratedMediaItems: components["schemas"]["MediaItem"][];
  selectedMediaItemIds: string[];
  setFilterResults: (
    searchResults: components["schemas"]["MediaItem"][]
  ) => void;
  toggleShowExcelModal: () => void;
  warnForIncompleteSelection: boolean;
  width: number;
}

const BulkActionsBar = ({
  getSelectionCacheToken,
  onBulkFavourite,
  onBulkRead,
  onBulkSentiment,
  onOpenDeleteModal,
  onPdfExport,
  setFilterResults,
  patchMediaItem,
  selectedHydratedMediaItems,
  selectedMediaItemIds,
  toggleShowExcelModal,
  warnForIncompleteSelection,
  width,
}: IBulkActionsBarProps) => {
  const { t } = React.useContext(I18nContext);
  const triggerRef = React.useRef(null);

  const [barControls, menuControls] = React.useMemo(() => {
    const barControls: React.ReactElement[] = [];
    const menuControls: React.ReactElement[] = [];

    const componentMap = {
      labelIds: {
        control: (
          <BulkLabelButton
            key="labelIds"
            getSelectionCacheToken={getSelectionCacheToken}
            hasChevron
            patchMediaItem={patchMediaItem}
            placement={width < 600 ? "rightStart" : "bottom"}
            selectedHydratedMediaItems={selectedHydratedMediaItems}
            setFilterResults={setFilterResults}
          />
        ),
        barBreakpoint: 600,
      },
      isInEditorialRubricIds: {
        control: (
          <AddToEditorialNavItem
            key="isInEditorialRubricIds"
            forBulk
            hasChevron
            hideSelectedCount
            mediaItemIds={selectedMediaItemIds}
            warnForIncompleteSelection={warnForIncompleteSelection}
            placement={width < 710 ? "rightStart" : "bottom"}
          />
        ),
        barBreakpoint: 710,
      },
      export: {
        control: (
          <DropdownNavItem
            key="export"
            title={t("export")}
            iconComponent={Export}
            placement={width < 799 ? "rightStart" : "bottomStart"}
          >
            <Dropdown.Item onSelect={onPdfExport}>PDF</Dropdown.Item>
            <Dropdown.Item onSelect={toggleShowExcelModal}>Excel</Dropdown.Item>
          </DropdownNavItem>
        ),
        barBreakpoint: 799,
      },
      isFavourite: {
        control: (
          <DropdownNavItem
            key="isFavourite"
            title={t("favorite")}
            iconComponent={FavouriteFilled}
            placement={width < 900 ? "rightStart" : "bottomStart"}
          >
            <Dropdown.Item
              icon={<Icon icon="question" componentClass={FavouriteFilled} />}
              onSelect={() => onBulkFavourite(true)}
            >
              {t("favorite")}
            </Dropdown.Item>
            <Dropdown.Item
              icon={<Icon icon="question" componentClass={Favourite} />}
              onSelect={() => onBulkFavourite(false)}
            >
              {t("unfavorite")}
            </Dropdown.Item>
          </DropdownNavItem>
        ),
        barBreakpoint: 900,
      },
      isRead: {
        control: (
          <DropdownNavItem
            key="isRead"
            title={t("read")}
            iconComponent={FlagFilled}
            placement={width < 970 ? "rightStart" : "bottomStart"}
          >
            <Dropdown.Item
              icon={<Icon icon="question" componentClass={Flag} />}
              onSelect={() => onBulkRead(true)}
            >
              {t("read")}
            </Dropdown.Item>
            <Dropdown.Item
              icon={<Icon icon="question" componentClass={FlagFilled} />}
              onSelect={() => onBulkRead(false)}
            >
              {t("unread")}
            </Dropdown.Item>
          </DropdownNavItem>
        ),
        barBreakpoint: 970,
      },
      sentiment: {
        control: (
          <DropdownNavItem
            key="sentiment"
            title={t("sentiment")}
            iconComponent={SentimentPositive}
            placement={width < 1080 ? "rightStart" : "bottomStart"}
          >
            {[
              ESentiment.POSITIVE,
              ESentiment.NEUTRAL,
              ESentiment.NEGATIVE,
              ESentiment.UNDEFINED,
            ].map((sentiment) => (
              <Dropdown.Item
                key={`sentiment_${sentimentOptions[sentiment].value}`}
                icon={
                  sentimentOptions[sentiment].icon ? (
                    <Icon
                      icon="question"
                      componentClass={sentimentOptions[sentiment].icon}
                      style={{ color: sentimentOptions[sentiment].color }}
                    />
                  ) : undefined
                }
                onSelect={() =>
                  onBulkSentiment(sentimentOptions[sentiment].value)
                }
              >
                {t(sentimentOptions[sentiment].label)}
              </Dropdown.Item>
            ))}
            <Dropdown.Item
              icon={<Icon icon="question" componentClass={ReloadIcon} />}
              onSelect={() => onBulkSentiment(null)}
            >
              {t("resetOriginal")}
            </Dropdown.Item>
          </DropdownNavItem>
        ),
        barBreakpoint: 1080,
      },
      delete: {
        control: (
          <IconNavItem
            key="delete"
            title={t("delete")}
            iconComponent={Prullie}
            onClick={onOpenDeleteModal}
          />
        ),
        // this should be the same as the one above: there is no menu with a single item
        barBreakpoint: 1080,
      },
    };

    Object.keys(componentMap).forEach((controlKey) => {
      // @ts-ignore
      const { control, barBreakpoint } = componentMap[controlKey];
      if (width < barBreakpoint) {
        menuControls.push(control);
        if (controlKey !== "delete") {
          menuControls.push(
            <Divider style={{ margin: 0 }} key={`${controlKey}Divider`} />
          );
        }
        return;
      }
      barControls.push(control);
    });
    return [barControls, menuControls];
  }, [
    getSelectionCacheToken,
    onBulkFavourite,
    onBulkRead,
    onBulkSentiment,
    onOpenDeleteModal,
    onPdfExport,
    patchMediaItem,
    selectedHydratedMediaItems,
    selectedMediaItemIds,
    setFilterResults,
    t,
    toggleShowExcelModal,
    warnForIncompleteSelection,
    width,
  ]);

  const closePopover = React.useCallback(() => {
    if (triggerRef.current) {
      (triggerRef.current as any).close();
    }
  }, []);

  const moreOptionsPopover = React.useMemo(
    () =>
      menuControls.length ? <MoreOptionsPopover items={menuControls} /> : null,
    [menuControls]
  );

  React.useEffect(() => {
    const handleClickOutsideActionBar = (event: MouseEvent) => {
      const actionBar = document.querySelector(
        ".views__dashboard-view__widgets__mediaitem-widget__action-bar"
      );
      const popover = document.querySelector(
        ".views__dashboard-view__widgets__mediaitem-widget__action-bar__popover"
      );
      const navItemPopovers = document.querySelectorAll(
        ".views__dashboard-view__widgets__mediaitem-widget__action-bar__navbar-item-popover"
      );

      // Does user click within the actionBar, the popover, or any of the navbar popovers?
      // If not, close the popover
      if (
        actionBar &&
        !actionBar?.contains(event.target as Node) &&
        (!popover || !popover.contains(event.target as Node)) &&
        (!navItemPopovers.length ||
          !Array.from(navItemPopovers).some((navItemPopover) =>
            navItemPopover.contains(event.target as Node)
          ))
      ) {
        closePopover();
      }
    };
    document.addEventListener("mousedown", handleClickOutsideActionBar);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideActionBar);
    };
  }, [closePopover]);

  return (
    <div className="views__dashboard-view__widgets__mediaitem-widget__action-bar">
      <Nav className="views__dashboard-view__widgets__mediaitem-widget__action-bar__navbar text-end">
        {barControls}
        {moreOptionsPopover}
      </Nav>
    </div>
  );
};
export default BulkActionsBar;
