import axios from "axios";
import { ConcurrencyManager } from "axios-concurrency";
import { AUTH_LOCAL_STORAGE_KEY, IAuth } from "../provider/AuthProvider";
import { differenceInSeconds } from "date-fns";
import { parseLocationSearch } from "./data";

const { REACT_APP_API_BASE_URL = "https://api.mediaweb.eu/v5/", NODE_ENV } =
  process.env;

// e.g. loading an editorial has LOADS of axios calls. This make it more managable without choking the browser
ConcurrencyManager(axios, 4);

let api: string | undefined;
if (NODE_ENV === "development") {
  api = parseLocationSearch<{ api?: string }>(window.location.search).api;
}

axios.defaults.baseURL = api || REACT_APP_API_BASE_URL;

axios.interceptors.request.use((config: any) => {
  const stringifiedAuth = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!stringifiedAuth || config.url?.startsWith("http")) {
    return config;
  }
  let auth: IAuth = JSON.parse(stringifiedAuth);
  const age = differenceInSeconds(new Date(), new Date(auth.issuedAt));
  if (age > auth.jwt.tokenExpirationDuration) {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    // cannot continue with invalid token, possible late refresh?
    window.location.href = "/";
    return config;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${auth.bearer}`,
    },
  };
});

// Add global handle for debugging purposes
if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  window.axios = axios;
}

export default axios;
