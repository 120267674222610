import { Checkbox } from "rsuite";
import React from "react";
import { IColumnConfig } from "../../../../components/SchemaTable";
import { components } from "../../../../types/openapi";

export function getMemberColumnConfig(
  editorialSubscribers: components["schemas"]["EditorialSubscriber"][],
  editorialMediumPropName: "useMail" | "useApp",
  onChange: (
    editorialSubscriber: components["schemas"]["EditorialSubscriber"]
  ) => void
): IColumnConfig<components["schemas"]["EditorialSubscriber"]> {
  return {
    name: editorialMediumPropName,
    width: 150,
    sort: (editorialSubscriberA, editorialSubscriberB, sortAsc) => {
      const scoreA =
        editorialSubscriberA && editorialSubscriberA[editorialMediumPropName]
          ? 1
          : -1;
      const scoreB =
        editorialSubscriberB && editorialSubscriberB[editorialMediumPropName]
          ? 1
          : -1;
      return (scoreA - scoreB) * (sortAsc ? 1 : -1);
    },
    renderCell: (rowEditorialSubscriber) => (
      <Checkbox
        checked={rowEditorialSubscriber[editorialMediumPropName]}
        onChange={() => {
          onChange({
            ...rowEditorialSubscriber,
            [editorialMediumPropName]:
              !rowEditorialSubscriber[editorialMediumPropName],
          });
        }}
      />
    ),
  };
}
