import React from "react";
import SchemaTable from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../types/openapi";
import axios from "../../inc/axios";
import { formatInt } from "../../inc/numbers";
import { I18nContext } from "../../provider/I18nProvider";
import { Icon, Input, InputGroup, Loader } from "rsuite";
import { LayoutContext } from "../../provider/LayoutProvider";

const schema = openapi.components.schemas.ActiveSource as oas30.SchemaObject;

const ActiveSourcesView = () => {
  const { t } = React.useContext(I18nContext);
  const { setIsHelpBarOpen } = React.useContext(LayoutContext);

  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [activeSources, setActiveSources] = React.useState<
    components["schemas"]["ActiveSource"][] | null
  >();

  React.useEffect(() => {
    if (typeof activeSources === "undefined") {
    }
  }, [activeSources]);

  const onSubmit = React.useCallback(
    (e:any) => {
      e.preventDefault();
      if (searchQuery.length > 1 && activeSources !== null) {
        setActiveSources(null);
        axios
          .request({
            method: "get",
            url: "/activeSource/crud",
            params: {
              query: searchQuery,
            },
          })
          .then((res) => {
            setActiveSources(res.data);
          });
      }
    },
    [activeSources, searchQuery]
  );

  return (
    <div className="d-flex flex-column h-100">
      <form className="schema-table__topbar" onSubmit={onSubmit}>
        <div className="row">
          <div className="col col-md-6 offset-2 offset-sm-1 offset-md-0">
            <InputGroup
              size="sm"
              inside
              className="schema-table__topbar__search"
            >
              <InputGroup.Addon>
                <Icon icon="search" />
              </InputGroup.Addon>
              <Input
                placeholder={`${t("search")}...`}
                value={searchQuery}
                onChange={(value) => setSearchQuery(value)}
              />
            </InputGroup>
          </div>
          {/*<div*/}
          {/*  className={"col-auto col-md-6 schema-table__topbar__col--right"}*/}
          {/*  style={{ display: "flex", justifyContent: "flex-end" }}*/}
          {/*>*/}
          {/*  <Button appearance="primary">Zoeken</Button>*/}
          {/*</div>*/}
        </div>
      </form>
      {activeSources === null ? <Loader /> : null}
      {activeSources === undefined ? (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center pt-5 flex-column">
          <h2>{t("sources")}</h2>
          <p className="mb-3">{t("activeSourcesView_startDescription")}</p>
        </div>
      ) : null}
      {activeSources ? (
        <>
          <SchemaTable<components["schemas"]["ActiveSource"]>
            data={activeSources}
            schema={schema}
            sortable
            columnsConfigs={[
              {
                name: "mediaType",
                width: 100,
                renderCell: (rowData) => t(`mediaType_${rowData.mediaType}`),
              },
              {
                name: "name",
                flexGrow: 1,
              },
              {
                name: "currentReach",
                title: "Bereik",
                renderCell: (rowData) =>
                  rowData.currentReach ? formatInt(rowData.currentReach) : null,
              },
              {
                name: "currentCirculation",
                title: "Oplage",
                renderCell: (rowData) =>
                  rowData.currentCirculation
                    ? formatInt(rowData.currentCirculation)
                    : null,
              },
            ]}
          />
          <p>
            {t("activeSourcesView_help")}
            {" " /* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => {
                setIsHelpBarOpen(true);
                const Tawk_API = (window as any).Tawk_API;
                if (Tawk_API) {
                  Tawk_API.maximize();
                }
              }}
            >
              {t("activeSourcesView_letUsKnow")}
            </a>
          </p>
        </>
      ) : null}
    </div>
  );
};

export default ActiveSourcesView;
