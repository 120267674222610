import React from "react";
import SentimentPositive from "../icons/SentimentPositive";
import SentimentNeutral from "../icons/SentimentNeutral";
import SentimentNegative from "../icons/SentimentNegative";

export enum ESentiment {
  UNDEFINED,
  NEGATIVE,
  NEUTRAL,
  POSITIVE,
}

export type TSentimentOption = {
  label: string;
  color?: string;
  value: number;
  icon?: React.ElementType;
};

export const sentimentOptions: {
  [key in ESentiment]: TSentimentOption;
} = {
  [ESentiment.POSITIVE]: {
    label: "positive",
    value: ESentiment.POSITIVE,
    icon: SentimentPositive,
    color: "#3cbe8c",
  },
  [ESentiment.NEUTRAL]: {
    label: "neutral",
    value: ESentiment.NEUTRAL,
    icon: SentimentNeutral,
    color: "#2d50b4",
  },
  [ESentiment.NEGATIVE]: {
    label: "negative",
    value: ESentiment.NEGATIVE,
    icon: SentimentNegative,
    color: "#e68c91",
  },
  [ESentiment.UNDEFINED]: {
    label: "sentimentUndefined",
    value: ESentiment.UNDEFINED,
  },
};

export const chartSortedSentiments = [
  ESentiment.NEGATIVE,
  ESentiment.NEUTRAL,
  ESentiment.POSITIVE,
  ESentiment.UNDEFINED,
];
