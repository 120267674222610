import React from "react";
import EditorialItem from "./EditorialItem";
import { Loader } from "rsuite";
import { components } from "../../types/openapi";
import useEditorials from "../../hooks/useEditorials";

const EditorialsView = () => {
  const editorials = useEditorials();

  if (!editorials) {
    return <Loader />;
  }

  return (
    <div>
      {Object.values(editorials).map(
        (editorial: components["schemas"]["Editorial"]) => (
          <EditorialItem key={editorial.editorialId} editorial={editorial} />
        )
      )}
    </div>
  );
};
export default EditorialsView;
