import React from "react";
import { Badge } from "rsuite";
import "./index.scss";

interface IWarningBadgeProps {
  children?: React.ReactChild;
  hide?: boolean;
  style?: React.CSSProperties;
}

const WarningBadge = ({ children, style, hide }: IWarningBadgeProps) => (
  <Badge className="warning-badge" content={hide ? false : "!"} style={style}>
    {children}
  </Badge>
);
export default WarningBadge;
