import React from "react";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../../components/SchemaFormBody";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { paths } from "../../../../types/openapi";
import {
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../../inc/schema";
import { Button, ButtonToolbar, Form, FormGroup, Message } from "rsuite";
import axios from "../../../../inc/axios";
import ajv from "../../../../inc/ajv";
import { I18nContext } from "../../../../provider/I18nProvider";
import { AuthContext, parseJwt } from "../../../../provider/AuthProvider";
import { useHistory } from "react-router-dom";

type TLoginRequest =
  paths["/auth/login"]["post"]["requestBody"]["content"]["application/json"];

const loginRequestSchema = openapi.paths["/auth/login"].post.requestBody
  .content["application/json"].schema as oas30.SchemaObject;
const validate = ajv.compile({
  ...loginRequestSchema,
  properties: {
    ...loginRequestSchema.properties,
    password: {
      ...loginRequestSchema.properties?.password,
      // allow an insecure password to accept first entry of old password
      format: undefined,
    },
  },
});

const LoginForm = () => {
  const [loginRequest, setLoginRequest] = React.useState<TLoginRequest>(
    getEmptyObject<TLoginRequest>(loginRequestSchema),
  );
  const [formErrors, setErrors] = React.useState<
    TSchemaFormErrors<TLoginRequest & { api: string }>
  >({});
  const { auth, setBearer } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const history = useHistory();

  const isInProgress = auth === null;
  return (
    <>
      <h1>{t("loginForm_title")}</h1>
      <Form
        style={{ marginTop: 40 }}
        onSubmit={() => {
          const isValid = validate(loginRequest);
          if (!isValid && validate.errors && validate.errors.length) {
            setErrors(validationErrorsToSchemaFormErrors(validate.errors));
            return;
          }
          setBearer(null);
          setErrors({});
          axios
            .post("/auth/login", loginRequest)
            .then(async (res) => {
              const { bearer } = res.data;
              const jwt = parseJwt(bearer);
              const customerLinkId = localStorage.getItem(
                `customerLinkId_${jwt.userId}`,
              );
              if (customerLinkId) {
                try {
                  const switchedCustomerRes = await axios.request({
                    method: "post",
                    url: "/auth/refreshToken",
                    headers: {
                      Authorization: `Bearer ${bearer}`,
                    },
                    params: { customerLinkId },
                  });
                  setBearer(switchedCustomerRes.data.bearer);
                  return;
                } catch (e) {
                  console.log(e);
                  history.push("/logout");
                }
              }
              await setBearer(res.data.bearer);
            })
            .catch((err) => {
              console.log(err);
              setBearer(undefined);
              setErrors({
                api: t("loginForm_usernameAndPasswordCombinationInvalid"),
              });
            });
        }}
      >
        <SchemaFormBody<TLoginRequest>
          errors={formErrors}
          schema={{
            ...loginRequestSchema,
            properties: {
              ...loginRequestSchema.properties,
              userName: {
                ...(loginRequestSchema.properties as any).userName,
                "x-props": {
                  autoComplete: "userName",
                },
              },
              password: {
                ...(loginRequestSchema.properties as any).password,
                "x-props": {
                  autoComplete: "current-password",
                },
              },
            },
          }}
          value={loginRequest}
          onChange={setLoginRequest}
          disabled={isInProgress}
        />
        {formErrors.api ? (
          <Message
            showIcon
            type="error"
            title={t("loginFailed")}
            description={formErrors.api}
          />
        ) : null}
        <FormGroup style={{ marginTop: 40 }}>
          <ButtonToolbar>
            <Button type="submit" appearance="primary" disabled={isInProgress}>
              {t("Login")}
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </>
  );
};

export default LoginForm;
