import { Dropdown, Icon, IconButton } from "rsuite";
import React from "react";
import { I18nContext } from "../../../provider/I18nProvider";
import { components } from "../../../types/openapi";
import Flag from "../../../icons/Flag";

interface IUnreadControlProps {
  controlType: "menuItem" | "button";
  disabled: boolean;
  filter: components["schemas"]["Filter"];
  expandAndChange: (filter: components["schemas"]["Filter"]) => void;
}

const UnreadControl = ({
  controlType,
  disabled,
  expandAndChange,
  filter,
}: IUnreadControlProps) => {
  const { t } = React.useContext(I18nContext);

  const onClick = React.useCallback(() => {
    const { isReadMediaItem, ...newFilter } = filter;
    expandAndChange(
      isReadMediaItem === undefined
        ? { ...newFilter, isReadMediaItem: false }
        : newFilter
    );
  }, [expandAndChange, filter]);

  return controlType === "button" ? (
    <IconButton
      ripple={false}
      disabled={disabled}
      className={`filter-bar__filters-col__button`}
      size="xs"
      appearance="subtle"
      onClick={onClick}
      icon={<Icon icon="question" componentClass={Flag} />}
    >
      {t("unread")}
    </IconButton>
  ) : (
    <Dropdown.Item
      onSelect={onClick}
      className="components__filter-bar__unread-control"
    >
      {t("unread")}
    </Dropdown.Item>
  );
};

export default UnreadControl;
