import { LRUCache } from "lru-cache";
import axios, { AxiosRequestConfig } from "axios";
import React from "react";
import { components } from "../types/openapi";

const cache = new LRUCache({ max: 500 });

export function useSource() {
  const getSourceAxiosCall = React.useCallback(
    (
      config: AxiosRequestConfig,
    ): Promise<components["schemas"]["Source"] | null> => {
      return axios
        .get<components["schemas"]["Source"][]>("/source/crud", config)
        .then((res) => {
          // Find the shortest match with a name
          const sortedSources = res.data.sort((a, b) => {
            if (!a.name) {
              return 1;
            }
            if (!b.name) {
              return -1;
            }
            return a.name.length < b.name.length ? -1 : 1;
          });
          if (sortedSources.length) {
            cache.set(sortedSources[0].sourceId, sortedSources[0]);
            return sortedSources[0];
          }
          return null;
        });
    },
    [],
  );

  const getSource = React.useCallback(
    async (
      sourceId?: string,
      sourceName?: string,
    ): Promise<components["schemas"]["Source"] | null> => {
      // if Nether sourceName nor sourceId are given, end function
      if (!sourceName && !sourceId) {
        return null;
      }

      if (sourceId) {
        // Search in cache
        if (cache.has(sourceId)) {
          return cache.get(sourceId) as components["schemas"]["Source"];
        }
        // Get source via an axios call
        return await getSourceAxiosCall({ params: { sourceIds: sourceId } });
      }

      // if only sourceName is given
      let source;
      // Check cache values if source is already cached
      cache.forEach((value: any) => {
        if ((value as components["schemas"]["Source"]).name === sourceName) {
          source = value;
        }
      });
      // Return cached source, or source via an axios call
      return (
        source || (await getSourceAxiosCall({ params: { query: sourceName } }))
      );
    },
    [getSourceAxiosCall],
  );

  return { getSource };
}
