import React from "react";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { Loader } from "rsuite";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../provider/AuthProvider";
import { APP_PATH } from "../../inc/constants";

const LogoutView = () => {
  const { impersonate } = React.useContext(ApiDataContext);
  const { auth, setBearer } = React.useContext(AuthContext);

  const history = useHistory();

  React.useEffect(() => {
    if (auth?.jwt.superUserId) {
      impersonate(auth?.jwt.superUserId);
      // don't stay on the logout page while impersonating...
      // The effect will be triggered again and you will be logged out completely
      history.push(APP_PATH);
      return;
    }
    localStorage.clear();
    setBearer(undefined);
    window.location.href = "/";
  }, [auth?.jwt.superUserId, history, impersonate, setBearer]);

  return <Loader />;
};

export default LogoutView;
