import { Badge, Button, Icon } from "rsuite";
import React from "react";
import "./index.scss";

interface IToggleButtonProps {
  amountOfActiveFilters: number;
  isTagsPanelExpanded: boolean;
  setIsTagsPanelExpanded: (isTagsPanelExpanded: boolean) => void;
}

const ToggleButton = ({
  amountOfActiveFilters,
  setIsTagsPanelExpanded,
  isTagsPanelExpanded,
}: IToggleButtonProps) => (
  <Button
    size="xs"
    className={`views-dashboard-view__filter-bar__toolbar__button bg-white ${
      isTagsPanelExpanded
        ? "views-dashboard-view__filter-bar__toolbar__button--expanded"
        : ""
    }`}
    onClick={() => setIsTagsPanelExpanded(!isTagsPanelExpanded)}
    ripple={false}
  >
    <Icon icon="arrow-down2" />
    {!isTagsPanelExpanded && amountOfActiveFilters ? (
      <Badge
        className="views-dashboard-view__filter-bar__toolbar__badge"
        content={amountOfActiveFilters}
      />
    ) : null}
  </Button>
);

export default ToggleButton;
