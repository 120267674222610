import React from "react";

import { components } from "../../types/openapi";
import SchemaTable from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import GroupModal from "./GroupModal";
import ActionButton from "../../components/ActionButton";
import { I18nContext } from "../../provider/I18nProvider";
import useGroups from "../../hooks/useGroups";

const schema = openapi.components.schemas.Group as oas30.SchemaObject;
type TGroup = components["schemas"]["Group"];

const GroupsView = () => {
  const { t } = React.useContext(I18nContext);
  const [selectedGroupId, setSelectedGroupId] = React.useState<
    string | undefined
  >();
  const groups = useGroups();

  const onRowClick = React.useCallback((rowData: TGroup | null) => {
    if (rowData) {
      setSelectedGroupId(rowData.groupId);
    }
  }, []);
  const onClose = React.useCallback(() => {
    setSelectedGroupId(undefined);
  }, []);
  return (
    <>
      <GroupModal
        show={!!selectedGroupId}
        onClose={onClose}
        groupId={selectedGroupId}
      />
      <SchemaTable<TGroup>
        data={groups ? Object.values(groups) : undefined}
        schema={schema}
        onRowClick={onRowClick}
        columnsConfigs={[
          {
            name: "groupId",
            hidden: true,
          },
        ]}
        globalSearch
        sortable
      >
        <ActionButton
          onClick={() => setSelectedGroupId("NEW")}
          title={t("newGroup")}
        />
      </SchemaTable>
    </>
  );
};

export default GroupsView;
