import React, { Dispatch, SetStateAction } from "react";
import { debounce } from "lodash";
import { BootstrapSize } from "../../inc/constants";
import { useLocation } from "react-router-dom";
import { emptyFn } from "../../inc/data";
import ReactGA from "react-ga";

type TTourId =
  | "dashboard"
  | "myMedia"
  | "editorial"
  | "editEditorial"
  | "searchEngine";

export enum ENarrowCastingMode {
  NARROW_CASTING_OFF = "NARROW_CASTING_OFF",
  NARROW_CASTING_ON = "NARROW_CASTING_ON",
  NARROW_CASTING_MOUSE_IDLE = "NARROW_CASTING_MOUSE_IDLE",
}

interface ILayoutContext {
  contentWidth: number;
  isHelpBarOpen: boolean;
  isSidebarOpen: boolean;
  isTemplateDialogOpen: boolean;
  narrowCastingMode: ENarrowCastingMode;
  openTourId?: TTourId;
  selectedMediaItemIds: true | string[];
  setContentContainerDiv: (contentContainerDiv: HTMLDivElement) => void;
  setSelectedMediaItemIds: (selectedMediaItemIds: true | string[]) => void;
  setIsHelpBarOpen: Dispatch<SetStateAction<boolean>>;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setIsTemplateDialogOpen: (isTemplateDialogOpen: boolean) => void;
  setOpenTourId: (tourId?: TTourId) => void;
  startNarrowCasting: () => void;
  startTour?: () => void;
  tawkLoaded: boolean;
  windowOuterWidth: number;
  windowInnerHeight: number;
}

export function getBootstrapSize(width: number): BootstrapSize {
  if (width >= BootstrapSize.XL) {
    return BootstrapSize.XL;
  }
  if (width >= BootstrapSize.LG) {
    return BootstrapSize.LG;
  }
  if (width >= BootstrapSize.MD) {
    return BootstrapSize.MD;
  }
  if (width >= BootstrapSize.SM) {
    return BootstrapSize.SM;
  }
  return BootstrapSize.XS;
}

export const LayoutContext = React.createContext<ILayoutContext>({
  contentWidth: 1024,
  isHelpBarOpen: false,
  isSidebarOpen: false,
  isTemplateDialogOpen: false,
  narrowCastingMode: ENarrowCastingMode.NARROW_CASTING_OFF,
  selectedMediaItemIds: [],
  setContentContainerDiv: emptyFn,
  setSelectedMediaItemIds: emptyFn,
  setIsHelpBarOpen: emptyFn,
  setIsSidebarOpen: emptyFn,
  setIsTemplateDialogOpen: emptyFn,
  setOpenTourId: emptyFn,
  startNarrowCasting: emptyFn,
  tawkLoaded: false,
  // some stuff, e.g. Dashboard widgets stretch <body> making innerWidth unusable...,
  windowOuterWidth: window.outerWidth,
  windowInnerHeight: window.innerHeight,
});

const LayoutProvider = ({ children }: any) => {
  const [{ windowOuterWidth, windowInnerHeight }, setWndowDimensions] =
    React.useState<{
      windowOuterWidth: number;
      windowInnerHeight: number;
    }>({
      windowOuterWidth: window.outerWidth,
      windowInnerHeight: window.innerHeight,
    });
  const [isHelpBarOpen, setIsHelpBarOpen] = React.useState(false);
  const [narrowCastingMode, setNarrowCastingMode] =
    React.useState<ENarrowCastingMode>(ENarrowCastingMode.NARROW_CASTING_OFF);
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = React.useState(false);
  const [selectedMediaItemIds, setSelectedMediaItemIds] = React.useState<
    true | string[]
  >([]);
  const [openTourId, setOpenTourId] = React.useState<TTourId>();
  const [tawkLoaded, setTawkLoaded] = React.useState(false);
  // https://www.notion.so/c700a5b224564b5395ec6c30144d735a?v=886ff64714724bb2a742a4953b2fdf44&p=7fd7063c00fe4197a361242fbeb7a40e
  // CTRL + klikken op mijn media. Opent mijn media op volledige pagina zonder navigatiebalk
  //
  // Opening in a new, invisible tab breaks rendering due to outerWidth being 0. Set sidebar open in that case!
  //
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(
    !windowOuterWidth || getBootstrapSize(windowOuterWidth) > BootstrapSize.LG
  );
  const [contentContainerDiv, setContentContainerDiv] =
    React.useState<HTMLDivElement>();
  const [contentWidth, setContentWidth] = React.useState<number>(0);

  const location = useLocation();
  const startTour = React.useMemo(() => {
    const getTourStarter = (tourId: TTourId) => () => {
      ReactGA.event({
        category: "Reddingsboei",
        action: `Tour open ${tourId}`,
      });
      setOpenTourId(tourId);
    };

    switch (location.pathname) {
      case "/app/search":
        return getTourStarter("searchEngine");

      case "/app/editorials/overview":
        return getTourStarter("editorial");

      case "/app/myMedia":
        return getTourStarter("myMedia");

      default:
        if (location.pathname.startsWith("/app/dashboards")) {
          return getTourStarter("dashboard");
        }
        if (location.pathname.startsWith("/app/editorials/overview/")) {
          return getTourStarter("editEditorial");
        }
    }
    return undefined;
  }, [location.pathname]);

  React.useEffect(() => {
    // Stuff like a closing sidebar is animated... We need to "check twice" if the contentwidth is correct
    const timeouts = [200, 500].map((ms) =>
      setTimeout(() => {
        if (contentContainerDiv) {
          setContentWidth(contentContainerDiv.clientWidth);
        }
      }, ms)
    );
    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [
    contentContainerDiv,
    windowOuterWidth,
    windowInnerHeight,
    isSidebarOpen,
    narrowCastingMode,
  ]);

  React.useEffect(() => {
    if (narrowCastingMode === ENarrowCastingMode.NARROW_CASTING_OFF) {
      return;
    }
    let moveTime = new Date();
    const checkInterval = setInterval(() => {
      if (narrowCastingMode === ENarrowCastingMode.NARROW_CASTING_MOUSE_IDLE) {
        return;
      }
      const diff = new Date().getTime() - moveTime.getTime();
      if (diff > 2_000) {
        setNarrowCastingMode(ENarrowCastingMode.NARROW_CASTING_MOUSE_IDLE);
      }
    }, 500);
    const mouseMoveHandler = debounce(() => {
      if (narrowCastingMode === ENarrowCastingMode.NARROW_CASTING_MOUSE_IDLE) {
        setNarrowCastingMode(ENarrowCastingMode.NARROW_CASTING_ON);
      }
      moveTime = new Date();
    });
    window.addEventListener("mousemove", mouseMoveHandler);
    return () => {
      clearInterval(checkInterval);
      window.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, [narrowCastingMode]);

  React.useEffect(() => {
    const updateWindowDimensions = debounce(() => {
      setWndowDimensions({
        windowOuterWidth: window.outerWidth,
        windowInnerHeight: window.innerHeight,
      });
      if (
        !document.fullscreenElement &&
        narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF
      ) {
        setNarrowCastingMode(ENarrowCastingMode.NARROW_CASTING_OFF);
      }
    }, 100);
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener("focus", updateWindowDimensions);
    window.addEventListener("fullscreenchange", updateWindowDimensions);

    const { Tawk_API } = window as any;
    if (Tawk_API) {
      Tawk_API.onLoad = () => {
        Tawk_API.hideWidget();
        setTawkLoaded(true);
      };
    }
    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
      window.removeEventListener("focus", updateWindowDimensions);
      window.removeEventListener("fullscreenchange", updateWindowDimensions);
    };
  }, [narrowCastingMode]);

  const startNarrowCasting = React.useCallback(() => {
    if (narrowCastingMode !== ENarrowCastingMode.NARROW_CASTING_OFF) {
      setNarrowCastingMode(ENarrowCastingMode.NARROW_CASTING_OFF);
      document.exitFullscreen().catch(console.log);
      return;
    }

    if (!contentContainerDiv || !contentContainerDiv.parentNode) {
      return;
    }
    ReactGA.event({
      category: "Dashboards",
      action: "Start narrowcasting",
    });
    setNarrowCastingMode(ENarrowCastingMode.NARROW_CASTING_ON);
    // @ts-ignore
    contentContainerDiv.parentNode.requestFullscreen();
  }, [contentContainerDiv, narrowCastingMode]);

  return (
    <LayoutContext.Provider
      value={{
        contentWidth,
        isHelpBarOpen,
        isSidebarOpen,
        isTemplateDialogOpen,
        narrowCastingMode,
        openTourId,
        setOpenTourId,
        selectedMediaItemIds,
        setContentContainerDiv,
        setIsHelpBarOpen,
        setIsSidebarOpen,
        setIsTemplateDialogOpen,
        setSelectedMediaItemIds,
        startNarrowCasting,
        startTour,
        tawkLoaded,
        windowOuterWidth,
        windowInnerHeight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
