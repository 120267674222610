import React, { RefObject } from "react";
import { components } from "../../../types/openapi";
import { Divider, Dropdown, Icon, IconButton, Popover } from "rsuite";
import { I18nContext } from "../../../provider/I18nProvider";
import Plus from "../../../icons/Plus";
import MwWhisper from "../../MwWhisper";
import { TypeAttributes } from "rsuite/lib/@types/common";
import useEditorialRubrics from "../../../hooks/useEditorialRubrics";
import { elementHasClassName } from "../../../inc/dom";

interface IMoreOptionsPopoverProps {
  authorsWhisperRef: React.RefObject<any>;
  categoryWhisperRef: React.RefObject<any>;
  distributionProvincesWhisperRef: React.RefObject<any>;
  filter: components["schemas"]["Filter"];
  isDirty?: boolean;
  items?: React.ReactElement[];
  onChange: (filter: components["schemas"]["Filter"]) => void;
  placement?: TypeAttributes.Placement | TypeAttributes.Placement4;
  showButtonText: boolean;
  sourcesWhisperRef: React.RefObject<any>;
  editorialRubricsWhisperRef: React.RefObject<any>;
}

const MoreOptionsPopover = ({
  authorsWhisperRef,
  categoryWhisperRef,
  distributionProvincesWhisperRef,
  filter,
  isDirty,
  items,
  onChange,
  placement,
  showButtonText,
  sourcesWhisperRef,
  editorialRubricsWhisperRef,
}: IMoreOptionsPopoverProps) => {
  const editorialRubrics = useEditorialRubrics();
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = React.useContext(I18nContext);
  const triggerRef: RefObject<any> = React.useRef();

  React.useEffect(() => {
    if (!isDirty) {
      setIsOpen(false);
    }
  }, [isDirty]);

  React.useEffect(() => {
    if (!isOpen) {
      return;
    }
    const handleClickOutsideModal = (event: any) => {
      // detect a click outside any (nested-)popover up.
      let doClose = true;
      let parentNode = event.target.parentNode;
      while (parentNode && doClose) {
        if (elementHasClassName(parentNode, "label-popover__apply")) {
          // always close when submitting label control!
          break;
        }
        doClose = !elementHasClassName(parentNode, "rs-popover-content");
        parentNode = parentNode.parentNode;
      }
      if (doClose) {
        setTimeout(() => {
          setIsOpen(false);
        }, 100);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideModal);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
  }, [isOpen]);

  const hasEditorials: boolean = !!(
    editorialRubrics && Object.keys(editorialRubrics).length
  );
  const { isFavouriteMediaItem, isInEditorial } = filter;
  return (
    <MwWhisper
      placement={placement}
      triggerRef={triggerRef}
      trigger="none"
      open={isOpen}
      speaker={
        <Popover className="popover-without-arrow">
          <Dropdown.Menu>
            {items}
            <Dropdown.Item
              onSelect={() => {
                onChange({
                  ...filter,
                  categoryIds: filter.categoryIds || [],
                });
                setTimeout(() => {
                  if (categoryWhisperRef && categoryWhisperRef.current) {
                    categoryWhisperRef.current.open();
                  }
                }, 10);
                setIsOpen(false);
              }}
            >
              {t("category")}
            </Dropdown.Item>
            <Divider style={{ margin: 0 }} />
            <Dropdown.Item
              onSelect={() => {
                onChange({
                  ...filter,
                  sourceIds: filter.sourceIds || [],
                });
                setTimeout(() => {
                  if (sourcesWhisperRef && sourcesWhisperRef.current) {
                    sourcesWhisperRef.current.open();
                  }
                }, 10);
                setIsOpen(false);
              }}
            >
              {t("sources")}
            </Dropdown.Item>
            <Divider style={{ margin: 0 }} />
            <Dropdown.Item
              onSelect={() => {
                onChange({
                  ...filter,
                  distributionProvinces: filter.distributionProvinces || [],
                });
                setTimeout(() => {
                  if (
                    distributionProvincesWhisperRef &&
                    distributionProvincesWhisperRef.current
                  ) {
                    distributionProvincesWhisperRef.current.open();
                  }
                }, 10);
                setIsOpen(false);
              }}
            >
              {t("distributionProvinces")}
            </Dropdown.Item>
            <Divider style={{ margin: 0 }} />
            <Dropdown.Item
              onSelect={() => {
                onChange({
                  ...filter,
                  authorIds: filter.authorIds || [],
                });
                setTimeout(() => {
                  if (authorsWhisperRef && authorsWhisperRef.current) {
                    authorsWhisperRef.current.open();
                  }
                }, 10);
                setIsOpen(false);
              }}
            >
              {t("authors")}
            </Dropdown.Item>
            <Divider style={{ margin: 0 }} />
            <Dropdown.Item
              icon={isFavouriteMediaItem ? <Icon icon="check" /> : undefined}
              onSelect={() => {
                onChange({
                  ...filter,
                  isFavouriteMediaItem: !isFavouriteMediaItem || undefined,
                });
                setIsOpen(false);
              }}
            >
              {t("favorite")}
            </Dropdown.Item>
            <Divider style={{ margin: 0 }} />
            <Dropdown.Item
              icon={isInEditorial ? <Icon icon="check" /> : undefined}
              disabled={!hasEditorials}
              onSelect={() => {
                onChange({
                  ...filter,
                  isInEditorial: !isInEditorial || undefined,
                });
                setIsOpen(false);
              }}
            >
              {t("addedToEditorial")}
            </Dropdown.Item>
            <Divider style={{ margin: 0 }} />
            <Dropdown.Item
              disabled={!hasEditorials}
              onSelect={() => {
                onChange({
                  ...filter,
                  editorialRubricIds: filter.editorialRubricIds || [],
                });
                setTimeout(() => {
                  if (
                    editorialRubricsWhisperRef &&
                    editorialRubricsWhisperRef.current
                  ) {
                    editorialRubricsWhisperRef.current.open();
                  }
                }, 10);
                setIsOpen(false);
              }}
            >
              {t("addedToEditorialRubric")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      }
    >
      <IconButton
        className="filter-bar__filters-col__button"
        ripple={false}
        size="xs"
        icon={<Icon icon="question" componentClass={Plus} />}
        appearance="subtle"
        disabled={!onChange}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        {showButtonText ? t("moreOptions") : null}
      </IconButton>
    </MwWhisper>
  );
};
export default MoreOptionsPopover;
