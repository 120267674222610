import React from "react";
import Tour, { ReactourStep } from "reactour";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { I18nContext } from "../../../provider/I18nProvider";

const EditEditorialTour = () => {
  const { openTourId, setOpenTourId } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const tourRef = React.useRef(null);
  const isEditEditorialTourOpen = openTourId === "editEditorial";
  React.useEffect(() => {
    if (isEditEditorialTourOpen && tourRef.current) {
      disableBodyScroll(tourRef.current as any);
      return;
    }
    clearAllBodyScrollLocks();
  }, [isEditEditorialTourOpen]);

  const steps: ReactourStep[] = React.useMemo(
    () => [
      {
        content: () => (
          <>
            <h3>{t("introduction")}</h3>
            <p>{t("editorialTour_1_1")}</p>
            <p>
              <strong>{t("achtung")}</strong> {t("editorialTour_1_2")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".rs-form-group:nth-child(1)",
        content: () => (
          <>
            <h3>{t("editorialTour_2_1")}</h3>
            <p>{t("editorialTour_2_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".rs-form-group:nth-child(2)",
        content: () => (
          <>
            <h3>{t("editorialTour_3_1")}</h3>
            <p>{t("editorialTour_3_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".rs-form-group:nth-child(3)",
        content: () => (
          <>
            <h3>{t("editorialTour_4_1")}</h3>
            <p>{t("editorialTour_4_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".editorial-rubrics",
        content: () => (
          <>
            <h3>{t("editorialTour_5_1")}</h3>
            <p>{t("editorialTour_5_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".rs-btn-green",
        content: () => (
          <>
            <h3>{t("editorialTour_6_1")}</h3>
            <p>{t("editorialTour_6_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".rs-checkbox",
        content: () => (
          <>
            <h3>{t("editorialTour_7_1")}</h3>
            <p>{t("editorialTour_7_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".layout__app-layout__nav-items__help",
        content: () => (
          <>
            <h3>{t("editorialTour_8_1")}</h3>
            <p>{t("editorialTour_8_2")}</p>
          </>
        ),
        stepInteraction: false,
      },
    ],
    [t],
  );
  return (
    <Tour
      steps={steps}
      isOpen={isEditEditorialTourOpen}
      onRequestClose={() => {
        setOpenTourId(undefined);
      }}
      ref={tourRef}
    />
  );
};

export default EditEditorialTour;
