import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { cleanQuery, IHashMap } from "../inc/data";
import { components } from "../types/openapi";
import { Alert } from "rsuite";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

export const useSearchEngineQueries = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { searchEngineQueries, setSearchEngineQueries } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      searchEngineQueries !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    const { currentCustomerLinkId, customerRole } = auth.jwt;
    const isInholland = customerRole === "inholland";

    if (currentCustomerLinkId && !isInholland) {
      setSearchEngineQueries(null);
      axios
        .get<components["schemas"]["SearchEngineQuery"][]>(
          "/searchEngineQuery/crud"
        )
        .then((res) => {
          setSearchEngineQueries(
            res.data.reduce<
              IHashMap<components["schemas"]["SearchEngineQuery"]>
            >((prev, searchEngineQuery) => {
              prev[searchEngineQuery.searchEngineQueryId as string] = {
                ...searchEngineQuery,
                query: cleanQuery(searchEngineQuery.query),
              };
              return prev;
            }, {})
          );
        })
        .catch((err) => {
          console.log(err);
          Alert.error(t("apiDataProvider_fetchingFailed_searchEngineQuery"));
        });
    }
  }, [auth?.bearer, setSearchEngineQueries, searchEngineQueries, t, auth?.jwt]);

  return searchEngineQueries;
});
