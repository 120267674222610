import React from "react";
import {
  ACTIVE_LANGUAGE_LOCAL_STORAGE_KEY,
  ELocaleOptions,
  TLocaleRsuiteData,
} from "../../inc/i18n";
import { unparse } from "papaparse";
import { locale as localeNL } from "../../inc/i18n/nl";
import { locale as localeEN } from "../../inc/i18n/en";
import { emptyFn } from "../../inc/data";

interface II18nContext {
  activeLanguage: ELocaleOptions;
  getCsv: () => string;
  rsuiteLocale: TLocaleRsuiteData;
  switchActiveLanguage: (newActiveLanguage: ELocaleOptions) => void;
  t: (key: string, ...args: any[]) => string;
}

export const I18nContext = React.createContext<II18nContext>({
  activeLanguage: ELocaleOptions.NL,
  getCsv: () => "",
  rsuiteLocale: { DatePicker: {} },
  switchActiveLanguage: emptyFn,
  t: (key: string) => {
    console.warn(`Cannot translate ${key}: Context not initialized`);
    const emergencyDb = {
      publicationDate: "Publicatiedatum",
      insertDate: "Datum toegevoegd",
    };
    return emergencyDb[key as "publicationDate"] || key;
  },
});

const I18nProvider = ({ children }: any) => {
  const activeLanguageLocalStorage = localStorage.getItem(
    ACTIVE_LANGUAGE_LOCAL_STORAGE_KEY
  );
  const [activeLanguage, setActiveLanguage] = React.useState(
    activeLanguageLocalStorage
      ? (activeLanguageLocalStorage as ELocaleOptions)
      : ELocaleOptions.NL
  );

  const activeLocale = React.useMemo(() => {
    switch (activeLanguage) {
      case ELocaleOptions.EN:
        return localeEN;
      case ELocaleOptions.NL:
      default:
        return localeNL;
    }
  }, [activeLanguage]);

  const t = React.useCallback(
    (key: string, ...args: any[]) => {
      let string = activeLocale.db[key] || key;
      args.forEach((arg, index) => {
        string = string.replace(`{${index}}`, arg);
      });
      return string;
    },
    [activeLocale.db]
  );
  const getCsv = () => unparse(Object.entries(activeLocale.db));

  const switchActiveLanguage = React.useCallback(
    (newActiveLanguage: ELocaleOptions) => {
      localStorage.setItem(
        ACTIVE_LANGUAGE_LOCAL_STORAGE_KEY,
        newActiveLanguage
      );
      setActiveLanguage(newActiveLanguage);
    },
    []
  );

  return (
    <I18nContext.Provider
      value={{
        activeLanguage,
        getCsv,
        rsuiteLocale: activeLocale.rsuite,
        switchActiveLanguage,
        t,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;
