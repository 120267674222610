import React, { SVGProps } from "react";

const FlagFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.219}
    height={14.692}
    viewBox="0 0 11.219 14.692"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Gelezen_icon_fill">
      <path
        id="prefix__Path_1637"
        d="M1648.1 568.49a.563.563 0 01-.563-.563v-12.832a.563.563 0 111.126 0v12.832a.563.563 0 01-.563.563z"
        transform="translate(-1638.31 -553.798)"
      />
      <path
        id="prefix__Path_1638"
        d="M1633.085 557.461a.561.561 0 00-.528-.052l-7.364 3.034a.563.563 0 00-.019 1.032l7.364 3.36a.556.556 0 00.233.051.562.562 0 00.563-.562v-6.395a.563.563 0 00-.249-.468z"
        transform="translate(-1624.845 -555.479)"
      />
      <rect
        id="prefix__Rectangle_1570"
        width={2.815}
        height={1.363}
        rx={0.436}
        transform="rotate(-180 5.609 .681)"
      />
    </g>
  </svg>
);
export default FlagFilled;
