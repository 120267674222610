import React, { ReactNode } from "react";
import { FlexboxGrid, Col } from "rsuite";
import LanguageToggle from "../../components/LanguageToggle";

import "./index.scss";

interface IShareBenchmarkLayoutProps {
  children: ReactNode;
  img?: string;
  img2x?: string;
  img3x?: string;
}

const ShareBenchmarkLayout = (props: IShareBenchmarkLayoutProps) => {
  return (
    <>
      <FlexboxGrid
        className="shareBenchmarkLayout"
        align="middle"
        justify="center"
      >
        <FlexboxGrid.Item
          componentClass={Col}
          colspan={22}
          className="text-end"
        >
          <LanguageToggle />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} colspan={8} xsHidden smHidden>
          <img
            className="shareBenchmarkLayout__image"
            alt="media·web"
            // src={props.img || "/img/art/key/key.png"}
            srcSet={`${props.img2x || "/img/art/key/key@2x.png"} 2x, ${
              props.img3x || "/img/art/key/key@3x.png"
            } 3x`}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item
          componentClass={Col}
          xs={24}
          sm={18}
          md={8}
          className="layout__share-benchmark-layout__content p-3"
        >
          {props.children}
        </FlexboxGrid.Item>
        <div className="w-100 align-self-baseline text-center">
          <img src="/img/migLogo.svg" alt="media·web" />
        </div>
      </FlexboxGrid>
    </>
  );
};

export default ShareBenchmarkLayout;
