import React from "react";
import { ESplitType, EValueType, IDashboardWidgetProps } from "../index";
import { Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { components } from "../../../types/openapi";
import axios from "../../../inc/axios";
import { getDefaultOptions } from "../../../inc/widgets/chart";
import { I18nContext } from "../../../provider/I18nProvider";
import {
  chartSortedSentiments,
  sentimentOptions,
} from "../../../inc/sentimentOptions";
import PopoverForm from "../inc/PopoverForm";
import { EChartType } from "../../../inc/enums";
import { lowerFirst } from "lodash";
import {
  getSeriesMaxValue,
  getSortedAnalysisSplitItemKeys,
  useSeries,
} from "../inc/default";
import AnalysisSplitChartBody from "../inc/AnalysisSplitChartBody";

import "./index.scss";

export interface IAuthorWidgetSettings {
  chartType?: EChartType.donut | EChartType.column;
  splitType?: ESplitType.none | ESplitType.sentiment;
  valueType?: EValueType;
  limit: number;
  showRemaining: boolean;
}

const AuthorWidget = ({
  filterCacheResponse,
  height,
  onDelete,
  onFilterChange,
  onSettingsChange,
  onSettingsToggle,
  period,
  settings,
  width,
  colWidth,
  uid,
}: IDashboardWidgetProps<IAuthorWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);
  const [analysisSplit, setAnalysisSplit] = React.useState<
    components["schemas"]["AnalysisSplit"] | null
  >();
  const {
    chartType = EChartType.donut,
    valueType = EValueType.count,
    limit = 10,
    showRemaining = false,
  } = settings || {};
  let { splitType = ESplitType.none } = settings || {};
  if (chartType === EChartType.donut) {
    splitType = ESplitType.none;
  }
  const dateType = period?.dateType || "insertDate";
  const type = chartType === "column" ? "bar" : chartType;
  const hasRemaining = !!(
    limit &&
    analysisSplit &&
    showRemaining &&
    analysisSplit.items.length > limit
  );
  const token = filterCacheResponse?.token;

  const chartWidth = Math.floor(0.96 * width);
  // make space for bottom legend if needed
  const chartHeight = height - (splitType === ESplitType.none ? 58 : 80);

  React.useEffect(() => {
    setAnalysisSplit(null);
    axios
      .get<components["schemas"]["AnalysisSplit"]>(
        `/analyse/cache/${token}/${lowerFirst(
          valueType.replace("summed", ""),
        )}PerSentiment/author`,
      )
      .then((res) => setAnalysisSplit(res.data));
  }, [token, dateType, valueType]);

  const series = useSeries(
    analysisSplit,
    chartType,
    splitType,
    valueType,
    "",
    limit,
    hasRemaining,
  ) as ApexAxisChartSeries;
  const sortedAnalysisSplitItemKeys = React.useMemo(
    () => (analysisSplit ? getSortedAnalysisSplitItemKeys(analysisSplit) : []),
    [analysisSplit],
  );
  const chartOptions = React.useMemo(() => {
    if (!analysisSplit) {
      return {};
    }
    const yAxisLabels = sortedAnalysisSplitItemKeys
      .slice(0, limit)
      .map((key) => analysisSplit.perGroup[key]);
    if (hasRemaining) {
      yAxisLabels.push(t("remainingAuthors"));
    }
    const options = getDefaultOptions(
      chartType,
      valueType,
      splitType,
      chartWidth,
      chartHeight,
      getSeriesMaxValue(series),
      colWidth === 1,
      yAxisLabels,
    );
    const colors =
      splitType === ESplitType.sentiment
        ? chartSortedSentiments.map(
            (sentiment) => sentimentOptions[sentiment].color || "#ccc",
          )
        : options.colors;
    return {
      ...options,
      chart: {
        ...options.chart,
        events: {
          dataPointSelection: (
            event: React.MouseEvent | null,
            _chartContext: any,
            config: any,
          ) => {
            if (event?.button) {
              return;
            }
            const authorName =
              analysisSplit.perGroup[
                sortedAnalysisSplitItemKeys[config.dataPointIndex]
              ];
            axios
              .request<components["schemas"]["Author"][]>({
                method: "post",
                url: "/author/crud",
                data: {
                  query: authorName,
                },
              })
              .then((res) => {
                const author = res.data.find(
                  (result) => result.name === authorName,
                );
                if (onFilterChange && author) {
                  onFilterChange({
                    authorIds: [author.authorId!],
                  });
                }
              });
          },
        },
      },
      colors,
    };
  }, [
    analysisSplit,
    chartHeight,
    chartType,
    chartWidth,
    colWidth,
    hasRemaining,
    limit,
    onFilterChange,
    series,
    sortedAnalysisSplitItemKeys,
    splitType,
    t,
    valueType,
  ]);

  const header = React.useMemo(
    () => (
      <WidgetPanelHeader
        allowImageDownload={!!onSettingsChange}
        title={t("WIDGET_TYPE_AUTHOR")}
        subtitle={`${t(`valueType_${valueType}`)}${
          splitType !== ESplitType.none
            ? ` > ${t(`splitType_${splitType}`)}`
            : ""
        }`}
        onDelete={onDelete}
        onSettingsToggle={onSettingsToggle}
        popoverForm={
          onSettingsChange && settings ? (
            <PopoverForm<IAuthorWidgetSettings>
              onSettingsChange={onSettingsChange}
              settings={settings}
              chartTypes={[EChartType.donut, EChartType.bar]}
              topResultOptions={[3, 5, 10]}
              showRemainingOption
              splitTypes={[ESplitType.none, ESplitType.sentiment]}
              widgetUid={uid}
            />
          ) : undefined
        }
      />
    ),
    [
      onDelete,
      onSettingsChange,
      onSettingsToggle,
      settings,
      splitType,
      t,
      uid,
      valueType,
    ],
  );

  return (
    <Panel
      bordered={true}
      header={header}
      className="views__dashboard-view__widgets__author-widget"
    >
      <AnalysisSplitChartBody
        analysisSplit={analysisSplit}
        splitType={splitType}
        type={type}
        chartOptions={chartOptions}
        series={series}
        width={width}
        height={height}
      />
    </Panel>
  );
};

export default AuthorWidget;
