import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { I18nContext } from "../provider/I18nProvider";
import { Alert } from "rsuite";
import { AuthContext } from "../provider/AuthProvider";

const useSearchTopics = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { searchTopics, setSearchTopics } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      searchTopics !== undefined ||
      !auth?.bearer ||
      auth?.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    setSearchTopics(null);
    axios
      .get<components["schemas"]["SearchTopic"][]>("/searchTopic/crud")
      .then((res) => {
        setSearchTopics(
          res.data.reduce<IHashMap<components["schemas"]["SearchTopic"]>>(
            (prev, searchTopic) => {
              prev[searchTopic.searchTopicId as string] = {
                ...searchTopic,
                name:
                  searchTopic.searchType === "manualAdd"
                    ? t("manualAdd")
                    : searchTopic.name,
              };
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("apiDataProvider_fetchingFailed_searchTopic"));
      });
  }, [auth?.bearer, setSearchTopics, searchTopics, t, auth?.jwt.userRoles]);

  return searchTopics;
});

export default useSearchTopics;
