import React from "react";
import { Loader, Nav, Toggle } from "rsuite";
import axios from "../../../inc/axios";
import { components } from "../../../types/openapi";
import TextTab from "./TextTab";
import PdfTab from "./PdfTab";
import CoverTab from "./CoverTab";
import PageTab from "./PageTab";
import ScreenshotTab from "./ScreenshotTab";
import { TMediaItemTabType } from "../index";
import { I18nContext } from "../../../provider/I18nProvider";
import MessageTab from "./MessageTab";
import "./index.scss";

interface IMainContentSectionProps {
  activeTab: TMediaItemTabType;
  availableTabTypes: TMediaItemTabType[];
  mediaItem?: components["schemas"]["MediaItem"];
  mediaItemView?: components["schemas"]["MediaItemView"];
  setActiveTab: (activeTab: TMediaItemTabType) => void;
}

const MainContentSection = ({
  activeTab,
  availableTabTypes,
  mediaItem,
  mediaItemView,
  setActiveTab,
}: IMainContentSectionProps) => {
  const { t } = React.useContext(I18nContext);
  const [isLexisNexisContentVisible, setisLexisNexisContentVisible] =
    React.useState(false);
  const [lexisNexisView, setLexisNexisView] =
    React.useState<components["schemas"]["LexisNexisView"]>();
  const hasLexisNexisToggle = !!lexisNexisView;

  React.useEffect(() => {
    if (!mediaItemView?.lexisNexisUrl) {
      setLexisNexisView(undefined);
      return;
    }
    // do not add bearer here: the article viewer has no auth object
    axios
      // sometimes, this may result in 404-call!
      .get<components["schemas"]["LexisNexisView"]>(mediaItemView.lexisNexisUrl)
      .then((res) => setLexisNexisView(res.data))
      .catch((e) => {
        console.log(e);
      });
  }, [mediaItemView?.lexisNexisUrl]);

  const activeTabBody = React.useMemo(() => {
    switch (activeTab) {
      case "text":
        return (
          <TextTab
            mediaItem={mediaItem}
            mediaItemView={mediaItemView}
            lexisNexisView={lexisNexisView}
            isLexisNexisContentVisible={isLexisNexisContentVisible}
          />
        );
      case "pdf":
        return (
          <PdfTab
            mediaItem={mediaItem}
            mediaItemView={mediaItemView}
            lexisNexisView={lexisNexisView}
            isLexisNexisContentVisible={isLexisNexisContentVisible}
          />
        );
      case "cover":
        return <CoverTab mediaItem={mediaItem} />;
      case "page":
        return <PageTab mediaItem={mediaItem} />;
      case "screenshot":
        return (
          <ScreenshotTab mediaItem={mediaItem} mediaItemView={mediaItemView} />
        );
      case "message":
        return (
          <MessageTab mediaItem={mediaItem} mediaItemView={mediaItemView} />
        );
    }
    return <Loader />;
  }, [
    activeTab,
    isLexisNexisContentVisible,
    lexisNexisView,
    mediaItem,
    mediaItemView,
  ]);

  return (
    <>
      <div className="d-flex flex-wrap">
        <Nav
          className="flex-grow-1"
          activeKey={activeTab}
          onSelect={(tabKey: TMediaItemTabType) => {
            if (mediaItemView && ["fragment", "website"].indexOf(tabKey) >= 0) {
              window.open(`${mediaItemView.sourceUrl}`);
              return;
            }
            setActiveTab(tabKey);
          }}
        >
          {availableTabTypes.map((tabKey) => (
            <Nav.Item
              eventKey={tabKey}
              key={tabKey}
              className={`main-content-section__tab main-content-section__tab--${tabKey}`}
            >
              {t(tabKey)}
            </Nav.Item>
          ))}
        </Nav>
        {hasLexisNexisToggle ? (
          <div className="main-content-section__lexis-nexis-toggle-container">
            <img
              src="/viewer/MigSquare.png"
              alt="Media Info Groep weergave tonen"
              title="Media Info Groep weergave tonen"
              style={{
                height: 40,
                marginRight: 10,
              }}
              onClick={() => setisLexisNexisContentVisible(false)}
            />
            <Toggle
              size="md"
              checked={isLexisNexisContentVisible}
              onChange={() =>
                setisLexisNexisContentVisible((isLexisNexis) => !isLexisNexis)
              }
              // checkedChildren="Lexis Nexis"
              // unCheckedChildren="Media Info Groep"
              // style={{ width: 150, height: 26 }}
            />
            <img
              style={{ marginLeft: 10, height: 36 }}
              src="/viewer/LexisNexisSquare.png"
              alt="Lexis-Nexis weergave tonen"
              title="Lexis-Nexis weergave tonen"
              onClick={() => setisLexisNexisContentVisible(true)}
            />
          </div>
        ) : null}
      </div>
      {activeTabBody}
    </>
  );
};
export default MainContentSection;
