import React from "react";
import { Loader } from "rsuite";
import { components } from "../../../../../types/openapi";

interface IPreviewTabProps {
  preview: components["schemas"]["EditorialPreview"] | null | undefined;
}

const PreviewTab = ({ preview }: IPreviewTabProps) =>
  preview?.html ? (
    <div
      className="mb-3"
      dangerouslySetInnerHTML={{
        __html: preview.html,
      }}
    />
  ) : (
    <Loader />
  );
export default PreviewTab;
