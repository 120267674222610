import { Column } from "exceljs";
import ReactGA from "react-ga";

export async function generateAndDownload(
  name: string,
  columns: Partial<Column>[],
  data: {}[],
) {
  // exceljs is > 1mb
  const ExcelJS = await import("exceljs");
  ReactGA.event({
    category: "Beheer",
    action: `Export ${name}`,
  });
  const wb = new ExcelJS.Workbook();
  wb.addWorksheet(name);
  const ws = wb.getWorksheet(name);
  ws.columns = columns;
  ws.getRow(1).font = {
    name: "Calibri",
    bold: true,
  };
  ws.addRows(data);
  wb.xlsx.writeBuffer().then((uint8Array) => {
    const blob = new Blob([uint8Array], {
      type: "application/octet-binary",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.xlsx`;
    a.click();
    a.remove();
  });
}
