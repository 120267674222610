import React, { RefObject } from "react";
import { Dropdown, Popover, Tooltip, Nav, Icon } from "rsuite";
import { NavIcon } from "../../index";
import { useLocation } from "react-router-dom";
import Dashboards from "../../../../../icons/Dashboards";
import MwWhisper from "../../../../../components/MwWhisper";

interface IDashboardDropRightProps {
  title: string;
  baseNavigation: string;
  onItemSelect: (itemId: string) => void;
  items: {
    id: string;
    title: string;
  }[];
}

const MAX_TITLE_LENGTH = 25;

const DashboardDropRight = ({
  title,
  baseNavigation,
  items,
  onItemSelect,
}: IDashboardDropRightProps) => {
  const location = useLocation();
  const currentItemId = location.pathname.split("/")[3];
  const triggerRef: RefObject<any> = React.useRef();
  const submenuIcon = (
    <NavIcon svg={Dashboards} to={baseNavigation} title={title} />
  );

  const submenuItems = React.useMemo(
    () =>
      items.map(({ id, title }) => {
        const showTitlePartial = title.length > MAX_TITLE_LENGTH;
        return (
          <MwWhisper
            key={title}
            delayShow={350}
            trigger={showTitlePartial ? "hover" : "none"}
            speaker={<Tooltip>{title}</Tooltip>}
          >
            <Dropdown.Item
              eventKey={title}
              active={id === currentItemId}
              className="layout__app-layout__nav-items__submenu__item"
              onSelect={() => {
                if (triggerRef.current) {
                  triggerRef.current.close();
                }
                onItemSelect(id);
              }}
            >
              &#8226;{" "}
              {showTitlePartial
                ? `${title.substr(0, MAX_TITLE_LENGTH)}...`
                : title}
            </Dropdown.Item>
          </MwWhisper>
        );
      }),
    [currentItemId, items, onItemSelect]
  );

  return (
    <MwWhisper
      placement={"rightStart"}
      triggerRef={triggerRef}
      trigger="click"
      preventOverflow
      speaker={
        <Popover full className="popover-without-arrow">
          <Dropdown.Menu className="layout__app-layout__nav-items__submenu__menu">
            {submenuItems}
          </Dropdown.Menu>
        </Popover>
      }
    >
      <Nav.Item icon={submenuIcon} hasTooltip={false}>
        <span className="layout__app-layout__nav-items__submenu__menu__arrows">
          <Icon
            className="layout__app-layout__nav-items__submenu__menu__arrow layout__app-layout__nav-items__submenu__menu__arrow--top"
            icon="angle-up"
          />
          <Icon
            className="layout__app-layout__nav-items__submenu__menu__arrow layout__app-layout__nav-items__submenu__menu__arrow--bottom"
            icon="angle-down"
          />
        </span>
      </Nav.Item>
    </MwWhisper>
  );
};
export default DashboardDropRight;
