import XRegExp from "xregexp";

export type TAdvancedMatches = {
  all: string[];
  some: string[];
  none: string[];
};

export const advancedMatchesToString = (
  advancedMatches: TAdvancedMatches
): string => {
  const queryParts = [];

  if (advancedMatches.all.length) {
    queryParts.push(
      `(${advancedMatches.all
        .map((searchTerm) => `"${searchTerm.trim()}"`)
        .join(" AND ")})`
    );
  }
  if (advancedMatches.some.length) {
    queryParts.push(
      `(${advancedMatches.some
        .map((searchTerm) => `"${searchTerm.trim()}"`)
        .join(" OR ")})`
    );
  }
  let queryString = queryParts.join(" AND ");

  if (advancedMatches.none.length) {
    queryString += ` NOT (${advancedMatches.none
      .map((searchTerm) => `"${searchTerm.trim()}"`)
      .join(" OR ")})`;
  }
  return queryString;
};

export const advancedMatchStringToHTMLString = (
  originalMatchText: string
): string => {
  if (!originalMatchText) {
    return "";
  }

  // All matches between top level hooks
  // I.e. ((foo)) => (foo)
  const allMatchesBetweenHooks = XRegExp.matchRecursive(
    originalMatchText,
    "\\(",
    "\\)",
    "g",
    {
      valueNames: [null, null, "value", null],
      unbalanced: "skip",
    }
  );
  let matchText = originalMatchText; // Copy the originalMatchText
  allMatchesBetweenHooks.forEach(({ value }: { value: string }) => {
    // Replace all operators (AND, OR, NOT) in match
    const newValue = value.replaceAll(
      /(AND|OR|NOT)/g,
      "<span class='operator'>$1</span>"
    );

    // We cannot use the XRegExp start and end values, because our string changes size
    const start = matchText.indexOf(value);
    const end = start + value.length;

    // replace part in matchText with newValue (from start till end)
    matchText = `${matchText.slice(0, start)}${newValue}${matchText.slice(
      end
    )}`;
  });

  // Replace (,),",AND,OR,NOT with HTML span elements
  const htmlMatchText = matchText
    .replaceAll(/([()])/g, "<span class='hook'>$1</span>")
    .replaceAll(/(")/g, "<span class='quote'>$1</span>")
    // Replace all the operators that aren't between hooks (i.e. all operators that aren't already replaced)
    .replaceAll(
      /(?!<span class='operator'>)(AND|OR|NOT)(?!<\/span>)/g,
      "<span class='operator operator--outside-hooks'>$1</span>"
    );
  return `<code class="colored-match-text" title="${originalMatchText.replace(
    /"/g,
    "&quot;"
  )}">${htmlMatchText}</code>`;
};
