import React from "react";
import { Button } from "rsuite";
import { getMediaItemIcon } from "../../../icons";
import { components } from "../../../types/openapi";
import { currency, formatInt } from "../../../inc/numbers";
import { DATE_FORMAT, DATE_TIME_FORMAT, localeFormat } from "../../../inc/date";
import { I18nContext } from "../../../provider/I18nProvider";
import useCategories from "../../../hooks/useCategories";
import useSearchTopics from "../../../hooks/useSearchTopics";
import LogPanel from "./LogPanel";
import "./index.scss";

interface IMetaDataSectionProps {
  mediaItem: components["schemas"]["MediaItem"];
  details?: components["schemas"]["MediaItemDetails"];
}

const MetaDataSection = ({ mediaItem, details }: IMetaDataSectionProps) => {
  const { t } = React.useContext(I18nContext);
  const [showSyndicates, setShowSyndicates] = React.useState(false);

  const categories = useCategories();
  const searchTopics = useSearchTopics();

  const publicationDateValue = localeFormat(
    new Date(mediaItem.publicationDate),
    ["radio", "tv"].includes(mediaItem.mediaType)
      ? DATE_TIME_FORMAT
      : DATE_FORMAT,
  );
  const authorValue = mediaItem.authors.join(", ") || "-";
  const insertDateValue = localeFormat(
    new Date(mediaItem.insertDate),
    DATE_FORMAT,
  );
  const aveValue = mediaItem.ave ? currency(mediaItem.ave) : "-";
  const reachValue = mediaItem?.reach ? formatInt(mediaItem.reach) : "-";
  const searchTopicTitle =
    mediaItem.searchTopicIds?.length && searchTopics
      ? mediaItem.searchTopicIds
          .filter((searchTopicId) => !!searchTopics[searchTopicId])
          .map((searchTopicId) => searchTopics[searchTopicId].name)
          .join(", ")
      : "";
  const distributionProvincesTitle = mediaItem.distributionProvinces
    ? mediaItem.distributionProvinces.map((province) => t(province)).join(", ")
    : "";

  return (
    <>
      <table className="mt-5 media-item-meta-data-section">
        <tbody>
          <tr>
            <td className="meta-list__title">
              {t("components_MediaItem_source")}
            </td>
            <td className="meta-list__description" title={mediaItem.source}>
              {mediaItem.source}
            </td>
          </tr>
          {mediaItem.issueName ? (
            <tr>
              <td className="meta-list__title">
                {t("components_MediaItem_issueName")}
              </td>
              <td
                className="meta-list__description"
                title={mediaItem.issueName}
              >
                {mediaItem.issueName}
              </td>
            </tr>
          ) : null}
          <tr>
            <td className="meta-list__title">{t("mediaType")}</td>
            <td className="meta-list__description" title={mediaItem.mediaType}>
              {t(mediaItem.mediaType)}
            </td>
          </tr>
          {mediaItem.categoryId &&
          categories &&
          !!categories[mediaItem.categoryId] ? (
            <tr>
              <td className="meta-list__title">{t("category")}</td>
              <td
                className="meta-list__description"
                title={categories[mediaItem.categoryId].name}
              >
                {categories[mediaItem.categoryId].name}
              </td>
            </tr>
          ) : null}
          <tr>
            <td className="meta-list__title">{t("date")}</td>
            <td className="meta-list__description" title={publicationDateValue}>
              {publicationDateValue}
            </td>
          </tr>
          <tr>
            <td className="meta-list__title">{t("author")}</td>
            <td className="meta-list__description" title={authorValue}>
              {authorValue}
            </td>
          </tr>
          <tr>
            <td className="meta-list__title">{t("added")}</td>
            <td className="meta-list__description" title={insertDateValue}>
              {insertDateValue}
            </td>
          </tr>
          <tr>
            <td className="meta-list__title">{t("ave")}</td>
            <td className="meta-list__description" title={aveValue}>
              {aveValue}
            </td>
          </tr>
          {mediaItem.mediaType === "print" && mediaItem?.circulation ? (
            <tr>
              <td className="meta-list__title">{t("circulation")}</td>
              <td
                className="meta-list__description"
                title={formatInt(mediaItem.circulation)}
              >
                {formatInt(mediaItem.circulation)}
              </td>
            </tr>
          ) : null}
          <tr>
            <td className="meta-list__title">{t("reach")}</td>
            <td className="meta-list__description" title={reachValue}>
              {reachValue}
            </td>
          </tr>
          {mediaItem.wordCount ? (
            <tr>
              <td className="meta-list__title">{t("wordCount")}</td>
              <td
                className="meta-list__description"
                title={formatInt(mediaItem.wordCount)}
              >
                {formatInt(mediaItem.wordCount)}
              </td>
            </tr>
          ) : null}
          <tr>
            <td className="meta-list__title">{t("doubleArticles")}</td>
            <td
              className="meta-list__description"
              title={`${details?.linkedArticles.links.length}`}
            >
              {details?.linkedArticles.links.length}
            </td>
          </tr>
          {searchTopicTitle ? (
            <tr>
              <td className="meta-list__title">{t("searchQueries")}</td>
              <td className="meta-list__description" title={searchTopicTitle}>
                {searchTopicTitle}
              </td>
            </tr>
          ) : null}
          {distributionProvincesTitle ? (
            <tr>
              <td className="meta-list__title">{t("distributionProvinces")}</td>
              <td
                className="meta-list__description"
                title={distributionProvincesTitle}
              >
                {distributionProvincesTitle}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {details?.linkedArticles.links.length ? (
        <div>
          <Button
            className="mt-1"
            block
            onClick={() => {
              setShowSyndicates((showSyndicates) => !showSyndicates);
            }}
          >
            {showSyndicates
              ? `${t("components_MediaItem_hide")} ${details?.linkedArticles
                  .links.length} ${
                  details?.linkedArticles.links.length === 1
                    ? t("components_MediaItem_doubleArticle")
                    : t("components_MediaItem_doubleArticles")
                }`
              : `${t("components_MediaItem_show")} ${details?.linkedArticles
                  .links.length} ${
                  details?.linkedArticles.links.length === 1
                    ? t("components_MediaItem_doubleArticle")
                    : t("components_MediaItem_doubleArticles")
                }`}
          </Button>
          {showSyndicates && details ? (
            <div className="meta-lists">
              {details.linkedArticles.links.map(
                (
                  link, // , key
                ) => (
                  <div className="meta-lists__item" key={link.name}>
                    <table className="meta-list">
                      <tbody>
                        <tr>
                          <td className="meta-list__title">
                            {t("components_MediaItem_LinkedArticlesName")}:
                          </td>
                          <td
                            className="meta-list__description"
                            style={{ position: "relative" }}
                          >
                            <div style={{ position: "absolute", left: -20 }}>
                              {getMediaItemIcon(mediaItem)}
                            </div>
                            <div title={link.name}>
                              <span className="ms-1">{link.name}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="meta-list__title">
                            {t("components_MediaItem_LinkedArticlesDate")}:
                          </td>
                          <td
                            className="meta-list__description"
                            title={localeFormat(
                              new Date(link.date),
                              DATE_FORMAT,
                            )}
                          >
                            {localeFormat(new Date(link.date), DATE_FORMAT)}
                          </td>
                        </tr>
                        <tr>
                          <td className="meta-list__title">
                            {t("components_MediaItem_LinkedArticlesPage")}:
                          </td>
                          <td
                            className="meta-list__description"
                            title={`${link.page}`}
                          >
                            {link.page}
                          </td>
                        </tr>
                        <tr>
                          <td className="meta-list__title">
                            {t("components_MediaItem_LinkedArticlesRegion")}:
                          </td>
                          <td
                            className="meta-list__description"
                            title={link.region}
                          >
                            {link.region}
                          </td>
                        </tr>
                        {link.circulation ? (
                          <tr>
                            <td className="meta-list__title">
                              {t(
                                "components_MediaItem_LinkedArticlesCirculation",
                              )}
                              :
                            </td>
                            <td
                              className="meta-list__description"
                              title={formatInt(link.circulation)}
                            >
                              {formatInt(link.circulation)}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td className="meta-list__title">
                            {t("components_MediaItem_LinkedArticlesFrequency")}:
                          </td>
                          <td
                            className="meta-list__description"
                            title={link.frequency}
                          >
                            {link.frequency}
                          </td>
                        </tr>
                        {link.ave ? (
                          <tr>
                            <td
                              className="meta-list__title"
                              title={t("aveMeaning")}
                            >
                              {t("components_MediaItem_LinkedArticlesAve")}:
                            </td>
                            <td
                              className="meta-list__description"
                              title={t("aveMeaning")}
                            >
                              {currency(link.ave)}
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                ),
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <LogPanel mediaItem={mediaItem} />
    </>
  );
};
export default MetaDataSection;
