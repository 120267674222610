import React, { SVGProps } from "react";

const MediaTypePrint = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="prefix__Print"
      xmlns="http://www.w3.org/2000/svg"
      width={16.002}
      height={10.641}
      viewBox="0 0 16.002 10.641"
      fill="currentColor"
      {...props}
    >
      <path
        id="prefix__Path_1968"
        data-name="Path 1968"
        d="M1523.826 1219.806a1.7 1.7 0 01-1.681-1.715.443.443 0 01.886 0 .8.8 0 101.59 0 .443.443 0 01.886 0 1.7 1.7 0 01-1.681 1.715z"
        transform="translate(-1522.145 -1209.167)"
      />
      <path
        id="prefix__Path_1969"
        data-name="Path 1969"
        d="M1522.588 1203.992h-.006a.444.444 0 01-.437-.449l.088-7.249a.443.443 0 01.443-.438h.854a.443.443 0 010 .886h-.416l-.083 6.812a.444.444 0 01-.443.438z"
        transform="translate(-1522.145 -1194.624)"
      />
      <path
        id="prefix__Path_1970"
        data-name="Path 1970"
        d="M1526.31 1202.794a.443.443 0 110-.886h12.449c.737 0 .985 0 .985-1.022v-7.846h-11.718l-.039 8.04a.443.443 0 01-.443.441.444.444 0 01-.441-.445l.041-8.481a.443.443 0 01.443-.441h12.6a.443.443 0 01.443.443v8.289c0 1.76-.924 1.908-1.871 1.908z"
        transform="translate(-1524.629 -1192.153)"
      />
      <path
        id="prefix__Path_1971"
        data-name="Path 1971"
        d="M1539.3 1207.768h-3.386a.443.443 0 01-.443-.443v-1.94a.443.443 0 01.443-.443h3.386a.443.443 0 01.443.443v1.94a.443.443 0 01-.443.443zm-2.943-.886h2.5v-1.054h-2.5z"
        transform="translate(-1531.039 -1200.687)"
      />
      <path
        id="prefix__Path_1972"
        data-name="Path 1972"
        d="M1542.728 1199.515h-6.812a.443.443 0 010-.886h6.812a.443.443 0 110 .886z"
        transform="translate(-1531.039 -1196.474)"
      />
      <path
        id="prefix__Path_1973"
        data-name="Path 1973"
        d="M1539.791 1216.837h-3.875a.443.443 0 010-.886h3.875a.443.443 0 110 .886z"
        transform="translate(-1531.039 -1208.034)"
      />
      <path
        id="prefix__Path_1974"
        data-name="Path 1974"
        d="M1555.245 1205.828h-3.729a.443.443 0 110-.886h3.729a.443.443 0 010 .886z"
        transform="translate(-1541.449 -1200.687)"
      />
      <path
        id="prefix__Path_1975"
        data-name="Path 1975"
        d="M1555.245 1211.008h-3.729a.443.443 0 110-.886h3.729a.443.443 0 010 .886z"
        transform="translate(-1541.449 -1204.144)"
      />
    </svg>
  );
};

export default MediaTypePrint;
