import React from "react";
import Tour, { ReactourStep } from   "reactour";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { I18nContext } from "../../../provider/I18nProvider";
import { AuthContext } from "../../../provider/AuthProvider";
import useMes from "../../../hooks/useMes";

const DashboardTour = () => {
  const { openTourId, setOpenTourId } = React.useContext(LayoutContext);
  const { t } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);

  const users = useMes();

  const isMyMedia = openTourId === "myMedia";
  const isDashboardTourOpen = isMyMedia || openTourId === "dashboard";
  const tourRef = React.useRef(null);

  const hasExplainMultipleCustomers: boolean = React.useMemo(() => {
    if (!isMyMedia) {
      return false;
    }
    const currentUser =
      users && auth
        ? users.find(
            (user) =>
              user.userId === auth.jwt.userId &&
              user.groupId === auth.jwt.currentGroupId
          )
        : null;
    return currentUser ? currentUser.customerLinkIds.length > 1 : false;
  }, [auth, isMyMedia, users]);

  const steps: ReactourStep[] = React.useMemo(() => {
    const result = [];
    if (isMyMedia) {
      result.push({
        selector: ".nav-item--my-media",
        content: () => (
          <>
            <h3>{t("myMediaTourHeading1")}</h3>
            <p>{t("myMediaTourBody1")}</p>
          </>
        ),
        stepInteraction: false,
      });
    }

    result.push(
      {
        selector: ".views__dashboard-view__grid-layout",
        content: () => (
          <>
            <h3>{t("Hier staan je widgets")}</h3>
            <p>{t("De widgets zijn volledig zelf in te delen")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".views__dashboard-view__widgets__mediaitem-widget",
        content: () => (
          <>
            <h3>Widgets</h3>
            <p>{t("In de widgets wordt je data getoond")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".dashboard-action-button--add-widget",
        content: () => (
          <>
            <h3>{t("Hier voeg je widgets toe")}</h3>
            <p>{t("Klik hier om widgets toe te voegen")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector:
          ".views__dashboard-view__widgets__mediaitem-widget .views__dashboard-view__widgets__settings__button",
        content: (
          <>
            <h3>{t("Aanpassen widgets")}</h3>
            <p>
              {t("Via deze knop kun je de widget aanpassen of verwijderen")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".filter-bar",
        content: () => (
          <>
            <h3>{t("Met de filter kun je de resultaten aanpassen")}</h3>
            <p>{t("Bijvoorbeeld het type of sentiment")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".tags-bar",
        content: () => (
          <>
            <h3>{t("Actieve filterbalk")}</h3>
            <p>
              {t("De actieve punten in de filter verschijnen in deze balk")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector:
          ".views__dashboard-view__existing-dashboard-view__col-dashboard-title",
        content: () => (
          <>
            <h3>
              {t(
                isMyMedia ? "myMediaTourHeading8" : "Is je dashboard naar wens"
              )}
            </h3>
            <p>
              {t(
                isMyMedia
                  ? "myMediaTourBody8"
                  : "Dan kun je de naam hier aanpassen"
              )}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".layout__app-layout__nav-items .rs-dropdown",
        content: (
          <>
            <h3>{t("Dashboards beheren")}</h3>
            <p>
              {t(
                "Hier kun je opgeslagen dashboards terugvinden of verwijderen"
              )}
            </p>
          </>
        ),
        stepInteraction: false,
      }
    );

    if (hasExplainMultipleCustomers) {
      result.push({
        selector: ".customer-menu",
        content: () => (
          <>
            <h3>{t("Wissel van account")}</h3>
            <p>{t("Wil je naar een ander account")}</p>
          </>
        ),
        stepInteraction: false,
      });
    }

    result.push({
      selector: ".layout__app-layout__nav-items__help",
      content: () => (
        <>
          <h3>{t("myMediaTourHeading11")}</h3>
          <p
            dangerouslySetInnerHTML={{
              __html: t("myMediaTourBody11"),
            }}
          />
        </>
      ),
      stepInteraction: false,
    });
    return result;
  }, [hasExplainMultipleCustomers, isMyMedia, t]);

  React.useEffect(() => {
    if (isDashboardTourOpen && tourRef.current) {
      disableBodyScroll(tourRef.current as any);
      return;
    }
    clearAllBodyScrollLocks();
  }, [isDashboardTourOpen]);

  const onRequestClose = React.useCallback(() => {
    setOpenTourId(undefined);
  }, [setOpenTourId]);

  return (
    <Tour
      steps={steps}
      isOpen={isDashboardTourOpen}
      onRequestClose={onRequestClose}
      ref={tourRef}
    />
  );
};

export default DashboardTour;
