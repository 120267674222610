import React, { SVGProps } from "react";

const ChartVerticalBars = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Component_83_1"
    width={25}
    height={24}
    viewBox="0 0 25 24"
    {...props}
  >
    <defs>
      <style>{".prefix__cls-1{fill:#9baeaf}"}</style>
    </defs>
    <path
      id="prefix__Rectangle_1595"
      d="M0 0h7v16H0z"
      className="prefix__cls-1"
      transform="translate(0 8)"
    />
    <path
      id="prefix__Rectangle_1597"
      d="M0 0h7v10H0z"
      className="prefix__cls-1"
      transform="translate(9 14)"
    />
    <path
      id="prefix__Rectangle_1599"
      d="M0 0h7v24H0z"
      className="prefix__cls-1"
      transform="translate(18)"
    />
  </svg>
);

export default ChartVerticalBars;
