import React, { SVGProps } from "react";

const Alert = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.979}
      height={20.493}
      viewBox="0 0 16.979 20.493"
      fill="currentColor"
      {...props}
    >
      <path
        id="prefix__Path_657"
        data-name="Path 657"
        d="M1446.364 766.475h-14.116a1.433 1.433 0 01-1.431-1.431 3.706 3.706 0 012.628-3.523v-5.544a5.861 5.861 0 1111.721 0v5.544a3.707 3.707 0 012.628 3.523 1.433 1.433 0 01-1.43 1.431zm-7.058-15.185a4.692 4.692 0 00-4.686 4.686v6.013a.587.587 0 01-.5.58 2.519 2.519 0 00-2.129 2.474.257.257 0 00.256.256h14.116a.257.257 0 00.257-.256 2.52 2.52 0 00-2.129-2.474.587.587 0 01-.5-.58v-6.013a4.691 4.691 0 00-4.685-4.685z"
        transform="translate(-1430.816 -750.116)"
      />
      <path
        id="prefix__Path_658"
        data-name="Path 658"
        d="M1446.579 793.68a3.124 3.124 0 01-3.125-2.845.587.587 0 111.169-.109 1.965 1.965 0 003.912 0 .587.587 0 011.169.109 3.125 3.125 0 01-3.125 2.845z"
        transform="translate(-1438.09 -773.187)"
      />
    </svg>
  );
};

export default Alert;
