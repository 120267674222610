import React from "react";
import { Alert, Button, Form, Modal } from "rsuite";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import { components } from "../../../types/openapi";
import { Loader } from "rsuite";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import "./index.scss";
import axios from "../../../inc/axios";
import ajv from "../../../inc/ajv";
import { I18nContext } from "../../../provider/I18nProvider";
import useGroups from "../../../hooks/useGroups";
import { AuthContext } from "../../../provider/AuthProvider";

interface IGroupModalProps {
  show: boolean;
  groupId?: string;
  onClose: () => void;
}

type Group = components["schemas"]["Group"];

const GroupSchema = openapi.components.schemas.Group as oas30.SchemaObject;
const validate = ajv.compile(GroupSchema);

const { properties } = GroupSchema;
if (!properties) {
  throw new Error("Incomplete group schema");
}

const GroupModal = ({ show, groupId, onClose }: IGroupModalProps) => {
  const { t } = React.useContext(I18nContext);
  const { setGroups } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const [group, setGroup] = React.useState<Group>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  const groups = useGroups();

  React.useEffect(() => {
    if (!groups || !auth) {
      return;
    }
    let group: Group | null = groups && groupId ? groups[groupId] : null;
    if (!group) {
      group = getEmptyObject(GroupSchema) as Group;
    }

    if (!group) {
      throw new Error("Could not construct group for form");
    }
    setGroup(JSON.parse(JSON.stringify(group)));
  }, [auth, groupId, groups]);

  const formIsDirty = React.useMemo(() => {
    const originalGroup = groups && groupId ? groups[groupId] : null;
    return JSON.stringify(originalGroup) !== JSON.stringify(group);
  }, [group, groupId, groups]);

  const onDeleteClick = React.useCallback(() => {
    const { groupId } = group || {};
    if (!groupId || !groups) {
      return;
    }
    axios
      .delete(`/group/crud/${groupId}`)
      .then(() => {
        const newGroups = { ...groups };
        delete newGroups[groupId];
        setGroups(newGroups);
        Alert.success(t("deleteSuccessful"));
        onClose();
      })
      .catch((err) => {
        Alert.error(t(err.message || "genericErrorMessage"));
      });
  }, [onClose, setGroups, t, group, groups]);

  const onSubmit = React.useCallback(() => {
    const isValid = validate(group);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }
    setErrors({});
    const isNew = !(group && group.groupId);
    axios
      .request({
        url: `/group/crud${group && group.groupId ? `/${group.groupId}` : ""}`,
        method: isNew ? "post" : "put",
        data: group,
      })
      .then((res) => {
        const updatedGroup = res.data as Group;
        if (groups) {
          setGroups({
            ...groups,
            [updatedGroup.groupId as string]: updatedGroup,
          });
        }
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [onClose, setGroups, t, group, groups]);

  return (
    <Modal show={show} onHide={onClose} className="modal-size-auto">
      <Modal.Header>
        <Modal.Title>{group ? group.groupName : t("loading")}</Modal.Title>
        <div style={{ display: "flex" }}>
          <Button
            onClick={onSubmit}
            appearance="primary"
            disabled={!formIsDirty}
          >
            {t("save")}
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: undefined }}>
        <div className="modal__content">
          {group ? (
            <Form className="modal__content__form">
              <div className="modal__content__form__profile">
                <h4 className="modal__content__form__profile__title">
                  Klantprofiel
                </h4>
                <SchemaFormBody<Group>
                  schema={GroupSchema}
                  onChange={(updatedGroup) => {
                    setGroup(updatedGroup);
                  }}
                  value={group}
                  errors={errors}
                  inputClassName="input-gray"
                />
              </div>
              {group.groupId ? (
                <Button
                  onClick={onDeleteClick}
                  appearance="default"
                  color="red"
                  style={{ marginTop: 48 }}
                >
                  {t("delete")}
                </Button>
              ) : null}
            </Form>
          ) : (
            <Loader center size="lg" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GroupModal;
