import { components } from "../types/openapi";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  differenceInHours,
  eachDayOfInterval,
  endOfDay,
  format,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subYears,
} from "date-fns";
import locale from "date-fns/locale/nl";

export const DATE_FORMAT = "dd-MM-yyyy";
export const DATE_FORMAT_REVERSE = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "dd-MM-yyyy HH:mm";
export const DATE_TIME_SEPARATED_FORMAT = "dd-MM-yyyy -- HH:mm";
export const DATE_COMPACT_FORMAT = "dd-MM-yy";

export interface IPeriods {
  comparePeriod?: components["schemas"]["Period"];
  period: components["schemas"]["Period"];
}

export const periodTypeToDateRange = (
  periodType: components["schemas"]["Period"]["periodType"]
): [Date, Date] | null => {
  const today = startOfDay(new Date());
  const endOfToday = endOfDay(today);
  switch (periodType) {
    // "afgelopen 7 dagen"
    case "lastWeek":
      return [subDays(today, 7), endOfToday];

    // "afgelopen 30 dagen"
    case "lastMonth":
      return [subDays(today, 30), endOfToday];

    // "afgelopen 90 dagen"
    case "lastQuarter":
      return [subDays(today, 90), endOfToday];

    case "yearToDate":
      return [startOfYear(today), endOfToday];

    case "thisDay":
      return [today, endOfToday];

    default:
      // case "all":
      // case "custom":
      return null;
  }
};

export type TDateRange = [] | [Date, Date];

export function getPeriodDateRange(
  period: components["schemas"]["Period"]
): TDateRange {
  if (period.periodType === "custom") {
    if (period.startDate && period.endDate) {
      return [new Date(period.startDate), new Date(period.endDate)];
    }
    return [];
  }
  return periodTypeToDateRange(period.periodType) || [];
}

/**
 *
 * Overcome summertime issues:
 *
 * https://date-fns.org/v2.29.3/docs/differenceInDays
 *
 * To ignore DST and only measure exact 24-hour periods, use this instead: Math.floor(differenceInHours(dateLeft, dateRight)/24)|0.
 *
 */
export const differenceInDays = (
  dateA: number | Date,
  dateB: number | Date
) => {
  return Math.round(differenceInHours(dateA, dateB) / 24);
};

export const getComparePeriodDateRange = (
  comparePeriod: components["schemas"]["Period"],
  period: components["schemas"]["Period"]
): TDateRange => {
  if (comparePeriod.periodType === "custom") {
    if (!comparePeriod.startDate || !comparePeriod.endDate) {
      return [];
    }
    return [new Date(comparePeriod.startDate), new Date(comparePeriod.endDate)];
  }
  const periodDateRange = getPeriodDateRange(period);
  if (!periodDateRange.length) {
    return [];
  }
  const endDate = subDays(periodDateRange[0], 1);
  switch (comparePeriod.periodType) {
    // "afgelopen 7 dagen"
    case "lastWeek":
      return [subDays(endDate, 7), endDate];

    // "afgelopen 30 dagen"
    case "lastMonth":
      return [subDays(endDate, 30), endDate];

    // "afgelopen 90 dagen"
    case "lastQuarter":
      return [subDays(endDate, 90), endDate];

    case "yearToDate":
      return [subYears(periodDateRange[0], 1), subYears(periodDateRange[1], 1)];
  }
  const dayDiff = differenceInDays(periodDateRange[1], periodDateRange[0]);
  return [subDays(endDate, dayDiff), endDate];
};

export const getComparedToDate = (
  date: Date,
  comparePeriod: components["schemas"]["Period"],
  period: components["schemas"]["Period"]
): Date => {
  switch (comparePeriod.periodType) {
    case "lastWeek":
      return addDays(date, 7);
    case "lastMonth":
      return addDays(date, 30);
    case "lastQuarter":
      return addDays(date, 90);
    case "yearToDate":
      return addYears(date, 1);
  }
  const periodDateRange = getPeriodDateRange(period);
  const comparePeriodDateRange = getPeriodDateRange(comparePeriod);
  if (periodDateRange.length && comparePeriodDateRange.length) {
    const dayIndex = differenceInDays(date, comparePeriodDateRange[0]);
    return addDays(periodDateRange[0], dayIndex);
  }
  console.log("Could not find getComparedToDate", date, comparePeriod);
  return date;
};

export const daysInRange = (
  start: Date,
  end: Date,
  interval: "day" | "week" | "month" | "quarter" | "year"
) => {
  const startDate = new Date(start);

  switch (interval) {
    case "day":
      return eachDayOfInterval({ start, end });

    case "week":
    case "month":
    case "quarter":
    case "year":
      const startOfInterval = (date: Date): Date => {
        switch (interval) {
          case "week":
            return startOfWeek(date, { weekStartsOn: 1 });

          case "month":
            return startOfMonth(date);

          case "quarter":
            return startOfQuarter(date);

          case "year":
            return startOfYear(date);
        }
      };
      const addInterval = (date: Date): Date => {
        switch (interval) {
          case "week":
            return addWeeks(date, 1);

          case "month":
            return addMonths(date, 1);

          case "quarter":
            return addQuarters(date, 1);

          case "year":
            return addYears(date, 1);
        }
      };

      let currentDate = addInterval(startOfInterval(startDate));
      const dates = [startDate];
      while (!isAfter(startOfDay(currentDate), startOfDay(end))) {
        dates.push(currentDate);
        currentDate = addInterval(currentDate);
      }
      return dates;
  }
};

/**
 * e.g. 2022-09-07T00:00:00.000+02:00 should be treated as 2022-09-07 with local timezone
 * but  2022-09-07T11:11:11.111+02:00 should be evaluated WITH time and specified timezone
 */
export const parseDateWithOptionalTime = (dateString: string): Date => {
  return new Date(
    dateString.includes("T00:00:00.000")
      ? dateString.substring(0, 10)
      : dateString
  );
};

export const localeFormat = (date: Date | number, dateFormat: string): string =>
  format(date, dateFormat, { locale });
