export type TCronFrequency = "daily" | "weekly" | "monthly";

/**
 * Get the cron frequency from a cron array
 *
 * @param cronArray
 */
export const getCronFrequency = (
  cronArray: string[]
): TCronFrequency | null => {
  if (cronArray.length !== 5) {
    console.log("Cron is invalid");
    return null;
  }

  if (cronArray[2] === "*" && cronArray[4] !== "*") {
    return "weekly";
  } else if (cronArray[2] !== "*" && cronArray[4] === "*") {
    return "monthly";
  } else {
    return "daily";
  }
};
