import React from "react";
import { Icon, Radio, RadioGroup } from "rsuite";
import { IconNames } from "rsuite/lib/Icon/Icon";
import "./index.scss";

interface IIconRadioGroupProps {
  inline?: boolean;
  items: {
    disabled?: boolean;
    value: string;
    svg?: React.FunctionComponent;
    icon?: IconNames;
  }[];
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  value: string;
}

const IconRadioGroup = ({
  items,
  inline,
  onChange,
  style,
  value,
}: IIconRadioGroupProps) => (
  <RadioGroup
    className="icon-radio-group"
    inline={inline}
    name="radioList"
    onChange={onChange}
    value={value}
    style={style}
  >
    {items.map((item, key) => (
      <Radio
        key={key}
        className={`icon-radio-group__item${
          item.disabled ? " icon-radio-group__item--disabled" : ""
        }`}
        value={item.value}
        disabled={item.disabled}
      >
        {item.icon || item.svg ? (
          <Icon icon={item.icon || "star"} componentClass={item.svg} />
        ) : null}
      </Radio>
    ))}
  </RadioGroup>
);
export default IconRadioGroup;
