import React from "react";
import SchemaTable from "../../components/SchemaTable";
import { components } from "../../types/openapi";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import { Checkbox, Icon, IconButton } from "rsuite";
import Mig from "../../icons/Mig";
import Search from "../../icons/Search";
import useSearchTopics from "../../hooks/useSearchTopics";
import { I18nContext } from "../../provider/I18nProvider";
import Rss from "../../icons/Rss";
import { generateAndDownload } from "../../inc/excel";
import "./index.scss";

const schema = openapi.components.schemas.SearchTopic as oas30.SchemaObject;

const SearchTopicsView = () => {
  const { t } = React.useContext(I18nContext);
  const [isInactiveVisible, setIsInactiveVisible] =
    React.useState<boolean>(false);

  const searchTopics = useSearchTopics();

  const data = React.useMemo(
    () =>
      searchTopics
        ? Object.values(searchTopics)
            .filter((searchTopic) =>
              isInactiveVisible ? true : searchTopic.active
            )
            .sort((a, b) => {
              if (a.active !== b.active) {
                return a.active ? -1 : 1;
              }
              return a.name < b.name ? -1 : 1;
            })
        : [],
    [isInactiveVisible, searchTopics]
  );

  const onExport = React.useCallback(
    (searchTopics: components["schemas"]["SearchTopic"][]) => {
      generateAndDownload(
        "Zoekopdrachten",
        [
          { header: "Naam", key: "name", width: 40 },
          { header: "Type", key: "searchType", width: 40 },
          { header: "Actief", key: "active" },
        ],
        searchTopics.map((searchTopic) => ({
          ...searchTopic,
          searchType: t(searchTopic.searchType),
          active: searchTopic.active ? "Ja" : "Nee",
        }))
      );
    },
    [t]
  );

  return (
    <div className="search-topics-view">
      <SchemaTable<components["schemas"]["SearchTopic"]>
        globalSearch
        data={data}
        onExport={onExport}
        schema={schema}
        columnsConfigs={[
          { name: "searchTopicId", hidden: true },
          {
            name: "name",
            flexGrow: 2,
            renderCell: (searchTopic) => (
              <div
                className={`search-topic-view__name-column search-topic-view__name-column--${
                  searchTopic.active ? "active" : "inactive"
                }`}
              >
                <Icon
                  icon={searchTopic.active ? "circle" : "circle-o"}
                  style={{ fontSize: 10, marginRight: 4 }}
                />
                <span>{searchTopic.name}</span>
              </div>
            ),
          },
          {
            name: "active",
            width: 120,
            align: "center",
            renderCell: (rowData) =>
              rowData.searchType === "searchByCustomer" ? (
                <IconButton
                  size="sm"
                  appearance="subtle"
                  className="rs-btn-subtle--in-table"
                  icon={<Icon icon={rowData.active ? "check" : "close"} />}
                />
              ) : (
                <Icon icon={rowData.active ? "check" : "close"} />
              ),
          },
          {
            name: "searchType",
            width: 120,
            align: "center",
            renderCell: (rowData) => {
              switch (rowData.searchType) {
                case "searchByMig":
                  return (
                    <div title={t("searchByMig")}>
                      <Mig style={{ height: 32, width: 32 }} />
                    </div>
                  );

                case "searchByRss":
                  return (
                    <div title={t("searchByRss")}>
                      <Rss style={{ height: 20, width: 20, marginRight: 6 }} />
                    </div>
                  );

                case "searchByCustomer":
                case "manualAdd":
                  return (
                    <div title={t("searchByCustomer")}>
                      <Search
                        style={{ height: 32, width: 25, marginRight: 8 }}
                      />
                    </div>
                  );

                default:
                  return <div>{rowData.searchType}</div>;
              }
            },
          },
        ]}
      >
        <Checkbox
          checked={isInactiveVisible}
          onChange={() => {
            setIsInactiveVisible((isInactiveVisible) => !isInactiveVisible);
          }}
        >
          {t("inactiveSearchTopics_show")}
        </Checkbox>
      </SchemaTable>
    </div>
  );
};
export default SearchTopicsView;
