import { difference } from "lodash";
import { Divider, Loader, Panel, Popover } from "rsuite";
import LabelTag from "../../LabelTag";
import MediaType from "../../../icons/MediaType";
import Favourite from "../../../icons/Favourite";
import React from "react";
import { components } from "../../../types/openapi";
import Signal from "../../../icons/Signal";
import Editorial from "../../../icons/Editorial";
import Flag from "../../../icons/Flag";
import LabelIcon from "../../../icons/Label";
import AsyncPopover from "../../AsyncPopover";
import Slider from "../../Slider";
import SearchTopicPopover from "./SearchTopicPopover";
import { ESentiment, sentimentOptions } from "../../../inc/sentimentOptions";
import Author from "../../../icons/Author";
import MagnifyingGlass from "../../../icons/MagnifyingGlass";
import MwTag from "../../MwTag";
import CategoryPopover from "./CategoryPopover";
import { I18nContext } from "../../../provider/I18nProvider";
import useLabels from "../../../hooks/useLabels";
import "./index.scss";
import MwWhisper from "../../MwWhisper";
import EditorialRubricCheckTree from "../../EditorialRubricCheckTree";
import SwitchEditorialRubric from "../../../icons/SwitchEditorialRubric";
import DistributedProvincePopover from "./DistributionProvincePopover";
import { AuthContext } from "../../../provider/AuthProvider";

interface ITagsBarProps {
  authorsWhisperRef: React.Ref<any>;
  categoryWhisperRef: React.Ref<any>;
  distributionProvincesWhisperRef: React.Ref<any>;
  collapsible?: boolean;
  editorialRubricsWhisperRef: React.Ref<any>;
  isExpanded: boolean;
  filter: components["schemas"]["Filter"];
  searchTopicWhisperRef: React.Ref<any>;
  sourcesWhisperRef: React.Ref<any>;
  onChange?: (filter: components["schemas"]["Filter"]) => void;
}

const TagsBar = ({
  categoryWhisperRef,
  collapsible,
  distributionProvincesWhisperRef,
  filter,
  isExpanded,
  onChange,
  sourcesWhisperRef,
  authorsWhisperRef,
  searchTopicWhisperRef,
  editorialRubricsWhisperRef,
}: ITagsBarProps) => {
  const { auth } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const {
    authorIds,
    categoryIds,
    distributionProvinces,
    editorialRubricIds,
    isFavouriteMediaItem,
    isInEditorial,
    isReadMediaItem,
    mediaTypes,
    searchTopicIds,
    sentiments,
    sourceIds,
    text,
  } = filter;
  const labels = useLabels();

  const isDashboardOnly = auth
    ? auth.jwt.userRoles.indexOf("dashboardOnly") >= 0
    : false;

  const tagBarChildren = React.useMemo(() => {
    const children: React.ReactElement[] = [];

    if (searchTopicIds) {
      children.push(
        <SearchTopicPopover
          key="searchTopicIds"
          whisperRef={searchTopicWhisperRef}
          value={searchTopicIds}
          onChange={
            onChange
              ? (newSearchTopicIds) => {
                  if (!onChange) {
                    return;
                  }
                  const { searchTopicIds, ...newFilter } = filter;
                  if (newSearchTopicIds !== undefined) {
                    (
                      newFilter as components["schemas"]["Filter"]
                    ).searchTopicIds = newSearchTopicIds;
                  }
                  onChange(newFilter);
                }
              : undefined
          }
        />,
      );
      children.push(<Divider key="searchTopicIds_div" vertical />);
    }
    if (mediaTypes?.length) {
      children.push(
        ...mediaTypes.map((item, index) => (
          <MwTag
            key={`mediaTypes_${index}`}
            onClose={
              onChange
                ? () => {
                    onChange({
                      ...filter,
                      mediaTypes: mediaTypes?.filter(
                        (mediaType) => mediaType !== item,
                      ),
                    });
                  }
                : undefined
            }
            iconComponent={MediaType}
            color="cyan"
            size="lg"
          >
            {t(`mediaType_${item}`)}
          </MwTag>
        )),
      );
      children.push(<Divider key="mediaTypes_div" vertical />);
    }
    if (sentiments?.length) {
      children.push(
        ...sentiments.map((item, index) => {
          return (
            <MwTag
              key={`sentiments_${index}`}
              onClose={
                onChange
                  ? () => {
                      onChange({
                        ...filter,
                        sentiments: sentiments?.filter(
                          (sentiment) => sentiment !== item,
                        ),
                      });
                    }
                  : undefined
              }
              iconComponent={sentimentOptions[item as ESentiment].icon}
              color="cyan"
              size="lg"
            >
              {t(sentimentOptions[item as ESentiment].label)}
            </MwTag>
          );
        }),
      );
      children.push(<Divider key="sentiments_div" vertical />);
    }
    // Only show if isReadMediaItem equals the boolean value false,
    // not when isReadMediaItem is falsy
    if (isReadMediaItem === false) {
      children.push(
        <MwTag
          key="isReadMediaItem"
          iconComponent={Flag}
          onClose={
            onChange
              ? () => {
                  const { isReadMediaItem, ...newFilter } = filter;
                  onChange(newFilter);
                }
              : undefined
          }
          color="cyan"
          size="lg"
        >
          {t("unread")}
        </MwTag>,
      );
      children.push(<Divider key="isReadMediaItem_div" vertical />);
    }
    if (filter?.includeLabelIds?.length) {
      children.push(
        ...filter.includeLabelIds.map((ids, index) => (
          <MwTag
            key={`includeLabelIds_${index}`}
            iconComponent={LabelIcon}
            onClose={
              onChange
                ? () => {
                    onChange({
                      ...filter,
                      includeLabelIds: filter.includeLabelIds?.filter(
                        (labelIds, curIndex) => index !== curIndex,
                      ),
                    });
                  }
                : undefined
            }
            color="cyan"
            size="lg"
          >
            {ids
              .map((id) => (labels && labels[id] ? labels[id].name : "⌛"))
              .join(" + ")}
          </MwTag>
        )),
      );
      children.push(<Divider key="includeLabelIds_div" vertical />);
    }
    if (filter?.excludeLabelIds?.length) {
      children.push(
        ...filter.excludeLabelIds.map((id, index) => {
          const label = labels ? labels[id] : undefined;
          return label ? (
            <LabelTag
              key={`excludeLabelIds_${index}`}
              isExcluded
              onClose={
                onChange
                  ? () => {
                      onChange({
                        ...filter,
                        excludeLabelIds: filter.excludeLabelIds?.filter(
                          (excludeLabelId) => excludeLabelId !== id,
                        ),
                      });
                    }
                  : undefined
              }
              label={label}
              color="cyan"
              size="lg"
            />
          ) : (
            <Loader />
          );
        }),
      );
      children.push(<Divider key="excludeLabelIds_div" vertical />);
    }
    if (categoryIds) {
      children.push(
        <CategoryPopover
          key="categoryIds"
          whisperRef={categoryWhisperRef}
          value={categoryIds}
          onChange={
            onChange
              ? (categoryIds) => {
                  onChange({
                    ...filter,
                    categoryIds,
                  });
                }
              : undefined
          }
        />,
      );
      children.push(<Divider key="categoryIds_div" vertical />);
    }
    if (sourceIds) {
      children.push(
        <AsyncPopover
          placement="bottomStart"
          key="sourceIds"
          dataUrl="/source/crud"
          initialLabel={t("sourceAsyncPopoverInitialLabel")} //Zoek een bron"
          nameProp="name"
          valueProp="sourceId"
          whisperRef={sourcesWhisperRef}
          values={sourceIds}
          onChange={
            onChange
              ? (sourceIds) => {
                  onChange({
                    ...filter,
                    sourceIds,
                  });
                }
              : undefined
          }
        >
          <MwTag
            iconComponent={Signal}
            onClose={
              onChange
                ? () => {
                    const { sourceIds, ...newFilter } = filter;
                    onChange(newFilter);
                  }
                : undefined
            }
            color="cyan"
            size="lg"
          >
            {t("sourcesX", sourceIds.length)}
          </MwTag>
        </AsyncPopover>,
      );
      children.push(<Divider key="sourceIds_div" vertical />);
    }
    if (distributionProvinces) {
      children.push(
        <DistributedProvincePopover
          key="distributionProvinceIds"
          whisperRef={distributionProvincesWhisperRef}
          value={distributionProvinces}
          onChange={
            onChange
              ? (distributionProvinces) => {
                  onChange({
                    ...filter,
                    distributionProvinces,
                  });
                }
              : undefined
          }
        />,
      );
      children.push(<Divider key="distributionProvinceIds_div" vertical />);
    }
    if (authorIds) {
      children.push(
        <AsyncPopover
          key="authorIds"
          dataUrl="/author/crud"
          initialLabel={t("authorAsyncPopoverInitialLabel")} //Zoek een bron"
          nameProp="name"
          placement="bottomStart"
          valueProp="authorId"
          whisperRef={authorsWhisperRef}
          values={authorIds}
          onChange={
            onChange
              ? (authorIds) => {
                  onChange({
                    ...filter,
                    authorIds,
                  });
                }
              : undefined
          }
        >
          <MwTag
            iconComponent={Author}
            onClose={
              onChange
                ? () => {
                    if (!onChange) {
                      return;
                    }
                    const { authorIds, ...newFilter } = filter;
                    onChange(newFilter);
                  }
                : undefined
            }
            color="cyan"
            size="lg"
          >
            {t("authorsX", authorIds.length)}
          </MwTag>
        </AsyncPopover>,
      );
      children.push(<Divider key="authorIds_div" vertical />);
    }
    if (isFavouriteMediaItem) {
      children.push(
        <MwTag
          key="isFavouriteMediaItem"
          iconComponent={Favourite}
          onClose={
            onChange
              ? () =>
                  onChange({
                    ...filter,
                    isFavouriteMediaItem: undefined,
                  })
              : undefined
          }
          color="cyan"
          size="lg"
        >
          {t("favorite")}
        </MwTag>,
      );
      children.push(<Divider key="isFavouriteMediaItem_div" vertical />);
    }
    if (isInEditorial) {
      children.push(
        <MwTag
          key="isInEditorial"
          className="tags-bar__is-in-editorial-tag"
          iconComponent={Editorial}
          onClose={
            onChange
              ? () =>
                  onChange({
                    ...filter,
                    isInEditorial: undefined,
                  })
              : undefined
          }
          color="cyan"
          size="lg"
        >
          {t("addedToEditorial")}
        </MwTag>,
      );
      children.push(<Divider key="isFavouriteMediaItem_div" vertical />);
    }
    if (editorialRubricIds) {
      children.push(
        <MwWhisper
          key="editorialRubricsWhisperRef"
          trigger="active"
          ref={editorialRubricsWhisperRef}
          placement="bottom"
          speaker={
            isDashboardOnly ? (
              <div />
            ) : (
              <Popover full>
                <EditorialRubricCheckTree
                  canSelectEditorials
                  value={editorialRubricIds}
                  onToggle={(toggledEditorialRubricIds, checked) => {
                    if (!onChange) {
                      return;
                    }
                    let newEditorialRubricIds: undefined | string[];
                    if (checked) {
                      newEditorialRubricIds = [
                        ...editorialRubricIds,
                        ...toggledEditorialRubricIds,
                      ];
                    } else {
                      newEditorialRubricIds = difference(
                        editorialRubricIds,
                        toggledEditorialRubricIds,
                      );
                      if (!newEditorialRubricIds.length) {
                        newEditorialRubricIds = undefined;
                      }
                    }
                    onChange({
                      ...filter,
                      editorialRubricIds: newEditorialRubricIds,
                    });
                  }}
                />
              </Popover>
            )
          }
        >
          <MwTag
            iconComponent={SwitchEditorialRubric}
            onClose={
              onChange
                ? () => {
                    const { editorialRubricIds, ...newFilter } = filter;
                    onChange(newFilter);
                  }
                : undefined
            }
            color="cyan"
            size="lg"
          >
            {t("addedToEditorialRubricX", editorialRubricIds.length)}
          </MwTag>
        </MwWhisper>,
      );
      children.push(<Divider key="editorialRubricIds_div" vertical />);
    }

    if (text) {
      children.push(
        <MwTag
          key="text"
          iconComponent={MagnifyingGlass}
          onClose={
            onChange
              ? () => {
                  const { text, ...newFilter } = filter;
                  onChange(newFilter);
                }
              : undefined
          }
          color="cyan"
          size="lg"
        >
          {`"${text}"`}
        </MwTag>,
      );
      children.push(<Divider key="text_div" vertical />);
    }
    return children;
  }, [
    searchTopicIds,
    mediaTypes,
    sentiments,
    isReadMediaItem,
    filter,
    categoryIds,
    sourceIds,
    distributionProvinces,
    authorIds,
    isFavouriteMediaItem,
    isInEditorial,
    editorialRubricIds,
    text,
    searchTopicWhisperRef,
    onChange,
    t,
    labels,
    categoryWhisperRef,
    sourcesWhisperRef,
    distributionProvincesWhisperRef,
    authorsWhisperRef,
    editorialRubricsWhisperRef,
    isDashboardOnly,
  ]);

  return (
    <Panel
      className="tags-bar"
      collapsible={collapsible !== undefined ? collapsible : true}
      expanded={isExpanded}
      shaded
    >
      <div className="w-100">
        <Slider listHeight={25}>{tagBarChildren}</Slider>
      </div>
    </Panel>
  );
};

export default TagsBar;
