import React from "react";
import { sortSelectedOnTop } from "../../../../../inc/sort";
import { dataContainsWordThatStartWith } from "../../../../../inc/query";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Icon,
  IconButton,
  Input,
  InputGroup,
  Popover,
} from "rsuite";
import MagnifyingGlass from "../../../../../icons/MagnifyingGlass";
import Compass from "../../../../../icons/Compass";
import { I18nContext } from "../../../../../provider/I18nProvider";
import useSearchTopics from "../../../../../hooks/useSearchTopics";
import NumberBadge from "../../../../../components/NumberBadge";
import MwWhisper from "../../../../../components/MwWhisper";

interface ISearchTopicButtonProps {
  editable: boolean;
  value: string[];
  onChange: (newValue?: string[]) => void;
}

const SearchTopicButton = ({
  editable,
  value,
  onChange,
}: ISearchTopicButtonProps) => {
  const { t } = React.useContext(I18nContext);
  const searchTopics = useSearchTopics();

  const [hasActiveFilter, setHasActiveFilter] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [sortCounter, setSortCounter] = React.useState<number>(0);

  const onToggleInactiveClick = React.useCallback(() => {
    setHasActiveFilter((showInactive) => !showInactive);
  }, []);
  const sortedSearchTopics = React.useMemo(() => {
    return sortSelectedOnTop(
      Object.values(searchTopics || {}).sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        if (nameA === nameB) {
          return 0;
        }
        return nameA > nameB ? 1 : -1;
      }),
      value,
      "searchTopicId"
    );
    // eslint-disable-next-line
  }, [sortCounter, searchTopics]);

  const results = React.useMemo(
    () =>
      sortedSearchTopics.filter((searchTopic) => {
        if (!editable) {
          // If not editable, return only the selected search topics
          return (
            searchTopic.searchTopicId &&
            value.indexOf(searchTopic.searchTopicId) >= 0
          );
        }

        if (searchQuery) {
          return dataContainsWordThatStartWith(searchTopic.name, searchQuery);
        }
        if (hasActiveFilter) {
          return (
            searchTopic.active ||
            (searchTopic.searchTopicId &&
              value.indexOf(searchTopic.searchTopicId) >= 0)
          );
        }
        return true;
      }),
    [sortedSearchTopics, editable, searchQuery, hasActiveFilter, value]
  );

  const options = React.useMemo(
    () =>
      results.map((searchTopic) => (
        <Checkbox
          className={`checkbox-max-width views__dashboard-view__filter-bar__tags-bar__search-topic-popover__item--${
            searchTopic.active ? "active" : "inactive"
          }`}
          key={searchTopic.searchTopicId}
          value={searchTopic.searchTopicId}
        >
          <Icon
            icon={searchTopic.active ? "circle" : "circle-o"}
            style={{ fontSize: 10, marginRight: 4 }}
          />
          {searchTopic.name}
        </Checkbox>
      )),
    [results]
  );

  return (
    <NumberBadge number={value.length}>
      <MwWhisper
        placement="bottom"
        trigger="click"
        onClose={() => {
          if (!value.length) {
            onChange();
          }
          setSortCounter(sortCounter + 1);
        }}
        speaker={
          <Popover className="popover-without-arrow checkbox-popover views__dashboard-view__filter-bar__tags-bar__search-topic-popover">
            {editable ? (
              <>
                <InputGroup inside>
                  <InputGroup.Addon>
                    <Icon icon="star" componentClass={MagnifyingGlass} />
                  </InputGroup.Addon>
                  <Input
                    size="sm"
                    value={searchQuery}
                    onChange={(value) => setSearchQuery(value)}
                  />
                </InputGroup>
                <div>
                  {searchQuery ? null : (
                    <Button
                      block
                      className="my-2 px-0"
                      onClick={onToggleInactiveClick}
                    >
                      {hasActiveFilter
                        ? t("inactiveSearchTopics_show")
                        : t("inactiveSearchTopics_hide")}
                    </Button>
                  )}
                  <CheckboxGroup value={value} onChange={onChange}>
                    {options}
                  </CheckboxGroup>
                </div>
              </>
            ) : (
              <ul className="list-unstyled mb-0">
                {results.map((result) => (
                  <li>{result.name}</li>
                ))}
              </ul>
            )}
          </Popover>
        }
      >
        <IconButton
          className={`filter-bar__filters-col__button`}
          appearance="subtle"
          size="xs"
          icon={<Icon icon="question" componentClass={Compass} />}
        >
          {t("searchQuery")}
        </IconButton>
      </MwWhisper>
    </NumberBadge>
  );
};
export default SearchTopicButton;
