import React from "react";
import { components } from "../../types/openapi";
import SchemaTable from "../../components/SchemaTable";
import openapi from "../../openapi.json";
import { oas30 } from "openapi3-ts";
import CustomerModal from "./CustomerModal";
import useCustomers from "../../hooks/useCustomers";
import { AuthContext } from "../../provider/AuthProvider";

const schema = openapi.components.schemas.Customer as oas30.SchemaObject;
type TCustomer = components["schemas"]["Customer"];

const CustomersView = () => {
  const [selectedCustomerLinkId, setSelectedCustomerLinkId] = React.useState<
    string | undefined
  >();
  const customers = useCustomers();
  const { auth } = React.useContext(AuthContext);

  const isSuperUser = (auth?.jwt.userRoles || []).indexOf("superuser") >= 0;
  const data = React.useMemo(
    () =>
      customers
        ? Object.values(customers).filter(
            // filter users for the currently active group
            (user) => isSuperUser || user.groupId === auth?.jwt.currentGroupId
          )
        : undefined,
    [isSuperUser, auth?.jwt.currentGroupId, customers]
  );

  const onRowClick = React.useCallback((rowData: TCustomer | null) => {
    if (rowData) {
      setSelectedCustomerLinkId(rowData.customerLinkId);
    }
  }, []);
  const onClose = React.useCallback(() => {
    setSelectedCustomerLinkId(undefined);
  }, []);
  return (
    <>
      <CustomerModal
        show={!!selectedCustomerLinkId}
        onClose={onClose}
        customerLinkId={selectedCustomerLinkId}
      />
      <SchemaTable<TCustomer>
        data={data}
        schema={schema}
        onRowClick={onRowClick}
        columnsConfigs={[
          { name: "customerLinkId", hidden: true },
          { name: "logoFileId", hidden: true },
          { name: "appConfig", hidden: true },
          { name: "editorialConfig", hidden: true },
          { name: "showPdfPublication", hidden: true },
          { name: "showPdfPublicationDate", hidden: true },
          { name: "showPdfArea", hidden: true },
          { name: "showPdfPageNumber", hidden: true },
          { name: "showPdfAve", hidden: true },
          { name: "showPdfRegion", hidden: true },
          { name: "showPdfFrequency", hidden: true },
          { name: "showPdfCirculation", hidden: true },
          { name: "showPdfCumulativeCirculation", hidden: true },
          { name: "showPdfReach", hidden: true },
          { name: "showPdfCumulativeReach", hidden: true },
          { name: "showPdfCumulativeAve", hidden: true },
          { name: "active" },
          { name: "customerName" },
          {
            name: "customerNo",
            renderCell: (rowData) => `${rowData.customerNo}`,
            sort: (a, b, sortAsc) =>
              (a.customerNo - b.customerNo) * (sortAsc ? 1 : -1),
          },
          { name: "groupId" },
        ]}
        globalSearch
        sortable
        dataSorter={(a, b, query: string) => {
          const customerNo = parseInt(query, 10);
          if (customerNo && !isNaN(customerNo)) {
            if (a.customerNo === customerNo) {
              return -1;
            }
            if (b.customerNo === customerNo) {
              return 1;
            }
          }
          return 0;
        }}
      />
    </>
  );
};

export default CustomersView;
