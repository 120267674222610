import React, { SVGProps } from "react";

const Help = (props: SVGProps<SVGSVGElement> & { title: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    viewBox="0 0 23 23"
    fill="currentColor"
    {...props}
  >
    <title>{props.title}</title>
    <g opacity={0.7}>
      <g>
        <g>
          <path
            d="M1429.8 406.86a11.315 11.315 0 1111.315-11.315 11.327 11.327 0 01-11.315 11.315zm0-21.581a10.267 10.267 0 1010.266 10.267 10.278 10.278 0 00-10.266-10.267z"
            transform="translate(-1418.484 -384.231)"
          />
          <path
            d="M1438.325 410.191a6.119 6.119 0 116.119-6.119 6.126 6.126 0 01-6.119 6.119zm0-11.19a5.071 5.071 0 105.071 5.071 5.077 5.077 0 00-5.071-5.072z"
            transform="translate(-1427.01 -392.758)"
          />
        </g>
        <g transform="translate(3.299 4.422)">
          <path
            d="M1458.1 399.233a.524.524 0 01-.364-.9l2.36-2.274a.524.524 0 11.727.755l-2.359 2.273a.522.522 0 01-.364.146z"
            transform="translate(-1446.071 -395.911)"
          />
          <path
            d="M1427.721 430.066a.524.524 0 01-.377-.887l2.185-2.271a.524.524 0 11.755.727l-2.184 2.265a.521.521 0 01-.379.166z"
            transform="translate(-1427.197 -415.072)"
          />
        </g>
        <g transform="translate(4.422 4.422)">
          <path
            d="M1463.795 429.98a.522.522 0 01-.363-.146l-2.271-2.185a.524.524 0 01.727-.755l2.271 2.185a.524.524 0 01-.364.9z"
            //
            transform="translate(-1449.324 -415.072)"
          />
          <path
            d="M1432.87 399.227a.523.523 0 01-.378-.161l-2.182-2.268a.524.524 0 11.755-.727l2.182 2.268a.524.524 0 01-.378.887z"
            //
            transform="translate(-1430.164 -395.911)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Help;
