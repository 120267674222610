import React from "react";
import { Alert, Button, Form, Loader, Modal } from "rsuite";
import { components } from "../../../types/openapi";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../../inc/ajv";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import axios from "axios";
import "./index.scss";
import { I18nContext } from "../../../provider/I18nProvider";
import useRssExports from "../../../hooks/useRssExports";
import { AuthContext } from "../../../provider/AuthProvider";

interface IRssExportModalProps {
  rssExportId?: string;
  show: boolean;
  onClose: () => void;
}

type TRssExport = components["schemas"]["RssExport"];

const RssExportchema = openapi.components.schemas
  .RssExport as oas30.SchemaObject;
const validate = ajv.compile(RssExportchema);

const { properties } = RssExportchema;
if (!properties) {
  throw new Error("Incomplete rssExport schema");
}

const RssExportModal = (props: IRssExportModalProps) => {
  const { rssExportId, show, onClose } = props;
  const { t } = React.useContext(I18nContext);
  const { updateRssExport, setRssExports } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  const rssExports = useRssExports();
  const [rssExport, setRssExport] = React.useState<TRssExport>();
  const [errors, setErrors] = React.useState<TSchemaFormErrors<TRssExport>>({});

  React.useEffect(() => {
    if (!rssExports) {
      return;
    }

    if (
      rssExport &&
      ((rssExport.rssExportId === undefined && rssExportId === "NEW") ||
        rssExport.rssExportId === rssExportId)
    ) {
      return;
    }

    let newRssExport: TRssExport | undefined =
      rssExports && rssExportId ? rssExports[rssExportId] : undefined;
    if (!newRssExport) {
      newRssExport = {
        ...getEmptyObject(RssExportchema),
      } as TRssExport;
    }

    if (!newRssExport) {
      throw new Error("Could not construct rssExport for form");
    }
    setRssExport(JSON.parse(JSON.stringify(newRssExport)));
  }, [rssExports, rssExportId, rssExport]);

  const formIsDirty = React.useMemo(() => {
    const originalRssExport =
      rssExports && rssExportId ? rssExports[rssExportId] : null;
    return JSON.stringify(originalRssExport) !== JSON.stringify(rssExport);
  }, [rssExports, rssExportId, rssExport]);

  const onSubmit = React.useCallback(() => {
    const isValid = validate(rssExport);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }

    if (!rssExport) {
      return;
    }
    setErrors({});
    updateRssExport(rssExport)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [onClose, rssExport, t, updateRssExport]);

  const onDelete = React.useCallback(() => {
    if (rssExportId === "NEW") {
      return;
    }
    axios
      .delete(`/rssExport/crud/${rssExportId}`)
      .then(() => {
        const newRssExport: { [key: string]: TRssExport } = { ...rssExports };
        delete newRssExport[rssExportId as string];
        setRssExports(newRssExport);
        Alert.success(t("deleteSuccessful"));
        onClose();
      })
      .catch((err) => {
        Alert.error(t(err.message || "genericErrorMessage"));
      });
  }, [onClose, rssExportId, rssExports, setRssExports, t]);

  const isSuperUser = !!auth?.jwt.superUserId;

  return (
    <Modal
      show={show}
      onHide={onClose}
      className="modal-size-auto views__rssExport-view__rssExport-modal"
    >
      <Form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>{rssExport ? rssExport.name : t("loading")}</Modal.Title>
          <div className="d-flex">
            <Button type="submit" appearance="primary" disabled={!formIsDirty}>
              {t("save")}
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: undefined }}>
          <div className="w-100">
            {rssExport ? (
              <>
                <SchemaFormBody<TRssExport>
                  schema={{
                    ...RssExportchema,
                    properties,
                  }}
                  config={
                    {
                      title: {
                        order: 1,
                      },
                      url: {
                        order: 2,
                      },
                      labelIds: {
                        order: 3,
                      },
                      lastIndexDate: {
                        hidden: true,
                      },
                    } as any
                  }
                  onChange={(updatedRssExport) => {
                    setRssExport(updatedRssExport);
                  }}
                  value={rssExport}
                  errors={errors}
                  inputClassName="input-gray"
                />
                {!!rssExport?.rssExportId && isSuperUser ? (
                  <Button
                    style={{ marginTop: 48 }}
                    onClick={onDelete}
                    appearance="primary"
                    color="red"
                  >
                    {t("delete")}
                  </Button>
                ) : null}
              </>
            ) : (
              <Loader center size="lg" />
            )}
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};
export default RssExportModal;
