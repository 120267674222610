import React from "react";
import Slider from "../Slider";
import { Icon } from "rsuite";
import useSearchTopics from "../../hooks/useSearchTopics";
import { components } from "../../types/openapi";

interface IMediaItemGridItemSearchTopicsProps {
  mediaItem: components["schemas"]["MediaItem"];
}

const MediaItemGridItemSearchTopics = ({
  mediaItem,
}: IMediaItemGridItemSearchTopicsProps) => {
  const { searchTopicIds } = mediaItem;
  const searchTopics = useSearchTopics();
  return searchTopics && searchTopicIds?.length ? (
    <div className="media-item-grid-item-search-topics mt-2">
      <Slider>
        {searchTopicIds.map((searchTopicId) => {
          const searchTopic = searchTopics[searchTopicId] as
            | components["schemas"]["SearchTopic"]
            | undefined;
          return (
            <div
              key={searchTopicId}
              className="views__dashboard-view__widgets__mediaitem-widget__search-topic"
              title={searchTopic ? searchTopic.name : "* ONBEKEND *"}
            >
              <Icon
                icon={searchTopic && searchTopic.active ? "circle" : "circle-o"}
                style={{ fontSize: 10 }}
              />
              {searchTopic ? searchTopic.name : "* ONBEKEND *"}
            </div>
          );
        })}
      </Slider>
    </div>
  ) : null;
};

export default MediaItemGridItemSearchTopics;
