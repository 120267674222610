import React, { SVGProps } from "react";

const DashboardEmpty = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={17.35}
      viewBox="0 0 25 17.35"
      {...props}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#fff}"}</style>
      </defs>
      <g
        id="prefix__Group_1555"
        opacity={0.704}
        transform="translate(-1359.13 -70.202)"
      >
        <g id="prefix__Group_1553" transform="translate(1359.13 76.655)">
          <path
            id="prefix__Path_1840"
            d="M1381.849 96.144h-20.439a2.284 2.284 0 01-2.281-2.281v-6.336a2.283 2.283 0 012.281-2.28.593.593 0 110 1.187 1.1 1.1 0 00-1.093 1.093v6.336a1.1 1.1 0 001.093 1.093h20.439a1.1 1.1 0 001.094-1.093v-6.336a1.1 1.1 0 00-1.094-1.093.593.593 0 110-1.187 2.283 2.283 0 012.281 2.28v6.336a2.284 2.284 0 01-2.281 2.281z"
            className="prefix__cls-2"
            transform="translate(-1359.13 -85.247)"
          />
        </g>
        <g id="prefix__Group_1554" transform="translate(1364.803 70.202)">
          <path
            id="prefix__Path_1841"
            d="M1387.484 77.249a.594.594 0 01-.594-.594V70.8a.593.593 0 111.187 0v5.859a.594.594 0 01-.593.59z"
            className="prefix__cls-2"
            transform="translate(-1380.656 -70.202)"
          />
          <path
            id="prefix__Path_1842"
            d="M1396.95 78.939a.594.594 0 01-.551-.814l2.173-5.441a.594.594 0 111.1.44l-2.172 5.442a.594.594 0 01-.55.373z"
            className="prefix__cls-2"
            transform="translate(-1386.062 -71.406)"
          />
          <path
            id="prefix__Path_1843"
            d="M1375.123 78.939a.594.594 0 01-.551-.374l-2.173-5.442a.594.594 0 011.1-.44l2.172 5.441a.594.594 0 01-.551.814z"
            className="prefix__cls-2"
            transform="translate(-1372.356 -71.406)"
          />
        </g>
      </g>
    </svg>
  );
};

export default DashboardEmpty;
