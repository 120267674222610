import React, { SVGProps } from "react";

const MediaTypeRadio = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      id="prefix__Radio"
      xmlns="http://www.w3.org/2000/svg"
      width={16.002}
      height={13.989}
      viewBox="0 0 16.002 13.989"
      fill="currentColor"
      {...props}
    >
      <g
        id="prefix__Group_1626"
        data-name="Group 1626"
        transform="translate(2.121 7.066)"
      >
        <path
          id="prefix__Path_1943"
          data-name="Path 1943"
          d="M1595.6 1165.86a.436.436 0 01-.435-.435v-2.275a.435.435 0 11.87 0v2.275a.435.435 0 01-.435.435z"
          transform="translate(-1595.166 -1162.715)"
        />
        <path
          id="prefix__Path_1944"
          data-name="Path 1944"
          d="M1600.825 1165.86a.435.435 0 01-.435-.435v-2.275a.435.435 0 01.87 0v2.275a.435.435 0 01-.435.435z"
          transform="translate(-1598.683 -1162.715)"
        />
        <path
          id="prefix__Path_1945"
          data-name="Path 1945"
          d="M1606.045 1165.86a.435.435 0 01-.435-.435v-2.275a.435.435 0 01.87 0v2.275a.435.435 0 01-.435.435z"
          transform="translate(-1602.198 -1162.715)"
        />
      </g>
      <g
        id="prefix__Group_284"
        data-name="Group 284"
        transform="translate(0 3.288)"
      >
        <path
          id="prefix__Path_1946"
          data-name="Path 1946"
          d="M1601.777 1161.852h-10.213a2.9 2.9 0 01-2.894-2.895v-4.912a2.9 2.9 0 012.894-2.895h10.213a2.9 2.9 0 012.895 2.895v4.912a2.9 2.9 0 01-2.895 2.895zm-10.213-9.831a2.026 2.026 0 00-2.024 2.024v4.912a2.026 2.026 0 002.024 2.024h10.213a2.026 2.026 0 002.024-2.024v-4.912a2.027 2.027 0 00-2.024-2.024z"
          transform="translate(-1588.67 -1151.151)"
        />
      </g>
      <path
        id="prefix__Path_1947"
        data-name="Path 1947"
        d="M1615.372 1164.148a3.186 3.186 0 113.186-3.186 3.189 3.189 0 01-3.186 3.186zm0-5.5a2.316 2.316 0 102.315 2.316 2.318 2.318 0 00-2.315-2.318z"
        transform="translate(-1604.504 -1152.324)"
      />
      <path
        id="prefix__Path_1948"
        data-name="Path 1948"
        d="M1605.662 1145.247a.432.432 0 01-.15-.027l-8.962-3.288a.435.435 0 11.3-.817l8.962 3.288a.435.435 0 01-.15.844z"
        transform="translate(-1593.783 -1141.089)"
      />
    </svg>
  );
};

export default MediaTypeRadio;
