import React from "react";

import { Col, FlexboxGrid } from "rsuite";
import "./index.scss";
import { I18nContext } from "../../provider/I18nProvider";

const NoAccessView = () => {
  const { t } = React.useContext(I18nContext);
  return (
    <FlexboxGrid
      className="noAccessView"
      align="middle"
      justify="center"
      style={{ height: "100vh" }}
    >
      <FlexboxGrid.Item componentClass={Col} colspan={8} xsHidden smHidden>
        <img
          className="noAccessView__image"
          alt="'Page not found'"
          src="/img/art/lookout/lookout.png"
          style={{ maxWidth: "100%" }}
          srcSet="/img/art/lookout/lookout@2x.png 2x, /img/art/lookout/lookout@3x.png 3x"
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col} xs={24} sm={18} md={8} mdPush={2}>
        <h1 className="noAccessView__title">{t("noAccessViewTitle")}</h1>
        <p>{t("noAccessViewBody")}</p>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default NoAccessView;
