import React from "react";
import Tour, { ReactourStep } from "reactour";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { I18nContext } from "../../../provider/I18nProvider";
import { elementHasClassName } from "../../../inc/dom";

const SearchEngineTour = () => {
  const { openTourId, setOpenTourId } = React.useContext(LayoutContext);
  const isSearchEngineTourOpen = openTourId === "searchEngine";
  const { t } = React.useContext(I18nContext);
  const tourRef = React.useRef(null);
  const [updateHack, setUpdateHack] = React.useState(1);

  React.useEffect(() => {
    if (isSearchEngineTourOpen && tourRef.current) {
      disableBodyScroll(tourRef.current as any);
      return;
    }
    clearAllBodyScrollLocks();
  }, [isSearchEngineTourOpen]);

  const steps: ReactourStep[] = React.useMemo(
    () => [
      {
        content: () => (
          <>
            <h3>{t("Zelf zoeken in onze database")}</h3>
            <p>
              {t("Dat kan hier met de zoekopdracht die jij zelf samenstelt")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".searchEngineRequest-bar",
        content: () => (
          <>
            <h3>{t("Stel je zoekopdracht samen")}</h3>
            <p>
              {t("Voer een zoekterm in en specificeer eventueel op mediumtype")}
            </p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".views-search-view__search-request-bar__toolbar__button",
        content: (
          <>
            <h3>{t("Hulptool")}</h3>
            <p>{t("Aan een woord niet genoeg")}</p>
          </>
        ),
        stepInteraction: false,
        action: (node: any) => {
          if (!elementHasClassName(node.parentNode, "--open")) {
            node.click();
            setTimeout(() => {
              setUpdateHack((updateHack) => updateHack + 1);
            }, 500);
          }
        },
      },
      {
        selector: ".views__search-view__widgets__mediaitem-widget",
        content: () => (
          <>
            <h3>{t("Check de resultaten")}</h3>
            <p>{t("Schaf de artikelen aan die je nodig hebt")}</p>
          </>
        ),
        stepInteraction: false,
      },
      {
        selector: ".search-engine-view__col-title",
        content: () => (
          <>
            <h3>{t("Sla je zoekopdracht op")}</h3>
            <p>{t("De perfecte zoekopdracht samengesteld")}</p>
          </>
        ),
        stepInteraction: false,
      },
    ],
    [t],
  );
  return (
    <Tour
      steps={steps}
      isOpen={isSearchEngineTourOpen}
      update={`update_${updateHack}`}
      onRequestClose={() => {
        setOpenTourId(undefined);
      }}
      ref={tourRef}
    />
  );
};

export default SearchEngineTour;
