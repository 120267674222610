import React, { Dispatch, SetStateAction, SVGProps } from "react";
import { LayoutContext } from "../../provider/LayoutProvider";
import { components } from "../../types/openapi";
import { Alert, Loader } from "rsuite";
import "./index.scss";
import { Layout } from "react-grid-layout";
import HightlightWidget from "./HighlightDashboardWidget";
import { v4 } from "uuid";
import MediaTypeWidget from "./MediaTypeDashboardWidget";
import WidgetTypeMediaItem from "../../icons/WidgetTypeMediaItem";
import WidgetTypeHighlight from "../../icons/WidgetTypeHighlight";
import WidgetTypeMedia from "../../icons/WidgetTypeMedia";
import LabelWidget from "./LabelDashboardWidget";
import CategoryWidget from "./CategoryDashboardWidget";
import SearchTopicWidget from "./SearchTopicDashboardWidget";
import SourceWidget from "./SourceDashboardWidget";
import TrendWidget from "./TrendDashboardWidget";
import WidgetTypeSearchTopic from "../../icons/WidgetTypeSearchTopic";
import WidgetTypeAuthor from "../../icons/WidgetTypeAuthor";
import WidgetTypeSource from "../../icons/WidgetTypeSource";
import WidgetTypeTrend from "../../icons/WidgetTypeTrend";
import AuthorWidget from "./AuthorDashboardWidget";
import WidgetTypeCategory from "../../icons/WidgetTypeCategory";
import WidgetTypeLabel from "../../icons/WidgetTypeLabel";
import DragHandle from "../../components/DragHandle";
import { BootstrapSize } from "../../inc/constants";
import { useHistory, useLocation } from "react-router-dom";
import { I18nContext } from "../../provider/I18nProvider";
import useCategories from "../../hooks/useCategories";
import useLabels from "../../hooks/useLabels";
import useSearchTopics from "../../hooks/useSearchTopics";
import { EChartType } from "../../inc/enums";
import ReactGA from "react-ga";
import { useSource } from "../../hooks/useSource";
import MediaitemDashboardWidget, {
  IMediaItemDashboardWidgetSettings,
} from "./MediaitemDashboardWidget";

export enum EDashboardWidgetType {
  WIDGET_TYPE_MEDIAITEM = "WIDGET_TYPE_MEDIAITEM",
  WIDGET_TYPE_HIGHLIGHT = "WIDGET_TYPE_HIGHLIGHT",
  WIDGET_TYPE_TREND = "WIDGET_TYPE_TREND",
  WIDGET_TYPE_MEDIA_TYPE = "WIDGET_TYPE_MEDIA_TYPE",
  WIDGET_TYPE_SOURCE = "WIDGET_TYPE_SOURCE",
  WIDGET_TYPE_CATEGORY = "WIDGET_TYPE_CATEGORY",
  WIDGET_TYPE_AUTHOR = "WIDGET_TYPE_AUTHOR",
  WIDGET_TYPE_LABEL = "WIDGET_TYPE_LABEL",
  WIDGET_TYPE_SEARCH_TOPIC = "WIDGET_TYPE_SEARCH_TOPIC",
}

export enum ESplitType {
  none = "none",
  mediatype = "mediatype",
  sentiment = "sentiment",
  source = "source",
  labels = "labels",
  searchtopic = "searchtopic",
  category = "category",
  "author" = "author",
}

export enum EValueType {
  count = "count",
  summedAve = "summedAve",
  summedReach = "summedReach",
  summedPrValue = "summedPrValue",
}

interface IDashboardWidgetDefinition<T = any> {
  component: (props: IDashboardWidgetProps<any>) => JSX.Element;
  menuIcon: React.ElementType<SVGProps<SVGSVGElement>>;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  defaultW: number;
  defaultH: number;
  widgetConfig: T;
}

export interface IDashboardWidget<T = any> {
  uid: string;
  type: EDashboardWidgetType;
  layouts: {
    xs: Omit<Layout, "i">;
    lg: Omit<Layout, "i">;
  };
  widgetConfig: T;
}

export interface IDashboardHashData {
  filterPeriod?: {
    comparePeriod?: components["schemas"]["Period"];
    filter: components["schemas"]["Filter"];
    period: components["schemas"]["Period"];
  };
  isCopy?: boolean;
  openMediaItemId?: string;
  openMediaItemWidgetId?: string;
}

export const dashboardWidgetDefinitions: {
  [widgetId: string]: IDashboardWidgetDefinition;
} = {
  [EDashboardWidgetType.WIDGET_TYPE_MEDIAITEM]: {
    component: MediaitemDashboardWidget,
    menuIcon: WidgetTypeMediaItem,
    minW: 1,
    maxW: 3,
    minH: 3,
    defaultW: 3,
    defaultH: 8,
    widgetConfig: {
      display: "block",
      sort: "mediaItemSortType_insertDateDesc",
      columnLayout: [],
    },
  } as IDashboardWidgetDefinition<IMediaItemDashboardWidgetSettings>,
  [EDashboardWidgetType.WIDGET_TYPE_HIGHLIGHT]: {
    component: HightlightWidget,
    menuIcon: WidgetTypeHighlight,
    minW: 1,
    maxW: 3,
    minH: 3,
    maxH: 9,
    defaultW: 2,
    defaultH: 4,
    widgetConfig: {},
  },
  [EDashboardWidgetType.WIDGET_TYPE_MEDIA_TYPE]: {
    component: MediaTypeWidget,
    menuIcon: WidgetTypeMedia,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      showValueTypeInTitle: false,
    },
  },
  [EDashboardWidgetType.WIDGET_TYPE_LABEL]: {
    component: LabelWidget,
    menuIcon: WidgetTypeLabel,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  } as IDashboardWidgetDefinition,
  [EDashboardWidgetType.WIDGET_TYPE_CATEGORY]: {
    component: CategoryWidget,
    menuIcon: WidgetTypeCategory,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
  [EDashboardWidgetType.WIDGET_TYPE_SEARCH_TOPIC]: {
    component: SearchTopicWidget,
    menuIcon: WidgetTypeSearchTopic,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
  [EDashboardWidgetType.WIDGET_TYPE_SOURCE]: {
    component: SourceWidget,
    menuIcon: WidgetTypeSource,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
  [EDashboardWidgetType.WIDGET_TYPE_AUTHOR]: {
    component: AuthorWidget,
    menuIcon: WidgetTypeAuthor,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 5,
    widgetConfig: {
      valueType: EValueType.count,
      chartType: EChartType.donut,
      limit: 10,
      showRemaining: false,
      showValueTypeInTitle: false,
    },
  },
  [EDashboardWidgetType.WIDGET_TYPE_TREND]: {
    component: TrendWidget,
    menuIcon: WidgetTypeTrend,
    minW: 1,
    maxW: 3,
    minH: 5,
    defaultW: 1,
    defaultH: 8,
    widgetConfig: {
      chartType: EChartType.curved,
      valueType: EValueType.count,
      showValueTypeInTitle: false,
      limit: 5,
      showRemaining: false,
    },
  },
};

export const newDashboardWidget = (
  type: EDashboardWidgetType,
): IDashboardWidget => {
  const widgetType = dashboardWidgetDefinitions[type];
  return {
    ...widgetType,
    type,
    uid: v4(),
    layouts: {
      xs: {
        x: 0,
        y: 0,
        w: widgetType.defaultW,
        h: widgetType.defaultH,
      },
      lg: {
        x: 0,
        y: 0,
        w: widgetType.defaultW,
        h: widgetType.defaultH,
      },
    },
  };
};

export interface IDashboardWidgetProps<Settings> {
  colWidth: number;
  compareFilterCacheResponse?:
    | components["schemas"]["FilterCacheResponse"]
    | null;
  comparePeriod?: components["schemas"]["Period"];
  filter?: components["schemas"]["Filter"];
  filterCacheResponse?: components["schemas"]["FilterCacheResponse"] | null;
  height: number;
  onDelete?: () => void;
  onFilterChange?: (filter: Partial<components["schemas"]["Filter"]>) => void;
  onSettingsChange?: (widgetUid: string, newSettings: Settings) => void;
  onSettingsToggle?: (isOpen: boolean) => void;
  period?: components["schemas"]["Period"];
  setFilterCacheResponse?: Dispatch<
    SetStateAction<components["schemas"]["FilterCacheResponse"]>
  >;
  settings?: Settings;
  uid: string;
  width: number;
}

interface IDashboardWidgetContainerProps {
  children?: React.ReactNode;
  className?: string;
  compareFilterCacheResponse?:
    | components["schemas"]["FilterCacheResponse"]
    | null;
  comparePeriod?: components["schemas"]["Period"];
  dashboardWidth: number;
  disabled?: boolean;
  filter?: components["schemas"]["Filter"];
  filterCacheResponse?: components["schemas"]["FilterCacheResponse"] | null;
  onDelete: () => void;
  onSettingsChange: (widgetUid: string, newSettings: any) => void;
  period: components["schemas"]["Period"];
  setFilterCacheResponse: Dispatch<
    SetStateAction<
      components["schemas"]["FilterCacheResponse"] | null | undefined
    >
  >;
  style?: React.CSSProperties;
  widget: IDashboardWidget;
}

export const DashboardWidgetContainer = React.forwardRef(
  (
    props: IDashboardWidgetContainerProps,
    ref: React.LegacyRef<HTMLDivElement>,
  ) => {
    const {
      compareFilterCacheResponse,
      comparePeriod,
      dashboardWidth,
      disabled,
      filter,
      filterCacheResponse,
      onDelete,
      onSettingsChange,
      period,
      setFilterCacheResponse,
      widget,
      ...divProps
    } = props;
    const { t } = React.useContext(I18nContext);
    const { windowOuterWidth } = React.useContext(LayoutContext);

    const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);

    const history = useHistory();
    const location = useLocation();
    const { getSource } = useSource();
    const categories = useCategories();
    const labels = useLabels();
    const searchTopics = useSearchTopics();

    React.useEffect(() => {
      if (isSettingsOpen) {
        ReactGA.event({
          category: "Widgets",
          action: `Settings geopend`,
        });
      }
    }, [isSettingsOpen]);

    const getAlertMessage = React.useCallback(
      async ({
        filterUpdate,
        periodUpdate,
      }: {
        filterUpdate?: Partial<components["schemas"]["Filter"]>;
        periodUpdate?: components["schemas"]["Period"];
      }) => {
        if (periodUpdate) {
          return t("filterDateChanged");
        }

        if (!filterUpdate) {
          console.log("Got strange alert message");
          return t("filterHasBeenUpdated");
        }

        if (filterUpdate.includeLabelIds && labels) {
          return t("labelAddedToFilter", [
            labels[filterUpdate.includeLabelIds[0][0]].name,
          ]);
        }
        if (filterUpdate.categoryIds && categories) {
          return t("categoryAddedToFilter", [
            categories[filterUpdate.categoryIds[0]].name,
          ]);
        }
        if (filterUpdate.searchTopicIds && searchTopics) {
          let searchTopicName =
            searchTopics[filterUpdate.searchTopicIds[0]].name;
          if (filterUpdate.mediaTypes) {
            searchTopicName += ` + ${t("MediaType")} ${t(
              `mediaType_${filterUpdate.mediaTypes[0]}`,
            )}`;
          }
          return t("searchTopicAddedToFilter", [searchTopicName]);
        }
        if (filterUpdate.mediaTypes) {
          return t("mediaTypeAddedToFilter", [filterUpdate.mediaTypes[0]]);
        }
        if (filterUpdate.sourceIds?.length) {
          const sourceId = filterUpdate.sourceIds[0];
          if (!sourceId) {
            return t("filterHasBeenUpdated");
          }
          const source = await getSource(sourceId);
          return source
            ? t("sourceAddedToFilter", [source.name])
            : t("filterHasBeenUpdated");
        }
        return t("filterHasBeenUpdated");
      },
      [categories, getSource, labels, searchTopics, t],
    );

    const onFilterChange = React.useCallback(
      async (filterUpdate: Partial<components["schemas"]["Filter"]>) => {
        if (!filter || !period) {
          return;
        }
        let newFilter: components["schemas"]["Filter"];
        Alert.info(await getAlertMessage({ filterUpdate }));
        if (filterUpdate.includeLabelIds && filter.includeLabelIds?.length) {
          const toBeAddedLabelId = filterUpdate.includeLabelIds[0][0];
          newFilter = {
            ...filter,
            includeLabelIds: filter.includeLabelIds.map((labelPair) =>
              labelPair.indexOf(toBeAddedLabelId) >= 0
                ? labelPair
                : [...labelPair, toBeAddedLabelId],
            ),
          };
        } else {
          newFilter = { ...filter, ...filterUpdate };
        }
        history.push(
          `${location.pathname}#${encodeURIComponent(
            JSON.stringify({
              filterPeriod: {
                filter: newFilter,
                period: period,
              },
            } as IDashboardHashData),
          )}`,
        );
      },
      [filter, getAlertMessage, history, location.pathname, period],
    );

    const widgetDefinition = dashboardWidgetDefinitions[widget.type];
    let width = -1;
    if (props.style?.width) {
      width = parseInt(`${props.style.width}`, 10);
      if (width > dashboardWidth - 20) {
        props.style.width = `${dashboardWidth - 20}px`;
      }
    }
    let height = -1;
    if (props.style?.height) {
      height = parseInt(`${props.style.height}`, 10) - 23;
    }

    const colWidth = React.useMemo(
      () =>
        windowOuterWidth >= BootstrapSize.LG
          ? Math.ceil((width / windowOuterWidth) * 3)
          : 1,
      [width, windowOuterWidth],
    );
    return (
      <div
        {...divProps}
        className={`${divProps.className} widget ${
          isSettingsOpen ? "widget--open-settings" : ""
        }`}
        ref={ref}
      >
        {filterCacheResponse && widgetDefinition ? (
          <>
            <DragHandle />
            <widgetDefinition.component
              {...widget.widgetConfig}
              colWidth={colWidth}
              compareFilterCacheResponse={compareFilterCacheResponse}
              comparePeriod={comparePeriod}
              filter={filter}
              filterCacheResponse={filterCacheResponse}
              height={height}
              onDelete={disabled ? undefined : onDelete}
              onFilterChange={onFilterChange}
              onSettingsChange={disabled ? undefined : onSettingsChange}
              onSettingsToggle={setIsSettingsOpen}
              period={period}
              setFilterCacheResponse={setFilterCacheResponse}
              settings={widget.widgetConfig}
              uid={widget.uid}
              width={width}
            />
          </>
        ) : (
          <Loader size="md" backdrop vertical />
        )}
        {/* <!-- The drag handle --> */}
        {props.children}
      </div>
    );
  },
);
