const clonedSvg2canvas = (svg: SVGElement) =>
  new Promise<HTMLCanvasElement>((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const loader = new Image();
    loader.onload = () => {
      if (!ctx) {
        reject(new Error("Missing context"));
        return;
      }
      canvas.width = loader.width * 2;
      canvas.height = loader.height * 2;
      ctx.beginPath();
      ctx.rect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white";
      ctx.fill();
      ctx.drawImage(loader, 0, 0, canvas.width, canvas.height);
      resolve(canvas);
    };

    // y-axis should remain in-bounds
    const clonedSvg = svg.cloneNode(true) as SVGElement;
    const yAxis = clonedSvg.querySelector(".apexcharts-yaxis") as SVGGElement;
    const isTrendChart = !!clonedSvg.querySelector(".apexcharts-line-series");
    if (isTrendChart && yAxis) {
      // axis value of trend graph are cropped of png image
      yAxis.transform.baseVal.getItem(0).matrix.e += 10;
    }

    // hide uglyness: no hover stuff
    clonedSvg
      .querySelectorAll(".apexcharts-xcrosshairs, .apexcharts-ycrosshairs")
      .forEach((uglyness) => {
        uglyness.remove();
      });

    // Apply all CSS styling to elements, since the PNG image is out of css scope
    clonedSvg
      .querySelectorAll(".apexcharts-legend,.apexcharts-legend *")
      .forEach((cssStyledElement) => {
        const newStyle = (cssStyledElement as HTMLDivElement | HTMLSpanElement)
          .style;
        // apply external CSS styling to span elements
        const oldStyle = getComputedStyle(cssStyledElement);
        for (let i = 0; i < oldStyle.length; i++) {
          const prop = oldStyle[i].replace(/-([a-z])/g, (v) =>
            v[1].toUpperCase()
          ) as "fontSize";
          newStyle[prop] = oldStyle[prop];
        }

        // legend items are cropped?!
        if (cssStyledElement.className !== "apexcharts-legend-marker") {
          newStyle.width = "auto";
        }
      });
    loader.src =
      "data:image/svg+xml," +
      encodeURIComponent(new XMLSerializer().serializeToString(clonedSvg));
  });

export default clonedSvg2canvas;
