import React from "react";
import {
  Alert,
  Button,
  Checkbox,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  Popover,
  Progress,
} from "rsuite";
import { ApiDataContext } from "../../../../provider/ApiDataProvider";
import { components } from "../../../../types/openapi";
import "./index.scss";
import axios from "../../../../inc/axios";
import _ from "lodash";
import { IHashMap } from "../../../../inc/data";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../../inc/schema";
import { TSchemaFormErrors } from "../../../../components/SchemaFormBody";
import ajv from "../../../../inc/ajv";
import RubricsTab from "./RubricsTab";
import PreviewTab from "./PreviewTab";
import { WhisperInstance } from "rsuite/lib/Whisper";
import { findDOMNode } from "react-dom";
import { I18nContext } from "../../../../provider/I18nProvider";
import useEditorialRubrics from "../../../../hooks/useEditorialRubrics";
import useEditorials from "../../../../hooks/useEditorials";
import useEditorialIssues from "../../../../hooks/useEditorialIssues";
import useEditorialRubricMediaItems from "../../../../hooks/useEditorialRubricMediaItems";
import MwWhisper from "../../../../components/MwWhisper";
import useEditorialSubscribers from "../../../../hooks/useEditorialSubscribers";

const EditorialIssueSchema = openapi.components.schemas
  .EditorialIssue as oas30.SchemaObject;
const validate = ajv.compile(EditorialIssueSchema);

const { properties } = EditorialIssueSchema;
if (!properties) {
  throw new Error("Incomplete editorial issue schema");
}

interface IEditorialIssueCreateModalProps {
  editorialId: string;
  onClose: () => void;
}

export interface IEditorialRubricMap {
  editorialRubricId: string;
  mediaItemIds: string[];
}

const debouncedSync = _.debounce(
  (
    editorialRubricMaps: IEditorialRubricMap[],
    editorialRubricMediaItemMap: IHashMap<
      components["schemas"]["EditorialRubricMediaItem"]
    >,
    setEditorialRubricMediaItems: (
      editorialRubricMediaItemMap: IHashMap<
        components["schemas"]["EditorialRubricMediaItem"]
      >,
    ) => void,
    setHydrationPercentage: (hydrationPercentage: number) => void,
  ): Promise<any> => {
    if (!editorialRubricMediaItemMap) {
      return Promise.resolve();
    }
    const newEditorialRubricMediaItemMap = { ...editorialRubricMediaItemMap };
    const editorialRubricMediaItems = Object.values(
      newEditorialRubricMediaItemMap,
    );
    let finishedRequestCount = 0;
    const syncPromises: Promise<any>[] = [];
    editorialRubricMaps.forEach((editorialRubricMap) => {
      const { editorialRubricId, mediaItemIds } = editorialRubricMap;
      const existingMediaItemIds = editorialRubricMediaItems
        .filter(
          (editorialRubricMediaItem) =>
            editorialRubricMediaItem.editorialRubricId ===
            editorialRubricMap.editorialRubricId,
        )
        .map(
          (editorialRubricMediaItem) => editorialRubricMediaItem.mediaItemId,
        );
      const toBeAddedMediaItemIds = _.difference(
        mediaItemIds,
        existingMediaItemIds,
      );
      const toBeRemovedMediaItemIds = _.difference(
        existingMediaItemIds,
        mediaItemIds,
      );
      const toBeCheckedMediaItemIds = _.difference(
        existingMediaItemIds,
        toBeRemovedMediaItemIds,
      );

      syncPromises.push(
        ...toBeAddedMediaItemIds.map((toBeAddedMediaItemId) =>
          axios
            .request<components["schemas"]["EditorialRubricMediaItem"]>({
              method: "post",
              url: `/editorialRubricMediaItem/crud`,
              data: {
                editorialRubricId,
                mediaItemId: toBeAddedMediaItemId,
                order: mediaItemIds.findIndex(
                  (mediaItemId) => mediaItemId === toBeAddedMediaItemId,
                ),
              },
            })
            .then((res) => {
              newEditorialRubricMediaItemMap[
                res.data.editorialRubricMediaItemId as string
              ] = res.data;
            }),
        ),
        ...toBeRemovedMediaItemIds.map((toBeRemovedMediaItemId) => {
          const toBeRemovedEditorialRubricMediaItem =
            editorialRubricMediaItems.find(
              (editorialRubricMediaItem) =>
                editorialRubricMediaItem.mediaItemId ===
                  toBeRemovedMediaItemId &&
                editorialRubricMediaItem.editorialRubricId ===
                  editorialRubricId,
            );
          if (!toBeRemovedEditorialRubricMediaItem) {
            throw new Error(
              "Cannot find to be synced EditorialRubricMediaItem",
            );
          }
          return axios
            .request<components["schemas"]["EditorialRubricMediaItem"]>({
              method: "delete",
              url: `/editorialRubricMediaItem/crud/${toBeRemovedEditorialRubricMediaItem.editorialRubricMediaItemId}`,
            })
            .then(() => {
              delete newEditorialRubricMediaItemMap[
                toBeRemovedEditorialRubricMediaItem.editorialRubricMediaItemId as string
              ];
            });
        }),
      );
      toBeCheckedMediaItemIds.forEach((toBeCheckedMediaItemId) => {
        const toBeCheckedEditorialRubricMediaItem =
          editorialRubricMediaItems.find(
            (editorialRubricMediaItem) =>
              editorialRubricMediaItem.mediaItemId === toBeCheckedMediaItemId &&
              editorialRubricMediaItem.editorialRubricId === editorialRubricId,
          );
        if (!toBeCheckedEditorialRubricMediaItem) {
          throw new Error("Cannot find to be synced EditorialRubricMediaItem");
        }
        const order = mediaItemIds.findIndex(
          (mediaItemId) => mediaItemId === toBeCheckedMediaItemId,
        );
        if (toBeCheckedEditorialRubricMediaItem.order !== order) {
          syncPromises.push(
            axios
              .request<components["schemas"]["EditorialRubricMediaItem"]>({
                method: "put",
                url: `/editorialRubricMediaItem/crud/${toBeCheckedEditorialRubricMediaItem.editorialRubricMediaItemId}`,
                data: {
                  ...toBeCheckedEditorialRubricMediaItem,
                  order,
                },
              })
              .then((res) => {
                setHydrationPercentage(
                  Math.round(
                    (++finishedRequestCount / syncPromises.length) * 100,
                  ),
                );
                newEditorialRubricMediaItemMap[
                  res.data.editorialRubricMediaItemId as string
                ] = res.data;
              }),
          );
        }
      });
    });
    return syncPromises.length
      ? Promise.all(syncPromises).then(() => {
          setHydrationPercentage(100);
          setEditorialRubricMediaItems(newEditorialRubricMediaItemMap);
        })
      : Promise.resolve();
  },
  1000,
);

const EditorialIssueCreateModal = (props: IEditorialIssueCreateModalProps) => {
  const { editorialId, onClose } = props;
  const { t } = React.useContext(I18nContext);
  const [preview, setPreview] = React.useState<
    components["schemas"]["EditorialPreview"] | null
  >();
  const [isSendPopoverOpen, setIsSendPopoverOpen] =
    React.useState<boolean>(false);
  const [previewFetchedForEditorialIssue, setPreviewFetchedForEditorialIssue] =
    React.useState<components["schemas"]["EditorialIssue"]>();
  const [mediaItems, setMediaItems] = React.useState<
    IHashMap<components["schemas"]["MediaItem"] | null>
  >({});
  const [editorialMediaItems, setEditorialMediaItems] = React.useState<
    IHashMap<components["schemas"]["EditorialMediaItem"] | null>
  >({});
  const [editorialIssue, setEditorialIssue] = React.useState<
    components["schemas"]["EditorialIssue"]
  >(getEmptyObject(EditorialIssueSchema));
  const [activeTab, setActiveTab] = React.useState<"rubrics" | "preview">(
    "rubrics",
  );
  const [errors, setErrors] = React.useState<
    TSchemaFormErrors<
      components["schemas"]["EditorialIssue"] & { testEmailaddress: string }
    >
  >({});
  const [testEmailaddress, setTestEmailaddress] = React.useState<string>("");
  const [hydrationPercentage, setHydrationPercentage] =
    React.useState<number>(100);

  const testPopoverTriggerRef = React.useRef<WhisperInstance>();
  const modalBodyRef = React.useRef(null);
  const sendPopoverRef = React.useRef(null);

  const { setEditorialIssues, setEditorialRubricMediaItems } =
    React.useContext(ApiDataContext);
  const editorials = useEditorials();
  const editorialRubrics = useEditorialRubrics();
  const editorialRubricMediaItems = useEditorialRubricMediaItems();
  const editorialIssues = useEditorialIssues();
  const editorialSubscribers = useEditorialSubscribers();

  const subscriberCount = React.useMemo(
    () =>
      editorialSubscribers
        ? Object.values(editorialSubscribers).filter(
            (subscriber) =>
              subscriber.editorialId === editorialId &&
              (subscriber.useApp || subscriber.useMail),
          ).length
        : 0,
    [editorialId, editorialSubscribers],
  );

  const editorialRubricMediaItemss = React.useMemo(
    () =>
      editorialRubricMediaItems ? Object.values(editorialRubricMediaItems) : [],
    [editorialRubricMediaItems],
  );
  const [editorialRubricMaps, setEditorialRubricMaps] =
    React.useState<IEditorialRubricMap[]>();

  const getPreview = React.useCallback(
    (editorialIssue: components["schemas"]["EditorialIssue"]) => {
      if (
        preview === null ||
        _.isEqual(previewFetchedForEditorialIssue, editorialIssue)
      ) {
        return;
      }

      setPreview(null);
      axios.post("/editorialIssue/preview", editorialIssue).then((res) => {
        setPreviewFetchedForEditorialIssue(editorialIssue);
        setPreview(res.data);
      });
    },
    [preview, previewFetchedForEditorialIssue],
  );

  React.useEffect(() => {
    if (!editorialRubricMaps || !editorialRubricMediaItems) {
      return;
    }
    debouncedSync(
      editorialRubricMaps,
      editorialRubricMediaItems,
      setEditorialRubricMediaItems,
      setHydrationPercentage,
    );
  }, [
    editorialRubricMaps,
    editorialRubricMediaItems,
    setEditorialRubricMediaItems,
  ]);

  const editorialEditorialRubrics = React.useMemo(
    () =>
      editorialRubrics
        ? Object.values(editorialRubrics)
            .filter(
              (editorialRubric) => editorialRubric.editorialId === editorialId,
            )
            .sort((rubricA, rubricB) => rubricA.order - rubricB.order)
        : undefined,
    [editorialId, editorialRubrics],
  );

  React.useEffect(() => {
    if (
      editorialRubricMaps ||
      !editorialEditorialRubrics ||
      !editorialRubricMediaItems
    ) {
      return;
    }
    let totalRequestCount = 0;
    let finishedRequestCount = 0;
    setEditorialRubricMaps(
      editorialEditorialRubrics.map<IEditorialRubricMap>((editorialRubric) => {
        return {
          editorialRubricId: editorialRubric.editorialRubricId as string,
          mediaItemIds: editorialRubricMediaItemss
            .filter(
              (editorialRubricMediaItem) =>
                editorialRubricMediaItem.editorialRubricId ===
                editorialRubric.editorialRubricId,
            )
            .sort(
              (editorialRubricMediaItemA, editorialRubricMediaItemB) =>
                editorialRubricMediaItemA.order -
                editorialRubricMediaItemB.order,
            )
            .map((editorialRubricMediaItem) => {
              const { mediaItemId } = editorialRubricMediaItem;
              if (editorialMediaItems[mediaItemId] === undefined) {
                setMediaItems((mediaItems) => ({
                  ...mediaItems,
                  [mediaItemId]: null,
                }));
                setEditorialMediaItems((editorialMediaItems) => ({
                  ...editorialMediaItems,
                  [mediaItemId]: null,
                }));
                totalRequestCount += 2;
                axios
                  .get<components["schemas"]["MediaItem"]>(
                    `/mediaItem/crud/${mediaItemId}`,
                  )
                  .then((res) => {
                    setHydrationPercentage(
                      Math.round(
                        (++finishedRequestCount / totalRequestCount) * 100,
                      ),
                    );
                    setMediaItems((mediaItems) => ({
                      ...mediaItems,
                      [mediaItemId]: res.data,
                    }));
                  })
                  .catch((err) => {
                    setHydrationPercentage(
                      Math.round(
                        (++finishedRequestCount / totalRequestCount) * 100,
                      ),
                    );
                    Alert.error(
                      t(err.response?.data.error || "genericErrorMessage"),
                    );
                  });
                axios
                  .get<components["schemas"]["EditorialMediaItem"]>(
                    `/editorialMediaItem/crud/${mediaItemId}`,
                  )
                  .then((res) => {
                    setHydrationPercentage(
                      Math.round(
                        (++finishedRequestCount / totalRequestCount) * 100,
                      ),
                    );
                    setEditorialMediaItems((editorialMediaItems) => ({
                      ...editorialMediaItems,
                      [mediaItemId]: res.data,
                    }));
                  })
                  .catch((err) => {
                    setHydrationPercentage(
                      Math.round(
                        (++finishedRequestCount / totalRequestCount) * 100,
                      ),
                    );
                    Alert.error(
                      t(err.response?.data.error || "genericErrorMessage"),
                    );
                  });
              }
              return mediaItemId;
            }),
        };
      }),
    );
  }, [
    editorialEditorialRubrics,
    editorialMediaItems,
    editorialRubricMaps,
    editorialRubricMediaItems,
    editorialRubricMediaItemss,
    t,
  ]);

  React.useEffect(() => {
    setEditorialIssue((editorialIssue) => {
      const newEditorialIssue = { ...editorialIssue };
      newEditorialIssue.editorialId = editorialId as string;
      newEditorialIssue.editorialRubricMediaItemIds = editorialRubricMaps
        ? editorialRubricMaps.reduce((carry: string[], editorialRubricMap) => {
            return [
              ...carry,
              ...editorialRubricMap.mediaItemIds.map(
                (mediaItemId) =>
                  (
                    editorialRubricMediaItemss.find(
                      (editorialRubricMediaItem) =>
                        editorialRubricMediaItem.mediaItemId === mediaItemId &&
                        editorialRubricMediaItem.editorialRubricId ===
                          editorialRubricMap.editorialRubricId,
                    ) as components["schemas"]["EditorialRubricMediaItem"]
                  )?.editorialRubricMediaItemId as string,
              ),
            ];
          }, [])
        : [];

      const editorial = editorials
        ? editorials[editorialId as string]
        : undefined;
      if (editorial?.defaultIntro && !newEditorialIssue.intro) {
        newEditorialIssue.intro = editorial.defaultIntro;
      }
      if (editorial?.defaultSenderName && !newEditorialIssue.senderName) {
        newEditorialIssue.senderName = editorial.defaultSenderName;
      }
      if (editorial?.defaultSubject && !newEditorialIssue.subject) {
        newEditorialIssue.subject = editorial.defaultSubject;
      }
      return newEditorialIssue;
    });
  }, [
    editorialId,
    editorialRubricMaps,
    editorialRubricMediaItemss,
    editorials,
  ]);

  const validateEditorialIssue = React.useCallback(
    (editorialIssue: components["schemas"]["EditorialIssue"]): boolean => {
      validate(editorialIssue);
      const errors = validate.errors || [];

      if (
        editorialIssue.senderName &&
        editorialIssue.senderName.includes("@")
      ) {
        errors.push({
          instancePath: ".senderName",
          keyword: "custom",
          message: "atNotAllowed",
          schemaPath: "#/properties/senderName",
          params: {},
        });
      }

      if (errors && errors.length) {
        console.log(errors);
        setErrors(validationErrorsToSchemaFormErrors(errors));
        setTimeout(focusError, 200);
      }
      return errors.length < 1;
    },
    [],
  );

  const onTest = React.useCallback(() => {
    if (testEmailaddress === "" || !/\S+@\S+\.\S+/.test(testEmailaddress)) {
      setErrors({ ...errors, testEmailaddress: "invalid email format" });
      return;
    }
    axios
      .post<components["schemas"]["EditorialIssue"]>(
        "/editorialIssue/test",
        editorialIssue,
        { params: { testEmailAddress: testEmailaddress } },
      )
      .then(() => {
        Alert.info(t("testMailHasBeenSend"));
        if (testPopoverTriggerRef.current) {
          testPopoverTriggerRef.current.close();
        }
      });
  }, [editorialIssue, errors, setErrors, t, testEmailaddress]);

  const goToPreviewTab = React.useCallback(
    (editorialIssue: components["schemas"]["EditorialIssue"]) => {
      if (validateEditorialIssue(editorialIssue)) {
        getPreview(editorialIssue);
        setActiveTab("preview");
        return;
      }
    },
    [getPreview, validateEditorialIssue],
  );

  const hasEditorialRubricMediaItems = React.useMemo(
    () =>
      !!editorialRubricMaps?.find(
        (editorialRubricMap) => editorialRubricMap.mediaItemIds.length,
      ),
    [editorialRubricMaps],
  );

  React.useEffect(() => {
    const handleClickOutsidePopover = (event: any) => {
      const popover = findDOMNode(sendPopoverRef.current);
      const popoverTrigger = document.querySelector(
        ".editorial-issue-create-modal__schedule-sending",
      );
      const scheduleDatePicker = document.querySelector(
        ".editorial-issue-create-modal__schedule-sending__date-picker",
      );
      if (
        !popover ||
        popover.contains(event.target) ||
        popoverTrigger?.contains(event.target) ||
        scheduleDatePicker?.contains(event.target)
      ) {
        return;
      }
      setIsSendPopoverOpen(false);
    };
    document.addEventListener("mousedown", handleClickOutsidePopover);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsidePopover);
    };
  }, []);

  const submit = React.useCallback(() => {
    if (!validateEditorialIssue(editorialIssue)) {
      return;
    }
    setErrors({});
    axios
      .post<components["schemas"]["EditorialIssue"]>(
        "/editorialIssue/send",
        editorialIssue,
      )
      .then((res) => {
        const updatedEditorialIssue = res.data;
        setEditorialIssues({
          ...editorialIssues,
          [updatedEditorialIssue.editorialIssueId as string]:
            updatedEditorialIssue,
        });
        setEditorialRubricMediaItems(undefined);
        onClose();
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [
    editorialIssue,
    editorialIssues,
    onClose,
    setEditorialIssues,
    setEditorialRubricMediaItems,
    t,
    validateEditorialIssue,
  ]);

  const stepBack = React.useCallback(() => {
    switch (activeTab) {
      case "rubrics":
        onClose();
        break;

      case "preview":
        setActiveTab("rubrics");
        break;
    }
  }, [activeTab, onClose]);

  const modalHeaderContent = React.useMemo(() => {
    let superTitle: string;
    let actions: React.ReactElement | null = null;
    switch (activeTab) {
      case "rubrics":
        superTitle = t("makeUp");
        actions = (
          <Button
            onClick={() => goToPreviewTab(editorialIssue)}
            disabled={!hasEditorialRubricMediaItems}
            appearance="primary"
            color="blue"
          >
            {t("goToSend")}
          </Button>
        );
        break;
      case "preview":
        superTitle = t("send");
        actions = (
          <>
            <div style={{ flex: 1 }}>
              <MwWhisper
                placement="bottom"
                trigger="active"
                triggerRef={testPopoverTriggerRef}
                speaker={
                  <Popover>
                    <Form>
                      <FormGroup>
                        <ControlLabel className="label">
                          E-mailadres
                        </ControlLabel>
                        <FormControl
                          className="input input-gray"
                          onChange={setTestEmailaddress}
                          size="sm"
                          value={testEmailaddress}
                        />
                        {errors && errors["testEmailaddress"] ? (
                          <HelpBlock style={{ color: "red" }}>
                            {t(errors["testEmailaddress"])}
                          </HelpBlock>
                        ) : null}
                      </FormGroup>
                      <Button
                        appearance="primary"
                        color="green"
                        onClick={onTest}
                      >
                        {t("sendEditorialIssueTest")}
                      </Button>
                    </Form>
                  </Popover>
                }
              >
                <Button appearance="ghost" color="blue">
                  {t("sendTestMail")}
                </Button>
              </MwWhisper>
            </div>
            <div className="d-flex">
              <Checkbox
                className="d-inline-block me-3"
                checked={editorialIssue.scheduledDatetime !== undefined}
                onChange={(_: unknown, checked) =>
                  setEditorialIssue({
                    ...editorialIssue,
                    scheduledDatetime: checked ? "" : undefined,
                  })
                }
              >
                {t("sendScheduled")}
              </Checkbox>
              {editorialIssue.scheduledDatetime !== undefined ? (
                <MwWhisper
                  trigger="none"
                  placement="bottomEnd"
                  open={isSendPopoverOpen}
                  speaker={
                    <Popover
                      className="popover-without-arrow"
                      ref={sendPopoverRef}
                    >
                      <Form>
                        <FormGroup>
                          <ControlLabel className="label">
                            {t("editorialIssueSchedule")}
                          </ControlLabel>
                          <DatePicker
                            menuClassName="editorial-issue-create-modal__schedule-sending__date-picker"
                            block
                            format={"DD-MM-YYYY HH:mm"}
                            isoWeek
                            ranges={[]}
                            cleanable={false}
                            preventOverflow
                            onChange={(newScheduledDatetime) =>
                              setEditorialIssue({
                                ...editorialIssue,
                                scheduledDatetime:
                                  newScheduledDatetime.toISOString(),
                              })
                            }
                            value={
                              editorialIssue?.scheduledDatetime
                                ? new Date(editorialIssue?.scheduledDatetime)
                                : undefined
                            }
                          />
                          {errors && errors["scheduledDatetime"] ? (
                            <HelpBlock style={{ color: "red" }}>
                              {t(errors["scheduledDatetime"])}
                            </HelpBlock>
                          ) : null}
                        </FormGroup>
                        <Button appearance="primary" onClick={submit}>
                          {t("scheduleEditorialIssue")}
                        </Button>
                      </Form>
                    </Popover>
                  }
                >
                  <Button
                    appearance="primary"
                    color="blue"
                    className="editorial-issue-create-modal__schedule-sending"
                    onClick={() =>
                      setIsSendPopoverOpen(
                        (isSendPopoverOpen) => !isSendPopoverOpen,
                      )
                    }
                  >
                    {t("scheduleSending")}
                  </Button>
                </MwWhisper>
              ) : (
                <Button
                  onClick={submit}
                  appearance="primary"
                  color="blue"
                  disabled={!subscriberCount}
                  title={
                    subscriberCount
                      ? undefined
                      : t("editorial-issue-create-modal__disabled-title")
                  }
                >
                  {t("sendDirect")} ({subscriberCount})
                </Button>
              )}
            </div>
          </>
        );
        break;
    }
    return (
      <div
        className="editorial-issue-create-modal__header"
        style={{ flex: 1, overflow: "hidden" }}
      >
        <Modal.Title className="editorial-issue-create-modal__title">
          <div className="modal-super-title">{superTitle}</div>
          {editorials ? editorials[editorialId as string].name : null}
        </Modal.Title>
        <div className="editorial-issue-create-modal__actions">{actions}</div>
      </div>
    );
  }, [
    activeTab,
    editorialId,
    editorialIssue,
    editorials,
    errors,
    goToPreviewTab,
    hasEditorialRubricMediaItems,
    isSendPopoverOpen,
    onTest,
    submit,
    subscriberCount,
    t,
    testEmailaddress,
  ]);

  const modalContent = React.useMemo(() => {
    switch (activeTab) {
      case "rubrics":
        return (
          <RubricsTab
            errors={errors}
            editorialMediaItems={editorialMediaItems}
            mediaItems={mediaItems}
            setEditorialMediaItems={setEditorialMediaItems}
            editorialEditorialRubrics={editorialEditorialRubrics}
            editorialIssue={editorialIssue}
            editorialRubricMaps={editorialRubricMaps}
            setEditorialRubricMaps={setEditorialRubricMaps}
            onChange={setEditorialIssue}
            goToPreviewTab={goToPreviewTab}
            hasEditorialRubricMediaItems={hasEditorialRubricMediaItems}
            container={findDOMNode(modalBodyRef.current) as Element}
          />
        );
      case "preview":
        return <PreviewTab preview={preview} />;
    }
  }, [
    activeTab,
    editorialEditorialRubrics,
    editorialIssue,
    editorialMediaItems,
    editorialRubricMaps,
    errors,
    goToPreviewTab,
    hasEditorialRubricMediaItems,
    mediaItems,
    preview,
  ]);

  return (
    <Modal
      show={true}
      onHide={stepBack}
      className="editorial-issue-create-modal modal-size-auto"
    >
      <Modal.Header>{modalHeaderContent}</Modal.Header>
      <Modal.Body style={{ maxHeight: undefined }} ref={modalBodyRef}>
        <div className="editorial-issue-create-modal__content">
          {modalContent}
        </div>
      </Modal.Body>
      {hydrationPercentage < 100 ? (
        <div
          style={{
            background: "rgba(255,255,255,0.9)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <div>
            <h3>{t("oneMomentPlease")}</h3>
            <div style={{ width: 200, height: 200, margin: "30px auto" }}>
              <Progress.Circle percent={hydrationPercentage} />
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
};
export default EditorialIssueCreateModal;
