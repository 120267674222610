import React from "react";
import axios from "../inc/axios";
import { TUser } from "../types";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { AuthContext } from "../provider/AuthProvider";

const useMes = singletonHook(undefined, () => {
  const { mes, setMes } = React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (mes !== undefined || !auth?.bearer) {
      return;
    }
    setMes(null);

    axios.get<TUser[]>("/user/me").then((res) => {
      const users = res.data.map((user) => ({
        ...user,
        settings: user.settings || {},
      }));
      setMes(users);
    });
  }, [auth?.bearer, auth?.jwt.userRoles, setMes, mes]);

  return mes;
});

export default useMes;
