import React, { SVGProps } from "react";

function PrDashboard(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={41}
      height={41}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      {...props}
    >
      <mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={0}
        y={18}
        width={41}
        height={23}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 18.929h40.964v22.036H0Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m19.88 31.511-2.346 6.53H9.962l.389-1.077h-.928l4.332-12.016C8.549 24.015 3.84 21.995 0 19.193v21.771h35.047a5.914 5.914 0 0 0 5.918-5.91V18.93a30.662 30.662 0 0 1-3.91 2.496c-2.286 5.757-8.988 9.883-17.174 10.086z"
          fill="#f6921e"
        />
      </g>
      <mask
        id="b"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={41}
        height={25}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 .046h40.964v24.902H0Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.917.046A5.914 5.914 0 0 0 0 5.956v13.237c3.84 2.803 8.549 4.822 13.755 5.754l3.892-10.798 7.206 1.102-.34.942.88.135-3.052 8.437c3.92-.918 7.494-4.187 7.494-8.69 0-.827-.148-1.593-.414-2.292-1.265-1.973-3.71-3.169-6.857-3.169-5.738 0-10.211 3.796-11.673 9.907l-.167.698-7.064-1.054.25-.86c.009-.032.02-.062.03-.094l-.82-.122.25-.86c2.71-9.31 9.785-14.437 19.923-14.437 6.227 0 11.14 2.991 13.118 7.544a11.408 11.408 0 0 1 1.49 5.7c0 1.536-.297 3.009-.845 4.389a30.661 30.661 0 0 0 3.909-2.496V.046Z"
          fill="#f6921e"
        />
      </g>
      <mask
        id="c"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={41}
        height={25}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.895.046A5.914 5.914 0 0 0 0 5.956v13.237c3.84 2.803 8.549 4.822 13.755 5.754l3.892-10.798 7.206 1.102-.34.942.88.135-3.052 8.437c3.92-.918 7.494-4.187 7.494-8.69 0-.827-.148-1.593-.414-2.292-1.265-1.973-3.71-3.169-6.857-3.169-5.738 0-10.211 3.796-11.673 9.907l-.167.698-7.064-1.054.25-.86c.009-.032.02-.062.03-.094l-.82-.122.25-.86c2.71-9.31 9.785-14.437 19.923-14.437 6.227 0 11.14 2.991 13.118 7.544a11.408 11.408 0 0 1 1.49 5.7c0 1.536-.297 3.009-.845 4.389a30.655 30.655 0 0 0 3.908-2.496V.046Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.895.046A5.914 5.914 0 0 0 0 5.956v13.237c3.84 2.803 8.549 4.822 13.755 5.754l3.892-10.798 7.206 1.102-.34.942.88.135-3.052 8.437c3.92-.918 7.494-4.187 7.494-8.69 0-.827-.148-1.593-.414-2.292-1.265-1.973-3.71-3.169-6.857-3.169-5.738 0-10.211 3.796-11.673 9.907l-.167.698-7.064-1.054.25-.86c.009-.032.02-.062.03-.094l-.82-.122.25-.86c2.71-9.31 9.785-14.437 19.923-14.437 6.227 0 11.14 2.991 13.118 7.544a11.408 11.408 0 0 1 1.49 5.7c0 1.536-.297 3.009-.845 4.389a30.655 30.655 0 0 0 3.908-2.496V.046Z"
          fill="url(#d)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.633 22.145c-.164.275-.34.543-.525.807-.04.058-.082.115-.123.172a14.802 14.802 0 0 1-.515.673l-.082.102c-.206.249-.424.491-.65.729-.044.047-.09.093-.136.14-.194.198-.394.392-.6.582l-.147.136a16.37 16.37 0 0 1-.766.648l-.118.09c-.231.18-.469.355-.712.525-.066.046-.132.093-.2.139a20.753 20.753 0 0 1-.873.56l-.035.02a16.996 16.996 0 0 1-.882.491 20.734 20.734 0 0 1-1.109.54l-.103.049a19.256 19.256 0 0 1-1 .408c-.086.033-.174.064-.26.096-.284.103-.57.202-.861.294-.073.023-.145.048-.219.07a21.677 21.677 0 0 1-1.07.3l-.25.06a23.08 23.08 0 0 1-.907.199l-.284.056a25.349 25.349 0 0 1-1.139.185c-.063.009-.127.015-.19.023a24.404 24.404 0 0 1-1.327.133c-.4.03-.801.052-1.208.062l-2.347 6.53H10.35l-.388 1.077h7.572l2.347-6.53c8.186-.203 14.888-4.33 17.175-10.086-.466.25-.94.49-1.423.72z"
        fill="#f6921e"
      />
      <mask
        id="e"
        maskUnits="userSpaceOnUse"
        x={9}
        y={21}
        width={29}
        height={18}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.633 22.145c-.164.275-.34.543-.525.807-.04.058-.082.115-.123.172a14.586 14.586 0 0 1-.515.673l-.082.102a14.75 14.75 0 0 1-.65.729c-.044.047-.09.093-.136.14-.194.198-.394.392-.6.582l-.147.136c-.247.222-.502.438-.766.648l-.118.09c-.231.18-.469.355-.712.525-.066.046-.132.093-.2.139a20.753 20.753 0 0 1-.873.56l-.035.02a21.983 21.983 0 0 1-1.121.615c-.285.144-.574.284-.87.417-.035.015-.068.032-.103.048a19.005 19.005 0 0 1-1 .408c-.086.033-.174.064-.26.096-.284.103-.57.202-.861.294-.073.023-.145.048-.219.07a21.822 21.822 0 0 1-1.07.3l-.25.06c-.299.072-.601.138-.907.199l-.284.056a24.903 24.903 0 0 1-1.139.185l-.19.023a24.39 24.39 0 0 1-1.327.133c-.4.03-.801.052-1.208.062l-2.347 6.53H10.35l-.389 1.077h7.572l2.347-6.53c8.186-.203 14.888-4.33 17.175-10.086-.466.25-.94.49-1.423.72z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#e)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.633 22.145c-.164.275-.34.543-.525.807-.04.058-.082.115-.123.172a14.586 14.586 0 0 1-.515.673l-.082.102a14.75 14.75 0 0 1-.65.729c-.044.047-.09.093-.136.14-.194.198-.394.392-.6.582l-.147.136c-.247.222-.502.438-.766.648l-.118.09c-.231.18-.469.355-.712.525-.066.046-.132.093-.2.139a20.753 20.753 0 0 1-.873.56l-.035.02a21.983 21.983 0 0 1-1.121.615c-.285.144-.574.284-.87.417-.035.015-.068.032-.103.048a19.005 19.005 0 0 1-1 .408c-.086.033-.174.064-.26.096-.284.103-.57.202-.861.294-.073.023-.145.048-.219.07a21.822 21.822 0 0 1-1.07.3l-.25.06c-.299.072-.601.138-.907.199l-.284.056a24.903 24.903 0 0 1-1.139.185l-.19.023a24.39 24.39 0 0 1-1.327.133c-.4.03-.801.052-1.208.062l-2.347 6.53H10.35l-.389 1.077h7.572l2.347-6.53c8.186-.203 14.888-4.33 17.175-10.086-.466.25-.94.49-1.423.72z"
          fill="url(#f)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m22.34 24.765 3.052-8.437-.88-.135-3.167 8.76c.333-.044.665-.11.996-.188z"
        fill="#f6921e"
      />
      <mask
        id="g"
        maskUnits="userSpaceOnUse"
        x={21}
        y={16}
        width={5}
        height={9}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.345 24.953c.333-.044.665-.11.996-.188l3.051-8.437-.88-.135z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#g)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.345 24.953c.333-.044.665-.11.996-.188l3.051-8.437-.88-.135z"
          fill="url(#h)"
        />
      </g>
      <mask
        id="i"
        maskUnits="userSpaceOnUse"
        x={21}
        y={16}
        width={5}
        height={9}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.345 24.953c.333-.044.665-.11.996-.188l3.051-8.437-.88-.135z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#i)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.345 24.953c.333-.044.665-.11.996-.188l3.051-8.437-.88-.135z"
          fill="url(#j)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.025 9.537c-5.738 0-10.211 3.796-11.673 9.908l-.167.698-6.245-.932c-.01.032-.021.062-.03.094l-.25.86 7.064 1.054.167-.698c1.462-6.111 5.935-9.907 11.673-9.907 3.147 0 5.592 1.196 6.857 3.169-.99-2.608-3.713-4.246-7.396-4.246Z"
        fill="#f6921e"
      />
      <mask
        id="k"
        maskUnits="userSpaceOnUse"
        x={3}
        y={9}
        width={27}
        height={13}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m10.352 19.445-.167.698-6.245-.932c-.01.032-.021.062-.03.094l-.25.86 7.064 1.054.167-.698c1.462-6.111 5.935-9.907 11.673-9.907 3.147 0 5.592 1.196 6.857 3.169-.99-2.608-3.713-4.246-7.396-4.246-5.738 0-10.211 3.796-11.673 9.908z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#k)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m10.352 19.445-.167.698-6.245-.932c-.01.032-.021.062-.03.094l-.25.86 7.064 1.054.167-.698c1.462-6.111 5.935-9.907 11.673-9.907 3.147 0 5.592 1.196 6.857 3.169-.99-2.608-3.713-4.246-7.396-4.246-5.738 0-10.211 3.796-11.673 9.908z"
          fill="url(#l)"
        />
      </g>
      <mask
        id="m"
        maskUnits="userSpaceOnUse"
        x={3}
        y={9}
        width={27}
        height={13}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m10.352 19.445-.167.698-6.245-.932c-.01.032-.021.062-.03.094l-.25.86 7.064 1.054.167-.698c1.462-6.111 5.935-9.907 11.673-9.907 3.147 0 5.592 1.196 6.857 3.169-.99-2.608-3.713-4.246-7.396-4.246-5.738 0-10.211 3.796-11.673 9.908z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#m)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m10.352 19.445-.167.698-6.245-.932c-.01.032-.021.062-.03.094l-.25.86 7.064 1.054.167-.698c1.462-6.111 5.935-9.907 11.673-9.907 3.147 0 5.592 1.196 6.857 3.169-.99-2.608-3.713-4.246-7.396-4.246-5.738 0-10.211 3.796-11.673 9.908z"
          fill="url(#n)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.633 22.145c.482-.23.957-.47 1.423-.72.548-1.38.845-2.853.845-4.389 0-2.102-.536-4.026-1.49-5.7.611 1.407.951 2.959.951 4.623 0 2.225-.618 4.319-1.73 6.186z"
        fill="#f6921e"
      />
      <mask
        id="o"
        maskUnits="userSpaceOnUse"
        x={35}
        y={11}
        width={3}
        height={12}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.362 15.96c0 2.224-.618 4.318-1.73 6.185.483-.23.958-.47 1.424-.72.548-1.38.845-2.853.845-4.389 0-2.102-.536-4.026-1.49-5.7.611 1.407.951 2.958.951 4.623z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#o)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.362 15.96c0 2.224-.618 4.318-1.73 6.185.483-.23.958-.47 1.424-.72.548-1.38.845-2.853.845-4.389 0-2.102-.536-4.026-1.49-5.7.611 1.407.951 2.958.951 4.623z"
          fill="url(#p)"
        />
      </g>
      <mask
        id="q"
        maskUnits="userSpaceOnUse"
        x={35}
        y={11}
        width={3}
        height={12}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.362 15.96c0 2.224-.618 4.318-1.73 6.185.483-.23.958-.47 1.424-.72.548-1.38.845-2.853.845-4.389 0-2.102-.536-4.026-1.49-5.7.611 1.407.951 2.958.951 4.623z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#q)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.362 15.96c0 2.224-.618 4.318-1.73 6.185.483-.23.958-.47 1.424-.72.548-1.38.845-2.853.845-4.389 0-2.102-.536-4.026-1.49-5.7.611 1.407.951 2.958.951 4.623z"
          fill="url(#r)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.755 24.948 9.423 36.964Zm10.835.332c-.154.018-.31.032-.465.048.155-.016.31-.03.464-.048h.001zm-.758-20.411c5.721 0 10.332 2.526 12.579 6.467-1.978-4.553-6.89-7.544-13.118-7.544C13.155 3.792 6.08 8.92 3.37 18.23l-.25.86.82.122C6.671 9.963 13.73 4.869 23.832 4.869Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m14.632 25.085 3.554-9.86 6.326.968.341-.942-7.206-1.102-3.892 10.798c.29.052.585.093.877.138zm7.552.393c.177-.009.352-.021.528-.033a37.784 37.784 0 0 0 1.413-.117c.155-.016.31-.03.465-.048 3.19-1.357 5.784-4.294 5.784-8.128 0-1.277-.34-2.413-.953-3.37.266.7.414 1.466.414 2.293 0 4.503-3.574 7.772-7.494 8.69l-.26.716c.035-.001.07 0 .103-.003zm.384 4.663zm10.287-4.655zm2.777-3.341-.001.001h.001zm-5.004 5.028zm-.993.589zm2.335-1.538zm-5.174 2.828zm.556-.211zm-2.426.802zm9.807-6.179zm.899-1.319z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24.603 25.278-.013.002h-.001l.014-.002zm-6.178.201.23.014c-.077-.003-.153-.01-.23-.014z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24.59 25.28.013-.001a37.234 37.234 0 0 0 3.682-.618l.243-.057a36.741 36.741 0 0 0 1.636-.415l.182-.05a34.04 34.04 0 0 0 5.285-1.993h.001c1.112-1.868 1.73-3.962 1.73-6.187 0-1.664-.34-3.216-.951-4.623-2.247-3.94-6.858-6.467-12.579-6.467-10.102 0-17.161 5.094-19.892 14.342l6.245.932.167-.698c1.462-6.112 5.935-9.908 11.673-9.908 3.683 0 6.406 1.638 7.396 4.246.613.956.953 2.092.953 3.369 0 3.834-2.593 6.771-5.784 8.128z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.632 25.085c.626.097 1.256.183 1.894.25l.363.036.108.011a34.082 34.082 0 0 0 1.428.097l.23.014c.546.024 1.095.037 1.648.037.598 0 1.19-.02 1.779-.049l.259-.716c-.33.078-.663.144-.996.188l3.167-8.76-6.326-.967z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m33.002 25.35-.147.136.147-.136zm-7.066 3.996-.219.071c.074-.023.146-.048.219-.07zm-2.446.629-.284.056.284-.056zm-9.735-5.027L9.423 36.964h.927l4.28-11.872a35.924 35.924 0 0 1-.875-.145zm8.129 1.082c.92-.12 1.833-.38 2.705-.75-.154.018-.31.032-.464.048a36.183 36.183 0 0 1-1.413.117c-.176.012-.351.025-.528.033l-.104.007z"
        fill="#fbfbfb"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.184 25.478c.177-.008.352-.021.528-.033-.176.012-.351.025-.528.033zm-7.552-.393c-.292-.045-.587-.086-.877-.138.29.052.582.1.875.145zm8.08.36zm.598-.043zm-1.126.076-.102.003-.001.004.103-.007z"
        fill="#fbfbfb"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.342 30.435a25.181 25.181 0 0 0 1.53-.09c.338-.028.673-.064 1.005-.106.063-.008.127-.014.19-.023a22.313 22.313 0 0 0 1.14-.185l.283-.056c.306-.061.608-.127.907-.2.083-.02.167-.038.25-.059a24.424 24.424 0 0 0 1.07-.299c.074-.023.146-.048.22-.07.29-.093.576-.191.86-.295.086-.032.174-.063.26-.096l.295-.115c.238-.094.474-.191.705-.293l.103-.048a19.837 19.837 0 0 0 1.475-.738c.174-.095.347-.193.516-.294l.035-.02a15.843 15.843 0 0 0 .873-.56c.068-.046.134-.093.2-.14.243-.17.48-.344.712-.524l.118-.09c.264-.21.52-.426.766-.648l.147-.136c.206-.19.406-.384.6-.583.045-.046.092-.092.137-.14.225-.237.443-.48.65-.728l.08-.102a13.357 13.357 0 0 0 .516-.673c.041-.057.083-.114.123-.172.184-.264.361-.532.525-.806h-.002a33.904 33.904 0 0 1-5.284 1.992l-.183.051a33.663 33.663 0 0 1-1.636.415c-.081.019-.161.039-.243.057a36.565 36.565 0 0 1-3.683.618l-.013.001c-.872.37-1.785.63-2.705.75l.197-.545c-.589.028-1.181.045-1.778.045-.553 0-1.102-.013-1.648-.037-.077-.003-.153-.01-.23-.014a41.31 41.31 0 0 1-1.428-.097l-.108-.011a37.214 37.214 0 0 1-2.26-.279l-4.278 11.872h6.644zm1.208-.063zm2.656-.341zm7.854-3.143zm2.542-2.121zm-3.452 2.701zm-5.753 2.308zm4.633-1.693zm-.973.465zm-2.34.869zm9.268-6.293zm-.597.775z"
        fill="#fbfbfb"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.285 24.66c.081-.017.162-.037.242-.056-.08.019-.16.039-.242.057zm5.454-.032-.137.14.137-.14zm-1.65 1.506-.118.09.118-.09zm-15.563-.799a39.098 39.098 0 0 1-1.894-.25l-.002.007a36.48 36.48 0 0 0 1.896.243zm0 0 .363.036-.363-.036zm13.82-1.197zm-8.264 1.343c-.59.028-1.181.05-1.78.05.598 0 1.19-.018 1.779-.046zm2.52-.203zm1.618-.224zm2.308-.45zm.838-.202zm-11.534 1.043zm-.835-.063zm1.658.111z"
        fill="#fbfbfb"
      />
      <defs>
        <linearGradient
          id="d"
          x1={-18.493}
          y1={-4.441}
          x2={-18.493}
          y2={42.944}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9AF3F" />
          <stop offset={1} stopColor="#F6911D" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={-40.089}
          y1={7.045}
          x2={-40.089}
          y2={85.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6911D" />
          <stop offset={1} stopColor="#EF5928" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={12.421}
          y1={-4.441}
          x2={12.421}
          y2={42.944}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9AF3F" />
          <stop offset={1} stopColor="#F6911D" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={5.345}
          y1={7.045}
          x2={5.345}
          y2={85.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6911D" />
          <stop offset={1} stopColor="#EF5928" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={-35.706}
          y1={-4.441}
          x2={-35.706}
          y2={42.944}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9AF3F" />
          <stop offset={1} stopColor="#F6911D" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={-69.471}
          y1={7.045}
          x2={-69.471}
          y2={85.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6911D" />
          <stop offset={1} stopColor="#EF5928" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={31.794}
          y1={-4.441}
          x2={31.794}
          y2={42.944}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9AF3F" />
          <stop offset={1} stopColor="#F6911D" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={28.581}
          y1={7.045}
          x2={28.581}
          y2={85.052}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6911D" />
          <stop offset={1} stopColor="#EF5928" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default PrDashboard;
