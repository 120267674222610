import React, { SVGProps } from "react";

const Category = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.021}
    height={14.147}
    viewBox="0 0 17.021 14.147"
    fill="currentColor"
    {...props}
  >
    <path
      id="prefix__Path_1700"
      data-name="Path 1700"
      d="M1792.889 937.357h-13.1a1.442 1.442 0 01-1.44-1.415l-.518-5.926v-.045a1.442 1.442 0 011.44-1.44h14.141a1.442 1.442 0 011.44 1.44v.045l-.518 5.926a1.442 1.442 0 01-1.445 1.415zm-14.014-7.4l.517 5.918v.046a.4.4 0 00.394.394h13.1a.394.394 0 00.394-.394v-.046l.518-5.918a.394.394 0 00-.393-.375h-14.141a.394.394 0 00-.389.371zm15.451.018z"
      transform="translate(-1777.828 -923.21)"
    />
    <g
      id="prefix__Group_1431"
      data-name="Group 1431"
      transform="translate(1.191)"
    >
      <path
        id="prefix__Path_1701"
        data-name="Path 1701"
        d="M1781.5 919.475a.524.524 0 01-.472-.749l1.892-3.965a.524.524 0 01.472-.3h4.845a.523.523 0 110 1.046h-4.515l-1.75 3.668a.523.523 0 01-.472.3z"
        transform="translate(-1780.978 -914.462)"
      />
      <path
        id="prefix__Path_1702"
        data-name="Path 1702"
        d="M1806.367 919.475a.523.523 0 01-.473-.3l-1.75-3.668h-4.515a.523.523 0 110-1.046h4.845a.524.524 0 01.472.3l1.892 3.965a.524.524 0 01-.472.749z"
        transform="translate(-1792.251 -914.462)"
      />
      <path
        id="prefix__Path_1703"
        data-name="Path 1703"
        d="M1791.349 915.508h-4.849a.523.523 0 110-1.046h4.845a.523.523 0 110 1.046z"
        transform="translate(-1784.089 -914.462)"
      />
    </g>
    <path
      id="prefix__Path_1704"
      data-name="Path 1704"
      d="M1796.724 920.316h-8.532a.523.523 0 110-1.046h8.532a.523.523 0 110 1.046z"
      transform="translate(-1783.947 -917.452)"
    />
    <path
      id="prefix__Path_1705"
      data-name="Path 1705"
      d="M1796.389 924.789h-9.571a.523.523 0 110-1.047h9.571a.523.523 0 010 1.047z"
      transform="translate(-1783.094 -920.232)"
    />
    <path
      id="prefix__Path_1706"
      data-name="Path 1706"
      d="M1797.76 938.979h-4.653a.523.523 0 01-.522-.483l-.13-1.674a.523.523 0 01.522-.564h4.913a.523.523 0 01.522.564l-.13 1.674a.523.523 0 01-.522.483zm-4.168-1.046h3.684l.048-.628h-3.781z"
      transform="translate(-1786.924 -928.016)"
    />
  </svg>
);
export default Category;
