import React from "react";
import "./index.scss";
import { Divider, Icon, IconButton, Panel } from "rsuite";
import Plus from "../../../../icons/Plus";
import Times from "../../../../icons/Times";
import { components } from "../../../../types/openapi";
import { LayoutContext } from "../../../../provider/LayoutProvider";
import {
  advancedMatchesToString,
  TAdvancedMatches,
} from "../../../../inc/advancedMatch";
import { BootstrapSize } from "../../../../inc/constants";
import AdvancedMatchInput from "../../../../components/AdvancedMatchInput";
import AdvancedMatchPanel from "../../../../components/AdvancedMatchPanel";
import SearchTopicButton from "./SearchTopicButton";
import BottomArrowToggleButton from "../../../../components/BottomArrowToggleButton";
import { normalizeDoubleQuotes } from "../../../../inc/text";

interface ILabelAutomatorFilterBarProps {
  editable: boolean;
  onChange: (
    SearchEngineRequest: components["schemas"]["SearchEngineRequest"]
  ) => void;
  searchEngineRequest: components["schemas"]["SearchEngineRequest"];
  searchTopicIds: string[];
  onSearchTopicIdsChange: (searchTopicIds: string[]) => void;
}

const emptyAdvancedMatches: TAdvancedMatches = {
  all: [],
  some: [],
  none: [],
};

const LabelAutomatorFilterBar = ({
  editable,
  onChange,
  onSearchTopicIdsChange,
  searchEngineRequest,
  searchTopicIds,
}: ILabelAutomatorFilterBarProps) => {
  const [isAdvancedMatchPanelExpanded, setIsAdvancedMatchPanelExpanded] =
    React.useState(false);
  const [
    isLabelAutomatorFilterBarExpanded,
    setIsLabelAutomatorFilterBarExpanded,
  ] = React.useState(false);
  const [advancedMatches, setAdvancedMatches] =
    React.useState<TAdvancedMatches>(emptyAdvancedMatches);
  const { windowOuterWidth } = React.useContext(LayoutContext);

  return (
    <div>
      <Panel className="labelAutomator-bar" shaded>
        <div className="row justify-content-between align-items-center">
          <div
            className="col-12 d-md-none labelAutomator-bar__global-col"
            style={{ flexGrow: 0 }}
          >
            <IconButton
              className="labelAutomator-bar__menu-toggle"
              circle
              appearance="subtle"
              icon={
                <Icon
                  icon="star"
                  componentClass={
                    isLabelAutomatorFilterBarExpanded ? Times : Plus
                  }
                />
              }
              size="sm"
              onClick={() =>
                setIsLabelAutomatorFilterBarExpanded(
                  !isLabelAutomatorFilterBarExpanded
                )
              }
            />
          </div>
          <div
            className={`col-12 col-xl-auto order-xl-1 labelAutomator-bar__searchEngineRequests-col ${
              isLabelAutomatorFilterBarExpanded ||
              windowOuterWidth >= BootstrapSize.MD
                ? "open"
                : ""
            }`}
            style={{ flex: 1 }}
          >
            <Panel
              bodyFill
              collapsible
              expanded={
                isLabelAutomatorFilterBarExpanded ||
                windowOuterWidth >= BootstrapSize.MD
              }
              className="labelAutomator-bar__searchEngineRequests-col__panel"
              style={{ display: "flex" }}
            >
              <div className="d-xl-flex align-items-center">
                <AdvancedMatchInput
                  editable={editable && !isAdvancedMatchPanelExpanded}
                  queryMatchText={searchEngineRequest.query.matchText || ""}
                  onChange={(queryMatchText) => {
                    if (
                      JSON.stringify(advancedMatches) !==
                      JSON.stringify(emptyAdvancedMatches)
                    ) {
                      setAdvancedMatches(emptyAdvancedMatches);
                    }
                    onChange({
                      ...searchEngineRequest,
                      query: {
                        ...searchEngineRequest.query,
                        matchText: normalizeDoubleQuotes(queryMatchText),
                      },
                    });
                  }}
                  onEnterPress={() => {
                    /* @TODO */
                  }}
                />
                <Divider vertical />
                <div>
                  <SearchTopicButton
                    editable={editable}
                    value={searchTopicIds}
                    onChange={(newSearchTopicIds) => {
                      onSearchTopicIdsChange(newSearchTopicIds || []);
                    }}
                  />
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </Panel>
      {editable ? (
        <>
          <AdvancedMatchPanel
            isExpanded={isAdvancedMatchPanelExpanded}
            advancedMatch={advancedMatches}
            onChange={(newAdvancedMatches) => {
              setAdvancedMatches(newAdvancedMatches);
              onChange({
                ...searchEngineRequest,
                query: {
                  ...searchEngineRequest.query,
                  matchText: normalizeDoubleQuotes(
                    advancedMatchesToString(newAdvancedMatches)
                  ),
                },
              });
            }}
            shaded
          />
          <div className="views-search-view__search-request-bar__toolbar">
            <BottomArrowToggleButton
              isAdvancedMatchPanelExpanded={isAdvancedMatchPanelExpanded}
              setIsAdvancedMatchPanelExpanded={setIsAdvancedMatchPanelExpanded}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
export default LabelAutomatorFilterBar;
