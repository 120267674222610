import {
  Button,
  FormControlProps,
  Icon,
  IconButton,
  Input,
  InputGroup,
} from "rsuite";
import MagnifyingGlass from "../../../../../icons/MagnifyingGlass";
import React from "react";
import { I18nContext } from "../../../../../provider/I18nProvider";

interface IMatchTextControlProps extends FormControlProps {
  onChange?: (value: string, event: React.SyntheticEvent<HTMLElement>) => void;
  onSubmit?: (event: React.SyntheticEvent) => void;
  onToggleAdvancedMatchPanelExpandedClick?: (
    event: React.SyntheticEvent,
  ) => void;
}

const MatchTextControl = ({
  onChange,
  onSubmit,
  onToggleAdvancedMatchPanelExpandedClick,
  value,
}: IMatchTextControlProps) => {
  const { t } = React.useContext(I18nContext);

  return (
    <div className="d-flex align-items-center">
      <InputGroup
        className={`advanced-search__input`}
        size="sm"
        inside
        style={{ width: 500 }}
      >
        <InputGroup.Addon>
          <Icon icon="question" componentClass={MagnifyingGlass} />
        </InputGroup.Addon>
        <Input
          placeholder={t("matchTextPlaceholder")}
          value={value}
          onPressEnter={onSubmit}
          onChange={onChange}
        />
      </InputGroup>
      <IconButton
        icon={<Icon icon="arrow-down2" />}
        placement="right"
        style={{ width: 200, marginLeft: "1rem" }}
        appearance="subtle"
        onClick={onToggleAdvancedMatchPanelExpandedClick}
      >
        {t("useAdvancedSearchEngineMatch")}
      </IconButton>
      <Button
        onClick={onSubmit}
        appearance="primary"
        className="ms-3"
        type="submit"
      >
        {t("updateSearch")}
      </Button>
    </div>
  );
};

export default React.memo(MatchTextControl) as typeof MatchTextControl;
