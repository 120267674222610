import React, { SVGProps } from "react";

const MediaItemTableDisplay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Weergave_BW_tabel"
    width={47.5}
    height={27.952}
    viewBox="0 0 47.5 27.952"
    fill="#96afaf"
    {...props}
  >
    <path
      id="prefix__Path_1988"
      d="M1697.8 1361.18a1.2 1.2 0 01-1.2-1.2v-25.55a1.2 1.2 0 012.4 0v25.548a1.2 1.2 0 01-1.2 1.202z"
      transform="translate(-1677.881 -1333.228)"
    />
    <path
      id="prefix__Path_1989"
      d="M1728.159 1361.18a1.2 1.2 0 01-1.2-1.2v-25.55a1.2 1.2 0 012.405 0v25.548a1.2 1.2 0 01-1.205 1.202z"
      transform="translate(-1681.862 -1333.228)"
    />
    <path
      id="prefix__Path_1990"
      d="M1688.665 1361.764h-12.407a1.2 1.2 0 010-2.405h12.407a1.2 1.2 0 110 2.405z"
      transform="translate(-1675.057 -1336.654)"
    />
    <path
      id="prefix__Path_1991"
      d="M1688.665 1349.844h-12.407a1.2 1.2 0 010-2.405h12.407a1.2 1.2 0 010 2.405z"
      transform="translate(-1675.057 -1335.091)"
    />
    <path
      id="prefix__Path_1992"
      d="M1688.665 1339.116h-12.407a1.2 1.2 0 010-2.405h12.407a1.2 1.2 0 110 2.405z"
      transform="translate(-1675.057 -1333.684)"
    />
    <path
      id="prefix__Path_1993"
      d="M1719.936 1361.764h-12.406a1.2 1.2 0 110-2.405h12.406a1.2 1.2 0 110 2.405z"
      transform="translate(-1679.157 -1336.654)"
    />
    <path
      id="prefix__Path_1994"
      d="M1719.936 1349.844h-12.406a1.2 1.2 0 110-2.405h12.406a1.2 1.2 0 010 2.405z"
      transform="translate(-1679.157 -1335.091)"
    />
    <path
      id="prefix__Path_1995"
      d="M1719.936 1339.116h-12.406a1.2 1.2 0 110-2.405h12.406a1.2 1.2 0 110 2.405z"
      transform="translate(-1679.157 -1333.684)"
    />
  </svg>
);
export default MediaItemTableDisplay;
