import React from "react";
import { paths } from "../../../../types/openapi";
import openapi from "../../../../openapi.json";
import { oas30 } from "openapi3-ts";
import ajv from "../../../../inc/ajv";
import {
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../../../inc/schema";
import { Button, ButtonToolbar, Form, FormGroup } from "rsuite";
import axios from "../../../../inc/axios";
import SchemaFormBody from "../../../../components/SchemaFormBody";
import { useHistory } from "react-router-dom";
import { I18nContext } from "../../../../provider/I18nProvider";
import "./index.scss";
import { AuthContext } from "../../../../provider/AuthProvider";

type TResetPassword =
  paths["/auth/resetPassword"]["post"]["requestBody"]["content"]["application/json"] & {
    newPasswordRepeat: string;
  };
type TResetFormErrors = { [P in keyof TResetPassword]?: string };

const ResetPasswordSchema = openapi.paths["/auth/resetPassword"].post
  .requestBody.content["application/json"].schema as oas30.SchemaObject;
const NewPasswordRequestSchema = {
  ...ResetPasswordSchema,
  properties: {
    ...ResetPasswordSchema.properties,
    newPasswordRepeat: {
      ...ResetPasswordSchema.properties?.password,
    },
  },
  required: [...(ResetPasswordSchema.required || []), "newPasswordRepeat"],
} as oas30.SchemaObject;
const validate = ajv.compile(NewPasswordRequestSchema);

interface IResetPasswordFormProps {
  nextPath: string;
  resetToken: string;
}

const ResetPasswordForm = ({
  nextPath,
  resetToken,
}: IResetPasswordFormProps) => {
  const { t } = React.useContext(I18nContext);
  const [resetPassword, setResetPassword] = React.useState<TResetPassword>(
    getEmptyObject<TResetPassword>(NewPasswordRequestSchema),
  );
  const [errors, setErrors] = React.useState<TResetFormErrors>({});
  const [status, setStatus] = React.useState<
    "idle" | "pending" | "expiredToken"
  >("idle");
  const { setBearer } = React.useContext(AuthContext);
  const history = useHistory();

  React.useEffect(() => {
    if (resetPassword.token !== resetToken) {
      setResetPassword({
        password: "",
        newPasswordRepeat: "",
        token: resetToken,
      });
    }
  }, [resetPassword, resetToken]);

  const onFormSubmit = () => {
    const { newPasswordRepeat, ...request } = resetPassword;
    if (newPasswordRepeat !== request.password) {
      setErrors({
        newPasswordRepeat: t("newPasswordRepeatIsntEqualToNewPassword"),
      });
      return;
    }
    const isValid = validate(resetPassword);
    if (!isValid && validate.errors && validate.errors.length) {
      console.log(validate.errors);
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      return;
    }
    setStatus("pending");
    setErrors({});
    axios
      .post("/auth/resetPassword", resetPassword)
      .then((response) => {
        setBearer(response.data.bearer);
        history.push(nextPath);
      })
      .catch((err) => {
        console.log(err);
        setBearer(undefined);
        setStatus("expiredToken");
      });
  };

  return status === "expiredToken" ? (
    <div className="invalid-token">
      <h1>{t("invalidToken")}</h1>
      <p className="invalid-token__description">
        {t("invalidTokenDescription")}
      </p>
      <Button
        appearance="primary"
        onClick={() => {
          history.push("/login/lostPassword");
        }}
      >
        {t("resetAgain")}
      </Button>
    </div>
  ) : (
    <>
      <h1>{t("resetPassword")}</h1>
      <Form style={{ marginTop: 40 }} onSubmit={onFormSubmit}>
        <SchemaFormBody<TResetPassword>
          schema={NewPasswordRequestSchema}
          value={resetPassword}
          errors={errors}
          onChange={setResetPassword}
          disabled={status !== "idle"}
          config={{
            token: {
              hidden: true,
            },
            password: {
              label: <>{t("newPassword")}</>,
            },
          }}
        />
        <FormGroup style={{ marginTop: 40 }}>
          <ButtonToolbar>
            <Button
              type="submit"
              appearance="primary"
              disabled={status !== "idle"}
            >
              {t("setValue")}
            </Button>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    </>
  );
};

export default ResetPasswordForm;
