import { Alert, Button, Form, Modal } from "rsuite";
import React from "react";
import SchemaFormBody, {
  TSchemaFormErrors,
} from "../../../components/SchemaFormBody";
import openapi from "../../../openapi.json";
import { oas30 } from "openapi3-ts";
import {
  focusError,
  validationErrorsToSchemaFormErrors,
} from "../../../inc/schema";
import ajv from "../../../inc/ajv";
import { APP_PATH } from "../../../inc/constants";
import { useHistory } from "react-router-dom";
import { TDashboard } from "../../../types";
import * as _ from "lodash";
import { ApiDataContext } from "../../../provider/ApiDataProvider";
import { I18nContext } from "../../../provider/I18nProvider";
import { useDashboardHashData } from "../inc/hooks";
import useDashboards from "../../../hooks/useDashboards";

const DashboardSchema = openapi.components.schemas.Dashboard as oas30.SchemaObject;
const validate = ajv.compile(DashboardSchema);

interface ISaveAsModalProps {
  close: () => void;
  dashboard?: TDashboard;
  setDashboard: (newDashboard: TDashboard | undefined) => void;
}

const SaveAsModal = ({ dashboard, close, setDashboard }: ISaveAsModalProps) => {
  const { updateDashboard } = React.useContext(ApiDataContext);
  const { t } = React.useContext(I18nContext);
  const dashboards = useDashboards();

  const history = useHistory();
  const locationHashData = useDashboardHashData();
  const [errors, setErrors] = React.useState<TSchemaFormErrors>({});
  React.useEffect(() => {
    setDashboard(dashboard);
    setTimeout(() => {
      const formInput = window.document.querySelector<HTMLInputElement>(
        ".views__dashboard-view__save-as-modal input"
      );
      if (formInput) {
        formInput.focus();
      }
    }, 1000);
  }, [dashboard, setDashboard]);

  const onSubmit = React.useCallback(() => {
    if (!dashboard) {
      return;
    }
    let isValid = validate(dashboard);
    if (
      isValid &&
      dashboards &&
      Object.values(dashboards).find((obj) => obj.name === dashboard.name)
    ) {
      setErrors({ name: "Er bestaat al een dashboard met deze naam" });
      setTimeout(focusError, 200);
      return;
    }
    if (!isValid && validate.errors && validate.errors.length) {
      setErrors(validationErrorsToSchemaFormErrors(validate.errors));
      setTimeout(focusError, 200);
      return;
    }
    setErrors({});
    updateDashboard(dashboard)
      .then((newDashboard) => {
        setDashboard(undefined);
        history.push(
          `${APP_PATH}/dashboards/${
            newDashboard.dashboardId
          }#${encodeURIComponent(
            JSON.stringify({ ...locationHashData, isCopy: true })
          )}`
        );
      })
      .catch((err) => {
        console.log(err);
        Alert.error(t("invalidData"));
      });
  }, [
    dashboard,
    dashboards,
    updateDashboard,
    t,
    setDashboard,
    history,
    locationHashData,
  ]);

  return (
    <Modal
      show={!!dashboard}
      onHide={close}
      size="xs"
      dialogClassName={"views__dashboard-view__save-as-modal"}
    >
      <Modal.Header>
        <Modal.Title>{t("saveDashboardAs")}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "90vh", overflow: "hidden" }}>
        <Form className="w-100" onSubmit={onSubmit}>
          <div>
            <SchemaFormBody<TDashboard>
              schema={{
                ...DashboardSchema,
                properties: _.pick(DashboardSchema.properties, ["name"]) as any,
              }}
              onChange={setDashboard}
              value={dashboard}
              errors={errors}
              inputClassName="input-gray"
            />
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit} appearance="primary">
          {t("OK")}
        </Button>
        <Button onClick={close} appearance="subtle">
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SaveAsModal;
