import React from "react";
import { Loader, Panel } from "rsuite";
import WidgetPanelHeader from "../../../inc/widgets/WidgetPanelHeader";
import { I18nContext } from "../../../provider/I18nProvider";
import Chart from "react-apexcharts";
import { round, startCase, sum } from "lodash";
import { CHART_COLORS } from "../../../inc/constants";
import { ApexOptions } from "apexcharts";
import { currency, formatInt } from "../../../inc/numbers";
import { useBenchmarkAnalysisCache } from "../inc/useBenchmarkAnalysisCache";
import { EValueType, IBenchmarkWidgetProps } from "../index";
import { components } from "../../../types/openapi";
import { EChartType } from "../../../inc/enums";
import PopoverForm from "../inc/PopoverForm";
import { ESplitType } from "../../dashboardWidgets";
import { formatValue } from "../../../inc/widgets/chart";
import CustomLegend from "../../dashboardWidgets/inc/CustomLegend";
import InsufficientDataBody from "../../../inc/widgets/InsufficientDataBody";
import "./index.scss";

interface ICategoryCountMap {
  [category: string]: number;
}

export interface ICategoryBenchmarkWidgetSettings {
  chartType?: EChartType.donut | EChartType.bar;
  customValueGroups?: string[];
  limit: number;
  matchTextIndex: number;
  notes?: string;
  valueType: EValueType;
}

const CategoryBenchmarkWidget = ({
  benchmark,
  height,
  onDelete,
  onNotesChange,
  onSettingsChange,
  settings,
  uid,
  width,
}: IBenchmarkWidgetProps<ICategoryBenchmarkWidgetSettings>) => {
  const { t } = React.useContext(I18nContext);

  const defaultSettings = React.useMemo(
    () => ({
      chartType: EChartType.donut,
      matchTextIndex: 0,
      valueType: EValueType.count,
    }),
    [],
  );
  const { chartType, customValueGroups, limit, matchTextIndex, valueType } = {
    ...defaultSettings,
    ...settings,
  };

  const matchText = benchmark.matchTexts[matchTextIndex] as
    | components["schemas"]["BenchmarkMatchText"]
    | undefined;

  const benchmarkAnalysis = useBenchmarkAnalysisCache(
    `/benchmark/analyse/${valueType}/category/none/publicationDate`,
    benchmark,
  );
  const benchmarkAnalysisItemsIndex = React.useMemo(
    () =>
      benchmarkAnalysis
        ? Object.values(benchmarkAnalysis.items).findIndex(
            (item) => item.benchmarkName === matchText?.name,
          )
        : -1,
    [benchmarkAnalysis, matchText?.name],
  );

  const categoryCountMap = React.useMemo<ICategoryCountMap>(() => {
    if (!benchmarkAnalysis || benchmarkAnalysisItemsIndex < 0) {
      return {};
    }
    return benchmarkAnalysis.valueGroup.reduce<ICategoryCountMap>(
      (prev, categoryName, categoryIndex) => {
        prev[categoryName] =
          benchmarkAnalysis.items[benchmarkAnalysisItemsIndex].values[
            categoryIndex
          ];
        return prev;
      },
      {},
    );
  }, [benchmarkAnalysis, benchmarkAnalysisItemsIndex]);

  const topCategoryNames = React.useMemo<string[]>(() => {
    if (!limit && customValueGroups) {
      return customValueGroups;
    }
    return Object.keys(categoryCountMap)
      .sort((a, b) => categoryCountMap[b] - categoryCountMap[a])
      .slice(0, limit || 10);
  }, [categoryCountMap, customValueGroups, limit]);

  const chartOptions = React.useMemo<ApexOptions>(() => {
    const result: ApexOptions = {
      colors: CHART_COLORS,
      chart: {
        fontFamily: "Gelion, sans-serif",
        toolbar: {
          show: false,
        },
        zoom: { enabled: false },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: chartType !== EChartType.donut,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        custom:
          chartType === EChartType.donut
            ? ({ series, seriesIndex }) => {
                const total = series.reduce(
                  (carry: number, value: number) => carry + value,
                );
                return `<div class="arrow_box arrow_box--bottom"><span>${
                  topCategoryNames[seriesIndex]
                }: ${formatValue(valueType, series[seriesIndex])} (${round(
                  (series[seriesIndex] / total) * 100,
                  1,
                )}%)</span></div>`;
              }
            : ({ dataPointIndex, series, seriesIndex, splitType }) => {
                const value = series[seriesIndex][dataPointIndex];
                return `<div class="arrow_box arrow_box--left"><span>${formatValue(
                  valueType,
                  value,
                )}${
                  splitType !== ESplitType.none
                    ? ` (${round((value / sum(series[0])) * 100, 1)}%)`
                    : ""
                }</span></div>`;
              },
      },
      xaxis: {
        categories: topCategoryNames.map((category) =>
          category.includes(".")
            ? category.toLowerCase()
            : startCase(category.toLowerCase()),
        ),
        labels: {
          formatter: (val: any) =>
            valueType && ["ave", "summedAve"].indexOf(valueType) >= 0
              ? currency(val, 0)
              : formatInt(val),
        },
      },
      legend: {
        show: false,
      },
    };

    if (chartType === EChartType.donut) {
      result.labels = topCategoryNames;
    }
    return result;
  }, [chartType, topCategoryNames, valueType]);

  const series = React.useMemo(() => {
    if (chartType === EChartType.donut) {
      return topCategoryNames.map((categoryName) =>
        categoryCountMap[categoryName] ? categoryCountMap[categoryName] : 0,
      );
    }
    return matchText
      ? [
          {
            name: matchText.name,
            data: topCategoryNames.map(
              (categoryName) => categoryCountMap[categoryName],
            ),
          },
        ]
      : [];
  }, [chartType, matchText, categoryCountMap, topCategoryNames]);

  const header = React.useMemo(() => {
    return matchText ? (
      <WidgetPanelHeader
        popoverForm={
          onSettingsChange ? (
            <PopoverForm
              onSettingsChange={onSettingsChange}
              settings={
                {
                  ...defaultSettings,
                  ...settings,
                } as ICategoryBenchmarkWidgetSettings
              }
              chartTypes={{
                donut: true,
                bar: true,
              }}
              widgetUid={uid}
              topResultOptions={[3, 5, 10, 0]}
            />
          ) : undefined
        }
        notes={settings?.notes}
        onNotesChange={onNotesChange}
        allowImageDownload
        onDelete={onDelete}
        title={`${t("categories")}`}
        subtitle={`${t(`valueType_${valueType}`)} - ${t("matchText")} ${t(
          matchText.name,
        )}`}
      />
    ) : (
      <Loader />
    );
  }, [
    defaultSettings,
    matchText,
    onDelete,
    onNotesChange,
    onSettingsChange,
    settings,
    t,
    uid,
    valueType,
  ]);

  const onCustomLegendChange = React.useCallback(
    (customValueGroups: any) => {
      if (!onSettingsChange || !settings) {
        return;
      }
      onSettingsChange(uid, {
        ...settings,
        limit: 0,
        customValueGroups,
      });
    },
    [onSettingsChange, settings, uid],
  );

  const chartWidth = Math.floor(0.96 * width);
  const chartHeight = height - 105;
  return (
    <Panel
      bordered
      header={header}
      className={`views__benchmark-view__widgets__category-widget`}
    >
      {series ? (
        series.length ? (
          <div
            className="chart"
            style={{
              width: chartWidth,
              height: chartHeight,
              overflow: "hidden",
            }}
          >
            <Chart
              key={`${chartType}_${valueType}`}
              type={chartType as any}
              series={series}
              options={chartOptions}
              width={chartWidth}
              height={chartHeight}
            />
            {benchmarkAnalysis ? (
              <CustomLegend
                colors={chartOptions.colors as string[]}
                options={benchmarkAnalysis.valueGroup}
                value={topCategoryNames}
                onChange={onCustomLegendChange}
              />
            ) : null}
          </div>
        ) : (
          <InsufficientDataBody />
        )
      ) : (
        <div style={{ width }}>
          <Loader backdrop />
        </div>
      )}
    </Panel>
  );
};

export default CategoryBenchmarkWidget;
