import React, { SVGProps } from "react";

const Group = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="prefix__Beheer_gebruikers_icon"
    width={15}
    height={15.312}
    viewBox="0 0 15 15.312"
    fill="currentColor"
    {...props}
  >
    <g id="prefix__Group_690">
      <g id="prefix__Group_689">
        <path
          id="prefix__Path_1398"
          d="M32.892 23.352H30.83a.507.507 0 010-1.014h2.062a.317.317 0 00.311-.291l-.216-2.026a2.425 2.425 0 00-1.155-2.044.507.507 0 01.443-.912 3.386 3.386 0 011.718 2.83l.222 2.078a.443.443 0 010 .054 1.327 1.327 0 01-1.325 1.325"
          transform="translate(-19.217 -10.782)"
        />
        <path
          id="prefix__Path_1399"
          d="M23.56 6.971a.507.507 0 010-1.014 2.472 2.472 0 100-4.944 2.447 2.447 0 00-1.52.522.507.507 0 11-.624-.8A3.449 3.449 0 0123.56 0a3.485 3.485 0 110 6.971"
          transform="translate(-13.449)"
        />
        <path
          id="prefix__Path_1400"
          d="M32.975 28.72a.507.507 0 01-.507-.507v-2.222a.507.507 0 111.014 0v2.222a.507.507 0 01-.507.507"
          transform="translate(-20.577 -16.15)"
        />
        <path
          id="prefix__Path_1401"
          d="M9.328 29.826h-7.82A1.51 1.51 0 010 28.318a.512.512 0 010-.058l.243-2.1a3.985 3.985 0 011.948-3.172.507.507 0 11.464.9 3.046 3.046 0 00-1.407 2.4l-.238 2.055a.5.5 0 00.493.47h7.82a.4.4 0 00.4-.38l-.24-2.253a2.742 2.742 0 00-1.313-2.313.507.507 0 01.443-.912 3.709 3.709 0 011.876 3.1l.245 2.3a.442.442 0 010 .054 1.417 1.417 0 01-1.416 1.415"
          transform="translate(0 -14.514)"
        />
        <path
          id="prefix__Path_1402"
          d="M8.069 11.645a3.813 3.813 0 113.813-3.813 3.817 3.817 0 01-3.813 3.813m0-6.612a2.8 2.8 0 102.8 2.8 2.8 2.8 0 00-2.8-2.8"
          transform="translate(-2.697 -2.547)"
        />
        <path
          id="prefix__Path_1403"
          d="M6.857 35.784a.507.507 0 01-.507-.507v-2.466a.507.507 0 111.014 0v2.466a.507.507 0 01-.507.507"
          transform="translate(-4.024 -20.473)"
        />
        <path
          id="prefix__Path_1404"
          d="M20.723 35.784a.507.507 0 01-.507-.507v-2.466a.507.507 0 111.014 0v2.466a.507.507 0 01-.507.507"
          transform="translate(-12.812 -20.473)"
        />
      </g>
    </g>
  </svg>
);
export default Group;
