export const LOGIN_PATH = "/login";
export const APP_PATH = "/app";
export enum BootstrapSize {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}
export const MEDIA_TYPE_COLORS = {
  print: "#288c8c",
  web: "#a0d2aa",
  rss: "#e1dca5",
  radio: "#96afaf",
  podcast: "#bf946f",
  social: "#646973",
  tv: "#bec8d2",
};

export const CHART_COLORS = [
  "#2d50b4",
  "#3cbe8c",
  "#e68c91",
  "#646973",
  "#96afaf",
  "#bec8d2",
  "#288c8c",
  "#a0d2aa",
  "#6e64a0",
  "#e1dca5",
  "#bf946f",
];

export const LOAD_MEDIAITEM_COUNT = 12;
export const LOAD_BENCHMARKRESULT_COUNT = 12;

export const REMAINING_AUTHOR_NAME = "#$Other#$";
