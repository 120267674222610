import { Button, Icon } from "rsuite";
import React from "react";
import { I18nContext } from "../../provider/I18nProvider";

import "./index.scss";

interface IBottomArrowToggleButtonProps {
  isAdvancedMatchPanelExpanded: boolean;
  setIsAdvancedMatchPanelExpanded: (
    isAdvancedMatchPanelExpanded: boolean
  ) => void;
}

const BottomArrowToggleButton = ({
  setIsAdvancedMatchPanelExpanded,
  isAdvancedMatchPanelExpanded,
}: IBottomArrowToggleButtonProps) => {
  const { t } = React.useContext(I18nContext);
  return (
    <Button
      size="xs"
      className={`views-search-view__search-request-bar__toolbar__button bg-white ${
        isAdvancedMatchPanelExpanded
          ? "views-search-view__search-request-bar__toolbar__button--expanded"
          : ""
      }`}
      onClick={() =>
        setIsAdvancedMatchPanelExpanded(!isAdvancedMatchPanelExpanded)
      }
      ripple={false}
    >
      <Icon icon="arrow-down2" />
      <span className="position-relative ms-2" style={{ top: -8 }}>
        {t(
          isAdvancedMatchPanelExpanded
            ? "closeAdvancedSearchEngineMatch"
            : "useAdvancedSearchEngineMatch"
        )}
      </span>
    </Button>
  );
};

export default BottomArrowToggleButton;
