import React, { SVGProps } from "react";

const Favourite = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.376}
      height={10.656}
      viewBox="0 0 118.414 101.959"
      fill="currentColor"
      {...props}
    >
      <path d="M58.597 101.958a4.586 4.586 0 01-1.719-.334C54.563 100.673.016 77.861.016 35.01a34.115 34.115 0 0133.073-35c.067 0 .282-.006.337-.006a33.412 33.412 0 0125.723 12.8A33.35 33.35 0 0184.891.004a34.333 34.333 0 0133.513 35.079c0 21.368-14.609 41.6-42.246 58.383a135.694 135.694 0 01-15.8 8.139 4.483 4.483 0 01-1.761.353zm-25.306-92.9A25.078 25.078 0 009.065 34.873c0 33.022 40.64 53.521 49.545 57.617a135.217 135.217 0 0012.835-6.759c17.3-10.506 37.9-27.837 37.9-50.762A25.252 25.252 0 0084.829 9.057a24.188 24.188 0 00-21.545 14.3 4.513 4.513 0 01-4.127 2.678 4.532 4.532 0 01-4.134-2.672A24.309 24.309 0 0033.288 9.057z" />
    </svg>
  );
};

export default Favourite;
