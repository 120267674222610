import React from "react";
import { components } from "../../types/openapi";
import openapi from "../../openapi.json";
import {
  focusError,
  getEmptyObject,
  validationErrorsToSchemaFormErrors,
} from "../../inc/schema";
import { oas30 } from "openapi3-ts";
import AutosizeInput from "react-18-input-autosize";
import {
  Alert,
  Button,
  ButtonGroup,
  Dropdown,
  HelpBlock,
  Icon,
  IconButton,
  Loader,
  Message,
} from "rsuite";
import { useHistory, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { APP_PATH, BootstrapSize } from "../../inc/constants";
import Prullie from "../../icons/Prullie";
import { ApiDataContext } from "../../provider/ApiDataProvider";
import { TSchemaFormErrors } from "../../components/SchemaFormBody";
import axios from "../../inc/axios";
import { useBenchmarks } from "../../hooks/useBenchmarks";
import { ValidationError } from "ajv";
import ajv from "../../inc/ajv";
import { AuthContext } from "../../provider/AuthProvider";
import { I18nContext } from "../../provider/I18nProvider";
import BenchmarkBar from "./BenchmarkBar";
import { LayoutContext } from "../../provider/LayoutProvider";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import {
  BenchmarkWidgetContainer,
  benchmarkWidgetDefinitions,
  EBenchmarkWidgetType,
  IBenchmarkWidget,
  newBenchmarkWidget,
} from "../../components/benchmarkWidgets";
import { IMaartenError, TBenchmark } from "../../types";
import ShareButton from "./ShareButton";
import ActionButton from "../../components/ActionButton";
import BenchmarkWidgetDrawer from "../BenchmarkView/BenchmarkWidgetDrawer";
import { useBenchmarkHashData } from "./inc/hooks";
import { IHashMap } from "../../inc/data";
import "./index.scss";
import { range } from "lodash";
import ProgressIndicator from "./ProgressIndicator";
import { stringifyApiCacheBenchmark } from "../../inc/benchmark";

const benchmarkSchema = openapi.components.schemas
  .Benchmark as oas30.SchemaObject;
const schemaValidate = ajv.compile(benchmarkSchema);
const emptyBenchmark = getEmptyObject<TBenchmark>(benchmarkSchema);
emptyBenchmark.searchEngineRequest.query.includeMediaPrint = true;
emptyBenchmark.searchEngineRequest.query.includeMediaWeb = true;
emptyBenchmark.config = {};

const emptySearchEngineRequest = getEmptyObject<
  components["schemas"]["SearchEngineRequest"]
>(openapi.components.schemas.SearchEngineRequest as oas30.SchemaObject);
emptySearchEngineRequest.query.includeMediaPrint = true;
emptySearchEngineRequest.query.includeMediaWeb = true;

const BenchmarkView = () => {
  const { auth } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const { setBenchmarks } = React.useContext(ApiDataContext);
  const { contentWidth, windowOuterWidth } = React.useContext(LayoutContext);
  const benchmarkViewRef = React.useRef<HTMLDivElement>(null);
  const { benchmarkId } = useParams<{ benchmarkId?: string }>();
  const benchmarks = useBenchmarks();
  const [benchmark, setBenchmark] = React.useState<TBenchmark>(emptyBenchmark);
  const [isSavePending, setIsSavePending] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const getBenchmarkErrors = React.useCallback(
    (benchmark: TBenchmark) => {
      let isValid = schemaValidate(benchmark);
      let { errors } = schemaValidate;
      if (
        isValid &&
        benchmarks &&
        Object.values(benchmarks).find(
          (obj) =>
            obj.name === benchmark.name &&
            obj.benchmarkId !== benchmark.benchmarkId,
        )
      ) {
        errors = [
          {
            instancePath: ".name",
            keyword: "custom",
            message: "name already exists",
            schemaPath: "#/properties/name",
            params: {},
          },
        ];
      }
      return errors || [];
    },
    [benchmarks],
  );

  const [cachedBenchmarkMap, setCachedBenchmarkMap] = React.useState<
    IHashMap<{
      resultCount?: number;
      startDate: Date;
      endDate?: Date;
    }>
  >({});

  const isBenchmarkOnly = auth
    ? auth.jwt.userRoles?.indexOf("benchmarkOnly") >= 0
    : false;

  const layouts = React.useMemo(
    () => ({
      xs:
        benchmark?.config?.widgets?.map((widget) => ({
          ...widget.layouts.xs,
          ...benchmarkWidgetDefinitions[widget.type],
          i: widget.uid,
        })) || [],
      lg:
        benchmark?.config?.widgets?.map((widget) => ({
          ...widget.layouts.lg,
          ...benchmarkWidgetDefinitions[widget.type],
          i: widget.uid,
        })) || [],
    }),
    [benchmark?.config?.widgets],
  );

  const history = useHistory();
  const locationBenchmarkHashData = useBenchmarkHashData();
  const [errors, setErrors] = React.useState<
    TSchemaFormErrors<components["schemas"]["Benchmark"]>
  >({});

  React.useEffect(() => {
    if (locationBenchmarkHashData) {
      let newWidgets: IBenchmarkWidget[] | undefined = undefined;
      const isMatchTextAdded =
        locationBenchmarkHashData.matchTexts.length >
        benchmark.matchTexts.length;

      if (isMatchTextAdded) {
        // if a matchText has been added, also add it
        [
          EBenchmarkWidgetType.WIDGET_TYPE_AUTHOR,
          EBenchmarkWidgetType.WIDGET_TYPE_CATEGORY,
          EBenchmarkWidgetType.WIDGET_TYPE_SOURCE,
        ].forEach((widgetType) => {
          const existingWidgets =
            benchmark.config.widgets?.filter(
              (widget) => widget.type === widgetType,
            ) || [];
          if (!existingWidgets.length) {
            return;
          }
          if (!newWidgets) {
            newWidgets = benchmark.config.widgets
              ? [...benchmark.config.widgets]
              : [];
          }
          range(0, locationBenchmarkHashData.matchTexts.length).forEach(
            (matchTextIndex) => {
              if (
                existingWidgets.find(
                  (widget) =>
                    widget.widgetConfig.matchTextIndex === matchTextIndex,
                )
              ) {
                return;
              }
              newWidgets!.push(
                newBenchmarkWidget(widgetType, {
                  matchTextIndex,
                }),
              );
            },
          );
        });
      }

      setBenchmark((benchmark) => {
        const newBenchmark = {
          ...benchmark,
          ...locationBenchmarkHashData,
        };
        if (newWidgets) {
          newBenchmark.config.widgets = newWidgets;
        }
        // Got this from locationBenchmarkHashData
        // @ts-ignore
        delete newBenchmark.sort;
        return newBenchmark;
      });
    }
  }, [
    benchmark.config.widgets,
    benchmark.matchTexts.length,
    locationBenchmarkHashData,
  ]);

  const isBenchmarkDirty = React.useMemo<boolean>(() => {
    if (!benchmarks || !benchmark.matchTexts.length) {
      return false;
    }
    if (!benchmarkId) {
      return true;
    }
    const originalBenchmark = benchmarks[benchmarkId];
    return (
      !originalBenchmark ||
      JSON.stringify(originalBenchmark) !== JSON.stringify(benchmark)
    );
  }, [benchmark, benchmarkId, benchmarks]);

  const onBenchmarkSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      const errors = getBenchmarkErrors(benchmark);
      if (errors && errors.length) {
        setErrors(validationErrorsToSchemaFormErrors(errors));
        setTimeout(focusError, 200);
        throw new ValidationError(errors);
      }
      setErrors({});
      setIsSavePending(true);
      axios
        .request<components["schemas"]["Benchmark"]>({
          method: benchmarkId ? "put" : "post",
          url: `/benchmark/crud${benchmarkId ? `/${benchmarkId}` : ""}`,
          data: benchmark,
        })
        .then((res) => {
          setIsSavePending(false);
          Alert.success(t("saveSuccessful"));
          setBenchmarks((benchmarks) =>
            benchmarks
              ? {
                  ...benchmarks,
                  [res.data.benchmarkId as string]: res.data as TBenchmark,
                }
              : benchmarks,
          );
          if (benchmarkId !== res.data.benchmarkId) {
            history.push(`${APP_PATH}/analysis/${res.data.benchmarkId}`);
          }
        })
        .catch((err: AxiosError<IMaartenError>) => {
          setIsSavePending(false);
          Alert.error(t(err.response?.data.error || "genericErrorMessage"));
        });
    },
    [benchmark, benchmarkId, getBenchmarkErrors, history, setBenchmarks, t],
  );

  React.useEffect(() => {
    setBenchmark(
      benchmarks && benchmarkId && benchmarks[benchmarkId]
        ? benchmarks[benchmarkId]
        : emptyBenchmark,
    );
  }, [benchmarkId, benchmarks]);

  const onItemDelete = React.useCallback(
    (benchmarkId: string) => {
      if (!window.confirm(t("deleteConfirmation"))) {
        return;
      }
      axios
        .delete(`/benchmark/crud/${benchmarkId}`)
        .then(() => {
          setBenchmarks((currentBenchmarks) => {
            if (!currentBenchmarks) {
              return currentBenchmarks;
            }
            const newBenchmarks = { ...currentBenchmarks };
            delete newBenchmarks[benchmarkId];
            return newBenchmarks;
          });
          Alert.success(t("deleteSuccessful"));
          history.push(`${APP_PATH}/analysis`);
        })
        .catch((err) => {
          Alert.error(t(err.message || "genericErrorMessage"));
        });
    },
    [history, setBenchmarks, t],
  );

  const loadableBenchmarks = React.useMemo(() => {
    return benchmarks
      ? Object.values(benchmarks).sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1,
        )
      : [];
  }, [benchmarks]);

  const onSettingsChange = React.useCallback(
    (widgetUid: string, newSettings: any) => {
      if (!benchmark.config.widgets) {
        return;
      }
      setBenchmark({
        ...benchmark,
        config: {
          ...benchmark.config,
          widgets: benchmark.config.widgets.map((existingWidget) => {
            if (existingWidget.uid === widgetUid) {
              existingWidget.widgetConfig = newSettings;
            }
            return existingWidget;
          }),
        },
      });
    },
    [benchmark],
  );

  React.useEffect(
    () => {
      const newBenchmark = {
        ...benchmark,
        ...locationBenchmarkHashData,
      };
      // Got this from locationBenchmarkHashData
      // @ts-ignore
      delete newBenchmark.sort;
      setBenchmark(newBenchmark);
    },
    // eslint-disable-next-line
    [locationBenchmarkHashData],
  );

  const stringifiedBenchmark = React.useMemo(
    () => stringifyApiCacheBenchmark(benchmark),
    [benchmark],
  );

  React.useEffect(() => {
    const { matchTexts, config } = benchmark;
    if (config) {
      const { widgets = [] } = config;
      const filteredWidgets = widgets.filter((widget) => {
        switch (widget.type) {
          case EBenchmarkWidgetType.WIDGET_TYPE_HIGHLIGHT:
            return matchTexts.length === 1;

          case EBenchmarkWidgetType.WIDGET_TYPE_AUTHOR:
          case EBenchmarkWidgetType.WIDGET_TYPE_CATEGORY:
          case EBenchmarkWidgetType.WIDGET_TYPE_SOURCE:
            return widget.widgetConfig.matchTextIndex < matchTexts.length;
        }
        return true;
      });

      if (widgets.length !== filteredWidgets.length) {
        setBenchmark({
          ...benchmark,
          config: {
            ...config,
            widgets: filteredWidgets,
          },
        });
      }
    }
  }, [benchmark]);

  React.useEffect(() => {
    if (
      !stringifiedBenchmark ||
      cachedBenchmarkMap[stringifiedBenchmark] !== undefined
    ) {
      return;
    }
    const data = JSON.parse(stringifiedBenchmark);
    const benchmarkErrors = getBenchmarkErrors({
      ...data,
      name: new Date().toString(),
    });
    if (benchmarkErrors.length) {
      console.log(data, benchmarkErrors);
      Alert.error(t("benchmarkInvalid"));
      setBenchmark(emptyBenchmark);
      return;
    }

    axios
      .request<components["schemas"]["BenchmarkAnalysis"]>({
        method: "post",
        url: `/benchmark/analyse/count/mediatype/none/publicationDate`,
        data,
      })
      .then(() => {
        setCachedBenchmarkMap((cachedBenchmarkMap) => ({
          ...cachedBenchmarkMap,
          [stringifiedBenchmark]: {
            ...cachedBenchmarkMap[stringifiedBenchmark],
            endDate: new Date(),
          },
        }));
      })
      .catch((err: AxiosError<IMaartenError>) => {
        Alert.error(t(err.response?.data.error || "genericErrorMessage"));
      });
    setCachedBenchmarkMap((cachedBenchmarkMap) => ({
      ...cachedBenchmarkMap,
      [stringifiedBenchmark]: {
        startDate: new Date(),
      },
    }));
    // try and find result counts
    Promise.all(
      benchmark.matchTexts.map((matchText) =>
        axios.request<components["schemas"]["SearchEngineResults"]>({
          method: "post",
          url: "/searchEngineSearch",
          data: {
            ...benchmark.searchEngineRequest,
            query: {
              ...benchmark.searchEngineRequest.query,
              matchText: matchText.matchText,
            },
          },
          params: {
            limit: 1,
            offset: 0,
          },
        }),
      ),
    ).then((results) => {
      const resultCount = results.reduce(
        (prev, result) => prev + result.data.count,
        0,
      );
      setCachedBenchmarkMap((cachedBenchmarkMap) => ({
        ...cachedBenchmarkMap,
        [stringifiedBenchmark]: {
          ...cachedBenchmarkMap[stringifiedBenchmark],
          resultCount,
        },
      }));
    });
  }, [
    benchmark.matchTexts,
    benchmark.searchEngineRequest,
    cachedBenchmarkMap,
    getBenchmarkErrors,
    stringifiedBenchmark,
    t,
  ]);

  const onLayoutChange = React.useCallback(
    (_currentLayout: any, updatedLayouts: any) => {
      setBenchmark((benchmark) => ({
        ...benchmark,
        config: {
          ...benchmark.config,
          widgets: benchmark.config.widgets?.map(
            (widget, index): IBenchmarkWidget => ({
              ...widget,
              layouts: {
                xs: {
                  ...updatedLayouts["xs"][index],
                  x: 0,
                  w: 1,
                  h: updatedLayouts["xs"][index].h || 3,
                },
                lg: {
                  ...updatedLayouts["lg"][index],
                },
              },
            }),
          ),
        },
      }));
    },
    [],
  );

  const { widgets } = benchmark.config || {};

  React.useEffect(() => {
    const benchmarkIds = benchmarks ? Object.keys(benchmarks) : [];
    if (isBenchmarkOnly && !benchmarkId && benchmarkIds.length) {
      history.push(`/app/analysis/${benchmarkIds[0]}`);
    }
  }, [
    benchmark.benchmarkId,
    benchmarkId,
    benchmarks,
    history,
    isBenchmarkOnly,
  ]);

  if (!auth?.jwt.canBenchmark) {
    return (
      <div className="flex-grow-1 d-flex justify-content-center align-items-center mt-5 pt-5 flex-column">
        <h2>{t("benchmarkView__no-access-title")}</h2>
        <div
          className="my-3"
          dangerouslySetInnerHTML={{
            __html: t("benchmarkView__no-access-body"),
          }}
        />
        <img
          className="benchmark-view__start-image mt-5"
          alt="media·web analyse"
          src={"/img/art/liniaal/liniaal@3x.png"}
          srcSet={`/img/art/liniaal/liniaal@2x.png 2x, /img/art/liniaal/liniaal@3x.png 3x`}
          style={{ height: 254, width: 431 }}
        />
      </div>
    );
  }

  if (stringifiedBenchmark) {
    if (!cachedBenchmarkMap[stringifiedBenchmark]) {
      return <Loader />;
    }

    if (!cachedBenchmarkMap[stringifiedBenchmark].endDate) {
      const { resultCount, startDate } =
        cachedBenchmarkMap[stringifiedBenchmark];
      return (
        <ProgressIndicator resultCount={resultCount} startDate={startDate} />
      );
    }
  }

  if (isBenchmarkOnly && !benchmark.benchmarkId) {
    return <Loader />;
  }

  return (
    <div
      ref={benchmarkViewRef}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      }}
    >
      <div className={`searchEngineRequest-bar-container`}>
        <form className="mb-3 mb-md-4" onSubmit={onBenchmarkSubmit}>
          <div
            className="row align-items-center"
            style={{ minHeight: 40, justifyContent: "space-between" }}
          >
            <div className="col-auto d-none d-sm-flex benchmark-view__col-title">
              <div>
                {isBenchmarkOnly ? (
                  <h3>{benchmark.name}</h3>
                ) : (
                  <AutosizeInput
                    value={benchmark.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setBenchmark({
                        ...benchmark,
                        name: event.target.value,
                      });
                    }}
                    onKeyDown={(event: KeyboardEvent) => {
                      if (
                        event.key !== "Escape" ||
                        !benchmarkId ||
                        !benchmarks ||
                        !benchmarks[benchmarkId]
                      ) {
                        return;
                      }
                      setBenchmark({
                        ...benchmark,
                        name: benchmarks[benchmarkId].name,
                      });
                    }}
                    style={{ maxWidth: "100%" }}
                    className="benchmark-view__title-autosize-input"
                    placeholder={t("namelessBenchmark")}
                    inputClassName="rs-input benchmark-view__title-autosize-input__input"
                  />
                )}
                {errors && errors["name"] ? (
                  <HelpBlock
                    style={{ color: "red", marginLeft: 6, marginBottom: -23 }}
                  >
                    {t(errors["name"])}
                  </HelpBlock>
                ) : null}
              </div>
              <ButtonGroup
                size="xs"
                className="benchmark-view__topbar__save-benchmark-buttons d-none d-sm-flex"
              >
                {isSavePending ? (
                  <Loader />
                ) : (
                  <Button
                    className="benchmark-view__topbar__save-benchmark-buttons__button"
                    disabled={!isBenchmarkDirty}
                    appearance={
                      !benchmarkId || isBenchmarkDirty ? "primary" : "ghost"
                    }
                    type="submit"
                    title={
                      isBenchmarkDirty
                        ? undefined
                        : t("benchmarkCanBeSavedWhenDirty")
                    }
                  >
                    {t("save")}
                  </Button>
                )}
              </ButtonGroup>
            </div>
            <div className="col-auto">
              {benchmarkId && !isBenchmarkOnly ? (
                <ShareButton benchmarkId={benchmarkId} />
              ) : null}
              {loadableBenchmarks && loadableBenchmarks.length ? (
                <Dropdown
                  className="benchmark-view__Benchmark-selector mx-2"
                  appearance="ghost"
                  size="xs"
                  title={t("open")}
                  placement="bottomEnd"
                >
                  {loadableBenchmarks.map((item) => (
                    <Dropdown.Item
                      className="benchmark-view__Benchmark-selector__item"
                      key={item.benchmarkId}
                      onSelect={() => {
                        history.push(
                          `${APP_PATH}/analysis/${item.benchmarkId}`,
                        );
                      }}
                    >
                      {item.name}
                      <IconButton
                        placement={"right"}
                        ripple={false}
                        className="bg-transparent benchmark-view__Benchmark-selector__item__icon-button ms-auto me-0"
                        size="xs"
                        appearance="subtle"
                        onClick={(e: any) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onItemDelete(item.benchmarkId as string);
                        }}
                        icon={<Icon icon="trash" componentClass={Prullie} />}
                      />
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              ) : null}
              {isBenchmarkOnly ? null : (
                <ActionButton
                  className="benchmark-action-button--add-widget mx-2"
                  disabled={!benchmark.matchTexts.length}
                  onClick={() => setIsDrawerOpen(true)}
                  title={
                    windowOuterWidth >= BootstrapSize.MD ? "widget" : undefined
                  }
                />
              )}
            </div>
          </div>
        </form>
        <BenchmarkBar
          benchmark={benchmark}
          isLoading={isBenchmarkDirty}
          disabled={isBenchmarkOnly}
        />
      </div>
      {benchmark.matchTexts.length ? (
        widgets?.length ? (
          <ResponsiveGridLayout
            draggableHandle={isBenchmarkOnly ? undefined : ".drag-handle"}
            className={`views__benchmark-view__grid-layout views__benchmark-view__grid-layout--draggable`}
            isDraggable={!isBenchmarkOnly}
            rowHeight={40}
            breakpoints={{ lg: BootstrapSize.LG, xs: 0 }}
            cols={{ lg: 3, xs: 1 }}
            width={contentWidth + 48}
            margin={[30, 30]}
            style={{
              marginLeft: -30,
            }}
            layouts={layouts}
            onLayoutChange={isBenchmarkOnly ? undefined : onLayoutChange}
          >
            {widgets?.map((widget, index) => (
              <BenchmarkWidgetContainer
                benchmark={benchmark}
                benchmarkWidth={contentWidth + 48}
                key={widget.uid}
                onDelete={
                  isBenchmarkOnly
                    ? undefined
                    : () => {
                        setBenchmark({
                          ...benchmark,
                          config: {
                            ...benchmark.config,
                            widgets: widgets.filter(
                              (_existingWidget, existingIndex) =>
                                index !== existingIndex,
                            ),
                          },
                        });
                      }
                }
                onSettingsChange={
                  isBenchmarkOnly ? undefined : onSettingsChange
                }
                widget={widget}
              />
            ))}
          </ResponsiveGridLayout>
        ) : (
          <Message
            type="info"
            title={t("addWidgets")}
            className="mt-3"
            description={t("benchmarkView_noWidgetsWarning")}
            onClick={() => setIsDrawerOpen(true)}
          />
        )
      ) : (
        <div className="flex-grow-1 d-flex justify-content-center align-items-center mt-5 pt-5 flex-column">
          <h2>{t("benchmarkView_startTitle")}</h2>
          <p className="my-3">{t("benchmarkView_startBody")}</p>
          <img
            className="benchmark-view__start-image mt-5"
            alt="media·web analyse"
            src={"/img/art/liniaal/liniaal@3x.png"}
            srcSet={`/img/art/liniaal/liniaal@2x.png 2x, /img/art/liniaal/liniaal@3x.png 3x`}
            style={{ height: 254, width: 431 }}
          />
        </div>
      )}
      {isBenchmarkOnly ? null : (
        <div
          className="mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: t("benchmarkView_disclaimer") }}
        />
      )}
      <BenchmarkWidgetDrawer
        benchmark={benchmark}
        isDrawerOpen={isDrawerOpen}
        setBenchmark={setBenchmark}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </div>
  );
};

export default BenchmarkView;
