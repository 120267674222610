// LEAVE THIS STYLING BOOTSTRAP ON TOP
import "./fonts/gelion-regular/gelion-regular.css"; // font-weight 400 / normaal
import "./fonts/gelion-medium/gelion-medium.css"; // font-weight 500
import "./fonts/gelion-semi-bold/gelion-semi-bold.css"; // font-weight 600
import "rsuite/lib/styles/index.less";
import "./App.scss";
// DO NOT PLACE IT AFTER COMPONENTS ARE LOADED

import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import AlertsView from "./views/AlertsView";
import LoginView from "./views/login/LoginView";
import DashboardView from "./views/DashboardView";
import UsersView from "./views/UsersView";
import NotFoundView from "./views/NotFoundView";
import AppLayout from "./layout/AppLayout/index";
import LoginLayout from "./layout/LoginLayout";
import LogoutView from "./views/LogoutView";
import LostPasswordView from "./views/login/LostPasswordView";
import ResetPasswordView from "./views/login/ResetPasswordView";
import ExpiredPasswordView from "./views/login/ExpiredPasswordView";
import CustomersView from "./views/CustomersView";
import CustomerView from "./views/CustomerView";
import GroupsView from "./views/GroupsView";
import LabelsView from "./views/LabelsView";
import MediaItemView from "./views/MediaItemView";
import SwitchCustomerView from "./views/SwitchCustomerView";
import { APP_PATH, LOGIN_PATH } from "./inc/constants";
import SearchEngineView from "./views/SearchEngineView";
import InvoicesView from "./views/InvoicesView";
import SearchTopicsView from "./views/SearchTopicsView";
import ContentExportView from "./views/ContentExportView";
import RssView from "./views/RssView";
import DeleteRequestsView from "./views/DeleteRequestsView";
import EditorialsView from "./views/EditorialsView";
import EditorialDetailView from "./views/EditorialDetailView";
import EditorialIssuesView from "./views/EditorialIssuesView";
import SubscribersView from "./views/SubscribersView";
import MediaItemPurchasesView from "./views/MediaItemPurchasesView";
import UnsubscribeLayout from "./layout/UnsubscribeLayout";
import ProfileView from "./views/ProfileView";
import DashboardsView from "./views/DashboardsView";
import { Alert, IntlProvider } from "rsuite";
import { I18nContext } from "./provider/I18nProvider";
import { AuthContext } from "./provider/AuthProvider";
import ActiveSourcesView from "./views/ActiveSourcesView";
import ShareDashboardView from "./views/login/ShareDashboardView";
import ShareDashboardLayout from "./layout/ShareDashboardLayout";
import NoAccessView from "./views/NoAccess";
import BenchmarkView from "./views/BenchmarkView";
import PrDashboardView from "./views/PrDashboardView";
import PrDashboardLayout from "./layout/PrDashboardLayout";
import ShareBenchmarkView from "./views/login/ShareBenchmarkView";
import ShareBenchmarkLayout from "./layout/ShareBenchmarkLayout";

ReactGA.initialize("UA-22373408-9");

// https://www.notion.so/slem/411ddaa6d55f42859f535a736080485c?v=6eb751581a7144cd9b93867adecb16a0&p=f26b89604b4444949358df32567a0fe6
// Melding blauwe popup langer in beeld (Bijv bij aanmaken dashboard).
Alert.config({
  duration: 4000,
});

const App = () => {
  const { rsuiteLocale } = React.useContext(I18nContext);
  const { auth } = React.useContext(AuthContext);
  const { userRoles = [], customerRole = "" } = auth?.jwt || {};

  const history = useHistory();

  React.useEffect(() => {
    history.listen((location) => {
      ReactGA.pageview(location.pathname + location.search);
    });
    ReactGA.pageview(history.location.pathname + history.location.search);
  }, [history]);

  let appIndexPath = `${APP_PATH}/noAccess`;
  if (["regular", "restricted"].indexOf(customerRole) >= 0) {
    appIndexPath = `${APP_PATH}/myMedia`;
  }
  if (userRoles.includes("superuser")) {
    appIndexPath = `${APP_PATH}/users`;
  }
  if (userRoles.includes("benchmarkOnly")) {
    appIndexPath = `${APP_PATH}/analysis`;
  }
  if (customerRole === "inholland") {
    appIndexPath = `${APP_PATH}/search`;
  }
  return (
    <IntlProvider locale={rsuiteLocale}>
      <Switch>
        <Route path={LOGIN_PATH}>
          <Switch>
            <Route
              path={`${LOGIN_PATH}/expiredPassword`}
              component={ExpiredPasswordView}
            />
            <Route
              path={`${LOGIN_PATH}/shareDashboard`}
              component={ShareDashboardView}
            >
              <ShareDashboardLayout>
                <Route component={ShareDashboardView} />
              </ShareDashboardLayout>
            </Route>
            <Route
              path={`${LOGIN_PATH}/shareBenchmark`}
              component={ShareBenchmarkView}
            >
              <ShareBenchmarkLayout>
                <Route component={ShareBenchmarkView} />
              </ShareBenchmarkLayout>
            </Route>
            <Route>
              <LoginLayout>
                <Switch>
                  <Route exact path={LOGIN_PATH} component={LoginView} />
                  <Route
                    path={`${LOGIN_PATH}/lostPassword`}
                    component={LostPasswordView}
                  />
                  <Route
                    path={`${LOGIN_PATH}/resetPassword`}
                    component={ResetPasswordView}
                  />
                  <Route>
                    <NotFoundView />
                  </Route>
                </Switch>
              </LoginLayout>
            </Route>
          </Switch>
        </Route>
        <Route path={APP_PATH}>
          <AppLayout>
            <Switch>
              <Route
                path={`${APP_PATH}/mediaItem/:customerLinkId/:mediaItemId`}
                component={MediaItemView}
              />
              <Route
                path={`${APP_PATH}/myMedia`}
                exact
                component={DashboardView}
              />
              <Route
                path={`${APP_PATH}/dashboards`}
                exact
                component={DashboardsView}
              />
              <Route
                path={`${APP_PATH}/dashboards/:dashboardId`}
                component={DashboardView}
              />
              <Route
                path={`${APP_PATH}/editorials/overview/:editorialId`}
                component={EditorialDetailView}
              />
              <Route
                path={`${APP_PATH}/editorials/overview`}
                component={EditorialsView}
              />
              <Route
                path={`${APP_PATH}/editorials/subscribers`}
                component={SubscribersView}
              />
              <Route
                path={`${APP_PATH}/editorials/issues`}
                component={EditorialIssuesView}
              />
              <Route
                path={`${APP_PATH}/admin/media-item-purchases`}
                component={MediaItemPurchasesView}
              />
              <Route
                path={`${APP_PATH}/admin/deleted-media-items`}
                component={DeleteRequestsView}
              />
              <Route path={`${APP_PATH}/admin/users`} component={UsersView} />
              <Route
                path={`${APP_PATH}/admin/invoices`}
                component={InvoicesView}
              />
              <Route
                path={`${APP_PATH}/admin/searchTopics`}
                component={SearchTopicsView}
              />
              <Route path={`${APP_PATH}/admin/rss`} component={RssView} />
              <Route
                path={`${APP_PATH}/admin/contentExport`}
                component={ContentExportView}
              />
              <Route path={`${APP_PATH}/admin/labels`} component={LabelsView} />
              <Route
                path={`${APP_PATH}/admin/customer`}
                component={CustomerView}
              />
              <Route
                path={`${APP_PATH}/admin/activeSources`}
                component={ActiveSourcesView}
              />
              <Route path={`${APP_PATH}/admin`} exact>
                <Redirect to={`${APP_PATH}/admin/searchTopics`} />
              </Route>
              <Route path={`${APP_PATH}/alerts`} component={AlertsView} />
              <Route exact path={`${APP_PATH}/editorials`}>
                <Redirect to={`${APP_PATH}/editorials/overview`} />
              </Route>
              <Route path={`${APP_PATH}/users`} component={UsersView} />
              <Route path={`${APP_PATH}/customers`} component={CustomersView} />
              <Route path={`${APP_PATH}/groups`} component={GroupsView} />
              <Route path={`${APP_PATH}/noAccess`} component={NoAccessView} />
              <Route exact path={`${APP_PATH}`}>
                <Redirect to={appIndexPath} />
              </Route>
              <Route
                path={`${APP_PATH}/switchCustomer/:customerId`}
                component={SwitchCustomerView}
              />
              <Route
                exact
                path={`${APP_PATH}/search`}
                component={SearchEngineView}
              />
              <Route
                path={`${APP_PATH}/search/:searchEngineQueryId`}
                component={SearchEngineView}
              />
              <Route
                exact
                path={`${APP_PATH}/analysis`}
                component={BenchmarkView}
              />
              <Route
                exact
                path={`${APP_PATH}/analysis/:benchmarkId`}
                component={BenchmarkView}
              />
              <Route path={`${APP_PATH}/account`} component={ProfileView} />
              <Route>
                <NotFoundView />
              </Route>
            </Switch>
          </AppLayout>
        </Route>
        <Route path={`/pr-dashboard`}>
          <PrDashboardLayout>
            <Route component={PrDashboardView} />
          </PrDashboardLayout>
        </Route>
        <Route path={"/logout"} component={LogoutView} />
        <Route
          path="/unsubscribe/:editorialUnsubscribeToken"
          exact
          component={UnsubscribeLayout}
        />
        <Route exact path={`/`}>
          <Redirect to={LOGIN_PATH} />
        </Route>
        <Route>
          <NotFoundView />
        </Route>
      </Switch>
    </IntlProvider>
  );
};

export default App;
