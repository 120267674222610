import React from "react";
import axios from "../inc/axios";
import { ApiDataContext } from "../provider/ApiDataProvider";
import { singletonHook } from "react-singleton-hook";
import { components } from "../types/openapi";
import { IHashMap } from "../inc/data";
import { I18nContext } from "../provider/I18nProvider";
import { AuthContext } from "../provider/AuthProvider";

const useContentExports = singletonHook(undefined, () => {
  const { t } = React.useContext(I18nContext);
  const { contentExports, setContentExports } =
    React.useContext(ApiDataContext);
  const { auth } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (
      contentExports !== undefined ||
      !auth?.bearer ||
      auth.jwt.userRoles.indexOf("superuser") >= 0
    ) {
      return;
    }
    setContentExports(null);
    axios
      .get<components["schemas"]["ContentExport"][]>("/contentExport/crud")
      .then((res) => {
        setContentExports(
          res.data.reduce<IHashMap<components["schemas"]["ContentExport"]>>(
            (prev, contentExport) => {
              prev[contentExport.contentExportId as string] = contentExport;
              return prev;
            },
            {}
          )
        );
      })
      .catch((err) => {
        console.log(err);
        // Alert.error(t("apiDataProvider_fetchingFailed_contentExport"));
      });
  }, [auth?.bearer, auth?.jwt.userRoles, setContentExports, contentExports, t]);

  return contentExports;
});

export default useContentExports;
