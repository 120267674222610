import React, { RefObject } from "react";
import { Dropdown, Tooltip, Icon, IconButton } from "rsuite";
import { LayoutContext } from "../../../../../provider/LayoutProvider";
import { NavIcon } from "../../index";
import { useLocation } from "react-router-dom";
import Prullie from "../../../../../icons/Prullie";
import Dashboards from "../../../../../icons/Dashboards";
import Plus from "../../../../../icons/Plus";
import MwWhisper from "../../../../../components/MwWhisper";
import { AuthContext } from "../../../../../provider/AuthProvider";

interface IDashboardDropDownProps {
  baseNavigation: string;
  items: { id: string; title: string }[];
  onItemDeleteClick: (itemId: string) => void;
  onItemSelect: (itemId: string) => void;
  title: string;
}

const MAX_TITLE_LENGTH = 25;

const DashboardDropDown = ({
  baseNavigation,
  items,
  onItemDeleteClick,
  onItemSelect,
  title,
}: IDashboardDropDownProps) => {
  const { setIsTemplateDialogOpen } = React.useContext(LayoutContext);
  const { auth } = React.useContext(AuthContext);

  const location = useLocation();
  const currentItemId = location.pathname.split("/")[3];
  const triggerRef: RefObject<any> = React.useRef();
  const isDashboardOnly = auth
    ? auth.jwt.userRoles?.indexOf("dashboardOnly") >= 0
    : false;

  const submenuIcon = (
    <NavIcon svg={Dashboards} to={baseNavigation} title={title} />
  );

  const submenuItems = React.useMemo(
    () =>
      items.map(({ id, title }) => {
        const showTitlePartial = title.length > MAX_TITLE_LENGTH;
        return (
          <MwWhisper
            key={title}
            delayShow={350}
            trigger={showTitlePartial ? "hover" : "none"}
            speaker={<Tooltip>{title}</Tooltip>}
          >
            <Dropdown.Item
              eventKey={title}
              active={id === currentItemId}
              className="layout__app-layout__nav-items__submenu__item"
              onSelect={() => {
                if (triggerRef.current) {
                  triggerRef.current.close();
                }
                onItemSelect(id);
              }}
            >
              &#8226;{" "}
              {showTitlePartial
                ? `${title.substr(0, MAX_TITLE_LENGTH)}...`
                : title}
              {!isDashboardOnly ? (
                <IconButton
                  className="layout__app-layout__nav-items__submenu__item__icon bg-transparent"
                  size="xs"
                  color="blue"
                  ripple={false}
                  appearance="primary"
                  icon={<Icon icon="trash" componentClass={Prullie} />}
                  onClick={(e:any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onItemDeleteClick(id);
                  }}
                />
              ) : null}
            </Dropdown.Item>
          </MwWhisper>
        );
      }),
    [
      currentItemId,
      isDashboardOnly,
      items,
      onItemDeleteClick,
      onItemSelect,
      triggerRef,
    ]
  );

  return (
    <div className="rs-nav-item nav-item app-layout__nav-items__dashboard-submenu position-relative">
      <Dropdown
        eventKey={title}
        className={`rs-nav-item ${
          location.pathname.startsWith(baseNavigation)
            ? "rs-nav-item-active"
            : ""
        }`}
        title={title}
        icon={submenuIcon}
        toggleClassName="rs-nav-item-content"
        placement={"rightStart"}
        open={isDashboardOnly ? true : undefined}
      >
        {submenuItems}
      </Dropdown>
      {isDashboardOnly ? null : (
        <Icon
          icon="plus"
          className="app-layout__nav-items__dashboard-submenu__submenu__add"
          componentClass={Plus}
          onClick={() => {
            setIsTemplateDialogOpen(true);
          }}
        />
      )}
    </div>
  );
};
export default DashboardDropDown;
