import React from "react";
import { LayoutContext } from "../../../provider/LayoutProvider";
import { Icon, IconButton } from "rsuite";
import "./index.scss";
import Hamburger from "../../../icons/Hamburger";
import { AuthContext } from "../../../provider/AuthProvider";

interface IAppHeaderProps {
  children?: React.ReactNode;
}

const AppHeader = (props: IAppHeaderProps) => {
  const { children } = props;
  const { isSidebarOpen, setIsSidebarOpen } = React.useContext(LayoutContext);
  const { auth } = React.useContext(AuthContext);

  const toggleSidebar = React.useCallback(
    () => setIsSidebarOpen(!isSidebarOpen),
    [isSidebarOpen, setIsSidebarOpen]
  );
  return (
    <>
      {auth?.jwt.customerRole !== "inholland" ? (
        <IconButton
          className="app-header__hamburger"
          appearance="subtle"
          icon={<Icon icon="align-justify" componentClass={Hamburger} />}
          onClick={toggleSidebar}
        />
      ) : null}
      <div className="app-header__children">{children}</div>
    </>
  );
};

export default AppHeader;
