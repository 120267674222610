import React from "react";
import useQueryParameters from "../../../hooks/useQueryParameters";
import { AuthContext } from "../../../provider/AuthProvider";
import { Loader } from "rsuite";
import axios from "../../../inc/axios";
import { components } from "../../../types/openapi";
import { Redirect } from "react-router-dom";
import { APP_PATH } from "../../../inc/constants";
import { I18nContext } from "../../../provider/I18nProvider";
import ResetPasswordForm from "../inc/ResetPasswordForm";

const ShareBenchmarkView = () => {
  const { auth } = React.useContext(AuthContext);
  const { t } = React.useContext(I18nContext);
  const [benchmarkAccessTokenResponse, setBenchmarkAccessTokenResponse] =
    React.useState<components["schemas"]["BenchmarkAccessTokenResponse"]>();

  const { token } = useQueryParameters<{ token: string }>();

  React.useEffect(() => {
    if (!token) {
      setBenchmarkAccessTokenResponse({
        emailAddress: "",
        benchmarkAccessTokenStatus: "tokenUnknown",
      });
      return;
    }
    axios
      .request<components["schemas"]["BenchmarkAccessTokenResponse"]>({
        method: "post",
        url: "/auth/benchmarkRequest",
        data: {
          token,
        },
      })
      .then((res) => {
        setBenchmarkAccessTokenResponse(res.data);
      })
      .catch((err) => {
        console.log(err);
        setBenchmarkAccessTokenResponse({
          emailAddress: "",
          benchmarkAccessTokenStatus: "tokenUnknown",
        });
      });
  }, [token]);

  if (!benchmarkAccessTokenResponse) {
    return <Loader />;
  }

  let nextPath = `${APP_PATH}${
    benchmarkAccessTokenResponse.benchmarkId
      ? `/analysis/${benchmarkAccessTokenResponse.benchmarkId}`
      : ``
  }`;
  if (
    auth ||
    benchmarkAccessTokenResponse.benchmarkAccessTokenStatus === "userExists"
  ) {
    if (benchmarkAccessTokenResponse.customerLinkId && nextPath) {
      nextPath += `?customerLinkId=${benchmarkAccessTokenResponse.customerLinkId}`;
    }
    return <Redirect to={nextPath} />;
  }

  switch (benchmarkAccessTokenResponse.benchmarkAccessTokenStatus) {
    case "tokenValid":
      return <ResetPasswordForm nextPath={nextPath} resetToken={token} />;

    case "tokenExpired":
      return (
        <>
          <h2>{t("benchmarkAccessTokenExpiredTitle")}</h2>
          <p>
            {t(
              "benchmarkAccessTokenExpiredBody",
              benchmarkAccessTokenResponse.emailAddress,
            )}
          </p>
        </>
      );

    case "tokenUnknown":
    default:
      return (
        <>
          <h2>{t("benchmarkAccessTokenUnknownTitle")}</h2>
          <p>{t("benchmarkAccessTokenUnknownBody")}</p>
        </>
      );
  }
};

export default ShareBenchmarkView;
